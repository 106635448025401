import { CloseCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { DatePicker, Modal, PageHeader } from 'antd';
import produce from 'immer';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { restApi } from '../../../apis';
import { useGetAllContent, useGetContentGroupDetail } from '../../../apis/queries';
import { parseImageUri } from '../../../common/utils';
import PaginateTable from '../../../components/table/PaginateTable';
import TableColHeader from '../../../components/table/TableColHeader';
import useBlockRefresh from '../../../hooks/useBlockRefresh';
import styles from './main_section.module.less';

const OperationMainSectionComposeDetailSearch = () => {
  useBlockRefresh();
  const { RangePicker } = DatePicker;
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  //추가하기 전 검색목록(왼쪽)

  const [selectedRowKeysBeforeAdd, setSelectedRowKeysBeforeAdd] = React.useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeysBeforeAdd(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeysBeforeAdd,
    onChange: onSelectChange,
  };
  //추가한 뒤 목록(오른쪽)
  const [selectedRowKeysAfterAdd, setSelectedRowKeysAfterAdd] = React.useState([]);
  const { commonCodes } = useSelector((state) => state.common);
  const codeMapCOTP = React.useMemo(() => {
    const obj = {};
    commonCodes?.tree.COTP.forEach((el) => {
      obj[el.code] = el.label;
    });
    return obj;
  }, []);
  const codeMapCOCT = React.useMemo(() => {
    const obj = {};
    commonCodes?.tree.COCT.forEach((el) => {
      obj[el.code] = el.label;
    });
    return obj;
  }, []);
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: null,
      sort: null,
    }),
    [],
  );
  const [keywordValue, setKeywordValue] = React.useState();
  const [codeOption, setCodeOption] = React.useState({});
  const [page, setPage] = React.useState(initialParams.page);
  const { data: contentGroupDetailData } = useGetContentGroupDetail({
    id: location?.state?.contentGroupId,
  });
  const [detailModify, setDetailModify] = React.useState({
    contentIds: [],
    contents: [],
    contentType: 'STYLE',
    description: '',
    title: '',
  });
  const { data: allContentData } = useGetAllContent({
    type:
      detailModify?.contentType === 'STYLE'
        ? 'styles'
        : detailModify?.contentType === 'PORTFOLIO'
        ? 'portfolios'
        : detailModify?.contentType === 'TREND'
        ? 'trends'
        : 'construction-guides',
    params: { keyword: keywordValue, ...codeOption },
  });

  React.useEffect(() => {
    setDetailModify(
      produce((draft) => {
        draft.contentIds =
          contentGroupDetailData?.contents?.length > 0 ? contentGroupDetailData?.contents?.map((v) => v.id) : [];
        draft.contents = contentGroupDetailData?.contents ?? [];
        draft.contentType = contentGroupDetailData?.contentType ?? 'STYLE';
        draft.description = contentGroupDetailData?.description ?? '';
        draft.title = contentGroupDetailData?.title ?? '';
      }),
    );
  }, [contentGroupDetailData]);

  const [getSelectedData, setGetSelectedData] = React.useState([]);
  const [addAfterData, setAddAfterData] = React.useState(
    contentGroupDetailData?.contents ? [...contentGroupDetailData?.contents] : [],
  );
  const [pageSize, setPageSize] = React.useState(20);
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 60 - 280);
  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);
  console.log('detailModify', detailModify);
  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="메인 섹션 > 항목구성 > 항목세부 > 컨텐츠 검색"
        style={{ paddingBottom: 10 }}
      />
      <div style={{ height: 50, textAlign: 'right', marginTop: 10 }}>
        <button
          style={{ padding: '3px 18px', borderRadius: '20px', border: '1.5px solid #1e3d83' }}
          onClick={() => {
            setDetailModify(
              produce((draft) => {
                draft.contentIds = [];
                draft.contents = [];
              }),
            );
          }}
        >
          CLEAR ALL
        </button>
        <button
          style={{
            padding: '3px 18px',
            borderRadius: '20px',
            backgroundColor: '#1e3d83',
            border: '1.5px solid #1e3d83',
            color: 'white',
            marginLeft: 10,
          }}
          onClick={() => {
            let id;
            Modal.confirm({
              title: '저장',
              content: '이 값으로 저장하시겠습니까?',
              okText: '저장',
              cancelText: '취소',
              async onOk() {
                try {
                  if (location?.state?.contentGroupId) {
                    await restApi.put(`/content-groups/${location?.state?.contentGroupId}`, {
                      contentIds: [
                        ...detailModify?.contents?.map((el) => {
                          return el.id;
                        }),
                      ],
                      contentType: detailModify?.contentType,
                      description: detailModify?.description,
                      title: detailModify?.title,
                    });
                  } else {
                    const { data } = await restApi.post(`/content-groups`, {
                      contentIds: [
                        ...detailModify?.contents?.map((el) => {
                          return el.id;
                        }),
                      ],
                      contentType: detailModify?.contentType,
                      description: detailModify?.description,
                      title: detailModify?.title,
                    });
                    id = data;
                  }
                  console.log(detailModify);
                  Modal.confirm({
                    title: '완료',
                    content: '완료되었습니다',
                    onOk() {
                      navigate('/operation/main_section/section/detail', {
                        state: {
                          detailId: location?.state?.detailId,
                          contentGroupId: id ?? location?.state?.contentGroupId,
                          index: location?.state?.index,
                        },
                      });
                    },
                    cancelButtonProps: {
                      style: { display: 'none' },
                    },
                    okText: '확인',
                  });
                } catch (error) {
                  Modal.warning({
                    title: '오류',
                    content: '오류입니다',
                  });
                }
              },
            });
          }}
        >
          저장하기
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            
            paddingLeft: 20,
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: document.body.clientHeight - 240,
            marginRight: 40,
            paddingBottom: 15,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              padding: 20,
              backgroundColor: 'white',
              height: 900,
            }}
          >
            <div
              style={{
                width: 360,
                height: 800,
                backgroundColor: 'white',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 40,
                padding: 20,
              }}
            >
              <p style={{ fontWeight: 'bold', fontSize: 20, color: '#1e3d83' }}>리스트 정보</p>
              <div>
                <input
                  type="text"
                  style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '10px 0' }}
                  placeholder="제목을 입력해주세요."
                  value={detailModify?.title}
                  onChange={(e) => {
                    setDetailModify(
                      produce((draft) => {
                        draft.title = e.target.value;
                      }),
                    );
                  }}
                />
              </div>
              <div>
                <input
                  type="text"
                  style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '10px 0' }}
                  placeholder="설명을 입력해주세요."
                  value={detailModify?.description}
                  onChange={(e) => {
                    setDetailModify(
                      produce((draft) => {
                        draft.description = e.target.value;
                      }),
                    );
                  }}
                />
              </div>
              <p style={{ fontWeight: 'bold', fontSize: 20, color: '#1e3d83', marginTop: 80 }}>리스트 검색</p>
              <div>
                <select
                  style={{
                    width: '100%',
                    padding: '5px 0',
                    marginTop: 30,
                    border: 'none',
                    borderBottom: '1px solid #d9d9d9',
                  }}
                  defaultValue={'STYLE'}
                  onChange={(e) => {
                    setDetailModify(
                      produce((draft) => {
                        draft.contentType = e.target.value;
                      }),
                    );
                    setCodeOption({});
                    setSelectedRowKeysBeforeAdd([]);
                  }}
                >
                  <option value="STYLE">스타일</option>
                  <option value="PORTFOLIO">포트폴리오</option>
                  <option value="TREND">트렌드</option>
                  <option value="CONSTRUCTION_GUIDE">공사 가이드</option>
                </select>
              </div>
              <div>
                {(detailModify?.contentType === 'STYLE' || detailModify?.contentType === 'PORTFOLIO') && (
                  <select
                    style={{
                      width: '100%',
                      padding: '5px 0',
                      marginTop: 30,
                      border: 'none',
                      borderBottom: '1px solid #d9d9d9',
                    }}
                    onChange={(e) => {
                      if (e.target.value) {
                        setCodeOption({ constructionType: e.target.value });
                      } else {
                        setCodeOption({});
                      }
                    }}
                  >
                    <option value="">모두보기</option>
                    {commonCodes?.tree.COTP?.map((el, index) => {
                      return (
                        <option key={`list_cotp_code ${index}`} value={el.code}>
                          {el.label}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
              <div>
                {detailModify?.contentType === 'CONSTRUCTION_GUIDE' && (
                  <select
                    style={{
                      width: '100%',
                      padding: '5px 0',
                      marginTop: 30,
                      border: 'none',
                      borderBottom: '1px solid #d9d9d9',
                    }}
                    onChange={(e) => {
                      if (e.target.value) {
                        setCodeOption({ constructionCategory: e.target.value });
                      } else {
                        setCodeOption({});
                      }
                    }}
                  >
                    <option value="">모두보기</option>
                    {commonCodes?.tree.COCT?.map((el, index) => {
                      return (
                        <option key={`list_coct_code ${index}`} value={el.code}>
                          {el.label}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
              <div>
                <input
                  type="text"
                  style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '30px 0' }}
                  placeholder="검색어를 입력해주세요."
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setKeywordValue(e.target.value);
                    }
                  }}
                />
              </div>
            </div>
            {/* <div style={{ textAlign: 'center' }}>
              <button
                style={{ marginBottom: 20, padding: '3px 18px', borderRadius: '20px', border: '1.5px solid #1e3d83' }}
              >
                저장
              </button>
            </div> */}
          </div>
        </div>
        <PaginateTable
          dataSource={allContentData?.content}
          selectedRowKeys={selectedRowKeysBeforeAdd}
          scroll={{ y: tableHeight - 40 }}
          setSelectedRowKeys={setSelectedRowKeysBeforeAdd}
          rowSelection={rowSelection}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '32%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
          page={page}
          setPage={setPage}
          setPageSize={setPageSize}
          columns={[
            {
              title: '썸네일',
              dataIndex: 'thumbnailId',
              key: 'thumbnailId',
              width: 70,
              align: 'center',
              render: (data, record) => {
                return (
                  <div style={{ position: 'relative' }}>
                    {data ? (
                      <img
                        src={parseImageUri(data)}
                        alt="배너 이미지"
                        style={{ width: 50, height: 50, borderRadius: 10 }}
                      ></img>
                    ) : (
                      <div style={{ width: 50, height: 50 }} />
                    )}
                  </div>
                );
              },
            },
            {
              title: <TableColHeader title={'제목'} subTitle={'공종'} />,
              dataIndex: 'title',
              key: 'title',
              width: 150,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>{record.title}</p>
                    {(detailModify?.contentType === 'STYLE' || detailModify?.contentType === 'PORTFOLIO') && (
                      <p style={{ fontSize: 10, color: '#999' }}>{codeMapCOTP[record.constructionType]}</p>
                    )}
                    {detailModify?.contentType === 'CONSTRUCTION_GUIDE' && (
                      <p style={{ fontSize: 10, color: '#999' }}>{codeMapCOCT[record.category]}</p>
                    )}
                  </div>
                );
              },
            },
          ]}
        />
        <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px' }}>
          <button
            style={{ backgroundColor: 'rgba(0,0,0,0)' }}
            onClick={() => {
              if (
                detailModify?.contents?.length > 1 &&
                contentGroupDetailData?.contentType &&
                contentGroupDetailData?.contentType !== detailModify?.contentType
              ) {
                Modal.warning({
                  title: '실패했습니다',
                  content: '같은 컨텐츠 타입을 가진 요소들만 리스트로 생성할 수 있습니다.',
                });
              } else {
                setDetailModify(
                  produce((draft) => {
                    draft.contents = [
                      ...draft.contents,
                      ...selectedRowKeysBeforeAdd
                        .filter((v) => !draft.contents.map((v) => v.id).includes(v))
                        .map((v) => allContentData?.content?.find((el) => el.id === v)),
                    ];
                  }),
                );
              }
            }}
          >
            <PlusCircleFilled className={styles.search_plus_button} style={{ color: '#e8e8e8', fontSize: 40 }} />
          </button>
        </div>
        <PaginateTable
          dataSource={detailModify?.contents}
          selectedRowKeys={selectedRowKeysAfterAdd}
          scroll={{ y: tableHeight - 40 }}
          setSelectedRowKeys={setSelectedRowKeysAfterAdd}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '32%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
          page={page}
          setPage={setPage}
          setPageSize={setPageSize}
          columns={[
            {
              title: '썸네일',
              dataIndex: 'thumbnailId',
              key: 'thumbnailId',
              width: 70,
              align: 'center',
              render: (data, record, index) => {
                return (
                  <div style={{ position: 'relative' }}>
                    <button
                      style={{
                        position: 'absolute',
                        top: -2,
                        right: 15,
                        zIndex: 10,
                        padding: 0,
                        width: 18,
                        height: 18,
                        border: 'none',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(255,255,255,1)',
                      }}
                      onClick={() => {
                        setDetailModify(
                          produce((draft) => {
                            // draft.contentIds=[...draft.contentIds.splice(index,1)];
                            const data = [...draft.contents];
                            data.splice(index, 1);
                            draft.contents = data;
                          }),
                        );
                      }}
                    >
                      <CloseCircleFilled style={{ fontSize: 18, color: '#1e3d83' }} />
                    </button>
                    {data ? (
                      <img
                        src={parseImageUri(data)}
                        alt="배너 이미지"
                        style={{ width: 50, height: 50, borderRadius: 10 }}
                      ></img>
                    ) : (
                      <div style={{ width: 50, height: 50 }} />
                    )}
                  </div>
                );
              },
            },
            {
              title: <TableColHeader title={'제목'} subTitle={'공종'} />,
              dataIndex: 'title',
              key: 'title',
              width: 150,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>{record.title}</p>
                    {(detailModify?.contentType === 'STYLE' || detailModify?.contentType === 'PORTFOLIO') && (
                      <p style={{ fontSize: 10, color: '#999' }}>{codeMapCOTP[record.constructionType]}</p>
                    )}
                    {detailModify?.contentType === 'CONSTRUCTION_GUIDE' && (
                      <p style={{ fontSize: 10, color: '#999' }}>{codeMapCOCT[record.category]}</p>
                    )}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default OperationMainSectionComposeDetailSearch;
