import { Checkbox, Input, Select, Switch } from 'antd';
import styled, { css } from 'styled-components';

export const TableHeader = styled.div`
  & > span {
    display: block;
    font-size: 6px;
    letter-spacing: -0.15px;

    & > .title {
      color: #000;
    }

    & > .sub_title {
      color: #a6a6a6 !important;
    }
  }
`;

export const ExtraWrapper = styled.div``;

export const MultiLineInput = styled(Input.TextArea)`
  background-color: transparent;
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  &:focus {
    border: none;
    box-shadow: none;
  }
    ${({ placeholdercolor }) => {
        return (
                placeholdercolor &&
                css`
        &::placeholder {
          color: ${placeholdercolor};
        }
      `
        );
    }};
`;

export const InputComponent = styled(Input)`
  background-color: transparent;
  width: 100%;
  border: none;

  &:focus {
    border: none;
    box-shadow: none;
  }
`;

export const FilterInput = styled(Input)`
  background-color: #e8e8e8;
  border-radius: 100px;
`;

export const FilterSelect = styled(Select)`
  display: flex;
  align-items: center;
  flex-grow: 0;
  margin-left: 20px;
  max-width: 150px !important;

  .ant-select-selector {
    border-bottom: 1px solid #b9b9b9 !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    background-color: rgba(255, 255, 255, 0) !important;
    border-radius: 0 !important;
    padding: 0px !important;

    .ant-select-selection-item {
      display: inline-block;
      width: 8px;
      font-size: 12px;
      color: #767676;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .ant-select-focused {
    box-shadow: none; /* 포커스 시 box-shadow 제거 */
    border-color: initial; /* 포커스 시 border 효과 초기값으로 설정 */
  }

  .ant-select-selection-placeholder {
    color: #222; /* 원하는 색상으로 변경 */
    font-size: 12px;
  }

  ${({ title }) => {
    return (
      title &&
      css`
        &::before {
          content: '${title}';
          white-space: nowrap;
          margin-right: 2.3px;
          font-size: 12px;
          color: #767676;
          margin-right: 10px;
        }
      `
    );
  }};
`;

export const FilterButton = styled.div`
  cursor: pointer;
  background-color: #1f3d82;
  padding: 6px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;

  color: #fff;
  font-weight: 500;
  font-size: 12px;
`;

export const FilterWhiteButton = styled.div`
  cursor: pointer;
  background-color: #fff;
  padding: 5px 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 2px solid #1f3d82;
  color: #1f3d82;
  font-weight: bold;
  font-size: 12px;
`;

export const TableCheckbox = styled(Checkbox)`
  & .label {
    font-size: 14px;
    color: rgb(118, 118, 118);
  }
`;

export const TableSwitch = styled(Switch)`
  .ant-switch-checked {
    .ant-switch {
      background-color: a6a6a6 !important;
    }
  }
`;

export const TableOrderFilter = styled.div`
  cursor: pointer;

  & > span {
    font-size: 12px;
    color: rgb(118, 118, 118);
    margin-right: 2.5px;
  }
`;
