import React, { useEffect, useState } from 'react';
import { PageHeader, Checkbox, Switch, Modal } from 'antd';
import CustomCheckBox from '../../../components/button/CustomCheckBox';
import styles from './notice.module.less';
import {
  ExtraWrapper,
  FilterButton,
  FilterInput,
  FilterSelect,
  FilterWhiteButton,
} from '../../../components/template/page';
import { Option } from 'antd/lib/mentions';
import produce from 'immer';
import moment from 'moment';
import { useGetNotice, useGetNoticeDetail } from '../../../apis/queries';
import PaginateTable from '../../../components/table/PaginateTable';
import WriteNoticeModal from '../../../components/modal/WriteNoticeModal';
import { restApi } from '../../../apis';
import { useQueryClient } from 'react-query';
import { NOTICE_LIST_KEY } from '../../../apis/queryKeys';
import { parseImageUri } from '../../../common/utils';

const ManagementNotice = (props) => {
  const queryClient = useQueryClient();
  const initialParams = React.useMemo(
    () => ({
      isShow: true,
      keyword: null,
      limit: 50,
      page: 0,
      targetPlatform: 'ALL', // ALL, USER, PARTNER
    }),
    [],
  );
  const [visibleModal, setVisibleModal] = React.useState(false);
  const [params, setParams] = React.useState(initialParams);
  const [idList, setIdList] = React.useState([]);
  const [checkedList, setCheckedList] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [keyword, setKeyword] = React.useState(null);
  const { data: noticeList } = useGetNotice({
    params,
    config: {
      onSuccess: (data) => {
        if (!selectedId) {
          setSelectedId(data?.content[0]?.id);
        }
      },
    },
  });
  const { data: noticeDetail } = useGetNoticeDetail({
    id: selectedId,
    config: {
      enabled: !!selectedId,
    },
  });
  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 280);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);
  const data = React.useMemo(() => {
    if (!noticeList) return [];
    return noticeList?.content?.map((v) => ({
      ...v,
    }));
  }, [noticeList]);

  React.useEffect(() => {
    if (!!data) {
      const idList_ = data?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [data]);

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setSelectedId(record.id);
      },
    };
  };

  const columns = [
    {
      title: '선택',
      dataIndex: 'id',
      key: 'id',
      width: 40,
      align: 'center',
      render: (v) => {
        return (
          <Checkbox
            key={`${v}_check`}
            checked={checkedList.includes(v)}
            onClick={() => {
              if (checkedList.includes(v)) {
                setCheckedList(checkedList.filter((v2) => v2 !== v));
              } else {
                setCheckedList([...checkedList, v]);
              }
            }}
          />
        );
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      width: 200,
      align: 'center',
      render: (v) => <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{v}</span>,
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 40,
      align: 'center',
      render: (v) => <span style={{ color: '#767676', fontSize: '14px' }}>{moment(v).format('YYYY.MM.DD')}</span>,
    },
  ];

  const handleDelete = () => {
    Modal.confirm({
      content: '정말 삭제하시겠습니까?',
      okText: '삭제',
      cancelText: '취소',
      onOk: () => {
        restApi
          .delete('notices', {
            data: {
              ids: checkedList,
            },
          })
          .then(async (res) => {
            await queryClient.invalidateQueries(NOTICE_LIST_KEY);
            Modal.success({
              content: '삭제되었습니다.',
              okText: '확인',
            });
          });
      },
    });
  };

  return (
    <>
      <div>
        <PageHeader className="site-page-header" title="공지사항" style={{ paddingBottom: 10 }} />
        <ExtraWrapper>
          {/* 필터 윗줄 */}
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4.5 }}>
            <FilterInput
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              style={{ width: 250, marginRight: 10 }}
              placeholder="검색어를 입력하세요"
            />
            <div>
              <FilterSelect
                value={params?.isShow}
                onChange={(v) => {
                  setParams(
                    produce((draft) => {
                      draft.isShow = v;
                    }),
                  );
                }}
                style={{ width: 150 }}
                title="게시여부"
                placeholder="전체"
              >
                <Option value={true}>게시</Option>
                <Option value={false}>대기</Option>
              </FilterSelect>
            </div>
            <div
              style={{
                marginLeft: 40,
                justifyContent: 'space-between',
                display: 'flex',
                position: 'relative',
                width: '100%',
              }}
            >
              <FilterButton
                onClick={() => {
                  setParams(
                    produce((draft) => {
                      draft.keyword = keyword;
                    }),
                  );
                }}
              >
                찾기
              </FilterButton>
              <div style={{ display: 'flex', gap: '10px' }}>
                <FilterButton
                  onClick={() => {
                    setIsEdit(true);
                    setVisibleModal(true);
                  }}
                >
                  수정하기
                </FilterButton>
                <FilterWhiteButton onClick={() => setVisibleModal(true)}>새글쓰기</FilterWhiteButton>
              </div>
            </div>
          </div>
          {/* 필터 아랫줄 */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 4.5,
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
          >
            <div className={styles.filterWrapper}>
              <CustomCheckBox
                onChange={() => {
                  if (idList.length === checkedList.length) {
                    setCheckedList([]);
                  } else {
                    setCheckedList(idList);
                  }
                }}
                key={`check_all`}
                text={'전체선택'}
                value={idList.length === checkedList.length}
              />
            </div>
            <div
              onClick={() => {
                handleDelete();
              }}
              style={{ cursor: 'pointer' }}
              className={styles.filterWrapper}
            >
              <img
                src={require('../../../assets/delete.png')}
                alt="delete"
                style={{
                  width: '18.2px',
                  height: '21.2px',
                }}
              />
              <span className={styles.filterText}>항목삭제</span>
            </div>
          </div>
        </ExtraWrapper>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PaginateTable
            dataSource={data}
            columns={columns}
            scroll={{ y: tableHeight - 40 }}
            data={noticeList}
            page={params?.page}
            setPage={(v) =>
              setParams(
                produce((draft) => {
                  draft.page = v;
                }),
              )
            }
            pageSize={params?.limit}
            setPageSize={(v) =>
              setParams(
                produce((draft) => {
                  draft.limit = v;
                }),
              )
            }
            onRow={onRow}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '65%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
            }}
          />
          <div
            style={{
              width:550,
              paddingLeft: 20,
              overflowY: 'scroll',
              overflowX: 'hidden',
              height: document.body.clientHeight - 240,
              paddingBottom: 15,
            }}
          >
            <div className={styles.detailWrapper}>
              <div className={styles.detailTitleWrapper}>
                <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{noticeDetail?.title}</span>
                <span style={{ fontSize: '12px', color: '#767676' }}>
                  {moment(noticeDetail?.createdAt).format('YYYY.MM.DD')}
                </span>
              </div>
              {noticeDetail?.contents[0]?.imageId && (
                <img
                  className={styles.imageWrapper}
                  src={parseImageUri(noticeDetail?.contents[0]?.imageId)}
                  alt={`image_${noticeDetail?.contents[0]?.imageId}`}
                />
              )}
              <div className={styles.contentWrapper}>
                <span className={styles.content}>{noticeDetail?.contents[0]?.content}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WriteNoticeModal
        visible={visibleModal}
        setVisible={setVisibleModal}
        handleCancel={() => {
          setVisibleModal(false);
          setIsEdit(false);
        }}
        isEdit={isEdit}
        selectedId={selectedId}
      />
    </>
  );
};

export default ManagementNotice;
