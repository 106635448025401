import { HeartFilled } from '@ant-design/icons';
import { PageHeader, Button, DatePicker, Input, Select, Space, Switch, Checkbox, Modal } from 'antd';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import qs from 'query-string';
import { restApi } from '../../../../apis';
import CommentIcon from '../../../../components/image/CommentIcon';
import PaginateTable from '../../../../components/table/PaginateTable';
import { proposalDummy } from '../../../../data/dummy';
import {
  formatComma,
  getClientWidth,
  getRemainTime,
  getTimeAgo,
  handleModifyWriteAccount,
  handleRequest,
  handleRequestShowAndStop,
  parseImageUri,
} from '../../../../common/utils';
import CurrentBid from '../../../../components/card/CurrentBid/CurrentBid';
import SmallUserDetail from '../../../../components/card/SmallUserDetail/SmallUserDetail';
import LocationInfo from '../../../../components/card/LocationInfo/LocationInfo';
import SmallEstimate from '../../../../components/card/SmallEstimate/SmallEstimate';
import useRenderSwitch from '../../../../components/useRenderSwitch';
import {
  ExtraWrapper,
  FilterButton,
  FilterInput,
  FilterSelect,
  FilterWhiteButton,
  TableCheckbox,
  TableSwitch,
} from '../../../../components/template/page';
import ContentSlider from '../../../../components/card/ContentSlider/ContentSlider';
import useSearchBar from '../../../../hooks/useSearchBar';
import {
  useGetConstructionRequest,
  useGetConstructionRequestDetail,
  useGetConstructionRequestProposed,
  useGetLikedConstructionRequestList,
} from '../../../../apis/queries';
import Thumbnail from '../../../../components/image/Thumbnail';
import TableColHeader from '../../../../components/table/TableColHeader';
import { useQueryClient } from 'react-query';
import styles from '../../../collections/magazines/magazines.module.less';
import useUpperLine from '../../../../hooks/useUpperLine';
import { CONSTRUCTION_REQUEST_KEY } from '../../../../apis/queryKeys';
import LowerLine from '../../../../components/LowerLine';
import ModifyAllButton from '../../../../components/button/ModifyAllButton';
import { Option } from 'antd/lib/mentions';
import produce from 'immer';

const ContentCard = React.memo(({ item, commonCodes, setVisibleDetailModal }) => {
  return (
    <div
      onClick={() => {
        setVisibleDetailModal(true);
      }}
      className={styles.slider_div}
      style={{
        cursor: 'pointer',
        backgroundImage: `url(${parseImageUri(item?.thumbnailId)})`,
      }}
    >
      <div className={styles.backdrop} />
      <div className={styles.slider_inner_div}>
        <p className={styles.category_p}>{commonCodes?.map[item?.area]}</p>
        <p className={styles.title_p}>{item?.description}</p>
        <div className={styles.flex_div}>
          <div className={styles.flex_div} style={{ alignItems: 'center' }}>
            <Thumbnail
              containerStyle={{
                width: 'max-content',
                display: 'flex',
                alignItems: 'center',
              }}
              className={styles.style_profile_img}
              id={item?.createdBy?.profileImageId}
            />
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <p className={styles.category_p} style={{ marginLeft: 10 }}>
                {item?.createdBy?.nickname}
              </p>
            </div>
          </div>
          <div className={styles.flex_div}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <HeartFilled style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p}>{item?.likeCount}</p>
            </div>
            <div
              style={{
                marginLeft: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CommentIcon style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p}>{item?.commentCount ?? 0}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
const SaveRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const pathname = location?.pathname;


  //필터 윗줄 생성(keword, filter params 포함, query param 에 spread 구문으로 삽입)
  const args = qs.parse(location?.search);
  const filters = React.useMemo(() => {
    return [
      { title: '진행구분', key: 'isRunning', options: [undefined, ['true', '진행중'], ['false', '종료']],defaultValue: args?.isRunning },
    ];
  }, []);
  const { params: upperParam, UpperLine } = useUpperLine(filters,(key,e)=>{
  console.log({pathname})
    navigate(pathname + '?' + qs.stringify({ ...args, [key]: e }));
  });

  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: 'DESC',
      ...args
    }),
    [args],
  );
  const [params, setParams] = React.useState(initialParams);
  React.useEffect(() => {
    if (upperParam) {
      setParams((prev) => ({ ...prev, ...upperParam }));
    }
  }, [upperParam]);
  const [selectedId, setSelectedId] = React.useState(undefined);

  // 모두 선택 버튼로직
  const [checkedList, setCheckedList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const { data: requestData, refetch: refetchList } = useGetLikedConstructionRequestList({
    id: id,
    params,
  });
  const { data: detail, refetch } = useGetConstructionRequestDetail({
    id: selectedId,
    config: { enabled: !!selectedId },
  });
  console.log(detail);
  const allData = React.useMemo(() => {
    return requestData?.content?.map((v) => ({
      data: v,
      id: v?.id,
    }));
  }, [requestData]);
  console.log(id);
  console.log(requestData?.data);
  React.useEffect(() => {
    if (!!allData) {
      const idList_ = allData?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [allData]);
  React.useEffect(() => {
    if (selectedId) refetch();
  }, [selectedId]);

  //상세 화면
  //   const [refreshDetail, setRefreshDetail] = React.useState();
  //   React.useEffect(() => {
  //     if (!refreshDetail) {
  //       setRefreshDetail(requestData?.content[0]?.id);
  //     }
  //   }, [requestData]);
  // const { data: requestDetailData, isFetchedAfterMount } = useGetConstructionRequestDetail({
  //   id: refreshDetail ? refreshDetail : requestData?.content[0]?.id,
  //   params: {},
  // });
  // 테이블 페이지네이션
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 380);
  const { commonCodes } = useSelector((s) => s.common);
  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 360);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);



  //테이블 col
  const COLUMNS = [
    {
      title: <TableColHeader title={'상태'} subTitle={'선택'} />,
      dataIndex: 'data',
      key: 'data',
      width: 40,
      align: 'center',
      render: (data, record) => {
        return (
          <>
            <p
              style={{
                width: 40,
                backgroundColor: !record.finishedAt ? '#a2ce76' : '#1e3d83',
                color: 'white',
                fontWeight: 'bold',
                fontSize: 10,
                padding: '2px 0 1px',
                borderRadius: 4,
                margin: '0 auto',
              }}
            >
              {!record.finishedAt ? (record?.isRunning ? '진행중' : '중지') : '종료'}
            </p>
            <Checkbox
              key={`${record.id}_check`}
              checked={checkedList.includes(record.id)}
              onClick={() => {
                if (checkedList.includes(record.id)) {
                  setCheckedList(checkedList.filter((v) => v !== record.id));
                } else {
                  setCheckedList([...checkedList, record.id]);
                }
              }}
            />
          </>
        );
      },
    },
    {
      title: '프로필',
      key: 'thumbnailId',
      dataIndex: 'thumbnailId',
      align: 'center',
      width: 60,
      render: (data, record) => {
        return (
          <Thumbnail
            // onDelete={() => {
            //   handleDeleteItems([record.id]);
            // }}
            id={record.thumbnailId}
          />
        );
      },
    },
    {
      title: <TableColHeader title={'견적명'} subTitle={'주소'} />,
      key: 'title',
      dataIndex: 'title',
      align: 'center',
      width: 150,
      render: (data, record) => (
        <>
          <TableColHeader title={data} subTitle={record.address} />
        </>
      ),
    },
    {
      title: '접수일시',
      key: 'createdAt',
      dataIndex: 'createdAt',
      align: 'center',
      width: 100,
      render: (data, record) => (
        <>
          <p style={{ color: '#767676', fontSize: 12 }}>{getTimeAgo(data)}</p>
          <p style={{ color: '#767676', fontSize: 12 }}>{moment(data).format('YYYY-MM-DD HH:mm')}</p>
        </>
      ),
    },
    {
      title: <TableColHeader title={'진행구분'} subTitle={'진행/중지'} />,
      key: 'isRunning',
      dataIndex: 'isRunning',
      align: 'center',
      width: 60,
      render: (data, record) => {
        return (
          <Switch
            disabled={!!record?.finishedAt}
            checked={!record?.finishedAt && data}
            onClick={async (e) => {
              try {
                await restApi.put(`/construction-request/is-running`, { ids: [record.id], isActive: !data });
                refetchList();
              } catch (e) {
                alert(e);
              }
              // handleModifyWriteAccount(`/accounts/partner/limit/create-proposals`, record.id, data, async () => {
              //   await queryClient.invalidateQueries([PARTMER_USER_KEY]);
              //   await queryClient.invalidateQueries([PARTMER_USER_DETAIL_KEY]);
              // });
            }}
          />
        );
      },
    },
    {
      title: '고객예산',
      key: 'totalPrice',
      dataIndex: 'totalPrice',
      align: 'center',
      width: 100,
      render: (data, record) => (
        <>
          <p style={{ color: '#1e3d83', fontSize: 14, fontWeight: 'bold' }}>{data ? formatComma(data) : 0}원</p>
        </>
      ),
    },
    {
      title: '마감처리일',
      key: 'finishedAt',
      dataIndex: 'finishedAt',
      align: 'center',
      width: 80,
      render: (data, record) => {
        if (data) {
          return (
            <span
              style={{
                fontSize: 12,
                fontWeight: 'bold',
                color: '#767676',
              }}
            >
              {data?.toLocaleString()}
            </span>
          );
        } else {
          return (
            <span
              style={{
                fontSize: 12,
                fontWeight: 'bold',
                color: 'red',
              }}
            >
              {'미확인'}
            </span>
          );
        }
      },
    },
    {
      title: '마감일시',
      key: 'finishedAt',
      dataIndex: 'finishedAt',
      align: 'center',
      width: 80,
      render: (data, record) => {
        return (
          <>
            <p style={{ color: '#767676', fontSize: 12 }}>
              {data ? getTimeAgo(data) : getRemainTime(moment(record?.createdAt).add(7, 'days'))}
            </p>
            <p style={{ color: '#767676', fontSize: 12 }}>
              {moment(data ? data : moment(record?.createdAt).add(7, 'days')).format('YYYY-MM-DD HH:mm')}
            </p>
          </>
        );
      },
    },
    {
      title: '완료처리',
      key: 'finishedAt',
      dataIndex: 'finishedAt',
      align: 'right',
      width: 100,
      render: (data, record) => {
        return (
          <FilterWhiteButton
            className={styles.completeButton}
            // style={{ width: 80 }}
            disabled={!!data}
            onClick={async () => {
              // if (!window.confirm('완료처리 하시겠습니까?')) return;
              Modal.confirm({
                title: '완료',
                content: '완료하시겠습니까?',
                okText: '완료하기',
                cancelText: '취소하기',
                async onOk() {
                  try {
                    await restApi.put(`/construction-request/finish`, { ids: [record.id], isActive: !data });
                    Modal.confirm({
                      title: '완료',
                      content: '완료되었습니다',
                      onOk() {
                        refetchList();
                      },
                      cancelButtonProps: {
                        style: { display: 'none' },
                      },
                      okText: '확인',
                    });
                  } catch (error) {
                    Modal.warning({
                      title: '실패했습니다',
                      content: error.response.data.message,
                    });
                  }
                },
              });
              // handleModifyWriteAccount(`/accounts/partner/limit/is-active`, record.id, data, async () => {
              //   await queryClient.invalidateQueries([PARTMER_USER_KEY]);
              //   await queryClient.invalidateQueries([PARTMER_USER_DETAIL_KEY]);
              // });
            }}
          >
            완료처리
          </FilterWhiteButton>
        );
      },
    },
  ];
  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setSelectedId(record?.id);
      },
    };
  };

  return (
    <div>
      <div>
        <PageHeader className="site-page-header" title="공사도움받기" style={{ paddingBottom: 10 }} />
        <ExtraWrapper>
          {/* 필터 윗줄 */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 4.5,

            }}
          >
            <UpperLine containerStyle={{width:'65%'}} listKey={CONSTRUCTION_REQUEST_KEY} detailKey={CONSTRUCTION_REQUEST_KEY} />
          </div>
          {/* 필터 아랫줄 */}
          <div style={{ width: '65%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <LowerLine
              onCheck={() => {
                if (idList.length === checkedList.length) {
                  setCheckedList([]);
                } else {
                  setCheckedList(idList);
                }
              }}
              checked={idList.length === checkedList.length}
              left={<ModifyAllButton
                  showText={'게시'}
                  stopText={'중지'}
                  customStyle={{ marginLeft: 40 }}
                  showAction={() => {
                    handleRequestShowAndStop('/construction-request/is-running', checkedList, true, () => {
                      refetchList();
                    });
                  }}
                  stopAction={() => {
                    handleRequestShowAndStop('/construction-request/is-running', checkedList, false, () => {
                      refetchList();
                    });
                  }}
              />}
            >

            </LowerLine>
            <div style={{width:170}}>
            <FilterSelect
              value={params?.order}
              onChange={(v) =>
                setParams(
                  produce((draft) => {
                    draft.order = v;
                  }),
                )
              }
            >
              <Option value="DESC">저장일시 최신순</Option>
            </FilterSelect>
          </div>
          </div>
        </ExtraWrapper>
      </div>

      <div class={styles.estimate_table} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PaginateTable
          dataSource={requestData?.data?.content}
          columns={COLUMNS}
          scroll={{ y: tableHeight - 40 }}
          data={requestData?.data}
          page={params?.page}
          setPage={(v) =>
            setParams(
              produce((draft) => {
                draft.page = v;
              }),
            )
          }
          pageSize={params?.limit}
          setPageSize={(v) =>
            setParams(
              produce((draft) => {
                draft.limit = v;
              }),
            )
          }
          onRow={onRow}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '65%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
        />
        {detail && (
          <div
            style={{
              maxWidth: 550,
              overflowY: 'scroll',
              overflowX: 'hidden',
              height: document.body.clientHeight - 240,
              paddingLeft: 20,
              boxSizing: 'border-box',
              paddingBottom: 15,
            }}
          >
            <CurrentBid
              data={detail}
              isFirst={true}
              onStop={async () => {
                Modal.confirm({
                  title: '완료',
                  content: '완료하시겠습니까?',
                  okText: '완료하기',
                  cancelText: '취소하기',
                  async onOk() {
                    try {
                      await restApi.put(`/construction-request/finish`, { ids: [selectedId], isActive: false });
                      Modal.confirm({
                        title: '완료',
                        content: '완료되었습니다',
                        onOk() {
                          refetchList();
                        },
                        cancelButtonProps: {
                          style: { display: 'none' },
                        },
                        okText: '확인',
                      });
                    } catch (error) {
                      Modal.warning({
                        title: '실패했습니다',
                        content: '실패했습니다',
                      });
                    }
                  },
                });
              }}
            />
            <SmallUserDetail data={detail} tree={commonCodes} user={detail?.estimateInfo?.createdBy} />
            <LocationInfo data={detail?.estimateInfo} />
            <div style={{ marginTop: 30 }}></div>
            <ContentSlider leftLine={true}>
              {detail?.estimateInfo?.areas?.map((v) => (
                <ContentCard setVisibleDetailModal={() => {}} item={v?.subContent} commonCodes={commonCodes} />
              ))}
            </ContentSlider>
            <SmallEstimate data={detail?.estimateInfo} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SaveRequest;
