export const dummyData = [
  {
    id: 1,
    key: 1,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: true,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 2,
    key: 2,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 3,
    key: 3,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 4,
    key: 4,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 5,
    key: 5,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 6,
    key: 6,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 7,
    key: 7,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 8,
    key: 8,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 2,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
];
export const proposalDummy = [
  {
    id: 1,
    key: 1,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: true,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 2,
    key: 2,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 3,
    key: 3,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 4,
    key: 4,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 5,
    key: 5,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 6,
    key: 6,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 7,
    key: 7,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 8,
    key: 8,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
  {
    id: 9,
    key: 9,
    username: '김철수',
    nickname: '백마탄환자',
    address: '경기도 성남시 이매동',
    created_at: '2023-02-01 12:23:35',
    hasAuth: false,
    grade: '3',
    estimate: 25,
    isSaved: 3,
    help_cnt: 3,
    apply_cnt: 0,
    point: 50000,
  },
];

export const dummyDataAgencyGuide = [
  {
    id: 9,
    key: 9,
    positions: ['실크도배 시공시 주의사항', '도배'],
    update: ['산들벽지', '2021-10-08'],
    recommend: true,
    modify: true,
  },
  {
    id: 9,
    key: 9,
    positions: ['실크도배 시공시 주의사항', '도배'],
    update: ['산들벽지', '2021-10-08'],
    recommend: true,
    modify: true,
  },
  {
    id: 9,
    key: 9,
    positions: ['실크도배 시공시 주의사항', '도배'],
    update: ['산들벽지', '2021-10-08'],
    recommend: true,
    modify: true,
  },
  {
    id: 9,
    key: 9,
    positions: ['실크도배 시공시 주의사항', '도배'],
    update: ['산들벽지', '2021-10-08'],
    recommend: true,
    modify: true,
  },
  {
    id: 9,
    key: 9,
    positions: ['실크도배 시공시 주의사항', '도배'],
    update: ['산들벽지', '2021-10-08'],
    recommend: true,
    modify: true,
  },
  {
    id: 9,
    key: 9,
    positions: ['실크도배 시공시 주의사항', '도배'],
    update: ['산들벽지', '2021-10-08'],
    recommend: true,
    modify: true,
  },
  {
    id: 9,
    key: 9,
    positions: ['실크도배 시공시 주의사항', '도배'],
    update: ['산들벽지', '2021-10-08'],
    recommend: true,
    modify: true,
  },
  {
    id: 9,
    key: 9,
    positions: ['실크도배 시공시 주의사항', '도배'],
    update: ['산들벽지', '2021-10-08'],
    recommend: true,
    modify: true,
  },
  {
    id: 9,
    key: 9,
    positions: ['실크도배 시공시 주의사항', '도배'],
    update: ['산들벽지', '2021-10-08'],
    recommend: true,
    modify: true,
  },
  {
    id: 9,
    key: 9,
    positions: ['실크도배 시공시 주의사항', '도배'],
    update: ['산들벽지', '2021-10-08'],
    recommend: true,
    modify: true,
  },
  {
    id: 9,
    key: 9,
    positions: ['실크도배 시공시 주의사항', '도배'],
    update: ['산들벽지', '2021-10-08'],
    recommend: true,
    modify: true,
  },
  {
    id: 9,
    key: 9,
    positions: ['asdasdsadasd', '도배'],
    update: ['산들벽지', '2021-10-08'],
    recommend: true,
    modify: true,
  },
];

export const dummyDataExpert = [
  {
    id: 9,
    key: 9,
    positions: ['이거 누수인가요?', '바닥'],
    create_At: ['돼지아빠', '2021-10-08'],
    recommend: true,
    modify: true,
  },
  {
    id: 9,
    key: 9,
    positions: ['이거 누수인가요?', '바닥'],
    create_At: ['돼지아빠', '2021-10-08'],
    recommend: true,
    modify: true,
  },
  {
    id: 9,
    key: 9,
    positions: ['이거 누수인가요?', '바닥'],
    create_At: ['돼지아빠', '2021-10-08'],
    recommend: true,
    modify: true,
  },
];
