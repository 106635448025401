import React from 'react';
import styles from './adminDetail.module.less';
import { Modal, Switch } from 'antd';
import {
  CloseOutlined,
  FormOutlined,
  HeartFilled,
  ShopOutlined,
  StarFilled,
  StarOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import useReviewModal from '../../../hooks/useReviewModal';
import { CommonCodeContext } from '../../../context/CommonCodeContext';
import { parseImageUri } from '../../../common/utils';
import assets from '../../../assets';
import { useGetAccountAdminDetail, useGetPartnerUserDetail, useGetRegions } from '../../../apis/queries';
import { useQueryClient } from 'react-query';
import { ADMIN_LIST_DETAIL_KEY, ADMIN_LIST_KEY, PARTMER_USER_DETAIL_KEY } from '../../../apis/queryKeys';
import { restApi } from '../../../apis';
import useBusinessNumberModal from '../../../hooks/useBusinessNumberModal';
import { useNavigate } from 'react-router-dom';
import { FilterWhiteButton } from '../../../components/template/page';
import produce from 'immer';
const AdminUserDetail = ({ userType, detailId }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: partnerDetailData, isFetchedAfterMount } = useGetAccountAdminDetail({
    id: detailId,
    params: {},
  });
  const [teamInput, setTeamInput] = React.useState(false);
  console.log(teamInput);
  const [roleInput, setRoleInput] = React.useState(false);
  const [staffIdInput, setStaffIdInput] = React.useState(false);
  const [detailModify, setDetailModify] = React.useState({
    permissions: partnerDetailData?.permissions ?? [],
    profileImageId: partnerDetailData?.profileImageId ?? null,
    role: partnerDetailData?.role ?? null,
    staffId: partnerDetailData?.staffId ?? null,
    team: partnerDetailData?.team ?? null,
  });
  const [modifyPermissions, setModifyPermissions] = React.useState(partnerDetailData?.permissions ?? []);
  console.log(detailModify);
  React.useEffect(() => {
    setDetailModify(
      produce((draft) => {
        draft.permissions = partnerDetailData?.permissions;
        draft.profileImageId = partnerDetailData?.profileImageId;
        draft.role = partnerDetailData?.role;
        draft.staffId = partnerDetailData?.staffId;
        draft.team = partnerDetailData?.team;
      }),
      setModifyPermissions(partnerDetailData?.permissions),
    );
  }, [partnerDetailData]);

  const adminDetailList = [
    { title: '홈', code: 'ADPM001', isChild: false },
    { title: '회원관리', code: 'ADPM002', isChild: false },
    { title: '소비자', code: 'ADPM003', isChild: true },
    { title: '시공사(전체공사)', code: 'ADPM004', isChild: true },
    { title: '시공사(부분공사)', code: 'ADPM005', isChild: true },
    { title: '제조/유통사', code: 'ADPM006', isChild: true },
    { title: '인플루언서', code: 'ADPM007', isChild: true },
    { title: '공사도움받기', code: 'ADPM008', isChild: false },
    { title: '실시간 견적 의뢰 리스트', code: 'ADPM009', isChild: true },
    { title: '서비스 비용 설정', code: 'ADPM010', isChild: true },
    { title: 'CM상품관리', code: 'ADPM011', isChild: false },
    { title: '카테고리관리', code: 'ADPM012', isChild: true },
    { title: '상품등록관리', code: 'ADPM013', isChild: true },
    { title: '컨텐츠 관리', code: 'ADPM014', isChild: false },
    { title: '글쓰기 관리', code: 'ADPM015', isChild: false },
    { title: '앱운영관리', code: 'ADPM016', isChild: false },
    { title: '견적알고리즘관리', code: 'ADPM017', isChild: false },
    { title: '공종카테고리관리', code: 'ADPM018', isChild: true },
    { title: '견적템플릿작성', code: 'ADPM019', isChild: true },
    { title: '간접공사비율 및 주기관리', code: 'ADPM020', isChild: true },
    { title: '공지사항관리', code: 'ADPM021', isChild: false },
    { title: '통계', code: 'ADPM022', isChild: false },
    { title: '설정', code: 'ADPM023', isChild: false },
    { title: '직원관리', code: 'ADPM024', isChild: true },
    { title: '서비스이용가이드관리', code: 'ADPM025', isChild: true },
  ];
  return (
    <>
      <h3 style={{ margin: '0 0 5px' }}>고객정보</h3>
      <div className={styles.user_detail}>
        <div
          className={styles.side_line}
          style={{ backgroundColor: userType === 'agencies' ? '#1e3d83' : '#7eccde' }}
        ></div>
        <div style={{ paddingBottom: 10, flex: 1 }}>
          <div style={{ display: 'flex', alignItems: 'center', padding: '20px 50px' }}>
            <div style={{ marginRight: '20px' }}>
              <div
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '50%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <img
                  src={
                    partnerDetailData?.profileImageId
                      ? parseImageUri(partnerDetailData?.profileImageId)
                      : assets.icons.blank_profile
                  }
                  style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                />
              </div>
            </div>
            <div>
              <p className={styles.name}>{partnerDetailData?.nickname}</p>
              <p className={styles.email}>{partnerDetailData?.email}</p>
              <p className={styles.phone}>{partnerDetailData?.phone}</p>
            </div>
          </div>
          <div style={{ padding: '10px 30px' }}>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
              <p style={{ color: '#767676', fontWeight: 'bold' }}>닉네임</p>
              <p style={{ display: 'flex', alignContent: 'center', color: '#1e3d83', fontWeight: 'bold' }}>
                <span>{partnerDetailData?.nickname}</span>
                <p style={{ width: 28 }}></p>
              </p>
            </div>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
              <p style={{ color: '#767676', fontWeight: 'bold' }}>본명</p>
              <p style={{ display: 'flex', alignContent: 'center', color: '#1e3d83', fontWeight: 'bold' }}>
                <span>{partnerDetailData?.name}</span>
                <p style={{ width: 28 }}></p>
              </p>
            </div>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
              <p style={{ color: '#767676', fontWeight: 'bold' }}>소속팀</p>
              <p style={{ display: 'flex', alignContent: 'center', color: '#1e3d83', fontWeight: 'bold' }}>
                {teamInput ? (
                  <input
                    type="text"
                    style={{
                      border: 'none',
                      borderBottom: '1px solid #d9d9d9',
                      textAlign: 'center',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                    value={detailModify?.team}
                    onChange={(e) => {
                      setDetailModify(
                        produce((draft) => {
                          draft.team = e.target.value;
                        }),
                      );
                    }}
                  />
                ) : (
                  <span>{detailModify?.team}</span>
                )}
                <button
                  onClick={() => {
                    setTeamInput((prev) => !prev);
                    setRoleInput(false);
                    setStaffIdInput(false);
                  }}
                >
                  {teamInput ? (
                    <CloseOutlined style={{ fontSize: 16, color: '#767676' }} />
                  ) : (
                    <FormOutlined style={{ fontSize: 16, color: '#767676' }} />
                  )}
                </button>
              </p>
            </div>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
              <p style={{ color: '#767676', fontWeight: 'bold' }}>직책</p>
              <p style={{ display: 'flex', alignContent: 'center', color: '#1e3d83', fontWeight: 'bold' }}>
                {roleInput ? (
                  <input
                    type="text"
                    style={{
                      border: 'none',
                      borderBottom: '1px solid #d9d9d9',
                      textAlign: 'center',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                    value={detailModify?.role}
                    onChange={(e) => {
                      setDetailModify(
                        produce((draft) => {
                          draft.role = e.target.value;
                        }),
                      );
                    }}
                  />
                ) : (
                  <span>{detailModify?.role}</span>
                )}
                <button
                  onClick={() => {
                    setTeamInput(false);

                    setRoleInput((prev) => !prev);
                    setStaffIdInput(false);
                  }}
                >
                  {roleInput ? (
                    <CloseOutlined style={{ fontSize: 16, color: '#767676' }} />
                  ) : (
                    <FormOutlined style={{ fontSize: 16, color: '#767676' }} />
                  )}
                </button>
              </p>
            </div>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
              <p style={{ color: '#767676', fontWeight: 'bold' }}>사번</p>
              <p style={{ display: 'flex', alignContent: 'center', color: '#1e3d83', fontWeight: 'bold' }}>
                {staffIdInput ? (
                  <input
                    type="text"
                    style={{
                      border: 'none',
                      borderBottom: '1px solid #d9d9d9',
                      textAlign: 'center',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                    value={detailModify?.staffId}
                    onChange={(e) => {
                      setDetailModify(
                        produce((draft) => {
                          draft.staffId = e.target.value;
                        }),
                      );
                    }}
                  />
                ) : (
                  <span>{detailModify?.staffId}</span>
                )}
                <button
                  onClick={() => {
                    setTeamInput(false);
                    setRoleInput(false);
                    setStaffIdInput((prev) => !prev);
                  }}
                >
                  {staffIdInput ? (
                    <CloseOutlined style={{ fontSize: 16, color: '#767676' }} />
                  ) : (
                    <FormOutlined style={{ fontSize: 16, color: '#767676' }} />
                  )}
                </button>
              </p>
            </div>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
              <p style={{ color: '#767676', fontWeight: 'bold' }}>연락처</p>
              <p style={{ display: 'flex', alignContent: 'center', color: '#1e3d83', fontWeight: 'bold' }}>
                <span>{partnerDetailData?.phone}</span>
                <p style={{ width: 28 }}></p>
              </p>
            </div>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
              <p style={{ color: '#767676', fontWeight: 'bold' }}>이메일</p>
              <p style={{ display: 'flex', alignContent: 'center', color: '#1e3d83', fontWeight: 'bold' }}>
                <span>{partnerDetailData?.email}</span>
                <p style={{ width: 28 }}></p>
              </p>
            </div>
            <div
              style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', margin: '50px 0 0 0' }}
            >
              <p style={{ color: '#767676', fontWeight: 'bold' }}>접근권한</p>
              <p style={{ display: 'flex', alignContent: 'center', color: '#1e3d83', fontWeight: 'bold' }}></p>
            </div>
          </div>
          <div>
            {adminDetailList?.map((item, index) => {
              return (
                <div
                  key={`myDetail ${index}`}
                  className={styles.my_page_list}
                  style={item?.isChild ? { marginLeft: 40 } : { marginLeft: 20 }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', width: '60%' }}>
                    <span style={{ color: '#767676' }}>{item.title}</span>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', height: 34, marginLeft: 'auto' }}>
                    <Switch
                      checked={modifyPermissions?.includes(item.code)}
                      onClick={() => {
                        if (modifyPermissions?.includes(item.code)) {
                          setModifyPermissions(modifyPermissions.filter((v) => v !== item.code));
                        } else {
                          setModifyPermissions([...modifyPermissions, item.code]);
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ width: '30%', margin: '20px auto 10px' }}>
            <FilterWhiteButton
              onClick={async () => {
                try {
                  await restApi.put(`/accounts/admin/${detailId}`, {
                    permissions: modifyPermissions,
                    profileImageId: detailModify?.profileImageId,
                    role: detailModify?.role,
                    staffId: detailModify?.staffId,
                    team: detailModify?.team,
                  });
                  Modal.success({
                    content: '성공적으로 저장했습니다',
                    onOk: () => {
                      queryClient.invalidateQueries(ADMIN_LIST_DETAIL_KEY);
                      queryClient.invalidateQueries(ADMIN_LIST_KEY);
                      setTeamInput(false);
                      setRoleInput(false);
                      setStaffIdInput(false);
                    },
                  });
                } catch (error) {
                  Modal.error({
                    title: '실패',
                    content: error?.response?.data?.message,
                  });
                }
              }}
            >
              저장하기
            </FilterWhiteButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUserDetail;
