import React from 'react';

const TableColHeader = ({ title, subTitle }) => {
  return (
    <div style={{ height: 34, paddingTop: 0 }}>
      <p style={{ width: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{title}</p>
      <span style={{ fontSize: 10, color: '#999',whiteSpace:'nowrap',maxWidth:'100%',display:'inline-block',textOverflow:'ellipsis',overflow:'hidden' }}>{subTitle}</span>
    </div>
  );
};

export default TableColHeader;
