import React from 'react';
import CustomCheckBox from './button/CustomCheckBox';

const LowerLine = ({ onCheck, checked, children, containerStyle,left }) => {
  return (
    <div
      style={{
        width: 870,
        display: 'flex',
        justifyContent:'space-between',
        alignItems: 'center',
        position: 'relative',
        padding: '10px 0 13px 0',
        ...containerStyle,
      }}
    >
      <div style={{display:'flex'}}>
      <CustomCheckBox onClick={onCheck} text={'모두선택'} key={`check_all`} checked={checked} />
      {left}
      </div>
      {children}
    </div>
  );
};

export default LowerLine;
