import {useGetConsultingList, useGetConsultingListDetail} from '../../apis/queries';
import {HeartFilled} from '@ant-design/icons';
import {Modal, PageHeader} from 'antd';
import {Option} from 'antd/lib/mentions';
import produce from 'immer';
import moment from 'moment';
import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {CONSULTING_LIST_KEY,CONSULTING_LIST_DETAIL_KEY} from '../../apis/queryKeys';

import {formatComma, parseImageUri} from '../../common/utils';
import ContentSlider from '../../components/card/ContentSlider/ContentSlider';
import LocationInfo from '../../components/card/LocationInfo/LocationInfo';
import SmallEstimate
  from '../../components/card/SmallEstimate/SmallEstimate';
import CommentIcon from '../../components/image/CommentIcon';
import Thumbnail from '../../components/image/Thumbnail';
import ShowEstimateModal from '../../components/modal/ShowEstimateModal';
import PaginateTable from '../../components/table/PaginateTable';
import TableColHeader from '../../components/table/TableColHeader';
import {ExtraWrapper, FilterSelect} from '../../components/template/page';
import useUpperLine from '../../hooks/useUpperLine';
import styles from '../expert/proposal/index.module.less';


const ContentCard = React.memo(({ item, commonCodes, setVisibleDetailModal }) => {
  return (
    <div
      onClick={() => {
        setVisibleDetailModal(true);
      }}
      className={styles.slider_div}
      style={{
        cursor: 'pointer',
        backgroundImage: `url(${parseImageUri(item?.thumbnailId)})`,
      }}
    >
      <div className={styles.backdrop} />
      <div className={styles.slider_inner_div}>
        <p className={styles.category_p}>{commonCodes?.map[item?.area]}</p>
        <p className={styles.title_p}>{item?.description}</p>
        <div className={styles.flex_div}>
          <div className={styles.flex_div} style={{ alignItems: 'center' }}>
            <Thumbnail
              containerStyle={{
                width: 'max-content',
                display: 'flex',
                alignItems: 'center',
              }}
              className={styles.style_profile_img}
              id={item?.createdBy?.profileImageId}
            />
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <p className={styles.category_p} style={{ marginLeft: 10 }}>
                {item?.createdBy?.nickname}
              </p>
            </div>
          </div>
          <div className={styles.flex_div}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <HeartFilled style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p}>{item?.likeCount}</p>
            </div>
            <div
              style={{
                marginLeft: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CommentIcon style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p}>{item?.commentCount ?? 0}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
const ConsultingListIndex = () => {
  const { commonCodes } = useSelector((s) => s.common);
  const { params: upperParam, UpperLine } = useUpperLine();
  const [selectedId, setSelectedId] = React.useState(null);
  const [isOpenEstimateModal, setIsOpenEstimateModal] = React.useState(false);
  const [isProposalModal, setIsProposalModal] = React.useState(false);
  const [filter, setFilter] = React.useState('createdAt_DESC');
  const {id} = useParams()
  const initialParmas = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: 'DESC',
      sort: 'createdAt',
      keyword: null,
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParmas);
  const { data: realtimeData } = useGetConsultingList({ params: {  ...params, ...upperParam },accountId :id });
  console.log(realtimeData);
  const { data: detail } = useGetConsultingListDetail({
    id: selectedId,
    config: {
      enabled: !!selectedId,
    },
  });
  console.log(detail);
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 380);
  React.useEffect(() => {
    if (upperParam) {
      const { keyword } = upperParam;
      setParams(
        produce((draft) => {
          draft.keyword = keyword;
        }),
      );
    }
  }, [upperParam]);

  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 360);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);
  const filterItem = React.useMemo(
    () => [
      {
        label: '최신순',
        value: 'createdAt_DESC',
      },
      {
        label: '만료순',
        value: 'createdAt_ASC',
      },
      {
        label: '예산 높은순',
        value: 'totalPrice_DESC',
      },
      {
        label: '예산 낮은순',
        value: 'totalPrice_ASC',
      },
      {
        label: '입찰순',
        value: 'proposalCount_DESC',
      },
    ],
    [],
  );

  const columns = [
    {
      title: '썸네일',
      key: 'thumbnailId',
      dataIndex: 'thumbnailId',
      align: 'center',
      width: 60,
      render: (data, record) => {
        return <Thumbnail id={record.thumbnailId} />;
      },
    },
    {
      title: <TableColHeader title={'견적명'} subTitle={'주소'} />,
      key: 'title',
      dataIndex: 'title',
      align: 'left',
      width: 200,
      render: (data, record) => (
        <>
          <TableColHeader title={data} subTitle={record.address} />
        </>
      ),
    },
    {
      title: '작성일시',
      key: 'createdAt',
      dataIndex: 'createdAt',
      align: 'center',
      width: 100,
      render: (data, record) => (
        <>
          <p style={{ color: '#767676', fontSize: 12 }}>{moment(data).format('YYYY-MM-DD HH:mm')}</p>
        </>
      ),
    },

    {
      title: '견적금액',
      key: 'totalPrice',
      dataIndex: 'totalPrice',
      align: 'center',
      width: 100,
      render: (data, record) => (
        <>
          <p style={{ color: '#1e3d83', fontSize: 14, fontWeight: 'bold' }}>{data ? formatComma(data) : 0}</p>
        </>
      ),
    },
  ];
  const onRow = (record) => {
    return {
      onClick: () => {
        setSelectedId(record.id);
      },
    };
  };

  return (
    <>
      <div>
        <div>
          <PageHeader className="site-page-header" title="컨설팅 리스트" style={{ paddingBottom: 10 }} />
          <ExtraWrapper>
            {/* 필터 윗줄 */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 4.5,
              }}
            >
              <UpperLine
                listKey={CONSULTING_LIST_KEY}
                detailKey={CONSULTING_LIST_DETAIL_KEY}
                // setRefresh={setRefreshDetail}
              />

            </div>
            {/* 필터 아랫줄 */}
            <div
              style={{
                width: '65%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <FilterSelect
                style={{ width: 100 }}
                value={filter}
                onChange={(v) => {
                  const [sort, order] = v.split('_');
                  setParams(
                    produce((draft) => {
                      draft.sort = sort;
                      draft.order = order;
                    }),
                  );
                  setFilter(v);
                }}
              >
                {filterItem?.map((v) => (
                  <Option value={v.value}>{v.label}</Option>
                ))}
              </FilterSelect>
            </div>
          </ExtraWrapper>
        </div>

        <div class={styles.estimate_table} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PaginateTable
            dataSource={realtimeData?.content}
            columns={columns}
            scroll={{ y: tableHeight - 40 }}
            data={realtimeData}
            page={params?.page}
            setPage={(v) =>
              setParams(
                produce((draft) => {
                  draft.page = v;
                }),
              )
            }
            pageSize={params?.limit}
            setPageSize={(v) =>
              setParams(
                produce((draft) => {
                  draft.limit = v;
                }),
              )
            }
            onRow={onRow}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '65%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
            }}
          />
          {detail && (
            <div
              style={{
                width:500,
                overflowY: 'scroll',
                overflowX: 'hidden',
                height: document.body.clientHeight - 240,
                paddingInline: 20,
                boxSizing: 'border-box',
                paddingBottom: 15,
              }}
            >
              {/* <SmallUserDetail isFirst data={detail} tree={commonCodes} user={detail?.estimateInfo?.createdBy} /> */}
              <SmallEstimate visibleModal={() => setIsOpenEstimateModal(true)} data={detail?.estimateInfo} />
              <LocationInfo data={detail?.estimateInfo} />
              <div style={{ marginTop: 30 }}></div>
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  marginBottom: '10px',
                }}
              >
                선택 스타일 정보
              </span>
              <ContentSlider leftLine={true}>
                {detail?.estimateInfo?.areas?.map((v) => (
                  <ContentCard setVisibleDetailModal={() => {}} item={v?.subContent} commonCodes={commonCodes} />
                ))}
              </ContentSlider>
            </div>
          )}
        </div>
      </div>
      <ShowEstimateModal
        visible={isOpenEstimateModal}
        setVisible={setIsOpenEstimateModal}
        data={detail}
        handleCancel={() => setIsOpenEstimateModal(false)}
      />

    </>
  );
};

export default ConsultingListIndex;
