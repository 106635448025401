import React, { useMemo } from 'react';
import styles from './ExpertTable.module.less';
import { Col, Row, Table } from 'antd';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';
import { comma } from '../../common/utils';

const ProposalTable = styled(Table)`
  thead .ant-table-cell {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 0.5px;
  }
  .ant-table-thead > tr > th:first-child {
    border-left: none;
  }
  .ant-table-thead > tr > th:last-child {
    border-right: none;
  }
  td.ant-table-cell {
    padding-inline: 0;
    border-color: #ddd;
  }
`;

let customerTotalPrice;
let proposalTotalPrice;

// const CUSTOMER_QUOTATION = [
//   [
//     {
//       category: '거실',
//       name: '바닥재 타일',
//       item: 'A 66046',
//       item_category: '자재비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 1900,
//       amount: 384996,
//     },
//     {
//       category: '거실',
//       name: '타일 부자재',
//       item: '',
//       item_category: '인건비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 1500,
//       amount: 296150,
//     },
//     {
//       category: '거실',
//       name: '타일 인건비',
//       item: '',
//       item_category: '인건비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 35000,
//       amount: 791018,
//     },
//   ],
//   [
//     {
//       category: '거실',
//       name: '바닥재 타일',
//       item: 'A 66046',
//       item_category: '자재비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 1900,
//       amount: 384996,
//     },
//     {
//       category: '거실',
//       name: '타일 부자재',
//       item: '',
//       item_category: '인건비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 1500,
//       amount: 296150,
//     },
//     {
//       category: '거실',
//       name: '타일 인건비',
//       item: '',
//       item_category: '인건비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 35000,
//       amount: 791018,
//     },
//   ],
//   [
//     {
//       category: '거실',
//       name: '바닥재 타일',
//       item: 'A 66046',
//       item_category: '자재비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 1900,
//       amount: 384996,
//     },
//     {
//       category: '거실',
//       name: '타일 부자재',
//       item: '',
//       item_category: '인건비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 1500,
//       amount: 296150,
//     },
//     {
//       category: '거실',
//       name: '타일 인건비',
//       item: '',
//       item_category: '인건비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 35000,
//       amount: 791018,
//     },
//   ],
// ];

// const COLUMNS = [
//   {
//     title: '구분',
//     dataIndex: 'category',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Category_${v?.id}`}>{v?.category}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '명칭',
//     dataIndex: 'name',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Name_${v?.id}`}>{v?.name}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow}>
//             <span>소계</span>
//           </div>
//         </div>
//       );
//     },
//   },
//   {
//     title: '적용자재',
//     dataIndex: 'item',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Item_${v?.id}`}>{v?.item}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '계정구분',
//     dataIndex: 'item_category',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Item_Category_${v?.id}`}>{v?.item_category}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '단위',
//     dataIndex: 'unit',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Unit_${v?.id}`}>{v?.unit}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '수량',
//     dataIndex: 'quantity',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Quantity_${v?.id}`}>{v?.quantity}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '단가',
//     dataIndex: 'price',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Price_${v?.id}`}>{v?.price}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '금액',
//     dataIndex: 'amount',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Amount_${v?.id}`}>{v?.amount}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow}>
//             <span>{array?.[0]?.amount + array?.[1]?.amount + array?.[2]?.amount}</span>
//           </div>
//         </div>
//       );
//     },
//   },
// ];

// const COMPANY_QUOTATION = [
//   [
//     {
//       category: '거실',
//       name: '바닥재 타일',
//       item: 'A 66046',
//       item_category: '자재비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 1900,
//       amount: 384996,
//       item_category_proposal: '자재비',
//       unit_proposal: 'M2',
//       quantity_proposal: 19.74,
//       price_proposal: 19500,
//       amount_proposal: 384996,
//     },
//     {
//       category: '거실',
//       name: '타일 부자재',
//       item: '',
//       item_category: '인건비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 1500,
//       amount: 296150,
//       item_category_proposal: '인건비',
//       unit_proposal: 'M2',
//       quantity_proposal: 19.74,
//       price_proposal: 1500,
//       amount_proposal: 296150,
//     },
//     {
//       category: '거실',
//       name: '타일 인건비',
//       item: '',
//       item_category: '인건비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 35000,
//       amount: 791018,
//       item_category_proposal: '인건비',
//       unit_proposal: 'M2',
//       quantity_proposal: 19.74,
//       price_proposal: 35000,
//       amount_proposal: 791018,
//     },
//   ],
//   [
//     {
//       category: '거실',
//       name: '바닥재 타일',
//       item: 'A 66046',
//       item_category: '자재비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 1900,
//       amount: 384996,
//       item_category_proposal: '자재비',
//       unit_proposal: 'M2',
//       quantity_proposal: 19.74,
//       price_proposal: 19500,
//       amount_proposal: 384996,
//     },
//     {
//       category: '거실',
//       name: '타일 부자재',
//       item: '',
//       item_category: '인건비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 1500,
//       amount: 296150,
//       item_category_proposal: '인건비',
//       unit_proposal: 'M2',
//       quantity_proposal: 19.74,
//       price_proposal: 1500,
//       amount_proposal: 296150,
//     },
//     {
//       category: '거실',
//       name: '타일 인건비',
//       item: '',
//       item_category: '인건비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 35000,
//       amount: 791018,
//       item_category_proposal: '인건비',
//       unit_proposal: 'M2',
//       quantity_proposal: 19.74,
//       price_proposal: 35000,
//       amount_proposal: 791018,
//     },
//   ],
//   [
//     {
//       category: '거실',
//       name: '바닥재 타일',
//       item: 'A 66046',
//       item_category: '자재비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 1900,
//       amount: 384996,
//       item_category_proposal: '자재비',
//       unit_proposal: 'M2',
//       quantity_proposal: 19.74,
//       price_proposal: 19500,
//       amount_proposal: 384996,
//     },
//     {
//       category: '거실',
//       name: '타일 부자재',
//       item: '',
//       item_category: '인건비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 1500,
//       amount: 296150,
//       item_category_proposal: '인건비',
//       unit_proposal: 'M2',
//       quantity_proposal: 19.74,
//       price_proposal: 1500,
//       amount_proposal: 296150,
//     },
//     {
//       category: '거실',
//       name: '타일 인건비',
//       item: '',
//       item_category: '인건비',
//       unit: 'M2',
//       quantity: 19.74,
//       price: 35000,
//       amount: 791018,
//       item_category_proposal: '인건비',
//       unit_proposal: 'M2',
//       quantity_proposal: 19.74,
//       price_proposal: 35000,
//       amount_proposal: 791018,
//     },
//   ],
// ];

// const COLUMNS2 = [
//   {
//     title: '구분',
//     dataIndex: 'category',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Category_${v?.id}`}>{v?.category}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '명칭',
//     dataIndex: 'name',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Name_${v?.id}`}>{v?.name}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow}>
//             <span>소계</span>
//           </div>
//         </div>
//       );
//     },
//   },
//   {
//     title: '적용자재',
//     dataIndex: 'item',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Item_${v?.id}`}>{v?.item}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },

//   {
//     title: '계정구분',
//     dataIndex: 'item_category',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Item_Category_${v?.id}`}>{v?.item_category}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '단위',
//     dataIndex: 'unit',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Unit_${v?.id}`}>{v?.unit}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '수량',
//     dataIndex: 'quantity',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Quantity_${v?.id}`}>{v?.quantity}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '단가',
//     dataIndex: 'price',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Price_${v?.id}`}>{v?.price}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '금액',
//     dataIndex: 'amount',
//     align: 'center',
//     render: (v, array) => {
//       customerTotalPrice = array?.[0]?.amount + array?.[1]?.amount + array?.[2]?.amount;
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Amount_${v?.id}`}>{v?.amount}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow}>
//             <strong>{customerTotalPrice}</strong>
//           </div>
//         </div>
//       );
//     },
//   },
//   {
//     title: '계정구분',
//     dataIndex: 'item_category_proposal',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Item_Category_Propposal_${v?.id}`}>{v?.item_category_proposal}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '단위',
//     dataIndex: 'unit_proposal',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Unit_Proposal_${v?.unit_proposal}`}>{v?.unit}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '수량',
//     dataIndex: 'quantity_proposal',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Quantity_Proposal_${v?.id}`}>{v?.quantity_proposal}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '단가',
//     dataIndex: 'price_proposal',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Price_Proposal_${v?.id}`}>{v?.price_proposal}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow} />
//         </div>
//       );
//     },
//   },
//   {
//     title: '금액',
//     dataIndex: 'amount_proposal',
//     align: 'center',
//     render: (v, array) => {
//       proposalTotalPrice = array?.[0]?.amount + array?.[1]?.amount + array?.[2]?.amount;
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Amount_Proposal_${v?.id}`}>{v?.amount_proposal}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow}>
//             <strong>{proposalTotalPrice}</strong>
//           </div>
//         </div>
//       );
//     },
//   },
//   {
//     title: '차액',
//     align: 'center',
//     render: (v, array) => {
//       return (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           {array?.map((v) => {
//             return (
//               <div style={{ height: 20 }}>
//                 <span key={`Difference_${v?.id}`}>{v?.amount - v?.amount_proposal}</span>
//               </div>
//             );
//           })}
//           <div className={styles.subTitleRow}>
//             <span>{customerTotalPrice - proposalTotalPrice}</span>
//           </div>
//         </div>
//       );
//     },
//   },
// ];

export default function ExpertTable({ title, tab, type }) {
  const { estimateTpls } = useSelector((s) => s.common, shallowEqual);
  console.log({ tab });

  const tpls = useMemo(() => {
    return estimateTpls?.filter((tpls) => tpls.main_category.name === title);
  }, [estimateTpls, title]);
  return (
    <>
      {type === 'customer' ? (
        // TODO: 데이터 변경 필요
        <div>
          <div className={styles.contentTitle}>
            <div style={{ marginBottom: 20 }}>
              <span>총 견적 금액</span>
              <span>33,950,000</span>
              <span>원</span>
            </div>
            <div>
              <span>{`${title}소계`}</span>
              <span>33,950,000</span>
              <span>원</span>
            </div>
          </div>
          {/* <ProposalTable dataSource={CUSTOMER_QUOTATION} columns={COLUMNS} style={{ marginBottom: 100 }} /> */}
          <table>
            <thead style={{ textAlign: 'center', height: 40 }}>
              <td style={{ width: 150 }}>구분</td>
              <td>명칭</td>
              <td>적용자재</td>
              <td>계정구분</td>
              <td>단위</td>
              <td>수량</td>
              <td>단가</td>
              <td>금액</td>
            </thead>
            {tpls?.map((item) => {
              let totalPrice;
              let nowPrice = item.main_category.order_index * item.price;
              totalPrice = nowPrice + nowPrice + nowPrice;
              return (
                <>
                  <tbody style={{ borderTop: '1px solid', textAlign: 'center' }}>
                    <td>-</td>
                    <td>{item.side_category.name}</td>
                    <td>{item.use_code?.[0] ?? '-'}</td>
                    <td>{item.use_product ? '자재비' : '인건비'}</td>
                    <td>{item.print_mode}</td>
                    <td>{item.main_category.order_index}</td>
                    <td>{comma(item.price) ?? 0}</td>
                    <td>{comma(nowPrice)}</td>
                  </tbody>
                  <tbody style={{ textAlign: 'center' }}>
                    <td>-</td>
                    <td>{item.side_category.name}</td>
                    <td>{item.use_code?.[0] ?? '-'}</td>
                    <td>{item.use_product ? '자재비' : '인건비'}</td>
                    <td>{item.print_mode}</td>
                    <td>{item.main_category.order_index}</td>
                    <td>{comma(item.price) ?? 0}</td>
                    <td>{comma(nowPrice)}</td>
                  </tbody>
                  <tbody style={{ textAlign: 'center' }}>
                    <td>-</td>
                    <td>{item.side_category.name}</td>
                    <td>{item.use_code?.[0] ?? '-'}</td>
                    <td>{item.use_product ? '자재비' : '인건비'}</td>
                    <td>{item.print_mode}</td>
                    <td>{item.main_category.order_index}</td>
                    <td>{comma(item.price) ?? 0}</td>
                    <td>{comma(nowPrice)}</td>
                  </tbody>
                  <tbody style={{ borderTop: '1px solid #e6e6e6', textAlign: 'center' }}>
                    <td></td>
                    <td style={{ padding: '10px 0' }}>소계</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{comma(totalPrice)}</td>
                  </tbody>
                </>
              );
            })}
          </table>
        </div>
      ) : (
        // TODO: 데이터 변경 필요
        <div>
          <div>
            <div>
              {/* <ProposalTable dataSource={COMPANY_QUOTATION} columns={COLUMNS2} style={{ marginBottom: 100 }} /> */}
              <table style={{ textAlign: 'center' }}>
                <thead>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: 600 }}>소비자 견적</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: 600 }}>34,000,000 원</td>
                  <td style={{ fontWeight: 600 }}>제안견적</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: 600 }}>34,000,000 원</td>
                  <td></td>
                </thead>
                <thead style={{ textAlign: 'center', height: 40 }}>
                  <td>구분</td>
                  <td>명칭</td>
                  <td>적용자재</td>
                  <td>계정구분</td>
                  <td>단위</td>
                  <td>수량</td>
                  <td>단가</td>
                  <td>금액</td>

                  <td>계정구분</td>
                  <td>단위</td>
                  <td>수량</td>
                  <td>단가</td>
                  <td>금액</td>
                  <td>차액</td>
                </thead>
                {tpls?.map((item) => {
                  let totalPrice;
                  let nowPrice = item.main_category.order_index * item.price;
                  totalPrice = nowPrice + nowPrice + nowPrice;
                  return (
                    <>
                      <tbody style={{ borderTop: '1px solid', textAlign: 'center' }}>
                        <td>-</td>
                        <td>{item.side_category.name}</td>
                        <td>{item.use_code?.[0] ?? '-'}</td>
                        <td>{item.use_product ? '자재비' : '인건비'}</td>
                        <td>{item.print_mode}</td>
                        <td>{item.main_category.order_index}</td>
                        <td>{comma(item.price) ?? 0}</td>
                        <td>{comma(nowPrice)}</td>
                        <td>{item.use_product ? '자재비' : '인건비'}</td>
                        <td>{item.print_mode}</td>
                        <td>{item.main_category.order_index}</td>
                        <td>{comma(item.price) ?? 0}</td>
                        <td>{comma(nowPrice)}</td>
                        <td>0</td>
                      </tbody>
                      <tbody style={{ textAlign: 'center' }}>
                        <td>-</td>
                        <td>{item.side_category.name}</td>
                        <td>{item.use_code?.[0] ?? '-'}</td>
                        <td>{item.use_product ? '자재비' : '인건비'}</td>
                        <td>{item.print_mode}</td>
                        <td>{item.main_category.order_index}</td>
                        <td>{comma(item.price) ?? 0}</td>
                        <td>{comma(nowPrice)}</td>
                        <td>{item.use_product ? '자재비' : '인건비'}</td>
                        <td>{item.print_mode}</td>
                        <td>{item.main_category.order_index}</td>
                        <td>{comma(item.price) ?? 0}</td>
                        <td>{comma(nowPrice)}</td>
                        <td>0</td>
                      </tbody>
                      <tbody style={{ textAlign: 'center' }}>
                        <td>-</td>
                        <td>{item.side_category.name}</td>
                        <td>{item.use_code?.[0] ?? '-'}</td>
                        <td>{item.use_product ? '자재비' : '인건비'}</td>
                        <td>{item.print_mode}</td>
                        <td>{item.main_category.order_index}</td>
                        <td>{comma(item.price) ?? 0}</td>
                        <td>{comma(nowPrice)}</td>
                        <td>{item.use_product ? '자재비' : '인건비'}</td>
                        <td>{item.print_mode}</td>
                        <td>{item.main_category.order_index}</td>
                        <td>{comma(item.price) ?? 0}</td>
                        <td>{comma(nowPrice)}</td>
                        <td>0</td>
                      </tbody>
                      <tbody style={{ borderTop: '1px solid #e6e6e6', textAlign: 'center' }}>
                        <td></td>
                        <td style={{ padding: '10px 0', fontSize: 13 }}>소계</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontWeight: 600, fontSize: 13 }}>{comma(totalPrice)}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontWeight: 600, fontSize: 13 }}>{comma(totalPrice)}</td>
                        <td style={{ fontWeight: 600, fontSize: 13 }}>0</td>
                      </tbody>
                    </>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
