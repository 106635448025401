import React from 'react';
import { Button, Card, Col, Form, Input, Modal, PageHeader, Row, Tree } from 'antd';
import { restApi } from '../../../apis';
import styles from './estimate_categories.module.less';
import { useGetConstructionCategories } from '../../../apis/queries';
import { useQueryClient } from 'react-query';
import { CONSTRUCTION_CATEGORIES_KEY } from '../../../apis/queryKeys';
import produce from 'immer';

const TreeComponent = React.memo(
  ({ queryClient, title, id, setVisibleModal, setSelectedId, deletable = false, setIsEdit = () => {} }) => {
    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', width: '100%' }}>
        <div
          onClick={() => {
            setIsEdit(true);
            setVisibleModal(true);
            setSelectedId(id);
          }}
          style={{ flex: 1 }}
        >
          {title}
        </div>
        <div className={styles.buttonWrapper}>
          <div
            onClick={(e) => {
              setVisibleModal(true);
              setSelectedId(id);
            }}
            className={styles.leftButton}
          >
            <span className={styles.buttonText}>하위추가</span>
          </div>
          <div
            onClick={() => {
              deletable &&
                Modal.confirm({
                  content: '해당 카테고리를 삭제하시겠습니까?',
                  okText: '확인',
                  cancelText: '취소',
                  onOk: () => {
                    restApi.delete(`/construction-categories/${id}`).then(async (res) => {
                      await queryClient.invalidateQueries(CONSTRUCTION_CATEGORIES_KEY);
                      Modal.success({
                        content: '삭제되었습니다.',
                        okText: '확인',
                        onOk: () => {
                          setVisibleModal(false);
                          setSelectedId(null);
                        },
                      });
                    });
                  },
                });
            }}
            className={styles.rightButton}
            style={{
              backgroundColor: deletable ? '#de7e7e' : '#e8e8e8',
              cursor: deletable ? 'pointer' : 'not-allowed',
            }}
          >
            <span className={styles.buttonText}>삭제</span>
          </div>
        </div>
      </div>
    );
  },
);

const SettingsEstimateCategoriesIndex = () => {
  const { data: constructionCategories } = useGetConstructionCategories();
  const [visibleModal, setVisibleModal] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const initialForm = React.useMemo(
    () => ({
      categoryName: null,
      description: null,
    }),
    [],
  );
  const [form, setForm] = React.useState(initialForm);
  const queryClient = useQueryClient();

  const treeData = React.useMemo(() => {
    const data = constructionCategories?.tree;

    const returnChildren = (items) => {
      if (items?.children?.length > 0) {
        return {
          title: () => (
            <TreeComponent
              setVisibleModal={setVisibleModal}
              setSelectedId={setSelectedId}
              title={items.categoryName}
              id={items?.categoryId}
              queryClient={queryClient}
              setIsEdit={setIsEdit}
              deletable
            />
          ),
          key: items.categoryId,
          children: items?.children?.map((v) => returnChildren(v)),
        };
      }
      return {
        title: () => (
          <TreeComponent
            setVisibleModal={setVisibleModal}
            setSelectedId={setSelectedId}
            title={items.categoryName}
            id={items?.categoryId}
            queryClient={queryClient}
            setIsEdit={setIsEdit}
            deletable
          />
        ),
        key: items.categoryId,
        children: null,
      };
    };

    return [
      {
        title: () => (
          <TreeComponent setVisibleModal={setVisibleModal} id={null} setSelectedId={setSelectedId} title="전체" />
        ),
        key: 'all',
        children: data?.map((v) => returnChildren(v)),
      },
    ];
  }, [constructionCategories, setVisibleModal, setSelectedId]);

  const handleSubmit = React.useCallback(() => {
    const { categoryName, description } = form;
    if (!categoryName) {
      Modal.warn({
        content: '카테고리명을 입력해 주세요.',
        okText: '확인',
      });
    } else {
      const api = isEdit
        ? () => restApi.put(`/construction-categories/${selectedId}`, { categoryName })
        : () =>
            restApi.post('/construction-categories', {
              categoryName,
              parentCategoryId: selectedId,
            });

      api().then(async (res) => {
        await queryClient.invalidateQueries(CONSTRUCTION_CATEGORIES_KEY);
        Modal.success({
          content: `카테고리가 ${isEdit ? '수정' : '생성'}되었습니다.`,
          okText: '확인',
          onOk: () => {
            setVisibleModal(false);
            setSelectedId(null);
            setForm(initialForm);
          },
        });
      });
    }
  }, [selectedId, form, isEdit]);
  React.useEffect(() => {
    if (isEdit && !!selectedId) {
      setForm(
        produce((draft) => {
          draft.categoryName = constructionCategories?.map[selectedId];
        }),
      );
    }
  }, [isEdit, selectedId]);
  return (
    <div style={{flexDirection:'column',display:'flex'}}>
      <Modal
        width={450}
        bodyStyle={{
          backgroundColor: '#f9f9f9',
          display: 'flex',
          flexDirection: 'column',
          padding: '30px 20px',
        }}
        open={visibleModal}
        onOk={handleSubmit}
        onCancel={() => {
          setVisibleModal(false);
          setSelectedId(null);
          setIsEdit(false);
          setForm(initialForm);
        }}
        destroyOnClose={true}
        okText={'확인'}
        cancelText="취소"
      >
        <Form
          style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
        >
          <Form.Item label="카테고리명">
            <Input
              value={form?.categoryName}
              onChange={(e) =>
                setForm(
                  produce((draft) => {
                    draft.categoryName = e.target.value;
                  }),
                )
              }
            />
          </Form.Item>
          <Form.Item label="설명">
            <Input
              value={form?.description}
              onChange={(e) =>
                setForm(
                  produce((draft) => {
                    draft.description = e.target.value;
                  }),
                )
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <PageHeader
        className="site-page-header"
        title="공사 카테고리 관리"
        style={{ width: 980, marginLeft: 'auto', marginRight: 'auto' }}
      />
      <div className={styles.category_div}>
        <div className={styles.category_header}>
          <div className={styles.category_header_title_section}>
            <span>대분류 / 중분류</span>
          </div>
          <div className={styles.category_header_title_section}>
            <span>하위추가 / 삭제</span>
          </div>
        </div>
        <div style={{ maxWidth: 1280, backgroundColor: 'red', height: '100%' }}>
          <Row gutter={10}>
            <Col span={10}>
              <Card>
                <div className={styles.tree_container_div}>
                  <Tree defaultExpandedKeys={['all']} treeData={treeData} showLine />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SettingsEstimateCategoriesIndex;
