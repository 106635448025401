import React from 'react';
import {useSelector} from 'react-redux';
import Thumbnail from '../../image/Thumbnail';
import styles from './SmallUserDetail.module.less';
import { PageHeader } from 'antd';
import assets from '../../../assets';

const SmallUserDetail = ({ data, isFirst, user }) => {
  const { commonCodes } = useSelector((s) => s.common);
  return (
    <>
      <h3 style={{ marginTop: isFirst ? 0 : 20, marginBottom: 5 }}>고객정보</h3>
      <div className={styles.small_user_container}>
        <div className={styles.side_line} />
        <div style={{ padding: '30px 10px' }}>
          <div style={{ display: 'flex', alignItems: 'center', padding: '20px 50px' }}>
            <div style={{ marginRight: '20px' }}>
              <div
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '50%',
                  // backgroundColor: 'green',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Thumbnail
                  containerStyle={{ width: '60px', height: '60px', borderRadius: '50%' }}
                  imageStyle={{ width: '60px', height: '60px', borderRadius: '50%' }}
                  id={user?.profileImageId}
                ></Thumbnail>
              </div>
              <div className={styles.label}>인테리어 {commonCodes?.map[user?.estimateRate]}</div>
            </div>
            <div>
              <p className={styles.name}>{user?.nickname}</p>
              <p className={styles.email}>{user?.email}</p>
              {user?.phone && (
                <p className={styles.phone}>{user?.phone?.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}</p>
              )}
              <p className={styles.email}>공사예정일 : {data?.constructionDate}</p>
            </div>
          </div>
          <div className={styles.life_container}>
            <div key={`life 1`} style={{ display: 'flex', width: '27%', marginLeft: '6%', alignItems: 'center' }}>
              <div style={{ width: '20px', height: '20px', marginRight: '4px' }}>
                <img src={assets.lifeStyle.generation} alt="" style={{ width: '100%' }} />
              </div>
              <div className={styles.life_item}>
                {commonCodes?.tree?.['SUVA']?.find((v) => v.code === user?.lifestyle?.generation)?.extra1}
              </div>
            </div>
            <div key={`life 2`} style={{ display: 'flex', width: '27%', marginLeft: '6%', alignItems: 'center' }}>
              <div style={{ width: '20px', height: '20px', marginRight: '4px' }}>
                <img src={assets.lifeStyle.job} alt="" style={{ width: '100%' }} />
              </div>
              <div className={styles.life_item}>
                {commonCodes?.tree?.['SUVB']?.find((v) => v.code === user?.lifestyle?.job)?.extra1}
              </div>
            </div>
            <div key={`life 3`} style={{ display: 'flex', width: '27%', marginLeft: '6%', alignItems: 'center' }}>
              <div style={{ width: '20px', height: '20px', marginRight: '4px' }}>
                <img src={assets.lifeStyle.households} alt="" style={{ width: '100%' }} />
              </div>
              <div className={styles.life_item}>
                {commonCodes?.tree?.['SUVC']?.find((v) => v.code === user?.lifestyle?.households)?.extra1}
              </div>
            </div>
            <div key={`life 4`} style={{ display: 'flex', width: '27%', marginLeft: '6%', alignItems: 'center' }}>
              <div style={{ width: '20px', height: '20px', marginRight: '4px' }}>
                <img src={assets.lifeStyle.families} alt="" style={{ width: '100%' }} />
              </div>
              <div className={styles.life_item}>
                {commonCodes?.tree?.['SUVD']?.find((v) => v.code === user?.lifestyle?.families)?.extra1}
              </div>
            </div>
            <div key={`life 5`} style={{ display: 'flex', width: '27%', marginLeft: '6%', alignItems: 'center' }}>
              <div style={{ width: '20px', height: '20px', marginRight: '4px' }}>
                <img src={assets.lifeStyle.activityRate} alt="" style={{ width: '100%' }} />
              </div>
              <div className={styles.life_item}>
                {commonCodes?.tree?.['SUVE']?.find((v) => v.code === user?.lifestyle?.activityRate)?.extra1}
              </div>
            </div>
            <div key={`life 6`} style={{ display: 'flex', width: '27%', marginLeft: '6%', alignItems: 'center' }}>
              <div style={{ width: '20px', height: '20px', marginRight: '4px' }}>
                <img src={assets.lifeStyle.interiorLiking} alt="" style={{ width: '100%' }} />
              </div>
              <div className={styles.life_item}>
                {commonCodes?.tree?.['SUVF']?.find((v) => v.code === user?.lifestyle?.interiorLiking)?.extra1}
              </div>
            </div>
          </div>
          <div className={styles.content_box}>{user?.lifestyle?.feedback}</div>
        </div>
      </div>
    </>
  );
};

export default SmallUserDetail;
