import { Checkbox, Col, Modal, PageHeader, Row, Radio, DatePicker, Space, Table, Switch, Spin } from 'antd';
import React from 'react';
import PaginateTable from '../../../components/table/PaginateTable';
import UserDetail from '../../../components/card/userdetail/UserDetail';
import qs from 'query-string';
import { OPERATION_MAIN_POPUP_LIST } from '../../../data/dummy/operation';
import {
  ExtraWrapper,
  FilterButton,
  FilterInput,
  FilterSelect,
  TableCheckbox,
  TableHeader,
  TableOrderFilter,
  TableSwitch,
} from '../../../components/template/page';
import ImageWithClear from '../../../components/image/ImageWithClear';
import CommonStatistics from '../../../components/card/CommonStatistics,js/CommonStatistics';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './ads.module.less';
import modalImage from '../../../assets/dummy/02/02-01.jpg';
import { CloseCircleFilled, SyncOutlined } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import { useGetAds, useGetAdsDetail, useGetConstructionCategories } from '../../../apis/queries';
import produce from 'immer';
import { getFileId, handleModifyShow, handleShowAndStop, parseImageUri } from '../../../common/utils';
import TableColHeader from '../../../components/table/TableColHeader';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ADS_LIST_DETAIL_KEY, ADS_LIST_KEY } from '../../../apis/queryKeys';
import { restApi } from '../../../apis';
import ModifyAllButton from '../../../components/button/ModifyAllButton';
import AddAdsModal from './AddAdsModal';
import useSearchBar from '../../../hooks/useSearchBar';
import useUpperLine from '../../../hooks/useUpperLine';
import LowerLine from '../../../components/LowerLine';
import { Option } from 'antd/lib/mentions';
const OperationAdsIndex = () => {
  const { RangePicker } = DatePicker;
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: categoryTree } = useGetConstructionCategories();

  //필터 윗줄 생성(keword, filter params 포함, query param 에 spread 구문으로 삽입)
  const filters = React.useMemo(() => {
    return [{ title: '게시여부', key: 'isShow', options: [undefined, [true, '게시'], [false, '중지']] }];
  }, []);
  const { params: upperParam, UpperLine } = useUpperLine(filters);

  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: 'DESC',
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  React.useEffect(() => {
    if (upperParam) {
      setParams((prev) => ({ ...prev, ...upperParam }));
    }
  }, [upperParam]);

  // 모두 선택 버튼로직
  const [checkedList, setCheckedList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const { data: adsData } = useGetAds({ params });
  const allData = React.useMemo(() => {
    return adsData?.content?.map((v) => ({
      data: v,
      id: v?.id,
    }));
  }, [adsData]);
  React.useEffect(() => {
    if (!!allData) {
      const idList_ = allData?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [allData]);

  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    if (imageId) {
      setDetailModify(
        produce((draft) => {
          draft.imageId = imageId;
        }),
      );
    }
  };
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 60 - 280);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isListModal, setIsListModal] = React.useState(false);
  const [radioValue, setRadioValue] = React.useState(2);
  const [refreshDetail, setRefreshDetail] = React.useState();
  console.log('refreshDetail', adsData?.content[0]?.id);
  React.useEffect(() => {
    if (!refreshDetail) {
      setRefreshDetail(adsData?.content[0]?.id);
    }
  }, [adsData]);
  const { data: adsDetailData, isFetchedAfterMount } = useGetAdsDetail({
    id: refreshDetail ? refreshDetail : adsData?.content[0]?.id,
    params: {},
  });

  //모달창에서 입력할 값
  const [detailModify, setDetailModify] = React.useState({
    title: adsData?.title ?? '',
    categoryId: adsData?.category?.categoryId ?? null,
    imageId: adsData?.imageId ?? null,
    sortNum: adsData?.sortNum ?? null,
    startAt: adsData?.startAt ?? undefined,
    endAt: adsData?.endAt ?? undefined,
    isShow: adsData?.isShow ?? null,
    isLimit: adsData?.isLimit ?? null,
    sameCategoryCount: adsData?.sameCategoryCount ?? null,
    probability: adsData?.probability ?? null,
  });
  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  //모달창 키면 값이 매핑됨
  const showModal = () => {
    setRadioValue(adsDetailData?.isLimit ? 2 : 1);
    setDetailModify(
      produce((draft) => {
        draft.title = adsDetailData?.title;
        draft.categoryId = adsDetailData?.category.categoryId;
        draft.imageId = adsDetailData?.imageId;
        draft.sortNum = adsDetailData?.sortNum;
        draft.startAt = adsDetailData?.startAt ? adsDetailData?.startAt : undefined;
        draft.endAt = adsDetailData?.endAt ? adsDetailData?.endAt : undefined;
        draft.isShow = adsDetailData?.isShow;
        draft.isLimit = adsDetailData?.isLimit;
        draft.sameCategoryCount = adsDetailData?.sameCategoryCount;
        draft.probability = adsDetailData?.probability;
      }),
    );
    setIsModalOpen(true);
  };
  const handleOk = () => {
    Modal.confirm({
      title: '수정',
      content: '해당 내용으로 수정하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      async onOk() {
        try {
          await restApi.put(`/estimate-ads/${refreshDetail}`, detailModify);
          Modal.confirm({
            title: '완료',
            content: '수정이 완료되었습니다',
            onOk() {
              queryClient.invalidateQueries([ADS_LIST_KEY]);
              setIsModalOpen(false);
            },
            cancelButtonProps: {
              style: { display: 'none' },
            },
            okText: '확인',
          });
        } catch (error) {
          Modal.warning({
            title: '실패했습니다',
            content: error.response.data.message,
          });
        }
      },
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const radioChange = (e) => {
    if (e.target.value === 1) {
      setDetailModify(
        produce((draft) => {
          draft.startAt = undefined;
          draft.endAt = undefined;
          draft.isLimit = false;
        }),
      );
    } else {
      setDetailModify(
        produce((draft) => {
          draft.isLimit = true;
        }),
      );
    }
    setRadioValue(e.target.value);
  };

  const onRow = (record) => {
    return {
      onClick: (event) => {
        setRefreshDetail(record.id);
        queryClient.invalidateQueries([ADS_LIST_DETAIL_KEY]);
      },
    };
  };
  return (
    <div>
      <PageHeader className="site-page-header" title="견적 광고" style={{ paddingBottom: 10 }} />
      <ExtraWrapper>
        {/* 필터 윗줄 */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 4.5 }}>
          <UpperLine containerStyle={{width:'65%'}} listKey={ADS_LIST_KEY} detailKey={ADS_LIST_DETAIL_KEY} setRefresh={setRefreshDetail} />
          <FilterButton
            onClick={() => {
              setIsListModal(true);
            }}
          >
            새로 작성
          </FilterButton>
        </div>
        {/* 필터 아랫줄 */}
        <div style={{
          width: '65%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <LowerLine
              onCheck={() => {
                if (idList.length === checkedList.length) {
                  setCheckedList([]);
                } else {
                  setCheckedList(idList);
                }
              }}
              checked={idList.length === checkedList.length}
              left={<ModifyAllButton
                  showText={'모두 게시'}
                  stopText={'모두 중지'}
                  showAction={() => {
                    handleShowAndStop('/estimate-ads/show', checkedList, true,
                        () => {
                          queryClient.invalidateQueries([ADS_LIST_KEY]);
                        });
                  }}
                  stopAction={() => {
                    handleShowAndStop('/estimate-ads/show', checkedList, false,
                        () => {
                          queryClient.invalidateQueries([ADS_LIST_KEY]);
                        });
                  }}
              />}
          >

          </LowerLine>
          <div style={{width:170}}>
          <FilterSelect
              value={params?.order}
              onChange={(v) =>
                  setParams(
                      produce((draft) => {
                        draft.order = v;
                      }),
                  )
              }
          >
            <Option value="DESC">저장일시 최신순</Option>
          </FilterSelect>
          </div>
        </div>
      </ExtraWrapper>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <PaginateTable
          dataSource={adsData?.content}
          data={adsData}
          scroll={{ y: tableHeight - 40 }}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '65%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
          onRow={onRow}
          page={params?.page}
          setPage={(v) =>
            setParams(
              produce((draft) => {
                draft.page = v;
              }),
            )
          }
          pageSize={params?.limit}
          setPageSize={(v) =>
            setParams(
              produce((draft) => {
                draft.limit = v;
              }),
            )
          }
          columns={[
            {
              title: '선택',
              dataIndex: 'data',
              key: 'data',
              width: 20,
              align: 'center',
              render: (data, record) => {
                return (
                  <>
                    <Checkbox
                      key={`${record.id}_check`}
                      checked={checkedList.includes(record.id)}
                      onClick={() => {
                        if (checkedList.includes(record.id)) {
                          setCheckedList(checkedList.filter((v) => v !== record.id));
                        } else {
                          setCheckedList([...checkedList, record.id]);
                        }
                      }}
                    />
                  </>
                );
              },
            },
            {
              title: '썸네일',
              width: 30,
              dataIndex: 'imageId',
              key: 'imageId',
              align: 'center',
              render: (data, record) => {
                return (
                  <div style={{ position: 'relative' }}>
                    {data ? (
                      <img
                        src={parseImageUri(data)}
                        alt="배너 이미지"
                        style={{ width: 50, height: 50, borderRadius: 10 }}
                      ></img>
                    ) : (
                      <div style={{ width: 50, height: 50 }} />
                    )}
                    <button
                      style={{
                        position: 'absolute',
                        top: -2,
                        right: -5,
                        zIndex: 10,
                        padding: 0,
                        width: 18,
                        height: 18,
                        border: 'none',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(255,255,255,1)',
                      }}
                      onClick={() => {
                        Modal.confirm({
                          title: '삭제',
                          content: '해당 글을 삭제하시겠습니까?',
                          okText: '확인',
                          cancelText: '취소',
                          async onOk() {
                            try {
                              await restApi.delete(`/estimate-ads/${record?.id}`);
                              Modal.confirm({
                                title: '완료',
                                content: '삭제가 완료되었습니다',
                                async onOk() {
                                  await queryClient.invalidateQueries([ADS_LIST_KEY]);
                                  await queryClient.invalidateQueries([ADS_LIST_DETAIL_KEY]);
                                  setRefreshDetail(adsData?.content[0]?.id);
                                },
                                cancelButtonProps: {
                                  style: { display: 'none' },
                                },
                                okText: '확인',
                              });
                            } catch (error) {
                              Modal.warning({
                                title: '실패했습니다',
                                content: error.response.data.message,
                              });
                            }
                          },
                        });
                      }}
                    >
                      <CloseCircleFilled style={{ fontSize: 18, color: '#1e3d83' }} />
                    </button>
                  </div>
                );
              },
            },
            {
              title: <TableColHeader title={'제목'} subTitle={'공종'} />,
              dataIndex: 'title',
              key: 'title',
              width: 150,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>{record.title}</p>
                    <p style={{ fontSize: 11, color: '#999' }}>{record.category?.categoryName}</p>
                  </div>
                );
              },
            },
            {
              title: '게시일',
              dataIndex: 'startAt',
              key: 'startAt',
              width: 60,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>
                      {data ? (
                        data
                      ) : record.isShow ? (
                        <span style={{ fontWeight: 'bold', color: '#1e3d83' }}>영구게시</span>
                      ) : (
                        <span style={{ fontWeight: 'bold', color: 'red' }}>게시중지</span>
                      )}
                    </p>
                  </div>
                );
              },
            },
            {
              title: '만료일',
              dataIndex: 'endAt',
              key: 'endAt',
              width: 60,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>
                      {data ? (
                        data
                      ) : record.isShow ? (
                        <span style={{ fontWeight: 'bold', color: '#1e3d83' }}>영구게시</span>
                      ) : (
                        <span style={{ fontWeight: 'bold', color: 'red' }}>게시중지</span>
                      )}
                    </p>
                  </div>
                );
              },
            },
            {
              title: '순서',
              dataIndex: 'sortNum',
              key: 'sortNum',
              width: 30,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>{data}</p>
                  </div>
                );
              },
            },
            {
              title: <TableColHeader title={'동일공종게시물수'} subTitle={'표출확률(%)'} />,
              dataIndex: 'sameCategoryCount',
              key: 'sameCategoryCount',
              width: 30,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>{record.sameCategoryCount}</p>
                    <p>{Number(record.probability).toFixed(1)}%</p>
                  </div>
                );
              },
            },
            {
              title: <TableColHeader title={'게시여부'} subTitle={'게시/중지'} />,
              dataIndex: 'isShow',
              key: 'isShow',
              width: 30,
              align: 'center',
              render: (data, record) => {
                return (
                  <Switch
                    checked={data}
                    onClick={() => {
                      handleModifyShow(`/estimate-ads/show`, record.id, data, () => {
                        queryClient.invalidateQueries([ADS_LIST_KEY]);
                      });
                    }}
                  />
                );
              },
            },
          ]}
        />
        <div
          style={{

            paddingLeft: 20,
            overflowY: 'scroll',
            height: document.body.clientHeight - 240,
            paddingBottom: 15,
          }}
        >
          <p style={{ marginBottom: 15 }}>미리보기</p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: 20,
              paddingTop: 180,
              backgroundColor: 'white',
              height: 900,
            }}
          >
            {isFetchedAfterMount ? (
              <div style={{ backgroundColor: '#e8e8e8', height: '480px', borderRadius: 10, overflow: 'hidden' }}>
                <div style={{ backgroundColor: '#e8e8e8', height: '480px', backgroundColor: 'black' }}>
                  {adsDetailData?.imageId && (
                    <img
                      src={parseImageUri(adsDetailData?.imageId)}
                      alt="배너 이미지"
                      style={{ width: '100%', height: 480 }}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: '#e8e8e8',
                  height: '240px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Spin size="large" />
              </div>
            )}
            <div style={{ textAlign: 'center' }}>
              <button
                style={{ marginBottom: 20, padding: '3px 18px', borderRadius: '20px', border: '1.5px solid #1e3d83' }}
                onClick={showModal}
              >
                수정
              </button>
              <p className={styles.preview_text}>※ 미리보기는 참고용입니다. 모바일에서 실제 화면을 확인하세요.</p>
            </div>
          </div>
          <CommonStatistics />
        </div>
      </div>
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        okText={'수정하기'}
        cancelText={'취소하기'}
      >
        <p
          style={{
            margin: '40px 46.5px 28px 50px',
            fontSize: 24,
            fontWeight: 600,
          }}
        >
          견적광고 수정
        </p>
        <div
          style={{
            width: 400,
            height: 800,
            backgroundColor: 'white',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 100,
            padding: 20,
          }}
        >
          <div
            style={{
              position: 'relative',
              width: 150,
              height: 150,
              marginLeft: 'auto',
              marginRight: 'auto',
              backgroundImage: `url(${parseImageUri(detailModify?.imageId)})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundColor: 'rgba(0,0,0,0.15)',
              borderRadius: 10,
            }}
          >
            <button
              style={{
                width: 50,
                height: 50,
                backgroundColor: 'rgba(0,0,0,0.2)',
                padding: 10,
                position: 'absolute',
                top: 3,
                right: 3,
                borderRadius: '50%',
              }}
            >
              <input
                type="file"
                id="fileInput"
                style={{
                  width: '100%',
                  height: '100%',
                  opacity: 0,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  cursor: 'pointer',
                }}
                onChange={handleChangeImage}
              />
              <SyncOutlined style={{ fontSize: 30, color: '#1e3d83' }} />
            </button>
          </div>
          <input
            type="text"
            style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '30px 0' }}
            placeholder="제목을 입력해주세요."
            value={detailModify?.title}
            onChange={(e) => {
              setDetailModify(
                produce((draft) => {
                  draft.title = e.target.value;
                }),
              );
            }}
          />
          <select
            style={{
              padding: 5,
              width: '100%',
              textAlign: 'center',
              border: 'none',
              borderBottom: '1px solid #d9d9d9',
            }}
            value={detailModify?.categoryId}
            onChange={(e) => {
              setDetailModify(
                produce((draft) => {
                  draft.categoryId = e.target.value;
                }),
              );
            }}
          >
            {categoryTree?.tree?.map((el) => {
              return <option value={el.categoryId}>{el.categoryName}</option>;
            })}
          </select>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <span>컨텐츠 연결</span>
            <button
              style={{ border: '1.5px solid #1e3d83', padding: '4px 18px', borderRadius: '15px' }}
              onClick={() => {
                setIsListModal(true);
              }}
            >
              리스트 생성
            </button>
          </div>
          <input
            type="text"
            style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '30px 0' }}
            placeholder="순서"
            value={detailModify?.sortNum}
            onChange={(e) => {
              setDetailModify(
                produce((draft) => {
                  draft.sortNum = e.target.value;
                }),
              );
            }}
          />
          <p style={{ fontWeight: 'bold', color: '#1e3d83' }}>게시기간 설정</p>
          <Radio.Group
            style={{ width: '100%', textAlign: 'center', margin: '30px 0' }}
            onChange={radioChange}
            value={radioValue}
          >
            <Radio value={1} style={{ marginRight: 50 }}>
              영구
            </Radio>
            <Radio value={2}>기간설정</Radio>
          </Radio.Group>
          <div style={{ marginBottom: 10 }}>
            <RangePicker
              disabled={radioValue == 1 ? true : false}
              value={[moment(detailModify?.startAt), moment(detailModify?.endAt)]}
              onChange={(date, dateString) => {
                setDetailModify(
                  produce((draft) => {
                    draft.startAt = dateString[0];
                    draft.endAt = dateString[1];
                  }),
                );
              }}
            />
          </div>
          <p className={styles.preview_text}>※ 시작일 00:00부터 종료일 24:00까지 게시됩니다.</p>
        </div>
      </Modal>
      <AddAdsModal modalOpen={isListModal} setModalOpen={setIsListModal} />
      {/* <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={isListModal}
        onOk={() => {
          setIsListModal(false);
        }}
        onCancel={() => {
          setIsListModal(false);
        }}
        destroyOnClose={true}
        maskClosable={false}
        okText={'확인'}
        cancelText={'수정'}
      >
        <p>메인팝업 수정</p>
        <div style={{ display: 'flex', justifyContent: 'space-between', height: 850 }}>
          <div style={{ backgroundColor: 'white', width: '48%', height: '100%' }}>
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: 20,
              }}
            >
              <div
                style={{
                  position: 'relative',
                  width: 150,
                  height: 150,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  backgroundImage: `url(${parseImageUri(detailModify?.imageId)})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  borderRadius: 10,
                }}
              >
                <input
                  type="file"
                  id="fileInput"
                  style={{
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    cursor: 'pointer',
                  }}
                  onChange={handleChangeImage}
                />
                <button
                  style={{ backgroundColor: 'rgba(0,0,0,0)', padding: 0, position: 'absolute', top: 10, right: 10 }}
                >
                  <SyncOutlined style={{ fontSize: 30, color: '#1e3d83' }} />
                </button>
              </div>
              <input
                type="text"
                style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '30px 0' }}
                placeholder="제목을 입력해주세요."
                value={detailModify?.title}
                onChange={(e) => {
                  setDetailModify(
                    produce((draft) => {
                      draft.title = e.target.value;
                    }),
                  );
                }}
              />
              <div style={{ display: 'flex', borderBottom: '1px solid #d9d9d9' }}>
                <input type="text" style={{ width: '90%', border: 'none' }} />
                <span style={{ color: '#767676', fontSize: 12 }}>0/80</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                <span>컨텐츠 연결</span>
                <button
                  style={{ border: '1.5px solid #1e3d83', padding: '4px 18px', borderRadius: '15px' }}
                  onClick={() => {
                    setIsListModal(true);
                  }}
                >
                  리스트 생성
                </button>
              </div>
              <input
                type="text"
                style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '30px 0' }}
                placeholder="순서"
                value={detailModify?.sortNum}
                onChange={(e) => {
                  setDetailModify(
                    produce((draft) => {
                      draft.sortNum = e.target.value;
                    }),
                  );
                }}
              />
              <p>게시기간설정</p>
              <Radio.Group
                style={{ width: '100%', textAlign: 'center', margin: '30px 0' }}
                onChange={radioChange}
                value={radioValue}
              >
                <Radio value={1} style={{ marginRight: 50 }}>
                  영구
                </Radio>
                <Radio value={2}>기간설정</Radio>
              </Radio.Group>
              <div style={{ marginBottom: 10 }}>
                <RangePicker
                  disabled={radioValue == 1 ? true : false}
                  value={[moment(detailModify?.startAt), moment(detailModify?.endAt)]}
                  onChange={(date, dateString) => {
                    setDetailModify(
                      produce((draft) => {
                        draft.startAt = dateString[0];
                        draft.endAt = dateString[1];
                      }),
                    );
                  }}
                />
              </div>
              <p className={styles.preview_text}>※ 시작일 00:00부터 종료일 24:00까지 게시됩니다.</p>
            </div>
          </div>
          <div style={{ backgroundColor: 'white', width: '48%', height: '100%' }}>
            <Table
              dataSource={OPERATION_MAIN_POPUP_LIST}
              scroll={{ y: tableHeight }}
              style={{
                borderRadius: 10,
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
                paddingBottom: '100px',
              }}
              columns={[
                {
                  title: (
                    <TableHeader>
                      <span className="title">형상</span>
                    </TableHeader>
                  ),
                  width: 80,
                  align: 'center',
                  render: (data, record) => {
                    console.log({ record });
                    return '';
                  },
                },
                {
                  title: (
                    <TableHeader>
                      <span className="title">제목</span>
                      <span className="sub_title">메모</span>
                    </TableHeader>
                  ),
                  render: (data, record) => {
                    return '';
                  },
                },
              ]}
            />
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

export default OperationAdsIndex;
