import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import Slider from 'react-slick';
import { restApi } from '../../../apis';
import { useGetAskMe, useGetAskMeDetail } from '../../../apis/queries';
import { ASK_ME_LIST_DETAIL_KEY, ASK_ME_LIST_KEY } from '../../../apis/queryKeys';
import profileImage from '../../../assets/dummy/user/02.jpg';
import { getFileId, parseImageUri } from '../../../common/utils';
import CustomCheckBox from '../../../components/button/CustomCheckBox';
import RoundButton from '../../../components/button/RoundButton';
import CommonStatistics from '../../../components/card/CommonStatistics,js/CommonStatistics';
import Comment from '../../../components/comment/Comment';
import CommentIcon from '../../../components/image/CommentIcon';
import Thumbnail from '../../../components/image/Thumbnail';
import PaginateTable from '../../../components/table/PaginateTable';
import TableColHeader from '../../../components/table/TableColHeader';
import {
  ExtraWrapper,
  FilterButton,
  FilterInput,
  FilterSelect,
  FilterWhiteButton,
} from '../../../components/template/page';
import styles from './Customer.module.less';
import CustomerAddModal from './CustomerAddModal';
import useSearchBar from '../../../hooks/useSearchBar';
import useUpperLine from '../../../hooks/useUpperLine';
import { CloseCircleFilled, DollarCircleOutlined, HeartFilled, UserOutlined, WechatFilled } from '@ant-design/icons';
import produce from 'immer';
import moment from 'moment';
import { Checkbox, Modal, PageHeader } from 'antd';
import LowerLine from '../../../components/LowerLine';
import { Option } from 'antd/lib/mentions';

const CustomerBoard = (props) => {
  const [refreshDetail, setRefreshDetail] = React.useState();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalModify, setIsModalModify] = useState(false);
  const [isAddModal, setIsAddModal] = React.useState(false);

  //필터 윗줄 생성(keword, filter params 포함, query param 에 spread 구문으로 삽입)
  const filters = React.useMemo(() => {
    return [
      {
        title: '기준정렬',
        key: 'sort',
        options: [undefined, ['createdAt', '시간순'], ['viewCount', '조회순'], ['commentCount', '댓글순']],
      },
    ];
  }, []);
  const { params: upperParam, UpperLine } = useUpperLine(filters);

  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: 'DESC',
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  React.useEffect(() => {
    if (upperParam) {
      setParams((prev) => ({ ...prev, ...upperParam }));
    }
  }, [upperParam]);

  // 모두 선택 버튼로직
  const [checkedList, setCheckedList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const { data: boardData } = useGetAskMe({
    params,
  });
  const allData = React.useMemo(() => {
    return boardData?.content?.map((v) => ({
      data: v,
      id: v?.id,
    }));
  }, [boardData]);
  React.useEffect(() => {
    if (!!allData) {
      const idList_ = allData?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [allData]);

  React.useEffect(() => {
    setRefreshDetail(boardData?.content[0]?.id);
  }, [boardData]);
  const { data: detailData } = useGetAskMeDetail({ id: refreshDetail, params: {} });
  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    if (imageId) {
      setDetailModify(
        produce((draft) => {
          draft.images.push(imageId);
        }),
      );
    }
  };
  const [detailModify, setDetailModify] = React.useState({
    title: detailData?.title ?? '',
    content: detailData?.content ?? '',
    images: detailData?.images ?? [],
  });

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setRefreshDetail(record.id);
        queryClient.invalidateQueries([ASK_ME_LIST_DETAIL_KEY]);
      },
    };
  };
  const settings = {
    className: 'center',
    infinite: false,
    centerPadding: '60px',
    slidesToShow: 5,
    swipeToSlide: true,
    afterChange: function (index) {
      console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
    },
  };

  const columns = [
    {
      title: '선택',
      dataIndex: 'data',
      key: 'data',
      width: 40,
      align: 'center',
      render: (data, record) => {
        return (
          <>
            <Checkbox
              key={`${record.id}_check`}
              checked={checkedList.includes(record.id)}
              onClick={() => {
                if (checkedList.includes(record.id)) {
                  setCheckedList(checkedList.filter((v) => v !== record.id));
                } else {
                  setCheckedList([...checkedList, record.id]);
                }
              }}
            />
          </>
        );
      },
    },
    {
      title: '이미지',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      align: 'center',
      render: (id, record) => (
        <div style={{ position: 'relative', width: 50, height: 50, marginBlock: 6 }}>
          <button
            style={{
              position: 'absolute',
              top: -4,
              right: -5,
              zIndex: 10,
              padding: 0,
              width: 18,
              height: 18,
              border: 'none',
              borderRadius: '50%',
              backgroundColor: 'rgba(255,255,255,1)',
            }}
          >
            <CloseCircleFilled
              style={{ fontSize: 18, color: '#1e3d83' }}
              onClick={() => {
                Modal.confirm({
                  title: '삭제',
                  content: '해당 글을 삭제하시겠습니까?',
                  okText: '확인',
                  cancelText: '취소',
                  async onOk() {
                    try {
                      await restApi.delete(`/posts/ask-me/${record?.id}`);
                      Modal.confirm({
                        title: '완료',
                        content: '삭제가 완료되었습니다',
                        onOk() {
                          queryClient.invalidateQueries([ASK_ME_LIST_KEY]);
                        },
                        cancelButtonProps: {
                          style: { display: 'none' },
                        },
                        okText: '확인',
                      });
                    } catch (error) {
                      Modal.warning({
                        title: '실패했습니다',
                        content: error.response.data.message,
                      });
                    }
                  },
                });
              }}
            />
          </button>

          <Thumbnail id={record?.thumbnailId} />
        </div>
      ),
    },
    {
      title: <TableColHeader title={'제목'} subTitle={'작성구분'} />,
      dataIndex: 'title',
      key: 'title',
      render: (data, row) => <p style={{ color: '#000', fontWeight: 600, fontSize: 12 }}>{data}</p>,
    },
    {
      title: <TableColHeader title={'작성자'} subTitle={'작성일시'} />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 160,
      align: 'left',
      render: (value, record) => (
        <div style={{ color: '#767676', fontWeight: 400 }}>
          <p>{record.createdBy?.nickname}</p>
          <p>{moment(value).format('YYYY-MM-DD HH:mm')}</p>
        </div>
      ),
    },
  ];

  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 320);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalModify(true);
    setDetailModify(
      produce((draft) => {
        draft.title = detailData?.title;
        draft.content = detailData?.content;
        draft.images = detailData?.images;
      }),
    );
  };
  const handleModifyOK = async () => {
    try {
      await restApi.put(`/posts/ask-me/${refreshDetail}`, detailModify);
      Modal.confirm({
        title: '완료',
        content: '수정이 완료되었습니다',
        onOk() {
          window.location.reload();
          // setIsModalModify(false);
        },
        cancelButtonProps: {
          style: { display: 'none' },
        },
        okText: '확인',
      });
    } catch (error) {
      Modal.warning({
        title: '실패했습니다',
        content: error.response.data.message,
      });
      queryClient.invalidateQueries([ASK_ME_LIST_DETAIL_KEY]);
      queryClient.invalidateQueries([ASK_ME_LIST_KEY]);
    }
  };
  const handleDelete = () =>
    Modal.confirm({
      title: '일괄 삭제',
      content: '선택된 항목들을 삭제하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      async onOk() {
        try {
          await restApi.delete(`/posts/ask-me`, { data: { ids: checkedList } });
          Modal.confirm({
            title: '완료',
            content: '삭제가 완료되었습니다',
            onOk() {
              window.location.reload();
              // setIsModalModify(false);
            },
            cancelButtonProps: {
              style: { display: 'none' },
            },
            okText: '확인',
          });
        } catch (error) {
          Modal.warning({
            title: '오류',
            content: error.response.data.message,
          });
        }
      },
    });
  const handleModifyCancel = () => {
    setIsModalModify(false);
  };
  return (
    <>
      <div>
        <PageHeader
          className="site-page-header"
          title="소비자 게시판(무엇이든 물어보세요)"
          style={{ paddingBottom: 10 }}
        />
        <ExtraWrapper>
          {/* 필터 윗줄 */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 4.5,
              marginRight: 20,
              width:'100%'
            }}
          >
            <div style={{  display: 'flex',width:'65%' }}>
              <UpperLine containerStyle={{width: '100%',marginRight:'1%',justifyContent:'space-between'}} listKey={ASK_ME_LIST_KEY} detailKey={ASK_ME_LIST_DETAIL_KEY} setRefresh={setRefreshDetail} >
                <div> </div>
              </UpperLine>
            </div>
            <FilterButton
              onClick={() => {
                setIsAddModal(true);
              }}
            >
              새로 작성
            </FilterButton>
          </div>
          {/* 필터 아랫줄 */}
          <div style={{ width: '65%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <LowerLine
              onCheck={() => {
                if (idList.length === checkedList.length) {
                  setCheckedList([]);
                } else {
                  setCheckedList(idList);
                }
              }}
              left={<div
                  style={{width: 260, display: 'flex', alignItems: 'center'}}>
                <div
                    onClick={handleDelete}
                    style={{cursor: 'pointer', marginLeft: 10}}
                    className={styles.filterWrapper}
                >
                  <img
                      src={require('../../../assets/delete.png')}
                      alt="delete"
                      style={{
                        width: '18.2px',
                        height: '21.2px',
                      }}
                  />
                  <span className={styles.filterText}>항목삭제</span>
                </div>
              </div>}
              checked={idList.length === checkedList.length}
              containerStyle={{height: 50}}
            >

            </LowerLine>
            <div style={{width:180}}>
            <FilterSelect
                value={params?.order}
                onChange={(v) => {
                  setParams(
                      produce((draft) => {
                        draft.order = v;
                      }),
                  );
                }}
            >
              <Option value="DESC">저장일시 최신순</Option>
            </FilterSelect>
            </div>
          </div>
        </ExtraWrapper>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <PaginateTable
            dataSource={boardData?.content}
            columns={columns}
            scroll={{ y: tableHeight - 40 }}
            data={boardData}
            page={params?.page}
            setPage={(v) =>
              setParams(
                produce((draft) => {
                  draft.page = v;
                }),
              )
            }
            pageSize={params?.limit}
            setPageSize={(v) =>
              setParams(
                produce((draft) => {
                  draft.limit = v;
                }),
              )
            }
            onRow={onRow}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '65%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
            }}
          />
          <div
            style={{
              width:500,
              paddingLeft: 20,
              backgroundColor: '#f9f9f9',
              overflowY: 'scroll',
              height: document.body.clientHeight - 240,
              paddingBottom: 15,
            }}
          >
            <h3 style={{ marginBottom: '0 0px 5px 0px' }}>컨텐츠 미리보기</h3>
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 10,
                overflow: 'hidden',
                boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.08)',
              }}
            >
              <div>
                <div
                  className={[styles.slider_div]}
                  style={{
                    // backgroundColor: 'black',
                    backgroundImage:
                      detailData?.images.length > 0
                        ? `url(${parseImageUri(detailData?.images[0])})`
                        : `url(../../../../src/assets/dummy/01/01-07.jpg)`,
                  }}
                >
                  <div className={styles.backdrop} />
                  <div className={styles.slider_inner_div}>
                    <p className={styles.title_p}>{detailData?.title}</p>
                    <div className={styles.flex_div}>
                      <div className={styles.flex_div} style={{ alignItems: 'center' }}>
                        {detailData?.createdBy?.profileImageId ? (
                          <img className={styles.style_profile_img} src={profileImage} alt="프로필 이미지" />
                        ) : (
                          <div
                            className={styles.style_profile_img}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: '#e8e8e8',
                            }}
                          >
                            <UserOutlined style={{ color: 'white', fontSize: 30 }} />
                          </div>
                        )}
                        <p className={styles.category_p} style={{ marginLeft: 10 }}>
                          {detailData?.createdBy?.nickname}
                        </p>
                      </div>
                      <div className={styles.flex_div}>
                        <div style={{ marginLeft: 5 }}>
                          <CommentIcon style={{ fontSize: 18, color: '#e8e8e8' }} />
                          <p className={styles.style_button_count_p} style={{ textAlign: 'center' }}>
                            {detailData?.commentCount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.trand_card_div}>
                <div style={{ display: 'flex', position: 'relative' }}>
                  {detailData?.images.length > 0 ? (
                    [...detailData?.images].slice(1).map((item) => {
                      return (
                        <div
                          className={styles.trand_thumbnail_img}
                          style={{
                            zIndex: 10,
                          }}
                        >
                          <img style={{ width: '100%', height: '100%' }} src={parseImageUri(item)} alt="" />
                        </div>
                      );
                    })
                  ) : (
                    <div style={{ width: 40, height: 20 }} />
                  )}
                </div>
                <p className={styles.desc_p} style={{ maxHeight: 63, overflow: 'hidden', marginTop: 10 }}>
                  {detailData?.content}
                </p>
                <button onClick={showModal}>더보기</button>
              </div>
            </div>
            <CommonStatistics />
          </div>
        </div>
      </div>
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        title={
          <div className={styles.header}>
            <RoundButton
              onClick={() => {
                handleCancel();
              }}
              label="취소"
              containerStyle={{
                width: '100px',
                marginRight: '10px',
              }}
            />
            <RoundButton
              onClick={handleOk}
              reverse
              label="수정"
              containerStyle={{
                width: '100px',
              }}
            />
          </div>
        }
        footer={null}
        // title={}
        cancelText={'확인'}
      >
        <div style={{ width: '100%', backgroundColor: 'black' }}>
          <img
            style={{
              display: 'block',
              height: '450px',
              marginLeft: 'auto',
              marginRight: 'auto',
              backgroundColor: 'black',
            }}
            src={detailData?.images?.length > 0 && parseImageUri(detailData?.images[0])}
            alt=""
          />
        </div>
        <div style={{ padding: '50px 100px' }}>
          {detailData?.images.length > 1 && (
            <Slider {...settings}>
              {[...detailData?.images].length > 1 &&
                [...detailData?.images].slice(1).map((item, index) => {
                  return (
                    <div>
                      <img style={{ width: 108, height: 108, borderRadius: 10 }} src={parseImageUri(item)} alt="" />
                    </div>
                  );
                })}
            </Slider>
          )}
          <p style={{ margin: '10px 0', fontWeight: 600, fontSize: 17 }}>{detailData?.title}</p>
          <div className={styles.flex_div}>
            <div className={styles.flex_div} style={{ alignItems: 'center' }}>
              {detailData?.createdBy?.profileImageId ? (
                <img className={styles.style_profile_img} src={profileImage} alt="프로필 이미지" />
              ) : (
                <div
                  className={styles.style_profile_img}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#e8e8e8',
                  }}
                >
                  <UserOutlined style={{ color: 'white', fontSize: 30 }} />
                </div>
              )}
              <div>
                <p className={styles.category_p} style={{ marginLeft: 10, color: 'black', fontWeight: 'bold' }}>
                  {detailData?.createdBy?.nickname}
                </p>
                <p className={styles.category_p} style={{ marginLeft: 10, color: '#787878' }}>
                  {detailData?.createdAt}
                </p>
              </div>
            </div>
            <div className={styles.flex_div}>
              <div>
                <HeartFilled style={{ fontSize: 20, color: '#d9d9d9' }} />
                <p className={styles.style_button_count_p} style={{ color: '#999999', textAlign: 'center' }}>
                  {detailData?.likeInfo?.likeCount}
                </p>
              </div>
              <div style={{ marginLeft: 5 }}>
                <WechatFilled style={{ fontSize: 20, color: '#d9d9d9' }} />
                <p className={styles.style_button_count_p} style={{ color: '#999999', textAlign: 'center' }}>
                  {detailData?.commentCount}
                </p>
              </div>
            </div>
          </div>
          <p style={{ margin: '25px 0 50px', fontSize: 14, color: '#767676' }}>{detailData?.content}</p>
          {/*<div className={styles.modify_input_wrapper_div}>*/}
          {/*  <input*/}
          {/*    type="text"*/}
          {/*    defaultValue={'https://www.naver.com/dskfjhuwqerui@welkij124909'}*/}
          {/*    className={styles.modify_title_input}*/}
          {/*    style={{ fontWeight: 'normal', color: '#999', fontSize: 14 }}*/}
          {/*    maxLength={80}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div style={{ padding: '50px 100px' }}>
          <Comment id={refreshDetail} type={'POST'} />
        </div>
      </Modal>
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={false}
        open={isModalModify}
        onOk={handleModifyOK}
        onCancel={handleModifyCancel}
        destroyOnClose={true}
        maskClosable={true}
        title={
          <div className={styles.header}>
            <RoundButton
              onClick={handleModifyCancel}
              label="나가기"
              containerStyle={{
                width: '100px',
                marginRight: '10px',
              }}
            />
            <RoundButton
              onClick={handleModifyOK}
              reverse
              label="수정하기"
              containerStyle={{
                width: '100px',
              }}
            />
          </div>
        }
        footer={null}
        // okText={'수정하기'}
        // cancelText={'나가기'}
      >
        <div style={{ width: '100%', backgroundColor: 'black' }}>
          <img
            style={{
              display: 'block',
              height: '450px',
              marginLeft: 'auto',
              marginRight: 'auto',
              backgroundColor: 'black',
            }}
            src={detailModify?.images?.length > 0 && parseImageUri(detailModify?.images[0])}
            alt=""
          />
        </div>
        <div style={{ padding: '50px 100px' }}>
          <Slider {...settings}>
            <div style={{ width: 108, height: 108, borderRadius: 10, position: 'relative' }}>
              <button
                style={{
                  width: 108,
                  height: 108,
                  borderRadius: 10,
                  border: 'none',
                  backgroundColor: '#d9d9d9',
                  color: 'white',
                }}
              >
                <input
                  type="file"
                  id="fileInput"
                  style={{
                    width: 108,
                    height: 108,
                    opacity: 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    cursor: 'pointer',
                  }}
                  onChange={handleChangeImage}
                />
                +
                <br />
                추가사진
                <br />
                업로드
              </button>
            </div>
            {detailModify?.images.length > 1 &&
              [...detailModify?.images].slice(1).map((item, index) => {
                return (
                  <div style={{ width: 108, height: 108, borderRadius: 10, position: 'relative' }}>
                    <img style={{ width: 108, height: 108, borderRadius: 10 }} src={parseImageUri(item)} alt="" />
                    {[...detailModify?.images].slice(1).length === index + 1 && (
                      <div
                        style={{
                          width: 30,
                          height: 30,
                          borderRadius: '50%',
                          backgroundColor: 'white',
                          position: 'absolute',
                          top: 0,
                          right: 10,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setDetailModify(
                            produce((draft) => {
                              draft.images.pop();
                            }),
                          );
                        }}
                      >
                        <CloseCircleFilled style={{ fontSize: 30, color: '#1e3d83' }} />
                      </div>
                    )}
                  </div>
                );
              })}
          </Slider>
          <input
            type="text"
            value={detailModify?.title}
            style={{
              margin: '10px 0',
              fontWeight: 600,
              fontSize: 17,
              border: 'none',
              width: '100%',
              borderBottom: '1px solid #d9d9d9',
              backgroundColor: 'rgba(0,0,0,0)',
            }}
            onChange={(e) => {
              setDetailModify(
                produce((draft) => {
                  draft.title = e.target.value;
                }),
              );
            }}
          />
          <div className={styles.flex_div}></div>
          <textarea
            style={{
              width: '100%',
              minHeight: 200,
              backgroundColor: '#f9f9f9',
              border: '1px solid #d9d9d9',
              padding: 20,
            }}
            value={detailModify?.content}
            onChange={(e) => {
              setDetailModify(
                produce((draft) => {
                  draft.content = e.target.value;
                }),
              );
            }}
          ></textarea>
        </div>
        {/*<div style={{ padding: '50px 100px' }}>*/}
        {/*  <Comment id={refreshDetail} type={'POST'} />*/}
        {/*</div>*/}
      </Modal>
      <CustomerAddModal modalOpen={isAddModal} setModalOpen={setIsAddModal} settings={settings} />
      {/* <ModifyTrandModal open={isModalModify} setOepn={setIsModalModify} data={modalData} /> */}
    </>
  );
};

export default CustomerBoard;
