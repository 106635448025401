import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '../../../pages/homeIndex.module.less';

const PrevArrow = React.memo(({ children,onClick }) => {
  return (
      <div key={'prev'} onClick={onClick} className={styles.arrow} style={{ left: 110 + children?.length * 22 }}>
        <img className={styles.arrowIcon} src={require('../../../assets/arrow.png')} alt="arrowLeft" />
      </div>
  );
});
const NextArrow = React.memo(({ children,onClick }) => {
  return (
      <div key={'next'} onClick={onClick} className={styles.arrow} style={{ right: 110 + children?.length * 22 }}>
        <img
            className={styles.arrowIcon}
            style={{ transform: 'rotate(180deg)' }}
            src={require('../../../assets/arrow.png')}
            alt="arrowRight"
        />
      </div>
  );
});
const HomeCard = ({ width, children }) => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow:
        <PrevArrow
            children={children}
            onClick={() => {
              console.log('asdf');
            }}
        />
    ,
    nextArrow:
        <NextArrow
            children={children}
            onClick={() => {
              console.log('asdf');
            }}
        />
    ,

  };

  return (
    <div
      className={styles.container}
      style={{

        height: 380
      }}
    >
      <div className={styles.background} />

      <Slider {...settings}  className={styles.home_slider}>
        {children}
      </Slider>
    </div>
  );
};

export default HomeCard;
