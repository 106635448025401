import React, { useCallback, useEffect, useState } from 'react';
import { Button, DatePicker, Image, Modal, PageHeader, Table } from 'antd';
import Slider from 'react-slick';
import RoundButton from '../../../components/button/RoundButton';
import styles from './Customer.module.less';
import profileImage from '../../../assets/dummy/user/02.jpg';
import { CloseCircleFilled, HeartFilled, UserOutlined, WechatFilled } from '@ant-design/icons';
import { getFileId, parseImageUri } from '../../../common/utils';
import produce from 'immer';
import { restApi } from '../../../apis';

const CustomerAddModal = ({ modalOpen, setModalOpen, settings }) => {
  const [detailModify, setDetailModify] = React.useState({
    title: '',
    content: '',
    images: [],
  });

  const handleModifyOK = async () => {
    Modal.confirm({
      title: '작성',
      content: '작성을 완료하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      async onOk() {
        try {
          await restApi.post(`/posts/ask-me`, detailModify);
          Modal.confirm({
            title: '완료',
            content: '작성이 완료되었습니다',
            onOk() {
              window.location.reload();
            },
            cancelButtonProps: {
              style: { display: 'none' },
            },
            okText: '확인',
          });
        } catch (error) {
          Modal.warning({
            title: '실패했습니다',
            content: error.response.data.message,
          });
        }
      },
    });
  };
  const handleModifyCancel = () => {
    setModalOpen(false);
  };
  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    if (imageId) {
      setDetailModify(
        produce((draft) => {
          draft.images.push(imageId);
        }),
      );
    }
  };
  return (
    <Modal
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 200,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
      }}
      closable={false}
      open={modalOpen}
      onOk={handleModifyOK}
      onCancel={handleModifyCancel}
      destroyOnClose={true}
      maskClosable={true}
      footer={null}
      title={
        <div className={styles.header}>
          <RoundButton
            onClick={() => {
              handleModifyCancel();
            }}
            label="취소"
            containerStyle={{
              width: '100px',
              marginRight: '10px',
            }}
          />
          <RoundButton
            onClick={handleModifyOK}
            reverse
            label="저장"
            containerStyle={{
              width: '100px',
            }}
          />
        </div>
      }
      // okText={'작성하기'}
      // cancelText={'나가기'}
    >
      <div style={{ width: '100%', backgroundColor: 'black' }}>
        <img
          style={{
            display: 'block',
            height: '450px',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: 'black',
          }}
          src={detailModify?.images?.length > 0 && parseImageUri(detailModify?.images[0])}
          alt=""
        />
      </div>
      <div style={{ padding: '50px 100px' }}>
        <Slider {...settings}>
          <div style={{ width: 108, height: 108, borderRadius: 10, position: 'relative' }}>
            <button
              style={{
                width: 108,
                height: 108,
                borderRadius: 10,
                border: 'none',
                backgroundColor: '#d9d9d9',
                color: 'white',
              }}
            >
              <input
                type="file"
                id="fileInput"
                style={{
                  width: 108,
                  height: 108,
                  opacity: 0,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  cursor: 'pointer',
                }}
                onChange={handleChangeImage}
              />
              +
              <br />
              추가사진
              <br />
              업로드
            </button>
          </div>
          {detailModify?.images.length > 1 &&
            [...detailModify?.images].slice(1).map((item, index) => {
              return (
                <div style={{ width: 108, height: 108, borderRadius: 10, position: 'relative' }}>
                  <img style={{ width: 108, height: 108, borderRadius: 10 }} src={parseImageUri(item)} alt="" />
                  {[...detailModify?.images].slice(1).length === index + 1 && (
                    <div
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        position: 'absolute',
                        top: 0,
                        right: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setDetailModify(
                          produce((draft) => {
                            draft.images.pop();
                          }),
                        );
                      }}
                    >
                      <CloseCircleFilled style={{ fontSize: 30, color: '#1e3d83' }} />
                    </div>
                  )}
                </div>
              );
            })}
        </Slider>
        <input
          type="text"
          value={detailModify?.title}
          style={{
            margin: '50px 0',
            fontWeight: 600,
            fontSize: 17,
            border: 'none',
            width: '100%',
            borderBottom: '1px solid #d9d9d9',
            backgroundColor: 'rgba(0,0,0,0)',
          }}
          placeholder="제목을 입력하세요"
          onChange={(e) => {
            setDetailModify(
              produce((draft) => {
                draft.title = e.target.value;
              }),
            );
          }}
        />
        <div className={styles.flex_div}>
          <div className={styles.flex_div}></div>
        </div>
        <textarea
          style={{
            width: '100%',
            minHeight: 200,
            backgroundColor: '#f9f9f9',
            border: '1px solid #d9d9d9',
            padding: 20,
          }}
          value={detailModify?.content}
          placeholder="내용을 입력하세요"
          onChange={(e) => {
            setDetailModify(
              produce((draft) => {
                draft.content = e.target.value;
              }),
            );
          }}
        ></textarea>
        <div className={styles.modify_input_wrapper_div}>
          <input
            type="text"
            // defaultValue={'https://www.naver.com/dskfjhuwqerui@welkij124909'}
            className={styles.modify_title_input}
            style={{ fontWeight: 'normal', color: '#999', fontSize: 14 }}
            maxLength={80}
          />
        </div>
      </div>
      {/* <div style={{ padding: '50px 100px' }}>
          <Comment id={refreshDetail} type={'POST'} />
        </div> */}
    </Modal>
  );
};

export default CustomerAddModal;
