import React from 'react';
import styles from './signup.module.less';
import styled from 'styled-components';
import { Checkbox, Modal } from 'antd';
import { useSelector } from 'react-redux';
import produce from 'immer';
import { regex } from '../../../common/defineCMS';
import { getFileId, parseImageUri } from '../../../common/utils';
import RoundButton from '../../../components/button/RoundButton';
// import FindPostcodeModal from '../../../components/modal/FindPostcodeModal';
import PhoneCertModal from '../../../components/modal/PhoneCertModal';
import { restApi } from '../../../apis';

// import InsuranceModal from '../../../components/modal/InsuranceModal';
// import EstimateSettingModal from '../../../components/modal/EstimateSettingModal';
// import FindCustomerModal from '../../../components/modal/FindCustomerModal';
// import RegionsModal from '../../../components/modal/RegionsModal';
import moment from 'moment';
import { ArrowLeftOutlined } from '@ant-design/icons';

const CustomInput = styled.input`
  margin-top: 15px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #767676;
  background-color: inherit;
  &:focus {
    outline: none;
    background-color: inherit;
  }
  ::placeholder {
    color: ${({ bold }) => (bold ? '#767676' : '#d9d9d9')};
  }
`;

const CusomSelect = styled.select`
  margin-top: 15px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #767676;
  background-color: inherit;
  &:focus {
    outline: none;
    background-color: inherit;
  }
  select::placeholder {
    color: #d9d9d9;
  }
`;
const Section = React.memo(({ title, children }) => {
  return (
    <div className={styles.sectionWrapper}>
      <span
        style={{
          fontSize: '14px',
          fontWeight: '600',
          color: '#767676',
        }}
      >
        {title}
      </span>
      {children}
    </div>
  );
});

const InputComponent = React.memo(
  ({ onClick = () => {}, placeholder, value, setValue = () => {}, bold = false, ...rest }) => {
    return (
      <div onClick={onClick}>
        <CustomInput
          {...rest}
          bold={bold}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    );
  },
);

const SelectComponent = React.memo(({ value, setValue, items }) => {
  return (
    <CusomSelect value={value} onChange={(e) => setValue(e.target.value)}>
      {items?.map((v) => (
        <option value={v.value}>{v.label}</option>
      ))}
    </CusomSelect>
  );
});

const WarnMsg = React.memo(({ msg, visible }) => {
  return (
    <span
      style={{
        opacity: visible ? 1 : 0,
        fontSize: '12px',
        color: 'red',
        marginTop: 7,
      }}
    >
      {msg}
    </span>
  );
});

const SignUp = ({ setIsSignUp, setIsFindPassword, setIsFindId }) => {
  const inputRef = React.useRef(null);
  const initialForm = React.useMemo(
    () => ({
      signname: null,
      password: null,
      name: null,
      nickname: null,
      email: null,
      phone: null,
    }),
    [],
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDuplicateId, setIsDuplicateId] = React.useState(false);
  const [passwordConfirm, setPasswordConfirm] = React.useState(null);
  const [termsChecked, setTermsChecked] = React.useState([false, false, false]);
  const [form, setForm] = React.useState(initialForm);
  console.log(form);
  const termsItems = React.useMemo(
    () => [
      {
        label: '(필수) 바바 서비스 이용 약관',
        checked: termsChecked[0],
        onClickMore: () => {},
      },
      {
        label: '(필수) 바바 개인정보 취급 방침',
        checked: termsChecked[1],
        onClickMore: () => {},
      },
      {
        label: '(선택) 마케팅 정보 수신 동의',
        checked: termsChecked[2],
        onClickMore: () => {},
      },
    ],
    [termsChecked],
  );

  const handleSubmit = React.useCallback(() => {
    setIsLoading(true);
    restApi
      .post('/accounts/admin', form)
      .then(({ status }) => {
        if (status === 200) {
          Modal.success({
            content: '가입신청이 완료되었습니다.',
            okText: '확인',
            onOk: () => {
              setIsSignUp(false);
            },
          });
        }
      })
      .catch((err) => {
        Modal.warn({
          content: err.response.data.message,
          okText: '확인',
        });
      })
      .finally(() => setIsLoading(false));
  }, [form, termsChecked]);
  return (
    <>
      <div className={styles.signupContainer} style={{ position: 'relative', boxShadow: '0 0 10px rgba(0,0,0,0.18)' }}>
        <div
          onClick={() => {
            setIsSignUp(false);
            setIsFindPassword(false);
            setIsFindId(false);
          }}
          style={{ position: 'absolute', top: 30, right: 30, cursor: 'pointer' }}
        >
          <ArrowLeftOutlined />
        </div>
        <div className={styles.signupBodyWrapper}>
          <h2 style={{ fontSize: 22, marginTop: 0, marginBottom: 10 }}>직원등록정보입력</h2>
          <Section title="아이디">
            <InputComponent
              // onBlur={() => {
              //   restApi
              //     .get('/accounts', {
              //       params: {
              //         signname: form?.signname,
              //       },
              //     })
              //     .then((res) => {
              //       setIsDuplicateId(false);
              //     })
              //     .catch((err) => {
              //       setIsDuplicateId(true);
              //     });
              // }}
              maxLength={20}
              placeholder="4 ~ 20자리 영문 대소문자, 숫자 조합"
              value={form?.signname}
              setValue={(v) => {
                setIsDuplicateId(false);
                setForm(
                  produce((draft) => {
                    draft.signname = v;
                  }),
                );
              }}
            />
            {/* <WarnMsg
              msg={
                !regex['signname'].test(form?.signname)
                  ? '사용할 수 없는 아이디 입니다.'
                  : '사용할 수 있는 아이디 입니다.'
              }
              visible
            /> */}
          </Section>
          <Section title="비밀번호">
            <InputComponent
              placeholder="8 ~ 16자리 영문 대소문자, 숫자, 특수문자 중 2가지 조합"
              value={form?.password}
              setValue={(v) => {
                setForm(
                  produce((draft) => {
                    draft.password = v;
                  }),
                );
              }}
              type="password"
            />
            <WarnMsg
              visible
              msg={
                !regex['password'].test(form?.password)
                  ? '사용할 수 없는 비밀번호 입니다.'
                  : '사용할 수 있는 비밀번호 입니다.'
              }
            />
          </Section>
          <Section title="비밀번호 확인">
            <InputComponent
              disabled={!regex['password'].test(form?.password)}
              placeholder="8 ~ 16자리 영문 대소문자, 숫자, 특수문자 중 2가지 조합"
              value={passwordConfirm}
              setValue={(v) => setPasswordConfirm(v)}
              type="password"
            />
            <WarnMsg visible msg={form?.password !== passwordConfirm ? '비밀번호가 일치하지 않습니다.' : ''} />
          </Section>
          <Section title="이름">
            <InputComponent
              placeholder="김바바"
              value={form?.name}
              setValue={(v) => {
                setForm(
                  produce((draft) => {
                    draft.name = v;
                  }),
                );
              }}
            />
            {/* <WarnMsg
              visible
              msg={
                !regex['password'].test(form?.password)
                  ? '사용할 수 없는 비밀번호 입니다.'
                  : '사용할 수 있는 비밀번호 입니다.'
              }
            /> */}
          </Section>
          <Section title="닉네임(영문)">
            <InputComponent
              placeholder="닉네임을 입력해 주세요.(영문권장, 발음 가능할 것)"
              value={form?.nickname}
              setValue={(v) => {
                setForm(
                  produce((draft) => {
                    draft.nickname = v;
                  }),
                );
              }}
            />
            {/* <WarnMsg
              visible
              msg={
                !regex['password'].test(form?.password)
                  ? '사용할 수 없는 비밀번호 입니다.'
                  : '사용할 수 있는 비밀번호 입니다.'
              }
            /> */}
          </Section>
          <Section title="비밀번호 찾기용 이메일 주소">
            <InputComponent
              placeholder="youremail@email.com"
              value={form?.email}
              setValue={(v) => {
                setForm(
                  produce((draft) => {
                    draft.email = v;
                  }),
                );
              }}
            />
            <WarnMsg
              visible
              msg={
                !regex['email'].test(form?.email) ? '사용할 수 없는 이메일 입니다.' : '사용할 수 있는 이메일 입니다.'
              }
            />
          </Section>
          <Section title="휴대전화번호">
            <InputComponent
              placeholder="010012345678"
              value={form?.phone}
              setValue={(v) => {
                setForm(
                  produce((draft) => {
                    draft.phone = v;
                  }),
                );
              }}
            />
            <WarnMsg
              visible
              msg={
                form?.phone?.length > 11 ||
                form?.phone?.length < 10 ||
                form?.phone?.[0] !== '0' ||
                form?.phone?.[1] !== '1'
                  ? '사용할 수 없는 번호 입니다.'
                  : '사용할 수 있는 번호 입니다.'
              }
            />
          </Section>
          <Section title="서비스 이용약관 동의">
            <div className={styles.termsWrapper}>
              <div className={styles.termsTopWrapper}>
                <Checkbox
                  onChange={(e) => {
                    setTermsChecked(
                      produce((draft) => {
                        draft.fill(!termsChecked.every((v) => v === true));
                      }),
                    );
                  }}
                  checked={termsChecked.every((v) => v === true)}
                />
                <span style={{ marginLeft: 2 }}>전체동의</span>
              </div>
              <div className={styles.termsBottomWrapper}>
                {termsItems?.map((v, i) => {
                  return (
                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Checkbox
                        checked={v.checked}
                        onChange={(e) =>
                          setTermsChecked(
                            produce((draft) => {
                              draft[i] = e.target.checked;
                            }),
                          )
                        }
                      />
                      <span style={{ marginLeft: 10 }}>{v.label}</span>
                      <img
                        style={{ cursor: 'pointer', position: 'absolute', right: 0, width: '6px', height: '11px' }}
                        src={require('../../../assets/arrowRight.png')}
                        alt={`arrowRight_${i}`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <WarnMsg visible msg={form?.password !== passwordConfirm ? '비밀번호가 일치하지 않습니다.' : ''} />
          </Section>
          <RoundButton
            onClick={() => termsChecked[0] && termsChecked[1] && handleSubmit()}
            loading={isLoading}
            disabled={!(termsChecked[0] && termsChecked[1])}
            label="가입신청"
            reverse
            containerStyle={{
              height: '40px',
              borderRadius: '20px',
              marginTop: 20,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SignUp;
