export const REGIONS_KEY = 'regions'; // 지역설정
export const COMMON_CODE_KEY = 'commoncode'; // 공통코드
export const CONSTRUCTION_CATEGORIES_KEY = 'construction_categories'; // 공사 카테고리
export const CONSTRUCTION_TEMPLATES_KEY = 'construction_templates'; // 공종 카테고리별 템플릿 목록
export const ADMIN_LIST_KEY = 'admin_list_key'; // 관리자 회원 목록
export const ADMIN_LIST_DETAIL_KEY = 'admin_list_detail_key'; // 관리자 회원 상세
export const PARTMER_USER_KEY = 'partner_user_key'; //파트너 회원 목록
export const PARTMER_USER_DETAIL_KEY = 'partner_user_detail_key'; // 파트너 회원 상세
export const PARTMER_COMMENT_KEY = 'partner_comment_key'; //파트너 자신의 댓글
export const PARTMER_GUIDE_KEY = 'partner_guide_key'; // 파트너 자신의 공사 가이드
export const PROPOSAL_COST_KEY = 'proposal_cost_key';
export const PARTMER_PORTFOLIO_KEY = 'partner_portfolio_key'; // 파트너 자신의 포트폴리오
export const PARTMER_POST_KEY = 'partner_post_key'; // 파트너 자신의 게시판 글
export const PARTMER_PROPOSED_KEY = 'partner_proposed_key'; // 파트너 자신이 제안한 공사 도움받기
export const PARTMER_STYLE_KEY = 'partner_style_key'; // 파트너 자신의 스타일
export const PARTMER_TREND_KEY = 'partner_trend_key'; // 파트너 자신의 트렌드
export const CONTENTS_KEY = 'contents'; // 스타일, 포트폴리오


export const CONSULTING_LIST_KEY = 'consulting_list_key'; // 컨설팅 리스트
export const CONSULTING_LIST_DETAIL_KEY = 'consulting_list_detail_key'; // 컨설팅 리스트 상세
export const CONTENT_DETAIL_KEY = 'content_detail'; // 스타일, 포트폴리오 상세
export const POSTS_KEY = 'posts'; // 트렌드, 공사가이드
export const POST_DETAIL_KEY = 'post_detail'; // 트렌드, 공사가이드 상세
export const ALL_COPNTETN_KEY = 'all_content_key'; // 모든 컨텐츠 통합 api
export const ASK_ME_LIST_KEY = 'ask_list_key'; // 게시판 - 무엇이든 물어보세요 리스트
export const ASK_ME_LIST_DETAIL_KEY = 'ask_list_detail_key'; // 게시판 - 무엇이든 물어보세요 상세
export const EXPERT_TALK_LIST_KEY = 'expert_list_key'; // 게시판 - 전문가들의 수다
export const EXPERT_TALK_LIST_DETAIL_KEY = 'expert_list_detail_key'; // 게시판 - 전문가들의 수다 상세
export const ALL_POST_LIST_DETAIL_KEY = 'all_post_detail_key'; // 게시판 - 전문가들의 수다 상세
export const POPUP_LIST_KEY = 'popup_list_key';
export const POPUP_LIST_DETAIL_KEY = 'popup_list_detail_key';
export const MAIN_BANNER_LIST_KEY = 'main_banner_list_key'; // 메인 배너 관리
export const MAIN_BANNER_LIST_DETAIL_KEY = 'main_banner_list_detail_key'; // 메인 배너 상세
export const SUB_BANNER_LIST_KEY = 'sub_banner_list_key'; // 서브 배너 관리
export const SUB_BANNER_LIST_DETAIL_KEY = 'sub_banner_list_detail_key'; // 서브 배너 상세
export const ADS_LIST_KEY = 'ads_list_key'; // 견적 광고 관리
export const ADS_LIST_DETAIL_KEY = 'ads_list_detail_key'; // 견적 광고 상세
export const MAIN_SECTION_KEY = 'main_section_list_key'; // 메인 섹션 관리
export const MAIN_SECTION_DETAIL_KEY = 'main_section_list_detail_key'; // 메인 섹션 상세
export const CONTENT_GROUP_KEY = 'content_group_key'; // 컨텐츠그룹 관리
export const CONTENT_GROUP_DETAIL_KEY = 'content_group_detail_key'; // 컨텐츠그룹 상세
export const COMMENT_KEY = 'comment_key'; //댓글
export const PRODUCT_CATEGORY_KEY = 'product_category'; // 상품 카테고리
export const PRODUCT_LIST_KEY = 'product_list'; // 상품 리스트
export const PRODUCT_DETAIL_KEY = 'product_detail'; // 상품 상세
export const TEMPLATE_DETAIL_KEY = 'template_detail'; // 템플릿 상세
export const PRODUCT_LIST_MATERIAL_KEY = 'product_list_material'; // 자재 리스트
export const NOTICE_LIST_KEY = 'notice_list_key'; // 공지사항 리스트
export const NOTICE_DETAIL_KEY = 'notice_detail_key'; // 공지사항 상세
export const CONSTRUCTION_TEMPLATES_ADMIN_KEY = 'construction_templates_admin'; // 공종 카테고리별 템플릿 목록
export const USER_LIST_KEY = 'user_list_key'; // 유저 리스트
export const USER_DETAIL_KEY = 'user_detail_key'; // 유저 상세
export const TEMPLATE_DETAIL_KEY_ADMIN = 'template_detail_admin'; // 템플릿 상세
export const LIKED_CONSTRUCTIONGUIDE_KEY = 'liked_constructionguide_key'; // 좋아요 공사가이드 리스트
export const LIKED_STYLE_LIST_KEY = 'liked_style_list_key'; // 좋아요 스타일 리스트
export const LIKED_FOLLOW_LIST_KEY = 'liked_follow_list_key'; // 좋아요 시공사 리스트
export const LIKED_CONSTRUCTION_REQUEST_LIST_KEY = 'liked_construction_request_list_key'; // 좋아요 견적 요청 리스트
export const USER_COMMENT_LIST_KEY = 'user_comment_list_key'; // 유저 댓글 리스트
export const CONSTRUCTION_REQUEST_KEY = 'construction_request_key'; // 공사도움받기 목록
export const CONSTRUCTION_REQUEST_TABLE_KEY = 'construction_request_key'; // 공사도움받기 목록
export const PROPOSED_KEY = 'proposed_list'; //제안한 공사도움받기 리스트
export const USER_SAVED_ESTIMATE_KEY = 'user_saved_estimate_key'; // 견적저장 목록
export const CONSTRUCTION_REQUEST_DETAIL_KEY = 'construction_request_detail_key'; // 공사도움받기 상세
export const CONSTRUCTION_REQUEST_PROPOSED_KEY = 'construction_request_proposed_key'; // 공사도움받기 제안한 업체 목록
export const SERVICE_GUIDE_LIST_KEY = 'service_guide_list_key'; // 서비스 가이드 리스트
export const SERVICE_GUIDE_DETAIL_KEY = 'service_guide_detail_key'; // 서비스 가이드 상세
export const APARTMENT_LIST_KEY = 'apartment_list_key'; // 아파트 리스트
