import React, { useMemo, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { restApi } from '../../apis';
import styled from 'styled-components';
import { Checkbox, Input, Modal, Radio, Cascader, Tag, Select } from 'antd';
import produce from 'immer';
import { useSelector } from 'react-redux';
import { useGetConstructionCategories, useGetProductCategories, useGetTemplateDetailAdmin } from '../../apis/queries';
import { CALC_KEYS, PRINT_LABELS } from '../../common/define';
import { flatten } from 'lodash';
import RoundButton from '../button/RoundButton';
import { useQueryClient } from 'react-query';
import { CONSTRUCTION_TEMPLATES_ADMIN_KEY, TEMPLATE_DETAIL_KEY_ADMIN } from '../../apis/queryKeys';

const styles = {
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: '50px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px 20px 0px 20px',
  },
  titleWrapper: {
    padding: '0px 20px',
  },
  bodyWrapper: {
    marginTop: '20px',
    padding: '20px 40px',
  },
  sectionWrapper: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0px',
    alignItems: 'center',
  },
  sectionTitleText: {
    alignItems: 'flex-start',
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',
    flex: 0.2,
  },
  sectionBodyWrapper: {
    flex: 0.8,
  },
  input: {
    border: 'none',
  },
  checkboxContainer: {
    width: '300px',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: '60px',
    gridRowGap: '5px',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '20px',
    gap: '10px',
  },
};

const InputComponent = styled(Input)`
  border: none;
  border-bottom: 1px solid #e6e6e6;
  background-color: inherit;
  &:focus {
    border: none;
    border-bottom: 1px solid #e6e6e6;
    box-shadow: none;
  }
`;
const Section = React.memo(({ title, children, containerStyle }) => {
  return (
    <div style={{ ...styles.sectionWrapper, ...containerStyle }}>
      <span style={styles.sectionTitleText}>{title}</span>
      <div style={styles.sectionBodyWrapper}>{children}</div>
    </div>
  );
});

const CustomCascader = React.memo(({ setValue, deepDeps = false, ...rest }) => {
  const { value, options } = rest;
  const findLabel = (options, value) => {
    const target = options?.find((v) => v.value === value?.slice(0, v.value.length || v.value === value));
    if (target?.children?.length > 0 && value?.length > target?.value?.length) {
      return findLabel(target?.children, value);
    } else return target.label;
  };

  return (
    <div
      style={{ width: '100%', position: 'relative', border: '1px solid #e6e6e6', borderRadius: 5, paddingLeft: '5px' }}
    >
      <Cascader
        style={{ position: 'absolute', top: 0, left: 0 }}
        {...rest}
        displayRender={() => {}}
        tagRender={() => {}}
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '5px',
        }}
      >
        {value?.map((v) => {
          const label = deepDeps ? findLabel(options, v[v.length - 1]) : options?.find((v2) => v2.value === v[0]).label;

          return (
            <div
              style={{
                backgroundColor: '#f5f5f5',
                borderRadius: 5,
                padding: '2px 5px',
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
              }}
            >
              <span>{label}</span>
              <CloseOutlined
                style={{ width: '10px', height: '10px', color: '#999' }}
                onClick={() => {
                  const targetCode = v[v.length - 1];
                  const value_ = value?.filter((v) => v[v.length - 1] !== targetCode);
                  setValue(value_);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});

const CustomInput = React.memo(({ type, value, setValue, placeholder, maxCount = null }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
      <InputComponent
        maxLength={maxCount ?? null}
        placeholder={placeholder}
        value={value}
        onChange={(e) =>
          setValue(
            produce((draft) => {
              draft[type] = e.target.value;
            }),
          )
        }
      />
      {!!maxCount && (
        <div style={{ position: 'absolute', right: 0, fontSize: '12px', color: '6D6D6D', marginLeft: '5px' }}>{`${
          value?.length ?? 0
        }/${maxCount}`}</div>
      )}
    </div>
  );
});

const RadioWrapper = React.memo(({ labels, value, setValue, type }) => {
  const items = labels.map((v, i) => ({
    label: v,
    value: i === 0 ? true : false,
  }));

  return (
    <Radio.Group
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}
      value={value}
      onChange={(e) =>
        setValue(
          produce((draft) => {
            draft[type] = e.target.value;
          }),
        )
      }
    >
      {items?.map((v) => (
        <Radio style={{ color: v.value === value ? '#6D6D6D' : '#999' }} value={v.value}>
          {v.label}
        </Radio>
      ))}
    </Radio.Group>
  );
});

const ModifyTemplateModal = React.memo(({ visible, setVisible, handleOk, handleCancel, categoryId, templateId }) => {
  const queryClient = useQueryClient();

  const { commonCodes } = useSelector((s) => s.common);
  useGetTemplateDetailAdmin({
    id: templateId,
    config: {
      enabled: !!templateId && !!visible,
      onSuccess: (data) => {
        const transformArray = (data) => {
          return data?.map((v) => {
            if (v.length > 3) {
              let tmp = [];
              for (let i = 0; i < v.length; i += 3) {
                tmp.push(v.substring(0, i + 3));
              }
              return tmp;
            } else {
              return [v];
            }
          });
        };
        setForm({
          ...data,
          parents: data?.parents?.map((v) => [v]),
          conditions: data?.conditions?.map((v) => [v]),
          selectableProductCategoryIds: transformArray(data?.selectableProductCategoryIds),
          excludes: data?.excludes?.map((v) => [v]),
          withOptionsExist: [data?.withOptionsExist],
          printUnitName: [data?.printUnitName],
          displayGroup: [data?.displayGroup],
        });

        const regex = /(\$\{[^}]+\})/g;

        setFieldItems(data?.printUnitField?.split(regex) ?? []);
      },
    },
  });

  const { data: productCategories } = useGetProductCategories();
  const { data: constructionCategories } = useGetConstructionCategories(true);
  const initialForm = React.useMemo(
    () => ({
      categoryId: null, // 카테고리 id (prop)
      name: null, // 명칭
      description: '', // 설명
      areas: [], // 적용공간
      isSelectable: true, // 공종선택여부
      parents: [], // 부모값(isSelectable false일 때)
      conditions: [], // 추가 조건(isSelectable false일 때)
      withOptionsExist: true, // 추가 조건 boolean 값
      isProductSelectable: true, // 자재선택여부
      selectableProductCategoryIds: [], // 자재카테고리
      maxProductCount: null, // 제품최대개수 (isProductSelectable true 일 때)
      isSingleProduct: true, // 자재중복허용 boolean
      price: null, // 적용금액 - 자재선택여부 false일시 표시 , true일시 숨김
      defaultPrice: null, // 공종별전체 기본가격
      pricePerRoom: null, // 공간별 기본 금액
      printUnitName: null, // 출력단위명칭 (Ex. EA, M2 ..)
      printUnitField: null, // 출력필드
      calculateCode: null, // 전산코드
      displayGroup: null, // 표시그룹
      isShowDefault: true, // 기본표출여부
      confirmMessage: null, // 제품옵션시 안내글
      excludes: [], // 제외옵션
    }),
    [],
  );
  const [form, setForm] = React.useState(initialForm);

  const areaItems = React.useMemo(() => {
    if (!commonCodes) return [];
    let items = commonCodes?.tree['AREA']?.map((v) => ({
      label: v.label,
      value: v.code,
    }));
    items.unshift({
      label: '전체',
      value: 'all',
    });
    return items;
  }, [commonCodes]);

  const productCategoryItems = React.useMemo(() => {
    const expandCategory = (item) => {
      if (!!item?.children) {
        return {
          label: item.name,
          value: item.id,
          children: item.children.map((v) => expandCategory(v)),
        };
      }
      return {
        label: item.name,
        value: item.id,
      };
    };
    return productCategories?.tree?.map((v) => expandCategory(v));
  }, [productCategories]);

  const unitNameItems = React.useMemo(() => {
    return Object.entries(PRINT_LABELS)?.map(([key, value]) => ({
      label: value,
      value: key,
    }));
  }, []);

  const [fieldItems, setFieldItems] = useState([]);

  const templateItems = React.useMemo(() => {
    return constructionCategories?.tree?.flatMap((v) =>
      v.children?.flatMap((v2) =>
        v2.templates?.flatMap((v3) => ({
          label: `${v.categoryName} > ${v2.categoryName} > ${v3.name}`,
          value: v3.id,
        })),
      ),
    );
  }, [constructionCategories]);

  const displayGroupItems = React.useMemo(() => {
    if (!commonCodes) return [];
    let items = commonCodes?.tree['ESTP']?.map((v) => ({
      label: v.label,
      value: v.code,
    }));
    items.unshift({
      label: '해당없음',
      value: null,
    });
    return items;
  }, [commonCodes]);

  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const onCancel = () => {
    handleCancel();
    setForm(initialForm);
  };
  global.SQRT = Math.sqrt;
  global.IF = (condition, trueValue, falseValue) => (condition ? trueValue : falseValue);

  const handleSubmit = React.useCallback(() => {
    Modal.confirm({
      content: '저장하시겠습니까?',
      okText: '저장',
      cancelText: '취소',
      onOk: () => {
        const calc = fieldItems?.join(' ');
        try {

          eval(calc.replace(/\$\{[a-zA-Z0-9\_]+\}/g, '1'));
        } catch (e) {
          console.log({e})

          return alert('연산식이 잘못되었습니다.');
        }
        const data = {
          ...form,
          categoryId: categoryId,
          areas: form?.areas?.includes('all')
            ? areaItems
                ?.map((v, i) => {
                  if (i !== 0) return v.value;
                })
                .filter((v) => !!v)
            : form?.areas,
          parents: flatten(form?.parents),
          conditions: flatten(form?.conditions),
          selectableProductCategoryIds: form?.selectableProductCategoryIds?.map((v) => v[v.length - 1]),
          excludes: flatten(form?.excludes),
          withOptionsExist: form?.withOptionsExist[0],
          printUnitName: form?.printUnitName[0],
          displayGroup: form?.displayGroup[0],
          printUnitField: calc,
        };
        const api = templateId
          ? (data) => restApi.put(`/templates/${templateId}`, data)
          : (data) => restApi.post('/templates', data);

        api(data)
          .then(async (res) => {
            await queryClient.invalidateQueries(TEMPLATE_DETAIL_KEY_ADMIN);
            await queryClient.invalidateQueries(CONSTRUCTION_TEMPLATES_ADMIN_KEY);
            Modal.success({
              content: '저장되었습니다.',
              okText: '확인',
              onOk: () => {
                onCancel();
              },
            });
          })
          .catch((err) => {
            Modal.warn({
              content: err.response.data.message,
              okText: '확인',
            });
          });
      },
    });
  }, [form, setVisible, queryClient, templateId, categoryId, onCancel]);

  const tagItems = useMemo(() => {
    const items = Object.values(CALC_KEYS);
    return items
      .filter((v) => {
        return v.allow?.filter((v2) => (form?.areas?.includes('all') ? true : form?.areas?.includes(v2))).length > 0;
      })
      .map((v) => ({
        label: v.label,
        value: '${' + v.key + '}',
      }));
  }, [form?.areas]);
  const handleDelete = React.useCallback(() => {
    Modal.confirm({
      content: '삭제하시겠니까?',
      okText: '확인',
      cancelText: '취소',
      onOk: () => {
        restApi.delete(`templates/${templateId}`).then(async (res) => {
          await queryClient.invalidateQueries(CONSTRUCTION_TEMPLATES_ADMIN_KEY);
          Modal.success({
            content: '삭제되었습니다.',
            okText: '확인',
            onOk: () => {
              onCancel();
            },
          });
        });
      },
    });
  }, [templateId, queryClient]);

  return (
    <Modal
      width={840}
      closable={false}
      open={visible}
      onCancel={() => onCancel()}
      destroyOnClose={true}
      maskClosable={true}
      okText={'확인'}
      footer={null}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div style={styles.modalContainer}>
        <div style={styles.header}>
          <CloseOutlined
            onClick={() => {
              onCancel();
            }}
            style={{ cursor: 'pointer', fontSize: '17px' }}
          />
        </div>
        <div style={styles.titleWrapper}>
          <h3 style={{ fontSize: '18px', fontWeight: '500' }}>{`템플릿 ${templateId ? '수정' : '추가'}`}</h3>
        </div>
        <div style={styles.bodyWrapper}>
 <Section title="명칭">
            <CustomInput value={form?.name} placeholder="명칭을 입력해 주세요." setValue={setForm} type="name" />
          </Section>
          <Section title="설명">
            <CustomInput
              value={form?.description}
              placeholder="이 공종에 대한 설명을 입력해 주세요.(생략가능)"
              setValue={setForm}
              type="description"
              maxCount={30}
            />
          </Section>
          <Section
            containerStyle={{
              alignItems: null,
            }}
            title="적용공간"
          >
            <div style={styles.checkboxContainer}>
              {areaItems?.map((v) => {
                const value = v.value;
                const isChecked = form?.areas?.includes(value);
                const isDisabled = form?.areas?.includes('all') && value !== 'all';
                const isAllChecked = form?.areas?.length === areaItems?.length - 1 || form?.areas?.includes('all');
                return (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '3px' }}>
                    <Checkbox
                      disabled={isDisabled}
                      checked={isChecked || isAllChecked}
                      onChange={(e) => {
                        setForm(
                          produce((draft) => {
                            if (e.target.checked) {
                              draft.areas.push(value);
                            } else {
                              draft.areas = draft.areas.filter((v) => v !== value);
                            }
                          }),
                        );
                      }}
                    />
                    <span style={{ color: isChecked ? '#6D6D6D' : '#999' }}>{v.label}</span>
                  </div>
                );
              })}
            </div>
          </Section>
          <div style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Section title="공종선택여부">
              <RadioWrapper
                labels={['선택가능', '선택불가']}
                value={form?.isSelectable}
                setValue={setForm}
                type="isSelectable"
              />
            </Section>
            {!form?.isSelectable && (
              <>
                <Section title="부모값 선택">
                  <CustomCascader
                    value={form?.parents}
                    placeholder="부모값을 선택해 주세요."
                    bordered={false}
                    style={{ width: '100%' }}
                    options={templateItems}
                    onChange={(v) => {
                      setForm(
                        produce((draft) => {
                          draft.parents = v;
                        }),
                      );
                    }}
                    setValue={(v) => {
                      setForm(
                        produce((draft) => {
                          draft.parents = v;
                        }),
                      );
                    }}
                    multiple
                    maxTagCount="responsive"
                    showSearch={{
                      filter,
                    }}
                  />
                </Section>
                <Section title="추가조건 선택">
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <CustomCascader
                      value={form?.conditions}
                      bordered={false}
                      style={{ width: '65%' }}
                      options={templateItems}
                      onChange={(v) => {
                        setForm(
                          produce((draft) => {
                            draft.conditions = v;
                          }),
                        );
                      }}
                      setValue={(v) => {
                        setForm(
                          produce((draft) => {
                            draft.conditions = v;
                          }),
                        );
                      }}
                      multiple
                      maxTagCount="responsive"
                      showSearch={{
                        filter,
                      }}
                    />
                    <Cascader
                      value={form?.withOptionsExist}
                      options={[
                        {
                          label: '가 없을때',
                          value: false,
                        },
                        {
                          label: '가 있을때',
                          value: true,
                        },
                      ]}
                      bordered={false}
                      style={{ width: '30%', borderBottom: '1px solid #e6e6e6' }}
                      onChange={(v) => {
                        setForm(
                          produce((draft) => {
                            draft.withOptionsExist = v;
                          }),
                        );
                      }}
                      maxTagCount="responsive"
                    />
                  </div>
                </Section>
              </>
            )}
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Section title="자재선택여부">
              <RadioWrapper
                labels={['선택가능', '선택불가']}
                value={form?.isProductSelectable}
                setValue={setForm}
                type="isProductSelectable"
              />
            </Section>
            {!!form?.isProductSelectable ? (
              <>
                <Section title="자재카테고리">
                  <CustomCascader
                    deepDeps
                    value={form?.selectableProductCategoryIds}
                    placeholder="자재 카테고리를 선택해 주세요."
                    bordered={false}
                    style={{ width: '100%' }}
                    options={productCategoryItems}
                    onChange={(v) => {
                      setForm(
                        produce((draft) => {
                          draft.selectableProductCategoryIds = v;
                        }),
                      );
                    }}
                    setValue={(v) => {
                      setForm(
                        produce((draft) => {
                          draft.selectableProductCategoryIds = v;
                        }),
                      );
                    }}
                    multiple
                    maxTagCount="responsive"
                    showSearch={{
                      filter,
                    }}
                  />
                </Section>
                <Section title="제품최대개수">
                  <CustomInput
                    value={form?.maxProductCount}
                    placeholder="품목수를 입력해 주세요."
                    setValue={setForm}
                    type="maxProductCount"
                  />
                </Section>
                <Section title="자재중복허용">
                  <RadioWrapper
                    labels={['단품전용', '적용가능']}
                    value={form?.isSingleProduct}
                    setValue={setForm}
                    type="isSingleProduct"
                  />
                </Section>
              </>
            ) : (
              <Section title="적용금액">
                <CustomInput
                  value={form?.price}
                  placeholder="적용금액을 입력해 주세요."
                  setValue={setForm}
                  type="price"
                />
              </Section>
            )}
          </div>
          <Section title="공종별 기본금액(전체)">
            <CustomInput
              value={form?.defaultPrice}
              placeholder="공종별 기본 금액을 입력해 주세요."
              setValue={setForm}
              type="defaultPrice"
            />
          </Section>
          <Section title="공간별 기본금액">
            <CustomInput
              value={form?.pricePerRoom}
              placeholder="공간별 기본 금액을 입력해 주세요."
              setValue={setForm}
              type="pricePerRoom"
            />
          </Section>
          <Section title="단위">
            <Cascader
              value={form?.printUnitName}
              placeholder="단위를 선택해 주세요."
              bordered={false}
              style={{ width: '100%', borderBottom: '1px solid #e6e6e6' }}
              options={unitNameItems}
              onChange={(v) =>
                setForm(
                  produce((draft) => {
                    draft.printUnitName = v;
                  }),
                )
              }
            />
          </Section>
          <Section title="전산코드">
            <CustomInput
              value={form?.calculateCode}
              placeholder="전산코드를 입력해 주세요."
              setValue={setForm}
              type="calculateCode"
            />
          </Section>
          <Section title="연산식">
            <Select
              mode="tags"
              value={fieldItems}
              style={{ width: '100%' }}
              tagRender={({ label, value }) => {
                if (
                  Object.keys(CALC_KEYS)
                    ?.map((v) => '${' + v + '}')
                    .includes(value)
                ) {
                  return <Tag>{label}</Tag>;
                }
                return <>{value}</>;
              }}
              onChange={(value) => {
                setFieldItems(value);
              }}
              options={tagItems}
            />
          </Section>
          <Section title="모바일표시그룹">
            <Cascader
              value={form?.displayGroup}
              placeholder="모바일표시그룹을 선택해 주세요."
              bordered={false}
              style={{ width: '100%', borderBottom: '1px solid #e6e6e6' }}
              options={displayGroupItems}
              onChange={(v) =>
                setForm(
                  produce((draft) => {
                    draft.displayGroup = v;
                  }),
                )
              }
            />
          </Section>
          <Section title="상품 미적용시 표출여부">
            <RadioWrapper
              labels={['미적용시 빈칸 표출', '상품 적용시만 표출']}
              value={form?.isShowDefault}
              setValue={setForm}
              type="isShowDefault"
            />
          </Section>
          <div style={{ marginTop: '20px' }}>
            <Section title="제외옵션시 안내글">
              <CustomInput
                value={form?.confirmMessage}
                placeholder="안내글을 입력해 주세요."
                setValue={setForm}
                type="confirmMessage"
              />
            </Section>
            <Section title="제외옵션">
              <CustomCascader
                value={form?.excludes}
                setValue={(v) => {
                  setForm(
                    produce((draft) => {
                      draft.excludes = v;
                    }),
                  );
                }}
                placeholder="제외 옵션을 선택해 주세요."
                bordered={false}
                style={{
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
                options={templateItems}
                onChange={(v) => {
                  setForm(
                    produce((draft) => {
                      draft.excludes = v;
                    }),
                  );
                }}
                multiple
                maxTagCount="responsive"
                showSearch={{
                  filter,
                }}
              />
            </Section>
          </div>
          <div style={styles.buttonWrapper}>
            <>
              {!!templateId && (
                <RoundButton
                  onClick={() => handleDelete()}
                  label="삭제"
                  containerStyle={{
                    width: '100px',
                  }}
                  deleteButton
                />
              )}
              <RoundButton
                onClick={() => handleSubmit()}
                label={templateId ? '수정' : '저장'}
                containerStyle={{
                  width: '100px',
                }}
                reverse
              />
              <RoundButton
                onClick={() => {
                  onCancel();
                }}
                label="취소"
                containerStyle={{
                  width: '100px',
                }}
              />
            </>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default ModifyTemplateModal;
