import { Checkbox, Image, Modal, Switch } from 'antd';
import React from 'react';
import PaginateTable from '../components/table/PaginateTable';
import {
  ExtraWrapper,
  FilterButton,
  FilterInput,
  FilterSelect,
  TableCheckbox,
  TableSwitch,
} from '../components/template/page';
import TableColHeader from '../components//table/TableColHeader';
import moment from 'moment';
import { useGetConstructionCategories, useGetContentGroup, useGetPartnerGuide } from '../apis/queries';
import { CommonCodeContext } from '../context/CommonCodeContext';
import { contentTypeOBJ, parseImageUri } from '../common/utils';
import assets from '../assets';
import WritePostModal from '../components/modal/WritePostModal';
import { useQueryClient } from 'react-query';
import useUpperLine from './useUpperLine';
import { Option } from 'antd/lib/mentions';
import { restApi } from '../apis';
import { CONTENT_GROUP_KEY, PARTMER_GUIDE_KEY } from '../apis/queryKeys';
import ModalLowerLine from '../components/ModalLowerLine';
import ModifyAllButton from '../components/button/ModifyAllButton';
import deleteIcon from '../assets/delete.png';
import produce from 'immer';

const useConnectList = () => {
  const filters = React.useMemo(() => {
    return [
      {
        title: '컨텐츠타입',
        key: 'contentType',
        options: [
          undefined,
          ['STYLE', '스타일'],
          ['PORTFOLIO', '포트폴리오'],
          ['TREND', '트렌드'],
          ['CONSTRUCTION_GUIDE', '공사가이드'],
        ],
      },
    ];
  }, []);
  const [connect, setConnect] = React.useState();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { params: upperParam, UpperLine } = useUpperLine(filters);

  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: null,
      sort: null,
    }),
    [],
  );
  const [page, setPage] = React.useState(initialParams.page);
  const [pageSize, setPageSize] = React.useState(20);
  const [order, setOrder] = React.useState('DESC');

  // 모두 선택 버튼로직
  const [checkedList, setCheckedList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const { data: contentGroupData } = useGetContentGroup({
    params: { page: page, limit: pageSize, order: order, ...upperParam },
  });
  const allData = React.useMemo(() => {
    return contentGroupData?.content?.map((v) => ({
      data: v,
      id: v?.id,
    }));
  }, [contentGroupData]);

  React.useEffect(() => {
    if (!!allData) {
      const idList_ = allData?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [allData]);

  const { codeMapCOTP } = React.useContext(CommonCodeContext);
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 60 - 280);
  const [visibleModifyModal, setVisibleModifyModal] = React.useState(false);
  const [guideId, setGuideId] = React.useState(contentGroupData?.content[0]?.id);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const customColumns = React.useMemo(() => {
    return [
      {
        title: '리스트 연결',
        dataIndex: 'title',
        key: 'title',
        width: 100,
        align: 'center',
        render: (data, record) => {
          return (
            <div style={{ position: 'relative' }}>
              <button
                style={{ backgroundColor: '#1e3d83', borderRadius: 5, color: 'white', padding: '2px 5px 1px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setConnect(record.id);
                  setIsModalOpen(false);
                }}
              >
                연결하기
              </button>
            </div>
          );
        },
      },
      {
        title: '아이디',
        dataIndex: 'id',
        key: 'id',
        width: 30,
        align: 'center',
        render: (data, record) => {
          return <div style={{ position: 'relative' }}>{data}</div>;
        },
      },
      {
        title: <TableColHeader title={'제목'} subTitle={'컨텐츠 타입'} />,
        dataIndex: 'title',
        key: 'title',
        width: 100,
        align: 'center',
        render: (data, record) => {
          return (
            <div style={{ position: 'relative' }}>
              <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{record.title}</p>
              <p style={{ fontSize: 10, color: '#999' }}>{contentTypeOBJ[record.contentType]}</p>
            </div>
          );
        },
      },
      {
        title: '설명',
        dataIndex: 'description',
        key: 'description',
        width: 150,
        align: 'center',
        render: (data, record) => {
          return (
            <div>
              <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{data}</p>
            </div>
          );
        },
      },
      {
        title: '삭제',
        dataIndex: 'delete',
        key: 'delete',
        width: 50,
        align: 'center',
        render: (data, record) => {
          return (
            <div>
              <button
                style={{ width: 30, backgroundColor: 'rgba(0,0,0,0)' }}
                onClick={(e) => {
                  e.stopPropagation();
                  Modal.confirm({
                    content: '삭제하시겠니까?',
                    okText: '확인',
                    cancelText: '취소',
                    onOk: async () => {
                      try {
                        await restApi.delete('/content-groups', { data: { ids: [record.id] } });
                        Modal.success({
                          title: '성공',
                          content: '성공적으로 삭제되었습니다',
                          onOk: () => {
                            queryClient.invalidateQueries([CONTENT_GROUP_KEY]);
                          },
                        });
                      } catch (error) {
                        Modal.warning({
                          title: '오류',
                          content: '실패했습니다',
                        });
                      }
                    },
                  });
                }}
              >
                <img style={{ width: '100%' }} src={deleteIcon} alt="" />
              </button>
            </div>
          );
        },
      },
    ];
  }, [checkedList, idList]);
  const ConnectList = () => {
    return (
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={true}
        okText={'확인'}
        cancelText={'수정'}
      >
        <div style={{ padding: '35px 25px' }}>
          <p style={{ fontSize: 24, fontWeight: 600 }}>리스트 연결</p>
          <ExtraWrapper>
            {/* 필터 윗줄 */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 4.5,
                width: '100%',
                position: 'relative',
              }}
            >
              <UpperLine listKey={PARTMER_GUIDE_KEY} />
            </div>
            {/* 필터 아랫줄 */}
            <ModalLowerLine
              containerStyle={{ width: '100%' }}
              onCheck={() => {
                if (idList.length === checkedList.length) {
                  setCheckedList([]);
                } else {
                  setCheckedList(idList);
                }
              }}
              checked={idList.length === checkedList.length}
              text={'모두선택'}
              id={'agency-guide-modal-check'}
              hideAllCheck={true}
            ></ModalLowerLine>
          </ExtraWrapper>
          <PaginateTable
            customHeight={true}
            dataSource={contentGroupData?.content}
            columns={customColumns}
            data={contentGroupData}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            scroll={{ y: tableHeight - 240 }}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
            }}
          />
        </div>
        <WritePostModal
          visible={visibleModifyModal}
          setVisible={setVisibleModifyModal}
          handleOk={() => {}}
          handleCancel={() => {
            setVisibleModifyModal(false);
          }}
          isEdit={true}
          selectedId={guideId}
          type="construction-guides"
        />
      </Modal>
    );
  };
  return { connect, setConnect, showModal, ConnectList };
};

export default useConnectList;
