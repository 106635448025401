import { Modal } from 'antd';
import React from 'react';
import RoundButton from '../button/RoundButton';
import { useGetConstructionCategories, useGetContentDetail, useGetProductCategories } from '../../apis/queries';
import { findCategoryDeps, parseImageUri } from '../../common/utils';
import moment from 'moment';
import { useSelector } from 'react-redux';
import assets from '../../assets';
import Comment from '../comment/Comment';
import Thumbnail from '../image/Thumbnail';
import WriteContentModal from './WriteContentModal';
const styles = {
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    // height: '50px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px 20px 0px 20px',
  },
  titleImageWrapper: {
    width: '100%',
    height: '550px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  bodyWrapper: {
    padding: '0px 110px',
    marginTop: '50px',
    paddingBottom: '50px',
    display: 'flex',
    flexDirection: 'column',
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
  },
  profileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '6px',
  },
  contentIcon: {
    width: '20px',
    height: '20px',
  },
  constructionInfoWrapper: {
    marginTop: '65px',
    padding: '0px 120px',
  },
  constructionCardContainer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    gap: '28px',
  },
  constructionCardWrapper: {
    width: '72px',
    height: '72px',
    borderRadius: '10px',
    backgroundColor: '#1e3d83',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  constructionCardImage: {
    width: '25px',
    height: '25px',
    objectFit: 'contain',
  },
  constructionCardText: {
    marginTop: '5px',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
  },
  tplCategoryWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '10px',
  },
  tplItemWrapper: {
    width: '72.8px',
    height: '114px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tplItemCover: {
    width: '100%',
    height: '72.8px',
    borderRadius: '50%',
    boxShadow: '0 10px 30px 0 rgba(66, 89, 101, 0.16)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  tplIcon: {
    width: '35px',
    height: '35px',
    objectFit: 'contain',
  },
  tplCardText: {
    marginTop: '8.6px',
    fontSize: '12px',
    color: '#767676',
  },
  subContentsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  subSectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '140px',
  },
  titleText: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#000',
  },
  titleImage: {
    width: '100%',
    height: '435px',
    borderRadius: '10px',
    objectFit: 'cover',
    marginBlock:5,
  },
  contentText: {
    marginBlock:5,
    fontSize: '14px',
    color: '#767676',
  },
  productListWrapper: {
    width: '100%',
    height: '180px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    overflowX: 'scroll',
  },
  productList: {
    display: 'flex',
    flexDirection: 'row',
    gap: '13px',
  },
  productWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '103px',
    width: '80px',
  },
  productImage: {
    width: '100%',
    height: '80px',
    objectFit: 'cover',
    borderRadius: '15px',
  },
};
const SubSection = React.memo(({ productCategories, item, commonCodes }) => {
  const ProductList = React.memo(({ items, type = 'default' }) => {
    return (
      <div style={styles.productListWrapper}>
        <span
          style={{
            fontSize: '12px',
            fontWeight: '600',
          }}
        >
          {type === 'default' ? '공간별 적용 자재' : '공간별 적용 홈퍼니싱 상품'}
        </span>
        <div style={styles.productList}>
          {items?.map((v) => (
            <div style={styles.productWrapper}>
              <img style={styles.productImage} src={parseImageUri(v.thumbnailId)} alt={`product_${v.id}`} />
              {type === 'default' ? (
                <span
                  style={{
                    marginTop: '7px',
                    fontSize: '14px',
                    color: '#767676',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {v?.name}
                </span>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span
                    style={{
                      marginTop: '4px',
                      fontSize: '12px',
                      color: '#767676',
                    }}
                  >
                    {findCategoryDeps(productCategories.tree, v.categoryId).pop()}
                  </span>
                  <span
                    style={{
                      fontSize: '14px',
                      color: '#000',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {v.name}
                  </span>
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: '800',
                      width: '100%',
                      textAlign: 'right',
                    }}
                  >
                    {v?.price?.toLocaleString()}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  });
  return (
    <div style={styles.subSectionWrapper}>
      <span style={styles.titleText}>{commonCodes?.map[item?.area]}</span>
      <span style={{ ...styles.titleText, fontSize: '12px' }}>{item?.title}</span>
      {item?.subContentItems?.map(v=> (
          <>
            {v.thumbnailId &&
                <img style={styles.titleImage}
                     src={parseImageUri(v.thumbnailId)}
                     alt={`titleImage_${v.orderNum}`}/>}
            {
              v.content&&
                        <span style={styles.contentText}>{v.content}</span>
            }
          </>
      ))}

      {item?.appliedMaterials?.length > 0 && <ProductList items={item?.appliedMaterials} />}
      {item?.appliedHomeFurniture?.length > 0 && (
        <ProductList items={item?.appliedHomeFurniture} type="homeFurniture" />
      )}
    </div>
  );
});

const TplItem = React.memo(({ item, active }) => {
  const filter = active
    ? 'invert(22%) sepia(50%) saturate(1353%) hue-rotate(190deg) brightness(92%) contrast(96%)'
    : null;
  return (
    <div style={styles.tplItemWrapper}>
      <div style={styles.tplItemCover}>
        <img
          style={{
            ...styles.tplIcon,
            filter: filter,
          }}
          src={assets.tpl_category[`main_${item?.categoryId}`]}
          alt={`tpl_${item?.categoryId}`}
        />
      </div>
      <span style={styles.tplCardText}>{item?.categoryName}</span>
    </div>
  );
});

const ConstructionCard = React.memo(({ item }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={styles.constructionCardWrapper}>
        <img style={styles.constructionCardImage} alt={`image_${item?.title}`} src={item.assets} />
        <span style={styles.constructionCardText}>{item?.value}</span>
      </div>
      <span
        style={{
          marginTop: '5px',
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#1e3d83',
        }}
      >
        {item?.title}
      </span>
    </div>
  );
});

// type = styles, portfolios
const ContentDetailModal = React.memo(({ type, contentId, visible, setVisible, handleOk, handleCancel }) => {
  const [visibleModifyModal, setVisibleModifyModal] = React.useState(false);
  const { commonCodes } = useSelector((s) => s.common);
  const { data: constructionCategories } = useGetConstructionCategories();
  const { data: productCategories } = useGetProductCategories();
  const { data: contentDetail } = useGetContentDetail({
    type,
    id: contentId,
    config: {
      enabled: !!visible && !!contentId,
    },
  });

  const constructionConditionItems = React.useMemo(() => {
    const returnLabel = (code, value) => {
      return commonCodes?.tree[code]?.find((v) => v.code === value)?.extra1;
    };
    return [
      {
        title: '공사종목수',
        value: contentDetail?.appliedProcess?.length,
        assets: assets.constructions.length,
        label: `${contentDetail?.appliedProcess?.length}개의 공사 종목이 적용됩니다.`,
      },
      {
        title: '공사기간',
        value: commonCodes?.map[contentDetail?.constructionTerm],
        assets: assets.constructions.terms,
        label: returnLabel('COTM', contentDetail?.constructionTerm),
      },
      {
        title: '난이도',
        value: commonCodes?.map[contentDetail?.constructionDifficulty],
        assets: assets.constructions.difficulty,
        label: returnLabel('CODF', contentDetail?.constructionDifficulty),
      },
      {
        title: '공사비용',
        value: commonCodes?.map[contentDetail?.constructionCost],
        assets: assets.constructions.cost,
        label: returnLabel('COCO', contentDetail?.constructionCost),
      },
    ];
  }, [contentDetail, commonCodes]);

  return (
    <>
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 100,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
          padding: '0px',
        }}
        closable={false}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={true}
        okText={'확인'}
        footer={null}
        title={
          <div style={styles.header}>
            <RoundButton
                onClick={() => {
                  handleCancel();
                }}
                label="나가기"
                containerStyle={{
                  width: '100px',
                  marginRight: '10px',
                }}
            />
            <RoundButton
                onClick={() => setVisibleModifyModal(true)}
                reverse
                label="수정하기"
                containerStyle={{
                  width: '100px',
                }}
            />
          </div>
        }
        cancelButtonProps={{style: {display: 'none'}}}
      >
        <div style={styles.modalContainer}>

          <div
              style={{
                ...styles.titleImageWrapper,
                backgroundImage: `url(${parseImageUri(
                    contentDetail?.thumbnailId)})`,
              }}
          />
          <div style={styles.bodyWrapper}>
            <span style={{fontSize: '12px', color: '#767676'}}>{type ===
            'styles' ? '스타일' : '포트폴리오'}</span>
            <span
                style={{
                  fontSize: '16px',
                fontWeight: '600',
                color: '#000',
              }}
            >
              {contentDetail?.title}
            </span>
            <div style={styles.profileContainer}>
              <div style={styles.profileWrapper}>
                <Thumbnail
                  containerStyle={{
                    width: 30,
                    height: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  imageStyle={{ width: 30, height: 30, borderRadius: '50%' }}
                  id={contentDetail?.createdBy?.profileImageId}
                ></Thumbnail>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: '#000',
                    }}
                  >
                    {contentDetail?.createdBy?.nickname}
                  </span>
                  <span
                    style={{
                      fontSize: '12px',
                      color: '#767676',
                    }}
                  >
                    {moment(contentDetail?.createdAt).format('YYYY.MM.DD')}
                  </span>
                </div>
              </div>
              <div style={styles.contentButtonWrapper}>
                {/* TODO : 에셋 바꾸기 */}
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img alt="heart" style={styles.contentIcon} src={require('../../assets/heart.png')} />
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: '500',
                      color: '#999',
                    }}
                  >
                    {contentDetail?.likeInfo?.likeCount}
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img alt="heart" style={styles.contentIcon} src={require('../../assets/speechBubble.png')} />
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: '500',
                      color: '#999',
                    }}
                  >
                    {contentDetail?.shareCount}
                  </span>
                </div>
              </div>
            </div>
            <span
              style={{
                marginTop: '35px',
                fontSize: '14px',
                color: '#767676',
              }}
            >
              {contentDetail?.description}
            </span>
            <div style={styles.constructionInfoWrapper}>
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  color: '#000',
                }}
              >
                CONSTRUCTION CONDITION
              </span>
              <div style={styles.constructionCardContainer}>
                {constructionConditionItems?.map((v) => (
                  <ConstructionCard item={v} />
                ))}
              </div>
              <ul style={{ paddingLeft: '15px', marginTop: '25px', marginBottom: '90px' }}>
                {constructionConditionItems?.map((v) => (
                  <li
                    style={{
                      marginTop: '5px',
                      marginBottom: '5px',
                      fontSize: '14px',
                      color: '#767676',
                    }}
                  >
                    {v.label}
                  </li>
                ))}
              </ul>
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  color: '#000',
                }}
              >
                적용공종
              </span>
              <div style={styles.tplCategoryWrapper}>
                {constructionCategories?.tree?.map((v) => {
                  return <TplItem item={v} active={contentDetail?.appliedProcess?.includes(v.categoryId)} />;
                })}
              </div>
            </div>
            <div style={styles.subContentsWrapper}>
              {contentDetail?.subContents?.map((v) => (
                <SubSection productCategories={productCategories} commonCodes={commonCodes} item={v} />
              ))}
            </div>
            <div style={{ marginTop: '100px' }}>
              <Comment id={contentDetail?.id} type="CONTENT" />
            </div>
          </div>
        </div>
      </Modal>
      <WriteContentModal
        visible={visibleModifyModal}
        setVisible={setVisibleModifyModal}
        handleCancel={() => {
          setVisibleModifyModal(false);
        }}
        type="styles"
        isEdit={true}
        selectedId={contentId}
      />
    </>
  );
});
export default ContentDetailModal;
