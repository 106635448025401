import React, { useEffect, useState } from 'react';
import { PageHeader, Checkbox, Switch, Modal } from 'antd';
import CustomCheckBox from '../../../components/button/CustomCheckBox';
import Comment from '../../../components/comment/Comment';
import CommentIcon from '../../../components/image/CommentIcon';
import Thumbnail from '../../../components/image/Thumbnail';
import WritePostModal from '../../../components/modal/WritePostModal';
import TableColHeader from '../../../components/table/TableColHeader';
import styles from './notice.module.less';
import {
  ExtraWrapper,
  FilterButton,
  FilterInput,
  FilterSelect,
  FilterWhiteButton,
} from '../../../components/template/page';
import { Option } from 'antd/lib/mentions';
import produce from 'immer';
import moment from 'moment';
import { useGetGuides, useGetGuideDetail } from '../../../apis/queries';
import PaginateTable from '../../../components/table/PaginateTable';
import WriteNoticeModal from '../../../components/modal/WriteNoticeModal';
import { restApi } from '../../../apis';
import { useQueryClient } from 'react-query';
import { NOTICE_LIST_KEY, SERVICE_GUIDE_LIST_KEY } from '../../../apis/queryKeys';
import { parseImageUri } from '../../../common/utils';
import useUpperLine from '../../../hooks/useUpperLine';
import LowerLine from '../../../components/LowerLine';

const ManagementGuide = (props) => {
  const queryClient = useQueryClient();
  const initialParams = React.useMemo(
    () => ({
      isShow: null,
      keyword: null,
      limit: 50,
      page: 0,
      order: 'DESC',
    }),
    [],
  );
  const [visibleModal, setVisibleModal] = React.useState(false);
  const [params, setParams] = React.useState(initialParams);
  const [idList, setIdList] = React.useState([]);
  const [checkedList, setCheckedList] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState(null);
  const [imageError, setImageError] = React.useState(false); // 이미지 에러시 true
  const [isEdit, setIsEdit] = React.useState(false);
  const [commentCount, setCommentCount] = React.useState(0);
  const filters = React.useMemo(() => {
    const isShowItems = [
      [null, '전체'],
      [true, '게시'],
      [false, '중지'],
    ];
    return [
      {
        title: '게시여부',
        key: 'isShow',
        options: isShowItems,
      },
    ];
  }, []);
  const { params: upperParam, UpperLine } = useUpperLine(filters);
  React.useEffect(() => {
    if (upperParam) {
      setParams((prev) => ({ ...prev, ...upperParam }));
    }
  }, [upperParam]);
  const { data: noticeList } = useGetGuides({
    params,
    config: {
      onSuccess: (data) => {
        if (!selectedId) {
          // setSelectedId(data?.content[0]?.id);
        }
      },
    },
  });
  const { data: guideDetail } = useGetGuideDetail({
    id: selectedId,
    config: {
      enabled: !!selectedId,
    },
  });
  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 280);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);
  const data = React.useMemo(() => {
    if (!noticeList) return [];
    return noticeList?.content?.map((v) => ({
      ...v,
    }));
  }, [noticeList]);

  React.useEffect(() => {
    if (!!data) {
      const idList_ = data?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [data]);

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setImageError(false);
        setSelectedId(record.id);
      },
    };
  };

  const columns = [
    {
      title: '선택',
      dataIndex: 'id',
      key: 'id',
      width: 40,
      align: 'center',
      render: (v) => {
        return (
          <Checkbox
            key={`${v}_check`}
            checked={checkedList.includes(v)}
            onClick={() => {
              if (checkedList.includes(v)) {
                setCheckedList(checkedList.filter((v2) => v2 !== v));
              } else {
                setCheckedList([...checkedList, v]);
              }
            }}
          />
        );
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      width: 200,
      align: 'left',
      render: (v) => <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{v}</span>,
    },
    {
      title: '게시일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 80,
      align: 'center',
      render: (v) => <span style={{ color: '#767676', fontSize: '14px' }}>{moment(v).format('YYYY.MM.DD')}</span>,
    },
    {
      title: <TableColHeader title={'게시여부'} subTitle={'게시/중지'} />,
      dataIndex: 'isShow',
      key: 'isShow',
      width: 40,
      align: 'center',
      render: (v) => (
        <div style={{ transform: 'rotate(180deg)' }}>
          <Switch
            checked={v}
            onChange={(v) => {
              // handleChangeState([id], v ? 'ACTIVE' : 'READY');
            }}
          />
        </div>
      ),
    },
  ];

  const handleDeleteItems = (ids) => {
    Modal.confirm({
      content: '선택된 항목들을 삭제하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk: () => {
        restApi
          .delete('/contents', {
            data: {
              ids,
            },
          })
          .then(async (res) => {
            await queryClient.invalidateQueries(SERVICE_GUIDE_LIST_KEY);
            Modal.success({
              content: '삭제되었습니다.',
              okText: '확인',
            });
          });
      },
    });
  };
  return (
    <>
      <div>
        <PageHeader className="site-page-header" title="서비스 이용 가이드 관리" style={{ paddingBottom: 10 }} />
        <ExtraWrapper>
          {/* 필터 윗줄 */}
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4.5 ,justifyContent:'space-between'}}>
            <UpperLine containerStyle={{width:'65%'}}/>


              <div style={{ display: 'flex', gap: '10px' }}>
                <FilterWhiteButton
                  onClick={() => {
                    if (!selectedId) return alert('수정할 게시글을 선택해주세요');
                    setIsEdit(true);
                    setVisibleModal(true);
                  }}
                >
                  수정하기
                </FilterWhiteButton>
                <FilterButton onClick={() => setVisibleModal(true)}>새글쓰기</FilterButton>
              </div>
          </div>
          {/* 필터 아랫줄 */}
          <LowerLine
            containerStyle={{ width: '65%' }}
            onCheck={() => {
              if (idList.length === checkedList.length) {
                setCheckedList([]);
              } else {
                setCheckedList(idList);
              }
            }}
            checked={idList.length === checkedList.length}
            left={<div
                onClick={() => {
                  handleDeleteItems(checkedList);
                }}
                style={{cursor: 'pointer'}}
                className={styles.filterWrapper}
            >
              <img
                  src={require('../../../assets/delete.png')}
                  alt="delete"
                  style={{
                    width: '18.2px',
                    height: '21.2px',
                  }}
              />
              <span className={styles.filterText}>항목삭제</span>
            </div>}
          >


            <div style={{width:150}}>
              <FilterSelect
                  value={params?.order}
                  onChange={(v) =>
                      setParams(
                          produce((draft) => {
                            draft.order = v;
                          }),
                      )
                  }
              >
                <Option value="DESC">저장일시 최신순</Option>
              </FilterSelect>
            </div>
          </LowerLine>
        </ExtraWrapper>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PaginateTable
            dataSource={data}
            columns={columns}
            scroll={{ y: tableHeight - 40 }}
            data={noticeList}
            page={params?.page}
            setPage={(v) =>
              setParams(
                produce((draft) => {
                  draft.page = v;
                }),
              )
            }
            pageSize={params?.limit}
            setPageSize={(v) =>
              setParams(
                produce((draft) => {
                  draft.limit = v;
                }),
              )
            }
            onRow={onRow}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '65%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
            }}
          />
          {guideDetail && (
            <div
              style={{
                width: 550,
                paddingLeft: 10,
                overflowY: 'scroll',
                overflowX: 'hidden',
                height: document.body.clientHeight - 240,
              }}
            >
              {guideDetail?.thumbnailId && (
                <div className={styles.detailWrapper}>
                  {imageError ? (
                    <img
                      style={{ width: '100%', borderRadius: 10, maxHeight: 400 }}
                      src={require('../../../assets/alter.jpg')}
                      alt=""
                    />
                  ) : (
                    <img
                      onError={(e) => setImageError(true)}
                      style={{ width: '100%', borderRadius: 10, maxHeight: 400 }}
                      src={parseImageUri(guideDetail?.thumbnailId)}
                      alt=""
                    />
                  )}
                </div>
              )}
              <div className={styles.contentInfo}>
                <div className={styles.titleWrapper}>
                  <div>
                    <h3> {guideDetail?.title}</h3>
                    <div className={styles.profileWrapper}>
                      <Thumbnail
                        containerStyle={{
                          display: 'flex',
                          alignItems: 'center',
                          marginInline: 0,
                          width: 'min-content',
                        }}
                        imageStyle={{ borderRadius: '50%', overflow: 'hidden', width: 36, height: 36 }}
                        id={guideDetail?.createdBy?.profileImageId}
                      />
                      <div className={styles.nicknameWrapper}>
                        <span style={{ fontWeight: 'bold' }}>{guideDetail?.createdBy?.nickname}</span>
                        <span style={{ color: 'rgb(153, 153, 153)' }}>
                          {moment(guideDetail?.createdAt).format('YYYY.MM.DD')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.commentIconWrapper}>
                  <CommentIcon style={{ fontSize: 18, color: '#e8e8e8' }} />
                  <p className={styles.style_button_count_p}>{commentCount}</p>
                </div>
              </div>
              <p className={styles.description}>{guideDetail?.description}</p>
              {guideDetail?.subCreates?.map((subContent) => (
                <div style={{ marginBlock: 40 }}>
                  <div className={styles.detailWrapper}>
                    {imageError ? (
                      <img
                        style={{ width: '100%', borderRadius: 10, maxHeight: 400 }}
                        src={require('../../../assets/alter.jpg')}
                        alt=""
                      />
                    ) : (
                      <img
                        onError={(e) => setImageError(true)}
                        style={{ width: '100%', borderRadius: 10, maxHeight: 400 }}
                        src={parseImageUri(subContent?.thumbnailId)}
                        alt=""
                      />
                    )}
                  </div>
                  <div className={styles.titleWrapper}>
                    <div>
                      <h3> {guideDetail?.title}</h3>
                    </div>
                    <p className={styles.description}>{subContent?.description}</p>
                  </div>
                </div>
              ))}
              <div>
                <Comment setCommentCount={setCommentCount} id={selectedId} type={'CONTENT'} />
              </div>
            </div>
          )}
        </div>
      </div>
      <WritePostModal
        visible={visibleModal}
        setVisible={setVisibleModal}
        handleOk={() => {}}
        handleCancel={() => {
          setVisibleModal(false);
          setIsEdit(false);
        }}
        type="guides"
        isEdit={isEdit}
        selectedId={selectedId}
      />
    </>
  );
};

export default ManagementGuide;
