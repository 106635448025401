import React from 'react';
import { PageHeader } from 'antd';
import HomeCard from '../components/card/HomeCard/HomeCard';
import styles from './homeIndex.module.less';
import RoundButton from '../components/button/RoundButton';
import { useNavigate } from 'react-router-dom';

const Sections = React.memo((data) => {
  const navigate = useNavigate();
  const { title, subTitle, asset, countLabel, count, countPrefix, previousCount, url } = data;
  const [hovered, setHovered] = React.useState(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <div onClick={() => {
      navigate(url);
    }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={styles.content_div}>
      <div className={styles.contentWrapper}>
        <div className={styles.titleWrapper}>
          <span className={styles.title}>{title}</span>
          <span className={styles.subTitle}>{subTitle}</span>
        </div>
        <img className={styles.image} src={asset} alt="img" />
        <div className={styles.countWrapper}>
          {countLabel && (
            <span
              className={styles.label}
              style={{
                minWidth: '70px',
                position: 'absolute',
                textAlign: 'right',
                left: 25,
                fontSize: '12px',
                marginRight: '2px',
                alignSelf: 'flex-start',
              }}
            >
              {countLabel}
            </span>
          )}
          <span className={styles.mainCountText}>{count?.toLocaleString()}</span>
          <span
            className={styles.label}
            style={{
              position: 'absolute',
              fontSize: '12px',
              marginLeft: '2px',
            }}
          >
            {countPrefix}
          </span>
        </div>
        <span className={styles.label}>{previousCount ? `전일 대비 ${previousCount}` : ' '}</span>
        <div style={{ marginTop: '45px', zIndex: 99 }}>
          <RoundButton
            containerStyle={{
              padding: '8px 29px 8px 29px',
            }}
            reverse={hovered}
            label="바로가기"
            onClick={() => {
              navigate(url);
            }}
          />
        </div>
      </div>
    </div>
  );
});

const HomeIndex = (props) => {
  const handleResize = () => {
    setBodyHeight(document.body.clientHeight - 200);
  };
  const [bodyHeight, setBodyHeight] = React.useState(document.body.clientHeight - 200);
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      // cleanup
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div style={{}}>
      <PageHeader className="site-page-header" title="" subTitle="" />
      <div
        style={{
          // width: '70%',
          // overflowX: 'scroll',
          minWidth:943,
          maxWidth:1200,
          gap:'10px 30px',
          // height: bodyHeight,
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginTop: 100,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <HomeCard width="30%">
          <Sections
            title="회원관리"
            subTitle="소비자 회원"
            asset={require('../assets/profile.png')}
            countLabel="소비자회원 총"
            count={54234}
            countPrefix="명"
            previousCount="+1,125"
            url="/accounts/users"
          />
          <Sections
            title="회원관리"
            subTitle="시공사 회원"
            asset={require('../assets/profile.png')}
            countLabel="시공사 회원 총"
            count={54234}
            countPrefix="명"
            previousCount="+1,125"
            url="/accounts/agencies/interior"
          />
        </HomeCard>
        <HomeCard width="30%">
          <Sections
            title="공사도움받기"
            subTitle="실시간 견적의뢰"
            asset={require('../assets/EstimateRequest.png')}
            countLabel="소비자회원 총"
            count={10125}
            countPrefix="건/금일"
            previousCount="+1,125"
            url="/expert/proposal"
          />
        </HomeCard>
        <HomeCard width="30%">
          <Sections
            title="CM"
            subTitle="상품등록관리"
            asset={require('../assets/product.png')}
            countLabel="등록상품수 총"
            count={155254}
            countPrefix="품목"
            previousCount="+52"
            url="/products/items"
          />
        </HomeCard>
        <HomeCard width="30%">
          <Sections
            title="컨텐츠 관리"
            subTitle="스타일"
            asset={require('../assets/speech.png')}
            countLabel="총"
            count={25254}
            countPrefix="건"
            previousCount="+1125"
            url="/collections/magazines"
          />
        </HomeCard>
        <HomeCard width="30%">
          <Sections
            title="글쓰기 관리"
            subTitle="소비자 게시판"
            asset={require('../assets/note.png')}
            countLabel="게시글 총"
            count={54234}
            countPrefix="건"
            previousCount="+1125"
            url="/writing/customer"
          />
        </HomeCard>
        <HomeCard width="30%">
          <Sections
            title="공지사항 관리"
            subTitle="공지사항 관리"
            asset={require('../assets/notice.png')}
            countLabel="누적 조회수 총"
            count={54234}
            countPrefix="건"
            url="/settings/notice"
          />
        </HomeCard>
      </div>
    </div>
  );
};

export default HomeIndex;
