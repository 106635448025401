import { Table } from 'antd';
import React from 'react';
import styled from 'styled-components';

const DataTabel = styled(Table)`
  thead .ant-table-cell {
    border: none;
    border-bottom: 0.5px solid #000;
  }
  .ant-table-thead > tr > th:first-child {
    border-left: none;
  }
  .ant-table-thead > tr > th:last-child {
    border-right: none;
  }
  tbody .ant-table-cell {
    border: none;
  }
  .ant-table-column-title {
    flex: none;

  }
  .ant-table-column-sorters {
    justify-content: center;
  }
`;

const StyledTable = (props) => {
  return <DataTabel {...props} />;
};

export default StyledTable;
