import React from 'react';
import styles from './UserDetail.module.less';
import { Switch } from 'antd';
import { parseImageUri } from '../../../common/utils';
import useUserConstructionModal from '../../../hooks/useUserConstructionModal';
import useUserFollowModal from '../../../hooks/useUserFollowModal';
import useUserGoodsModal from '../../../hooks/useUserGoodsModal';
import useCommentModal from '../../../hooks/useCommentModal';
import useQuestionBoard from '../../../hooks/useQuestionBoard';
import { useGetUserDetail } from '../../../apis/queries';
import { useSelector } from 'react-redux';
import LifeStyle from '../../lifestyle';
import { useNavigate } from 'react-router-dom';

const UserDetail = ({ userType, agency, data, selectedId }) => {
  const navigate = useNavigate();
  const { commonCodes } = useSelector((s) => s.common);
  const { data: detailData } = useGetUserDetail({
    id: selectedId,
    config: {
      enabled: !!selectedId,
    },
  });
  const rateItems = React.useMemo(() => {
    const convert = (data, title) => {
      const code = data?.substring(0, 4);
      const target = commonCodes?.tree[code]?.find((v) => v.code === data);
      return {
        title,
        rate: target.extra1,
        label: target.label,
      };
    };
    if (!detailData || !commonCodes) return {};

    const data = {
      estimateRate: convert(detailData?.estimateRate, '견적지수'),
      experienceRate: convert(detailData?.experienceRate, '경험지수'),
      commentRate: convert(detailData?.commentRate, '댓글지수'),
    };

    return data;
  }, [detailData, commonCodes]);
  console.log(rateItems);

  const { showModal: showConstructionModal, UserConstructionModal } = useUserConstructionModal({ userType });
  const { showModal: showFollowModal, UserFollowModal } = useUserFollowModal({ userType });
  const { showModal: showGoodsModal, UserGoodsModal } = useUserGoodsModal({ userType });
  const { showModal: showCommentModal, CommentModal } = useCommentModal({ userType });
  const { showModal: showQuestionBoardModal, UserQuestionBoardModal } = useQuestionBoard({ userType });

  const saveContentAction = (type) => {
    navigate(`/accounts/save/${type}/${selectedId}/user`);
  };

  const saveEstimateAction = () => {
    navigate(`/accounts/user/saveestimate/${selectedId}`);
  };

  const saveRequestAction = () => {
    navigate(`/accounts/user/save/request/${selectedId}`);
  };
  const myPageList = React.useMemo(
    () => [
      {
        title: '견적',
        newItem: detailData?.estimateCount,
        buttonView: true,
        buttonAction: saveEstimateAction,
        type: 'customer',
      },
      {
        title: '공사도움받기',
        newItem: detailData?.constructionHelpCount,
        buttonView: true,
        buttonAction: () => saveRequestAction(),
        type: 'customer',
      },
      {
        title: '스타일',
        newItem: detailData?.savedStyleCount,
        middleSwitch: userType === 'agencies' && true,
        disabled: agency === 'influencer' ? true : false,
        buttonAction: () => saveContentAction('styles'),
        buttonView: true,
        type: userType,
      },
      {
        title: '포트폴리오',
        newItem: detailData?.savedPortfolioCount,
        middleSwitch: userType === 'agencies' && true,
        disabled: agency === 'influencer' ? true : false,
        buttonAction: () => saveContentAction('portfolios'),
        buttonView: true,
        type: userType,
      },
      {
        title: '트렌드',
        newItem: detailData?.savedTrendCount,
        buttonView: true,
        buttonAction: () => showConstructionModal('trends'),
        type: userType,
      },
      {
        title: '공사가이드',
        newItem: detailData?.savedConstructionGuideCount,
        buttonAction: () => showConstructionModal('construction-guides'),
        buttonView: true,
        type: userType,
      },

      {
        title: '팔로우',
        newItem: detailData?.followingContractorCount,
        buttonAction: () => showFollowModal(),
        buttonView: true,
        type: 'customer',
      },
      // {
      //   title: '찜한상품',
      //   newItem: detailData?.likedProductCount,
      //   // buttonAction: goodsAction,
      //   buttonView: true,
      //   type: 'customer',
      // },
      {
        title: '무엇이든 물어보세요',
        newItem: detailData?.postCount,
        buttonAction: () => showQuestionBoardModal(),
        buttonView: true,
        type: 'customer',
      },

      {
        title: '댓글',
        newItem: detailData?.commentCount,
        buttonAction: () => showCommentModal(),
        buttonView: true,
        type: userType,
      },
    ],
    [detailData, showConstructionModal],
  );
  console.log(detailData);
  return (
    <>
      <h3 style={{ margin: '0 0 5px' }}>고객정보</h3>
      <div className={styles.user_detail}>
        <div
          className={styles.side_line}
          style={{ backgroundColor: userType === 'agencies' ? '#1e3d83' : '#7eccde' }}
        ></div>
        <div style={{ paddingBottom: 10, flex: 1 }}>
          <div style={{ display: 'flex', alignItems: 'center', padding: '20px 50px' }}>
            <div style={{ marginRight: '20px' }}>
              <div
                style={{
                  width: '70px',
                  height: '70px',
                  borderRadius: '50%',
                  backgroundColor: '#d9d9d9',
                  backgroundImage: `url(${parseImageUri(detailData?.profileImageId)})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              ></div>
              <div className={styles.label}> 인테리어 {commonCodes?.map[detailData?.estimateRate]}</div>
            </div>
            <div>
              <p className={styles.name}>{detailData?.nickname}</p>
              <p className={styles.email}>{detailData?.email}</p>
              <p className={styles.phone}>{detailData?.phone?.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}</p>
            </div>
          </div>
          <div className={styles.level_container}>
            {Object.values(rateItems)?.map((v, i) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} key={`level ${i}`}>
                  <span
                    style={{
                      fontSize: '11px',
                      color: '#767676',
                      fontWeight: '600',
                    }}
                  >
                    {v.title}
                  </span>
                  <div
                    style={{
                      width: '50px',
                      padding: '5px 3px',
                      backgroundColor: '#d9d9d9',
                      borderRadius: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        color: '#767676',
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >{`LV.${v.rate}`}</span>
                    <span
                      style={{
                        fontSize: '11px',
                        color: '#767676',
                      }}
                    >
                      {v.label}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          {userType === 'customer' && (
            <div className={styles.life_container}>
              <LifeStyle data={detailData?.lifestyle} />
            </div>
          )}
          <div>
            {myPageList.map((item, index) => {
              return (
                item?.type == userType && (
                  <div key={`myDetail ${index}`} className={styles.my_page_list}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '40%' }}>
                      <span style={{ color: '#767676' }}>{item.title}</span>
                      <span className={styles.new_item}>{item.newItem}</span>
                    </div>
                    {item?.middleSwitch && (
                      <Switch disabled={item?.disabled} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    )}
                    {item?.buttonView && (
                      <button
                        className={styles.more_button}
                        onClick={item?.buttonAction ? item?.buttonAction : () => {}}
                        disabled={item?.disabled}
                      >
                        내용보기
                      </button>
                    )}
                    {item?.switchView && (
                      <div style={{ display: 'flex', alignItems: 'center', height: 34, marginLeft: 'auto' }}>
                        <Switch disabled={item?.disabled} value={item?.value} />
                      </div>
                    )}
                    {item?.customView && (
                      <button
                        onClick={item?.buttonAction ? item?.buttonAction : () => {}}
                        disabled={item?.disabled}
                        style={{
                          marginLeft: 'auto',
                          height: 34,
                          color: agency === 'influencer' ? '#c9c9c9' : '#1e3d83',
                          fontWeight: 'bold',
                        }}
                      >
                        {item?.customView}
                      </button>
                    )}
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
      <UserConstructionModal selectedId={selectedId} />
      <UserFollowModal selectedId={selectedId} />
      <CommentModal selectedId={selectedId} />
      <UserQuestionBoardModal id={selectedId} />
    </>
  );
};

export default UserDetail;
