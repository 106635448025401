import { Checkbox, Col, Modal, PageHeader, Row, Radio, DatePicker, Space, Table, Switch, Spin } from 'antd';
import React from 'react';
import PaginateTable from '../../../components/table/PaginateTable';
import UserDetail from '../../../components/card/userdetail/UserDetail';
import qs from 'query-string';
import { OPERATION_MAIN_POPUP_LIST } from '../../../data/dummy/operation';
import {
  ExtraWrapper,
  FilterButton,
  FilterInput,
  FilterSelect,
  TableCheckbox,
  TableHeader,
  TableOrderFilter,
  TableSwitch,
} from '../../../components/template/page';
import CommonStatistics from '../../../components/card/CommonStatistics,js/CommonStatistics';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './main_banner.module.less';
import modalImage from '../../../assets/dummy/02/02-01.jpg';
import { CloseCircleFilled, SyncOutlined } from '@ant-design/icons';
import { useGetMainBanner, useGetMainBannerDetail } from '../../../apis/queries';
import { useQueryClient } from 'react-query';
import TableColHeader from '../../../components/table/TableColHeader';
import { getFileId, handleModifyShow, handleShowAndStop, parseImageUri } from '../../../common/utils';
import {
  MAIN_BANNER_LIST_DETAIL_KEY,
  MAIN_BANNER_LIST_KEY, POPUP_LIST_DETAIL_KEY,
  POPUP_LIST_KEY,
} from '../../../apis/queryKeys';
import produce from 'immer';
import moment from 'moment';
import { restApi } from '../../../apis';
import AddBannerModal from './AddBannerModal';
import ModifyAllButton from '../../../components/button/ModifyAllButton';
import useSearchBar from '../../../hooks/useSearchBar';
import useUpperLine from '../../../hooks/useUpperLine';
import LowerLine from '../../../components/LowerLine';
import { Option } from 'antd/lib/mentions';
import useConnectList from '../../../hooks/useConnectList';

const OperationMainBannerIndex = () => {
  const { RangePicker } = DatePicker;
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  //리스트 연결 모달
  const { connect, showModal: showList, ConnectList } = useConnectList();

  //필터 윗줄 생성(keword, filter params 포함, query param 에 spread 구문으로 삽입)
  const filters = React.useMemo(() => {
    return [{ title: '게시여부', key: 'isShow', options: [undefined, [true, '게시'], [false, '중지']] }];
  }, []);
  const { params: upperParam, UpperLine } = useUpperLine(filters);

  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: 'DESC',
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  React.useEffect(() => {
    if (upperParam) {
      setParams((prev) => ({ ...prev, ...upperParam }));
    }
  }, [upperParam]);

  // 모두 선택 버튼로직
  const [checkedList, setCheckedList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const { data: mainBannerData } = useGetMainBanner({
    params,
  });
  const allData = React.useMemo(() => {
    return mainBannerData?.content?.map((v) => ({
      data: v,
      id: v?.id,
    }));
  }, [mainBannerData]);
  React.useEffect(() => {
    if (!!allData) {
      const idList_ = allData?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [allData]);

  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    if (imageId) {
      setDetailModify(
        produce((draft) => {
          draft.imageId = imageId;
        }),
      );
    }
  };

  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 60 - 280);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isListModal, setIsListModal] = React.useState(false);
  const [isAddModal, setIsAddModal] = React.useState();
  const [radioValue, setRadioValue] = React.useState(2);
  const [refreshDetail, setRefreshDetail] = React.useState();
  React.useEffect(() => {
    if (!refreshDetail) {
      setRefreshDetail(mainBannerData?.content[0]?.id);
    }
  }, [mainBannerData]);
  const { data: mainBannerDetailData, isFetchedAfterMount } = useGetMainBannerDetail({
    id: refreshDetail ? refreshDetail : mainBannerData?.content[0]?.id,
    params: {},
  });
  const [detailModify, setDetailModify] = React.useState({
    title: mainBannerDetailData?.title ?? '',
    memo: mainBannerDetailData?.memo ?? '',
    imageId: mainBannerDetailData?.imageId ?? null,
    sortNum: mainBannerDetailData?.sortNum ?? null,
    startAt: mainBannerDetailData?.startAt ?? undefined,
    endAt: mainBannerDetailData?.endAt ?? undefined,
    link: mainBannerDetailData?.link ?? null,
    isLimit: mainBannerDetailData?.isLimit ?? null,
    contentGroupId: connect ?? mainBannerDetailData?.contentGroupId ?? null,
  });

  React.useEffect(() => {
    setDetailModify(
      produce((draft) => {
        draft.contentGroupId = connect;
      }),
    );
  }, [connect]);
  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
    setRadioValue(mainBannerDetailData?.isLimit ? 2 : 1);
    setDetailModify(
      produce((draft) => {
        draft.title = mainBannerDetailData?.title;
        draft.memo = mainBannerDetailData?.memo;
        draft.imageId = mainBannerDetailData?.imageId;
        draft.sortNum = mainBannerDetailData?.sortNum;
        draft.startAt = mainBannerDetailData?.startAt ? mainBannerDetailData?.startAt : undefined;
        draft.endAt = mainBannerDetailData?.endAt ? mainBannerDetailData?.endAt : undefined;
        draft.link = mainBannerDetailData?.link;
        draft.isLimit = mainBannerDetailData?.isLimit;
        draft.contentGroupId = mainBannerDetailData?.contentGroupId;
      }),
    );
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    Modal.confirm({
      title: '수정',
      content: '이 값으로 수정하시겠습니까?',
      okText: '수정',
      cancelText: '취소',
      async onOk() {
        try {
          await restApi.put(`/banner/main/${refreshDetail}`, detailModify);
          Modal.confirm({
            title: '완료',
            content: '수정이 완료되었습니다',
            onOk() {
              queryClient.invalidateQueries([MAIN_BANNER_LIST_KEY]);
              queryClient.invalidateQueries([MAIN_BANNER_LIST_DETAIL_KEY]);
              setIsModalOpen(false);
            },
            cancelButtonProps: {
              style: { display: 'none' },
            },
            okText: '확인',
          });
        } catch (error) {
          Modal.warning({
            title: '오류',
            content: error.response.data.message,
          });
        }
      },
    });
  };
  const radioChange = (e) => {
    if (e.target.value === 1) {
      setDetailModify(
        produce((draft) => {
          draft.startAt = undefined;
          draft.endAt = undefined;
          draft.isLimit = false;
        }),
      );
    } else {
      setDetailModify(
        produce((draft) => {
          draft.isLimit = true;
        }),
      );
    }
    setRadioValue(e.target.value);
  };
  const onRow = (record) => {
    return {
      onClick: (event) => {
        setRefreshDetail(record.id);
        queryClient.invalidateQueries([MAIN_BANNER_LIST_DETAIL_KEY]);
      },
    };
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="메인 배너" style={{ paddingBottom: 10 }} />
      <ExtraWrapper>
        {/* 필터 윗줄 */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 4.5 }}>
          <UpperLine containerStyle={{width:'65%'}} listKey={MAIN_BANNER_LIST_KEY} detailKey={MAIN_BANNER_LIST_DETAIL_KEY} setRefresh={setRefreshDetail} childrenRight={
            <div style={{marginLeft:5}}/>
          } />
          <FilterButton
              onClick={() => {
                setIsAddModal(true);
              }}
          >
            새로 작성
          </FilterButton>
        </div>
        {/* 필터 아랫줄 */}
        <div style={{ width: '65%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <LowerLine
              onCheck={() => {
                if (idList.length === checkedList.length) {
                  setCheckedList([]);
                } else {
                  setCheckedList(idList);
                }
              }}
              checked={idList.length === checkedList.length}
              left={<ModifyAllButton
                  showText={'모두 게시'}
                  stopText={'모두 중지'}
                  showAction={() => {
                    handleShowAndStop('/popup/show', checkedList, true, () => {
                      queryClient.invalidateQueries([MAIN_BANNER_LIST_KEY]);
                    });
                  }}
                  stopAction={() => {
                    handleShowAndStop('/popup/show', checkedList, false, () => {
                      queryClient.invalidateQueries([MAIN_BANNER_LIST_KEY]);
                    });
                  }}
              />}
          >

          </LowerLine>
          <div style={{width:170}}>
          <FilterSelect
            value={params?.order}
            onChange={(v) => {
              setParams(
                produce((draft) => {
                  draft.order = v;
                }),
              );
            }}
          >
            <Option value="DESC">저장일시 최신순</Option>
          </FilterSelect>
          </div>
        </div>
      </ExtraWrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PaginateTable
          dataSource={mainBannerData?.content}
          scroll={{ y: tableHeight - 40 }}
          data={mainBannerData}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '65%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
          page={params?.page}
          setPage={(v) =>
            setParams(
              produce((draft) => {
                draft.page = v;
              }),
            )
          }
          pageSize={params?.limit}
          setPageSize={(v) =>
            setParams(
              produce((draft) => {
                draft.limit = v;
              }),
            )
          }
          onRow={onRow}
          columns={[
            {
              title: '선택',
              dataIndex: 'data',
              key: 'data',
              width: 20,
              align: 'center',
              render: (data, record) => {
                return (
                  <>
                    <Checkbox
                      key={`${record.id}_check`}
                      checked={checkedList.includes(record.id)}
                      onClick={() => {
                        if (checkedList.includes(record.id)) {
                          setCheckedList(checkedList.filter((v) => v !== record.id));
                        } else {
                          setCheckedList([...checkedList, record.id]);
                        }
                      }}
                    />
                  </>
                );
              },
            },
            {
              title: '썸네일',
              dataIndex: 'imageId',
              key: 'imageId',
              width: 30,
              align: 'center',
              render: (data, record) => {
                return (
                  <div style={{ position: 'relative' }}>
                    {data ? (
                      <img
                        src={parseImageUri(data)}
                        alt="배너 이미지"
                        style={{ width: 50, height: 50, borderRadius: 10 }}
                      ></img>
                    ) : (
                      <div style={{ width: 50, height: 50 }} />
                    )}
                    <button
                      style={{
                        position: 'absolute',
                        top: -2,
                        right: -5,
                        zIndex: 10,
                        padding: 0,
                        width: 18,
                        height: 18,
                        border: 'none',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(255,255,255,1)',
                      }}
                      onClick={() => {
                        Modal.confirm({
                          title: '삭제',
                          content: '해당 글을 삭제하시겠습니까?',
                          okText: '확인',
                          cancelText: '취소',
                          async onOk() {
                            try {
                              await restApi.delete(`/banner/main/${record?.id}`);
                              Modal.confirm({
                                title: '완료',
                                content: '삭제가 완료되었습니다',
                                async onOk() {
                                  await queryClient.invalidateQueries([MAIN_BANNER_LIST_KEY]);
                                  await queryClient.invalidateQueries([MAIN_BANNER_LIST_DETAIL_KEY]);
                                  setRefreshDetail(mainBannerData?.content[0]?.id);
                                },
                                cancelButtonProps: {
                                  style: { display: 'none' },
                                },
                                okText: '확인',
                              });
                            } catch (error) {
                              Modal.warning({
                                title: '실패했습니다',
                                content: error.response.data.message,
                              });
                            }
                          },
                        });
                      }}
                    >
                      <CloseCircleFilled style={{ fontSize: 18, color: '#1e3d83' }} />
                    </button>
                  </div>
                );
              },
            },
            {
              title: <TableColHeader title={'제목'} subTitle={'메모'} />,
              dataIndex: 'title',
              key: 'title',
              width: 150,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>{record.title}</p>
                    <p style={{ fontSize: 10, color: '#999' }}>{record.memo}</p>
                  </div>
                );
              },
            },
            {
              title: '게시일',
              dataIndex: 'startAt',
              key: 'startAt',
              width: 60,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>
                      {data ? (
                        data
                      ) : record.isShow ? (
                        <span style={{ fontWeight: 'bold', color: '#1e3d83' }}>영구게시</span>
                      ) : (
                        <span style={{ fontWeight: 'bold', color: 'red' }}>게시중지</span>
                      )}
                    </p>
                  </div>
                );
              },
            },
            {
              title: '만료일',
              dataIndex: 'endAt',
              key: 'endAt',
              width: 60,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>
                      {data ? (
                        data
                      ) : record.isShow ? (
                        <span style={{ fontWeight: 'bold', color: '#1e3d83' }}>영구게시</span>
                      ) : (
                        <span style={{ fontWeight: 'bold', color: 'red' }}>게시중지</span>
                      )}
                    </p>
                  </div>
                );
              },
            },
            {
              title: '순서',
              dataIndex: 'sortNum',
              key: 'sortNum',
              width: 30,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>{data}</p>
                  </div>
                );
              },
            },
            {
              title: <TableColHeader title={'게시여부'} subTitle={'게시/중지'} />,
              dataIndex: 'isShow',
              key: 'isShow',
              width: 30,
              align: 'center',
              render: (data, record) => {
                return (
                  <Switch
                    checked={data}
                    onClick={() => {
                      handleModifyShow(`/banner/main/show`, record.id, data, () => {
                        queryClient.invalidateQueries([MAIN_BANNER_LIST_KEY]);
                      });
                    }}
                  />
                );
              },
            },
          ]}
        />
        <div
          style={{

            paddingLeft: 20,
            overflowY: 'scroll',
            height: document.body.clientHeight - 240,
            paddingBottom: 15,
          }}
        >
          <p style={{ marginBottom: 15 }}>미리보기</p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              padding: 20,
              backgroundColor: 'white',
              height: 900,
            }}
          >
            {isFetchedAfterMount ? (
              <div style={{ backgroundColor: '#e8e8e8', height: '240px', borderRadius: 10, overflow: 'hidden' }}>
                {mainBannerDetailData?.imageId ? (
                  <img
                    src={parseImageUri(mainBannerDetailData?.imageId)}
                    alt="배너 이미지"
                    style={{ width: '100%', height: 240 }}
                  />
                ) : (
                  <div style={{  height: '240px', backgroundColor: 'black' }}></div>
                )}
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: '#e8e8e8',
                  height: '240px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Spin size="large" />
              </div>
            )}
            <div className={styles.main_carousel_wrapper_div}>
              <div className={styles.main_carousel_div}></div>
              <div className={styles.main_carousel_div}></div>
              <div className={styles.main_carousel_div}></div>
              <div className={styles.main_carousel_div}></div>
              <div className={styles.main_carousel_div}></div>
            </div>
            <div className={styles.main_carousel_wrapper_div}>
              <div className={styles.main_carousel_div}></div>
              <div className={styles.main_carousel_div}></div>
              <div className={styles.main_carousel_div}></div>
              <div className={styles.main_carousel_div}></div>
              <div className={styles.main_carousel_div}></div>
            </div>
            <div className={styles.main_footer_banner}></div>
            <div style={{ textAlign: 'center' }}>
              <button
                style={{ marginBottom: 20, padding: '3px 18px', borderRadius: '20px', border: '1.5px solid #1e3d83' }}
                onClick={showModal}
              >
                수정
              </button>
              <p className={styles.preview_text}>※ 미리보기는 참고용입니다. 모바일에서 실제 화면을 확인하세요.</p>
            </div>
          </div>
          <CommonStatistics />
        </div>
      </div>
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        okText={'수정하기'}
        cancelText={'취소하기'}
      >
        <p
          style={{
            margin: '40px 46.5px 28px 50px',
            fontSize: 24,
            fontWeight: 600,
          }}
        >
          메인배너 수정
        </p>
        <div
          style={{
            width: 400,
            height: 800,
            backgroundColor: 'white',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 100,
            padding: 20,
          }}
        >
          <div
            style={{
              position: 'relative',
              width: 150,
              height: 150,
              marginLeft: 'auto',
              marginRight: 'auto',
              backgroundImage: `url(${parseImageUri(detailModify?.imageId)})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              borderRadius: 10,
            }}
          >
            <button
              style={{
                width: 50,
                height: 50,
                backgroundColor: 'rgba(0,0,0,0.2)',
                padding: 10,
                position: 'absolute',
                top: 3,
                right: 3,
                borderRadius: '50%',
              }}
            >
              <input
                type="file"
                id="fileInput"
                style={{
                  width: '100%',
                  height: '100%',
                  opacity: 0,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  cursor: 'pointer',
                }}
                onChange={handleChangeImage}
              />
              <SyncOutlined style={{ fontSize: 30, color: '#1e3d83' }} />
            </button>
          </div>
          <input
            type="text"
            style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '30px 0' }}
            placeholder="제목을 입력해주세요."
            value={detailModify?.title}
            onChange={(e) => {
              setDetailModify(
                produce((draft) => {
                  draft.title = e.target.value;
                }),
              );
            }}
          />
          <div style={{ display: 'flex', borderBottom: '1px solid #d9d9d9' }}>
            <input
              type="text"
              style={{ width: '88%', marginRight: 5, border: 'none' }}
              placeholder="메모"
              maxLength={79}
              value={detailModify?.memo}
              onChange={(e) => {
                setDetailModify(
                  produce((draft) => {
                    draft.memo = e.target.value;
                  }),
                );
              }}
            />
            <span style={{ color: '#767676', fontSize: 12, display: 'inline-block', width: '10%' }}>
              {detailModify?.memo.length}/80
            </span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', alignItems: 'center' }}>
            <input
              type="text"
              disabled
              placeholder="연결된 컨텐츠가 없습니다"
              value={detailModify?.contentGroupId}
              style={{ width: '50%', border: 'none', borderBottom: '1px solid #d9d9d9', color: '#767676' }}
            />
            <button
              style={{ border: '2px solid #1e3d83', padding: '4px 18px', borderRadius: '15px', fontWeight: 900 }}
              onClick={showList}
            >
              리스트 연결
            </button>
          </div>
          <input
            type="text"
            style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '30px 0' }}
            placeholder="순서"
            value={detailModify?.sortNum}
            onChange={(e) => {
              setDetailModify(
                produce((draft) => {
                  draft.sortNum = e.target.value;
                }),
              );
            }}
          />
          <p style={{ fontWeight: 'bold', color: '#1e3d83' }}>게시기간 설정</p>
          <Radio.Group
            style={{ width: '100%', textAlign: 'center', margin: '30px 0' }}
            onChange={radioChange}
            value={radioValue}
          >
            <Radio value={1} style={{ marginRight: 50 }}>
              영구
            </Radio>
            <Radio value={2}>기간설정</Radio>
          </Radio.Group>
          <div style={{ marginBottom: 10 }}>
            <RangePicker
              disabled={radioValue == 1 ? true : false}
              value={[moment(detailModify?.startAt), moment(detailModify?.endAt)]}
              onChange={(date, dateString) => {
                setDetailModify(
                  produce((draft) => {
                    draft.startAt = dateString[0];
                    draft.endAt = dateString[1];
                  }),
                );
              }}
            />
          </div>
          <p className={styles.preview_text}>※ 시작일 00:00부터 종료일 24:00까지 게시됩니다.</p>
        </div>
      </Modal>
      <AddBannerModal modalOpen={isAddModal} setModalOpen={setIsAddModal} />
      <ConnectList />
    </div>
  );
};

export default OperationMainBannerIndex;
