import React from 'react';
import Slider from 'react-slick';
import styles from './ContentSlider.module.less';

const ContentSlider = ({ type, leftLine, isFirst, item, children ,...props}) => {
  const settings = {
    dots: true,
    className: 'center',
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 1,
    initialSlide:0,
    swipeToSlide: true,
    afterChange: function (index) {},
  };

  return (
    <>
      <div key={children?.[0]?.props?.item?.id} style={{ width: '100%', height: 360, marginBottom: 50 }}>
        <Slider {...settings}>{children}</Slider>
      </div>
    </>
  );
};

export default ContentSlider;
