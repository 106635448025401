import React from 'react';
import styles from './main_section.module.less';
const Preview = ({ type, buttonClick }) => {
  console.log(type);
  if (type === 'RDTP001' || !type) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          padding: 20,
          backgroundColor: 'white',
          height: 900,
        }}
      >
        <div style={{ backgroundColor: '#e8e8e8', height: '240px', borderRadius: 10, overflow: 'hidden' }}>
          <div style={{ backgroundColor: '#e8e8e8', height: '240px', backgroundColor: '#e8e8e8' }}></div>
        </div>
        <div className={styles.main_carousel_wrapper_div}>
          <div className={styles.main_carousel_div} style={{ backgroundColor: 'black' }}></div>
          <div className={styles.main_carousel_div} style={{ backgroundColor: 'black' }}></div>
          <div className={styles.main_carousel_div} style={{ backgroundColor: 'black' }}></div>
          <div className={styles.main_carousel_div} style={{ backgroundColor: 'black' }}></div>
          <div className={styles.main_carousel_div}></div>
        </div>
        <div className={styles.main_carousel_wrapper_div}>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
        </div>
        <div className={styles.main_footer_banner}></div>
        <div style={{ textAlign: 'center' }}>
          <button
            style={{ marginBottom: 20, padding: '3px 18px', borderRadius: '20px', border: '1.5px solid #1e3d83' }}
            onClick={buttonClick}
          >
            상세보기
          </button>
          <p className={styles.preview_text}>※ 미리보기는 참고용입니다. 모바일에서 실제 화면을 확인하세요.</p>
        </div>
      </div>
    );
  } else if (type === 'RDTP002') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          padding: 20,
          backgroundColor: 'white',
          height: 900,
        }}
      >
        <div style={{ backgroundColor: '#e8e8e8', height: '240px', borderRadius: 10, overflow: 'hidden' }}>
          <div style={{ backgroundColor: '#e8e8e8', height: '240px', backgroundColor: '#e8e8e8' }}></div>
        </div>
        <div className={styles.main_carousel_wrapper_div}>
          <div className={styles.main_middle_size_div} style={{ backgroundColor: 'black' }}></div>
          <div className={styles.main_middle_size_div} style={{ backgroundColor: 'black' }}></div>
          <div className={styles.main_middle_size_div} style={{ backgroundColor: 'black' }}></div>
          <div className={styles.main_middle_size_div} style={{ backgroundColor: 'black' }}></div>
        </div>
        <div className={styles.main_carousel_wrapper_div}>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
        </div>
        <div className={styles.main_footer_banner}></div>
        <div style={{ textAlign: 'center' }}>
          <button
            style={{ marginBottom: 20, padding: '3px 18px', borderRadius: '20px', border: '1.5px solid #1e3d83' }}
            onClick={buttonClick}
          >
            상세보기
          </button>
          <p className={styles.preview_text}>※ 미리보기는 참고용입니다. 모바일에서 실제 화면을 확인하세요.</p>
        </div>
      </div>
    );
  } else if (type === 'RDTP003') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          padding: 20,
          backgroundColor: 'white',
          height: 900,
        }}
      >
        <div style={{ backgroundColor: '#e8e8e8', height: '240px', borderRadius: 10, overflow: 'hidden' }}>
          <div style={{ backgroundColor: '#e8e8e8', height: '240px', backgroundColor: '#e8e8e8' }}></div>
        </div>
        <div className={styles.main_large_carousel_wrapper_div}>
          <div className={styles.main_large_size_div} style={{ backgroundColor: 'black' }}></div>
          <div className={styles.main_large_size_div} style={{ backgroundColor: 'black' }}></div>
          <div className={styles.main_large_size_div} style={{ backgroundColor: 'black' }}></div>
          <div className={styles.main_large_size_div} style={{ backgroundColor: 'black' }}></div>
        </div>
        <div className={styles.main_carousel_wrapper_div}>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
        </div>
        <div className={styles.main_footer_banner}></div>
        <div style={{ textAlign: 'center' }}>
          <button
            style={{ marginBottom: 20, padding: '3px 18px', borderRadius: '20px', border: '1.5px solid #1e3d83' }}
            onClick={buttonClick}
          >
            상세보기
          </button>
          <p className={styles.preview_text}>※ 미리보기는 참고용입니다. 모바일에서 실제 화면을 확인하세요.</p>
        </div>
      </div>
    );
  } else if (type === 'RDTP004') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          padding: 20,
          backgroundColor: 'white',
          height: 900,
        }}
      >
        <div style={{ backgroundColor: '#e8e8e8', height: '240px', borderRadius: 10, overflow: 'hidden' }}>
          <div style={{ backgroundColor: '#e8e8e8', height: '240px', backgroundColor: '#e8e8e8' }}></div>
        </div>
        <div className={styles.main_carousel_wrapper_div}>
          <div className={styles.main_carousel_div} style={{ backgroundColor: 'black' }}></div>
          <div className={styles.main_carousel_div} style={{ backgroundColor: 'black' }}></div>
          <div className={styles.main_carousel_div} style={{ backgroundColor: 'black' }}></div>
          <div className={styles.main_carousel_div} style={{ backgroundColor: 'black' }}></div>
          <div className={styles.main_carousel_div} style={{ backgroundColor: 'black' }}></div>
        </div>
        <div className={styles.main_carousel_wrapper_div}>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
        </div>
        <div className={styles.main_footer_banner}></div>
        <div style={{ textAlign: 'center' }}>
          <button
            style={{ marginBottom: 20, padding: '3px 18px', borderRadius: '20px', border: '1.5px solid #1e3d83' }}
            onClick={buttonClick}
          >
            상세보기
          </button>
          <p className={styles.preview_text}>※ 미리보기는 참고용입니다. 모바일에서 실제 화면을 확인하세요.</p>
        </div>
      </div>
    );
  } else if (type === 'RDTP005') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          padding: 20,
          backgroundColor: 'white',
          height: 900,
        }}
      >
        <div style={{ backgroundColor: '#e8e8e8', height: '240px', borderRadius: 10, overflow: 'hidden' }}>
          <div style={{ backgroundColor: 'black', height: '240px' }}></div>
        </div>
        <div className={styles.main_carousel_wrapper_div}>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
        </div>
        <div className={styles.main_carousel_wrapper_div}>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
          <div className={styles.main_carousel_div}></div>
        </div>
        <div className={styles.main_footer_banner}></div>
        <div style={{ textAlign: 'center' }}>
          <button
            style={{ marginBottom: 20, padding: '3px 18px', borderRadius: '20px', border: '1.5px solid #1e3d83' }}
            onClick={buttonClick}
          >
            상세보기
          </button>
          <p className={styles.preview_text}>※ 미리보기는 참고용입니다. 모바일에서 실제 화면을 확인하세요.</p>
        </div>
      </div>
    );
  }else if (type==='RDTP006'){
    return <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          padding: 20,
          backgroundColor: 'white',
          height: 900,
        }}
    >
      <div style={{ backgroundColor: '#e8e8e8', height: '240px', borderRadius: 10, overflow: 'hidden' }}>
        <div style={{ backgroundColor: '#e8e8e8', height: '240px' }}></div>
      </div>
      <div className={styles.main_carousel_wrapper_div}>
        <div className={styles.main_carousel_div}></div>
        <div className={styles.main_carousel_div}></div>
        <div className={styles.main_carousel_div}></div>
        <div className={styles.main_carousel_div}></div>
        <div className={styles.main_carousel_div}></div>
      </div>
      <div className={styles.main_carousel_wrapper_div}>
        <div className={styles.main_carousel_div}></div>
        <div className={styles.main_carousel_div}></div>
        <div className={styles.main_carousel_div}></div>
        <div className={styles.main_carousel_div}></div>
        <div className={styles.main_carousel_div}></div>
      </div>
      <div style={{ backgroundColor: '#e8e8e8', height: '180px', borderRadius: 10, overflow: 'hidden' }}>
        <div style={{ backgroundColor: 'black', height: '240px' }}></div>
      </div>
      <div className={styles.section}></div>
      <div style={{ textAlign: 'center' }}>
        <button
            style={{ marginBottom: 20, padding: '3px 18px', borderRadius: '20px', border: '1.5px solid #1e3d83' }}
            onClick={buttonClick}
        >
          상세보기
        </button>
        <p className={styles.preview_text}>※ 미리보기는 참고용입니다. 모바일에서 실제 화면을 확인하세요.</p>
      </div>
    </div>
  }
};

export default Preview;
