import { Image } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;

  & .btn {
    position: absolute;

    border-radius: 100%;
    background-color: rgb(30, 61, 131);
    top: -5.5px;
    right: -5.5px;
    z-index: 2;
  }
`;

const ImageWithClear = ({ source, wrapperStyle, imageStyle, btnStyle, onClick }) => {
  return (
    <Wrapper style={{ ...wrapperStyle }}>
      <img
        style={{ ...imageStyle }}
        // wrapperStyle={{ ...imageStyle }}
        src={require('../../assets/icon_blank_grey.png')}
      />
      <div className="btn" style={{ ...btnStyle }} onClick={onClick}></div>
    </Wrapper>
  );
};

export default ImageWithClear;
