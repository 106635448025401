import React, { useState } from 'react';
import Thumbnail from '../components/image/Thumbnail';
import styles from './BasicLayout.module.less';
import useRoutes from './useRoutes';
import { shallowEqual, useSelector } from 'react-redux';
import { Menu, Spin } from 'antd';
import Login from '../components/auth/Login';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';

const items = [
  {
    label: 'Navigation One',
    key: 'mail',
    icon: <MailOutlined />,
  },
  {
    label: 'Navigation Two',
    key: 'app',
    icon: <AppstoreOutlined />,
    disabled: true,
  },
  {
    label: 'Navigation Three - Submenu',
    key: 'SubMenu',
    icon: <SettingOutlined />,
    children: [
      {
        type: 'group',
        label: 'Item 1',
        children: [
          {
            label: 'Option 1',
            key: 'setting:1',
          },
          {
            label: 'Option 2',
            key: 'setting:2',
          },
        ],
      },
      {
        type: 'group',
        label: 'Item 2',
        children: [
          {
            label: 'Option 3',
            key: 'setting:3',
          },
          {
            label: 'Option 4',
            key: 'setting:4',
          },
        ],
      },
    ],
  },
  {
    label: (
      <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
        Navigation Four - Link
      </a>
    ),
    key: 'alipay',
  },
];

const childRenderer = (page, parent) => {
  const args = {
    path: [parent, page.path].filter((v) => !!v).join('/'),
  };
  if (page.children?.length) {
    //, args.path
    args.path = args.path + '/*';
    args.children = page.children?.map((child) => childRenderer(child));
  }
  if (page.screen) {
    const Elem = page.screen;
    args.element = <Elem />;
  }
  return <Route key={page.path} {...args} />;
};

const BasicLayout = ({ children, loading }) => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { routes, pages } = useRoutes();
  const [sideOpen, setSideOpen] = useState(false);
  const navigate = useNavigate();

  const [openKeys, setOpenKeys] = useState([]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };
  const [current, setCurrent] = useState('mail');
  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  const pathname = window.location.pathname;
  const currentPath = pathname.split('/').slice(1).join('/');
  console.log({ currentPath });

  return (
    <div
      className={`${styles.basic_layout} ${!principal || loading ? styles.auth : ''} ${
        sideOpen ? styles.side_open : ''
      }`}
      style={{ backgroundColor: 'rgb(249, 249, 249)' }}
    >
      {!principal ? (
        <div className={styles.auth_body}>{!!loading ? <Spin size={'large'} /> : <Login />}</div>
      ) : (
        <>
          <div className={styles.menu_container}>
            <div className={styles.menu_flex}>
              <div
                style={{
                  height: '50px',
                  paddingLeft: '50px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => navigate('/')}
              >
                <img className={styles.logo} alt={'baba'} src={require('../assets/logo.png')} />
              </div>
              <Menu
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                onSelect={console.log}
                onClick={(item) => navigate(item.key)}
                disabledOverflow
                style={{ backgroundColor: 'rgb(30, 61 ,131)', color: 'white' }}
                mode="horizontal"
              >
                {routes?.map((route) => {
                  if (!route.children?.length) {
                    return <Menu.Item key={route.path}>{route.title}</Menu.Item>;
                  }

                  const items = route?.children ?? [];
                  return (
                    <Menu.SubMenu key={route.path} title={route.title}>
                      {items.map((child) => (
                        <Menu.Item key={child.path}>{child.title}</Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  );
                })}
              </Menu>
              <div
                style={{
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'rgb(30, 61 ,131)',
                  paddingRight: '50px',
                }}
              >
                <Thumbnail
                  containerStyle={{
                    width: 'auto',
                    marginRight: 5,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  imageStyle={{ borderRadius: '50%', overflow: 'hidden', width: 36, height: 36 }}
                  id={principal?.nickname}
                ></Thumbnail>
                <div>
                  <span
                    style={{
                      display: 'inline-block',
                      width: 'max-content',
                      color: '#fff',
                      fontWeight: 'bold',
                      fontSize: 12,
                    }}
                  >
                    {principal?.nickname}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.body}>
            <Routes>{pages?.filter((v) => !!v.screen)?.map((page) => childRenderer(page, undefined))}</Routes>
          </div>
        </>
      )}
    </div>
  );
};

export default BasicLayout;
