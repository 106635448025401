import { Switch, Table } from 'antd';
import React from 'react';
import styles from './TraceTable.module.less';
import styled from 'styled-components';

const CustomTable = styled(Table)`
  thead .ant-table-cell {
    background-color: #f2f2f2;
    height: 40px;
    width: 10px;
  }
  // 제목행 첫 째줄
  thead tr:first-child .ant-table-cell {
    border: 1px solid #d9d9d9;
    border-top: none;
  }
  // 현재접속고객수 셀
  thead tr:first-child .ant-table-cell:first-child {
    border: none;
  }

  // 제목행 둘 째줄
  thead tr:nth-child(2) .ant-table-cell {
    border: 1px solid #d9d9d9;
    border-bottom: none;
  }

  thead tr:nth-child(2) .ant-table-cell:first-child {
    border-left: none;
  }

  // 레코드
  tbody .ant-table-cell {
  }
  ant-table-container {
  }
`;

const COLUMNS = [
  {
    title: (
      <div style={{ textAlign: 'left' }}>
        <span>현재접속고객수</span>
      </div>
    ),

    children: [
      {
        title: '소비자고객',
      },
      {
        title: '시공사',
      },
      {
        title: '하도급',
      },
      {
        title: '유통사',
      },
    ],
  },
  {
    title: (
      <div className={styles.switch_field}>
        <span>가입/탈퇴 회원수</span>
        <div>
          <span>가입</span>
          <Switch size="small" className={styles.switch} />
          <span>탈퇴</span>
        </div>
      </div>
    ),
    children: [
      {
        title: '소비자고객',
      },
      {
        title: '시공사',
      },
      {
        title: '하도급',
      },
      {
        title: '유통사',
      },
    ],
  },
  {
    title: (
      <div className={styles.switch_field}>
        <span>서비스 운영 현황</span>
        <div>
          <span>접속자수</span>
          <Switch size="small" className={styles.switch} />
          <span>견적금액</span>
        </div>
      </div>
    ),
    children: [
      {
        title: '소비자견적',
      },
      {
        title: '공사도움받기',
      },
      {
        title: '상담',
      },
      {
        title: 'IM',
      },
    ],
  },
  {
    title: (
      <div style={{ textAlign: 'left' }}>
        <span>고객센터</span>
      </div>
    ),
    children: [
      {
        title: '소비자고객',
      },
      {
        title: '시공사',
      },
    ],
  },
  {
    title: (
      <div className={styles.switch_field}>
        <span>포인트</span>
        <div>
          <span>보유</span>
          <Switch size="small" className={styles.switch} />
          <span>사용</span>
        </div>
      </div>
    ),
    children: [
      {
        title: '소비자고객',
      },
      {
        title: '시공사',
      },
    ],
  },
];

// const COLUMNS_TITLE = [
//   {
//     label: '현재접속고객수',
//   },
//   {
//     label: (
//       <div className={styles.switch_field}>
//         <span>가입/탈퇴 회원수</span>
//         <div>
//           <span>보유</span>
//           <Switch size="small" className={styles.switch} />
//           <span>사용</span>
//         </div>
//       </div>
//     ),
//   },
//   {
//     label: (
//       <div className={styles.switch_field}>
//         <span>서비스 운영 현황</span>
//         <div>
//           <span>접속자수</span>
//           <Switch size="small" className={styles.switch} />
//           <span>견적금액</span>
//         </div>
//       </div>
//     ),
//   },
//   {
//     label: '고객센터',
//   },
//   {
//     label: (
//       <div className={styles.switch_field}>
//         <span>포인트</span>
//         <div>
//           <span>발행</span>
//           <Switch size="small" className={styles.switch} />
//           <span>취소</span>
//         </div>
//       </div>
//     ),
//   },
// ];

// const SUB_TITLE = [
//   {
//     label: '소비자고객',
//   },
//   {
//     label: '시공사',
//   },
//   {
//     label: '하도급',
//   },
//   {
//     label: '유통사',
//   },
//   {
//     label: '소비자고객',
//   },
//   {
//     label: '시공사',
//   },
//   {
//     label: '하도급',
//   },
//   {
//     label: '유통사',
//   },

//   {
//     label: '소비자견적',
//   },
//   {
//     label: '공사도움받기',
//   },
//   {
//     label: '상담',
//   },
//   {
//     label: 'IM',
//   },
//   {
//     label: '소비자고객',
//   },
//   {
//     label: '시공사',
//   },
//   {
//     label: '소비자고객',
//   },
//   {
//     label: '시공사',
//   },
// ];

const TraceTable = ({ style }) => {
  return <CustomTable columns={COLUMNS} bordered style={style} className="trace-table" />;
};

export default TraceTable;
