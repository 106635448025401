import { DatePicker, Input, PageHeader, Select } from 'antd';
import { Button } from 'antd/lib/radio';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PERIOD_OPTIONS } from '../../../../common/define';
import StyledTable from '../../../../components/table/StyledTable';
import TraceTable from '../../../../components/table/TraceTable';
import styles from './index.module.less';

const SubmitIndex = () => {
  const navigate = useNavigate();
  const PERIOD = [
    { label: '1개월', value: '1m' },
    { label: '3개월', value: '3m' },
    { label: '6개월', value: '6m' },
  ];
  const dummy = [
    {
      id: 1,
      company: '민디자인',
      name: '김철수',
      region: '경기도 성남시 이매동',
      registerDate: '2023-02-23 12:00',
      rate: 'A',
      state: '접속중',
      manageBranch: '서울 남부',
      paymentInfo: '등록',
      proposalDate: '2023-02-23 12:00',
      estimate: '확인',
      designReport: '확인',
      intro: '확인',
      spaceArrange: '확인',
    },
  ];
  const columns = [
    {
      title: '',
      width: 50,
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: '업체명',
      dataIndex: 'company',
      align: 'center',
      sorter: true,
    },
    {
      title: '대표자',
      dataIndex: 'name',
      align: 'left',
      sorter: true,
    },
    {
      title: '소재지',
      dataIndex: 'region',
      align: 'center',
      sorter: true,
    },
    {
      title: '가입일자',
      dataIndex: 'registerDate',
      align: 'center',
      sorter: true,
    },
    {
      title: '등급',
      dataIndex: 'rate',
      align: 'center',
      sorter: true,
    },
    {
      title: '상태',
      dataIndex: 'state',
      align: 'center',
      sorter: true,
      render: (el) => {
        return (
          <div className={styles.alignInline}>
            <div
              style={{
                width: 14,
                height: 14,
                marginRight: 3,
                borderRadius: 18,
                backgroundColor: el === '접속중' ? 'rgb(60, 255, 0)' : '',
              }}
            />
            <span>{el}</span>
          </div>
        );
      },
    },
    {
      title: '관리지부',
      dataIndex: 'manageBranch',
      align: 'center',
      sorter: true,
    },
    {
      title: '결제정보',
      dataIndex: 'paymentInfo',
      align: 'center',
      sorter: true,
    },
    {
      title: '제안일시',
      dataIndex: 'proposalDate',
      align: 'center',
      sorter: true,
    },
    {
      title: '제안견적서',
      dataIndex: 'estimate',
      align: 'center',
      sorter: true,
      render: (el, array) => {
        return (
          <button className={styles.checkBtn} onClick={() => navigate(`/expert/proposal/submit/estimate/${array.id}`)}>
            {el}
          </button>
        );
      },
    },
    {
      title: '디자인리포트',
      dataIndex: 'designReport',
      align: 'center',
      sorter: true,
      render: (el) => {
        return <button className={styles.checkBtn}>{el}</button>;
      },
    },
    {
      title: '회사소개서',
      dataIndex: 'intro',
      align: 'center',
      sorter: true,
      render: (el) => {
        return <button className={styles.checkBtn}>{el}</button>;
      },
    },
    {
      title: '3D공간배치',
      dataIndex: 'spaceArrange',
      align: 'center',
      sorter: true,
      render: (el) => {
        return <button className={styles.checkBtn}>{el}</button>;
      },
    },
  ];
  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="전문가 도움받기 서비스 관리 > 제안요청리스트 > 제안서제출업체"
        extra={[
          PERIOD_OPTIONS.map((v) => {
            return <Button>{v?.label}</Button>;
          }),
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginLeft: 20, marginRight: 5 }}>기간설정</span>
            <DatePicker.RangePicker />
          </div>,
        ]}
      />
      <TraceTable />

      <div className={styles.alignInline} style={{ justifyContent: 'space-between', marginBottom: 35 }}>
        <div className={styles.alignInline}>
          <div className={styles.alignInline} style={{ marginRight: 20 }}>
            <span style={{ marginRight: 10 }}>요청일자</span>
            <DatePicker.RangePicker style={{ width: 230 }} />
          </div>
          <div>
            {PERIOD.map((v, index) => {
              return (
                <Button key={`Period_${index}`} style={{ marginRight: 5 }}>
                  {v.label}
                </Button>
              );
            })}
          </div>
        </div>
        <div>
          <span>지역</span>
          <Select style={{ width: 70, marginInline: 10 }} />
          <Select style={{ width: 70 }} />
        </div>
        <div>
          <span>비용</span>
          <Input style={{ width: 70, marginInline: 10 }} />
          ~ <Input style={{ width: 70 }} />
        </div>
        <div className={styles.alignInline}>
          <span style={{ marginRight: 10 }}>키워드</span>
          <Input style={{ width: 140 }} />
        </div>
      </div>

      <p style={{ fontSize: 16, fontWeight: '700' }}>제안서 제출업체 리스트</p>
      <StyledTable
        className="ant-table-pointer"
        dataSource={dummy}
        columns={columns}
        // onRow={onRow}
        style={{ marginBottom: 100 }}
      />
    </div>
  );
};

export default SubmitIndex;
