import { Modal } from 'antd';
import React from 'react';
import { parseImageUri } from '../common/utils';

const useBusinessNumberModal = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const BusinessNumberModal = ({ imageId, businessNumber }) => {
    return (
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
          display: 'flex',
          justifyContent: 'center',
        }}
        closable={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={true}
        okText={'확인'}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div style={{ padding: '35px 45px', height: '100%' }}>
          <h3
            style={{
              fontSize: '24px',
              fontWeight: '600',
            }}
          >
            사업자 등록정보
          </h3>
          <div
            style={{
              marginTop: '111px',
              width: '600px',
              padding: '52px 160px 52px 160px',
              borderRadius: '10px',
              backgroundColor: '#d9d9d9',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <img
              src={parseImageUri(imageId)}
              style={{
                width: '272px',
                height: '385px',
              }}
              alt="business"
            />
            <span
              style={{
                marginTop: 44,
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              {businessNumber?.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')}
            </span>
          </div>
        </div>
      </Modal>
    );
  };
  return { showModal, setIsModalOpen, BusinessNumberModal };
};

export default useBusinessNumberModal;
