import axios from 'axios';
import {getAPIHost, getFileHost, restApi} from '../apis';
import dayjs from 'dayjs';
import { Modal } from 'antd';

export const getClientWidth = () => {
  const w = document.body.clientWidth;
  let aside = w >= 1024 ? 240 : 0;
  return w - aside - 40;
};

export const comma = (x) => {
  return (x ?? 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// 이미지 id 반환
export const getFileId = async (file) => {
  if (!file?.name) return false;
  const allowedExtensions = ['jpg', 'jpeg', 'png'];
  const extension = file?.name?.split('.').pop().toLowerCase();
  if (allowedExtensions.includes(extension)) {
    const formData = new FormData();
    formData.append('file', file);
    const response = await fetch(getFileHost() + '/api/files', {
      body: formData,
      method: 'POST',
      headers: {
        accept: '*/*',
      },
    });
    const resFile = await response.json();
    const { id } = resFile;
    return id;
  } else {
    alert('이미지를 등록하지 못했습니다');
  }
};

export const parseImageUri = (id, w, h) => {
  const uri = `${getFileHost()}/api/files/${id}`;
  const params = [];
  if (w) params.push(`w=${w}`);
  if (h) params.push(`h=${h}`);
  return uri + (!params?.length ? '' : `?${params.join('&')}`);
};

const expendCategory = (children) => {
  let items = [];
  children?.map((item) => {
    items.push({
      code: item.code,
      label: item.label,
    });
    if (item.children) {
      items = items.concat(expendCategory(item.children));
    }
  });
  return items;
};

export const parseCategory = (categories, value, separator) => {
  const keys = [];
  for (let i = 1; i <= Math.floor(value.length / 3); i++) {
    keys.push(value.substring(0, i * 3));
  }

  const c = categories.reduce((a, c) => {
    // a[c.code] = c;

    a[c.code] = {
      code: c.code,
      label: c.label,
    };
    if (c.children) {
      expendCategory(c.children).forEach((item) => {
        a[item.code] = {
          code: item.code,
          label: item.label,
        };
      });
    }

    return a;
  }, {});

  if (separator && typeof separator === 'string') {
    return keys?.map((v) => c[v]?.label)?.join(separator);
  } else {
    return keys.map((v, i) => (
      <>
        {c[v]?.label}
        {i !== keys?.length - 1 && separator}
      </>
    ));
  }
};

export const formatComma = (num) => {
  // 문자열로 변환
  var str = num.toString();
  // 정수부와 소수부 분리
  var parts = str.split('.');
  // 천단위 콤마 추가
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // 새로운 문자열 반환
  return parts.join('.');
};

export const isSwitchChecked = (originDataList, checkedList) => {
  // originDataList : 전체 데이터 리스트
  // checkedList : 체크된 데이터 리스트
  return originDataList?.length > 0 && checkedList.some((v) => originDataList.includes(v));
};

// 작성일자 표시 함수
export const getTimeAgo = (timestamp) => {
  const diff = (Date.now() - new Date(timestamp).getTime()) / 1000;
  if(diff <0) return getTimeAfter(timestamp);
  if (diff < 60) return `방금 전`;
  if (diff < 3600) return `${Math.floor(diff / 60)}분 전`;
  if (diff < 86400) return `${Math.floor(diff / 3600)}시간 전`;
  if (diff < 2592000) return `${Math.floor(diff / 86400)}일 전`;
  if (diff < 31104000) return `${Math.floor(diff / 2592000)}달 전`;
  return `${Math.floor(diff / 31104000)}년 전`;
};

export const getTimeAfter = (timestamp) => {
  const diff = (Date.now() - new Date(timestamp).getTime()) / 1000;
  if (diff > -3600) return `${Math.floor(-diff / 60)}분 후`;
  if (diff > -86400) return `${Math.floor(-diff / 3600)}시간 후`;
  if (diff > -2592000) return `${Math.floor(-diff / 86400)}일 후`;
  if (diff > -31104000) return `${Math.floor(-diff / 2592000)}달 후`;
  return `${Math.floor(-diff / 31104000)}년 후`;
}




//마감일자 표시 함수
export const getRemainTime = (timestamp) => {
  const diff = (new Date(timestamp).getTime() - Date.now()) / 1000;
  if (diff < 60) return `곧 만료`;
  if (diff < 3600) return `${Math.floor(diff / 60)}분 남음`;
  if (diff < 86400) return `${Math.floor(diff / 3600)}시간 남음`;
  if (diff < 2592000) return `${Math.floor(diff / 86400)}일 남음`;
  if (diff < 31104000) return `${Math.floor(diff / 2592000)}달 남음`;
  return `${Math.floor(diff / 31104000)}년 남음`;
};

// 상품 카테고리 라벨 반환
export const findCategoryDeps = (categories, productId, labels = []) => {
  const labels_ = [...labels];
  const productId_ = String(productId);
  const item = categories?.find((v) => v.id === productId_.slice(0, v.id.length) || v.id === productId_);
  labels_.push(item?.name);
  if (item?.id !== productId_ && item?.children?.length > 0) {
    return findCategoryDeps(item.children, productId_, labels_);
  }
  return labels_;
};

// 사용자 견적 작성 여부 변경버튼 단일
export const handleModifyWriteAccount = async (uri, id, isActive, affterEffect) => {
  await restApi.put(uri, { accountIds: [id], isActive: !isActive });
  affterEffect();
};
// 사용자 견적 작성 여부 변경버튼 단일 복수
export const handleAccountShowAndStop = (uri, selectedRowKeys, boolean, affterEffect) => {
  Modal.confirm({
    title: '수정',
    content: boolean ? '선택한 요소들의 모두 게시하시겠습니까?' : '선택한 요소들의 모두 중지하시겠습니까?',
    okText: '수정하기',
    cancelText: '취소하기',
    async onOk() {
      try {
        await restApi.put(uri, { accountIds: selectedRowKeys, isActive: boolean });
        Modal.confirm({
          title: '완료',
          content: '수정이 완료되었습니다',
          onOk() {
            affterEffect();
          },
          cancelButtonProps: {
            style: { display: 'none' },
          },
          okText: '확인',
        });
      } catch (error) {
        Modal.warning({
          title: '실패했습니다',
          content: error.response.data.message,
        });
      }
    },
  });
};
// 공사도움받기 변경버튼 단일
export const handleRequest = async (uri, id, isActive, affterEffect) => {
  await restApi.put(uri, { ids: [id], isActive: !isActive });
  affterEffect();
};

// 공사도움받기 변경버튼 단일 복수
export const handleRequestShowAndStop = (uri, selectedRowKeys, boolean, affterEffect) => {
  Modal.confirm({
    title: '수정',
    content: boolean ? '선택한 요소들의 모두 게시하시겠습니까?' : '선택한 요소들의 모두 중지하시겠습니까?',
    okText: '수정하기',
    cancelText: '취소하기',
    async onOk() {
      try {
        await restApi.put(uri, { ids: selectedRowKeys, isActive: boolean });
        Modal.confirm({
          title: '완료',
          content: '수정이 완료되었습니다',
          onOk() {
            affterEffect();
          },
          cancelButtonProps: {
            style: { display: 'none' },
          },
          okText: '확인',
        });
      } catch (error) {
        Modal.warning({
          title: '실패했습니다',
          content: error.response.data.message,
        });
      }
    },
  });
};
// 게시 여부 변경버튼 단일
export const handleModifyShow = async (uri, id, isShow, affterEffect) => {
  await restApi.put(uri, { ids: [id], isShow: !isShow });
  affterEffect();
};

// 게시 여부 변경버튼 복수
export const handleShowAndStop = (uri, selectedRowKeys, boolean, affterEffect) => {
  Modal.confirm({
    title: '수정',
    content: boolean ? '선택한 요소들의 모두 게시하시겠습니까?' : '선택한 요소들의 모두 중지하시겠습니까?',
    okText: '수정하기',
    cancelText: '취소하기',
    async onOk() {
      try {
        await restApi.put(uri, { ids: selectedRowKeys, isShow: boolean });
        Modal.confirm({
          title: '완료',
          content: '수정이 완료되었습니다',
          onOk() {
            affterEffect();
          },
          cancelButtonProps: {
            style: { display: 'none' },
          },
          okText: '확인',
        });
      } catch (error) {
        Modal.warning({
          title: '실패했습니다',
          content: error.response.data.message,
        });
      }
    },
  });
};

// 게시 여부 변경버튼 단일
export const handleModifyConfirm = async (uri, id, isConfirmed, affterEffect) => {
  await restApi.put(uri, { ids: [id], isConfirmed: !isConfirmed });
  affterEffect();
};

// 게시 여부 변경버튼 복수
export const handleConfirmAndNot = (uri, selectedRowKeys, boolean, affterEffect) => {
  Modal.confirm({
    title: '수정',
    content: boolean ? '선택한 요소들의 모두 게시하시겠습니까?' : '선택한 요소들의 모두 중지하시겠습니까?',
    okText: '수정하기',
    cancelText: '취소하기',
    async onOk() {
      try {
        await restApi.put(uri, { ids: selectedRowKeys, isConfirmed: boolean });
        Modal.confirm({
          title: '완료',
          content: '수정이 완료되었습니다',
          onOk() {
            affterEffect();
          },
          cancelButtonProps: {
            style: { display: 'none' },
          },
          okText: '확인',
        });
      } catch (error) {
        Modal.warning({
          title: '실패했습니다',
          content: error.response.data.message,
        });
      }
    },
  });
};

//컨텐츠 타입 한글로 변경
export const contentTypeOBJ = {
  STYLE:'스타일',
  PORTFOLIO:'포트폴리오',
  TREND:'트렌드',
  CONSTRUCTION_GUIDE:'공사가이드'
}
