import { Checkbox, Col, Modal, PageHeader, Row, Radio, DatePicker, Space, Table, Switch } from 'antd';
import React from 'react';
import styles from './main_popup.module.less';
import { CloseCircleFilled, SyncOutlined } from '@ant-design/icons';
import { getFileId, parseImageUri } from '../../../common/utils';
import produce from 'immer';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { restApi } from '../../../apis';
import { MAIN_BANNER_LIST_KEY, POPUP_LIST_DETAIL_KEY, POPUP_LIST_KEY } from '../../../apis/queryKeys';
import { useSelector } from 'react-redux';
import useConnectList from '../../../hooks/useConnectList';

const AddPopupModal = ({ modalOpen, setModalOpen }) => {
  const queryClient = useQueryClient();
  const { commonCodes } = useSelector((state) => state.common);
  const { RangePicker } = DatePicker;

  // 리스트 연결 모달
  const { connect, setConnect, showModal: showList, ConnectList } = useConnectList();

  const [detailModify, setDetailModify] = React.useState({
    title: '',
    memo: '',
    imageId: null,
    sortNum: null,
    startAt: undefined,
    endAt: undefined,
    link: null,
    isLimit: false,
    hideOption: '',
    contentGroupId: null,
  });
  const [radioValue, setRadioValue] = React.useState(2);
  const [hideValue, setHideValue] = React.useState('HDOP001');
  const handleOk = () => {
    Modal.confirm({
      title: '작성',
      content: '작성을 완료하시겠습니까?',
      okText: '작성하기',
      cancelText: '취소하기',
      async onOk() {
        try {
          await restApi.post(`/popup`, detailModify);
          Modal.confirm({
            title: '완료',
            content: '작성이 완료되었습니다',
            onOk() {
              queryClient.invalidateQueries([POPUP_LIST_KEY]);
              queryClient.invalidateQueries([POPUP_LIST_DETAIL_KEY]);
              setDetailModify(
                produce((draft) => {
                  draft.title = null;
                  draft.memo = null;
                  draft.imageId = null;
                  draft.sortNum = null;
                  draft.startAt = undefined;
                  draft.endAt = undefined;
                  draft.link = null;
                  draft.isLimit = false;
                  draft.hideOption = null;
                }),
              );
              setModalOpen(false);
              setConnect(null);
            },
            cancelButtonProps: {
              style: { display: 'none' },
            },
            okText: '확인',
          });
        } catch (error) {
          Modal.warning({
            title: '오류',
            content: error.response.data.message,
          });
        }
      },
    });
  };
  const handleCancel = () => {
    setDetailModify(
      produce((draft) => {
        draft.title = null;
        draft.memo = null;
        draft.imageId = null;
        draft.sortNum = null;
        draft.startAt = undefined;
        draft.endAt = undefined;
        draft.link = null;
        draft.isLimit = false;
        draft.hideOption = null;
      }),
    );
    setModalOpen(false);
    setConnect(null);
  };
  const radioChange = (e) => {
    if (e.target.value === 1) {
      setDetailModify(
        produce((draft) => {
          draft.startAt = undefined;
          draft.endAt = undefined;
          draft.isLimit = false;
        }),
      );
    } else {
      setDetailModify(
        produce((draft) => {
          draft.isLimit = true;
        }),
      );
    }
    setRadioValue(e.target.value);
  };
  const hideOptionHandler = ({ target: { value } }) => {
    setHideValue(value);
    setDetailModify(
      produce((draft) => {
        draft.hideOption = value;
      }),
    );
  };
  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    if (imageId) {
      setDetailModify(
        produce((draft) => {
          draft.imageId = imageId;
        }),
      );
    }
  };
  return (
    <Modal
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 200,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
      }}
      closable={true}
      open={modalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      maskClosable={false}
      okText={'저장하기'}
      cancelText={'취소하기'}
    >
      <p
        style={{
          margin: '40px 46.5px 28px 50px',
          fontSize: 24,
          fontWeight: 600,
        }}
      >
        메인팝업 생성
      </p>
      <div
        style={{
          width: 400,
          height: 800,
          backgroundColor: 'white',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 100,
          padding: 20,
        }}
      >
        <div
          style={{
            position: 'relative',
            width: 150,
            height: 150,
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: '#d9d9d9',
            backgroundImage: `url(${parseImageUri(detailModify?.imageId)})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: 10,
          }}
        >
          <button
            style={{
              width: 50,
              height: 50,
              backgroundColor: 'rgba(0,0,0,0.2)',
              padding: 10,
              position: 'absolute',
              top: 3,
              right: 3,
              borderRadius: '50%',
            }}
          >
            <input
              type="file"
              id="fileInput"
              style={{
                width: '100%',
                height: '100%',
                opacity: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                cursor: 'pointer',
              }}
              onChange={handleChangeImage}
            />
            <SyncOutlined style={{ fontSize: 30, color: '#1e3d83' }} />
          </button>
        </div>
        <input
          type="text"
          style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '30px 0' }}
          placeholder="제목을 입력해주세요."
          value={detailModify?.title}
          onChange={(e) => {
            setDetailModify(
              produce((draft) => {
                draft.title = e.target.value;
              }),
            );
          }}
        />
        <div style={{ display: 'flex', borderBottom: '1px solid #d9d9d9' }}>
          <input
            type="text"
            style={{ width: '88%', marginRight: 5, border: 'none' }}
            placeholder="메모"
            maxLength={79}
            value={detailModify?.memo}
            onChange={(e) => {
              setDetailModify(
                produce((draft) => {
                  draft.memo = e.target.value;
                }),
              );
            }}
          />
          <span style={{ color: '#767676', fontSize: 12, display: 'inline-block', width: '10%' }}>
            {detailModify?.memo?.length}/80
          </span>
        </div>
        <div style={{ display: 'flex', borderBottom: '1px solid #d9d9d9', margin: '30px 0' }}>
          <input
            type="text"
            style={{ width: '100%', border: 'none' }}
            placeholder="링크"
            maxLength={79}
            value={detailModify?.link}
            onChange={(e) => {
              setDetailModify(
                produce((draft) => {
                  draft.link = e.target.value;
                }),
              );
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', alignItems: 'center' }}>
          <input
            type="text"
            disabled
            placeholder="연결된 컨텐츠가 없습니다"
            value={detailModify?.contentGroupId}
            style={{ width: '50%', border: 'none', borderBottom: '1px solid #d9d9d9', color: '#767676' }}
          />
          <button
            style={{ border: '2px solid #1e3d83', padding: '4px 18px', borderRadius: '15px', fontWeight: 900 }}
            onClick={showList}
          >
            리스트 연결
          </button>
        </div>
        <input
          type="text"
          style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '10px 0 30px' }}
          placeholder="순서"
          value={detailModify?.sortNum}
          onChange={(e) => {
            setDetailModify(
              produce((draft) => {
                draft.sortNum = e.target.value;
              }),
            );
          }}
        />
        <p style={{ fontWeight: 'bold', color: '#1e3d83' }}>숨김옵션 설정</p>
        <Radio.Group
          onChange={hideOptionHandler}
          value={hideValue}
          optionType="button"
          buttonStyle="solid"
          style={{ textAlign: 'center', margin: '20px 0 40px' }}
        >
          {commonCodes?.tree['HDOP']?.map((el, index) => {
            return (
              <Radio
                key={`hide radio ${index}`}
                value={el.code}
                style={{ marginRight: 0, width: 140, textAlign: 'center' }}
              >
                {el.label}
              </Radio>
            );
          })}
        </Radio.Group>
        <p style={{ fontWeight: 'bold', color: '#1e3d83' }}>게시기간 설정</p>
        <Radio.Group
          style={{ width: '100%', textAlign: 'center', margin: '30px 0' }}
          onChange={radioChange}
          value={radioValue}
        >
          <Radio value={1} style={{ marginRight: 50 }}>
            영구
          </Radio>
          <Radio value={2}>기간설정</Radio>
        </Radio.Group>
        <div style={{ marginBottom: 10 }}>
          <RangePicker
            disabled={radioValue == 1 ? true : false}
            value={[moment(detailModify?.startAt), moment(detailModify?.endAt)]}
            onChange={(date, dateString) => {
              setDetailModify(
                produce((draft) => {
                  draft.startAt = dateString[0];
                  draft.endAt = dateString[1];
                }),
              );
            }}
          />
        </div>
        <p className={styles.preview_text}>※ 시작일 00:00부터 종료일 24:00까지 게시됩니다.</p>
      </div>
      <ConnectList></ConnectList>
    </Modal>
  );
};

export default AddPopupModal;
