import React from 'react';

const ModifyAllButton = ({ showText, showAction, stopText, stopAction, customStyle }) => {
  return (
    <div style={{ marginLeft: 40, ...customStyle }}>
      <button
        style={{
          backgroundColor: '#1e3d83',
          border: '2px solid #1e3d83',
          borderRadius: 20,
          color: 'white',
          fontWeight: 'bold',
          fontSize: 12,
          padding: '2px 10px',
          marginRight: 5,
          width: 72,
        }}
        onClick={showAction}
      >
        {showText}
      </button>
      <button
        style={{
          backgroundColor: 'rgba(0,0,0,0)',
          border: '2px solid #1e3d83',
          borderRadius: 20,
          fontWeight: 'bold',
          fontSize: 12,
          padding: '2px 10px',
          width: 72,
        }}
        onClick={stopAction}
      >
        {stopText}
      </button>
    </div>
  );
};

export default ModifyAllButton;
