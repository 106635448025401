import { Button, PageHeader, Switch, Tabs } from 'antd';
import React from 'react';
import styles from './index.module.less';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import { DoubleRightOutlined } from '@ant-design/icons';
import ExpertTable from '../../../../../components/table/ExpertTable';
// import ExpertCategoryTable from '../category';

const MenuTabContainer = styled(Tabs)`
  border: none;
  .ant-tabs-tab {
    padding: 0;
    width: 150px;
    margin: 0;
    display: flex;
    justify-content: center;
    background-color: #c1c1c1;
    color: #fff;
    margin-right: 5px;
  }
  .ant-tabs-tab-active {
    background-color: #fff !important;
    border: 1px solid #000;

    .ant-tabs-tab-btn {
      color: #000;
    }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
`;

const MenuTabs = styled(Tabs.TabPane)``;

const EstimateIndex = () => {
  const estimateCategories = useSelector((s) => s.common?.estimateCategories, shallowEqual);
  console.log({ estimateCategories });
  const [tab, setTab] = React.useState('Category_0');

  return (
    <div>
      <PageHeader className="site-page-header" title="전문가 도움받기 서비스 관리 > 제안요청리스트 > 소비자견적금액" />
      <div
        className={styles.alignInline}
        style={{ borderBottom: '1px solid black', padding: '0 10px 10px', marginBottom: '15px' }}
      >
        <div>
          <strong>주택명</strong>
          <span className={styles.TopContent}>개봉 푸르지오</span>
        </div>
        <div>
          <strong>면적</strong>
          <span className={styles.TopContent}>34PY</span>
        </div>
        <div>
          <strong>현장주소</strong>
          <span className={styles.TopContent}>서울시 구로구 남부순환로 775</span>
        </div>
      </div>

      <div style={{ paddingLeft: 10, marginBottom: 25 }}>
        <span>공종별 보기</span>
        <Switch style={{ margin: '0 15px' }} />
        <span>공간별 보기</span>
      </div>

      <Tabs onChange={setTab}>
        {estimateCategories?.map((category) => {
          return (
            <Tabs.TabPane tab={category?.name} key={`Categories_${category?.order_index}`}>
              <ExpertTable title={category?.name} tab={tab} type="customer" />
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default EstimateIndex;
