import { createBrowserHistory } from 'history';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useBlockBack = () => {
  const history = createBrowserHistory();
  const location = useLocation();
  const navigate = useNavigate();

  // 브라우저에 렌더링 시 한 번만 실행하는 코드
  React.useEffect(() => {
    const listenBackEvent = () => {
      navigate('/operation/main_section');
    };

    const unlistenHistoryEvent = history.listen(({ action }) => {
      if (action === 'POP') {
        listenBackEvent();
      }
    });

    return unlistenHistoryEvent;
  }, [history, location]);
};
export default useBlockBack;
