import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {
  Button,
  Input,
  InputNumber,
  Modal,
  Table,
  TreeSelect,
  Upload,
} from 'antd';
import produce from 'immer';
import * as PropTypes from 'prop-types';
import qs from 'query-string';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {getAPIHost, getFileHost, restApi} from '../../../apis';
import {useGetProductCategories} from '../../../apis/queries';
import excelFile from '../../../assets/baba_product_example.xlsx'
const ProductImage = ({ value, onChange }) => {
  const [loading, setLoading] = useState();
  const [fileList, setFileList] = useState([]);
  const uploadButton = (
      <div style={{ fontSize: 12 }}>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 0, fontSize: 11 }}>Upload</div>
      </div>
  );

  const [preview,setPreview] = useState(undefined);
  return (
      <div className={'small-uploader'}>
        <Upload
            listType="picture-card"
            className="avatar-uploader"
            // action={`${getAPIHost()}/files`}
            action={`${getFileHost()}/api/files`}
            method={'POST'}
            name={'file'}
            onPreview={(e) => {
              setPreview(e.url)

            }}
            onChange={({ file, fileList }) => {
              setFileList(fileList);
              if (!fileList?.[0]) {
                onChange(undefined);
              } else {
                if (file.status === 'done') {
                  onChange(file.response.id);
                  setFileList([]);
                }
              }
            }}
            fileList={
              !value
                  ? fileList
                  : [
                    {
                      uid: value,
                      name: value + '.jpg',
                      status: 'done',
                      url: getAPIHost()+'/file/api' + '/files/' + value,
                      thumbUrl: getAPIHost()+'/file/api' + '/files/' + value,
                    },
                  ]
            }
        >
          {value ? null : uploadButton}
        </Upload>
        <Modal footer={null}  onCancel={()=>setPreview(undefined)} open={preview}>
          <img style={{width:'100%'}} src={preview} alt=""/>
        </Modal>
      </div>
  );
};
const downloadFile = () => {
  fetch(excelFile)
  .then(response => response.blob())
  .then(blob => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'baba_product_example.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();
  });
}



ProductImage.propTypes = {onChange: PropTypes.func};
const ProductCreate = (props) => {
  const [bodyHeight, setBodyHeight] = useState(10);
  const mainTable = useRef();


  const { data: productCategories } = useGetProductCategories();
  const [items,setItems] = React.useState([])
  const [file,setFile] = React.useState()
  const [loading,setLoading] = React.useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    const sizeChange = () => {
      const { top } = mainTable.current?.getBoundingClientRect?.();
      const height = document.body.clientHeight - top - 80;
      setBodyHeight(height);
    };
    sizeChange();
  }, [file]);
  const processUpload = async (file) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('excelFile', file);
      const { data } = await restApi.post(`/products/manage/excel`, formData);
      for (let row of data) {
        row.category  = row?.category1+row?.category2+row?.category3+row?.category4
      }
      setItems(data)
    } catch (e) {
      console.error(e);
      alert(e?.response?.data?.message || e?.message || '서버와 연결이 올바르지 않습니다.');
    }
    setLoading(false);
  };


  const treeData = useMemo(() => {

    const parseItem = (d) => {
      return {
        value: d.id,
        label: d.name,
        children: !d.children?.length ? undefined : d.children?.map((v) => parseItem(v)),
      };
    };
    if(productCategories)
    return productCategories?.tree?.map((v) => parseItem(v));
  }, [productCategories]);

  const handleSubmit = async () => {
      await restApi.post(`products/manage`, {excels:items}).then(
          v=>{
            alert('저장되었습니다.')
            navigate('/products/items');
          }
      ).catch(e=>{
        alert(e?.response?.data?.message || e?.message || '서버와 연결이 올바르지 않습니다.');
      });
  };
  const columns = React.useMemo(() => {
    return [
      {
        title: '고유코드',
        dataIndex: 'serial',
        key: 'serial',
        width: 150,
        fixed: 'left',
        render: (d, r, i) => (
            <Input
                value={d}
                onChange={(e) => {
                  const value = e.target.value;
                  setItems(
                      produce((draft) => {
                        draft[i].serial = value;
                      }),
                  );
                }}
            />
        ),
      },
      {
        title: '카테고리',
        dataIndex: 'category',
        key: 'category',
        width: 200,
        render: (value, r, i) => {
          const codes = [];
          if (value) {
            for (let i = 3; i <= value?.length; i += 3) {
              codes.push(value?.substring(0, i));
            }
          }

          return (
              <TreeSelect
                  showSearch
                  style={{ width: '100%' }}
                  value={value}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Please select"
                  allowClear
                  treeDefaultExpandedKeys={codes}
                  treeData={treeData}
                  onChange={(value) => {
                    setItems(
                        produce((draft) => {
                          draft[i].category = value;
                        }),
                    );
                  }}
              />
          );
        },
      },
      {
        title: '제조사',
        dataIndex: 'makerName',
        key: 'makerName',
        width: 150,
        render: (d, r, i) => (
            <Input
                value={d}
                onChange={(e) => {
                  const value = e.target.value;
                  setItems(
                      produce((draft) => {
                        draft[i].makerName = value;
                      }),
                  );
                }}
            />
        ),
      },
      {
        title: '브랜드',
        dataIndex: 'brandName',
        key: 'brandName',
        width: 150,
        render: (d, r, i) => (
            <Input
                value={d}
                onChange={(e) => {
                  const value = e.target.value;
                  setItems(
                      produce((draft) => {
                        draft[i].brand_name = value;
                      }),
                  );
                }}
            />
        ),
      },
      {
        title: '컬렉션',
        dataIndex: 'collectionName',
        key: 'collectionName',
        width: 150,
        render: (d, r, i) => (
            <Input
                value={d}
                onChange={(e) => {
                  const value = e.target.value;
                  setItems(
                      produce((draft) => {
                        draft[i].collectionName = value;
                      }),
                  );
                }}
            />
        ),
      },
      {
        title: '제품명',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        render: (d, r, i) => (
            <Input
                value={d}
                onChange={(e) => {
                  const value = e.target.value;
                  setItems(
                      produce((draft) => {
                        draft[i].name = value;
                      }),
                  );
                }}
            />
        ),
      },
      {
        title: '이미지',
        dataIndex: 'thumbnailId',
        key: 'thumbnailId',
        width: 90,
        align: 'center',
        render: (d, r, i) => {
          return (
              <ProductImage
                  value={d}
                  onChange={(value) => {
                    setItems(
                        produce((draft) => {
                          draft[i].thumbnailId = value;
                        }),
                    );
                  }}
              />
          );
        },
      },
      {
        title: '구매,소개링크',
        dataIndex: 'link',
        key: 'link',
        width: 200,
        render: (d, r, i) => (
            <Input
                value={d}
                onChange={(e) => {
                  const value = e.target.value;
                  setItems(
                      produce((draft) => {
                        draft[i].link = value;
                      }),
                  );
                }}
            />
        ),
      },
      {
        title: '바바 납품가',
        dataIndex: 'cost',
        key: 'cost',
        width: 130,
        render: (value, r, i) => {
          return (
              <InputNumber
                  keyboard={false}
                  controls={false}
                  style={{ width: '100%' }}
                  value={value}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={(value) => {
                    setItems(
                        produce((draft) => {
                          draft[i].cost = value;
                        }),
                    );
                  }}
              />
          );
        },
      },
      {
        title: '최소 소비자 표출가',
        dataIndex: 'minSupplyPrice',
        key: 'minSupplyPrice',
        width: 130,
        render: (value, r, i) => {
          return (
              <InputNumber
                  keyboard={false}
                  controls={false}
                  style={{ width: '100%' }}
                  value={value}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={(value) => {
                    setItems(
                        produce((draft) => {
                          draft[i].minSupplyPrice = value;
                        }),
                    );
                  }}
              />
          );
        },
      },
      {
        title: '기반 사이즈',
        dataIndex: 'baseSize',
        key: 'baseSize',
        width: 200,
        render: (d, r, i) => (
            <Input
                value={d}
                onChange={(e) => {
                  const value = e.target.value;
                  setItems(
                      produce((draft) => {
                        draft[i].baseSize = value;
                      }),
                  );
                }}
            />
        ),
      },
      {
        title: '소개글',
        dataIndex: 'contents',
        key: 'contents',
        width: 300,
        render: (d, r, i) => (
            <Input.TextArea
                value={d}
                onChange={(e) => {
                  const value = e.target.value;
                  setItems(
                      produce((draft) => {
                        draft[i].contents = value;
                      }),
                  );
                }}
            />
        ),
      },
      {
        title: '설정',
        dataIndex: 'serial',
        key: 'serial2',
        width: 70,
        align: 'center',
        fixed: 'right',
        render: (d, r, i) => (
            <Button
                size={'small'}
                danger
                onClick={() => {
                  setItems(
                      produce((draft) => {
                        draft.splice(i, 1);
                      }),
                  );
                }}
            >
              삭제
            </Button>
        ),
      },
    ];
  }, [file]);

  return (
      <div>
        <h1 style={{marginBottom:66}}>신규상품등록</h1>
        <div style={{display:'flex'}}>
          <div style={{marginRight:30}}>
            <button onClick={downloadFile} style={{background:'none',display:'flex',alignItems:'center'}}>
            <img style={{width:16,marginRight:5,'font-family': 'Pretendard'}} src={require('../../../assets/newDoc.png')}  alt={'1'}/>
            <span style={{fontSize:14,color: '#767676'}}>업로드 템플릿 다운로드</span>
          </button>
          </div>
          <div style={{marginRight:30}}>
            <Upload
                fileList={[]}
                beforeUpload={(f) => {
                  setFile(f);
                  processUpload(f).catch(console.warn);
                  return false;
                }}
            >
            <button style={{background:'none',display:'flex',alignItems:'center'}}>
              <img style={{width:20,height:20,marginRight:5,'font-family': 'Pretendard'}} src={require('../../../assets/upload.png')}  alt={'1'}/>
              <span style={{fontSize:14,color: '#767676'}}>업로드 처리</span>
            </button>
            </Upload>
          </div>
          <div>
            <button onClick={handleSubmit} style={{background:'none',display:'flex',alignItems:'center'}}>
              <img style={{width:18,height:20,marginRight:5,'font-family': 'Pretendard'}} src={require('../../../assets/disk.png')}  alt={'1'}/>
              <span style={{fontSize:14,color: '#767676'}}>일괄저장</span>
            </button>
          </div>
        </div>
        <div ref={mainTable}>
              <Table
                  loading={loading}
                  style={{minWidth:1600}}
                  // scroll={{ x: columns?.reduce((a, b) => a + b.width, 0),y: bodyHeight }}
                  dataSource={items}
                  size={'small'}
                  columns={columns}
                  pagination={false}
                  rowKey={(r) => `${items.indexOf(r)}`}/>
        </div>
      </div>
  );
}
export default ProductCreate

