import React from 'react';
import {
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  PageHeader,
  Row,
  Tree,
} from 'antd';
import styles from './CategoryManage.module.less';
import { useGetProductCategories } from '../../../apis/queries';
import { restApi } from '../../../apis';
import { useQueryClient } from 'react-query';
import { PRODUCT_CATEGORY_KEY } from '../../../apis/queryKeys';
const TreeComponent = React.memo(
  ({ title, id, setVisibleModal,setVisibleModalNew, setSelectedId, deletable = false, queryClient, setIsEdit }) => {
    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', width: '100%' }}>
        <div
          onClick={() => {
            if (deletable) {
              setIsEdit(true);
              setVisibleModal();
              setSelectedId(id);
            }
          }}
          style={{ flex: 1 }}
        >
          {title}
        </div>
        <div className={styles.buttonWrapper}>
          <div
            onClick={() => {
              setVisibleModalNew();
              // setSelectedId(id);
            }}
            className={styles.leftButton}
          >
            <span className={styles.buttonText}>하위추가</span>
          </div>
          <div
            onClick={() => {
              Modal.confirm({
                content: '해당 카테고리를 삭제하시겠습니까?',
                okText: '확인',
                cancelText: '취소',
                onOk: () => {
                  restApi.delete(`/products/categories/manage/${id}`).then(async (res) => {
                    await queryClient.invalidateQueries(PRODUCT_CATEGORY_KEY);
                    Modal.success({
                      content: '삭제되었습니다.',
                      okText: '확인',
                      // onOk: () => ,
                    });
                  });
                },
              });
            }}
            className={styles.rightButton}
            style={{
              backgroundColor: deletable ? '#de7e7e' : '#e8e8e8',
              cursor: deletable ? 'pointer' : 'not-allowed',
            }}
          >
            <span className={styles.buttonText}>삭제</span>
          </div>
        </div>
      </div>
    );
  },
);
const ProductsCategoriesIndex = (props) => {
  const queryClient = useQueryClient();
  const { data: productCategories } = useGetProductCategories();
  const [visibleModal, setVisibleModal] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const formRef = React.useRef(null);
  const treeData = React.useMemo(() => {
    const data = productCategories?.tree;

    const returnChildren = (items) => {
      if (items?.children?.length > 0) {
        return {
          title: () => (
            <TreeComponent
              setVisibleModal={()=>setVisibleModal(items)}
              setVisibleModalNew={()=>setVisibleModal({parentCategoryId:items?.id})}
              setSelectedId={setSelectedId}
              title={items.name}
              id={items?.id}
              deletable
              queryClient={queryClient}
              setIsEdit={setIsEdit}
            />
          ),
          key: items.id,
          children: items?.children?.map((v) => returnChildren(v)),
        };
      }
      return {
        title: () => (
          <TreeComponent
            setVisibleModal={()=>setVisibleModal(items)}
            setVisibleModalNew={()=>setVisibleModal({parentCategoryId:items?.id})}
            setSelectedId={setSelectedId}
            title={items.name}
            id={items?.id}
            deletable
            queryClient={queryClient}
            setIsEdit={setIsEdit}
          />
        ),
        key: items.id,
        children: null,
      };
    };
    return [
      {
        title: () => <TreeComponent setVisibleModalNew={()=>setVisibleModal({})} setVisibleModal={setVisibleModal} setSelectedId={setSelectedId} title="전체" />,
        key: 'all',
        children: data?.map((v) => returnChildren(v)),
      },
    ];
  }, [productCategories, setVisibleModal, setSelectedId]);
  const handleAddItem = React.useCallback(() => {
    const { categoryName, orderNum,isShowApp } = formRef.current.getFieldsValue();
    const api = visibleModal?.id
      ? (data) => restApi.put(`products/categories/manage/${visibleModal?.id}`, data)
      : (data) => restApi.post('products/categories/manage', data);
    api({
      name: categoryName,
      orderNum,isShowApp,
      parentCategoryId: visibleModal?.parentCategoryId,
    }).then(async (res) => {
      await queryClient.invalidateQueries(PRODUCT_CATEGORY_KEY);
      Modal.success({
        content: `카테고리가 ${isEdit ? '수정' : '추가'}되었습니다.`,
        okText: '확인',
        onOk: () => {
          setVisibleModal(undefined);
          setSelectedId(null);
        },
      });
    });
  }, [formRef, visibleModal, isEdit]);

  return (
    <div style={{display:'flex',flexDirection:'column'}}>
      <Modal
        width={450}
        bodyStyle={{
          backgroundColor: '#f9f9f9',
          display: 'flex',
          flexDirection: 'column',
          padding: '30px 20px',
        }}
        open={visibleModal}
        onOk={handleAddItem}
        onCancel={() => {
          setVisibleModal(undefined);
        }}
        destroyOnClose={true}
        okText={'확인'}
        cancelText="취소"
      >
        <Form
          ref={formRef}
          style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
        >
          <Form.Item
            label="카테고리명"
            name="categoryName"
            initialValue={visibleModal?.name}
            rules={[
              {
                required: true,
                message: '카테고리명을 입력해 주세요.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="순서"
            name="orderNum"
            initialValue={visibleModal?.orderNum}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
              valuePropName={'checked'}
              label="앱 노출여부"
              name="isShowApp"
              initialValue={visibleModal?.isShowApp}
              rules={[
                {
                  required: false,
                },
              ]}
          >
            <Checkbox  defaultChecked={visibleModal?.isShowApp} />
          </Form.Item>
        </Form>
      </Modal>
      <PageHeader
        className="site-page-header"
        title="카테고리 관리"
        style={{ width: 840, marginLeft: 'auto', marginRight: 'auto' }}
      />
      <div className={styles.category_div}>
        <div className={styles.category_header}>
          <div className={styles.category_header_title_section}>
            <span>대분류 / 중분류</span>
          </div>
          <div className={styles.category_header_title_section}>
            <span>하위추가 / 삭제</span>
          </div>
        </div>
        <div style={{ maxWidth: 1280, backgroundColor: 'red', height: '100%' }}>
          <Row gutter={10}>
            <Col span={10}>
              <Card>
                <div className={styles.tree_container_div}>
                  <Tree defaultExpandedKeys={['all']} treeData={treeData} showLine />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ProductsCategoriesIndex;
