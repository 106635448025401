import React from 'react';
import CustomCheckBox from './button/CustomCheckBox';
import CustomModalCheckBox from './button/CustomModalCheckBox';

const ModalLowerLine = ({ onCheck, checked, children, containerStyle, hideAllCheck }) => {
  return (
    <div
      style={{
        width: 870,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        padding: '10px 0 13px 0',
        height: 50,
        ...containerStyle,
      }}
    >
      {!hideAllCheck && <CustomModalCheckBox onClick={onCheck} text={'모두선택'} key={`check_all`} checked={checked} />}
      {children}
    </div>
  );
};

export default ModalLowerLine;
