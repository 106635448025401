import { Modal } from 'antd';
import React from 'react';
import { FilterInput } from '../components/template/page';
import RoundButton from '../components/button/RoundButton';
import { parseImageUri } from '../common/utils';
import { StarFilled, CloseOutlined } from '@ant-design/icons';

const Star = React.memo(({ rate }) => {
  const itemWidth = 20 * rate;
  return (
    <div
      style={{
        width: itemWidth,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <StarFilled style={{ fontSize: 18, color: '#1e3d83', textAlign: 'right' }} />
    </div>
  );
});

const RatingComponent = React.memo(({ rate }) => {
  const Dots = ({ rate }) => {
    const itemWidth = 10 * rate;
    return (
      <div
        style={{
          backgroundColor: '#767676',
          borderRadius: 5,
          width: itemWidth,
          height: 5,
        }}
      />
    );
  };

  const defineText = {
    designRate: '디자인',
    constructionQualityRate: '공사품질',
    defectManagementRate: '하자관리',
  };

  const rateItems = React.useCallback(
    (rate) => {
      let rate_ = rate;
      const item = [];
      for (let i = 0; i < 5; i++) {
        if (rate_ > 0) {
          if (rate_ > 1) {
            item?.push(1);
          } else {
            item.push(Math.round(rate_ * 10) / 10);
          }
          rate_ -= 1;
        }
      }
      return item;
    },
    [rate],
  );

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '2.5px' }}>
      {Object.entries(defineText).map(([k, v]) => (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ width: '40%' }}>
            <span
              style={{
                fontSize: '12px',
                color: '#767676',
              }}
            >
              {v}
            </span>
          </div>
          <div
            style={{
              width: '60%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ gap: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {rateItems(rate[k])?.map((v) => (
                <Dots rate={v} />
              ))}
            </div>
            <span style={{ fontSize: '12px', color: '#767676', fontWeight: 'bold' }}>{rate[k]}</span>
          </div>
        </div>
      ))}
    </div>
  );
});

const ReviewCard = React.memo(({ item }) => {
  const totalRate = React.useMemo(() => {
    const { designRate, constructionQualityRate, defectManagementRate } = item?.rate;
    return (designRate + constructionQualityRate + defectManagementRate) / 3;
  }, [item]);
  const starItems = React.useMemo(() => {
    let rate = totalRate;
    const item = [];
    for (let i = 0; i < 5; i++) {
      if (rate > 0) {
        if (rate > 1) {
          item?.push(1);
        } else {
          item.push(Math.round(rate * 10) / 10);
        }
        rate -= 1;
      }
    }
    return item;
  }, [totalRate]);

  return (
    <div
      style={{
        position: 'relative',
        width: '330px',
        height: '425px',
        margin: '2px 15px 1.5px 19px',
        borderRadius: '10px',
        boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.08)',
        backgroundColor: '#fff',
        padding: '18.5px 15px 18.5px 15px',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '24px',
          height: '24px',
          borderRadius: '12px',
          backgroundColor: '#1e3d83',
          right: '10px',
          top: '10px',
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <CloseOutlined style={{ color: '#fff' }} />
      </div>
      <span
        style={{
          fontSize: '15px',
          fontWeight: '600',
          color: '#000',
        }}
      >
        {item?.title}
      </span>
      <div
        style={{
          marginTop: 25.5,
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          style={{ width: '38.5px', height: '38.5px', borderRadius: '50%' }}
          src={parseImageUri(item?.createdBy?.profileImageId)}
          alt="profileImage"
        />
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <span
              style={{
                fontSize: '11px',
                fontWeight: '600',
                color: '#000',
              }}
            >
              {item?.createdBy?.nickname}
            </span>
            <span
              style={{
                fontSize: '11px',
                color: '#767676',
              }}
            >
              {item?.createdBy?.createdAt}
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 24.5,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
            {starItems?.map((v) => (
              <Star rate={v} />
            ))}
          </div>
          <span
            style={{
              fontSize: '29px',
              fontWeight: 'bold',
              color: '#1e3d83',
              marginTop: '3.2px',
              textAlign: 'right',
            }}
          >
            {Math.round(totalRate * 10) / 10}
          </span>
        </div>
        <div style={{ width: '50%', display: 'flex' }}>
          <RatingComponent rate={item?.rate} />
        </div>
      </div>
      <div style={{ marginTop: '28.5px', display: 'flex', flexDirection: 'column' }}>
        <span
          style={{
            fontSize: '14px',
            fontWeight: '600',
            color: '#767676',
          }}
        >
          공사후기
        </span>
        <span
          style={{
            marginTop: '8px',
            fontSize: '12px',
            color: '#767676',
          }}
        >
          {item?.reviewContent}
        </span>
      </div>
    </div>
  );
});

const reviewItemsDummy = [
  {
    title: '이매동 진흥아파트 135',
    createdBy: {
      nickname: '돼지아빠',
      createdAt: '2023.10.25 12:35',
      profileImageId: 8106,
    },
    rate: {
      designRate: 5,
      constructionQualityRate: 5,
      defectManagementRate: 5,
    },
    reviewContent:
      '바닥 보일러 배관 모두 새로하는 큰 공사라 걱정이 많았는데요 처음부터 상담 친절히 잘해주시고 견적서도 어떤시공하는지 잘 알아볼수있게 제일 잘 적어주셨어요가격도 합리적이었습니다 공사기일 시공표보내 주신대로 일정 지연없이 딱 마쳐주셨구요 맞벌이라 매일 공사현장 가볼수 없었는데 카톡으로 공사 과정 매일 보내주셔서 편하게 잘 마친것 같아아요 결과도 너무 만족스럽고 시공 마치고 이사온지 2달째인데 아직까지 하자는 없습니다',
  },
  {
    title: '이매동 진흥아파트 135',
    createdBy: {
      nickname: '돼지아빠',
      createdAt: '2023.10.25 12:35',
      profileImageId: 8106,
    },
    rate: {
      designRate: 4.9,
      constructionQualityRate: 4.5,
      defectManagementRate: 3.8,
    },
    reviewContent:
      '바닥 보일러 배관 모두 새로하는 큰 공사라 걱정이 많았는데요 처음부터 상담 친절히 잘해주시고 견적서도 어떤시공하는지 잘 알아볼수있게 제일 잘 적어주셨어요가격도 합리적이었습니다 공사기일 시공표보내 주신대로 일정 지연없이 딱 마쳐주셨구요 맞벌이라 매일 공사현장 가볼수 없었는데 카톡으로 공사 과정 매일 보내주셔서 편하게 잘 마친것 같아아요 결과도 너무 만족스럽고 시공 마치고 이사온지 2달째인데 아직까지 하자는 없습니다',
  },
  {
    title: '이매동 진흥아파트 135',
    createdBy: {
      nickname: '돼지아빠',
      createdAt: '2023.10.25 12:35',
      profileImageId: 8106,
    },
    rate: {
      designRate: 4.9,
      constructionQualityRate: 4.5,
      defectManagementRate: 3.5,
    },
    reviewContent:
      '바닥 보일러 배관 모두 새로하는 큰 공사라 걱정이 많았는데요 처음부터 상담 친절히 잘해주시고 견적서도 어떤시공하는지 잘 알아볼수있게 제일 잘 적어주셨어요가격도 합리적이었습니다 공사기일 시공표보내 주신대로 일정 지연없이 딱 마쳐주셨구요 맞벌이라 매일 공사현장 가볼수 없었는데 카톡으로 공사 과정 매일 보내주셔서 편하게 잘 마친것 같아아요 결과도 너무 만족스럽고 시공 마치고 이사온지 2달째인데 아직까지 하자는 없습니다',
  },
];

const useReviewModal = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const ReviewModal = () => {
    return (
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={true}
        okText={'확인'}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div style={{ padding: '70px 25px 70px 25px' }}>
          <h3
            style={{
              fontSize: '24px',
              fontWeight: '600',
              color: '#000',
            }}
          >
            공사리뷰 관리
          </h3>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <FilterInput style={{ width: 250, marginTop: 18 }} placeholder="검색어를 입력하세요" />
            <div style={{ width: '80px' }}>
              <RoundButton reverse label="찾기" />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '40px',
              marginTop: '84px',
              alignItems: 'center',
            }}
          >
            {reviewItemsDummy?.map((v) => (
              <ReviewCard item={v} />
            ))}
          </div>
        </div>
      </Modal>
    );
  };
  return { showModal, setIsModalOpen, ReviewModal };
};

export default useReviewModal;
