import React, { useCallback, useEffect, useState } from 'react';
import { Button, DatePicker, Image, Modal, PageHeader, Table } from 'antd';
import { getAPIHost, restApi } from '../../../apis';
import Slider from 'react-slick';
import CommentIcon from '../../../components/image/CommentIcon';
import styles from './AgencyBoard.module.less';
import profileImage from '../../../assets/dummy/user/02.jpg';
import {
  CloseCircleFilled,
  CloseCircleOutlined,
  DollarCircleOutlined,
  HeartFilled,
  UserOutlined,
  WechatFilled,
} from '@ant-design/icons';
import Comment from '../../../components/comment/Comment';
import { getFileId, parseImageUri } from '../../../common/utils';
import { useMutation, useQueryClient } from 'react-query';
import { EXPERT_TALK_LIST_DETAIL_KEY, EXPERT_TALK_LIST_KEY } from '../../../apis/queryKeys';
import produce from 'immer';
import { useGetExpertTalkDetail } from '../../../apis/queries';

const AgencyBoardModifyModal = ({ detailId, isModalModify, setIsModalModify }) => {
  const settings = {
    className: 'center',
    infinite: false,
    centerPadding: '60px',
    slidesToShow: 5,
    swipeToSlide: true,
    afterChange: function (index) {
      console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
    },
  };
  const queryClient = useQueryClient();
  const { data: detailData } = useGetExpertTalkDetail({ id: detailId });
  const [detailModify, setDetailModify] = React.useState({
    title: detailData?.title ?? '',
    content: detailData?.content ?? '',
    images: detailData?.images ?? [],
  });
  React.useEffect(() => {
    setDetailModify(
      produce((draft) => {
        draft.title = detailData?.title;
        draft.content = detailData?.content;
        draft.images = detailData?.images;
      }),
    );
  }, [detailData]);
  console.log(detailModify);
  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    if (imageId) {
      setDetailModify(
        produce((draft) => {
          draft.images.push(imageId);
        }),
      );
    }
  };
  const handleModifyOK = async () => {
    try {
      await restApi.put(`/posts/expert-talk/${detailId}`, detailModify);
      Modal.confirm({
        title: '완료',
        content: '수정이 완료되었습니다',
        onOk() {
          window.location.reload();
          // setIsModalModify(false);
        },
        cancelButtonProps: {
          style: { display: 'none' },
        },
        okText: '확인',
      });
    } catch (error) {
      Modal.warning({
        title: '실패했습니다',
        content: error.response.data.message,
      });
      queryClient.invalidateQueries([EXPERT_TALK_LIST_DETAIL_KEY]);
      queryClient.invalidateQueries([EXPERT_TALK_LIST_KEY]);
    }
  };
  const handleModifyCancel = () => {
    setIsModalModify(false);
  };
  return (
    <Modal
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 200,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
      }}
      closable={false}
      open={isModalModify}
      onOk={handleModifyOK}
      onCancel={handleModifyCancel}
      destroyOnClose={true}
      maskClosable={true}
      okText={'수정하기'}
      cancelText={'나가기'}
    >
      <div style={{ width: '100%', backgroundColor: 'black' }}>
        <img
          style={{
            display: 'block',
            height: '450px',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: 'black',
          }}
          src={detailModify?.images?.length > 0 && parseImageUri(detailModify?.images[0])}
          alt=""
        />
      </div>
      <div style={{ padding: '50px 100px' }}>
        <Slider {...settings}>
          <div style={{ width: 108, height: 108, borderRadius: 10, position: 'relative' }}>
            <button
              style={{
                width: 108,
                height: 108,
                borderRadius: 10,
                border: 'none',
                backgroundColor: '#d9d9d9',
                color: 'white',
              }}
            >
              <input
                type="file"
                id="fileInput"
                style={{
                  width: 108,
                  height: 108,
                  opacity: 0,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  cursor: 'pointer',
                }}
                onChange={handleChangeImage}
              />
              +
              <br />
              추가사진
              <br />
              업로드
            </button>
          </div>
          {detailModify?.images?.length > 1 &&
            [...detailModify?.images].slice(1).map((item, index) => {
              return (
                <div style={{ width: 108, height: 108, borderRadius: 10, position: 'relative' }}>
                  <img style={{ width: 108, height: 108, borderRadius: 10 }} src={parseImageUri(item)} alt="" />
                  {[...detailModify?.images].slice(1).length === index + 1 && (
                    <div
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        position: 'absolute',
                        top: 0,
                        right: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setDetailModify(
                          produce((draft) => {
                            draft.images.pop();
                          }),
                        );
                      }}
                    >
                      <CloseCircleFilled style={{ color: '#787878', fontSize: 30, color: '#1e3d83' }} />
                    </div>
                  )}
                </div>
              );
            })}
        </Slider>
        <input
          type="text"
          value={detailModify?.title}
          style={{
            margin: '10px 0',
            fontWeight: 600,
            fontSize: 17,
            border: 'none',
            width: '100%',
            borderBottom: '1px solid #d9d9d9',
            backgroundColor: 'rgba(0,0,0,0)',
          }}
          onChange={(e) => {
            setDetailModify(
              produce((draft) => {
                draft.title = e.target.value;
              }),
            );
          }}
        />
        <div className={styles.flex_div}>
          <div className={styles.flex_div} style={{ alignItems: 'center' }}>
            {detailData?.createdBy?.profileImageId ? (
              <img className={styles.style_profile_img} src={profileImage} alt="프로필 이미지" />
            ) : (
              <div
                className={styles.style_profile_img}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#e8e8e8',
                }}
              >
                <UserOutlined style={{ color: 'white', fontSize: 30 }} />
              </div>
            )}
            <div>
              <p className={styles.category_p} style={{ marginLeft: 10, color: 'black', fontWeight: 'bold' }}>
                {detailData?.createdBy?.nickname}
              </p>
              <p className={styles.category_p} style={{ marginLeft: 10, color: '#787878' }}>
                {detailData?.createdAt}
              </p>
            </div>
          </div>
          <div className={styles.flex_div}>
            <div>
              <HeartFilled style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p} style={{ color: '#999999', textAlign: 'center' }}>
                {detailData?.likeInfo?.likeCount}
              </p>
            </div>
            <div style={{ marginLeft: 5 }}>
              <CommentIcon style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p} style={{ color: '#999999', textAlign: 'center' }}>
                {detailData?.commentCount}
              </p>
            </div>
          </div>
        </div>
        <textarea
          style={{
            width: '100%',
            minHeight: 200,
            backgroundColor: '#f9f9f9',
            border: '1px solid #d9d9d9',
            padding: 20,
          }}
          value={detailModify?.content}
          onChange={(e) => {
            setDetailModify(
              produce((draft) => {
                draft.content = e.target.value;
              }),
            );
          }}
        ></textarea>
        <div className={styles.modify_input_wrapper_div}>
          <input
            type="text"
            defaultValue={'https://www.naver.com/dskfjhuwqerui@welkij124909'}
            className={styles.modify_title_input}
            style={{ fontWeight: 'normal', color: '#999', fontSize: 14 }}
            maxLength={80}
          />
        </div>
      </div>
      <div style={{ padding: '50px 100px' }}>
        <Comment id={detailId} type={'POST'} />
      </div>
    </Modal>
  );
};

export default AgencyBoardModifyModal;
