import React from 'react';
import { Modal, PageHeader } from 'antd';
import { ExtraWrapper, FilterSelect } from '../../../components/template/page';
import styles from './estimate_tpls.module.less';
import {
  useGetConstructionCategories,
  useGetConstructionTemplatesAdmin,
  useGetProductCategories,
} from '../../../apis/queries';
import { Option } from 'antd/lib/mentions';
import produce from 'immer';
import PaginateTable from '../../../components/table/PaginateTable';
import { useSelector } from 'react-redux';
import { findCategoryDeps, formatComma } from '../../../common/utils';
import TableColHeader from '../../../components/table/TableColHeader';
import RoundButton from '../../../components/button/RoundButton';
import ModifyTemplateModal from '../../../components/modal/ModifyTemplateModal';

const SettingsEstimateTplIndex = () => {
  const { commonCodes } = useSelector((s) => s.common);
  const areaMap = React.useMemo(
    () =>
      commonCodes?.tree['AREA']?.reduce((acc, v) => {
        acc[v.code] = v.label;
        return acc;
      }, {}),
    [commonCodes],
  );
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      categoryId: null,
    }),
    [],
  );
  const [selectedId, setSelectedId] = React.useState(null);
  const [visibleModal, setVisibleModal] = React.useState(false);
  const [tableHeight, setTableHeight] = React.useState();
  const [params, setParams] = React.useState(initialParams);
  const [mainCategory, setMainCategory] = React.useState(null);
  const [subCategory, setSubCategory] = React.useState(null);
  const { data: productCategories } = useGetProductCategories();
  const { data: categories } = useGetConstructionCategories();
  const { data: templates } = useGetConstructionTemplatesAdmin({ params });

  const mainCategoryItems = React.useMemo(() => {
    if (!categories) return [];
    let categories_ = categories?.tree?.map((v) => ({
      label: v.categoryName,
      value: v.categoryId,
    }));
    categories_.unshift({
      label: '전체',
      value: null,
    });
    return categories_;
  }, [categories]);

  const subCategoryItems = React.useMemo(() => {
    setSubCategory(null);
    if (!mainCategory) return [];

    const mainCategory_ = categories?.tree?.find((v) => v.categoryId === mainCategory);

    let categories_ = mainCategory_?.children?.map((v) => ({
      label: v.categoryName,
      value: v.categoryId,
    }));
    categories_?.unshift({
      label: '전체',
      value: null,
    });
    return categories_;
  }, [mainCategory, categories]);

  React.useEffect(() => {
    setParams(
      produce((draft) => {
        if (mainCategory) {
          if (subCategory) {
            draft.categoryId = subCategory;
          } else {
            draft.categoryId = mainCategory;
          }
        } else {
          draft.categoryId = null;
        }
      }),
    );
  }, [mainCategory, subCategory]);

  const returnAreas = (v) => {
    if (!areaMap) return;
    return v?.map((v) => areaMap[v])?.join(', ');
  };

  const columns = React.useMemo(
    () => [
      {
        title: '공종명(소분류)',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        width: 250,
        render: (v) => <span className={styles.colBoldText}>{v}</span>,
      },
      {
        title: '적용공간',
        dataIndex: 'areas',
        key: 'areas',
        align: 'left',
        render: (v) => <span className={styles.colText}>{returnAreas(v)}</span>,
      },
      {
        title: '선택가능',
        dataIndex: 'isProductSelectable',
        key: 'isProductSelectable',
        width: 80,
        align: 'center',
        render: (v) => <span className={styles.colText}>{v ? 'Y' : 'N'}</span>,
      },
      {
        title: '적용금액',
        dataIndex: 'price',
        key: 'price',
        width: 100,
        align: 'right',
        render: (v) => <span className={styles.colText}>{!v ? '제품가격' : formatComma(v)}</span>,
      },
      {
        title: '공간별 기본금액',
        dataIndex: 'pricePerRoom',
        key: 'pricePerRoom',
        width: 100,
        align: 'right',
        render: (v) => <span className={styles.colText}>{formatComma(v ?? '0')}</span>,
      },
      {
        title: '전체 기본금액',
        dataIndex: 'defaultPrice',
        key: 'defaultPrice',
        width: 100,
        align: 'right',
        render: (v) => <span className={styles.colText}>{formatComma(v ?? '0')}</span>,
      },
      {
        title: '적용상품카테고리',
        dataIndex: 'productCategoryIds',
        key: 'productCategoryIds',
        width: 250,
        align: 'left',
        render: (v) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {v?.length > 0
              ? v?.map((v2) => (
                  <span className={styles.colText}>{findCategoryDeps(productCategories?.tree, v2).join(' > ')}</span>
                ))
              : '-'}
          </div>
        ),
      },
      {
        title: <TableColHeader title="최종작성자" subTitle="최종작성일시" />,
        dataIndex: 'defaultPrice',
        key: 'defaultPrice',
        width: 160,
        align: 'center',
        render: (v) => <span>{}</span>,
      },
      {
        title: '수정',
        dataIndex: 'id',
        key: 'id',
        width: 120,
        align: 'center',
        render: (v) => (
          <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
            <RoundButton
              onClick={() => {
                setSelectedId(v);
                setVisibleModal(true);
              }}
              containerStyle={{ width: '100px' }}
              label="수정하기"
            />
          </div>
        ),
      },
    ],
    [productCategories],
  );

  React.useEffect(() => {
    const fn = () => setTableHeight(document.body.clientHeight - 60 - 200);
    fn();
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <PageHeader className="site-page-header" title="견적 템플릿 작성" style={{ paddingBottom: 10 }} />
      <ExtraWrapper>
        {/* 필터 윗줄 */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4.5 }}>
          <div style={{ marginLeft: 20, display: 'flex', gap: '40px' }}>
            <FilterSelect
              value={mainCategory}
              onChange={(v) => setMainCategory(v)}
              style={{ width: 170 }}
              title="대분류"
              placeholder="전체"
            >
              {mainCategoryItems?.map((v) => (
                <Option value={v.value}>{v.label}</Option>
              ))}
            </FilterSelect>
            <FilterSelect
              value={subCategory}
              onChange={(v) => setSubCategory(v)}
              style={{ width: 170 }}
              title="중분류"
              placeholder="전체"
            >
              {subCategoryItems?.map((v) => (
                <Option value={v.value}>{v.label}</Option>
              ))}
            </FilterSelect>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              position: 'relative',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <RoundButton
              onClick={() => {
                if (!!subCategory) {
                  setVisibleModal(true);
                } else {
                  Modal.warn({
                    content: '중분류까지 선택해 주세요.',
                    okText: '확인',
                  });
                }
              }}
              reverse
              containerStyle={{
                width: '100px',
              }}
              label="공종추가"
            />
          </div>
        </div>
        {/* 필터 아랫줄 */}

        <div style={{ flex: 1, marginTop: '20px' }}>
          <PaginateTable
            dataSource={templates?.content}
            columns={columns}
            scroll={{ y: tableHeight - 40 }}
            data={templates}
            page={params?.page}
            setPage={(v) =>
              setParams(
                produce((draft) => {
                  draft.page = v;
                }),
              )
            }
            pageSize={params?.limit}
            setPageSize={(v) =>
              setParams(
                produce((draft) => {
                  draft.limit = v;
                }),
              )
            }
            // onRow={() => {}}style={{
            //               borderRadius: 10,
            //               overflow: 'hidden',
            //               width: '100%',
            //               backgroundColor: 'white',
            //               boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            //               paddingBottom: '100px',
            //             }}
          />
        </div>
      </ExtraWrapper>
      <ModifyTemplateModal
        templateId={selectedId}
        visible={visibleModal}
        setVisible={setVisibleModal}
        handleCancel={() => {
          setVisibleModal(false);
          setSelectedId(null);
        }}
        categoryId={subCategory}
      />
    </div>
  );
};

export default SettingsEstimateTplIndex;
