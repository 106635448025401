import moment from 'moment';
import React from 'react';
import { ExtraWrapper, FilterButton, FilterSelect, FilterWhiteButton } from '../../template/page';
import styles from './CurrentBid.module.less';
import { PageHeader, Modal } from 'antd';
import { useGetConstructionRequestProposed } from '../../../apis/queries';
import { Option } from 'antd/lib/mentions';
import assets from '../../../assets';
import TableColHeader from '../../table/TableColHeader';
import { formatComma, getTimeAgo, parseImageUri } from '../../../common/utils';
import PaginateTable from '../../table/PaginateTable';
import { useQueryClient } from 'react-query';
import { CONSTRUCTION_REQUEST_KEY } from '../../../apis/queryKeys';
const CurrentBid = ({ detailId, data, isFirst, onStop }) => {
  const queryClient = useQueryClient();
  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: null,
      sort: null,
    }),
    [],
  );
  const [page, setPage] = React.useState(initialParams.page);
  const [pageSize, setPageSize] = React.useState(20);
  const [order, setOrder] = React.useState('DESC');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { data: proposeData, refetch } = useGetConstructionRequestProposed({
    id: detailId,
    params: { page: page, limit: pageSize, order: order },
  });
  React.useEffect(() => {
    refetch();
  }, [detailId]);
  console.log(proposeData);
  const [modifyWidth, setModifyWidth] = React.useState(false);

  //테이블 높이
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 380);

  const customColumns = [
    {
      title: '썸네일',
      dataIndex: 'thumbnailId',
      key: 'thumbnailId',
      width: 60,
      align: 'center',
      render: (data, record) => {
        return (
          <>
            <img
              src={data ? parseImageUri(data) : assets.icons.blank_profile}
              alt=""
              style={{ width: 50, height: 50, borderRadius: '50%' }}
            />
          </>
        );
      },
    },
    {
      title: '업체명',
      key: 'partnerInfo',
      dataIndex: 'partnerInfo',
      align: 'center',
      width: 60,
      render: (data, record) => {
        return (
          <>
            <p>{data.nickname}</p>
          </>
        );
      },
    },
    {
      title: '제안 예산',
      key: 'proposedPrice',
      dataIndex: 'proposedPrice',
      align: 'center',
      width: 100,
      render: (data, record) => (
        <>
          <p style={{ color: '#1e3d83', fontSize: 14, fontWeight: 'bold' }}>{data ? formatComma(data) : 0}원</p>
        </>
      ),
    },

    {
      title: '포트폴리오',
      key: 'isShowPortfolio',
      dataIndex: 'isShowPortfolio',
      align: 'center',
      width: 50,
      render: (data, record) => (
        <>
          <p style={{ color: '#767676', fontSize: 12 }}>{data ? 'Y' : 'N'}</p>
        </>
      ),
    },
    {
      title: '제안도면',
      key: 'hasBluePrints',
      dataIndex: 'hasBluePrints',
      align: 'center',
      width: 50,
      render: (data, record) => (
        <>
          <p style={{ color: '#767676', fontSize: 12 }}>{data ? 'Y' : 'N'}</p>
        </>
      ),
    },
    {
      title: '심층제안',
      key: 'hasDeepDive',
      dataIndex: 'hasDeepDive',
      align: 'center',
      width: 50,
      render: (data, record) => (
        <>
          <p style={{ color: '#767676', fontSize: 12 }}>{data ? 'Y' : 'N'}</p>
        </>
      ),
    },
    {
      title: '제안일시',
      key: 'createdAt',
      dataIndex: 'createdAt',
      align: 'center',
      width: 100,
      render: (data, record) => (
        <>
          <p style={{ color: '#767676', fontSize: 12 }}>{getTimeAgo(data)}</p>
          <p style={{ color: '#767676', fontSize: 12 }}>{moment(data).format('YYYY-MM-DD HH:mm')}</p>
        </>
      ),
    },
    {
      title: '채택일시',
      key: 'selectedAt',
      dataIndex: 'selectedAt',
      align: 'center',
      width: 100,
      render: (data, record) => (
        <>
          {data ? (
            <>
              <p style={{ color: '#767676', fontSize: 12 }}>{getTimeAgo(data)}</p>
              <p style={{ color: '#767676', fontSize: 12 }}>{moment(data).format('YYYY-MM-DD HH:mm')}</p>
            </>
          ) : (
            <p style={{ color: 'red', fontWeight: 'bold' }}>미채택</p>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <h3 style={{ marginTop: isFirst ? 0 : 20, marginBottom: 5 }}>입찰현황</h3>
      <div className={styles.bid_container}>
        <div className={styles.line}>
          <span
            style={!!data?.finishedAt ? { backgroundColor: 'rgb(30, 61, 131)' } : {}}
            className={styles.status_label}
          >
            {!data?.finishedAt ? (data?.isRunning ? '진행중' : '중지') : '종료'}
          </span>
          <span className={styles.apart_title}>{data?.apartmentName}</span>
        </div>
        <span className={styles.line} style={{ fontSize: 12, color: '#767676' }}>
          {data?.address}
        </span>
        <div className={styles.line}>
          <span className={styles.small_bold_content}>분양면적 / 전용면적</span>
          {modifyWidth ? (
            <span className={`${styles.small_content} ${styles.small_long_content}`}>
              {data?.estimateInfo?.exclusiveArea}㎡/{data?.estimateInfo?.supplyArea}㎡
            </span>
          ) : (
            <span className={`${styles.small_content} ${styles.small_long_content}`}>
              약 {(data?.estimateInfo?.exclusiveArea * 0.3025).toFixed(1)}평/ 약{' '}
              {(data?.estimateInfo?.supplyArea * 0.3025).toFixed(1)}평
            </span>
          )}
          <button
            className={styles.small_bold_content}
            onClick={() => {
              setModifyWidth((prev) => !prev);
            }}
          >
            평환산
          </button>
        </div>
        <div className={styles.line}>
          <span className={styles.small_bold_content}>방 개수 / 화장실 개수</span>
          <span className={styles.small_content}>
            {data?.estimateInfo?.roomCount}/{data?.estimateInfo?.bathRoomCount}
          </span>
        </div>
        <div className={styles.line}>
          <span className={styles.small_bold_content}>입찰 시작일</span>
          <span className={styles.small_content}>{moment(data?.createdAt).format('YYYY.MM.DD')}</span>
        </div>
        <div className={styles.line}>
          <span className={styles.small_bold_content}>입찰 마감일</span>
          <span className={styles.small_content}>
            {moment(data?.finishedAt ?? moment(data?.createdAt).add(7, 'days')).format('YYYY.MM.DD')}
          </span>
        </div>
        <div className={styles.line} style={{ display: 'flex' }}>
          <div>
            <span className={styles.small_bold_content}>선정 / 입찰업체</span>
            <span className={`${styles.small_content} ${styles.primary_text}`}>
              {data?.selectedProposalCount}/{data?.proposalCount}
            </span>
          </div>
          {/*todo 민규 : 이거 해주셈*/}
          <FilterWhiteButton
            onClick={() => {
              queryClient.invalidateQueries([CONSTRUCTION_REQUEST_KEY]);
              setIsModalOpen(true);
            }}
            style={{ width: 130 }}
          >
            리스트 보기
          </FilterWhiteButton>
        </div>
        <div className={styles.line}>
          <span className={styles.small_bold_content}>완료구분</span>
          <span className={`${styles.small_content} ${styles.primary_text}`}>
            {data?.finishedAt ? '완료' : '미완료'}
          </span>
        </div>
        {data?.isRunning && (
          <div style={{ textAlign: 'center' }}>
            {/*todo 민규 : 이거 목록에서 가져와서  해주셈*/}
            <button className={styles.stop_btn} onClick={onStop}>
              진행중지
            </button>
          </div>
        )}
      </div>
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        destroyOnClose={true}
        maskClosable={true}
        okText={'확인'}
        cancelText={'수정'}
      >
        <div style={{ padding: '35px 25px' }}>
          <p style={{ fontSize: 24, fontWeight: 600 }}>선정 / 입찰업체 리스트</p>
          <ExtraWrapper>
            {/* 필터 윗줄 */}
            {/* <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 4.5,
              width: '100%',
              position: 'relative',
            }}
          >
            <UpperLine listKey={PARTMER_POST_KEY} />
          </div> */}
            {/* 필터 아랫줄 */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <FilterSelect value={order} onChange={(v) => setOrder(v)}>
                <Option value="DESC">저장일시 최신순</Option>
              </FilterSelect>
            </div>
          </ExtraWrapper>
          <PaginateTable
            customHeight={true}
            dataSource={proposeData?.content}
            columns={customColumns}
            data={proposeData}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            scroll={{ y: tableHeight - 240 }}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default CurrentBid;
