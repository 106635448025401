import React from 'react';
import { useQueryClient } from 'react-query';
import produce from 'immer';
import { FilterSelect } from '../components/template/page';
import { SearchOutlined } from '@ant-design/icons';
import styles from './upper.module.less';

const useUpperLine = (filters, customAction) => {
  let inputValue;
  const filterOBJ = React.useMemo(() => {
    if (filters?.length > 0) {
      const obj = {};
      filters.forEach((el) => {
        obj[el.key] = el?.defaultValue ?? null;
      });
      return obj;
    }
    return null;
  }, [filters]);
  const changeInputHandler = (e) => {
    inputValue = e.target.value;
  };
  const [params, setParams] = React.useState({
    keyword: null,
    ...filterOBJ,
  });
  const UpperLine = ({ listKey, detailKey, setRefresh, containerStyle, children ,childrenRight}) => {
    const queryClient = useQueryClient();
    const pressEnter = async (e) => {
      if (e.key === 'Enter') {
        setParams(
          produce((draft) => {
            draft.keyword = e.target.value;
          }),
        );
        await queryClient.invalidateQueries([listKey]);
        await queryClient.invalidateQueries([detailKey]);
        if (setRefresh) {
          setRefresh();
        }
      }
    };
    const searchClick = async () => {
      setParams(
        produce((draft) => {
          draft.keyword = inputValue;
        }),
      );
      await queryClient.invalidateQueries([listKey]);
      await queryClient.invalidateQueries([detailKey]);
      if (setRefresh) {
        setRefresh();
      }
    };
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 870,
          ...containerStyle,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="text"
            onKeyDown={pressEnter}
            onChange={changeInputHandler}
            className={styles.search_input}
            style={{
              backgroundColor: '#e8e8e8',
              padding: '5px 10px',
              // borderRadius: 100,
              height: 32,
              borderRadius: '100px 0 0 100px',
              width: 220,
              border: 'none',
            }}
            placeholder="검색어를 입력하세요"
          />
          <button
            style={{
              width: 40,
              height: 32,
              backgroundColor: '#1e3d83',
              // borderRadius: 100,
              borderRadius: '0 100px 100px 0',
              border: 'none',
            }}
            onClick={searchClick}
          >
            <SearchOutlined style={{ fontSize: 16, color: 'white' }} />
          </button>
        </div>
        <div style={{ display: 'flex',justifyContent:'space-between' }}>
          {children}
          {filters?.map((el, index) => {
            return (
              <FilterSelect
                key={`filter ${index}`}
                defaultValue={el.defaultValue}
                value={params[el.key]}
                onChange={(e) => {
                  setParams(
                    produce((draft) => {
                      draft[el.key] = e;
                    }),
                  );
                  customAction?.(el.key, e);
                }}
                style={{ minWidth: 200 }}
                title={el.title}
              >
                {el.options?.map((option) => {
                  if (option === 'DESC' || option === 'ASC') {
                    return (
                      <FilterSelect.Option value={option}>
                        {option === 'ASC' ? '오름차순' : '내림차순'}
                      </FilterSelect.Option>
                    );
                  } else if (option === undefined) {
                    return <FilterSelect.Option value={option}>모두보기</FilterSelect.Option>;
                  } else if (option[1]) {
                    return <FilterSelect.Option value={option[0]}>{option[1]}</FilterSelect.Option>;
                  } else {
                    return <FilterSelect.Option value={option}>{option ? '가능' : '불가'}</FilterSelect.Option>;
                  }
                })}
              </FilterSelect>
            );
          })}
          {childrenRight}
        </div>
      </div>
    );
  };
  return { params, UpperLine };
};

export default useUpperLine;
