import React from 'react';
import profileImage from '../../assets/dummy/user/10.png';
import Thumbnail from '../image/Thumbnail';
import styles from './Comment.module.less';
import { useGetComment } from '../../apis/queries';
import { CloseCircleOutlined, UserOutlined } from '@ant-design/icons';
import { getTimeAgo, parseImageUri } from '../../common/utils';
import { shallowEqual, useSelector } from 'react-redux';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import produce from 'immer';
import { restApi } from '../../apis';
import { useQueryClient } from 'react-query';
import { COMMENT_KEY } from '../../apis/queryKeys';
import { Modal } from 'antd';
const Comment = ({ id, type, params,setCommentCount }) => {
  let timeoutID;
  const animateStyle = React.useMemo(() => ({
    animationName: styles.checkUpdate, // keyframes 이름
    animationDuration: '0.5s',
    animationDirection: 'alternate',
    animationTimingFunction: 'linear',
    animationIterationCount: '1.8',
    animationDelay: '0.2s',
  }));
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const queryClient = useQueryClient();
  const [commentStatus, setCommentStatus] = React.useState({
    replyCommentId: null,
    text: null,
    type: type,
  });
  const [updateId, setUpdtaeId] = React.useState(-1);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [updateAnimate, setUpdateAnimate] = React.useState(false);
  const { data: commentData } = useGetComment({ id, type, params });
  const commentInputRef = React.useRef();
  const updateInputRef = React.useRef();
  const unupdateInputRef = React.useRef();
  const HandleReply = (id) => {
    setCommentStatus(
      produce((draft) => {
        draft.replyCommentId = id;
      }),
    );
    elementScrollIntoView(commentInputRef.current, { behavior: 'smooth', block: 'center' });
    commentInputRef.current.focus();
  };
  const HandleInput = (e) => {
    setCommentStatus(
      produce((draft) => {
        draft.text = e.target.value;
      }),
    );
  };
  React.useEffect(()=>{
    setCommentCount?.(commentData?.totalElements)
  },[commentData])
  const createComment = async () => {
    try {
      await restApi.post(`/comments/${id}`, commentStatus);
    } catch (error) {
      console.log(error.response.data.message);
      Modal.warning({
        title: '실패했습니다',
        content: error.response.data.message,
      });
    }
    await queryClient.invalidateQueries([COMMENT_KEY]);
    commentInputRef.current.value = '';
  };
  const deleteComment = async (commentId) => {
    try {
      await restApi.delete(`/comments/${commentId}`);
    } catch (error) {
      Modal.warning({
        title: '실패했습니다',
        content: error.response.data.message,
      });
    }
    await queryClient.invalidateQueries([COMMENT_KEY]);
    commentInputRef.current.value = '';
  };
  const updateComment = async () => {
    try {
      await restApi.put(`/comments/${updateId}`, { text: commentStatus.text });
      setIsUpdate(false);
    } catch (error) {
      Modal.warning({
        title: '실패했습니다',
        content: error.response.data.message,
      });
    }
    await queryClient.invalidateQueries([COMMENT_KEY]);
    commentInputRef.current.value = '';
    elementScrollIntoView(updateInputRef.current, { block: 'center' });
    setUpdateAnimate(true);
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      setUpdateAnimate(false);
    }, 1500);
  };
  const HandleUpdate = async (item) => {
    setIsUpdate(true);
    setUpdtaeId(item.id);
    commentInputRef.current.value = item.text;
    setCommentStatus(
      produce((draft) => {
        draft.text = item.text;
      }),
    );
    elementScrollIntoView(commentInputRef.current, { behavior: 'smooth', block: 'center' });
    commentInputRef.current.focus();
  };
  const HandleCancelUpdate = () => {
    setIsUpdate(false);
    commentInputRef.current.value = '';
  };
  return (
    <div>
      <p className={styles.comment_count_p}>
        댓글 <span>{commentData?.totalElements}</span>
      </p>
      <div className={styles.comment_profile_div}>
        <Thumbnail containerStyle={{display:'flex',justifyContent:'center',alignItems:'center'}} imageStyle={{width:36,borderRadius:'50%',height:36}} id={principal?.profileImageId}></Thumbnail>
        <span style={{display:'inline-block',marginLeft:'0',minWidth:'max-content'}}>{principal.nickname}</span>
      </div>
      <div className={styles.comment_input_div}>
        <input type="text" ref={commentInputRef} onChange={HandleInput} />
        {!isUpdate ? (
          <button onClick={createComment}>보내기</button>
        ) : (
          <>
            <button onClick={updateComment}>수정</button>
            <button style={{ backgroundColor: '#1e3d83', color: 'white' }} onClick={HandleCancelUpdate}>
              취소
            </button>
          </>
        )}
      </div>
      {commentData?.content.map((item, index) => {
        return item?.replyTo ? (
          <div key={`comment profile ${index}`}>
            <div
              ref={item.id == updateId ? updateInputRef : unupdateInputRef}
              className={`${styles.comment_content_div} ${styles.comment_reply}`}
              style={item.id == updateId && updateAnimate ? { ...animateStyle } : {}}
            >
              <div className={styles.comment_profile_div}>
                <Thumbnail
                  containerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  imageStyle={{ width: 36, borderRadius: '50%', height: 36 }}
                  id={item?.createdBy?.profileImageId}
                  wrapperStyle={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                ></Thumbnail>
                <span style={{ display: 'inline-block', marginLeft: '0', minWidth: 'max-content' }}>
                  {item.createdBy.nickname}
                </span>
                <p style={{ display: 'flex', alignItems: 'center', color: '#767676', fontWeight: 400 }}>
                  <span style={{ color: '#767676', fontWeight: 400 }}>@</span>
                  <span style={{ color: '#767676', fontWeight: 400, marginLeft: 2, position: 'relative', top: 1.5 }}>
                    {item.replyTo.nickname}
                  </span>
                </p>
              </div>
              <p className={styles.comment_line_p}>{item.text}</p>
              <p>
                <span>{getTimeAgo(item.createdAt)}</span>
                <span className={styles.contour}>|</span>
                <span className={styles.comment_button} onClick={() => HandleReply(item.id)}>
                  답글쓰기
                </span>
                {principal?.id === item?.createdBy?.id && (
                  <>
                    <span className={styles.contour}>|</span>
                    <span className={styles.comment_button} onClick={() => HandleUpdate(item)}>
                      수정
                    </span>
                    <span className={styles.contour}>|</span>
                    <span className={styles.comment_button} onClick={() => deleteComment(item.id)}>
                      삭제
                    </span>
                  </>
                )}
              </p>
            </div>
          </div>
        ) : (
          <div key={`comment profile ${index}`}>
            <div
              ref={item.id == updateId ? updateInputRef : unupdateInputRef}
              className={`${styles.comment_content_div}`}
              style={item.id == updateId && updateAnimate ? { ...animateStyle } : {}}
            >
              <div className={styles.comment_profile_div}>
                <Thumbnail
                  containerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  imageStyle={{ width: 36, height: 36, borderRadius: '50%' }}
                  id={item?.createdBy?.profileImageId}
                ></Thumbnail>
                <span style={{ display: 'inline-block', marginLeft: '0', minWidth: 'max-content' }}>
                  {item.createdBy.nickname}
                </span>
              </div>
              <p className={styles.comment_line_p}>{item.text}</p>
              <p>
                <span>{getTimeAgo(item.createdAt)}</span>
                <span className={styles.contour}>|</span>
                <span className={styles.comment_button} onClick={() => HandleReply(item.id)}>
                  답글쓰기
                </span>
                {principal?.id === item?.createdBy?.id && (
                  <>
                    <span className={styles.contour}>|</span>
                    <span className={styles.comment_button} onClick={() => HandleUpdate(item)}>
                      수정
                    </span>
                    <span className={styles.contour}>|</span>
                    <span className={styles.comment_button} onClick={() => deleteComment(item.id)}>
                      삭제
                    </span>
                  </>
                )}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Comment;
