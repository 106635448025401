import { Modal } from 'antd';
import React from 'react';
import PaginateTable from '../components/table/PaginateTable';

import {
  ExtraWrapper,
  FilterButton,
  FilterInput,
  FilterSelect,
  TableCheckbox,
  TableSwitch,
} from '../components/template/page';
import { useGetLikedConstructionGuideList, useGetLikedConstructionGuideTrendList, useGetPosts } from '../apis/queries';
import TableColHeader from '../components/table/TableColHeader';
import Thumbnail from '../components/image/Thumbnail';
import { getTimeAgo, parseImageUri } from '../common/utils';
import moment from 'moment';
import useSearchBar from './useSearchBar';
import produce from 'immer';
import useUpperLine from './useUpperLine';

const useUserConstructionModal = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const showModal = (type) => {
    console.log(type);
    setIsModalOpen(type);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const UserConstructionModal = ({ selectedId, tableHeight }) => {
    const { params: upperParam, UpperLine } = useUpperLine();
    const initialParmas = React.useMemo(
      () => ({
        limit: 50,
        page: 0,
      }),
      [],
    );
    const [params, setParams] = React.useState(initialParmas);
    console.log(upperParam);
    const { data } = useGetLikedConstructionGuideTrendList({
      id: selectedId,
      type: isModalOpen === true ? 'construction-guides' : isModalOpen,
      params: { ...params, ...upperParam },
      config: { enabled: !!selectedId && !!isModalOpen },
    });

    const columns = React.useMemo(
      () => [
        {
          title: '썸네일',
          dataIndex: 'thumbnailId',
          key: 'thumbnailId',
          width: 20,
          align: 'center',
          render: (v) => <Thumbnail id={v} />,
        },
        {
          title: <TableColHeader title="제목" subTitle="공종" />,
          dataIndex: 'title',
          key: 'title',
          width: 40,
          align: 'center',
          render: (v) => v,
        },
        {
          title: '작성자',
          dataIndex: 'createdBy',
          key: 'createdBy',
          width: 80,
          align: 'center',
          render: (v) => (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
              <img
                style={{ width: '36px', height: '36px', objectFit: 'cover', borderRadius: '50%' }}
                src={parseImageUri(v.profileImageId)}
                alt={`profile_${v.profileImageId}`}
              />
              <span
                style={{
                  fontSize: '12px',
                  color: '#767676',
                }}
              >
                {v.nickname}
              </span>
            </div>
          ),
        },
        {
          title: '작성일시',
          dataIndex: 'createdAt',
          key: 'createdAt',
          width: 80,
          align: 'center',
          render: (v) => (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
              <span style={{}}>{getTimeAgo(v)}</span>
              <span style={{}}>{moment(v).format('YYYY-MM-DD HH:mm')}</span>
            </div>
          ),
        },
      ],
      [],
    );
    return (
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={true}
        footer={null}
      >
        <div style={{ padding: '35px 25px 0 25px' }}>
          <p style={{ fontSize: 24, fontWeight: 600 }}>
            저장한 {isModalOpen === true || isModalOpen === 'construction-guides' ? '공사가이드' : '트렌드'}
          </p>
          <ExtraWrapper>
            <div style={{ padding: '20px 0px' }}>
              <UpperLine />
            </div>
          </ExtraWrapper>
          <PaginateTable
            customHeight={true}
            dataSource={data?.content}
            data={data}
            columns={columns}
            page={params.page}
            setPageSize={(v) =>
              setParams(
                produce((draft) => {
                  draft.limit = v;
                }),
              )
            }
            pageSize={params.limit}
            setPage={(v) =>
              setParams(
                produce((draft) => {
                  draft.page = v;
                }),
              )
            }
            scroll={{ y: tableHeight - 200 }}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
            }}
          />
        </div>
      </Modal>
    );
  };
  return { showModal, setIsModalOpen, UserConstructionModal };
};

export default useUserConstructionModal;
