import { useQuery } from 'react-query';
import {
  CONSTRUCTION_CATEGORIES_KEY,
  CONSTRUCTION_TEMPLATES_KEY,
  CONTENTS_KEY,
  CONTENT_DETAIL_KEY,
  POSTS_KEY,
  POST_DETAIL_KEY,
  PRODUCT_CATEGORY_KEY,
  REGIONS_KEY,
  ASK_ME_LIST_KEY,
  ASK_ME_LIST_DETAIL_KEY,
  EXPERT_TALK_LIST_KEY,
  EXPERT_TALK_LIST_DETAIL_KEY,
  MAIN_BANNER_LIST_KEY,
  MAIN_BANNER_LIST_DETAIL_KEY,
  COMMENT_KEY,
  PRODUCT_LIST_KEY,
  PRODUCT_DETAIL_KEY,
  TEMPLATE_DETAIL_KEY,
  ADS_LIST_DETAIL_KEY,
  ADS_LIST_KEY,
  MAIN_SECTION_DETAIL_KEY,
  MAIN_SECTION_KEY,
  PRODUCT_LIST_MATERIAL_KEY,
  POPUP_LIST_KEY,
  POPUP_LIST_DETAIL_KEY,
  SUB_BANNER_LIST_KEY,
  SUB_BANNER_LIST_DETAIL_KEY,
  NOTICE_LIST_KEY,
  NOTICE_DETAIL_KEY,
  CONSTRUCTION_TEMPLATES_ADMIN_KEY,
  USER_LIST_KEY,
  TEMPLATE_DETAIL_KEY_ADMIN,
  CONTENT_GROUP_KEY,
  CONTENT_GROUP_DETAIL_KEY,
  PARTMER_USER_KEY,
  COMMON_CODE_KEY,
  PARTMER_USER_DETAIL_KEY,
  USER_DETAIL_KEY,
  LIKED_CONSTRUCTIONGUIDE_KEY,
  LIKED_STYLE_LIST_KEY,
  LIKED_FOLLOW_LIST_KEY,
  LIKED_CONSTRUCTION_REQUEST_LIST_KEY,
  USER_COMMENT_LIST_KEY,
  PARTMER_PORTFOLIO_KEY,
  PARTMER_POST_KEY,
  PARTMER_PROPOSED_KEY,
  PARTMER_STYLE_KEY,
  PARTMER_TREND_KEY,
  PARTMER_COMMENT_KEY,
  PARTMER_GUIDE_KEY,
  CONSTRUCTION_REQUEST_KEY,
  USER_SAVED_ESTIMATE_KEY,
  PROPOSAL_COST_KEY,
  CONSTRUCTION_REQUEST_DETAIL_KEY,
  CONSTRUCTION_REQUEST_PROPOSED_KEY,
  ADMIN_LIST_KEY,
  ADMIN_LIST_DETAIL_KEY,
  ALL_POST_LIST_DETAIL_KEY,
  APARTMENT_LIST_KEY,
  PROPOSED_KEY,
  CONSULTING_LIST_KEY,
  CONSULTING_LIST_DETAIL_KEY,
  CONSTRUCTION_REQUEST_TABLE_KEY,
} from './queryKeys';
import { restApi } from '.';

//// admin api 사용

// 관리자 회원 목록
export const useGetAccountAdmin = ({ params, config }) => {
  return useQuery(
    [ADMIN_LIST_KEY, params],
    async () => {
      const { data } = await restApi.get(`/accounts/admin`, {params});
      return data;
    },
    {
      ...config,
    },
  );
};
// 관리자 회원 상세
export const useGetAccountAdminDetail = ({ id, params, config }) => {
  return useQuery(
    [ADMIN_LIST_DETAIL_KEY, id, params],
    async () => {
      const { data } = await restApi.get(`/accounts/admin/${id}`);
      return data;
    },
    {
      ...config,
    },
  );
};

// 파트너 회원 목록
export const useGetPartnerUser = ({ params, type, config }) => {
  return useQuery(
    [PARTMER_USER_KEY, type, params],
    async () => {
      const { data } = await restApi.get(`/accounts/partner?accountType=${type}`, { params });
      return data;
    },
    {
      ...config,
      enabled: !!type,
    },
  );
};
// 파트너 회원 상세
export const useGetPartnerUserDetail = ({ params, id, config }) => {
  return useQuery(
    [PARTMER_USER_DETAIL_KEY, id],
    async () => {
      const { data } = await restApi.get(`/accounts/partner/${id}`);
      return data;
    },
    {
      ...config,
    },
  );
};
// 파트너 회원 댓글 목록
export const useGetPartnerComment = ({ params, id, config }) => {
  return useQuery(
    [PARTMER_COMMENT_KEY, id, params],
    async () => {
      const { data } = await restApi.get(`/accounts/partner/${id}/comments`, { params });
      return data;
    },
    {
      ...config,
    },
  );
}; // 파트너 회원 공사가이드 목록
export const useGetPartnerGuide = ({ params, id, config }) => {
  return useQuery(
    [PARTMER_GUIDE_KEY, id, params],
    async () => {
      const { data } = await restApi.get(`/accounts/partner/${id}/construction-guides`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};
// 파트너 회원 포트폴리오 목록
export const useGetPartnerPortfolio = ({ params, id, config }) => {
  return useQuery(
    [PARTMER_PORTFOLIO_KEY, id, params],
    async () => {
      const { data } = await restApi.get(`/accounts/partner/${id}/portfolios`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};
// 파트너 회원 게시글 목록
export const useGetPartnerPost = ({ params, id, config }) => {
  return useQuery(
    [PARTMER_POST_KEY, id, params],
    async () => {
      const { data } = await restApi.get(`/accounts/partner/${id}/posts`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};

export const useGetProposalCost = ({ config }) => {
  return useQuery(
    [PROPOSAL_COST_KEY],
    async () => {
      const { data } = await restApi.get(`/proposal-costs`);
      return data;
    },
    {
      ...config,
    },
  );
};

// 파트너 회원 제안한 공사도움받기 목록
export const useGetPartnerProposed = ({ params, id, config }) => {
  return useQuery(
    [PARTMER_PROPOSED_KEY, id],
    async () => {
      const { data } = await restApi.get(`/accounts/partner/${id}/proposed`);
      return data;
    },
    {
      ...config,
    },
  );
};
// 파트너 회원 스타일 목록
export const useGetPartnerStyle = ({ params, id, config }) => {
  return useQuery(
    [PARTMER_STYLE_KEY, id, params],
    async () => {
      const { data } = await restApi.get(`/accounts/partner/${id}/styles`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};
// 파트너 회원 트렌드 목록
export const useGetPartnerTrend = ({ params, id, config }) => {
  return useQuery(
    [PARTMER_TREND_KEY, id],
    async () => {
      const { data } = await restApi.get(`/accounts/partner/${id}/trends`);
      return data;
    },
    {
      ...config,
    },
  );
};

export const useGetConsultingList = ({ params, config, accountId }) => {
  return useQuery(
    [CONSULTING_LIST_KEY, params],
    async () => {
      const { data } = await restApi.get(`/consultings/${accountId}`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};

//컨설팅 리스트 상세
export const useGetConsultingListDetail = ({ id, params, config }) => {
  return useQuery(
    [CONSULTING_LIST_DETAIL_KEY, id, params],
    async () => {
      const { data } = await restApi.get(`/consultings/detail/${id}`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};

// 스타일, 포트폴리오
export const useGetContents = ({ params, type, config }) => {
  return useQuery([CONTENTS_KEY, type, params], () => restApi.get(`/${type}`, { params }).then(({ data }) => data), {
    ...config,
  });
};

// 스타일, 포트폴리오 상세
export const useGetContentDetail = ({ type, id, config }) => {
  // type = 'styles' or 'portfolios'
  return useQuery([CONTENT_DETAIL_KEY, type, id], () => restApi.get(`/${type}/${id}`).then(({ data }) => data), {
    ...config,
  });
};

// 트렌드, 공사가이드
export const useGetPosts = ({ type, params, config }) => {
  // type = 'trends' or 'construction-guides'
  return useQuery([POSTS_KEY, type, params], () => restApi.get(`/${type}`, { params }).then(({ data }) => data), {
    ...config,
  });
};

// 트렌드, 공사가이드 상세
export const useGetPostDetail = ({ type, id, config }) => {
  return useQuery([POST_DETAIL_KEY, type, id], () => restApi.get(`/${type}/${id}`).then(({ data }) => data), {
    ...config,
  });
};

// 스타일,포트폴리오, 트렌드, 공사가이드 통합검색
export const useGetAllContent = ({ type, params, config }) => {
  // type = 'styles' or 'portfolios' or 'trends' or 'construction-guides'
  return useQuery([POSTS_KEY, type, params], () => restApi.get(`/${type}`, { params }).then(({ data }) => data), {
    ...config,
  });
};
//게시판 - 무엇이든 물어보세요
export const useGetAskMe = ({ params, config }) => {
  return useQuery(
    [ASK_ME_LIST_KEY, params],
    async () => {
      const { data } = await restApi.get(`/posts/ask-me`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};
//게시판 - 무엇이든 물어보세요 상세
export const useGetAskMeDetail = ({ id, params, config }) => {
  return useQuery(
    [ASK_ME_LIST_DETAIL_KEY, params, id],
    async () => {
      const { data } = await restApi.get(`/posts/ask-me/detail/${id}`);
      return data;
    },
    {
      ...config,
    },
  );
};
//게시판 - 전문가들의 수다
export const useGetExpertTalk = ({ params, config }) => {
  return useQuery(
    [EXPERT_TALK_LIST_KEY, params],
    async () => {
      const { data } = await restApi.get(`/posts/expert-talk/EXPERT_TALK`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};
//게시판 - 전문가들의 수다 상세
export const useGetExpertTalkDetail = ({ id, params, config }) => {
  return useQuery(
    [EXPERT_TALK_LIST_DETAIL_KEY, params, id],
    async () => {
      const { data } = await restApi.get(`/posts/expert-talk/detail/${id}?type=EXPERT_TALK`);
      return data;
    },
    {
      ...config,
    },
  );
};
//게시판 - 게시판 통합 상세
export const useGetAllDetail = ({ id, type, params, config }) => {
  const postType = type === 'ASK_ME' ? 'ask-me' : 'expert-talk';
  return useQuery(
    [ALL_POST_LIST_DETAIL_KEY, params, id],
    async () => {
      const { data } = await restApi.get(`/posts/${postType}/detail/${id}?type=EXPERT_TALK`);
      return data;
    },
    {
      ...config,
    },
  );
};
//메인 팝업 관리
export const useGetPopups = ({ params, config }) => {
  return useQuery(
    [POPUP_LIST_KEY, params],
    async () => {
      const { data } = await restApi.get(`/popup`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};
//메인 팝업 상세
export const useGetPopupsDetail = ({ id, params, config }) => {
  return useQuery(
    [POPUP_LIST_DETAIL_KEY, params, id],
    async () => {
      const { data } = await restApi.get(`/popup/${id}`);
      return data;
    },
    {
      ...config,
    },
  );
};
//메인 배너 관리
export const useGetMainBanner = ({ params, config }) => {
  return useQuery(
    [MAIN_BANNER_LIST_KEY, params],
    async () => {
      const { data } = await restApi.get(`/banner/main`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};
//메인 배너 상세
export const useGetMainBannerDetail = ({ id, params, config }) => {
  return useQuery(
    [MAIN_BANNER_LIST_DETAIL_KEY, params, id],
    async () => {
      const { data } = await restApi.get(`/banner/main/${id}`);
      return data;
    },
    {
      ...config,
    },
  );
};
//서브 배너 관리
export const useGetSubBanner = ({ params, config }) => {
  return useQuery(
    [SUB_BANNER_LIST_KEY, params],
    async () => {
      const { data } = await restApi.get(`/banner/sub`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};
//서브 배너 상세
export const useGetSubBannerDetail = ({ id, config }) => {
  return useQuery(
    [SUB_BANNER_LIST_DETAIL_KEY, id],
    async () => {
      const { data } = await restApi.get(`/banner/sub/${id}`);
      return data;
    },
    {
      ...config,
    },
  );
};
//견적 광고 관리
export const useGetAds = ({ params, config }) => {
  return useQuery(
    [ADS_LIST_KEY, params],
    async () => {
      const { data } = await restApi.get(`/estimate-ads`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};
//견적 광고 상세
export const useGetAdsDetail = ({ id, params, config }) => {
  return useQuery(
    [ADS_LIST_DETAIL_KEY, params, id],
    async () => {
      const { data } = await restApi.get(`/estimate-ads/${id}`);
      return data;
    },
    {
      ...config,
    },
  );
};
//메인 섹션 관리
export const useGetMainSection = ({ params, config }) => {
  return useQuery(
    [MAIN_SECTION_KEY, params],
    async () => {
      const { data } = await restApi.get(`/main-sections`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};
//메인 섹션 상세
export const useGetMainSectionDetail = ({ id, params, config }) => {
  return useQuery(
    [MAIN_SECTION_DETAIL_KEY, params, id],
    async () => {
      const { data } = await restApi.get(`/main-sections/${id}`);
      return data;
    },
    {
      ...config,
    },
  );
};
//컨텐츠그룹 관리
export const useGetContentGroup = ({ params, config }) => {
  return useQuery(
    [CONTENT_GROUP_KEY, params],
    async () => {
      const { data } = await restApi.get(`/content-groups`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};
//컨텐츠그룹 상세
export const useGetContentGroupDetail = ({ id, params, config }) => {
  return useQuery(
    [CONTENT_GROUP_DETAIL_KEY, params, id],
    async () => {
      const { data } = await restApi.get(`/content-groups/${id}`);
      return data;
    },
    {
      ...config,
    },
  );
};

// 상품 목록 조회
export const useGetProductList = ({ params, config }) => {
  return useQuery(
    [PRODUCT_LIST_KEY, params],
    () => restApi.get('/products/manage', { params }).then(({ data }) => data),
    { ...config },
  );
};

// 상품 상세
export const useGetProductDetail = ({ id, config }) => {
  return useQuery([PRODUCT_DETAIL_KEY, id], () => restApi.get(`/products/manage/${id}`).then(({ data }) => data), {
    ...config,
  });
};

// 공지사항
export const useGetNotice = ({ params, config }) => {
  return useQuery([NOTICE_LIST_KEY, params], () => restApi.get('/notices', { params }).then(({ data }) => data), {
    ...config,
  });
};

// 공지사항 상세
export const useGetNoticeDetail = ({ id, config }) => {
  return useQuery([NOTICE_DETAIL_KEY, id], () => restApi.get(`/notices/${id}`).then(({ data }) => data), { ...config });
};

// 서비스 가이드
export const useGetGuides = ({ params, config }) => {
  return useQuery([NOTICE_LIST_KEY, params], () => restApi.get('/guides', { params }).then(({ data }) => data), {
    ...config,
  });
};

// 서비스 가이드 상세
export const useGetGuideDetail = ({ id, config }) => {
  return useQuery([NOTICE_DETAIL_KEY, id], () => restApi.get(`/guides/${id}`).then(({ data }) => data), { ...config });
};

// 일반회원 목록 조회
export const useGetUserList = ({ params, config }) => {
  return useQuery([USER_LIST_KEY, params], () => restApi.get('/accounts/user', { params }).then(({ data }) => data), {
    ...config,
  });
};

// 일반회원 상세 조회
export const useGetUserDetail = ({ id, config }) => {
  return useQuery([USER_DETAIL_KEY, id], () => restApi.get(`/accounts/user/${id}`).then(({ data }) => data), {
    ...config,
  });
};

// 좋아요한 공사가이드 조회
export const useGetLikedConstructionGuideTrendList = ({ id, type, params, config }) => {
  return useQuery(
    [LIKED_CONSTRUCTIONGUIDE_KEY, params, id],
    () => restApi.get(`/accounts/user/${id}/${type}`, { params }).then(({ data }) => data),
    { ...config },
  );
};

// 좋아요한 스타일 조회
export const useGetLikedStyleList = ({ id, params, type, accountType, config }) => {
  return useQuery(
    [LIKED_STYLE_LIST_KEY, id, params, type],
    () => restApi.get(`/accounts/user/${id}/${type}?accountType=${accountType}`, { params }).then(({ data }) => data),
    { ...config },
  );
};

// 좋아요한 사람들 리스트 조회 - 테이블 상세
export const useLikeList = ({ id, params, config }) => {
  return useQuery(
    ['LIKE_LIST_KEY', id, params],
    () => restApi.get(`/contents/liked/${id}`, { params }).then(({ data }) => data),
    { ...config },
  );
};

// 팔로우한 시공사 조회
export const useGetLikedFollowsList = ({ id, params, config }) => {
  return useQuery(
    [LIKED_FOLLOW_LIST_KEY, id, params],
    () => restApi.get(`/accounts/user/${id}/follows`, { params }).then(({ data }) => data),
    { ...config },
  );
};

// 저장한 공사 도움받기
export const useGetLikedConstructionRequestList = ({ id, params, config }) => {
  return useQuery(
    [LIKED_CONSTRUCTION_REQUEST_LIST_KEY, id, params],
    () => restApi.get(`/accounts/user/${id}/construction-request`, { params }).then(({ data }) => data),
    { ...config },
  );
};

// 유저 작성한 댓글 리스트
export const useGetUserCommentList = ({ id, params, config }) => {
  return useQuery(
    [USER_COMMENT_LIST_KEY, id, params],
    () => restApi.get(`/accounts/user/${id}/comments`, { params }).then(({ data }) => data),
    { ...config },
  );
};

// 공사도움받기 목록
export const useGetConstructionRequest = ({ params, config }) => {
  return useQuery(
    [CONSTRUCTION_REQUEST_TABLE_KEY, params],
    async () => {
      const { data } = await restApi.get(`/construction-request`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};

// 공사도움받기 목록 - 테이블 상세
export const useGetConstructionRequestTable = ({ id, params, config }) => {
  return useQuery(
    [CONSTRUCTION_REQUEST_KEY, params],
    async () => {
      const { data } = await restApi.get(`/contents/construction-requests/${id}`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};

export const useGetProposedList = ({ params, id, config, type }) => {
  return useQuery(
    [PROPOSED_KEY, params],
    async () => {
      const { data } = await restApi.get(`/construction-request/proposed/${id}?type=${type}`, { params });
      return data;
    },
    { ...config },
  );
};

export const useGetProposedListByConstructionId = ({ params,id, config }) => {
  return useQuery(
      [PROPOSED_KEY, params],
      async () => {
        const { data } = await restApi.get(`/construction-request/proposed/list/${id}`, { params });
        return data;
      },
      { ...config },
  );
};



export const useGetConstructionRequestDetail = ({ id, params, config }) => {
  return useQuery(
    [CONSTRUCTION_REQUEST_KEY, params],
    async () => {
      const { data } = await restApi.get(`/construction-request/${id}`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};

export const useGetConstructionRequestProposed = ({ id, params, config }) => {
  return useQuery(
    [CONSTRUCTION_REQUEST_KEY, params],
    async () => {
      const { data } = await restApi.get(`/construction-request/${id}/proposed`, { params });
      return data;
    },
    {
      ...config,
    },
  );
};
// 회원 저장한 견적
export const useGetSavedEstimate = ({ id, params, config }) => {
  return useQuery(
    [USER_SAVED_ESTIMATE_KEY, id, params],
    () => restApi.get(`/accounts/user/${id}/estimate`, { params }).then(({ data }) => data),
    { ...config },
  );
};
// 견적 - 테이블 상세
export const useGetSavedEstimateTable = ({ id, params, config }) => {
  return useQuery(
    ['TABLE_ESTIMATE_KEY', id, params],
    () => restApi.get(`/contents/estimates/${id}`, { params }).then(({ data }) => data),
    { ...config },
  );
};

export const useGetSavedPosts = ({ id, params, config }) => {
  return useQuery(
    ['saved_post', id, params],
    () => restApi.get(`/accounts/user/${id}/posts`, { params }).then(({ data }) => data),
    { ...config },
  );
};

export const useGetSavedEstimateDetail = ({ id, selectedId, params, config }) => {
  return useQuery(
    ['sde', id, params],
    () => restApi.get(`/accounts/user/${id}/estimate/${selectedId}`, { params }).then(({ data }) => data),
    { ...config },
  );
};
//// 공통 api

// 지역설정 관련 api
export const useGetRegions = (config) => {
  return useQuery(REGIONS_KEY, () => restApi.get('/regions').then(({ data }) => data), { ...config });
};

// 공사 카테고리 관련 api
export const useGetConstructionCategories = (withTemplate = false, config) => {
  return useQuery(
    [CONSTRUCTION_CATEGORIES_KEY, withTemplate],
    () =>
      restApi
        .get('/construction-categories', {
          params: {
            withTemplate,
          },
        })
        .then(({ data }) => data),
    {
      ...config,
    },
  );
};

//공통코드 받아오기
export const useGetCommonCode = ({ config }) => {
  return useQuery(
    [COMMON_CODE_KEY],
    async () => {
      const { data } = await restApi.get(`/codes/map`);
      return data;
    },
    {
      ...config,
    },
  );
};

// 템플릿 목록 - 관리자 전용
export const useGetConstructionTemplatesAdmin = ({ params, config }) => {
  return useQuery(
    [CONSTRUCTION_TEMPLATES_ADMIN_KEY, params],
    () => restApi.get('/templates', { params }).then(({ data }) => data),
    { ...config },
  );
};

// 템플릿 상세 - 관리자 수정용
export const useGetTemplateDetailAdmin = ({ id, config }) => {
  return useQuery([TEMPLATE_DETAIL_KEY_ADMIN, id], () => restApi.get(`/templates/${id}`).then(({ data }) => data), {
    ...config,
  });
};

// 공종 카테고리별 템플릿 목록
export const useGetConstructionTemplates = (config) => {
  return useQuery(
    CONSTRUCTION_TEMPLATES_KEY,
    () => restApi.get('/templates/common/displays/full').then(({ data }) => data),
    { ...config },
  );
};
// 템플릿 상세 정보
export const useGetTemplateDetail = ({ id, config }) => {
  return useQuery([TEMPLATE_DETAIL_KEY, id], () => restApi.get(`/templates/common/${id}`).then(({ data }) => data), {
    ...config,
  });
};

// 상품 카테고리
export const useGetProductCategories = (mode, config) => {
  return useQuery([PRODUCT_CATEGORY_KEY], () => restApi.get('/products/category').then(({ data }) => data), {
    ...config,
  });
};

//댓글 api
export const useGetComment = ({ id, type, params, config }) => {
  return useQuery(
    [COMMENT_KEY, id, type],
    async () => {
      const { data } = await restApi.get(`/comments/${id}?type=${type}`, params);
      return data;
    },
    { ...config },
  );
};

//
export const useGetEstimateCostTemplate = (config) => {
  return useQuery('ESTIMATE_COST_TEMPLATE_KEY', () => restApi.get('/estimate-cost-settings').then(({ data }) => data), {
    ...config,
  });
};
export const useGetEstimateCostNote = (config) => {
  return useQuery(
    'ESTIMATE_COST_TEMPLATE_NOTE_KEY',
    () => restApi.get('/estimate-cost-settings/note').then(({ data }) => data),
    { ...config },
  );
};

// 아파트 정보
export const useGetApartmentList = ({ params, config }) => {
  return useQuery([APARTMENT_LIST_KEY, params], () => restApi.get('/apartments', { params }).then(({ data }) => data), {
    ...config,
  });
};

//////////파트너스 긁어오기

//전부 받아오기
export const useGetPostsAll = ({ id, type, params, config }) => {
  // type = 'trends' or 'construction-guides'
  return useQuery(
    ['POSTS_ALL_KEY', id, type, params],
    () => restApi.get(`/${type}/${id}`, { params }).then(({ data }) => data),
    {
      ...config,
    },
  );
};

// 게시글 상세
export const useGetMyPostDetail = ({ id, params, config }) => {
  return useQuery(
    ['MY_POST_DETAIL_KEY', id, params],
    async () => {
      const { data } = await restApi.get(`/posts/detail/${id}`, { params });
      return data;
    },
    { ...config },
  );
};
