import { HeartFilled } from '@ant-design/icons';
import { PageHeader, Button, DatePicker, Input, Select, Space, Switch, Checkbox, Modal } from 'antd';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import qs from 'query-string';
import styled, { css } from 'styled-components';
import { restApi } from '../../../apis';
import CommentIcon from '../../../components/image/CommentIcon';
import PaginateTable from '../../../components/table/PaginateTable';
import { proposalDummy } from '../../../data/dummy';
import {
  formatComma,
  getClientWidth,
  getRemainTime,
  getTimeAgo,
  handleModifyWriteAccount,
  handleRequest,
  handleRequestShowAndStop,
  parseImageUri,
} from '../../../common/utils';
import CurrentBid from '../../../components/card/CurrentBid/CurrentBid';
import SmallUserDetail from '../../../components/card/SmallUserDetail/SmallUserDetail';
import LocationInfo from '../../../components/card/LocationInfo/LocationInfo';
import SmallEstimate from '../../../components/card/SmallEstimate/SmallEstimate';
import useRenderSwitch from '../../../components/useRenderSwitch';
import {
  ExtraWrapper,
  FilterButton,
  FilterInput,
  FilterSelect,
  FilterWhiteButton,
  TableCheckbox,
  TableSwitch,
} from '../../../components/template/page';
import ContentSlider from '../../../components/card/ContentSlider/ContentSlider';
import useSearchBar from '../../../hooks/useSearchBar';
import {
  useGetConstructionRequest,
  useGetConstructionRequestDetail,
  useGetConstructionRequestProposed,
  useGetConstructionRequestTable,
} from '../../../apis/queries';
import Thumbnail from '../../../components/image/Thumbnail';
import TableColHeader from '../../../components/table/TableColHeader';
import { useQueryClient } from 'react-query';
import styles from '../../collections/magazines/magazines.module.less';
import useUpperLine from '../../../hooks/useUpperLine';
import { CONSTRUCTION_REQUEST_KEY } from '../../../apis/queryKeys';
import LowerLine from '../../../components/LowerLine';
import ModifyAllButton from '../../../components/button/ModifyAllButton';
import { Option } from 'antd/lib/mentions';
import ContentDetailModal from '../../../components/modal/ContentDetailModal';
import produce from 'immer';

const { RangePicker } = DatePicker;
const CustomRangePicker = styled(RangePicker)`
  width: 250px;
  border: none;
  border-bottom: 1px solid rgb(185, 185, 185);
  border-radius: 0px;
  margin-top: -3px;
  background-color: inherit;
  text-align: left;
  font-size: 14px;
  font-weight: 500 !important;
  color: #000;
  box-shadow: none !important;

  ${({ title }) => {
    return (
      title &&
      css`
        &::before {
          border-bottom: none;
          content: '${title}';
          white-space: nowrap;
          margin-right: 2.5px;
          font-size: 12px;
          color: #767676;
          margin-right: 10px;
        }
      `
    );
  }};
`;

const ContentCard = React.memo(({ item, commonCodes, setVisibleDetailModal }) => {
  return (
    <div
      onClick={() => {
        setVisibleDetailModal(true);
      }}
      className={styles.slider_div}
      style={{
        cursor: 'pointer',
        backgroundImage: `url(${parseImageUri(item?.thumbnailId)})`,
      }}
    >
      <div className={styles.backdrop} />
      <div className={styles.slider_inner_div}>
        <p className={styles.category_p}>{commonCodes?.map[item?.area]}</p>
        <p className={styles.title_p}>{item?.description}</p>
        <div className={styles.flex_div}>
          <div className={styles.flex_div} style={{ alignItems: 'center' }}>
            <Thumbnail
              containerStyle={{
                width: 'max-content',
                display: 'flex',
                alignItems: 'center',
              }}
              className={styles.style_profile_img}
              id={item?.createdBy?.profileImageId}
            />
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <p className={styles.category_p} style={{ marginLeft: 10 }}>
                {item?.createdBy?.nickname}
              </p>
            </div>
          </div>
          <div className={styles.flex_div}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <HeartFilled style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p}>{item?.likeCount}</p>
            </div>
            <div
              style={{
                marginLeft: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CommentIcon style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p}>{item?.commentCount ?? 0}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
const ProposalDetail = () => {
  const param = useParams();
  //필터 윗줄 생성(keword, filter params 포함, query param 에 spread 구문으로 삽입)
  const filters = React.useMemo(() => {
    return [
      {
        title: '상태',
        key: 'status',
        options: [
          undefined,
          ['RUNNING', '진행중'],
          ['PAUSE', '정지'],
          ['NEW', '신규'],
          ['PROPOSING', '제안중'],
          ['FINISH', '완료'],
        ],
      },
      { title: '진행', key: 'isRunning', options: [undefined, [true, '진행'], [false, '중지']] },
    ];
  }, []);
  const { params: upperParam, UpperLine } = useUpperLine(filters);

  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: 'DESC',
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  React.useEffect(() => {
    if (upperParam) {
      setParams((prev) => ({ ...prev, ...upperParam }));
    }
  }, [upperParam]);
  const [selectedId, setSelectedId] = React.useState(undefined);

  // 모두 선택 버튼로직
  const [checkedList, setCheckedList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const { data: requestData, refetch: refetchList } = useGetConstructionRequestTable({
    id: param?.id,
    params,
  });
  const { data: detail, refetch } = useGetConstructionRequestDetail({
    id: selectedId,
    config: { enabled: !!selectedId },
  });
  const { data: proposedData } = useGetConstructionRequestProposed({
    id: selectedId,
    config: { enabled: !!selectedId },
  });
  const allData = React.useMemo(() => {
    return requestData?.content?.map((v) => ({
      data: v,
      id: v?.id,
    }));
  }, [requestData]);
  React.useEffect(() => {
    if (!!allData) {
      const idList_ = allData?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [allData]);
  React.useEffect(() => {
    if (selectedId) refetch();
  }, [selectedId]);

  //상세 화면
  const [refreshDetail, setRefreshDetail] = React.useState();
  React.useEffect(() => {
    if (!refreshDetail) {
      setRefreshDetail(requestData?.content[0]?.id);
    }
  }, [requestData]);
  const [visibleDetailModal, setVisibleDetailModal] = React.useState(false);

  // 테이블 페이지네이션
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 380);
  const { commonCodes } = useSelector((s) => s.common);
  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 360);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  //테이블 col
  const COLUMNS = [
    {
      title: <TableColHeader title={'상태'} subTitle={'선택'} />,
      dataIndex: 'data',
      key: 'data',
      width: 60,
      align: 'center',
      render: (data, record) => {
        return (
          <>
            <p
              style={{
                width: 40,
                backgroundColor: !record.finishedAt ? '#a2ce76' : '#1e3d83',
                color: 'white',
                fontWeight: 'bold',
                fontSize: 10,
                padding: '2px 0 1px',
                borderRadius: 4,
                margin: '0 auto',
              }}
            >
              {!record.finishedAt ? '진행중' : '종료'}
            </p>
            {/*<Checkbox*/}
            {/*  key={`${record.id}_check`}*/}
            {/*  checked={checkedList.includes(record.id)}*/}
            {/*  onClick={() => {*/}
            {/*    if (checkedList.includes(record.id)) {*/}
            {/*      setCheckedList(checkedList.filter((v) => v !== record.id));*/}
            {/*    } else {*/}
            {/*      setCheckedList([...checkedList, record.id]);*/}
            {/*    }*/}
            {/*  }}*/}
            {/*/>*/}
          </>
        );
      },
    },
    {
      title: '고객명',
      key: 'customerNickname',
      dataIndex: 'customerNickname',
      sorter: true,
      showSorterTooltip: false,
      width: 120,
      align: 'center',

      children: [
        {
          align: 'center',
          title: '연락처',
          sorter: true,
          width: 120,
          showSorterTooltip: false,
          key: 'customerPhone',
          dataIndex: 'customerPhone',
          render: (data, r) => (
            <>
              <div style={{ fontWeight: 'bold' }}>{r.customerNickname}</div>
              <div>{data}</div>
            </>
          ),
          // align: 'center',
          // width: 120,
        },
      ],
    },
    {
      title: '현장명',
      key: 'title',
      dataIndex: 'title',
      align: 'center',
      width: 200,
      sorter: true,
      showSorterTooltip: false,
      children: [
        {
          title: '주소',
          key: 'address',
          dataIndex: 'address',
          sorter: true,
          showSorterTooltip: false,
          align: 'center',
          width: 200,
          render: (data, record) => (
            <>
              <div style={{ fontWeight: 'bold' }}>{record?.title}</div>
              <div>{data}</div>
              {/*<TableColHeader title={data} subTitle={record.address} />*/}
            </>
          ),
        },
      ],
    },
    {
      title: '분양',
      key: 'exclusiveArea',
      dataIndex: 'exclusiveArea',
      align: 'center',
      width: 60,
      sorter: true,
      showSorterTooltip: false,
      children: [
        {
          title: '전용',
          key: 'supplyArea',
          dataIndex: 'supplyArea',
          sorter: true,
          width: 60,
          showSorterTooltip: false,
          align: 'center',
          render: (data, record) => (
            <>
              <div>{record?.exclusiveArea}</div>
              <div>{data}</div>
              {/*<TableColHeader title={data} subTitle={record.address} />*/}
            </>
          ),
        },
      ],
    },
    {
      title: '공사도움받기',
      children: [
        {
          title: '시작',
          key: 'createdAt',
          dataIndex: 'createdAt',
          sorter: true,
          showSorterTooltip: false,
          align: 'center',
          width: 100,
          render: (data, record) => (
            <>
              <p style={{ color: '#767676', fontSize: 12 }}>{getTimeAgo(data)}</p>
              <p style={{ color: '#767676', fontSize: 12 }}>{moment(data).format('YYYY-MM-DD HH:mm')}</p>
            </>
          ),
        },
        {
          title: '마감예정',
          key: 'createdAt',
          dataIndex: 'createdAt',
          sorter: true,
          showSorterTooltip: false,
          align: 'center',
          width: 100,
          render: (data, record) => (
            <>
              <p style={{ color: '#767676', fontSize: 12 }}>{getTimeAgo(moment(data).add(7, 'days').valueOf())}</p>
              <p style={{ color: '#767676', fontSize: 12 }}>{moment(data).add(7, 'days').format('YYYY-MM-DD HH:mm')}</p>
            </>
          ),
        },
      ],
    },
    {
      title: '찜',
      key: 'selectedProposalCount',
      dataIndex: 'selectedProposalCount',
      align: 'center',
      width: 60,
      sorter: true,
      showSorterTooltip: false,
      children: [
        {
          title: '전체',
          key: 'proposalCount',
          dataIndex: 'proposalCount',
          sorter: true,
          showSorterTooltip: false,
          align: 'center',
          width: 60,
          render: (data, record) => (
            <div style={{ background: 'transparent' }}>
              <button style={{ textDecoration: 'underline', background: 'transparent' }}>
                {record?.selectedProposalCount}
              </button>
              /<button style={{ textDecoration: 'underline', background: 'transparent' }}>{data}</button>
              {/*<TableColHeader title={data} subTitle={record.address} />*/}
            </div>
          ),
        },
      ],
    },
    {
      title: '고객견적',
      key: 'totalPrice',
      dataIndex: 'totalPrice',
      sorter: true,
      showSorterTooltip: false,
      align: 'center',
      width: 105,
      render: (data, record) => (
        <>
          <p style={{ color: '#1e3d83', fontSize: 14, fontWeight: 'bold', background: 'transparent' }}>
            {data ? formatComma(data) : 0}원
          </p>
        </>
      ),
    },
    {
      title: '공사예정일',
      key: 'constructionDate',
      dataIndex: 'constructionDate',
      sorter: true,
      showSorterTooltip: false,
      align: 'center',
      width: 90,
      render: (data, record) => {
        return (
          <>
            <div>{data && getTimeAgo(data)}</div>
            <span
              style={{
                fontSize: 12,
                fontWeight: 'bold',
                color: '#767676',
              }}
            >
              {data?.toLocaleString()}
            </span>
          </>
        );
      },
    },
    {
      title: <TableColHeader title={'진행구분'} subTitle={'진행/중지'} />,
      key: 'finishedAt',
      sorter: true,
      showSorterTooltip: false,
      dataIndex: 'finishedAt',
      align: 'center',
      width: 80,
      render: (data, record) => {
        return (
          <Switch
            disabled={!!record?.finishedAt}
            checked={!record?.finishedAt && data}
            onClick={async (e) => {
              try {
                await restApi.put(`/construction-request/is-running`, { ids: [record.id], isActive: !data });
                refetchList();
              } catch (e) {
                alert(e);
              }
              // handleModifyWriteAccount(`/accounts/partner/limit/create-proposals`, record.id, data, async () => {
              //   await queryClient.invalidateQueries([PARTMER_USER_KEY]);
              //   await queryClient.invalidateQueries([PARTMER_USER_DETAIL_KEY]);
              // });
            }}
          />
        );
      },
    },

    {
      title: '완료처리',
      key: 'finishedAt',
      dataIndex: 'finishedAt',
      align: 'right',
      width: 120,
      render: (data, record) => {
        return (
          <div>
            <FilterWhiteButton
              className={styles.completeButton}
              // style={{ width: 80 }}
              disabled={!!data}
              onClick={async () => {
                // if (!window.confirm('완료처리 하시겠습니까?')) return;
                Modal.confirm({
                  title: '완료',
                  content: '완료하시겠습니까?',
                  okText: '완료하기',
                  cancelText: '취소하기',
                  async onOk() {
                    try {
                      await restApi.put(`/construction-request/finish`, { ids: [record.id], isActive: !data });
                      Modal.confirm({
                        title: '완료',
                        content: '완료되었습니다',
                        onOk() {
                          refetchList();
                        },
                        cancelButtonProps: {
                          style: { display: 'none' },
                        },
                        okText: '확인',
                      });
                    } catch (error) {
                      Modal.warning({
                        title: '실패했습니다',
                        content: error.response.data.message,
                      });
                    }
                  },
                });
                // handleModifyWriteAccount(`/accounts/partner/limit/is-active`, record.id, data, async () => {
                //   await queryClient.invalidateQueries([PARTMER_USER_KEY]);
                //   await queryClient.invalidateQueries([PARTMER_USER_DETAIL_KEY]);
                // });
              }}
            >
              완료처리
            </FilterWhiteButton>
          </div>
        );
      },
    },
  ];
  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setSelectedId(record?.id);
      },
    };
  };

  return (
    <div>
      <div>
        <PageHeader className="site-page-header" title="공사도움받기" style={{ paddingBottom: 10 }} />
        <ExtraWrapper>
          {/* 필터 윗줄 */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 4.5,
            }}
          >
            <UpperLine
              listKey={CONSTRUCTION_REQUEST_KEY}
              detailKey={CONSTRUCTION_REQUEST_KEY}
              setRefresh={setRefreshDetail}
            >
              <CustomRangePicker
                title={'공사예정일'}
                activeBorderColor={'transparent'}
                style={{ padding: 0, marginLeft: 10 }}
                value={[]}
                onChange={(v) => {
                  if (!!v) {
                    const [startDate, endDate] = v;
                    // setForm(
                    //     produce((draft) => {
                    //       draft.startDate = startDate;
                    //       draft.endDate = endDate;
                    //     }),
                    // );
                  } else {
                    // setForm(
                    //     produce((draft) => {
                    //       draft.startDate = null;
                    //       draft.endDate = null;
                    //     }),
                    // );
                  }
                }}
                placeholder={['시작일', '종료일']}
              />
            </UpperLine>
          </div>
          {/* 필터 아랫줄 */}
          <div style={{ width: 870, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}></div>
        </ExtraWrapper>
      </div>

      <div class={styles.estimate_table} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PaginateTable
          dataSource={requestData?.content}
          columns={COLUMNS}
          scroll={{ y: tableHeight - 40 }}
          data={requestData}
          page={params?.page}
          setPage={(v) =>
            setParams(
              produce((draft) => {
                draft.page = v;
              }),
            )
          }
          pageSize={params?.limit}
          setPageSize={(v) =>
            setParams(
              produce((draft) => {
                draft.limit = v;
              }),
            )
          }
          setParams={setParams}
          onRow={onRow}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '65%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
        />
        {detail && (
          <div
            style={{
              maxWidth: 550,
              overflowY: 'scroll',
              overflowX: 'hidden',
              height: document.body.clientHeight - 240,
              paddingLeft: 20,
              boxSizing: 'border-box',
              paddingBottom: 15,
            }}
          >
            <CurrentBid
              data={detail}
              isFirst={true}
              detailId={selectedId}
              onStop={async () => {
                Modal.confirm({
                  title: '완료',
                  content: '완료하시겠습니까?',
                  okText: '완료하기',
                  cancelText: '취소하기',
                  async onOk() {
                    try {
                      await restApi.put(`/construction-request/finish`, { ids: [selectedId], isActive: false });
                      Modal.confirm({
                        title: '완료',
                        content: '완료되었습니다',
                        onOk() {
                          refetchList();
                        },
                        cancelButtonProps: {
                          style: { display: 'none' },
                        },
                        okText: '확인',
                      });
                    } catch (error) {
                      Modal.warning({
                        title: '실패했습니다',
                        content: '실패했습니다',
                      });
                    }
                  },
                });
              }}
            />
            <SmallUserDetail data={detail} tree={commonCodes} user={detail?.estimateInfo?.createdBy} />
            <LocationInfo data={detail?.estimateInfo} />
            <div style={{ marginTop: 30 }}></div>
            <ContentSlider leftLine={true}>
              {detail?.estimateInfo?.areas?.map((v) => (
                <ContentCard setVisibleDetailModal={() => {}} item={v?.subContent} commonCodes={commonCodes} />
              ))}
            </ContentSlider>
            <SmallEstimate data={detail?.estimateInfo} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProposalDetail;
