import { Checkbox, Col, Modal, PageHeader, Row, Radio, DatePicker, Space, Table, Select, Image, message } from 'antd';
import React from 'react';
import PaginateTable from '../../../components/table/PaginateTable';

import { useLocation, useNavigate } from 'react-router-dom';

import { SyncOutlined } from '@ant-design/icons';
import produce from 'immer';
import { useGetContentGroup, useGetContentGroupDetail, useGetMainSectionDetail } from '../../../apis/queries';
import { contentTypeOBJ, getFileId, parseImageUri } from '../../../common/utils';
import TableColHeader from '../../../components/table/TableColHeader';
import { restApi } from '../../../apis';
import useBlockRefresh from '../../../hooks/useBlockRefresh';
import deleteIcon from '../../../assets/delete.png';
import { QueryClient, useQueryClient } from 'react-query';
import { CONTENT_GROUP_KEY } from '../../../apis/queryKeys';
import useBlockBack from '../../../hooks/useBlockBack';

const OperationMainSectionComposeDetail = () => {
  useBlockBack();
  useBlockRefresh();
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: null,
      sort: null,
    }),
    [],
  );
  const [page, setPage] = React.useState(initialParams.page);
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 60 - 280);
  const { data: mainSectionDetailData, isFetchedAfterMount } = useGetMainSectionDetail({
    id: location?.state?.detailId,
    params: {},
  });
  const [contentId, setContentId] = React.useState(
     undefined,
  );

  const { data: contentGroupData } = useGetContentGroup({
    params: {},
  });

  const { data: contentGroupDetailData } = useGetContentGroupDetail({
    id: contentId,
  });

  const [detailModify, setDetailModify] = React.useState({
  });


  React.useEffect(()=>{
    setDetailModify(
      produce((draft) => {
        draft.title = mainSectionDetailData?.mainSectionItems[location?.state?.index]?.title ?? '';
        draft.sortNum = mainSectionDetailData?.mainSectionItems[location?.state?.index]?.sortNum ?? '';
        draft.imageId = mainSectionDetailData?.mainSectionItems[location?.state?.index]?.imageId ?? '';
        draft.contentGroupId = mainSectionDetailData?.mainSectionItems[location?.state?.index]?.contentGroupId ?? '';
      }),

    );
    setContentId(mainSectionDetailData?.mainSectionItems[location?.state?.index]?.contentGroupId ?? '');
  },[mainSectionDetailData])

  const [modalContent, setModalContent] = React.useState([
    {
      title: '타일',
      order: 1,
    },
  ]);
  React.useEffect(() => {
    console.log(modalContent);
  }, [modalContent]);
  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    if (imageId) {
      setDetailModify(
        produce((draft) => {
          draft.imageId = imageId;
        }),
      );
    }
  };

  const onRow = (record) => {
    return {
      onClick: (event) => {
        navigate('/operation/main_section/section/detail/search', {
          state: {
            detailId: location?.state?.detailId,
            contentGroupId: record.id,
            index: location?.state?.index,
          },
        });
      },
    };
  };
  return (
    <div>
      <PageHeader className="site-page-header" title="메인 섹션 > 항목구성 > 항목세부" style={{ paddingBottom: 10 }} />
      <div style={{ width: '65%', marginRight: 'auto', marginLeft: 'auto', textAlign: 'right' }}>
        <button
          style={{
            padding: '5px 20px',
            backgroundColor: '#1e3d83',
            color: 'white',
            borderRadius: '10px',
          }}
          onClick={() => {
            navigate('/operation/main_section/section/detail/search', {
              state: {
                detailId: location?.state?.detailId,
                // contentGroupId: location?.state?.contentGroupId,
                index: location?.state?.index,
              },
            });
          }}
        >
          리스트 생성
        </button>
      </div>
      <div style={{ height: 60 }}></div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            width: '32%',
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: document.body.clientHeight - 240,
            marginRight: 40,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              backgroundColor: 'white',
              height: 900,
            }}
          >
            <div
              style={{
                width: 400,
                height: 800 + modalContent.length * 76,
                backgroundColor: 'white',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 40,
                padding: 20,
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <input
                  type="text"
                  style={{ width: '85%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '30px 0' }}
                  placeholder="제목을 입력해주세요."
                  value={detailModify?.title}
                  onChange={(e) => {
                    setDetailModify(
                      produce((draft) => {
                        draft.title = e.target.value;
                      }),
                    );
                  }}
                />
                <input
                  type="text"
                  style={{ width: '10%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '30px 0' }}
                  placeholder="순서"
                  value={detailModify?.sortNum}
                  onChange={(e) => {
                    setDetailModify(
                      produce((draft) => {
                        draft.sortNum = e.target.value;
                      }),
                    );
                  }}
                />
              </div>
              <div>
                <input
                  type="text"
                  disabled
                  placeholder="리스트 ID"
                  value={contentGroupDetailData?.title}
                  style={{ width: '100%', border: '1px solid rgba(0,0,0,0.08)', marginBottom: 20, color: '#a9a9a9' }}
                />
              </div>
              <p>대표 이미지</p>
              <div style={{ position: 'relative' }}>
                <button
                  style={{
                    width: 50,
                    height: 50,
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    padding: 10,
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    borderRadius: '50%',
                  }}
                >
                  <input
                    type="file"
                    id="fileInput"
                    style={{
                      width: '100%',
                      height: '100%',
                      opacity: 0,
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      cursor: 'pointer',
                    }}
                    onChange={handleChangeImage}
                  />
                  <SyncOutlined style={{ fontSize: 30, color: '#1e3d83' }} />
                </button>
                <img
                  src={parseImageUri(detailModify?.imageId)}
                  alt=""
                  style={{ width: 360, height: 360, backgroundColor: '#d9d9d9', borderRadius: 10 }}
                />
              </div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <button
                style={{ marginBottom: 20, padding: '3px 18px', borderRadius: '20px', border: '1.5px solid #1e3d83' }}
                onClick={async () => {
                  const customData = [...mainSectionDetailData?.mainSectionItems];
                  if (location?.state?.index>-1) {
                    customData.splice(location?.state?.index, 1, detailModify);
                  } else {
                    customData.push(detailModify);
                  }
                  try {
                    await restApi.put(`/main-sections/${location?.state?.detailId}/items`, {
                      mainSectionItems: customData,
                    });
                    Modal.confirm({
                      title: '완료',
                      content: '완료되었습니다',
                      onOk() {
                        navigate('/operation/main_section/section', {
                          state: {
                            id: location?.state?.detailId,
                            contentGroupId: location?.state?.contentGroupId,
                            index: location?.state?.index,
                          },
                        });
                      },
                      cancelButtonProps: {
                        style: { display: 'none' },
                      },
                      okText: '확인',
                    });
                  } catch (error) {
                    Modal.warning({
                      title: '오류',
                      content: '실패했습니다',
                    });
                  }
                }}
              >
                저장
              </button>
            </div>
          </div>
        </div>
        <PaginateTable
          dataSource={contentGroupData?.content}
          scroll={{ y: tableHeight - 40 }}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '32%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
          page={page}
          setPage={setPage}
          onRow={onRow}
          columns={[
            {
              title: '리스트 연결',
              dataIndex: 'title',
              key: 'title',
              width: 100,
              align: 'center',
              render: (data, record) => {
                return (
                  <div style={{ position: 'relative' }}>
                    <button
                      style={{ backgroundColor: '#1e3d83', borderRadius: 5, color: 'white', padding: '2px 5px 1px' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setDetailModify(
                          produce((draft) => {
                            draft.contentGroupId = record.id;
                          }),
                        );
                        setContentId(record.id);
                      }}
                    >
                      연결하기
                    </button>
                  </div>
                );
              },
            },
            {
              title: <TableColHeader title={'제목'} subTitle={'컨텐츠 타입'} />,
              dataIndex: 'title',
              key: 'title',
              width: 100,
              align: 'center',
              render: (data, record) => {
                return (
                  <div style={{ position: 'relative' }}>
                    <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{record.title}</p>
                    <p style={{ fontSize: 10, color: '#999' }}>{contentTypeOBJ[record.contentType]}</p>
                  </div>
                );
              },
            },
            {
              title: '설명',
              dataIndex: 'description',
              key: 'description',
              width: 150,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{data}</p>
                  </div>
                );
              },
            },
            {
              title: '삭제',
              dataIndex: 'delete',
              key: 'delete',
              width: 50,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <button
                      style={{ width: 30, backgroundColor: 'rgba(0,0,0,0)' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        Modal.confirm({
                          content: '삭제하시겠니까?',
                          okText: '확인',
                          cancelText: '취소',
                          onOk: async () => {
                            try {
                              await restApi.delete('/content-groups', { data: { ids: [record.id] } });
                              Modal.success({
                                title: '성공',
                                content: '성공적으로 삭제되었습니다',
                                onOk: () => {
                                  queryClient.invalidateQueries([CONTENT_GROUP_KEY]);
                                },
                              });
                            } catch (error) {
                              Modal.warning({
                                title: '오류',
                                content: '실패했습니다',
                              });
                            }
                          },
                        });
                      }}
                    >
                      <img style={{ width: '100%' }} src={deleteIcon} alt="" />
                    </button>
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default OperationMainSectionComposeDetail;
