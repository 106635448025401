import { Checkbox, Col, Modal, PageHeader, Row, Radio, DatePicker, Space, Table, Select, Image, Switch } from 'antd';
import React from 'react';
import PaginateTable from '../../../components/table/PaginateTable';
import UserDetail from '../../../components/card/userdetail/UserDetail';
import qs from 'query-string';
import { OPERATION_MAIN_POPUP_LIST } from '../../../data/dummy/operation';
import {
  ExtraWrapper,
  FilterButton,
  FilterInput,
  FilterSelect,
  TableCheckbox,
  TableHeader,
  TableOrderFilter,
  TableSwitch,
} from '../../../components/template/page';
import ImageWithClear from '../../../components/image/ImageWithClear';
import CommonStatistics from '../../../components/card/CommonStatistics,js/CommonStatistics';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './main_section.module.less';
import modalImage from '../../../assets/dummy/02/02-01.jpg';
import modalContentImage from '../../../assets/dummy/02/02-04.jpg';
import { CloseCircleFilled, SyncOutlined } from '@ant-design/icons';
import produce from 'immer';
import { useGetMainBanner, useGetMainSection, useGetMainSectionDetail } from '../../../apis/queries';
import TableColHeader from '../../../components/table/TableColHeader';
import { useSelector } from 'react-redux';
import Preview from './Preview';
import { MAIN_SECTION_DETAIL_KEY, MAIN_SECTION_KEY } from '../../../apis/queryKeys';
import { useQueryClient } from 'react-query';
import { getFileId, handleModifyShow, handleShowAndStop, parseImageUri } from '../../../common/utils';
import ModifyAllButton from '../../../components/button/ModifyAllButton';
import { restApi } from '../../../apis';
import LowerLine from '../../../components/LowerLine';
import { Option } from 'antd/lib/mentions';
import useUpperLine from '../../../hooks/useUpperLine';

const OperationMainSectionIndex = () => {
  const { RangePicker } = DatePicker;
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  //필터 윗줄 생성(keword, filter params 포함, query param 에 spread 구문으로 삽입)
  const filters = React.useMemo(() => {
    return [
      {
        title: '게시여부',
        key: 'isShow',
        options: [undefined, [true, '게시'], [false, '중지']],
      },
    ];
  }, []);
  const { params: upperParam, UpperLine } = useUpperLine(filters);

  const { commonCodes } = useSelector((state) => state.common);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: 'DESC',
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  React.useEffect(() => {
    if (upperParam) {
      setParams((prev) => ({ ...prev, ...upperParam }));
    }
  }, [upperParam]);

  // 모두 선택 버튼로직
  const [checkedList, setCheckedList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const { data: mainSectionData } = useGetMainSection({
    params,
  });
  const allData = React.useMemo(() => {
    return mainSectionData?.content?.map((v) => ({
      data: v,
      id: v?.id,
    }));
  }, [mainSectionData]);
  React.useEffect(() => {
    if (!!allData) {
      const idList_ = allData?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [allData]);

  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    if (imageId) {
      setDetailModify(
        produce((draft) => {
          draft.imageId = imageId;
        }),
      );
    }
  };
  const [refreshDetail, setRefreshDetail] = React.useState();
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 60 - 280);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isListModal, setIsListModal] = React.useState(false);
  const [radioValue, setRadioValue] = React.useState(2);
  React.useEffect(() => {
    if (!refreshDetail) {
      setRefreshDetail(mainSectionData?.content[0]?.id);
    }
  }, [mainSectionData]);
  const { data: mainSectionDetailData, isFetchedAfterMount } = useGetMainSectionDetail({
    id: refreshDetail ? refreshDetail : mainSectionData?.content[0]?.id,
    params: {},
  });
  console.log(mainSectionDetailData);
  const [detailModify, setDetailModify] = React.useState({
    title: mainSectionDetailData?.title ?? '',
    description: mainSectionDetailData?.description ?? '',
    imageId: mainSectionDetailData?.imageId ?? null,
    sortNum: mainSectionDetailData?.sortNum ?? null,
    renderType: mainSectionDetailData?.renderType ?? undefined,
    isShow: mainSectionDetailData?.isShow ?? null,
  });

  console.log({ detailModify });
  const [modalContent, setModalContent] = React.useState([
    {
      title: '타일',
      order: 1,
    },
  ]);
  React.useEffect(() => {
    console.log(modalContent);
  }, [modalContent]);
  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const radioChange = (e) => {
    setRadioValue(e.target.value);
  };
  const handleAddContent = () => {
    setModalContent(
      produce((draft) => {
        draft.push({
          title: '',
          order: '',
        });
      }),
    );
  };
  const handleContentDelete = (index) => {
    if (modalContent.length < 2) {
      window.confirm('첫번째 항목은 삭제할 수 없습니다.');
    } else {
      setModalContent(
        produce((draft) => {
          draft.splice(index, 1);
        }),
      );
    }
  };
  const onRow = (record) => {
    return {
      onClick: (event) => {
        setRefreshDetail(record.id);
        queryClient.invalidateQueries([MAIN_SECTION_DETAIL_KEY]);
      },
    };
  };
  return (
    <div>
      <PageHeader className="site-page-header" title="메인 섹션" style={{ paddingBottom: 10 }} />
      <ExtraWrapper>
        {/* 필터 윗줄 */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',

          marginBottom: 4.5,
        }}>
          <UpperLine listKey={MAIN_SECTION_KEY}
                     containerStyle={{width:'65%'}}
                     detailKey={MAIN_SECTION_DETAIL_KEY}/>
          <FilterButton
              onClick={() => {
                navigate('/operation/main_section/section');
              }}
          >
            새로 작성
          </FilterButton>
        </div>
        {/* 필터 아랫줄 */}
        <div style={{
          width: '65%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <LowerLine
              left={ <ModifyAllButton
                  showText={'모두 게시'}
                  stopText={'모두 중지'}
                  showAction={() => {
                    handleShowAndStop('/main-sections/show', checkedList, true,
                        () => {
                          queryClient.invalidateQueries([MAIN_SECTION_KEY]);
                        });
                  }}
                  stopAction={() => {
                    handleShowAndStop('/main-sections/show', checkedList, false,
                        () => {
                          queryClient.invalidateQueries([MAIN_SECTION_KEY]);
                        });
                  }}
              />}
              onCheck={() => {
                if (idList.length === checkedList.length) {
                  setCheckedList([]);
                } else {
                  setCheckedList(idList);
                }
              }}
              checked={idList.length === checkedList.length}
          >

          </LowerLine>
          <div style={{width:170}}>
          <FilterSelect
              value={params?.order}
              onChange={(v) =>
                  setParams(
                      produce((draft) => {
                        draft.order = v;
                      }),
                  )
              }
          >
            <Option value="DESC">저장일시 최신순</Option>
          </FilterSelect>
          </div>
        </div>
      </ExtraWrapper>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <PaginateTable
          dataSource={mainSectionData?.content}
          data={mainSectionData}
          selectedRowKeys={selectedRowKeys}
          scroll={{ y: tableHeight - 40 }}
          setSelectedRowKeys={setSelectedRowKeys}
          onRow={onRow}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '65%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
          page={params?.page}
          setPage={(v) =>
            setParams(
              produce((draft) => {
                draft.page = v;
              }),
            )
          }
          pageSize={params?.limit}
          setPageSize={(v) =>
            setParams(
              produce((draft) => {
                draft.limit = v;
              }),
            )
          }
          columns={[
            {
              title: '선택',
              dataIndex: 'data',
              key: 'data',
              width: 40,
              align: 'center',
              render: (data, record) => {
                return (
                  <>
                    <Checkbox
                      key={`${record.id}_check`}
                      checked={checkedList.includes(record.id)}
                      onClick={() => {
                        if (checkedList.includes(record.id)) {
                          setCheckedList(checkedList.filter((v) => v !== record.id));
                        } else {
                          setCheckedList([...checkedList, record.id]);
                        }
                      }}
                    />
                  </>
                );
              },
            },
            {
              title: '대표이미지',
              dataIndex: 'imageId',
              key: 'imageId',
              width: 50,
              align: 'center',
              render: (data, record) => {
                return (
                  <div style={{ position: 'relative' }}>
                    {data ? (
                      <img
                        src={parseImageUri(data)}
                        alt="배너 이미지"
                        style={{
                          width: 50,
                          height: 50,
                          borderRadius: 10,
                        }}
                      ></img>
                    ) : (
                      <div style={{ width: 50, height: 50 }} />
                    )}
                    <button
                      style={{
                        position: 'absolute',
                        top: -2,
                        right: 10,
                        zIndex: 10,
                        padding: 0,
                        width: 18,
                        height: 18,
                        border: 'none',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(255,255,255,1)',
                      }}
                      onClick={() => {
                        Modal.confirm({
                          title: '삭제',
                          content: '해당 글을 삭제하시겠습니까?',
                          okText: '확인',
                          cancelText: '취소',
                          async onOk() {
                            try {
                              await restApi.delete(`/main-sections/${record?.id}`);
                              Modal.confirm({
                                title: '완료',
                                content: '삭제가 완료되었습니다',
                                async onOk() {
                                  try {
                                    await queryClient.invalidateQueries([MAIN_SECTION_DETAIL_KEY]);
                                    await queryClient.invalidateQueries([MAIN_SECTION_KEY]);
                                    setRefreshDetail(mainSectionData?.content[0]?.id);
                                  } catch (e) {
                                    window.location.reload();
                                  }
                                },
                                cancelButtonProps: {
                                  style: { display: 'none' },
                                },
                                okText: '확인',
                              });
                            } catch (error) {
                              Modal.warning({
                                title: '실패했습니다',
                                content: error.response.data.message,
                              });
                            }
                          },
                        });
                      }}
                    >
                      <CloseCircleFilled style={{ fontSize: 18, color: '#1e3d83' }} />
                    </button>
                  </div>
                );
              },
            },
            {
              title: '제목',
              dataIndex: 'title',
              key: 'title',
              width: 70,
              align: 'center',
              render: (data, record) => {
                return (
                  <div style={{ position: 'relative' }}>
                    <p>{record.title}</p>
                  </div>
                );
              },
            },
            {
              title: '설명',
              dataIndex: 'description',
              key: 'description',
              width: 150,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>{data}</p>
                  </div>
                );
              },
            },
            {
              title: '타입',
              dataIndex: 'renderType',
              key: 'renderType',
              width: 80,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>{commonCodes?.map[data]}</p>
                  </div>
                );
              },
            },
            {
              title: '순서',
              dataIndex: 'sortNum',
              key: 'sortNum',
              width: 30,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>{data}</p>
                  </div>
                );
              },
            },
            {
              title: <TableColHeader title={'게시여부'} subTitle={'게시/중지'} />,
              dataIndex: 'isShow',
              key: 'isShow',
              width: 50,
              align: 'center',
              render: (data, record) => {
                return (
                  <Switch
                    checked={data}
                    onClick={() => {
                      handleModifyShow(`/main-sections/show`, record.id, data, () => {
                        queryClient.invalidateQueries([MAIN_SECTION_KEY]);
                      });
                    }}
                  />
                );
              },
            },
          ]}
        />
        <div
          style={{

            overflowY: 'scroll',
            height: document.body.clientHeight - 240,
            paddingLeft: 20,
            boxSizing: 'border-box',
            paddingBottom: 15,
          }}
        >
          <p style={{ marginBottom: 15 }}>미리보기</p>
          <Preview
            type={mainSectionDetailData?.renderType}
            buttonClick={() => {
              navigate('/operation/main_section/section', { state: { id: refreshDetail } });
            }}
          />
          <CommonStatistics />
        </div>
      </div>
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        okText={'확인'}
        cancelText={'수정'}
      >
        <p>메인배너 수정</p>
        <div
          style={{
            width: 400,
            height: 800 + modalContent.length * 76,
            backgroundColor: 'white',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 100,
            padding: 20,
          }}
        >
          <div
            style={{
              position: 'relative',
              width: 150,
              height: 150,
              marginLeft: 'auto',
              marginRight: 'auto',
              backgroundImage: `url(${modalImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              borderRadius: 10,
            }}
          >
            <button
              style={{
                backgroundColor: 'rgba(0,0,0,0)',
                padding: 0,
                position: 'absolute',
                top: 10,
                right: 10,
              }}
            >
              <SyncOutlined style={{ fontSize: 30, color: '#1e3d83' }} />
            </button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <input
              type="text"
              style={{
                width: '85%',
                border: 'none',
                borderBottom: '1px solid #d9d9d9',
                margin: '30px 0',
              }}
              placeholder="제목을 입력해주세요."
            />
            <input
              type="text"
              style={{
                width: '10%',
                border: 'none',
                borderBottom: '1px solid #d9d9d9',
                margin: '30px 0',
              }}
              placeholder="순서"
            />
          </div>
          <div style={{ display: 'flex', borderBottom: '1px solid #d9d9d9' }}>
            <input type="text" style={{ width: '90%', border: 'none' }} />
            <span style={{ color: '#767676', fontSize: 12 }}>0/80</span>
          </div>
          <div>
            <select
              style={{
                width: '100%',
                padding: '5px 0',
                marginTop: 30,
                border: 'none',
                borderBottom: '1px solid #d9d9d9',
              }}
            >
              <option value="1">소형 좌우 슬라이드</option>
              <option value="2">중형 좌우 슬라이드</option>
              <option value="3">대형 좌우 슬라이드</option>
              <option value="4">소형 좌우 고정 레이아웃</option>
              <option value="5">중형 좌우 슬라이드</option>
              <option value="6">대형 좌우 슬라이드</option>
            </select>
          </div>
          <div className={styles.modal_sub_title_div}>
            <span>세부항목 추가/삭제</span>
            <button onClick={handleAddContent}>항목추가</button>
          </div>
          {modalContent?.map((item, index) => {
            return (
              <div key={`main_section_${index}`} className={styles.modal_content_line_div}>
                <div className={styles.content_img_div}>
                  <img src={modalContentImage} alt="" />
                  <button onClick={() => handleContentDelete(index)}>
                    <CloseCircleFilled style={{ color: '#1e3d83', fontSize: 20 }} />
                  </button>
                </div>
                <div className={styles.contetn_input_div}>
                  <input type="text" value={item?.title ? item?.title : ''} />
                  <input type="text" value={item?.order ? item?.order : ''} />
                </div>
                <div className={styles.content_add_button_div}>
                  <p>컨텐츠 연결</p>
                  <button
                    onClick={() => {
                      setIsListModal(true);
                    }}
                  >
                    리스트 생성
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={isListModal}
        onOk={() => {
          setIsListModal(false);
        }}
        onCancel={() => {
          setIsListModal(false);
        }}
        destroyOnClose={true}
        maskClosable={false}
        okText={'확인'}
        cancelText={'수정'}
      >
        <p>메인팝업 수정</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: 850,
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              width: '48%',
              height: '100%',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: 20,
              }}
            >
              <div
                style={{
                  position: 'relative',
                  width: 150,
                  height: 150,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  backgroundImage: `url(${modalImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  borderRadius: 10,
                }}
              >
                <button
                  style={{
                    backgroundColor: 'rgba(0,0,0,0)',
                    padding: 0,
                    position: 'absolute',
                    top: 10,
                    right: 10,
                  }}
                >
                  <SyncOutlined style={{ fontSize: 30, color: '#1e3d83' }} />
                </button>
              </div>
              <input
                type="text"
                style={{
                  width: '100%',
                  border: 'none',
                  borderBottom: '1px solid #d9d9d9',
                  margin: '30px 0',
                }}
                placeholder="제목을 입력해주세요."
              />
              <div
                style={{
                  display: 'flex',
                  borderBottom: '1px solid #d9d9d9',
                }}
              >
                <input type="text" style={{ width: '90%', border: 'none' }} />
                <span style={{ color: '#767676', fontSize: 12 }}>0/80</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '30px',
                }}
              >
                <span>컨텐츠 연결</span>
                <button
                  style={{
                    border: '1.5px solid #1e3d83',
                    padding: '4px 18px',
                    borderRadius: '15px',
                  }}
                  onClick={() => {
                    setIsListModal(true);
                  }}
                >
                  리스트 생성
                </button>
              </div>
              <input
                type="text"
                style={{
                  width: '100%',
                  border: 'none',
                  borderBottom: '1px solid #d9d9d9',
                  margin: '30px 0',
                }}
                placeholder="순서"
              />
              <p>게시기간설정</p>
              <Radio.Group
                style={{
                  width: '100%',
                  textAlign: 'center',
                  margin: '30px 0',
                }}
                onChange={radioChange}
                value={radioValue}
              >
                <Radio value={1} style={{ marginRight: 50 }}>
                  영구
                </Radio>
                <Radio value={2}>기간설정</Radio>
              </Radio.Group>
              <div style={{ marginBottom: 10 }}>
                <RangePicker disabled={radioValue == 1 ? true : false} />
              </div>
              <p className={styles.preview_text}>※ 시작일 00:00부터 종료일 24:00까지 게시됩니다.</p>
            </div>
          </div>
          <div
            style={{
              backgroundColor: 'white',
              width: '48%',
              height: '100%',
            }}
          >
            <Table
              dataSource={OPERATION_MAIN_POPUP_LIST}
              scroll={{ y: tableHeight }}
              style={{
                borderRadius: 10,
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
                paddingBottom: '100px',
              }}
              columns={[
                {
                  title: (
                    <TableHeader>
                      <span className="title">형상</span>
                    </TableHeader>
                  ),
                  width: 80,
                  align: 'center',
                  render: (data, record) => {
                    console.log({ record });
                    return '';
                  },
                },
                {
                  title: (
                    <TableHeader>
                      <span className="title">제목</span>
                      <span className="sub_title">메모</span>
                    </TableHeader>
                  ),
                  render: (data, record) => {
                    return '';
                  },
                },
              ]}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OperationMainSectionIndex;
