import { PageHeader, Button, DatePicker, Input, Select, Space, Switch } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { restApi } from '../../../apis';
import PaginateTable from '../../../components/table/PaginateTable';
import { proposalDummy } from '../../../data/dummy';
import { getClientWidth, handleAccountShowAndStop } from '../../../common/utils';
import CurrentBid from '../../../components/card/CurrentBid/CurrentBid';
import SmallUserDetail from '../../../components/card/SmallUserDetail/SmallUserDetail';
import LocationInfo from '../../../components/card/LocationInfo/LocationInfo';
import SmallEstimate from '../../../components/card/SmallEstimate/SmallEstimate';
import useRenderSwitch from '../../../components/useRenderSwitch';
import {
  ExtraWrapper,
  FilterButton,
  FilterInput,
  FilterSelect,
  TableCheckbox,
  TableSwitch,
} from '../../../components/template/page';
import ContentSlider from '../../../components/card/ContentSlider/ContentSlider';
import useUpperLine from '../../../hooks/useUpperLine';
import LowerLine from '../../../components/LowerLine';
import { useGetPartnerUser } from '../../../apis/queries';
import ModifyAllButton from '../../../components/button/ModifyAllButton';
const FindCustomerIndex = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  console.log(selectedRowKeys);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const [isStatus, setStatus] = React.useState(false);
  const { isStatus: renderState, setIsStatus: setRenderState, RenderSwitch } = useRenderSwitch({ isStatus });
  const buttonHandler = () => {
    setStatus((status) => !status);
    setRenderState(isStatus);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: 'DESC',
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);

  //필터 윗줄 생성(keword, filter params 포함, query param 에 spread 구문으로 삽입)
  const filters = React.useMemo(() => {
    return [
      { title: '회원사용', key: 'isActive', options: [undefined, [true, '정상'], [false, '정지']] },
      {
        title: '고객찾기상태',
        key: 'filter1',
        options: [undefined, [true, '제안중'], [false, '선정'], [false, '일반']],
      },
      { title: '지역설정', key: 'filter2', options: [] },
      { title: '사업자등록여부', key: 'filter3', options: [undefined, [true, 'Y'], [false, 'N']] },
      { title: '보험설정여부', key: 'filter4', options: [undefined, [true, 'Y'], [false, 'N']] },
    ];
  }, []);
  const { params: upperParam, UpperLine } = useUpperLine(filters);

  // 모두 선택 버튼로직
  const [checkedList, setCheckedList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const { data: partnerData, isLoading } = useGetPartnerUser({
    type: 'INTERIOR',
    params,
  });

  const allData = React.useMemo(() => {
    return partnerData?.content?.map((v) => ({
      data: v,
      id: v?.id,
      address1: v?.address1,
      address2: v?.address2,
      nickname: v?.nickname,
      image: v?.profileImageId,
      partnerType: v?.partnerType,
      createdAt: v?.createdAt,
      hasBusinessLicense: v?.hasBusinessLicense,
      limitCreateProposal: v?.limitCreateProposal,
      isActive: v?.isActive,
    }));
  }, [partnerData]);
  React.useEffect(() => {
    if (!!allData) {
      const idList_ = allData?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [allData]);

  const COLUMNS = [
    {
      title: '상태',
      key: 'id',
      dataIndex: 'id',
      align: 'center',
      width: 90,
    },
    {
      title: '프로필',
      key: 'username',
      dataIndex: 'username',
      align: 'center',
      sorter: true,
    },
    {
      title: '닉네임',
      key: 'nickname',
      dataIndex: 'nickname',
      align: 'center',
      sorter: true,
      width: 180,
    },
    {
      title: '가입일시',
      key: 'address',
      dataIndex: 'address',
      sorter: true,
      align: 'center',
    },
    {
      title: '전화인증',
      key: 'created_at',
      dataIndex: 'created_at',
      sorter: true,
      align: 'center',
    },
    {
      title: '진행구분',
      key: 'progress',
      dataIndex: 'progress',
      sorter: false,
      align: 'center',
      render: (status, record, index) => {
        return <Switch onClick={buttonHandler} checked={isStatus} />;
      },
    },
  ];

  const location = useLocation();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);
  const [page, setPage] = React.useState(parseInt(qs.parse(location.search)?.page || '0', 10));
  const [data, setData] = React.useState();
  const [pageSize, setPageSize] = React.useState(20);
  // const [extra, setExtra] = useState();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      limit: pageSize,
    };
    const { data } = await restApi.get('/accounts', { params });
    setData(data);
  }, [query, pageSize]);

  React.useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const [columns, tableWidth] = React.useMemo(() => {
    const o = [...COLUMNS];

    const width = o.reduce((a, c) => a + (c.width || c.minWidth || 300), 0);
    return [o, width];
  }, []);

  const [tableScroll, setTableScroll] = React.useState();
  React.useEffect(() => {
    const handle = () => {
      if (tableWidth < getClientWidth()) {
        setTableScroll(undefined);
      } else {
        setTableScroll({ x: tableWidth });
      }
    };

    handle();
  }, [tableWidth]);

  // 테이블 페이지네이션
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 380);

  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 360);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        console.log(1111);
      },
    };
  };

  return (
    <div>
      <div>
        <PageHeader className="site-page-header" title="고객찾기" style={{ paddingBottom: 10 }} />
        <ExtraWrapper>
          {/* 필터 윗줄 */}
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4.5 }}>
            <UpperLine
              // listKey={PARTMER_USER_KEY}
              // detailKey={PARTMER_USER_DETAIL_KEY}
              // setRefresh={setRefreshDetail}
              containerStyle={{ width: '76%' }}
            >
              {/* <TreeSelect
              style={{ width: '100%' }}
              // fieldNames={{ label: 'label', value: 'areaCode' }}
              placeholder="지역 선택"
              allowClear
              onChange={(e) => {
                console.log(e);
              }}
              treeDefaultExpandAll
              treeData={regions?.trees}
            /> */}
            </UpperLine>
          </div>
          {/* 필터 아랫줄 */}
          <div style={{ width: '76%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <LowerLine
              onCheck={() => {
                if (idList.length === checkedList.length) {
                  setCheckedList([]);
                } else {
                  setCheckedList(idList);
                }
              }}
              checked={idList.length === checkedList.length}
              containerStyle={{ width: '100%' }}
            >
              <ModifyAllButton
                showText={'견적 가능'}
                stopText={'불가능'}
                customStyle={{ marginLeft: 40 }}
                showAction={() => {
                  handleAccountShowAndStop('/accounts/partner/limit/create-proposals', checkedList, true, () => {
                    // queryClient.invalidateQueries([PARTMER_USER_KEY]);
                    // queryClient.invalidateQueries([PARTMER_USER_DETAIL_KEY]);
                  });
                }}
                stopAction={() => {
                  handleAccountShowAndStop('/accounts/partner/limit/create-proposals', checkedList, false, () => {
                    // queryClient.invalidateQueries([PARTMER_USER_KEY]);
                    // queryClient.invalidateQueries([PARTMER_USER_DETAIL_KEY]);
                  });
                }}
              />
            </LowerLine>
            {/* <FilterSelect
            value={params?.order}
            onChange={(v) =>
              setParams(
                produce((draft) => {
                  draft.order = v;
                }),
              )
            }
          >
            <Option value="DESC">저장일시 최신순</Option>
          </FilterSelect> */}
          </div>
        </ExtraWrapper>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PaginateTable
          rowSelection={rowSelection}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          dataSource={proposalDummy}
          columns={[
            {
              title: '상태',
              key: 'id',
              dataIndex: 'id',
              align: 'center',
              width: 90,
            },
            {
              title: '프로필',
              key: 'username',
              dataIndex: 'username',
              align: 'center',
              sorter: true,
            },
            {
              title: '닉네임',
              key: 'nickname',
              dataIndex: 'nickname',
              align: 'center',
              sorter: true,
              width: 180,
            },
            {
              title: '가입일시',
              key: 'address',
              dataIndex: 'address',
              sorter: true,
              align: 'center',
            },
            {
              title: '전화인증',
              key: 'created_at',
              dataIndex: 'created_at',
              sorter: true,
              align: 'center',
            },
            {
              title: '진행구분',
              key: 'progress',
              dataIndex: 'progress',
              sorter: false,
              align: 'center',
              render: (status, record, index) => {
                return <RenderSwitch selectedRowKeys={selectedRowKeys} index={index} />;
                // return <Switch onChange={handle} checked={isStatus} />;
              },
            },
          ]}
          scroll={{ y: tableHeight }}
          data={data}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onRow={onRow}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '65%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
        />
        <div
          style={{ width: 500, overflowY: 'scroll', overflowX: 'hidden', height: document.body.clientHeight - 240 }}
        >
          <SmallUserDetail isFirst={true} />
          <CurrentBid />
          <LocationInfo />
          <ContentSlider leftLine={true} />
          <SmallEstimate />
        </div>
      </div>
    </div>
  );
};
export default FindCustomerIndex;
