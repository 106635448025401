import React, { useEffect, useState } from 'react';
import { Modal, PageHeader, Checkbox, Switch } from 'antd';
import CommentIcon from '../../../../components/image/CommentIcon';
import styles from './index.module.less';
import { DollarCircleOutlined, HeartFilled, WechatFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Option } from 'antd/lib/mentions';
import produce from 'immer';
import moment from 'moment';
import { isSwitchChecked, parseImageUri } from '../../../../common/utils';
import { useQueryClient } from 'react-query';
import { useGetContentDetail, useGetLikedStyleList } from '../../../../apis/queries';
import Thumbnail from '../../../../components/image/Thumbnail';
import TableColHeader from '../../../../components/table/TableColHeader';
import { restApi } from '../../../../apis';
import { CONTENTS_KEY, CONTENT_DETAIL_KEY, LIKED_STYLE_LIST_KEY } from '../../../../apis/queryKeys';
import {
  ExtraWrapper,
  FilterButton,
  FilterInput,
  FilterSelect,
  FilterWhiteButton,
} from '../../../../components/template/page';
import PaginateTable from '../../../../components/table/PaginateTable';
import ContentSlider from '../../../../components/card/ContentSlider/ContentSlider';
import CommonStatistics from '../../../../components/card/CommonStatistics,js/CommonStatistics';
import { useParams } from 'react-router-dom';
import useUpperLine from '../../../../hooks/useUpperLine';
import LowerLine from '../../../../components/LowerLine';
import ModifyAllButton from '../../../../components/button/ModifyAllButton';

const ContentCard = React.memo(({ item, commonCodes, setVisibleDetailModal }) => {
  return (
    <div
      onClick={() => {
        setVisibleDetailModal(true);
      }}
      className={styles.slider_div}
      style={{ cursor: 'pointer', backgroundImage: `url(${parseImageUri(item?.thumbnailId)})` }}
    >
      <div className={styles.backdrop} />
      <div className={styles.slider_inner_div}>
        <p className={styles.category_p}>{commonCodes?.map[item?.area]}</p>
        <p className={styles.title_p}>{item?.description}</p>
        <div className={styles.flex_div}>
          <div className={styles.flex_div} style={{ alignItems: 'center' }}>
            <img
              className={styles.style_profile_img}
              src={parseImageUri(item?.createdBy?.profileImageId)}
              alt="프로필 이미지"
            />
            <p className={styles.category_p} style={{ marginLeft: 10 }}>
              {item?.createdBy?.nickname}
            </p>
          </div>
          <div className={styles.flex_div}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <HeartFilled style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p}>{item?.likeInfo?.likeCount}</p>
            </div>
            <div
              style={{
                marginLeft: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CommentIcon style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p}>{item?.shareCount}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
const CollectionsMagazinesIndex = (props) => {
  const { commonCodes } = useSelector((state) => state.common);
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      sort: 'createdAt',
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);

  const queryClient = useQueryClient();
  const [idList, setIdList] = React.useState([]);
  const [checkedList, setCheckedList] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState(null);
  const { type, id,accountType } = useParams();
  const filters = React.useMemo(() => {
    const constructionTypeItems = commonCodes?.tree['COTP']?.map((v) => [v.code, v.label]);
    constructionTypeItems?.unshift([null, '전체공사']);
    const isConfirmedItems = [
      [null, '전체'],
      [true, '승인'],
      [false, '대기'],
    ];
    return [
      // { title: '공사타입', key: 'constructionType', options: constructionTypeItems },
      { title: '승인여부', key: 'isConfirmed', options: isConfirmedItems },
    ];
  }, [commonCodes]);
  const { params: upperParam, UpperLine } = useUpperLine(filters);

  React.useEffect(() => {
    setParams((prev) => ({ ...prev, ...upperParam }));
  }, [upperParam]);

  const { data: contentData } = useGetLikedStyleList({
    id: id,
    params: { ...params, ...upperParam },
    type: type,
    accountType,
    config: {
      onSuccess: (data) => {
        if (!selectedId) {
          setSelectedId(data?.content[0]?.id);
        }
      },
    },
  });
  const { data: contentDetail } = useGetContentDetail({
    type: type,
    id: selectedId,
    config: {
      enabled: !!selectedId,
    },
  });

  const data = React.useMemo(() => {
    return contentData?.content?.map((v) => ({
      data: v,
      id: v?.id,
      title: v?.title,
      image: v?.thumbnailId,
      createdBy: v?.createdBy,
      confirmedBy: {
        handleConfirmAt: v.handleConfirmAt,
        handleConfirmBy: v.handleConfirmationBy,
        isConfirmed: v.isConfirmed,
      },
    }));
  }, [contentData]);

  const detailData = React.useMemo(() => {
    return contentDetail?.subContents?.map((v) => ({
      createdBy: contentDetail?.createdBy,
      likeInfo: contentDetail?.likeInfo,
      shareCount: contentDetail?.shareCount,
      ...v,
    }));
  }, [contentDetail]);

  const constructionCategories = React.useMemo(() => {
    if (!commonCodes) return [];
    let constructionTypes = commonCodes?.tree['COTP']?.map((v) => ({
      value: v.code,
      label: v.label === '전체' ? '전체공사' : v.label,
    }));
    constructionTypes = [{ value: null, label: '전체' }, ...constructionTypes];
    return constructionTypes;
  }, [commonCodes]);
  React.useEffect(() => {
    if (!!data) {
      const idList_ = data?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [data]);
  const columns = [
    {
      title: '선택',
      dataIndex: 'data',
      key: 'data',
      width: 40,
      align: 'center',
      render: ({ id }) => {
        return (
          <Checkbox
            key={`${id}_check`}
            checked={checkedList.includes(id)}
            onClick={() => {
              if (checkedList.includes(id)) {
                setCheckedList(checkedList.filter((v) => v !== id));
              } else {
                setCheckedList([...checkedList, id]);
              }
            }}
          />
        );
      },
    },
    {
      title: '썸네일',
      dataIndex: 'data',
      key: 'image',
      width: 40,
      align: 'center',
      render: ({ thumbnailId }) => <Thumbnail id={thumbnailId} />,
    },
    {
      title: <TableColHeader title="제목" subTitle="공사타입" />,
      dataIndex: 'data',
      key: 'data',
      width: 100,
      render: (data) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: '#000', fontWeight: '600' }}>{data?.title}</span>
            <span style={{ color: '#767676' }}>{commonCodes?.map[data?.constructionType]}</span>
          </div>
        );
      },
    },
    {
      title: <TableColHeader title="등록자" subTitle="등록일시" />,
      dataIndex: 'data',
      key: 'data',
      width: 100,
      render: ({ createdAt, createdBy }) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ color: '#767676' }}>{createdBy.nickname}</span>
            <span style={{ color: '#767676' }}>{moment(createdAt).format('YYYY-MM-DD HH:mm')}</span>
          </div>
        );
      },
    },
    {
      title: <TableColHeader title="승인자" subTitle="승인일시" />,
      dataIndex: 'confirmedBy',
      key: 'confirmedBy',
      width: 100,
      render: ({ handleConfirmBy, handleConfirmAt, isConfirmed }) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ color: '#767676' }}>{handleConfirmBy?.nickname}</span>
            <span style={{ color: '#767676' }}>
              {isConfirmed ? (handleConfirmAt ? moment(handleConfirmAt).format('YYYY-MM-DD HH:mm') : '최초승인') : '-'}
            </span>
          </div>
        );
      },
    },
    {
      title: <TableColHeader title="승인여부" subTitle="승인/대기" />,
      dataIndex: 'data',
      key: 'data',
      width: 80,
      align: 'center',
      render: ({ isConfirmed, id }) => {
        return (
          <div className={styles.switchWrapper}>
            <Switch
              checked={isConfirmed !== false}
              onClick={() =>
                restApi
                  .put(`/contents`, {
                    ids: [id],
                    isConfirmed: isConfirmed !== false ? false : true,
                  })
                  .then((res) => {
                    queryClient.invalidateQueries(LIKED_STYLE_LIST_KEY);
                  })
              }
            />
          </div>
        );
      },
    },
  ];

  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 280);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);
  const onRow = React.useCallback(
    (record) => {
      return {
        onClick: (event) => {
          setSelectedId(record.id);
        },
      };
    },
    [contentData],
  );

  const handleDeleteItems = React.useCallback(
    (ids) => {
      Modal.confirm({
        content: '정말 삭제하시겠습니까?',
        okText: '확인',
        cancelText: '취소',
        onOk: () => {
          restApi
            .delete('/contents', {
              data: { ids },
            })
            .then((res) => {
              queryClient.invalidateQueries(LIKED_STYLE_LIST_KEY);
              Modal.success({
                content: '삭제되었습니다.',
                okText: '확인',
              });
            });
        },
      });
    },
    [data],
  );
  const handleConfirmed = React.useCallback(
    (type) => {
      const ids = checkedList;
      restApi
        .put(`/contents`, {
          ids,
          isConfirmed: type,
        })
        .then((res) => {
          queryClient.invalidateQueries(LIKED_STYLE_LIST_KEY);
        });
    },
    [checkedList],
  );
  return (
    <>
      <div>
        <PageHeader
          className="site-page-header"
          title={`${accountType==='user'?'저장한':'등록한'} ${type === 'styles' ? '스타일' :type === 'portfolios'? '포트폴리오':type==='trends'?'트랜드':'공사가이드'}`}
          style={{ paddingBottom: 10 }}
        />
        <ExtraWrapper>
          {/* 필터 윗줄 */}
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4.5 }}>
            <UpperLine containerStyle={{width:'65%'}} listKey={CONTENTS_KEY} detailKey={CONTENT_DETAIL_KEY} />
          </div>
          {/* 필터 아랫줄 */}
          <div style={{
            width: '65%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <LowerLine
                containerStyle={{width: '65%'}}
                onCheck={() => {
                  if (idList.length === checkedList.length) {
                    setCheckedList([]);
                  } else {
                    setCheckedList(idList);
                  }
                }}
                checked={idList.length === checkedList.length}
                left={
                  <>
                    <div
                        onClick={() => {
                          handleDeleteItems(checkedList);
                        }}
                        style={{cursor: 'pointer', marginLeft: 40}}
                        className={styles.filterWrapper}
                    >
                      <img
                          src={require('../../../../assets/delete.png')}
                          alt="delete"
                          style={{
                            width: '18.2px',
                            height: '21.2px',
                          }}
                      />
                      <span className={styles.filterText}>항목삭제</span>
                    </div>
                    <ModifyAllButton
                        showText={'승인'}
                        stopText={'대기'}
                        customStyle={{marginLeft: 40}}
                        showAction={() => {
                          handleConfirmed(true);
                        }}
                        stopAction={() => {
                          handleConfirmed(false);
                        }}
                    /></>}
            >


            </LowerLine>
            <div style={{width: 170}}>
              <FilterSelect
                  value={params?.sort}
                  onChange={(v) =>
                      setParams(
                          produce((draft) => {
                            draft.sort = v;
                          }),
                      )
                  }
              >
                <Option value="createdAt">저장일시 최신순</Option>
              </FilterSelect>
            </div>
          </div>
        </ExtraWrapper>

        <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <PaginateTable
            dataSource={data}
            columns={columns}
            scroll={{ y: tableHeight - 40 }}
            data={contentData}
            page={params?.page}
            setPage={(v) =>
              setParams(
                produce((draft) => {
                  draft.page = v;
                }),
              )
            }
            pageSize={params?.limit}
            setPageSize={(v) =>
              setParams(
                produce((draft) => {
                  draft.limit = v;
                }),
              )
            }
            onRow={onRow}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '65%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
            }}
          />
          <div
            style={{ width: 500,paddingLeft:10, overflowY: 'scroll', overflowX: 'hidden', height: document.body.clientHeight - 240 }}
          >
            <h3
              style={{
                fontSize: '16px',
                fontWeight: '600',
                color: '#000',
              }}
            >
              {`${type === 'styles' ? '스타일' :type === 'portfolios'? '포트폴리오':type==='trends'?'트랜드':'공사가이드'} 정보`}
            </h3>
            <ContentSlider>
              {detailData?.map((v) => (
                <ContentCard setVisibleDetailModal={() => {}} item={v} commonCodes={commonCodes} />
              ))}
            </ContentSlider>
            <CommonStatistics />
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionsMagazinesIndex;
