import React from 'react';
import assets from '../../assets';
import { useSelector } from 'react-redux';
import { restApi } from '../../apis';

const IGNORED = ['feedback', 'constructionDate'];

const LifeStyle = ({ data }) => {
  const { commonCodes } = useSelector((s) => s.common);

  if (!data) return null;
  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', columnGap: '30px', rowGap: '10px' }}>
      {Object.entries(data)?.map(([k, v]) => {
        if (IGNORED.includes(k)) return null;
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '3px',
            }}
          >
            <img style={{ width: '20px', height: '20px' }} src={assets.lifeStyle[k]} alt={k} />
            <span
              style={{
                fontSize: '12px',
                color: '#767676',
              }}
            >
              {commonCodes?.tree[v.substring(0, 4)]?.find((v2) => v2.code === v).extra1}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default LifeStyle;
