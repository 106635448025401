import React from 'react';
import styles from './CommonStatistics.module.less';

const aa = [0, 1, 2, 3];
const bb = [0, 1, 2, 3, 4];
const MapCard = React.memo(({ item, index }) => {
  const { title, joinCount, secessionCount, fill } = item;
  const totalCount = joinCount - secessionCount;

  return (
    <div className={styles.map_card} style={{ marginTop: index > 3 ? '50px' : '0' }}>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <span style={{ color: fill }} className={styles.sum_member}>
          {totalCount > 0 ? `+${totalCount}` : totalCount}
        </span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className={styles.sub_text}>가입</span>
        <span className={styles.sub_text}>{joinCount}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className={styles.sub_text}>탈퇴</span>
        <span className={styles.sub_text}>{secessionCount}</span>
      </div>
      <hr />
      <p className={styles.sum_num}>125,342</p>
      <p className={styles.member_label}>{title}</p>
    </div>
  );
});

const MapCard2 = React.memo(({ item, index }) => {
  const { title, averageCount, rateOfChange, prefix, totalCount } = item;

  return (
    <div className={styles.map_card} style={{ marginTop: index > 3 ? '50px' : '0' }}>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '10px 0px' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{ color: '#1e3d83', fontSize: '16px' }} className={styles.sum_member}>
            {totalCount}
          </span>
          <span
            style={{
              fontSize: '10px',
              color: '#767676',
              alignSelf: 'flex-end',
              lineHeight: '18px',
              marginLeft: '2px',
            }}
          >
            {prefix}
          </span>
        </div>
      </div>
      <hr />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className={styles.sub_text}>연평균</span>
        <span className={styles.sub_text}>{`${averageCount}${prefix}`}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className={styles.sub_text}>증감</span>
        <span className={styles.sub_text}>{rateOfChange > 0 ? `+${rateOfChange}%` : `${rateOfChange}%`}</span>
      </div>
      <p style={{ marginTop: 18 }} className={styles.member_label}>
        {title}
      </p>
    </div>
  );
});

const StatisticsDetailCard = () => {
  const joinData = [
    {
      title: '소비자',
      joinCount: 123,
      secessionCount: 22,
      fill: '#7eccde',
    },
    {
      title: '시공사',
      joinCount: 123,
      secessionCount: 55,
      fill: '#1e3d83',
    },
    {
      title: '유통사',
      joinCount: 152,
      secessionCount: 26,
      fill: '#42b9ab',
    },
    {
      title: '인플루언서',
      joinCount: 25,
      secessionCount: 28,
      fill: '#a2ce76',
    },
  ];

  const operateData = [
    {
      title: '견적',
      totalCount: 51234,
      averageCount: 43289,
      rateOfChange: 15,
      prefix: '회',
    },
    {
      title: '공사도움받기',
      totalCount: 4252,
      averageCount: 43289,
      rateOfChange: 15,
      prefix: '회',
    },
    {
      title: '전문가 찜하기',
      totalCount: 253,
      averageCount: 43289,
      rateOfChange: -2,
      prefix: '회',
    },
    {
      title: '매출',
      totalCount: '1,06',
      averageCount: 0.83,
      rateOfChange: 15,
      prefix: '억',
    },
    {
      title: '견적액 합계',
      totalCount: '51,250',
      averageCount: '34,125',
      rateOfChange: 22,
      prefix: '억',
    },
  ];
  return (
    <div>
      <div className={styles.statistics_detail_card}>
        <span className={styles.title}>가입/탈퇴 회원수</span>
        <div>
          <span className={styles.select_label}>산출기간</span>
          <select
            style={{
              borderRadius: '6px',
              border: 'solid 2px #d9d9d9',
              color: '#767676',
            }}
            onChange={(e) => {
              console.log(e.target.value);
            }}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        {joinData.map((v, index) => (
          <React.Fragment key={`mapCard ${index}`}>
            <MapCard item={v} />
          </React.Fragment>
        ))}
      </div>
      <div className={styles.statistics_detail_card}>
        <span className={styles.title}>서비스 운영현황</span>
        <div>
          <span className={styles.select_label}>산출기간</span>
          <select
            style={{
              borderRadius: '6px',
              border: 'solid 2px #d9d9d9',
              color: '#767676',
            }}
            onChange={(e) => {
              console.log(e.target.value);
            }}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', flexWrap: 'wrap' }}>
        {operateData?.map((v, index) => {
          return (
            <React.Fragment key={`mapCard ${index}`}>
              <MapCard2 item={v} index={index} />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default StatisticsDetailCard;
