import React, { useState } from 'react';
import { Button, Form, Input, Divider } from 'antd';
// import styles from './Login.module.less';
import styles from '../Login.module.less';
import { restApi, setToken } from '../../../apis';
import { useDispatch } from 'react-redux';
import { me } from '../../../data/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import assets from '../../../assets';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import produce from 'immer';

const FindId = ({ setIsSignUp, setIsFindPassword, setIsFindId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = React.useState({
    email: null,
  });
  const handleFindId = async () => {
    try {
      await restApi.post(`/accounts/password`, null, { params: postData });
      alert(`비밀번호를 발송했습니다`);
      setIsFindId(false);
    } catch (error) {
      alert(`이메일을 바르게 입력해주세요`);
    }
  };
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 100,
          left: 50,
          fontSize: 40,
          color: '#1e3d83',
        }}
      >
        <span style={{ fontWeight: 'bold' }}>바바</span>와 함께하는
        <br />
        행복한 <span style={{ fontWeight: 'bold' }}>인테리어</span>의 시작
      </div>
      <div
        style={{
          width: 500,
          backgroundColor: 'white',
          height: 600,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          borderRadius: ' 10px 0 0 10px',
          boxShadow: '5px 5px 10px rgba(0,0,0,0.3)',
        }}
      >
        <img style={{ width: '100%' }} src={assets.findInfo} alt="" />
      </div>
      <div className={styles.login_wrapper} style={{ position: 'relative' }}>
        <ArrowLeftOutlined
          onClick={() => {
            setIsSignUp(false);
            setIsFindPassword(false);
            setIsFindId(false);
          }}
          style={{ position: 'absolute', top: 20, right: 20, cursor: 'pointer' }}
        />
        <div style={{ marginBottom: 70 }}>
          <p style={{ fontSize: 16, fontWeight: 'bold' }}>ID/비밀번호 찾기</p>
          <p style={{ color: '#767676' }}>이메일을 입력해 주세요 </p>
        </div>
        <Form>
          <Form.Item noStyle name={'username'}>
            <p style={{ fontSize: 16, fontWeight: 'bold' }}>이메일</p>
            <Input
              value={postData?.email}
              onChange={(e) => {
                setPostData(
                  produce((draft) => {
                    draft.email = e.target.value;
                  }),
                );
              }}
              placeholder={'youremail@baba.com'}
              className={styles.input}
              allowClear
            />
          </Form.Item>
          <Button
            onClick={handleFindId}
            block
            className={styles.submit}
            type={'primary'}
            htmlType={'submit'}
            loading={loading}
            style={{ marginTop: 180 }}
          >
            확인
          </Button>
        </Form>
      </div>
    </>
  );
};

export default FindId;
