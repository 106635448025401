import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, PageHeader, Radio, Space, Select, Modal } from 'antd';
import styles from './estimate_options.module.less';
import { useGetEstimateCostNote, useGetEstimateCostTemplate } from '../../../apis/queries';
import { CommonCodeContext } from '../../../context/CommonCodeContext';
import produce from 'immer';
import { useQueryClient } from 'react-query';
import { restApi } from '../../../apis';

const optionDummy = ['공과잡비', '기업이윤', '계약이행보증보험', '하자이행보증보험', '산업안전보험료'];

const EstimateOptionsIndex = (props) => {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const { data: costData, refetch } = useGetEstimateCostTemplate();
  const { data: noteData, refetch: refetchNote } = useGetEstimateCostNote();
  const { codeMapEACO } = React.useContext(CommonCodeContext);
  const [noteVlaue, setNoteValue] = useState(noteData);
  const [postData, setPostData] = React.useState({
    defaultSettings: [],
    estimateNote: '',
  });
  React.useEffect(() => {
    setPostData({
      defaultSettings:
        costData &&
        [...costData]
          ?.sort((a, b) => {
            return a.id - b.id;
          })
          ?.map((el) => {
            return {
              cost: el.cost,
              id: el.id,
              description: el.description,
              estimateAdditionalCostOption: el.estimateAdditionalCostOption,
              isFixed: el.isFixed,
              rate: el.rate,
              rate2 : el.rate2,
              title: el.title,
            };
          }),
      estimateNote: noteData,
    });
  }, [costData, noteData]);

  console.log(postData);

  const RadioLine = ({ item, postData, index, setPostData }) => {
    const [rateInput, setRateInput] = React.useState(item.rate * 100);
    const [rateInput2, setRateInput2] = React.useState(item.rate2 * 100);
    const [descriptionValue, setDescriptionValue] = React.useState(item.description);
    const radioChange = (e) => {
      if (e.target.value === true) {
        setPostData(
          produce((draft) => {
            draft.defaultSettings[index].isFixed = true;
          }),
        );
      } else {
        setPostData(
          produce((draft) => {
            draft.defaultSettings[index].isFixed = false;
          }),
        );
      }
    };

    const selectChange = (e) => {
      setPostData(
        produce((draft) => {
          draft.defaultSettings[index].estimateAdditionalCostOption = e.target.value;
        }),
      );
    };

    return (
      <div className={styles.radio_line}>
        <span className={styles.line_title_span}>{item.title}</span>
        <div className={styles.line_radio_div}>
          <Radio.Group onChange={radioChange} value={item.isFixed}>
            <Space direction="vertical">
              <Radio value={true} style={{ marginRight: 50, color: 'black' }}>
                관리자가 설정
              </Radio>
              <Radio value={false}>사용자가 설정</Radio>
            </Space>
          </Radio.Group>
        </div>
        <div className={styles.line_select_div}>
          <div>
            <select
              onChange={selectChange}
              value={item.estimateAdditionalCostOption}
              disabled={item.isFixed === false ? true : false}
            >
              <option value={null} hidden>
                선택
              </option>
              {codeMapEACO?.map((el, index) => {
                return (
                  <option key={`${el.code}_${index}`} value={el.code}>
                    {el.title}
                  </option>
                );
              })}
            </select>
            <p>의</p>
            <div style={{width:120}}>
              <input

                type="number"
                max={100}
                onChange={(e) => {
                  if (e.target.value > 100) {
                    setRateInput(100);
                  } else if (e.target.value < 0) {
                    setRateInput(0);
                  } else {
                    setRateInput(e.target.value);
                  }
                }}
                onBlurCapture={() => {
                  setPostData(
                    produce((draft) => {
                      draft.defaultSettings[index].rate = rateInput / 100;
                    }),
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setPostData(
                      produce((draft) => {
                        draft.defaultSettings[index].rate = rateInput / 100;
                      }),
                    );
                  }
                }}
                value={rateInput}
                disabled={item.isFixed === false ? true : false}
              />
              <span>%</span>
            </div>
            {
              (item.id===3||item.id ===4)&& (
                  <>
                    <p>X</p>
                    <div>
                      <input
                          type="number"
                          max={100}
                          onChange={(e) => {
                            if (e.target.value > 100) {
                              setRateInput2(100);
                            } else if (e.target.value < 0) {
                              setRateInput2(0);
                            } else {
                              setRateInput2(e.target.value);
                            }
                          }}
                          onBlurCapture={() => {
                            setPostData(
                                produce((draft) => {
                                  draft.defaultSettings[index].rate2 = rateInput2 / 100;
                                }),
                            );
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              setPostData(
                                  produce((draft) => {
                                    draft.defaultSettings[index].rate2 = rateInput2 / 100;
                                  }),
                              );
                            }
                          }}
                          value={rateInput2}
                          disabled={item.isFixed === false ? true : false}
                      />
                      <span>%</span>
                    </div>
                  </>
        )
        }
      </div>
    <input
        type="text"
        placeholder="주기를 입력하세요"
            onChange={(e) => {
              setDescriptionValue(e.target.value);
            }}
            onBlurCapture={() => {
              setPostData(
                produce((draft) => {
                  draft.defaultSettings[index].description = descriptionValue;
                }),
              );
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setPostData(
                  produce((draft) => {
                    draft.defaultSettings[index].description = descriptionValue;
                  }),
                );
              }
            }}
            value={descriptionValue}
            disabled={item.isFixed === false ? true : false}
          />
        </div>
      </div>
    );
  };
  console.log("=>(index.js:212) RadioLine", RadioLine);

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="간접공사비율 및 견적서 표기문구 관리"
        style={{ width: 980, marginLeft: 'auto', marginRight: 'auto' }}
      />
      <div style={{ width: 900, marginLeft: 'auto', marginRight: 'auto' }}>
        <p>세부설정</p>
        <div style={{ height: 850, backgroundColor: 'white', padding: 20 }}>
          {postData?.defaultSettings &&
            [...postData?.defaultSettings]
              ?.sort((a, b) => {
                return a.id - b.id;
              })
              .map((item, index) => {
                return <RadioLine key={`costData_${index}`} item={item} setPostData={setPostData} index={index} />;
              })}
          <div className={styles.memo_section}>
            <p className={styles.memo_title_p}>견적메모</p>
            <textarea
              value={postData?.estimateNote}
              onChange={(e) => {
                setPostData(
                  produce((draft) => {
                    draft.estimateNote = e.target.value;
                  }),
                );
              }}
              className={styles.memo_desc_input}
            ></textarea>
          </div>
          <div className={styles.option_button_div}>
            <button
              onClick={() => {
                setPostData({
                  defaultSettings:
                    costData &&
                    [...costData]
                      ?.sort((a, b) => {
                        return a.id - b.id;
                      })
                      ?.map((el) => {
                        return {
                          cost: el.cost,
                          id: el.id,
                          description: el.description,
                          estimateAdditionalCostOption: el.estimateAdditionalCostOption,
                          isFixed: el.isFixed,
                          rate: el.rate,
                          rate2 : el.rate2,
                          title: el.title,
                        };
                      }),
                  estimateNote: noteData,
                });
              }}
              className={styles.reset_button}
            >
              초기화
            </button>
            <button
              onClick={async () => {
                try {
                  await restApi.put(`/estimate-cost-settings`, postData);
                  Modal.confirm({
                    title: '성공',
                    content: '성공적으로 저장되었습니다',
                    okText: '확인',
                    cancelButtonProps: { style: { display: 'none' } },
                    onOk: () => {
                      window.location.reload();
                    },
                  });
                } catch (error) {
                  Modal.error({
                    title: '실패',
                    content: '실패했습니다',
                  });
                }
              }}
              className={styles.save_button}
            >
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimateOptionsIndex;
