export const OPERATION_MAIN_POPUP_LIST = [
  {
    id: 1,
    title: '1초견적',
    sub_title: '오픈캠페인용',
    created_at: '2023-10-25',
    finished_at: '2023-10-25',
    order: 3,
    active: false,
  },
  {
    id: 2,
    title: '1초견적',
    sub_title: '오픈캠페인용',
    created_at: '2023-10-25',
    finished_at: '2023-10-25',
    order: 3,
    active: false,
  },
  {
    id: 3,
    title: '1초견적',
    sub_title: '오픈캠페인용',
    created_at: '2023-10-25',
    finished_at: '2023-10-25',
    order: 3,
    active: false,
  },
  {
    id: 4,
    title: '1초견적',
    sub_title: '오픈캠페인용',
    created_at: '2023-10-25',
    finished_at: '2023-10-25',
    order: 3,
    active: false,
  },
];
