import { Modal, PageHeader } from 'antd';
import React from 'react';
import styles from './index.module.less';
import { formatComma, getClientWidth, getRemainTime, getTimeAgo, parseImageUri } from '../../../common/utils';
import TableColHeader from '../../../components/table/TableColHeader';
import { HeartFilled, RadiusUpleftOutlined, WechatFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import produce from 'immer';
import useUpperLine from '../../../hooks/useUpperLine';
import Thumbnail from '../../../components/image/Thumbnail';
import { Option } from 'antd/lib/mentions';
import CommentIcon from '../../../components/image/CommentIcon';
import RoundButton from '../../../components/button/RoundButton';
import PaginateTable from '../../../components/table/PaginateTable';
import CurrentBid from '../../../components/card/CurrentBid/CurrentBid';
import SmallUserDetail from '../../../components/card/SmallUserDetail/SmallUserDetail';
import LocationInfo from '../../../components/card/LocationInfo/LocationInfo';
import SmallEstimate from '../../../components/card/SmallEstimate/SmallEstimate';
import { ExtraWrapper, FilterSelect } from '../../../components/template/page';
import ContentSlider from '../../../components/card/ContentSlider/ContentSlider';
import { useGetConstructionRequestDetail, useGetSelectedList } from '../../../apis/queries';

const ContentCard = React.memo(({ item, commonCodes, setVisibleDetailModal }) => {
  return (
    <div
      onClick={() => {
        setVisibleDetailModal(true);
      }}
      className={styles.slider_div}
      style={{
        cursor: 'pointer',
        backgroundImage: `url(${parseImageUri(item?.thumbnailId)})`,
      }}
    >
      <div className={styles.backdrop} />
      <div className={styles.slider_inner_div}>
        <p className={styles.category_p}>{commonCodes?.map[item?.area]}</p>
        <p className={styles.title_p}>{item?.description}</p>
        <div className={styles.flex_div}>
          <div className={styles.flex_div} style={{ alignItems: 'center' }}>
            <Thumbnail
              containerStyle={{
                width: 'max-content',
                display: 'flex',
                alignItems: 'center',
              }}
              className={styles.style_profile_img}
              id={item?.createdBy?.profileImageId}
            />
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <p className={styles.category_p} style={{ marginLeft: 10 }}>
                {item?.createdBy?.nickname}
              </p>
            </div>
          </div>
          <div className={styles.flex_div}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <HeartFilled style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p}>{item?.likeCount}</p>
            </div>
            <div
              style={{
                marginLeft: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CommentIcon style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p}>{item?.commentCount ?? 0}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const SelectedListIndex = () => {
  const { commonCodes } = useSelector((s) => s.common);
  const { params: upperParam, UpperLine } = useUpperLine();
  const [selectedId, setSelectedId] = React.useState(null);
  const [isOpenEstimateModal, setIsOpenEstimateModal] = React.useState(false);
  const [isProposalViewModal, setIsProposalViewlModal] = React.useState(false);
  const [filter, setFilter] = React.useState('createdAt_DESC');
  const initialParmas = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: 'DESC',
      sort: 'createdAt',
      keyword: null,
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParmas);
  //   const { data: selectedList } = useGetSelectedList({ params });
  const selectedList = [];
  //   const { data: detail } = useGetConstructionRequestDetail({
  //     id: selectedId?.id,
  //     config: {
  //       enabled: !!selectedId,
  //     },
  //   });
  const detail = [];
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 380);
  React.useEffect(() => {
    if (upperParam) {
      const { keyword } = upperParam;
      setParams(
        produce((draft) => {
          draft.keyword = keyword;
        }),
      );
    }
  }, [upperParam]);

  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 360);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);
  const filterItem = React.useMemo(
    () => [
      {
        label: '최신순',
        value: 'createdAt_DESC',
      },
      {
        label: '만료순',
        value: 'createdAt_ASC',
      },
      {
        label: '예산 높은순',
        value: 'totalPrice_DESC',
      },
      {
        label: '예산 낮은순',
        value: 'totalPrice_ASC',
      },
      {
        label: '입찰순',
        value: 'proposalCount_DESC',
      },
    ],
    [],
  );

  const columns = [
    {
      title: '상태',
      key: 'isPaid',
      dataIndex: 'isPaid',
      align: 'center',
      width: 60,
      render: (v) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div
              style={{
                width: '60px',
                height: '18px',
                padding: '6px 5px 3px',
                borderRadius: '3px',
                backgroundColor: v ? '#1e3d83' : '#de7e7e',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span
                style={{
                  fontSize: '10px',
                  fontWeight: '900',
                  color: '#fff',
                }}
              >
                {v ? '결제완료' : '결제대기'}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: '썸네일',
      key: 'thumbnailId',
      dataIndex: 'thumbnailId',
      align: 'center',
      width: 60,
      render: (data, record) => {
        return <Thumbnail id={record.thumbnailId} />;
      },
    },
    {
      title: <TableColHeader title={'견적명'} subTitle={'주소'} />,
      key: 'title',
      dataIndex: 'title',
      align: 'left',
      width: 170,
      render: (data, record) => (
        <>
          <TableColHeader title={data} subTitle={record.address} />
        </>
      ),
    },
    {
      title: '제안일시',
      key: 'createdAt',
      dataIndex: 'createdAt',
      align: 'center',
      width: 100,
      render: (data, record) => (
        <>
          <p style={{ color: '#767676', fontSize: 12 }}>{getTimeAgo(data)}</p>
          <p style={{ color: '#767676', fontSize: 12 }}>{moment(data).format('YYYY-MM-DD HH:mm')}</p>
        </>
      ),
    },

    {
      title: '제안금액',
      key: 'proposedPrice',
      dataIndex: 'proposedPrice',
      align: 'center',
      width: 100,
      render: (data, record) => (
        <>
          <p style={{ color: '#1e3d83', fontSize: 14, fontWeight: 'bold' }}>{data ? formatComma(data) : 0}</p>
          {/* <p style={{ color: '#767676', fontSize: 12, fontWeight: 'bold' }}>{data ? formatComma(data) : 0}</p> */}
        </>
      ),
    },
    {
      title: '마감일시',
      key: 'finishedAt',
      dataIndex: 'finishedAt',
      align: 'center',
      width: 100,
      render: (data, record) => {
        return (
          <>
            <p style={{ color: '#767676', fontSize: 12 }}>{getRemainTime(data)}</p>
            <p style={{ color: data.readAt ? '#1e3d83' : '#767676', fontSize: 12 }}>
              {moment(data.readAt).format('YYYY-MM-DD HH:mm')}
            </p>
          </>
        );
      },
    },
    {
      title: '결제',
      key: 'proposalInfo',
      dataIndex: 'proposalInfo',
      align: 'center',
      width: 80,
      render: (data, record) => {
        return (
          <>
            <RoundButton label={'결제'} reverse={true} disabled={record.isPaid ? true : false} />
          </>
        );
      },
    },
  ];
  const onRow = (record) => {
    return {
      onClick: () => {
        setSelectedId({ id: record.id, isPaid: record.isPaid });
      },
    };
  };

  return (
    <>
      <div>
        <div>
          <PageHeader className="site-page-header" title="선정 리스트" style={{ paddingBottom: 10 }} />
          <ExtraWrapper>
            {/* 필터 윗줄 */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 12.5,
              }}
            >
              {/* <UpperLine
                listKey={REALTIME_KEY}
                detailKey={CONSTRUCTION_DETAIL_KEY}
                // setRefresh={setRefreshDetail}
              /> */}
              <div style={{ marginLeft: 'auto', display: 'flex' }}>
                <RoundButton
                  onClick={() => {
                    if (!selectedId) {
                      Modal.error({
                        content: '확인할 제안서를 선택해주세요',
                      });
                    } else {
                      setIsProposalViewlModal(true);
                    }
                  }}
                  label={'제안서 보기'}
                  containerStyle={{ padding: '8px 20px 6px' }}
                  reverse={true}
                />
              </div>
            </div>
            {/* 필터 아랫줄 */}
            <div
              style={{
                width: '65%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <FilterSelect
                style={{ width: 100 }}
                value={filter}
                onChange={(v) => {
                  const [sort, order] = v.split('_');
                  setParams(
                    produce((draft) => {
                      draft.sort = sort;
                      draft.order = order;
                    }),
                  );
                  setFilter(v);
                }}
              >
                {filterItem?.map((v) => (
                  <Option value={v.value}>{v.label}</Option>
                ))}
              </FilterSelect>
            </div>
          </ExtraWrapper>
        </div>

        <div class={styles.estimate_table} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PaginateTable
            dataSource={[]}
            columns={columns}
            scroll={{ y: tableHeight - 40 }}
            data={selectedList}
            page={params?.page}
            setPage={(v) =>
              setParams(
                produce((draft) => {
                  draft.page = v;
                }),
              )
            }
            pageSize={params?.limit}
            setPageSize={(v) =>
              setParams(
                produce((draft) => {
                  draft.limit = v;
                }),
              )
            }
            onRow={onRow}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '65%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
            }}
          />
          {detail && (
            <div
              style={{
                width:500,
                overflowY: 'scroll',
                overflowX: 'hidden',
                height: document.body.clientHeight - 240,
                paddingLeft: 20,
                boxSizing: 'border-box',
                paddingBottom: 15,
              }}
            >
              <SmallUserDetail
                isFirst
                secret={!selectedId?.isPaid}
                data={detail}
                tree={commonCodes}
                user={detail?.estimateInfo?.createdBy}
              />
              <LocationInfo data={detail?.estimateInfo} />
              <div style={{ marginTop: 30 }}></div>
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  marginBottom: '10px',
                }}
              >
                선택 스타일 정보
              </span>
              <ContentSlider leftLine={true}>
                {detail?.estimateInfo?.areas?.map((v) => (
                  <ContentCard setVisibleDetailModal={() => {}} item={v?.subContent} commonCodes={commonCodes} />
                ))}
              </ContentSlider>
              <SmallEstimate
                secret={!selectedId?.isPaid}
                visibleModal={() => setIsOpenEstimateModal(true)}
                data={detail?.estimateInfo}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectedListIndex;
