import React from 'react';
import styles from './CommonStatistics.module.less';
import CommonStatisticsCard from './CommonStatisticsCard';
import StatisticsDetailCard from './StatisticsDetailCard';

const data = [
  {
    nowUser: '동접자 수',
    allUser: '가입자 수',
  },
  {
    name: '소비자',
    nowUser: '15,243',
    allUser: '125,254',
    percent: '25',
    value: 100,
    fill: '#7eccde',
  },
  {
    name: '시공사',
    nowUser: '15,243',
    allUser: '125,254',
    percent: '15',
    value: 100,
    fill: '#1e3d83',
  },
  {
    name: '유통사',
    nowUser: '15,243',
    allUser: '125,254',
    percent: '85',
    value: 100,
    fill: '#42b9ab',
  },
  {
    name: '인플루언서',
    nowUser: '15,243',
    allUser: '125,254',
    percent: '50',
    value: 100,
    fill: '#a2ce76',
  },
];
const CommonStatistics = () => {
  return (
    <>
      <h3 style={{ margin: '15px 0 5px' }}>운영통계</h3>
      <div className={styles.statistic_container}>
        <p style={{ fontSize: '14px', color: '#767676' }}>가입자 대비 동시 접속자 비율</p>
        <div className={styles.statistic_card_wrapper}>
          {data?.map((item, index) => {
            return (
              <div key={`commonStatistics ${index}`}>
                <CommonStatisticsCard data={item} />
                <p className={styles.bold_text} style={{ margin: 0, textAlign: 'center' }}>
                  {item?.nowUser}
                </p>
                <hr style={{ margin: '0 10px' }} />
                <p className={styles.thin_text} style={{ margin: 0, textAlign: 'center' }}>
                  {item?.allUser}
                </p>
              </div>
            );
          })}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span className={styles.user_label}></span>
          <span className={styles.user_label}>소비자</span>
          <span className={styles.user_label}>시공사</span>
          <span className={styles.user_label}>유통사</span>
          <span className={styles.user_label}>인플루언서</span>
        </div>
        <div>
          <StatisticsDetailCard />
        </div>
      </div>
    </>
  );
};

export default CommonStatistics;
