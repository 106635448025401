import React, { useEffect, useState } from 'react';
import { Checkbox, Modal, PageHeader, Switch } from 'antd';
import { restApi } from '../../../apis';
import moment from 'moment';
import PaginateTable from '../../../components/table/PaginateTable';
import CommonStatistics from '../../../components/card/CommonStatistics,js/CommonStatistics';
import styles from './trand.module.less';
import TableColHeader from '../../../components/table/TableColHeader';
import { ExtraWrapper, FilterButton, FilterSelect, FilterWhiteButton } from '../../../components/template/page';
import { useGetPosts } from '../../../apis/queries';
import Thumbnail from '../../../components/image/Thumbnail';
import { useQueryClient } from 'react-query';
import { POSTS_KEY, POST_DETAIL_KEY } from '../../../apis/queryKeys';
import produce from 'immer';
import PostCard from '../../../components/card/PostCard/PostCard';
import usePostDetailModal from '../../../hooks/usePostDetailModal';
import { useSelector } from 'react-redux';
import { Option } from 'antd/lib/mentions';
import WritePostModal from '../../../components/modal/WritePostModal';
import useUpperLine from '../../../hooks/useUpperLine';
import LowerLine from '../../../components/LowerLine';

const CollectionsMagazinesIndex = (props) => {
  const { showModal: showPostDetailModal, PostDetailModal } = usePostDetailModal();
  const queryClient = useQueryClient();
  const [isEdit, setIsEdit] = React.useState(false);
  const [idList, setIdList] = React.useState([]);
  const [checkedList, setCheckedList] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState(null);
  const { commonCodes } = useSelector((state) => state.common);
  const [visibleModal, setVisibleModal] = React.useState(false);
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      keyword: null,
      tag: null,
      order: 'DESC',
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  const filters = React.useMemo(() => {
    const tagList = commonCodes?.tree['TRTG']?.map((v) => [v.code, v.label]);
    tagList?.unshift([null, '전체']);
    return [
      {
        title: '태그',
        key: 'tag',
        options: tagList,
      },
    ];
  }, [commonCodes]);

  const { params: upperParam, UpperLine } = useUpperLine(filters);
  React.useEffect(() => {
    if (upperParam) {
      setParams((prev) => ({ ...prev, ...upperParam }));
    }
  }, [upperParam]);
  const { data: trendData } = useGetPosts({
    type: 'trends',
    params,
    config: {
      onSuccess: (data) => {
        if (!selectedId) {
          setSelectedId(data?.content[0].id);
        }
      },
    },
  });

  const data = React.useMemo(
    () =>
      trendData?.content?.map((v) => ({
        data: v,
        ...v,
      })),
    [trendData],
  );

  React.useEffect(() => {
    if (!!data) {
      const idList_ = data?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [data]);
  const columns = [
    {
      title: '선택',
      dataIndex: 'id',
      key: 'data',
      width: 40,
      align: 'center',
      render: (v) => (
        <Checkbox
          checked={checkedList?.includes(v)}
          onClick={() => {
            if (checkedList?.includes(v)) {
              setCheckedList(checkedList.filter((id) => id !== v));
            } else {
              setCheckedList([...checkedList, v]);
            }
          }}
        />
      ),
    },
    {
      title: '순서',
      dataIndex: 'data',
      key: 'index',
      width: 40,
      align: 'center',
      // sorter: true,
      render: (v, r, i) => {
        return <div style={{ display: 'flex', justifyContent: 'center' }}>{data?.length - i}</div>;
      },
    },
    {
      title: '썸네일',
      dataIndex: 'data',
      key: 'data',
      width: 40,
      align: 'center',
      render: (v) => {
        const { thumbnailId, id } = v;

        return (
          <Thumbnail
            onDelete={() => {
              Modal.confirm({
                content: '정말 삭제하시겠습니까?',
                okText: '확인',
                cancelText: '취소',
                onOk: async () => {
                  restApi.delete(`/trends/${id}`).then(async (res) => {
                    await queryClient.invalidateQueries(POSTS_KEY);
                    Modal.success({
                      content: '삭제되었습니다.',
                      okText: '확인',
                    });
                  });
                },
              });
            }}
            id={thumbnailId}
          />
        );
      },
    },
    {
      title: <TableColHeader title={'제목'} subTitle={'등록구분'} />,
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      width: 180,
      render: (data, record) => (
        <div style={{ flexDirection: 'column', display: 'flex' }}>
          <span
            style={{
              fontSize: '12px',
              fontWeight: '600',
              color: '#000',
            }}
          >
            {data}
          </span>
          <span
            style={{
              fontSize: '12px',
              color: '#767676',
            }}
          >
            {commonCodes?.map[record?.tag]}
          </span>
        </div>
      ),
    },

    {
      title: '등록자',
      dataIndex: 'createdBy.nickname',
      key: 'createdBy.nickname',
      width: 80,
      align: 'center',
      sorter: true,
      render: (v, r) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <span
              style={{
                fontSize: '12px',
                color: '#767676',
              }}
            >
              {r?.createdBy?.nickname}
            </span>
          </div>
        );
      },
    },
    {
      title: '등록일시',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 80,
      align: 'center',
      sorter: true,
      render: (v) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <span
              style={{
                fontSize: '12px',
                color: '#767676',
              }}
            >
              {v ? moment(v).format('YYYY-MM-DD HH:mm') : '-'}
            </span>
          </div>
        );
      },
    },
    {
      title: '좋아요',
      dataIndex: 'likeCount',
      key: 'likeCount',
      width: 60,
      sorter: true,
      showSorterTooltip: false,
      align: 'center',
      render: (v, r) => (
        <button
          onClick={() => {
            window.open(`/like/detail/${r.id}`, '_blank', '');
          }}
          style={{
            textDecoration: 'underline',
            background: 'none',
          }}
        >
          {v}
        </button>
      ),
    },
    {
      title: '댓글',
      dataIndex: 'commentCount',
      key: 'commentCount',
      width: 50,
      sorter: true,
      showSorterTooltip: false,
      align: 'center',
      render: (v, r) => (
        <button
          onClick={() => {
            window.open(`/comment/detail/${r.id}/CONTENT`, '_blank', '');
          }}
          style={{
            textDecoration: 'underline',
            background: 'none',
          }}
        >
          {v ?? '-'}
        </button>
      ),
    },
  ];

  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 280);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const onRow = React.useCallback(
    (record) => {
      return {
        onClick: (event) => {
          setSelectedId(record.id);
        },
      };
    },
    [trendData],
  );

  const handleDeleteItems = (ids) => {
    Modal.confirm({
      content: '선택된 항목들을 삭제하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk: () => {
        restApi
          .delete('/contents', {
            data: {
              ids,
            },
          })
          .then(async (res) => {
            await queryClient.invalidateQueries(POSTS_KEY);
            Modal.success({
              content: '삭제되었습니다.',
              okText: '확인',
            });
          });
      },
    });
  };

  return (
    <>
      <div>
        <PageHeader className="site-page-header" title="트렌드 관리" style={{ paddingBottom: 10 }} />
        <ExtraWrapper>
          {/* 필터 윗줄 */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 4.5,
              justifyContent: 'space-between',
              width: '100%',
              position: 'relative',
            }}
          >
            <UpperLine
              containerStyle={{ width: '65%' }}
              listKey={POSTS_KEY}
              detailKey={POST_DETAIL_KEY}
              childrenRight={
                <div
                  style={{
                    marginLeft: 15,
                    justifyContent: 'flex-end',
                    display: 'flex',
                    position: 'relative',
                    // width: '30%',
                  }}
                ></div>
              }
            />
            <div style={{ display: 'flex', gap: '10px' }}>
              <FilterWhiteButton
                onClick={() => {
                  setIsEdit(true);
                  setVisibleModal(true);
                }}
              >
                수정하기
              </FilterWhiteButton>
              <FilterButton
                onClick={() => {
                  setIsEdit(false);
                  setVisibleModal(true);
                }}
              >
                새글쓰기
              </FilterButton>
            </div>
          </div>
          {/* 필터 아랫줄 */}
          <LowerLine
            containerStyle={{ width: '65%' }}
            onCheck={() => {
              if (idList.length === checkedList.length) {
                setCheckedList([]);
              } else {
                setCheckedList(idList);
              }
            }}
            checked={idList.length === checkedList.length}
            left={
              <div
                onClick={() => {
                  handleDeleteItems(checkedList);
                }}
                style={{ cursor: 'pointer' }}
                className={styles.filterWrapper}
              >
                <img
                  src={require('../../../assets/delete.png')}
                  alt="delete"
                  style={{
                    width: '18.2px',
                    height: '21.2px',
                  }}
                />
                <span className={styles.filterText}>항목삭제</span>
              </div>
            }
          >
            <div style={{ width: 180 }}>

            </div>
          </LowerLine>
        </ExtraWrapper>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PaginateTable
            dataSource={data}
            columns={columns}
            scroll={{ y: tableHeight - 40 }}
            data={trendData}
            page={params?.page}
            setParams={setParams}
            setPage={(v) =>
              setParams(
                produce((draft) => {
                  draft.page = v;
                }),
              )
            }
            pageSize={params?.limit}
            setPageSize={(v) =>
              setParams(
                produce((draft) => {
                  draft.limit = v;
                }),
              )
            }
            customHeight={false}
            onRow={onRow}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '65%',
              height: document.body.clientHeight - 500,
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
            }}
          />
          <div
            style={{
              width: 550,
              paddingLeft: 20,
              overflowY: 'scroll',
              height: document.body.clientHeight - 240,
              paddingBottom: 15,
            }}
          >
            <p
              style={{
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              컨텐츠 미리보기
            </p>
            <PostCard
              type="trends"
              id={selectedId}
              onClickMore={() => {
                showPostDetailModal(true);
              }}
            />
            <CommonStatistics />
          </div>
        </div>
      </div>
      <PostDetailModal id={selectedId} type="trends" />
      <WritePostModal
        visible={visibleModal}
        setVisible={setVisibleModal}
        handleOk={() => {}}
        handleCancel={() => {
          setVisibleModal(false);
          setIsEdit(false);
        }}
        type="trends"
        isEdit={isEdit}
        selectedId={selectedId}
      />
    </>
  );
};

export default CollectionsMagazinesIndex;
