import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import produce from 'immer';
import moment from 'moment/moment';
import React from 'react';
import { restApi } from '../apis';
import { useGetPosts, useGetSavedPosts } from '../apis/queries';
import { getTimeAgo, parseImageUri } from '../common/utils';
import RoundButton from '../components/button/RoundButton';
import Thumbnail from '../components/image/Thumbnail';
import TableColHeader from '../components/table/TableColHeader';
import { FilterInput } from '../components/template/page';
import PaginateTable from '../components/table/PaginateTable';
import { dummyData } from '../data/dummy';
import UserDetail from '../components/card/userdetail/UserDetail';
import followImage from '../assets/dummy/user/04.jpg';

const useQuestionBoard = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const UserQuestionBoardModal = ({ id, tableHeight }) => {
    const [params, setParams] = React.useState({ limit: 50 });
    const { data, refetch } = useGetSavedPosts({ id, params, config: { enabled: !!isModalOpen } });

    const handleDelete = async (id) => {
      if (!window.confirm('삭제하시겠습니까?')) return;
      try {
        await restApi.delete('/posts/ask-me', { data: { ids: [id] } });
        refetch();
      } catch (e) {
        alert(e?.response?.data?.message || '서버와 연결이 올바르지 않습니다.');
      }
    };

    const columns = React.useMemo(
      () => [
        {
          title: <TableColHeader title="제목" />,
          dataIndex: 'title',
          key: 'title',
          width: 120,
          align: 'center',
          render: (v, r) => (
            <div
              style={{
                fontWeight: 'bold',
                marginLeft: 25,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <div style={{ marginRight: 10 }}>
                <Thumbnail containerStyle={{ width: 50 }} imageStyle={{ width: 50 }} id={r?.thumbnailId} />
              </div>
              <TableColHeader title={v} />{' '}
            </div>
          ),
        },
        {
          title: '작성일시',
          dataIndex: 'createdAt',
          key: 'createdAt',
          width: 60,
          align: 'center',
          render: (v) => (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
              <span style={{}}>{getTimeAgo(v)}</span>
              <span style={{}}>{moment(v).format('YYYY-MM-DD HH:mm')}</span>
            </div>
          ),
        },
        {
          title: '삭제',
          dataIndex: 'id',
          key: 'id',
          width: 20,
          align: 'center',
          render: (v) => (
            <Button
              style={{ border: 'none' }}
              onClick={() => handleDelete(v)}
              // label="삭제"
              containerStyle={{
                width: '100px',
              }}
              deleteButton
            >
              <DeleteOutlined style={{ fontSize: 20 }} />
            </Button>
          ),
        },
      ],
      [],
    );
    return (
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={true}
        okText={'확인'}
        cancelText={'수정'}
      >
        <div style={{ padding: '35px 25px 0 25px' }}>
          <p style={{ fontSize: 24, fontWeight: 600 }}>무엇이든 물어보세요</p>
          <PaginateTable
            page={params.page}
            setPageSize={(v) =>
              setParams(
                produce((draft) => {
                  draft.limit = v;
                }),
              )
            }
            pageSize={params.limit}
            setPage={(v) =>
              setParams(
                produce((draft) => {
                  draft.page = v;
                }),
              )
            }
            customHeight={true}
            // dataSource={data}
            columns={columns}
            dataSource={data?.content}
            data={data}
            scroll={{ y: tableHeight - 200 }}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
            }}
          />
        </div>
      </Modal>
    );
  };
  return { showModal, setIsModalOpen, UserQuestionBoardModal };
};

export default useQuestionBoard;
