import produce from 'immer';
import React from 'react';
import MessagingModal from '../../modal/messaging/list';
import WriteMessagingModal from '../../modal/messaging/write';
import styles from './UserDetail.module.less';
import {InputNumber, Switch} from 'antd';
import {
  HeartFilled,
  ShopOutlined,
  StarFilled,
  StarOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import useReviewModal from '../../../hooks/useReviewModal';
import {CommonCodeContext} from '../../../context/CommonCodeContext';
import {parseImageUri} from '../../../common/utils';
import Thumbnail from '../../image/Thumbnail';
import assets from '../../../assets';
import {useGetPartnerUserDetail, useGetRegions} from '../../../apis/queries';
import {useQueryClient} from 'react-query';
import {PARTMER_USER_DETAIL_KEY} from '../../../apis/queryKeys';
import {restApi} from '../../../apis';
import useBusinessNumberModal from '../../../hooks/useBusinessNumberModal';
import AgencyInsurance from '../../modal/AgencyInsurance';
import {useNavigate} from 'react-router-dom';
import useUserGoodsModal from '../../../hooks/useUserGoodsModal';

const levelConeainer = [
  ['바바업력', 'experienceRate'],
  ['컨텐츠', 'contentRate'],
  ['답변속도', 'commentReplyRate'],
  ['사업지수', 'companyBusinessRate'],
];
const lifeStyle = ['MZ세대', '회사원', '2인가족', '없음', '역마살', '미드센추리'];
const PartnerUserDetail = ({
  guideAction,
  styleAction,
  portfolioAction,
  followAction,
  goodsAction,
  trendAction,
  regionsAction,
  commentAction,
  questionBoardAction,
  expertBoardAction,
  businessNumberAction,
  findCustomerAction,
  saveStyleAction,
  saveEstimateAction,
  userType,
  agency,
  detailId,
}) => {
  const {
    codeMapRegion,
    codeMapPTAT,
    codeMapCBER,
    codeMapCCRT,
    codeMapCRRT,
    codeMapCBRT,
  } =
      React.useContext(CommonCodeContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    data: partnerDetailData,
    isFetchedAfterMount,
      refetch: refetchPartnerDetail,
  } = useGetPartnerUserDetail({
    id: detailId,
    params: {},
  });


  //사업자 번호 모달
  const {
    showModal: showBusinessNumberModal,
    setIsModalOpen: setShowBusinessNumberModal,
    BusinessNumberModal,
  } = useBusinessNumberModal();
  //등록상품모달
  const {
    showModal: showGoodsModal,
    setIsModalOpen: serGoodsModalOpen,
    UserGoodsModal,
  } = useUserGoodsModal();
  //보험관련 모달
  const [insuranceModal, setInsuranceModal] = React.useState();

  const [messaging, setMessaging] = React.useState(null);


  const [hasRate, setHasRate] = React.useState(0);
  const [rate, setRate] = React.useState(false);

  React.useEffect(() => {
    setHasRate(partnerDetailData?.setRate || false);
    setRate(partnerDetailData?.rate || 0);
  }, [partnerDetailData]);


  const [messageId, setMessageId] = React.useState(null);

  const myPageList = [
    {
      title: '견적작성가능여부',
      switchView: true,
      type: 'agencies',
      disabled: true,
      checked: !partnerDetailData?.limitCreateProposal,
    },
    {
      title: '사업자등록번호',
      customView:
          agency === 'influencer' ? (
              '미입력'
          ) : (
              <div style={{width: '100%', textAlign: 'right'}}>
            <span
                style={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#1e3d83',
                  // textDecoration: 'underline',
                }}
            >
              {partnerDetailData?.corporationInfo?.businessNumber?.replace(
                  /(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')}
            </span>
              </div>
          ),
      type: 'agencies',
      disabled: agency === 'influencer' ? true : false,
      buttonAction: showBusinessNumberModal,
    },
    {
      title: '지역설정',
      customView:
          agency === 'influencer'
              ? '미입력'
              : partnerDetailData?.regions.length < 1
                  ? '없음'
                  : codeMapRegion[partnerDetailData?.regions[0]]?.label
                      ? `${codeMapRegion[partnerDetailData?.regions[0]]?.label} ${
                          codeMapRegion[partnerDetailData?.regions[0]]?.children[partnerDetailData?.regions[1]]
                              ? codeMapRegion[partnerDetailData?.regions[0]]?.children[partnerDetailData?.regions[1]]
                              : ''
                      }`
                      : '전체지역 미설정',
      type: 'agencies',
      disabled: agency === 'influencer' ? true : false,
      buttonAction: regionsAction,
    },
    {

      checked: hasRate,

      checkAction: (checked) => {

        // setRate(0);
        // try {
          setHasRate(checked);

          restApi.put(
              `/accounts/partner/${detailId}/setting/rate`,
              {setRate:checked, rate:rate||0}
          ).catch((e)=>{
            alert(e.response.data.message)
          }).finally(async ()=>{
          refetchPartnerDetail();

        });
      },
      title: '개별 서비스비용 설정',
      middleSwitch: userType == 'agencies' && true,
      type: 'agencies',
      inputView: <div>

        <button
            className={styles.more_button}
            style={{width: 55, padding: '0 5px',marginRight:10,marginLeft:-20}}
            onClick={() => {
              restApi.put(
                  `/accounts/partner/${detailId}/setting/rate`,
                  {setRate: hasRate, rate: rate || 0},
                  alert('수수료가 설정되었습니다.')
              ).catch((e) => {
                alert(e.response.data.message);
              }).finally(async () => {
                refetchPartnerDetail();

              });
            }}
            disabled={!hasRate}
        >
          저장
        </button>
        <InputNumber

            style={{width: 100, marginRight: 5, marginLeft: 'auto'}}
            min={0}
            max={100}

            addonAfter={<span
                style={{
                  color: '#767676',
                  fontSize: 14,
                  lineHeight: 2,
                }}>%</span>}
            disabled={!hasRate}

            value={`${rate || 0}`}
            onChange={(e) =>
                setRate(e)
            }

            className={styles.percentage_input}
            type="number"
            placeholder="수수료를 기입해주세요"
        />

      </div>,
      buttonAction: userType,
    },
    {
      title: '보험관련',
      buttonView: true,
      buttonAction: () => {
        setInsuranceModal(true);
      },
      type: 'agencies',
      disabled: agency === 'influencer' ? true : false,
    },
    {
      title: '고객찾기',
      middleSwitch: userType == 'agencies' && true,
      checked: !partnerDetailData?.limitFindCustomer,
      disabled: agency === 'influencer' ? true : false,
      putUrl: 'find-customer',
      buttonView: true,
      buttonAction: () => {
        navigate('/expert/proposal');
      },
      type: 'agencies',
    },
    {
      title: '등록상품',
      newItem: partnerDetailData?.productCount,
      buttonView: true,
      buttonAction: showGoodsModal,
      type: 'agencies',
    },
    {
      title: '견적',
      newItem: 3,
      buttonView: true,
      buttonAction: saveEstimateAction,
      type: 'customer',
    },
    {
      title: '공사도움받기',
      newItem: partnerDetailData?.constructionRequestCount,
      buttonView: true,
      type: 'customer',
    },
    {
      title: '스타일',
      newItem: partnerDetailData?.styleCount,
      middleSwitch: userType == 'agencies' && true,
      checked: !partnerDetailData?.limitCreateStyle,
      disabled: agency === 'influencer' ? true : false,
      putUrl: 'create-styles',
      buttonAction: userType == 'agencies' ? styleAction : saveStyleAction,
      buttonView: true,
      type: userType,
    },
    {
      title: '포트폴리오',
      newItem: partnerDetailData?.portfolioCount,
      middleSwitch: userType == 'agencies' && true,
      checked: !partnerDetailData?.limitCreatePortfolio,
      disabled: agency === 'influencer' ? true : false,
      putUrl: 'create-portfolios',
      buttonAction: portfolioAction,
      buttonView: true,
      type: userType,
    },
    {
      title: '트렌드',
      newItem: partnerDetailData?.trendCount,
      buttonView: true,
      buttonAction: trendAction,
      type: userType,
    },
    {
      title: '공사가이드',
      newItem: partnerDetailData?.constructionGuideCount,
      buttonAction: guideAction,
      buttonView: true,
      type: userType,
    },
    {
      title: '컨설팅',
      newItem: partnerDetailData?.consultingCount,
      buttonView: true,
      type: 'agencies',
    },
    {
      title: '전문가들의 수다',
      newItem: partnerDetailData?.postCount,
      buttonAction: expertBoardAction,
      buttonView: true,
      type: 'agencies',
    },
    {
      title: '댓글',
      newItem: partnerDetailData?.commentCount,
      buttonAction: commentAction,
      buttonView: true,
      type: userType,
    },
    {
      title: '다이렉트 메시징',
      // middleSwitch: userType == 'agencies' && true,
      type: 'agencies',

      inputView: <div>
        <button
            className={styles.more_button}
            onClick={()=>{
              setMessageId(true)
            }}
            // onClick={item?.buttonAction
            //     ? item?.buttonAction
            //     : () => {}}
            // disabled={item?.disabled}
        >
          작성
        </button>
      </div>,
      buttonView: '리스트',
      buttonAction: async  ()=>{
        setMessaging(detailId);
      },
    },
  ];
  const {showModal: showReviewModal, ReviewModal} = useReviewModal();
  return (
      <>
        <h3 style={{margin: '0 0 5px'}}>고객정보</h3>
        <div className={styles.user_detail}>
          <div
              className={styles.side_line}
              style={{
                backgroundColor: userType === 'agencies'
                    ? '#1e3d83'
                    : '#7eccde',
              }}
          ></div>
          <div style={{paddingBottom: 10, flex: 1}}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              padding: '20px 50px',
            }}>
              <div style={{marginRight: '20px'}}>
                <div
                    style={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '50%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                >
                  <img
                      src={
                        partnerDetailData?.profileImageId
                            ? parseImageUri(partnerDetailData?.profileImageId)
                            : assets.icons.blank_profile
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                      }}
                  />
                </div>
                <div
                    className={styles.label}>{codeMapPTAT[partnerDetailData?.partnerType]}</div>
              </div>
              <div>
                <p className={styles.name}>{partnerDetailData?.nickname}</p>
                <p className={styles.email}>{partnerDetailData?.email}</p>
                <p className={styles.phone}>{partnerDetailData?.phone}</p>
              </div>
            </div>
            <div className={styles.level_container}>
              {levelConeainer?.map((item, index) => {
                return (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }} key={`level ${index}`}>
                  <span
                      style={{
                        fontSize: '11px',
                        color: '#767676',
                        fontWeight: '600',
                      }}
                  >
                    {item[0]}
                  </span>
                      {index === 0 && partnerDetailData && (
                          <p style={{color: '#767676'}}>
                      <span style={{
                        color: '#767676',
                        display: 'inline-block',
                        padding: 6,
                      }}>
                        LV.{partnerDetailData[item[1]]?.slice(6)}
                      </span>
                            <span
                                style={{
                                  fontSize: 10,
                                  fontWeight: 500,
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                            >
                        {!partnerDetailData
                            ? '미확인'
                            : !partnerDetailData[item[1]]
                                ? '미확인'
                                : codeMapCBER[partnerDetailData[item[1]]]}
                      </span>
                          </p>
                      )}
                      {index === 1 && partnerDetailData && (
                          <p style={{color: '#767676'}}>
                      <span style={{
                        color: '#767676',
                        display: 'inline-block',
                        padding: 6,
                      }}>
                        LV.{partnerDetailData[item[1]]?.slice(6)}
                      </span>
                            <span
                                style={{
                                  fontSize: 10,
                                  fontWeight: 500,
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                            >
                        {!partnerDetailData
                            ? '미확인'
                            : !partnerDetailData[item[1]]
                                ? '미확인'
                                : codeMapCCRT[partnerDetailData[item[1]]]}
                      </span>
                          </p>
                      )}
                      {index === 2 && partnerDetailData && (
                          <p style={{color: '#767676'}}>
                      <span style={{
                        color: '#767676',
                        display: 'inline-block',
                        padding: 6,
                      }}>
                        LV.{partnerDetailData[item[1]]?.slice(6)}
                      </span>
                            <span
                                style={{
                                  fontSize: 10,
                                  fontWeight: 500,
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                            >
                        {!partnerDetailData
                            ? '미확인'
                            : !partnerDetailData[item[1]]
                                ? '미확인'
                                : codeMapCRRT[partnerDetailData[item[1]]]}
                      </span>
                          </p>
                      )}
                      {index === 3 && partnerDetailData && (
                          <p style={{color: '#767676'}}>
                      <span style={{
                        color: '#767676',
                        display: 'inline-block',
                        padding: 6,
                      }}>
                        LV.{partnerDetailData[item[1]]?.slice(6)}
                      </span>
                            <span
                                style={{
                                  fontSize: 10,
                                  fontWeight: 500,
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                            >
                        {!partnerDetailData
                            ? '미확인'
                            : !partnerDetailData[item[1]]
                                ? '미확인'
                                : codeMapCBRT[partnerDetailData[item[1]]]}
                      </span>
                          </p>
                      )}
                    </div>
                );
              })}
            </div>
            {userType === 'agencies' && !agency && (
                <div style={{
                  width: '100%',
                  padding: '0 70px',
                  marginTop: 30,
                  marginBottom: 20,
                }}>
                  <div className={styles.agencies_score}>
                    <div className={styles.inner_div}>
                      <p className={styles.star_p}>
                        <StarOutlined style={{
                          fontSize: 18,
                          color: '#767676',
                          margin: '0 2px',
                        }}/>
                        <StarOutlined style={{
                          fontSize: 18,
                          color: '#767676',
                          margin: '0 2px',
                        }}/>
                        <StarOutlined style={{
                          fontSize: 18,
                          color: '#767676',
                          margin: '0 2px',
                        }}/>
                        <StarOutlined style={{
                          fontSize: 18,
                          color: '#767676',
                          margin: '0 2px',
                        }}/>
                        <StarOutlined style={{
                          fontSize: 18,
                          color: '#767676',
                          margin: '0 2px',
                        }}/>
                      </p>
                      <p className={styles.star_score_p}>0</p>
                    </div>
                    <div className={styles.inner_div} style={{width: '40%'}}>
                      <p className={styles.flex_p}>
                        <span className={styles.score_title_span}>디자인</span>
                        <span className={styles.score_score_span}>0</span>
                      </p>
                      <p className={styles.flex_p}>
                        <span className={styles.score_title_span}>공사품질</span>
                        <span className={styles.score_score_span}>0</span>
                      </p>
                      <p className={styles.flex_p}>
                        <span className={styles.score_title_span}>하자관리</span>
                        <span className={styles.score_score_span}>0</span>
                      </p>
                    </div>
                  </div>
                  <p onClick={() => showReviewModal()} style={{
                    cursor: 'pointer',
                    color: '#1e3d83',
                    fontWeight: 'bold',
                  }}>
                    3개의 소비자 리뷰 보기
                  </p>
                </div>
            )}
            {agency === 'distribution' && (
                <div style={{
                  width: '100%',
                  padding: '0 70px',
                  marginTop: 30,
                  marginBottom: 20,
                }}>
                  <div className={styles.agencies_score}
                       style={{height: 80, padding: '8px 0px'}}>
                    <div style={{flex: 1, textAlign: 'center'}}>
                      <p style={{fontSize: 12, color: '#767676'}}>팔로어</p>
                      <UserAddOutlined style={{fontSize: 26}}/>
                      <p style={{
                        fontSize: 12,
                        color: '#767676',
                        fontWeight: 'bold',
                      }}>325</p>
                    </div>
                    <div style={{
                      width: 1,
                      height: '100%',
                      backgroundColor: 'black',
                    }}></div>
                    <div style={{flex: 1, textAlign: 'center'}}>
                      <p style={{fontSize: 12, color: '#767676'}}>등록상품</p>
                      <ShopOutlined style={{fontSize: 26}}/>
                      <p style={{
                        fontSize: 12,
                        color: '#767676',
                        fontWeight: 'bold',
                      }}>111,254</p>
                    </div>
                  </div>
                </div>
            )}
            {agency === 'influencer' && (
                <div style={{
                  width: '100%',
                  padding: '0 70px',
                  marginTop: 30,
                  marginBottom: 20,
                }}>
                  <div className={styles.agencies_score}
                       style={{height: 80, padding: '8px 0px'}}>
                    <div style={{flex: 1, textAlign: 'center'}}>
                      <p style={{fontSize: 12, color: '#767676'}}>팔로어</p>
                      <UserAddOutlined style={{fontSize: 26}}/>
                      <p style={{
                        fontSize: 12,
                        color: '#767676',
                        fontWeight: 'bold',
                      }}>325</p>
                    </div>
                    <div style={{
                      width: 1,
                      height: '100%',
                      backgroundColor: 'black',
                    }}></div>
                    <div style={{flex: 1, textAlign: 'center'}}>
                      <p style={{fontSize: 12, color: '#767676'}}>누적 좋아요</p>
                      <HeartFilled style={{fontSize: 26, color: 'red'}}/>
                      <p style={{
                        fontSize: 12,
                        color: '#767676',
                        fontWeight: 'bold',
                      }}>111,254</p>
                    </div>
                  </div>
                </div>
            )}
            <div>
              {myPageList.map((item, index) => {
                return (
                    item?.type == userType && (
                        <div key={`myDetail ${index}`}
                             className={styles.my_page_list}>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '40%',
                          }}>
                            <span style={{color: '#767676'}}>{item.title}</span>
                            <span
                                className={styles.new_item}>{item.newItem}</span>
                          </div>
                          {item?.middleSwitch && (
                              <Switch
                                  disabled={item?.disabled}
                                  style={{
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                  }}
                                  checked={item?.checked}

                                  onClick={async () => {
                                    if (item.putUrl) {

                                      await restApi.put(
                                          `/accounts/partner/${detailId}/limit/${item?.putUrl}?isActive=${!item?.checked}`,
                                      );
                                      await queryClient.invalidateQueries(
                                          [PARTMER_USER_DETAIL_KEY]);
                                    } else {
                                      item?.checkAction(!item.checked);
                                    }
                                  }}
                              />
                          )}
                          {item?.inputView && (
                              item?.inputView
                          )}
                          {item?.buttonView && (
                              <button
                                  className={styles.more_button}
                                  onClick={item?.buttonAction
                                      ? item?.buttonAction
                                      : () => {}}
                                  disabled={item?.disabled}
                              >
                                {typeof item?.buttonView ==='boolean'?'내용보기':item?.buttonView}
                              </button>
                          )}
                          {item?.switchView && (
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: 34,
                                marginLeft: 'auto',
                              }}>
                                <Switch disabled={item?.disabled}
                                        checked={item?.checked}/>
                              </div>
                          )}
                          {item?.customView && (
                              <button
                                  onClick={item?.buttonAction
                                      ? item?.buttonAction
                                      : () => {}}
                                  disabled={item?.disabled}
                                  style={{
                                    marginLeft: 'auto',
                                    height: 34,
                                    color: agency === 'influencer'
                                        ? '#c9c9c9'
                                        : '#1e3d83',
                                    fontWeight: 'bold',
                                  }}
                              >
                                {item?.customView}
                              </button>
                          )}
                        </div>
                    )
                );
              })}
            </div>
          </div>
        </div>
        <ReviewModal/>
        <BusinessNumberModal
            imageId={partnerDetailData?.corporationInfo?.businessLicenseImageId}
            businessNumber={partnerDetailData?.corporationInfo?.businessNumber}
        />
        <UserGoodsModal userType={userType} agency={agency}/>
        <AgencyInsurance isModalOpen={insuranceModal} data={partnerDetailData}
                         setIsModalOpen={setInsuranceModal}/>
        <MessagingModal setMessageId={setMessageId}  setData={setMessaging} data={messaging}/>
        <WriteMessagingModal messageId={messageId} setMessageId={setMessageId}  account={partnerDetailData} />
      </>
  );
};

export default PartnerUserDetail;
