import React from 'react';
import { PieChart, Pie } from 'recharts';

const data = [{ name: 'Group A', value: 100 }];

export default function CommonStatisticsCard({ data }) {
  const angle = 360 - (Number(data?.percent) * 360) / 100;
  return (
    <>
      <div style={{ position: 'relative' }}>
        <PieChart width={60} height={80}>
          <Pie
            activeIndex={'hihi'}
            dataKey="value"
            aspect={2}
            data={[data]}
            cx={'50%'}
            cy={'50%'}
            innerRadius={20}
            outerRadius={25}
            paddingAngle={angle}
            blendStroke={true}
            fill={data?.fill}
          />
        </PieChart>
        {data?.percent && (
          <p
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              fontSize: '15px',
              fontWeight: 'bold',
              color: '#1e3d83',
            }}
          >
            {data?.percent}%
          </p>
        )}
      </div>
    </>
  );
}
