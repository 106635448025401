import { Modal, Switch } from 'antd';
import produce from 'immer';
import React from 'react';
import {restApi} from '../../apis';
import RoundButton from '../button/RoundButton';

const AgencyInsurance = ({ data, tableHeight, isModalOpen, setIsModalOpen }) => {

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  React.useEffect(() => {
    setInsurance(data?.insurance);
  },[data])
  const [insurance, setInsurance] = React.useState(data?.insurance);
  const handleOk = async ()=>{
    try {
      await restApi.put(`/accounts/partner/${data?.id}/setting/insurance`,insurance);
      alert('보증보험 설정이 완료되었습니다.')
      window.location.reload();
    }catch (e){
      alert(e?.response?.data?.message || '서버와 연결이 올바르지 않습니다.');
    }

  }
  return (
    <Modal
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 200,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
      }}
      closable={true}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <RoundButton
            reverse
            onClick={handleOk}
            label="저장"
            containerStyle={{
              width: '100px',
            }}
          />
        </div>
      }
      // onCancel={handleCancel}
      destroyOnClose={true}
      maskClosable={true}
      okText={'확인'}

      // cancelText={'수정'}
    >
      <div style={{ padding: '70px 100px' }}>
        <p style={{ fontSize: 24, fontWeight: 600, marginBottom: 80 }}>보증보험 설정</p>
        <p style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
          <span style={{ fontSize: 16, color: '#767676' }}>보험구분</span>
          <span style={{ fontSize: 16, color: '#767676' }}>가능/불가</span>
        </p>
        <p style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
          <span style={{ fontSize: 16, color: '#767676' }}>계약이행 보증보험</span>
          <Switch
            checked={insurance?.contractGuaranteeInsurance}
            onChange={(e) =>
              setInsurance(
                produce((draft) => {
                  draft.contractGuaranteeInsurance = e;
                }),
              )
            }
          />
        </p>
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontSize: 16, color: '#767676' }}>하자이행 보증보험</span>
          <Switch
            onChange={(e) =>
              setInsurance(
                produce((draft) => {
                  draft.defectGuaranteeInsurance = e;
                }),
              )
            }
            checked={insurance?.defectGuaranteeInsurance}
          />
        </p>
      </div>
    </Modal>
  );
};

export default AgencyInsurance;
