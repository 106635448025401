import { Modal, PageHeader, Pagination } from 'antd';
import React from 'react';
import { useGetLikedFollowsList, useGetUserDetail, useLikeList } from '../../apis/queries';
import { parseImageUri } from '../../common/utils';
import produce from 'immer';
import PartnerUserDetail from '../../components/card/userdetail/PartnerUserDetail';
import { useParams } from 'react-router-dom';
import { ExtraWrapper } from '../../components/template/page';
import UserDetail from '../../components/card/userdetail/UserDetail';

const LikeIndex = () => {
  const param = useParams();
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  const [partnerDetail, setPartnerDetail] = React.useState({});
  const { data } = useLikeList({
    params,
    id: param?.id,
    config: {
      enabled: !!param?.id,
    },
  });
  return (
    <div style={{ height: document.body.clientHeight - 200 }}>
      <PageHeader className="site-page-header" title="좋아요" style={{ paddingBottom: 10 }} />
      <ExtraWrapper>
        {/* 필터 윗줄 */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 4.5,
            marginRight: 20,
          }}
        ></div>
        {/* 필터 아랫줄 */}
        <div style={{ width: 870, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}></div>
      </ExtraWrapper>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '65%' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: 'white',
              overflowY: 'auto',
              position: 'relative',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                cursor: 'pointer',
                padding: '50px 30px',
              }}
            >
              {data?.content?.map((item, index) => {
                console.log(item);
                return (
                  <div
                    key={`goodsList ${index}`}
                    style={{ width: '20%', textAlign: 'center', margin: '25px 0' }}
                    onClick={() => {
                      console.log(123123);
                      setPartnerDetail(
                        produce((draft) => {
                          draft.id = item.id;
                          draft.type = item.type;
                        }),
                      );
                    }}
                  >
                    {item && (
                      <>
                        <img
                          src={parseImageUri(item?.profileImageId)}
                          alt=""
                          style={{ width: 80, height: 80, borderRadius: '50%' }}
                        />
                        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{item?.nickname}</p>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
            <div
              style={{
                paddingBottom: '20px',
                position: 'absolute',
                bottom: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Pagination
                showSizeChanger
                onChange={(page, pageSize) => {
                  setParams(
                    produce((draft) => {
                      draft.page = page - 1;
                    }),
                  );
                }}
                defaultCurrent={1}
                current={params?.page + 1}
                pageSize={params?.limit}
                onShowSizeChange={(current, size) => {
                  setParams(
                    produce((draft) => {
                      draft.limit = size;
                    }),
                  );
                }}
                total={data?.totalElements}
              />
            </div>
          </div>
        </div>
        <div style={{ width: 450, height: '100%', overflowY: 'scroll', paddingBottom: 50, paddingLeft: 20 }}>
          {/* TODO : 시공사 카드로 교체 */}
          {partnerDetail?.id && (
            // <PartnerUserDetail detailId={partnerDetail?.id} agency={partnerDetail?.type} userType={'agencies'} />
            <UserDetail selectedId={partnerDetail?.id} userType={partnerDetail?.type} />
          )}
        </div>
      </div>
    </div>
  );
};

export default LikeIndex;
