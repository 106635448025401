import { Modal } from 'antd';
import React from 'react';
import { useGetPartnerUserDetail, useGetRegions } from '../apis/queries';
import check from '../assets/check.png';
const SELECTED_REGIONS = ['11680', '28710', '30', '46810', '46770'];
const styles = {
  itemWrapper: {
    marginBottom: 20,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
};
const useRegionsModal = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const Section = React.memo(({ title, children }) => {
    return (
      <div style={{ width: '186px' }}>
        <span
          style={{
            fontSize: '16px',
            color: '#767676',
          }}
        >
          {title}
        </span>
        <div
          style={{
            width: '100%',
            borderRadius: 10,
            backgroundColor: '#fff',
            height: '100%',
            marginTop: 12,
            padding: '15px 20px 15px 20px',
            overflow: 'scroll',
          }}
        >
          {children}
        </div>
      </div>
    );
  });
  const RegionsModal = ({ detailId, regions }) => {
    const { data: partnerDetailData, isFetchedAfterMount } = useGetPartnerUserDetail({
      id: detailId,
      params: {},
    });
    console.log(partnerDetailData?.regions);
    const [selectedCategory, setSelectedCategory] = React.useState(null);
    // const { data: regions } = useGetRegions();
    const [mainCategory, subCategory] = React.useMemo(() => {
      if (!regions) return [[], []];
      const mainCategory = regions?.trees?.map((v) => v);
      mainCategory.unshift({ areaCode: null, label: '전체' });
      if (!selectedCategory) return [mainCategory, []];
      const subCategory = regions?.trees?.find((v) => v.areaCode === selectedCategory)?.children;
      return [mainCategory, subCategory];
    }, [regions, selectedCategory]);
    return (
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'hidden',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={true}
        okText={'확인'}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div style={{ padding: '35px 45px', height: '100%' }}>
          <div style={{ display: 'Flex', flexDirection: 'row', alignItems: 'center', gap: 3 }}>
            <h3 style={{ fontSize: 24 }}>지역설정</h3>
            <h3 style={{ fontSize: 24, color: '#1e3d83', fontWeight: 900 }}>{partnerDetailData?.regions?.length}</h3>
          </div>
          <div
            style={{
              marginTop: 80,
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Section title="대분류">
              {mainCategory?.map((v) => {
                const selected = partnerDetailData?.regions?.find((v2) => v2.substring(0, 2) === v.areaCode);
                return (
                  <div
                    onClick={() => setSelectedCategory(v.areaCode)}
                    key={v.areaCode}
                    style={{ ...styles.itemWrapper, cursor: 'pointer' }}
                  >
                    <span style={{ color: selected ? '#1e3d83' : '#767676', fontWeight: selected ? '800' : 'normal' }}>
                      {v.label}
                    </span>
                    {selected && (
                      <img
                        style={{
                          width: '14px',
                          height: '10px',
                        }}
                        src={check}
                        alt="check"
                      />
                    )}
                  </div>
                );
              })}
            </Section>
            <Section title="소분류">
              {subCategory?.map((v) => {
                const selected = partnerDetailData?.regions.find((v2) => v2 === v.areaCode);
                return (
                  <div style={styles.itemWrapper} key={v.areaCode}>
                    <span style={{ color: selected ? '#1e3d83' : '#767676', fontWeight: selected ? '800' : 'normal' }}>
                      {v.label}
                    </span>
                    {selected && (
                      <img
                        style={{
                          width: '14px',
                          height: '10px',
                        }}
                        src={check}
                        alt="check"
                      />
                    )}
                  </div>
                );
              })}
            </Section>
            <Section title="선택지역">
              {partnerDetailData?.regions?.length > 0 ? (
                partnerDetailData?.regions?.map((v) => (
                  <div
                    style={{
                      display: 'flex',
                      width: '96px',
                      height: '30px',
                      padding: '7px 10px 7px 10px',
                      borderRadius: '15px',
                      border: 'solid 1.5px #1e3d83',
                      marginBottom: 6,
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '800',
                        color: '#1e3d83',
                      }}
                    >
                      {regions?.map[v]}
                    </span>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    display: 'flex',
                    width: '96px',
                    height: '30px',
                    padding: '7px 10px 7px 10px',
                    borderRadius: '15px',
                    border: 'solid 1.5px #1e3d83',
                    marginBottom: 6,
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '800',
                      color: '#1e3d83',
                    }}
                  >
                    전체
                  </span>
                </div>
              )}
            </Section>
          </div>
        </div>
      </Modal>
    );
  };
  return { showModal, setIsModalOpen, RegionsModal };
};

export default useRegionsModal;
