import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import styles from './Login.module.less';
import {getAPIHost, restApi, setToken} from '../../apis';
import { useDispatch } from 'react-redux';
import { me } from '../../data/auth';
import axios from 'axios';
import assets from '../../assets';
import FindPassword from './findPassword/FindPassword';
import FindId from './findId/FindId';
import SignUp from './signup/index';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [id, setId] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isSignUp, setIsSignUp] = React.useState(false);
  const [isFindId, setIsFindId] = React.useState(false);
  const [isFindPassword, setIsFindPassword] = React.useState(false);
  const dispatch = useDispatch();
  const handleSubmit = async (data) => {
    console.log(data);
    if (loading) return;
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('grant_type', 'password');
      formData.append('username', id);
      formData.append('password', password);

      const { data: responseData } = await axios.post(getAPIHost()+'/oauth/token', formData, {
        headers: {
          Authorization: 'Basic YmFiYTpiYWJhIUA=',
          'Content-Type': 'multipart/form-data',
        },
      });

      setToken(responseData);
      await dispatch(me());
    } catch (e) {
      alert('아이디와 비밀번호 혹은 직원권한을 확인해주세요');
    }
    setLoading(false);
  };

  return (
    <>
      {isSignUp ? (
        <SignUp setIsSignUp={setIsSignUp} setIsFindPassword={setIsFindPassword} setIsFindId={setIsFindId} />
      ) : isFindId ? (
        <FindId setIsSignUp={setIsSignUp} setIsFindPassword={setIsFindPassword} setIsFindId={setIsFindId} />
      ) : (
        <>
          <div
            style={{
              position: 'absolute',
              top: 100,
              left: 50,
              fontSize: 40,
              color: '#1e3d83',
            }}
          >
            <span style={{ fontWeight: 'bold' }}>바바</span>와 함께하는
            <br />
            행복한 <span style={{ fontWeight: 'bold' }}>인테리어</span>의 시작
          </div>
          <div
            style={{
              width: 500,
              backgroundColor: 'white',
              height: 600,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              borderRadius: ' 10px 0 0 10px',
              boxShadow: '5px 5px 10px rgba(0,0,0,0.3)',
            }}
          >
            <p
              style={{
                marginBottom: 30,
                fontSize: 30,
                color: '#1e3d83',
                fontWeight: 'bold',
                position: 'relative',
                top: -40,
                left: 40,
              }}
            >
              환영합니다!
            </p>

            <div>
              <div>
                <img src="" alt="" />
              </div>
              <div>
                <img src="" alt="" />
              </div>
              <div>
                <img src="" alt="" />
              </div>
            </div>

            <img style={{ width: '100%' }} src={assets.login} alt="" />
          </div>
          <div className={styles.login_wrapper}>
            <div style={{ marginBottom: 40 }}>
              <p style={{ fontSize: 16, fontWeight: 'bold' }}>로그인</p>
              <p style={{ color: '#767676' }}>아이디와 비밀번호를 입력해주세요</p>
            </div>
            <Form onFinish={handleSubmit}>
              <Form.Item noStyle name={'username'}>
                <p style={{ fontSize: 16, fontWeight: 'bold' }}>이메일 주소</p>
                <Input
                  onChange={(e) => {
                    setId(e.target.value);
                  }}
                  placeholder={'youremail@baba.com'}
                  className={styles.input}
                  allowClear
                />
              </Form.Item>
              <Form.Item noStyle name={'password'}>
                <p style={{ fontSize: 16, fontWeight: 'bold', marginTop: 30 }}>비밀번호</p>
                <Input.Password
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder={'비밀번호 입력'}
                  className={styles.input}
                  allowClear
                />
              </Form.Item>
              <div style={{ display: 'flex', justifyContent: 'space-around', width: '80%', margin: '30px auto' }}>
                <span
                  onClick={() => {
                    setIsSignUp(true);
                  }}
                  style={{ cursor: 'pointer', color: '#767676', fontSize: 12 }}
                >
                  회원가입
                </span>
                <span
                  onClick={() => {
                    setIsFindId(true);
                  }}
                  style={{ cursor: 'pointer', color: '#767676', fontSize: 12 }}
                >
                  ID/비밀번호 찾기
                </span>
              </div>
              <Button block className={styles.submit} type={'primary'} htmlType={'submit'} loading={loading}>
                로그인
              </Button>
            </Form>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
