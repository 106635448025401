export const POSITIONS = [
  '_all',
  'AREA001',
  'AREA003',
  'AREA004',
  'AREA002',
  'AREA005',
  'AREA006',
  'AREA007',
  'AREA008',
];

export const POSITION_LABELS = {
  _all: '전체',
  AREA001: '거실',
  AREA003: '안방',
  AREA004: '방',
  AREA002: '주방',
  AREA005: '현관',
  AREA006: '거실화장실',
  AREA007: '안방화장실',
  AREA008: '방화장실',
};

export const PRINT_LABELS = {
  M: 'm',
  M2: 'm2',
  PY: '평',
  JA: '자',
  EA: 'EA',
  LOT: 'LOT',
  SET: 'SET',
};

export const CALC_KEYS = {
  productCount: {
    key: 'productCount',
    label: '상품갯수',
    allow: [
      '_all',
      'AREA001',
      'AREA002',
      'AREA003',
      'AREA004',
      'AREA005',
      'AREA006',
      'AREA007',
      'AREA008',
    ],
  },
  size: {
    key: 'size',
    label: '전체실평수',
    allow: ['_all'],
  },
  sash: {
    key: 'sash',
    label: '샷시면적(평/자)',
    allow: ['_all'],
  },
  footSpace: {
    key: 'footSpace',
    label: '바닥면적(평)',
    allow: ['AREA001', 'AREA002', 'AREA003', 'AREA004', 'AREA005'],
  },
  wallSpace: {
    key: 'wallSpace',
    label: '벽체면적(평)',
    allow: ['AREA001', 'AREA002', 'AREA003', 'AREA004', 'AREA005'],
  },
  bathFootSpace: {
    key: 'bathFootSpace',
    label: '화장실 바닥면적(m2)',
    allow: ['AREA006', 'AREA007', 'AREA008'],
  },
  bathWallSpace: {
    key: 'bathWallSpace',
    label: '화장실 벽체면적(m2)',
    allow: ['AREA006', 'AREA007', 'AREA008'],
  },
  closet: {
    key: 'closet',
    label: '붙박이장',
    allow: ['AREA003', 'AREA004'],
  },
  shoeRack: {
    key: 'shoeRack',
    label: '신발장',
    allow: ['AREA005'],
  },
  moulding: {
    key: 'moulding',
    label: '몰딩',
    allow: ['AREA001', 'AREA002', 'AREA003', 'AREA004', 'AREA005'],
  },
  door: {
    key: 'door',
    label: '문',
    allow: ['AREA003', 'AREA004', 'AREA005', 'AREA006', 'AREA007', 'AREA008'],
  },
  balconyDoor: {
    key: 'balconyDoor',
    label: '발코니도어',
    allow: ['AREA001', 'AREA002'],
  },
  innerDoor: {
    key: 'innerDoor',
    label: '중문',
    allow: ['AREA005'],
  },
  balcony: {
    key: 'balcony',
    label: '발코니',
    allow: ['AREA001', 'AREA002', 'AREA003', 'AREA004'],
  },
  balconyStorage: {
    key: 'balconyStorage',
    label: '발코니장',
    allow: ['AREA001', 'AREA002', 'AREA003', 'AREA004'],
  },
  sinkType1: {
    key: 'sinkType1',
    label: 'ㅡ자싱크대',
    allow: ['AREA002'],
  },
  wallTileType1: {
    key: 'wallTileType1',
    label: 'ㅡ자싱크대앞 벽타일',
    allow: ['AREA002'],
  },
  sinkType2: {
    key: 'sinkType2',
    label: 'ㄱ자싱크대',
    allow: ['AREA002'],
  },
  wallTileType2: {
    key: 'wallTileType2',
    label: 'ㄱ자싱크대앞 벽타일',
    allow: ['AREA002'],
  },
  sinkType3: {
    key: 'sinkType3',
    label: 'ㄷ자싱크대',
    allow: ['AREA002'],
  },
  wallTileType3: {
    key: 'wallTileType3',
    label: 'ㄷ자싱크대앞 벽타일',
    allow: ['AREA002'],
  },
  sinkType4: {
    key: 'sinkType4',
    label: '11자싱크대',
    allow: ['AREA002'],
  },
  wallTileType4: {
    key: 'wallTileType4',
    label: '11자싱크대앞 벽타일',
    allow: ['AREA002'],
  },
  ceilingBoard: {
    key: 'ceilingBoard',
    label: '화장실천정재',
    allow: ['AREA006', 'AREA007', 'AREA008'],
  },
};

export const PERIOD_OPTIONS = [
  {
    label: '금일',
    value: 'today',
  },
  {
    label: '1주',
    value: '1w',
  },
  {
    label: '1달',
    value: '1m',
  },
  {
    label: '1년',
    value: '1y',
  },
];
