import React from 'react';

const useBlockRefresh = () => {
  const preventClose = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  // 브라우저에 렌더링 시 한 번만 실행하는 코드
  React.useEffect(() => {
    (() => {
      window.addEventListener('beforeunload', preventClose);
    })();

    return () => {
      window.removeEventListener('beforeunload', preventClose);
    };
  });
};
export default useBlockRefresh;
