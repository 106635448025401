import { Modal, Pagination, Slider } from 'antd';
import React from 'react';
import { FilterInput } from '../components/template/page';
import PaginateTable from '../components/table/PaginateTable';
import { dummyData } from '../data/dummy';
import { CloseCircleFilled, HeartFilled, UserOutlined, WechatFilled } from '@ant-design/icons';
import { useGetAllDetail, useGetPostDetail, useGetUserCommentList } from '../apis/queries';
import { parseImageUri } from '../common/utils';
import produce from 'immer';
import { restApi } from '../apis';
import { useQueryClient } from 'react-query';
import { USER_COMMENT_LIST_KEY } from '../apis/queryKeys';
import usePostDetailModal from './usePostDetailModal';
import styles from '../components/modal/commentModal.module.less';
import RoundButton from '../components/button/RoundButton';
import Comment from '../components/comment/Comment';
import ContentDetailModal from '../components/modal/ContentDetailModal';
import { useSelector } from 'react-redux';
import CommentIcon from '../components/image/CommentIcon';

function PostModal({ selectedId, onCancel, type }) {
  const { data: detailData } = useGetAllDetail({ id: selectedId, type: type, config: { enabled: !!selectedId } });
  const settings = {
    className: 'center',
    infinite: false,
    centerPadding: '60px',
    slidesToShow: 5,
    swipeToSlide: true,
    afterChange: function (index) {
      console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
    },
  };

  return (
    <Modal
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 200,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
      }}
      closable={false}
      open={!!detailData}
      // open={isModalOpen}
      // onOk={handleOk}
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
      title={
        <div className={styles.header}>
          <RoundButton
            onClick={() => {
              onCancel?.();
            }}
            label="나가기"
            containerStyle={{
              width: '100px',
              marginRight: '10px',
            }}
          />
        </div>
      }
      footer={null}
      // title={}
      cancelText={'확인'}
    >
      <div style={{ width: '100%', backgroundColor: 'black' }}>
        <img
          style={{
            display: 'block',
            height: '450px',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: 'black',
          }}
          src={detailData?.images?.length > 0 && parseImageUri(detailData?.images[0])}
          alt=""
        />
      </div>
      <div style={{ padding: '50px 100px' }}>
        {detailData?.images.length > 1 && (
          <Slider {...settings}>
            {[...detailData?.images].length > 1 &&
              [...detailData?.images].slice(1).map((item, index) => {
                return (
                  <div>
                    <img style={{ width: 108, height: 108, borderRadius: 10 }} src={parseImageUri(item)} alt="" />
                  </div>
                );
              })}
          </Slider>
        )}
        <p style={{ margin: '10px 0', fontWeight: 600, fontSize: 17 }}>{detailData?.title}</p>
        <div className={styles.flex_div}>
          <div className={styles.flex_div} style={{ alignItems: 'center' }}>
            {detailData?.createdBy?.profileImageId ? (
              <img
                className={styles.style_profile_img}
                src={detailData?.createdBy?.profileImageId}
                alt="프로필 이미지"
              />
            ) : (
              <div
                className={styles.style_profile_img}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#e8e8e8',
                }}
              >
                <UserOutlined style={{ color: 'white', fontSize: 30 }} />
              </div>
            )}
            <div>
              <p className={styles.category_p} style={{ marginLeft: 10, color: 'black', fontWeight: 'bold' }}>
                {detailData?.createdBy?.nickname}
              </p>
              <p className={styles.category_p} style={{ marginLeft: 10, color: '#787878' }}>
                {detailData?.createdAt}
              </p>
            </div>
          </div>
          <div className={styles.flex_div}>
            <div>
              <HeartFilled style={{ fontSize: 20, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p} style={{ color: '#999999', textAlign: 'center' }}>
                {detailData?.likeInfo?.likeCount}
              </p>
            </div>
            <div style={{ marginLeft: 5 }}>
              <WechatFilled style={{ fontSize: 20, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p} style={{ color: '#999999', textAlign: 'center' }}>
                {detailData?.commentCount}
              </p>
            </div>
          </div>
        </div>
        <p style={{ margin: '25px 0 50px', fontSize: 14, color: '#767676' }}>{detailData?.content}</p>
        {/*<div className={styles.modify_input_wrapper_div}>*/}
        {/*  <input*/}
        {/*    type="text"*/}
        {/*    defaultValue={'https://www.naver.com/dskfjhuwqerui@welkij124909'}*/}
        {/*    className={styles.modify_title_input}*/}
        {/*    style={{ fontWeight: 'normal', color: '#999', fontSize: 14 }}*/}
        {/*    maxLength={80}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <div style={{ padding: '50px 100px' }}>
        <Comment id={selectedId} type={'POST'} />
      </div>
    </Modal>
  );
}

const PostDetailModal = ({ id, type, onCancel }) => {
  const queryClient = useQueryClient();
  const postType =
    type === 'STYLE'
      ? 'styles'
      : type === 'PORTFOLIO'
      ? 'portfolios'
      : type === 'TREND'
      ? 'trends'
      : 'construction-guides';
  const { data } = useGetPostDetail({ type: postType, id });
  // const data = queryClient.getQueryData([POST_DETAIL_KEY, type, id]);
  const { commonCodes } = useSelector((state) => state.common);

  return (
    <Modal
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 200,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
        padding: 0,
      }}
      title={
        <div className={styles.header}>
          <RoundButton
            onClick={() => {
              onCancel?.();
            }}
            label="나가기"
            containerStyle={{
              width: '100px',
              marginRight: '10px',
            }}
          />
        </div>
      }
      closable={false}
      open={!!data}
      onOk={onCancel}
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
      footer={null}
      okText={'확인'}
      cancelText={'수정'}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <img
        style={{ width: '100%', objectFit: 'cover', height: '450px', marginLeft: 'auto' }}
        src={parseImageUri(data?.thumbnailId)}
        alt=""
      />
      <div style={{ padding: '50px 100px' }}>
        <p
          style={{
            fontSize: '12px',
            color: '#767676',
          }}
        >
          {commonCodes?.map[data?.tag]}
        </p>
        <p
          style={{
            fontSize: '16px',
            fontWeight: '600',
            color: '#000',
          }}
        >
          {data?.title}
        </p>
        <div className={styles.flex_div}>
          <div className={styles.flex_div} style={{ alignItems: 'center' }}>
            <img
              className={styles.style_profile_img}
              src={parseImageUri(data?.createdBy?.profileImageId)}
              alt="프로필 이미지"
            />
            <p className={styles.category_p} style={{ marginLeft: 10, color: 'black' }}>
              {data?.createdBy?.nickname}
            </p>
          </div>
          <div className={styles.flex_div}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <HeartFilled style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p} style={{ color: '#999999' }}>
                {data?.likeInfo?.likeCount}
              </p>
            </div>
            <div style={{ marginLeft: 5, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <CommentIcon style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p} style={{ color: '#999999' }}>
                {data?.shareCount}
              </p>
            </div>
          </div>
        </div>
        <p style={{ margin: '25px 0 50px', fontSize: 14, color: '#767676' }}>{data?.description}</p>
        <div className={styles.modify_input_wrapper_div}>
          <input
            type="text"
            defaultValue={'https://www.naver.com/dskfjhuwqerui@welkij124909'}
            className={styles.modify_title_input}
            style={{ fontWeight: 'normal', color: '#999', fontSize: 14 }}
            maxLength={80}
          />
        </div>
        {data?.subContents?.map((v) => (
          <div className={styles.style_section_div}>
            <img className={styles.style_section_main_img} src={parseImageUri(v?.thumbnailId)} alt="" />
            <p className={styles.title_p}>{v?.title}</p>
            <p className={styles.style_section_desc_p}>{v?.description}</p>
            <div className={styles.modify_input_wrapper_div}>
              <input
                type="text"
                defaultValue={'https://www.naver.com/dskfjhuwqerui@welkij124909'}
                className={styles.modify_title_input}
                style={{ fontWeight: 'normal', color: '#999', fontSize: 14 }}
                maxLength={80}
              />
            </div>
          </div>
        ))}
      </div>
      <div style={{ padding: '50px 100px' }}>
        <Comment id={id} type={'CONTENT'} />
      </div>
    </Modal>
  );
};

const useCommentModal = () => {
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const CommentModal = ({ customColumns, tableHeight, selectedId }) => {
    const initialParams = React.useMemo(
      () => ({
        limit: 50,
        page: 0,
      }),
      [],
    );
    const [params, setParams] = React.useState(initialParams);

    const { data } = useGetUserCommentList({
      id: selectedId,
      params,
      config: {
        enabled: !!selectedId,
      },
    });

    //본문보기
    // const { showModal, PostDetailModal } = usePostDetailModal();
    const [refreshDetail, setRefreshDetail] = React.useState();
    const handleDetail = (comment) => {
      if (comment?.content) {
        return { isContent: 'content', type: comment?.content?.type, id: comment?.content?.id };
      } else {
        return { type: comment?.post?.type, id: comment?.post?.id };
      }
    };
    const handleCommentCancel = () => {
      setRefreshDetail(undefined);
    };
    return (
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={true}
        footer={null}
      >
        <div style={{ padding: '35px 25px', position: 'relative' }}>
          <p onClick={showModal} style={{ fontSize: 24, fontWeight: 600 }}>
            댓글
          </p>

          <div style={{ margin: 20, backgroundColor: 'white', padding: '40px 20px' }}>
            {data?.content?.map((item, index) => {
              const post = item?.post ?? item?.content;
              return (
                <div key={`commentList ${index}`}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20, marginTop: 30 }}>
                    <span style={{ fontSize: 12, color: '#767676' }}>{item?.createdAt}</span>
                    <CloseCircleFilled
                      onClick={() => {
                        Modal.confirm({
                          content: '댓글을 삭제하시겠습니까?',
                          okText: '삭제',
                          cancelText: '취소',
                          onOk: () => {
                            restApi.delete(`/comments/${item?.id}`).then(async (res) => {
                              await queryClient.invalidateQueries(USER_COMMENT_LIST_KEY);
                              Modal.success({
                                content: '삭제되었습니다.',
                                okText: '확인',
                              });
                            });
                          },
                        });
                      }}
                      style={{ fontSize: 20, color: '#1e3d83' }}
                    />
                  </div>
                  <div style={{ fontSize: 14, color: '#767676' }}>{item?.text}</div>
                  <div>
                    <span
                      onClick={() => setRefreshDetail(handleDetail(item))}
                      style={{ fontSize: 12, color: '#767676' }}
                    >
                      본문보기
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          width: 50,
                          height: 50,
                          backgroundColor: '#efefef',
                          borderRadius: 5,
                          marginRight: 10,
                          backgroundImage: `url(${parseImageUri(post?.thumbnailId)})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                      />
                      <div>
                        <p style={{ fontSize: 14, fontWeight: 'bold' }}>{post?.title}</p>
                        <p style={{ fontSize: 12, color: '#767676' }}>{post?.type}</p>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              );
            })}
          </div>
          <div style={{ position: 'absolute', bottom: 10, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Pagination
              showSizeChanger
              onChange={(page, pageSize) => {
                setParams(
                  produce((draft) => {
                    draft.page = page - 1;
                  }),
                );
              }}
              defaultCurrent={1}
              current={params?.page + 1}
              pageSize={params?.limit}
              onShowSizeChange={(current, size) => {
                setParams(
                  produce((draft) => {
                    draft.limit = size;
                  }),
                );
              }}
              total={data?.totalElements}
            />
          </div>
        </div>
        {refreshDetail?.isContent ? (
          ['STYLE', 'PORTFOLIO'].includes(refreshDetail?.type) ? (
            <ContentDetailModal
              type={refreshDetail?.type}
              contentId={refreshDetail?.id}
              visible={!!refreshDetail}
              setVisible={setRefreshDetail}
              handleOk={() => {}}
              handleCancel={() => setRefreshDetail()}
            />
          ) : (
            ['TREND', 'CONSTRUCTION_GUIDE'].includes(refreshDetail?.type) && (
              <PostDetailModal id={refreshDetail?.id} type={refreshDetail?.type} onCancel={handleCommentCancel} />
            )
          )
        ) : (
          <PostModal
            selectedId={refreshDetail?.id}
            onCancel={handleCommentCancel}
            type={refreshDetail?.type}
          ></PostModal>
        )}
      </Modal>
    );
  };
  return { showModal, setIsModalOpen, CommentModal };
};

export default useCommentModal;
