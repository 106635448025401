import React from 'react';
import { getFileId, parseImageUri } from '../../common/utils';
import produce from 'immer';
import { Modal } from 'antd';
import RoundButton from '../button/RoundButton';
import { InputComponent, MultiLineInput } from '../template/page';
import { RedoOutlined } from '@ant-design/icons';
import { restApi } from '../../apis';
import { useQueryClient } from 'react-query';
import { NOTICE_DETAIL_KEY, NOTICE_LIST_KEY } from '../../apis/queryKeys';
const styles = {
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: '50px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px 20px 0px 20px',
  },
  titleImageWrapper: {
    position: 'reative',
    width: '100%',
    height: '536px',
    backgroundColor: '#d9d9d9',
  },
  titleImage: {
    width: '100%',
    height: 'inherit',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  bodyWrapper: {
    padding: '0px 60px',
  },
  titleInputWrapper: {
    position: 'relative',
    marginTop: '60px',
    borderBottom: '1px solid #d9d9d9',
  },
  contentInputWrapper: {
    marginTop: '55px',
    minHeight: '200px',
  },
  modify_style_select: {
    display: 'block',
    height: '35px',
    padding: '10px 12px 9px 10px',
    border: 'none',
    borderRadius: '5px',
    mixBlendMode: 'luminosity',
    filter: 'blur(0.2px)',
    color: 'white',
  },
  modify_reupload: {
    padding: '7px',
    borderRadius: '50%',
    backgroundcolor: '#888',
    mixBlendMode: 'luminosity',
    filter: 'blur(0.1px)',
    color: 'white',
    fontSize: '22px',
    marginRight: '10px',
  },
};

const WriteNoticeModal = React.memo(({ selectedId, visible, setVisible, handleOk, handleCancel, isEdit }) => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData([NOTICE_DETAIL_KEY, selectedId]);
  const initialForm = React.useMemo(
    () => ({
      contents: [{ content: '', imageId: null }],
      targetPlatform: 'ALL', // ALL, USER, PARTNER
      title: '',
    }),
    [],
  );
  const [form, setForm] = React.useState(initialForm);

  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    setForm(
      produce((draft) => {
        draft.contents[0].imageId = imageId;
      }),
    );
  };
  const targetPlatformItems = React.useMemo(
    () => [
      {
        label: '전체',
        value: 'ALL',
      },
      {
        label: '유저',
        value: 'USER',
      },
      {
        label: '파트너',
        value: 'PARTNER',
      },
    ],
    [],
  );

  const handleSubmit = React.useCallback(() => {
    Modal.confirm({
      content: `${isEdit ? '수정' : '저장'}하시겠습니까?`,
      okTexT: '확인',
      cancelText: '취소',
      onOk: () => {
        const api = isEdit
          ? (data) => restApi.put(`/notices/${selectedId}`, data)
          : (data) => restApi.post('/notices', data);
        api(form).then(async (res) => {
          await queryClient.invalidateQueries(NOTICE_LIST_KEY);
          await queryClient.invalidateQueries([NOTICE_DETAIL_KEY, selectedId]);
          setVisible(false);
          setForm(initialForm);
          Modal.success({
            content: `${isEdit ? '수정' : '저장'}되었습니다.`,
            okText: '확인',
          });
        });
      },
    });
  }, [form, queryClient, isEdit, selectedId]);
  React.useEffect(() => {
    if (isEdit && data) {
      setForm(
        produce((draft) => {
          draft.title = data.title;
          draft.targetPlatform = data.targetPlatform;
          draft.contents = data.contents;
        }),
      );
    }
  }, [isEdit, data]);
  return (
    <Modal
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 100,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
        padding: '0px',
        paddingBottom: '50px',
      }}
      closable={false}
      open={visible}
      onCancel={() => {
        handleCancel();
        setForm(initialForm);
      }}
      destroyOnClose={true}
      maskClosable={true}
      okText={'확인'}
      footer={null}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div style={styles.modalContainer}>
        <div style={styles.header}>
          <RoundButton
            onClick={() => {
              handleCancel();
              //   setForm(initialForm);
            }}
            label="취소"
            containerStyle={{
              width: '100px',
              marginRight: '10px',
            }}
          />
          <RoundButton
            onClick={() => handleSubmit()}
            reverse
            label="저장"
            containerStyle={{
              width: '100px',
            }}
          />
        </div>
      </div>
      <div style={styles.titleImageWrapper}>
        <div style={{ ...styles.titleImage, backgroundImage: `url(${parseImageUri(form.contents[0]?.imageId)})` }}>
          <div
            style={{
              display: 'flex',
              position: 'relative',
              justifyContent: 'flex-end',
              right: '120px',
              top: '490px',
              zIndex: 99,
            }}
          >
            <label style={{ cursor: 'pointer' }} htmlFor="fileInput">
              <div>
                <RedoOutlined style={styles.modify_reupload} />
              </div>
              <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleChangeImage} />
            </label>
            <select
              value={form?.targetPlatform}
              onChange={(e) => {
                setForm(
                  produce((draft) => {
                    draft.targetPlatform = e.target.value;
                  }),
                );
              }}
              style={{ ...styles.modify_style_select, width: '100px', backgroundColor: '#999' }}
            >
              {targetPlatformItems?.map((v) => (
                <option value={v.value}>{v.label}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div style={styles.bodyWrapper}>
        <div style={styles.titleInputWrapper}>
          <InputComponent
            maxLength={80}
            value={form?.title}
            onChange={(e) => {
              setForm(
                produce((draft) => {
                  draft.title = e.target.value;
                }),
              );
            }}
            placeholder="공지사항 제목을 입력해 주세요."
          />
          <span style={{ position: 'absolute', right: 0, fontSize: '14px' }}>{`${form?.title?.length}/80`}</span>
        </div>
        <div style={styles.contentInputWrapper}>
          <MultiLineInput
            value={form?.contents[0].content}
            onChange={(e) => {
              setForm(
                produce((draft) => {
                  draft.contents[0].content = e.target.value;
                }),
              );
            }}
            autoSize={{ minRows: 6 }}
            placeholder="공지 내용을 입력해 주세요."
          />
        </div>
      </div>
    </Modal>
  );
});

export default WriteNoticeModal;
