import {CloseOutlined} from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, DatePicker, PageHeader, Table, Select, Input, Checkbox, Modal, Switch, TreeSelect } from 'antd';
import { restApi } from '../../../../apis';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import assets from '../../../../assets';
import {
  formatComma,
  getClientWidth,
  getTimeAgo,
  handleAccountShowAndStop,
  handleModifyWriteAccount, parseImageUri,
} from '../../../../common/utils';
import PaginateTable from '../../../../components/table/PaginateTable';
import UserDetail from '../../../../components/card/userdetail/UserDetail';
import CommonStatistics from '../../../../components/card/CommonStatistics,js/CommonStatistics';
import useUserConstructionModal from '../../../../hooks/useUserConstructionModal';
import useUserFollowModal from '../../../../hooks/useUserFollowModal';
import useUserGoodsModal from '../../../../hooks/useUserGoodsModal';
import useRegionsModal from '../../../../hooks/useRegionsModal';
import { ExtraWrapper, FilterButton, FilterInput, FilterSelect } from '../../../../components/template/page';
import useBusinessNumberModal from '../../../../hooks/useBusinessNumberModal';
import useInsuranceModal from '../../../../hooks/useInsuranceModal';
import {
  useGetEstimateCostTemplate,
  useGetPartnerUser,
  useGetPartnerUserDetail,
  useGetRegions,
} from '../../../../apis/queries';
import { useSelector } from 'react-redux';
import Thumbnail from '../../../../components/image/Thumbnail';
import moment from 'moment';
import TableColHeader from '../../../../components/table/TableColHeader';
import { QueryClient, useQueryClient } from 'react-query';
import { PARTMER_USER_DETAIL_KEY, PARTMER_USER_KEY } from '../../../../apis/queryKeys';
import { CommonCodeContext } from '../../../../context/CommonCodeContext';
import ModifyAllButton from '../../../../components/button/ModifyAllButton';
import PartnerUserDetail from '../../../../components/card/userdetail/PartnerUserDetail';
import AgencyStyleModal from '../../../../components/modal/AgencyStyleModal';
import AgencyGuideModal from '../../../../components/modal/AgencyGuideModal';
import AgencyCommentModal from '../../../../components/modal/AgencyCommentModal';
import AgencyPortfolioModal from '../../../../components/modal/AgencyPortfolioModal';
import AgencyExpertBoardModal from '../../../../components/modal/AgencyExpertBoardModal';
import AgencyTrendModal from '../../../../components/modal/AgencyTrendModal';
import useSearchBar from '../../../../hooks/useSearchBar';
import useUpperLine from '../../../../hooks/useUpperLine';
import LowerLine from '../../../../components/LowerLine';
import { Option } from 'antd/lib/mentions';
import produce from 'immer';
import styles from '../../users/index.module.less';

const AccountsAgenciesInteriorIndex = () => {
  const location = useLocation();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);
  const queryClient = useQueryClient();
  const { codeMapPTAT } = React.useContext(CommonCodeContext);
  const { data: regions } = useGetRegions();

  // 시공사 회원 변경
  const [userType, setUserType] = React.useState('INTERIOR');
  // 시공사 회원 변경

  //필터 윗줄 생성(keword, filter params 포함, query param 에 spread 구문으로 삽입)
  const filters = React.useMemo(() => {
    const deleted = [
      // [null, '전체'],
      [false, '관리 회원 리스트'],
      [true, '삭제 회원 리스트'],
    ];
    return [
      // {
      //   title: '고객찾기상태',
      //   key: 'filter1',
      //   options: [undefined, [true, '제안중'], [false, '선정'], [false, '일반']],
      // },
      // { title: '지역설정', key: 'filter2', options: [] },
      {
        title: '리스트 구분',
        key: 'isDeleted',
        defaultValue:false,
        options: deleted,
      },
      { title: '사업자등록여부', key: 'hasBusinessLicense', options: [undefined, [true, 'Y'], [false, 'N']] },
      { title: '보험설정여부', key: 'hasInsurance', options: [undefined, [true, 'Y'], [false, 'N']] },
    ];
  }, []);
  const { params: upperParam, UpperLine } = useUpperLine(filters);




  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: 'DESC',
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  React.useEffect(() => {
    if (upperParam) {
      setParams((prev) => ({ ...prev, ...upperParam }));
    }
  }, [upperParam]);

  const [data, setData] = React.useState();
  const navigate = useNavigate();

  // 파트너 스타일 모달 동작
  const [isStyleModal, setIsStyleModal] = React.useState(false);
  const showStyleModal = () => {
    setIsStyleModal(true);
  };
  // 파트너 포트폴리오 모달 동작
  const [isPortfolioModal, setIsPortfolioModal] = React.useState(false);
  const showPortfolioModal = () => {
    setIsPortfolioModal(true);
  };

  //파트너 공사가이드 모달 동작
  const [isGuideModal, setIsGuideModal] = React.useState(false);
  const showGuideModal = () => {
    setIsGuideModal(true);
  };

  //파트너 댓글 모달 동작
  const [isCommentModal, setIsCommentModal] = React.useState(false);
  const showCommentModal = () => {
    setIsCommentModal(true);
  };

  //파트너 전문가들의 수다 모달 동작
  const [isBoardModal, setIsBoardModal] = React.useState(false);
  const showExpertBoardModal = () => {
    setIsBoardModal(true);
  };

  //파트너 트렌드 모달 동작
  const [isTrendModal, setIsTrendModal] = React.useState(false);
  const showExpertTrendModal = () => {
    setIsTrendModal(true);
  };
  const {
    showModal: showContentModal,
    setIsModalOpen: setContentModalOpen,
    UserConstructionModal,
  } = useUserConstructionModal();
  const { showModal: showFollowModal, setIsModalOpen: serFollowModalOpen, UserFollowModal } = useUserFollowModal();
  const { showModal: showGoodsModal, setIsModalOpen: serGoodsModalOpen, UserGoodsModal } = useUserGoodsModal();
  const { showModal: showRegionsModal, setIsModalOpen: setShowRegionsModal, RegionsModal } = useRegionsModal();
  const findCustomerAction = () => {
    navigate('/accounts/agencies/findcustomer');
  };

  const handleDelete = async (id) => {
    const result = window.confirm(`정말 ${params?.isDeleted?'복구':'삭제'}하시겠습니까?`);
    if (result) {
      try {
        await restApi.put(`/accounts/user/${id}`)
      }catch (e){
        alert(`${params?.isDeleted?'복구':'삭제'}에 실패했습니다.`)
      }
      await refetch();
    }
  }


  //썸네일 삭제버튼 로직
  const handleDeleteItems = React.useCallback(
    (ids) => {
      // Modal.confirm({
      //   content: '삭제 할 수 없습니다',
      //   okText: '확인',
      //   cancelText: '취소',

      //   onOk: () => {
      //     restApi
      //       .delete('/contents', {
      //         data: { ids },
      //       })
      //       .then((res) => {
      //         queryClient.invalidateQueries(CONTENTS_KEY);
      //         Modal.success({
      //           content: '삭제되었습니다.',
      //           okText: '확인',
      //         });
      //       });
      //   },
      // });
      Modal.warning({
        title: '삭제 불가',
        content: '삭제할 수 없습니다',
      });
    },
    [data],
  );

  // 모두 선택 버튼로직
  const [checkedList, setCheckedList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const { data: partnerData,refetch, isLoading } = useGetPartnerUser({
    type: userType,
    params,
  });
  console.log({ partnerData });

  const allData = React.useMemo(() => {
    return partnerData?.content?.map((v) => ({
      data: v,
      id: v?.id,
      address1: v?.address1,
      address2: v?.address2,
      nickname: v?.nickname,
      image: v?.profileImageId,
      partnerType: v?.partnerType,
      createdAt: v?.createdAt,
      hasBusinessLicense: v?.hasBusinessLicense,
      limitCreateProposal: v?.limitCreateProposal,
      isActive: v?.isActive,
      ...v,
    }));
  }, [partnerData]);
  React.useEffect(() => {
    if (!!allData) {
      const idList_ = allData?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [allData]);

  //상세 화면
  const [refreshDetail, setRefreshDetail] = React.useState();
  React.useEffect(() => {
    if (!refreshDetail) {
      setRefreshDetail(partnerData?.content[0]?.id);
    }
  }, [partnerData]);

  //테이블 col
  const COLUMNS = [
    {
      title: '전문분야',
      dataIndex: 'data',
      key: 'data',
      width: 100,
      align: 'center',
      render: ({ id, partnerType }) => {
        return (
          <>
            <p
              style={{
                width: '85%',
                backgroundColor: '#1e3d83',
                color: 'white',
                fontWeight: 'bold',
                fontSize: 9,
                padding: '2px 0 1px',
                borderRadius: 4,
                margin: '0 auto',
              }}
            >
              {codeMapPTAT[partnerType]}
            </p>
            <Checkbox
              key={`${id}_check`}
              checked={checkedList.includes(id)}
              onClick={() => {
                if (checkedList.includes(id)) {
                  setCheckedList(checkedList.filter((v) => v !== id));
                } else {
                  setCheckedList([...checkedList, id]);
                }
              }}
            />
          </>
        );
      },
    },
    // {
    //   title: '프로필',
    //   key: 'profileImageId',
    //   dataIndex: 'profileImageId',
    //   align: 'center',
    //   width: 60,
    //   render: (data, record) => {
    //     return (
    //       <Thumbnail
    //         onDelete={() => {
    //           handleDeleteItems([record.id]);
    //         }}
    //         id={record.data.profileImageId}
    //       />
    //     );
    //   },
    // },
    {
      title: '프로필',
      dataIndex: 'profileImageId',
      key: 'profileImageId',
      width: 80,
      align: 'center',
      render: (data,r) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div
                  className={styles.profileWrapper}
                  style={{
                    position: 'relative',
                    backgroundImage: !data
                        ? assets.icons.blank_profile
                        : `url(${parseImageUri(data)})`,
                  }}
              >
                <div onClick={()=>{handleDelete(r.id)}} className={styles.deletebuttonwrapper}>
                  <CloseOutlined
                      style={{
                        color: '#FFF',
                        fontSize: '13px',
                      }}
                  />
                </div>
              </div>
            </div>
        );
      },
    },
    {

      key: 'nickname',
      dataIndex: 'nickname',
      align: 'left',
      sorter: true,
      title: <TableColHeader title={'업체명'} subTitle={'주소'} />,
      width: 230,
      render: (data, record) => (
              <>
                <div>
                  <p style={{ fontSize: 13,fontWeight:'bold' }}>{record.nickname}</p>
                </div>
                <div style={{display:'flex'}}>
                  <p style={{ color: '#767676', fontSize: 11 }}>{record.address1} {record.address2}</p>
                </div>
              </>
      ),
    },
    {
      title: '고객찾기',
      children: [
        {
          dataIndex: 'constructionRequestCount',
          title: '제안중',
          key: 'constructionRequestCount',
          width: 60,
          sorter: true,
          showSorterTooltip: false,
          align: 'center',
          render: (v, r) => (
            <button
              onClick={() => {
                window.open(`/accounts/save/proposal/${r?.id}/partner?isFinished=false`, '_blank', '');
              }}
              style={{
                textDecoration: 'underline',
                background: 'none',
              }}
            >
              {v ?? '0'}
            </button>
          ),
        },
        {
          dataIndex: 'completedConstructionRequestCount',
          title: '완료',
          key: 'completedConstructionRequestCount',
          width: 50,
          sorter: true,
          showSorterTooltip: false,
          align: 'center',
          render: (v, r) => (
            <button
              onClick={() => {
                window.open(`/accounts/save/proposal/${r?.id}/partner?isFinished=true`, '_blank', '');
              }}
              style={{
                textDecoration: 'underline',
                background: 'none',
              }}
            >
              {v ?? 0}
            </button>
          ),
        },
      ],
    },
    {
      title: '평점',
      dataIndex: 'rate',
      key: 'rate',
      width: 60,
      sorter: true,
      showSorterTooltip: false,
      align: 'center',
      render: (rate) => (
        <span
          style={{
            fontSize: '14px',
            fontWeight: '600',
          }}
        >
          {rate ?? '0'}
        </span>
      ),
    },
    {
      title: '사업자\n등록',
      key: 'hasBusinessLicense',
      dataIndex: 'hasBusinessLicense',
      align: 'center',
      width: 70,
      sorter: true,
      render: (data, record) => (
        <>
          <p style={{ color: '#767676', fontSize: 12 }}>{data ? 'Y' : 'N'}</p>
        </>
      ),
    },
    {
      title: <div>지역<br/>설정</div>,
      key: 'partnerRegions',
      dataIndex: 'partnerRegions',
      align: 'center',
      width: 55,
      // sorter: true,
      render: (data, record) => (
        <>
          <p style={{ color: '#767676', fontSize: 12 }}>{data ? `${(data?.[0]||'전체')+' '+(data?.[1]||'')}` + (data?.length>2?'외 '+data?.length-2 +'곳':'') : '-'}</p>
        </>
      ),
    },
    {
      title: '보험\n설정',
      key: 'hasInsurance',
      dataIndex: 'hasInsurance',
      align: 'center',
      width: 55,
      sorter: true,
      render: (data, record) => (
        <>
          <p style={{ color: '#767676', fontSize: 12 }}>{data ? 'Y' : 'N'}</p>
        </>
      ),
    },
    {
      title: '컨텐츠 작성',
      children: [
        {
          dataIndex: 'styleCount',
          title: '스타일',
          key: 'styleCount',
          width: 60,
          sorter: true,
          showSorterTooltip: false,
          align: 'center',
          render: (v, r) => (
            <button
              onClick={() => {
                window.open(`/accounts/save/styles/${r.id}/partner`, '_blank', '');
              }}
              style={{
                textDecoration: 'underline',
                background: 'none',
              }}
            >
              {v ?? '0'}
            </button>
          ),
        },
        {
          dataIndex: 'portfolioCount',
          title: '포폴',
          key: 'portfolioCount',
          width: 50,
          sorter: true,
          showSorterTooltip: false,
          align: 'center',
          render: (v, r) => (
            <button
              onClick={() => {
                window.open(`/accounts/save/portfolios/${r.id}/partner`, '_blank', '');
              }}
              style={{
                textDecoration: 'underline',
                background: 'none',
              }}
            >
              {v ?? '0'}
            </button>
          ),
        },
        {
          dataIndex: 'trendCount',
          title: '트렌드',
          key: 'trendCount',
          width: 60,
          sorter: true,
          showSorterTooltip: false,
          align: 'center',
          render: (v, r) => (
            <button
              onClick={() => {
                window.open(`/accounts/save/trends/${r.id}/partner`, '_blank', '');
              }}
              style={{
                textDecoration: 'underline',
                background: 'none',
              }}
            >
              {v ?? '0'}
            </button>
          ),
        },
        {
          dataIndex: 'constructionGuideCount',
          title: '가이드',
          key: 'constructionGuideCount',
          width: 60,
          sorter: true,
          showSorterTooltip: false,
          align: 'center',
          render: (v, r) => (
            <button
              onClick={() => {
                window.open(`/accounts/save/construction-guides/${r.id}/partner`, '_blank', '');
              }}
              style={{
                textDecoration: 'underline',
                background: 'none',
              }}
            >
              {v ?? '0'}
            </button>
          ),
        },
      ],
    },
    {
      title: '컨설팅\n모드',
      key: 'consultingCount',
      dataIndex: 'consultingCount',
      align: 'center',
      width: 70,
      sorter: true,
      render: (data, record) => (
        <>
          <button style={{ background:'none',textDecoration:'underline',color: '#767676', fontSize: 12 }} onClick={()=>{

              window.open(`/accounts/save/consulting/${record.id}`, '_blank', '');
          }}>{data ?? '0'}</button>
        </>
      ),
    },
    {
      title: '게시판',
      key: 'boardCount',
      dataIndex: 'boardCount',
      align: 'center',
      width: 70,
      sorter: true,
      render: (data, record) => (
          <>
            <button style={{
              background: 'none',
              textDecoration: 'underline',
              color: '#767676',
              fontSize: 12,
            }} onClick={() => {

              window.open(`/writing/agency?accountId=${record.id}`, '_blank',
                  '');
            }}>{data ?? '0'}</button>
          </>
      ),
    },
    {
      title: '댓글',
      key: 'commentCount',
      dataIndex: 'commentCount',
      align: 'center',
      width: 70,
      sorter: true,
      render: (data, record) => (
          <>
            <button style={{
              background: 'none',
              textDecoration: 'underline',
              color: '#767676',
              fontSize: 12,
            }} onClick={() => {
              setRefreshDetail(record.id);
              showCommentModal();
            }}>{data ?? '0'}</button>
          </>
      ),
    },
    {
      title: '가입일시',
      sorter: true,
      key: 'createdAt',
      dataIndex: 'createdAt',
      align: 'center',
      width: 100,
      render: (data, record) => (
        <>
          <p style={{ color: '#767676', fontSize: 12 }}>{getTimeAgo(data)}</p>
          <p style={{ color: '#767676', fontSize: 12 }}>{moment(data).format('YYYY-MM-DD HH:mm')}</p>
        </>
      ),
    },
    {
      title: <TableColHeader title={'견적작성'} subTitle={'가능/불가'} />,
      sorter: true,
      key: 'limitCreateProposal',
      dataIndex: 'limitCreateProposal',
      align: 'center',
      width: 70,
      render: (data, record) => {
        return (
          <Switch
            checked={!data}
            onClick={() => {
              handleModifyWriteAccount(`/accounts/partner/limit/create-proposals`, record.id, data, async () => {
                await queryClient.invalidateQueries([PARTMER_USER_KEY]);
                await queryClient.invalidateQueries([PARTMER_USER_DETAIL_KEY]);
              });
            }}
          />
        );
      },
    },
    // {
    //   title: <TableColHeader title={'상태'} subTitle={'정상/정지'} />,
    //   key: 'isActive',
    //   sorter: true,
    //   dataIndex: 'isActive',
    //   align: 'center',
    //   width: 70,
    //   render: (data, record) => {
    //     return (
    //       <Switch
    //         checked={data}
    //         onClick={() => {
    //           handleModifyWriteAccount(`/accounts/partner/limit/is-active`, record.id, data, async () => {
    //             await queryClient.invalidateQueries([PARTMER_USER_KEY]);
    //             await queryClient.invalidateQueries([PARTMER_USER_DETAIL_KEY]);
    //           });
    //         }}
    //       />
    //     );
    //   },
    // },
  ];
  const [columns, tableWidth] = useMemo(() => {
    const o = [...COLUMNS];
    const width = o.reduce((a, c) => a + (c.width || c.minWidth || 300), 0);
    return [o, width];
  }, []);

  const [tableScroll, setTableScroll] = useState();
  useEffect(() => {
    const handle = () => {
      if (tableWidth < getClientWidth()) {
        setTableScroll(undefined);
      } else {
        setTableScroll({ x: tableWidth });
      }
    };

    handle();
  }, [tableWidth]);

  // 테이블 페이지네이션
  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 360);

  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 360);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setRefreshDetail(record.id);
        queryClient.invalidateQueries([PARTMER_USER_DETAIL_KEY]);
      },
    };
  };

  const regionData = React.useMemo(()=>{
    if(!regions) return []
    const arr = Object.keys(regions?.map)?.sort((a,b)=>a.toString().localeCompare(b.toString()));
    return [...arr.map((v)=>({label:v?.length===2?regions?.map[v]+' 전체':regions?.map[v?.substring(0,2)]+' '+regions?.map[v], value:v})).sort((a,b)=>{
      if(a.value.substring(0,2)===b.value.substring(0,2)) {
      if(a.label.includes('전체')) return -1;
      if(b.label.includes('전체')) return 1;
        return a.label.localeCompare(b.label)

      };
      return a.value.localeCompare(b.value);
    })];
  },[regions?.map])

  console.log({ppp: params?.region})
  return (
    <div>
      <PageHeader className="site-page-header" title={`시공사 회원 (${params?.isDeleted?'삭제회원':'관리회원'})`} style={{ paddingBottom: 10 }} />
      <ExtraWrapper>
        {/* 필터 윗줄 */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4.5 }}>
          <UpperLine
            listKey={PARTMER_USER_KEY}
            detailKey={PARTMER_USER_DETAIL_KEY}
            setRefresh={setRefreshDetail}
            containerStyle={{ width: '72%' }}
            childrenRight={<div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{fontSize: 12, color: 'rgba(118,118,118)'}}>전문분야</div>
              <FilterSelect

                  defaultValue={userType}
                  style={{width: 120}}
                  options={[
                    {label: '인테리어', value: 'INTERIOR'},
                    {label: '부분공사', value: 'PARTIAL_CONSTRUCTION'},
                    {label: '제조/유통사', value: 'DISTRIBUTOR'},
                    {label: '인플루언서', value: 'INFLUENCER'},
                  ]}
                  onChange={(e) => {
                    setUserType(e);
                  }}
              />
              <div style={{fontSize: 12, color: 'rgba(118,118,118)'}}>지역</div>
              <FilterSelect
                  allowClear
                  placeholder="전체"
                  value={params?.region}
                  style={{width: 120}}
                  options={regionData}
                  onChange={(e) => {
                    setParams(produce((draft) => {
                      console.log({e});
                      draft.region = e;
                    }));
                  }}
              />
            </div>}
          >
            {/* <TreeSelect
              style={{ width: '100%' }}
              // fieldNames={{ label: 'label', value: 'areaCode' }}
              placeholder="지역 선택"
              allowClear
              onChange={(e) => {
                console.log(e);
              }}
              treeDefaultExpandAll
              treeData={regions?.trees}
            /> */}

          </UpperLine>
        </div>
        {/* 필터 아랫줄 */}
        <div style={{
          width: '76%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <LowerLine
              onCheck={() => {
                if (idList.length === checkedList.length) {
                  setCheckedList([]);
                } else {
                  setCheckedList(idList);
                }
              }}
              checked={idList.length === checkedList.length}
              containerStyle={{width: '100%'}}
              left={<ModifyAllButton
                  showText={'견적 가능'}
                  stopText={'불가능'}
                  customStyle={{marginLeft: 40}}
                  showAction={() => {
                    handleAccountShowAndStop('/accounts/partner/limit/create-proposals', checkedList, true, () => {
                    queryClient.invalidateQueries([PARTMER_USER_KEY]);
                    queryClient.invalidateQueries([PARTMER_USER_DETAIL_KEY]);
                  });
                }}
                stopAction={() => {
                  handleAccountShowAndStop('/accounts/partner/limit/create-proposals', checkedList, false, () => {
                    queryClient.invalidateQueries([PARTMER_USER_KEY]);
                    queryClient.invalidateQueries([PARTMER_USER_DETAIL_KEY]);
                  });
                }}
              />
            }
          ></LowerLine>
          {/* <FilterSelect
            value={params?.order}
            onChange={(v) =>
              setParams(
                produce((draft) => {
                  draft.order = v;
                }),
              )
            }
          >
            <Option value="DESC">저장일시 최신순</Option>
          </FilterSelect> */}
        </div>
      </ExtraWrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PaginateTable
          isLoading={isLoading}
          dataSource={allData}
          columns={COLUMNS}
          scroll={{ y: tableHeight - 40 }}
          data={partnerData}
          page={params?.page}
          setParams={setParams}
          setPage={(v) =>
            setParams(
              produce((draft) => {
                draft.page = v;
              }),
            )
          }
          pageSize={params?.limit}
          setPageSize={(v) =>
            setParams(
              produce((draft) => {
                draft.limit = v;
              }),
            )
          }
          onRow={onRow}
          style={{
            borderRadius: 10,
            overflow: 'hidden',

            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
        />
        <div
          style={{
            minWidth: 500,
            overflowY: 'scroll',
            height: document.body.clientHeight - 240,
            paddingLeft: 20,
            boxSizing: 'border-box',
            paddingBottom: 15,
          }}
        >
          <PartnerUserDetail
            styleAction={showStyleModal}
            guideAction={showGuideModal}
            portfolioAction={showPortfolioModal}
            expertBoardAction={showExpertBoardModal}
            trendAction={showExpertTrendModal}
            followAction={showFollowModal}
            goodsAction={showGoodsModal}
            commentAction={showCommentModal}
            regionsAction={showRegionsModal}
            findCustomerAction={findCustomerAction}
            userType={'agencies'}
            detailId={refreshDetail ? refreshDetail : partnerData?.content[0]?.id}
          />
          <CommonStatistics />
          {/* <div style={{ width: '100%', backgroundColor: 'red', marginTop: '20px', borderRadius: '50%' }}></div> */}
        </div>
      </div>
      <UserConstructionModal customColumns={columns} tableHeight={tableHeight} />
      <UserFollowModal />
      <UserGoodsModal />
      <RegionsModal detailId={refreshDetail} regions={regions} />

      <AgencyCommentModal
        detailId={refreshDetail}
        tableHeight={tableHeight}
        isModalOpen={isCommentModal}
        setIsModalOpen={setIsCommentModal}
      />
      <AgencyStyleModal
        detailId={refreshDetail}
        tableHeight={tableHeight}
        isModalOpen={isStyleModal}
        setIsModalOpen={setIsStyleModal}
      />
      <AgencyPortfolioModal
        detailId={refreshDetail}
        tableHeight={tableHeight}
        isModalOpen={isPortfolioModal}
        setIsModalOpen={setIsPortfolioModal}
      />
      <AgencyGuideModal
        detailId={refreshDetail}
        tableHeight={tableHeight}
        isModalOpen={isGuideModal}
        setIsModalOpen={setIsGuideModal}
      />
      <AgencyExpertBoardModal
        detailId={refreshDetail}
        tableHeight={tableHeight}
        isModalOpen={isBoardModal}
        setIsModalOpen={setIsBoardModal}
      />
      <AgencyTrendModal
        detailId={refreshDetail}
        tableHeight={tableHeight}
        isModalOpen={isTrendModal}
        setIsModalOpen={setIsTrendModal}
      />
    </div>
  );
};

export default AccountsAgenciesInteriorIndex;
