import {HeartFilled} from '@ant-design/icons';
import { PageHeader, Button, DatePicker, Input, Select, Space, Switch } from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';
import {parseImageUri} from '../../../../common/utils';
import CommentIcon from '../../../../components/image/CommentIcon';
import Thumbnail from '../../../../components/image/Thumbnail';
import PaginateTable from '../../../../components/table/PaginateTable';
import LocationInfo from '../../../../components/card/LocationInfo/LocationInfo';
import SmallEstimate from '../../../../components/card/SmallEstimate/SmallEstimate';
import { ExtraWrapper, FilterSelect, FilterWhiteButton } from '../../../../components/template/page';
import ContentSlider from '../../../../components/card/ContentSlider/ContentSlider';
import useSearchBar from '../../../../hooks/useSearchBar';
import RoundButton from '../../../../components/button/RoundButton';
import { useGetSavedEstimate, useGetSavedEstimateDetail } from '../../../../apis/queries';
import produce from 'immer';
import TableColHeader from '../../../../components/table/TableColHeader';
import moment from 'moment';
import ShowEstimateModal from '../../../../components/modal/ShowEstimateModal';
import assets from '../../../../assets';
import useUpperLine from '../../../../hooks/useUpperLine';
import { USER_SAVED_ESTIMATE_KEY } from '../../../../apis/queryKeys';
import { Option } from 'antd/lib/mentions';
import styles from '../../../collections/magazines/magazines.module.less';



const ContentCard = React.memo(({ item, commonCodes, setVisibleDetailModal }) => {
  return (
      <div
          onClick={() => {
            setVisibleDetailModal(true);
          }}
          className={styles.slider_div}
          style={{
            cursor: 'pointer',
            backgroundImage: `url(${parseImageUri(item?.thumbnailId)})`,
          }}
      >
        <div className={styles.backdrop} />
        <div className={styles.slider_inner_div}>
          <p className={styles.category_p}>{commonCodes?.map[item?.area]}</p>
          <p className={styles.title_p}>{item?.description}</p>
          <div className={styles.flex_div}>
            <div className={styles.flex_div} style={{ alignItems: 'center' }}>
              <Thumbnail
                  containerStyle={{
                    width: 'max-content',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className={styles.style_profile_img}
                  id={item?.createdBy?.profileImageId}
              />
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <p className={styles.category_p} style={{ marginLeft: 10 }}>
                  {item?.createdBy?.nickname}
                </p>
              </div>
            </div>
            <div className={styles.flex_div}>
              <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
              >
                <HeartFilled style={{ fontSize: 18, color: '#d9d9d9' }} />
                <p className={styles.style_button_count_p}>{item?.likeCount}</p>
              </div>
              <div
                  style={{
                    marginLeft: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
              >
                <CommentIcon style={{ fontSize: 18, color: '#d9d9d9' }} />
                <p className={styles.style_button_count_p}>{item?.commentCount ?? 0}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
});
const SaveEstimate = () => {
  const { id } = useParams();
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      keyword: null,
      order: 'DESC',
    }),
    [],
  );
  const { commonCodes } = useSelector((state) => state.common);
  //필터 윗줄 생성(keword, filter params 포함, query param 에 spread 구문으로 삽입)
  const filters = React.useMemo(() => {
    return [
      {
        title: '신청여부',
        key: 'hasConstructionRequest',
        options: [undefined, [true, '신청'], [false, '미신청']],
      },
    ];
  }, []);
  const { params: upperParam, UpperLine } = useUpperLine(filters);

  const [params, setParams] = React.useState(initialParams);
  const { data } = useGetSavedEstimate({
    id,
    params: { ...params, ...upperParam },
    config: {
      enabled: !!id,
    },
  });

  const [visibleModal, setVisibleModal] = React.useState(false);
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 380);
  const [selectedId, setSelectedId] = React.useState(undefined);
  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 360);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const { data: detail, refetch } = useGetSavedEstimateDetail({ id, selectedId, config: { enabled: !!selectedId } });
  React.useEffect(() => {
    if (selectedId) {
      refetch();
    }
  }, [selectedId]);
  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setSelectedId(record?.id);
      },
    };
  };

  const columns = React.useMemo(
    () => [
      {
        title: '스타일',
        key: 'thumbnailId',
        dataIndex: 'thumbnailId',
        width: 40,
        align: 'center',
        render: (v, record) => <Thumbnail id={v} />,
      },
      {
        title: <TableColHeader title="견적명" subTitle="주소" />,
        key: 'estimate',
        dataIndex: 'estimate',
        width: 80,
        align: 'center',
        render: (data, record) => (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <span
              style={{
                fontSize: '14px',
                fontWeight: '600',
              }}
            >
              {record?.title}
            </span>
            <span
              style={{
                fontSize: '12px',
                color: '#767676',
              }}
            >
              {record?.address}
            </span>
          </div>
        ),
      },
      {
        title: '작성일시',
        key: 'createdAt',
        dataIndex: 'createdAt',
        width: 40,
        align: 'center',
        render: (v) => <span>{moment(v).format('YYYY-MM-DD HH:mm')}</span>,
      },
      {
        title: '공사도움받기 신청여부',
        key: 'hasConstructionRequest',
        dataIndex: 'hasConstructionRequest',
        width: 40,
        align: 'center',
        render: (v) => (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2px' }}>
            <img src={v ? assets.checked : assets.unChecked} alt={`checked_${v}`} style={{ width: 14, height: 14 }} />
            <span
              style={{
                fontSize: '12px',
                fontWeight: '600',
                color: v ? '#1e3d83' : '#767676',
              }}
            >
              {v ? '신청' : '미신청'}
            </span>
          </div>
        ),
      },
      {
        title: '견적금액',
        key: 'totalPrice',
        dataIndex: 'totalPrice',
        width: 40,
        align: 'center',
        render: (v) => (
          <span
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#1e3d83',
            }}
          >
            {v?.toLocaleString()}
          </span>
        ),
      },
    ],
    [],
  );
  return (
    <div>
      <div>
        <PageHeader className="site-page-header" title="저장된 견적서" style={{ paddingBottom: 10 }} />
        <ExtraWrapper>
          {/* 필터 윗줄 */}
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4.5 }}>
            <UpperLine containerStyle={{width:'65%'}} listKey={USER_SAVED_ESTIMATE_KEY} detailKey={'sde'} />
          </div>
          {/* 필터 아랫줄 */}
          <div style={{ width: '65%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <div style={{width:150}}>
            <FilterSelect
              value={params?.order}
              onChange={(v) => {
                setParams(
                  produce((draft) => {
                    draft.order = v;
                  }),
                );
              }}
            >
              <Option value="DESC">저장일시 최신순</Option>
            </FilterSelect>
            </div>
          </div>
        </ExtraWrapper>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PaginateTable
          dataSource={data?.content}
          columns={columns}
          scroll={{ y: tableHeight - 40 }}
          data={data}
          page={params.page}
          setPage={(v) =>
            setParams(
              produce((draft) => {
                draft.page = v;
              }),
            )
          }
          pageSize={params.limit}
          setPageSize={(v) =>
            setParams(
              produce((draft) => {
                draft.limit = v;
              }),
            )
          }
          onRow={onRow}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '65%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
        />
        <div
          style={{ width: 500, overflowY: 'scroll', overflowX: 'hidden', height: document.body.clientHeight - 240 }}
        >
          {detail && (
            <>
              <SmallEstimate visibleModal={() => setVisibleModal(true)} isFirst={true} data={detail} />
              <LocationInfo data={detail} />
              <ContentSlider  leftLine={true} >
                {detail?.areas?.map((v) => (
                    <ContentCard setVisibleDetailModal={() => {}} item={v?.subContent} commonCodes={commonCodes} />
                ))}
              </ContentSlider>
            </>
          )}
        </div>
      </div>
      <ShowEstimateModal
        visible={visibleModal}
        setVisible={setVisibleModal}
        data={detail}
        handleCancel={() => setVisibleModal(false)}
      />
    </div>
  );
};

export default SaveEstimate;
