import { Image, Modal, Switch } from 'antd';
import React from 'react';
import PaginateTable from '../table/PaginateTable';
import { dummyData, dummyDataAgencyGuide } from '../../data/dummy';
import { ExtraWrapper, FilterButton, FilterInput, FilterSelect, TableCheckbox, TableSwitch } from '../template/page';
import TableColHeader from '../table/TableColHeader';
import moment from 'moment';
import { useGetPartnerGuide, useGetPartnerPost } from '../../apis/queries';
import { CommonCodeContext } from '../../context/CommonCodeContext';
import assets from '../../assets';
import { parseImageUri } from '../../common/utils';
import AgencyBoardModifyModal from '../../pages/writing/agency/AgencyBoardModifyModal';
import useUpperLine from '../../hooks/useUpperLine';
import { PARTMER_POST_KEY } from '../../apis/queryKeys';
import { Option } from 'antd/lib/mentions';

const AgencyExpertBoardModal = ({ detailId, tableHeight, isModalOpen, setIsModalOpen }) => {
  //데이터 필터
  const filters = React.useMemo(() => {
    return [
      {
        title: '기준정렬',
        key: 'sort',
        options: [undefined, ['createdAt', '시간순'], ['viewCount', '조회순'], ['commentCount', '댓글순']],
      },
    ];
  }, []);
  const { params: upperParam, UpperLine } = useUpperLine(filters);

  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: null,
      sort: null,
    }),
    [],
  );
  const [page, setPage] = React.useState(initialParams.page);
  const [pageSize, setPageSize] = React.useState(20);
  const [order, setOrder] = React.useState('DESC');
  const customColumns = [
    {
      title: '대표이미지',
      dataIndex: 'thumbnailId',
      key: 'thumbnailId',
      width: 30,
      align: 'center',
      render: (data, text) => {
        return (
          <Image style={{ width: 50, height: 50 }} src={data ? parseImageUri(data) : assets.icons.blank_profile} />
        );
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      width: 50,
      render: (data, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <TableColHeader title={data} />
          </div>
        );
      },
    },
    {
      title: <TableColHeader title={'작성자'} subTitle={'작성일시'} />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (data, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <TableColHeader title={record.createdBy.nickname} subTitle={data} />
          </div>
        );
      },
    },
    // {
    //   title: <TableColHeader title={'추천여부'} />,
    //   dataIndex: 'recommend',
    //   key: 'recommend',
    //   width: 50,
    //   align: 'center',
    //   render: (v) => <Switch defaultChecked={v} />,
    // },
    {
      title: '컨텐츠수정',
      dataIndex: 'modify',
      key: 'modify',
      width: 50,
      align: 'center',
      render: (data, record) => (
        <button
          style={{ padding: '8px 19px', border: '1px solid #1e3d83', color: '#1e3d83', fontWeight: 'bold' }}
          onClick={() => {
            setExpertId(record.id);
            setVisibleModifyModal(true);
          }}
        >
          내용보기
        </button>
      ),
    },
  ];
  const { data: agencyData } = useGetPartnerPost({
    id: detailId,
    params: { page: page, limit: pageSize, order: order, ...upperParam },
  });

  const { codeMapCOTP } = React.useContext(CommonCodeContext);
  const [visibleModifyModal, setVisibleModifyModal] = React.useState(false);
  const [expertId, setExpertId] = React.useState(agencyData?.content[0]?.id);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 200,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
      }}
      closable={true}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      maskClosable={true}
      okText={'확인'}
      cancelText={'수정'}
    >
      <div style={{ padding: '35px 25px' }}>
        <p style={{ fontSize: 24, fontWeight: 600 }}>전문가들의 수다</p>
        <ExtraWrapper>
          {/* 필터 윗줄 */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 4.5,
              width: '100%',
              position: 'relative',
            }}
          >
            <UpperLine listKey={PARTMER_POST_KEY} />
          </div>
          {/* 필터 아랫줄 */}
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <FilterSelect value={order} onChange={(v) => setOrder(v)}>
              <Option value="DESC">저장일시 최신순</Option>
            </FilterSelect>
          </div>
        </ExtraWrapper>
        <PaginateTable
          customHeight={true}
          dataSource={agencyData?.content}
          columns={customColumns}
          data={agencyData}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          scroll={{ y: tableHeight - 240 }}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '100%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
        />
      </div>
      <AgencyBoardModifyModal
        detailId={expertId}
        isModalModify={visibleModifyModal}
        setIsModalModify={setVisibleModifyModal}
      />
    </Modal>
  );
};

export default AgencyExpertBoardModal;
