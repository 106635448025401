import { Checkbox, Image, Modal, Switch } from 'antd';
import React from 'react';
import PaginateTable from '../table/PaginateTable';
import { dummyData, dummyDataAgencyGuide } from '../../data/dummy';
import { ExtraWrapper, FilterButton, FilterInput, FilterSelect, TableCheckbox, TableSwitch } from '../template/page';
import TableColHeader from '../table/TableColHeader';
import moment from 'moment';
import { useGetConstructionCategories, useGetPartnerGuide } from '../../apis/queries';
import { CommonCodeContext } from '../../context/CommonCodeContext';
import { parseImageUri } from '../../common/utils';
import assets from '../../assets';
import WritePostModal from './WritePostModal';
import { useQueryClient } from 'react-query';
import useUpperLine from '../../hooks/useUpperLine';
import ModalLowerLine from '../ModalLowerLine';
import { Option } from 'antd/lib/mentions';
import ModifyAllButton from '../button/ModifyAllButton';
import { restApi } from '../../apis';
import { PARTMER_GUIDE_KEY } from '../../apis/queryKeys';

const AgencyGuideModal = ({ detailId, tableHeight, isModalOpen, setIsModalOpen }) => {
  const queryClient = useQueryClient();
  //데이터 필터
  const { data: constructionCategories } = useGetConstructionCategories();
  const filters = React.useMemo(() => {
    let categories = constructionCategories?.tree.map((v) => [v.categoryId, v.categoryName]);
    categories?.unshift([null, '전체']);
    return [
      {
        title: '카테고리',
        key: 'constructionCategory',
        options: categories,
      },
    ];
  }, [constructionCategories]);
  const { params: upperParam, UpperLine } = useUpperLine(filters);

  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: null,
      sort: null,
    }),
    [],
  );
  const [page, setPage] = React.useState(initialParams.page);
  const [pageSize, setPageSize] = React.useState(20);
  const [order, setOrder] = React.useState('DESC');

  // 모두 선택 버튼로직
  const [checkedList, setCheckedList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const { data: agencyData } = useGetPartnerGuide({
    id: detailId,
    params: { page: page, limit: pageSize, order: order, ...upperParam },
  });
  const allData = React.useMemo(() => {
    return agencyData?.content?.map((v) => ({
      data: v,
      id: v?.id,
    }));
  }, [agencyData]);

  React.useEffect(() => {
    if (!!allData) {
      const idList_ = allData?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [allData]);

  //모두 변경 버튼
  const handleConfirmed = React.useCallback(
    (type) => {
      const ids = checkedList;
      restApi
        .put(`/contents`, {
          ids,
          isConfirmed: type,
        })
        .then((res) => {
          queryClient.invalidateQueries(PARTMER_GUIDE_KEY);
        });
    },
    [checkedList],
  );

  const { codeMapCOTP } = React.useContext(CommonCodeContext);
  const [visibleModifyModal, setVisibleModifyModal] = React.useState(false);
  const [guideId, setGuideId] = React.useState(agencyData?.content[0]?.id);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const customColumns = React.useMemo(() => {
    return [
      {
        title: '선택',
        dataIndex: 'data',
        key: 'data',
        width: 20,
        align: 'center',
        render: (data, record) => {
          return (
            <>
              <Checkbox
                key={`${record.id}_guide_check`}
                checked={checkedList.includes(record.id)}
                onClick={() => {
                  if (checkedList.includes(record.id)) {
                    setCheckedList(checkedList.filter((v) => v !== record.id));
                  } else {
                    setCheckedList([...checkedList, record.id]);
                  }
                }}
              />
            </>
          );
        },
      },
      {
        title: '대표이미지',
        dataIndex: 'thumbnailId',
        key: 'thumbnailId',
        width: 50,
        align: 'center',
        render: (data, record) => (
          <Image style={{ width: 50, height: 50 }} src={data ? parseImageUri(data) : assets.icons.blank_profile} />
        ),
      },
      {
        title: <TableColHeader title={'제목'} subTitle={'타입'} />,
        dataIndex: 'title',
        key: 'title',
        width: 50,
        render: (data, record) => <TableColHeader title={data} subTitle={codeMapCOTP[record.constructionType]} />,
      },
      {
        title: <TableColHeader title={'등록자'} subTitle={'등록일시'} />,
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: 50,
        render: (data, record) => (
          <>
            <TableColHeader title={data.nickname} subTitle={moment(data.createdAt).format('YYYY-MM-DD HH:mm')} />
          </>
        ),
      },
      {
        title: <TableColHeader title={'승인자'} subTitle={'승인일시'} />,
        dataIndex: 'handleConfirmationBy',
        key: 'handleConfirmationBy',
        width: 50,
        render: (data, record) => {
          return (
            <>
              {record.handleConfirmAt && record.handleConfirmationBy ? (
                <TableColHeader
                  title={record.handleConfirmationBy.nickname}
                  subTitle={moment(record.handleConfirmAt).format('YYYY-MM-DD HH:mm')}
                />
              ) : (
                <p style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}>미승인</p>
              )}
            </>
          );
        },
      },
      // {
      //   title: <TableColHeader title={'추천여부'} subTitle={'추천/일반'} />,
      //   dataIndex: 'created_at',
      //   key: 'created_at',
      //   width: 50,
      //   align: 'center',
      //   render: (v) => <Switch />,
      // },
      {
        title: <TableColHeader title={'승인여부'} subTitle={'승인/대기'} />,
        dataIndex: 'isConfirmed',
        key: 'isConfirmed',
        width: 50,
        align: 'center',
        render: (data, record) => (
          <Switch
            checked={data}
            onClick={() =>
              restApi
                .put(`/contents`, {
                  ids: [record.id],
                  isConfirmed: data !== false ? false : true,
                })
                .then((res) => {
                  queryClient.invalidateQueries(PARTMER_GUIDE_KEY);
                })
            }
          />
        ),
      },
      {
        title: '컨텐츠수정',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 50,
        align: 'center',
        render: (data, record) => (
          <button
            style={{ padding: '8px 19px', border: '1px solid #1e3d83', color: '#1e3d83', fontWeight: 'bold' }}
            onClick={() => {
              setGuideId(record.id);
              setVisibleModifyModal(true);
            }}
          >
            내용보기
          </button>
        ),
      },
    ];
  }, [checkedList, idList]);
  return (
    <Modal
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 200,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
      }}
      closable={true}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      maskClosable={true}
      okText={'확인'}
      cancelText={'수정'}
    >
      <div style={{ padding: '35px 25px' }}>
        <p style={{ fontSize: 24, fontWeight: 600 }}>작성한 공사가이드</p>
        <ExtraWrapper>
          {/* 필터 윗줄 */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 4.5,
              width: '100%',
              position: 'relative',
            }}
          >
            <UpperLine listKey={PARTMER_GUIDE_KEY} />
          </div>
          {/* 필터 아랫줄 */}
          <ModalLowerLine
            containerStyle={{ width: '100%' }}
            onCheck={() => {
              if (idList.length === checkedList.length) {
                setCheckedList([]);
              } else {
                setCheckedList(idList);
              }
            }}
            checked={idList.length === checkedList.length}
            text={'모두선택'}
            id={'agency-guide-modal-check'}
          >
            <ModifyAllButton
              showText={'승인'}
              stopText={'대기'}
              customStyle={{ marginLeft: 40 }}
              showAction={() => {
                handleConfirmed(true);
              }}
              stopAction={() => {
                handleConfirmed(false);
              }}
            />

            <div style={{ position: 'absolute', right: 0 }}>
              <FilterSelect value={order} onChange={(v) => setOrder(v)}>
                <Option value="DESC">저장일시 최신순</Option>
              </FilterSelect>
            </div>
          </ModalLowerLine>
        </ExtraWrapper>
        <PaginateTable
          customHeight={true}
          dataSource={agencyData?.content}
          columns={customColumns}
          data={agencyData}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          scroll={{ y: tableHeight - 240 }}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '100%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
        />
      </div>
      <WritePostModal
        visible={visibleModifyModal}
        setVisible={setVisibleModifyModal}
        handleOk={() => {}}
        handleCancel={() => {
          setVisibleModifyModal(false);
        }}
        isEdit={true}
        selectedId={guideId}
        type="construction-guides"
      />
    </Modal>
  );
};

export default AgencyGuideModal;
