import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, DatePicker, Image, Modal, PageHeader, Table } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { getAPIHost, restApi } from '../../../apis';
import {
  Link,
  useLocation,
  useNavigate, useParams,
  useSearchParams,
} from 'react-router-dom';
import qs from 'query-string';
import { PERIOD_OPTIONS, POSITION_LABELS } from '../../../common/define';
import moment from 'moment';
import CommentIcon from '../../../components/image/CommentIcon';
import PaginateTable from '../../../components/table/PaginateTable';
import CommonStatistics from '../../../components/card/CommonStatistics,js/CommonStatistics';
import Slider from 'react-slick';
import styles from './AgencyBoard.module.less';
import profileImage from '../../../assets/dummy/user/02.jpg';
import modalImage from '../../../assets/dummy/02/02-01.jpg';
import modalImage2 from '../../../assets/dummy/02/02-02.jpg';
import {
  CloseCircleFilled,
  CloseCircleOutlined,
  DollarCircleOutlined,
  HeartFilled,
  UserOutlined,
  WechatFilled,
} from '@ant-design/icons';
import ModifyTrandModal from '../../../components/modal/ModifyTrandModal';
import Comment from '../../../components/comment/Comment';
import {
  ExtraWrapper,
  FilterButton,
  FilterInput,
  FilterSelect,
  TableCheckbox,
  TableSwitch,
} from '../../../components/template/page';
import TableColHeader from '../../../components/table/TableColHeader';
import { useGetExpertTalk, useGetExpertTalkDetail } from '../../../apis/queries';
import { getFileId, parseImageUri } from '../../../common/utils';
import { useMutation, useQueryClient } from 'react-query';
import { EXPERT_TALK_LIST_DETAIL_KEY, EXPERT_TALK_LIST_KEY } from '../../../apis/queryKeys';
import produce from 'immer';
import AgencyAddModal from './AgencyAddModal';
import AgencyBoardModifyModal from './AgencyBoardModifyModal';
import Thumbnail from '../../../components/image/Thumbnail';
import useSearchBar from '../../../hooks/useSearchBar';
import useUpperLine from '../../../hooks/useUpperLine';
import LowerLine from '../../../components/LowerLine';
import { Option } from 'antd/lib/mentions';

export const TplItem = ({ item, active, style }) => {
  return (
    <div
      key={`${item.id}`}
      style={{ display: 'flex', minWidth: '25%', maxWidth: '25%', alignItems: 'center', justifyContent: 'center' }}
    >
      <div
        style={{
          textAlign: 'center',
          width: 80,
          height: 80,
          borderRadius: 30,
          backgroundColor: '#fff',
          boxShadow: '3px 3px 3px rgba(0,0,0,0.1)',
          margin: '5px',
        }}
      >
        <DollarCircleOutlined style={{ color: active ? '#1e3d83' : '#d9d9d9', fontSize: 40 }} />
        {/* <img
          source={assets.tpl_category[`main_${item.categoryId}`]}
          style={[
            styles.tplItemIcon,
            {tintColor: active ? '#1e3d83' : '#d9d9d9'},
          ]}
          resizeMode={'contain'}
        /> */}
        <p
          style={{
            marginTop: 10,
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 'bold',
            color: '#767676',
          }}
        >
          {item.categoryName}
        </p>
      </div>
    </div>
  );
};

const AgencyBoard = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [refreshDetail, setRefreshDetail] = React.useState();
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalModify, setIsModalModify] = useState(false);
  const [isAddModal, setIsAddModal] = React.useState(false);

  //필터 윗줄 생성(keword, filter params 포함, query param 에 spread 구문으로 삽입)
  const filters = React.useMemo(() => {
    return [
      {
        title: '기준정렬',
        key: 'sort',
        options: [undefined, ['createdAt', '시간순'], ['viewCount', '조회순'], ['commentCount', '댓글순']],
      },
    ];
  }, []);
  const args = qs.parse(location?.search);
  const { params: upperParam, UpperLine } = useUpperLine(filters);

  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: 'DESC',
      accountId: args?.accountId,
    }),
    [args],
  );
  const [params, setParams] = React.useState(initialParams);
  React.useEffect(() => {
    if (upperParam) {
      setParams((prev) => ({ ...prev, ...upperParam }));
    }
  }, [upperParam]);

  // 모두 선택 버튼로직
  const [checkedList, setCheckedList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const { data: boardData } = useGetExpertTalk({

    params,
  });
  const allData = React.useMemo(() => {
    return boardData?.content?.map((v) => ({
      data: v,
      id: v?.id,
    }));
  }, [boardData]);
  React.useEffect(() => {
    if (!!allData) {
      const idList_ = allData?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [allData]);
  React.useEffect(() => {
    setRefreshDetail(boardData?.content[0]?.id);
  }, [boardData]);
  const { data: detailData } = useGetExpertTalkDetail({ id: refreshDetail, params: {} });

  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    if (imageId) {
      setDetailModify(
        produce((draft) => {
          draft.images.push(imageId);
        }),
      );
    }
  };

  const [detailModify, setDetailModify] = React.useState({
    title: detailData?.title ?? '',
    content: detailData?.content ?? '',
    images: detailData?.images ?? [],
  });

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setRefreshDetail(record.id);
        queryClient.invalidateQueries([EXPERT_TALK_LIST_DETAIL_KEY]);
      },
    };
  };
  const settings = {
    className: 'center',
    infinite: false,
    centerPadding: '60px',
    slidesToShow: 5,
    swipeToSlide: true,
    afterChange: function (index) {
      console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
    },
  };

  const columns = [
    {
      title: '선택',
      dataIndex: 'data',
      key: 'data',
      width: 40,
      align: 'center',
      render: (data, record) => {
        return (
          <>
            <Checkbox
              key={`${record.id}_check`}
              checked={checkedList.includes(record.id)}
              onClick={() => {
                if (checkedList.includes(record.id)) {
                  setCheckedList(checkedList.filter((v) => v !== record.id));
                } else {
                  setCheckedList([...checkedList, record.id]);
                }
              }}
            />
          </>
        );
      },
    },
    {
      title: '이미지',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      align: 'center',
      render: (id, record) => {
        return (
          <div style={{ position: 'relative', width: 50, height: 50 }}>
            <button
              style={{
                position: 'absolute',
                top: -2,
                right: -5,
                zIndex: 10,
                padding: 0,
                width: 18,
                height: 18,
                border: 'none',
                backgroundColor: 'rgba(0,0,0,0)',
              }}
            >
              <CloseCircleFilled
                style={{ fontSize: 18, color: '#1e3d83' }}
                onClick={() => {
                  Modal.confirm({
                    title: '삭제',
                    content: '해당 글을 삭제하시겠습니까?',
                    okText: '확인',
                    cancelText: '취소',
                    async onOk() {
                      try {
                        await restApi.delete(`/posts/expert-talk/${record?.id}`);
                        Modal.confirm({
                          title: '완료',
                          content: '삭제가 완료되었습니다',
                          onOk() {
                            queryClient.invalidateQueries([EXPERT_TALK_LIST_KEY]);
                          },
                          cancelButtonProps: {
                            style: { display: 'none' },
                          },
                          okText: '확인',
                        });
                      } catch (error) {
                        Modal.warning({
                          title: '실패했습니다',
                          content: error.response.data.message,
                        });
                      }
                    },
                  });
                }}
              />
            </button>
            <Thumbnail id={record.thumbnailId} />
          </div>
        );
      },
    },
    {
      title: <TableColHeader title={'제목'} subTitle={'등록구분'} />,
      dataIndex: 'title',
      key: 'title',
      render: (data, row) => <p style={{ color: '#1e3d83' }}>{data}</p>,
    },
    {
      title: <TableColHeader title={'작성자'} subTitle={'작성일시'} />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 160,
      align: 'center',
      render: (value, record) => (
        <div>
          <p>{record.createdBy?.nickname}</p>
          <p>{moment(value).format('YYYY-MM-DD HH:mm')}</p>
        </div>
      ),
    },
  ];

  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 320);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalModify(true);
    setDetailModify(
      produce((draft) => {
        draft.title = detailData?.title;
        draft.content = detailData?.content;
        draft.images = detailData?.images;
      }),
    );
  };
  const handleModifyOK = async () => {
    try {
      await restApi.put(`/posts/expert-talk/${refreshDetail}`, detailModify);
      Modal.confirm({
        title: '완료',
        content: '수정이 완료되었습니다',
        onOk() {
          window.location.reload();
          // setIsModalModify(false);
        },
        cancelButtonProps: {
          style: { display: 'none' },
        },
        okText: '확인',
      });
    } catch (error) {
      Modal.warning({
        title: '실패했습니다',
        content: error.response.data.message,
      });
      queryClient.invalidateQueries([EXPERT_TALK_LIST_DETAIL_KEY]);
      queryClient.invalidateQueries([EXPERT_TALK_LIST_KEY]);
    }
  };
  const handleDelete = () =>
    Modal.confirm({
      title: '일괄 삭제',
      content: '선택된 항목들을 삭제하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      async onOk() {
        try {
          await restApi.delete(`/posts/ask-me`, { data: { ids: checkedList } });
          Modal.confirm({
            title: '완료',
            content: '삭제가 완료되었습니다',
            onOk() {
              window.location.reload();
              // setIsModalModify(false);
            },
            cancelButtonProps: {
              style: { display: 'none' },
            },
            okText: '확인',
          });
        } catch (error) {
          Modal.warning({
            title: '오류',
            content: error.response.data.message,
          });
        }
      },
    });
  const handleModifyCancel = () => {
    setIsModalModify(false);
  };
  return (
    <>
      <div>
        <PageHeader className="site-page-header" title="시공사 게시판(전문가들의 수다)" style={{ paddingBottom: 10 }} />
        <ExtraWrapper>
          {/* 필터 윗줄 */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 4.5,
              marginRight: 20,
              width:'100%'
            }}
          >
            <div style={{  display: 'flex',width:'65%' }}>
              <UpperLine containerStyle={{width: '100%',marginRight:'1%',justifyContent:'space-between'}} listKey={EXPERT_TALK_LIST_KEY} detailKey={EXPERT_TALK_LIST_DETAIL_KEY} setRefresh={setRefreshDetail} >
                <div> </div>
              </UpperLine>
            </div>
            <FilterButton
              onClick={() => {
                setIsAddModal(true);
              }}
            >
              새로 작성
            </FilterButton>
          </div>
          {/* 필터 아랫줄 */}
          <div style={{ width: '65%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <LowerLine
              onCheck={() => {
                if (idList.length === checkedList.length) {
                  setCheckedList([]);
                } else {
                  setCheckedList(idList);
                }
              }}
              left={<div
                  style={{width: 260, display: 'flex', alignItems: 'center'}}>
                <div
                    onClick={handleDelete}
                    style={{cursor: 'pointer', marginLeft: 10}}
                    className={styles.filterWrapper}
                >
                  <img
                    src={require('../../../assets/delete.png')}
                    alt="delete"
                    style={{
                      width: '18.2px',
                      height: '21.2px',
                    }}
                  />
                  <span className={styles.filterText}>항목삭제</span>
                </div>
              </div>}
              checked={idList.length === checkedList.length}
              containerStyle={{height: 50}}
            >

            </LowerLine>
            <div style={{width:180}}>
            <FilterSelect
              value={params?.order}
              onChange={(v) => {
                setParams(
                  produce((draft) => {
                    draft.order = v;
                  }),
                );
              }}
            >
              <Option value="DESC">저장일시 최신순</Option>
            </FilterSelect>
            </div>
          </div>
        </ExtraWrapper>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <PaginateTable
            dataSource={boardData?.content}
            columns={columns}
            scroll={{ y: tableHeight - 40 }}
            data={boardData}
            page={params?.page}
            setPage={(v) =>
              setParams(
                produce((draft) => {
                  draft.page = v;
                }),
              )
            }
            pageSize={params?.limit}
            setPageSize={(v) =>
              setParams(
                produce((draft) => {
                  draft.limit = v;
                }),
              )
            }
            onRow={onRow}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '65%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
            }}
          />
          <div
            style={{
              width:500,
              paddingLeft: 20,
              overflowY: 'scroll',
              height: document.body.clientHeight - 240,
              paddingBottom: 15,
            }}
          >
            <h3>컨텐츠 미리보기</h3>
            <div style={{ height: 400, backgroundColor: 'white', borderRadius: 10, overflow: 'hidden' }}>
              <div>
                <div
                  className={[styles.slider_div]}
                  style={{
                    backgroundColor: 'black',
                    backgroundImage:
                      detailData?.images.length > 0
                        ? `url(${parseImageUri(detailData?.images[0])})`
                        : `url(../../../../src/assets/dummy/01/01-07.jpg)`,
                  }}
                >
                  <div className={styles.backdrop} />
                  <div className={styles.slider_inner_div}>
                    <p className={styles.title_p}>{detailData?.title}</p>
                    <div className={styles.flex_div}>
                      <div className={styles.flex_div} style={{ alignItems: 'center' }}>
                        <Thumbnail
                          className={styles.style_profile_img}
                          id={detailData?.createdBy?.profileImageId}
                          alt="프로필 이미지"
                        />
                        <p className={styles.category_p} style={{ marginLeft: 10, width: '100%' }}>
                          {detailData?.createdBy?.nickname}
                        </p>
                      </div>
                      <div className={styles.flex_div}>
                        <div style={{ marginLeft: 5 }}>
                          <WechatFilled style={{ fontSize: 18, color: '#e8e8e8' }} />
                          <p className={styles.style_button_count_p} style={{ textAlign: 'center' }}>
                            {detailData?.commentCount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.trand_card_div}>
                <div style={{ display: 'flex', position: 'relative' }}>
                  {detailData?.images.length > 0 ? (
                    [...detailData?.images].slice(1).map((item) => {
                      return (
                        <div
                          className={styles.trand_thumbnail_img}
                          style={{
                            zIndex: 10,
                          }}
                        >
                          <img style={{ width: '100%', height: '100%' }} src={parseImageUri(item)} alt="" />
                        </div>
                      );
                    })
                  ) : (
                    <div style={{ width: 40, height: 20 }} />
                  )}
                </div>
                <p className={styles.desc_p} style={{ maxHeight: 63, overflow: 'hidden', marginTop: 10 }}>
                  {detailData?.content}
                </p>
                <button onClick={showModal}>더보기</button>
              </div>
            </div>
            <CommonStatistics />
          </div>
        </div>
      </div>
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        okText={'수정하기'}
        cancelText={'취소하기'}
      >
        <div style={{ width: '100%', backgroundColor: 'black' }}>
          <img
            style={{
              display: 'block',
              height: '450px',
              marginLeft: 'auto',
              marginRight: 'auto',
              backgroundColor: 'black',
            }}
            src={detailData?.images?.length > 0 && parseImageUri(detailData?.images[0])}
            alt=""
          />
        </div>
        <div style={{ padding: '50px 100px' }}>
          {detailData?.images.length > 1 && (
            <Slider {...settings}>
              {[...detailData?.images].length > 1 &&
                [...detailData?.images].slice(1).map((item, index) => {
                  return (
                    <div>
                      <img style={{ width: 108, height: 108, borderRadius: 10 }} src={parseImageUri(item)} alt="" />
                    </div>
                  );
                })}
            </Slider>
          )}
          <p style={{ margin: '10px 0', fontWeight: 600, fontSize: 17 }}>{detailData?.title}</p>
          <div className={styles.flex_div}>
            <div className={styles.flex_div} style={{ alignItems: 'center' }}>
              <Thumbnail
                className={styles.style_profile_img}
                id={detailData?.createdBy?.profileImageId}
                alt="프로필 이미지"
              />
              <div>
                <p className={styles.category_p} style={{ marginLeft: 10, color: 'black', fontWeight: 'bold' }}>
                  {detailData?.createdBy?.nickname}
                </p>
                <p className={styles.category_p} style={{ marginLeft: 10, color: '#787878' }}>
                  {detailData?.createdAt}
                </p>
              </div>
            </div>
            <div className={styles.flex_div}>
              <div>
                <HeartFilled style={{ fontSize: 18, color: '#d9d9d9' }} />
                <p className={styles.style_button_count_p} style={{ color: '#999999', textAlign: 'center' }}>
                  {detailData?.likeInfo?.likeCount}
                </p>
              </div>
              <div style={{ marginLeft: 5 }}>
                <CommentIcon style={{ fontSize: 18, color: '#d9d9d9' }} />
                <p className={styles.style_button_count_p} style={{ color: '#999999', textAlign: 'center' }}>
                  {detailData?.commentCount}
                </p>
              </div>
            </div>
          </div>
          <p style={{ margin: '25px 0 50px', fontSize: 14, color: '#767676' }}>{detailData?.content}</p>
          <div className={styles.modify_input_wrapper_div}>
            <input
              type="text"
              defaultValue={'https://www.naver.com/dskfjhuwqerui@welkij124909'}
              className={styles.modify_title_input}
              style={{ fontWeight: 'normal', color: '#999', fontSize: 14 }}
              maxLength={80}
            />
          </div>
        </div>
        <div style={{ padding: '50px 100px' }}>
          <Comment id={refreshDetail} type={'POST'} />
        </div>
      </Modal>
      <AgencyBoardModifyModal
        detailId={refreshDetail}
        isModalModify={isModalModify}
        setIsModalModify={setIsModalModify}
      />
      <AgencyAddModal modalOpen={isAddModal} setModalOpen={setIsAddModal} settings={settings} />
    </>
  );
};

export default AgencyBoard;
