import React, { useEffect, useState } from 'react';
import './App.less';
import BasicLayout from './nav/BasicLayout';
import store from './data';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { me } from './data/auth';
import { QueryClient, QueryClientProvider } from 'react-query';
import { getCommonCodes } from './data/common';
import CommonCodePovider from './context/CommonCodeContext';

const Container = React.memo(() => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadData = async () => {
      await Promise.all([dispatch(me()), dispatch(getCommonCodes())]);
    };

    loadData()
      .catch(console.warn)
      .then(() => setLoading(false));
  }, [dispatch]);

  return <BasicLayout loading={loading} />;
});

const App = () => {
  const queryClient = new QueryClient({defaultOptions:{queries: {refetchOnWindowFocus: false}}});

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <CommonCodePovider>
          <BrowserRouter>
            <Container />
          </BrowserRouter>
        </CommonCodePovider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
