import React from 'react';
import {PageHeader} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import {restApi} from '../../../apis';
import {getTimeAgo, parseImageUri} from '../../../common/utils';
import styles from './index.module.less';
import moment from 'moment';
import PaginateTable from '../../../components/table/PaginateTable';
import UserDetail from '../../../components/card/userdetail/UserDetail';
import CommonStatistics
  from '../../../components/card/CommonStatistics,js/CommonStatistics';
import {ExtraWrapper, FilterSelect} from '../../../components/template/page';
import {CloseOutlined} from '@ant-design/icons';
import {useGetUserList} from '../../../apis/queries';
import produce from 'immer';
import useUpperLine from '../../../hooks/useUpperLine';
import {Option} from 'antd/lib/mentions';
import assets from '../../../assets';

const AccountsUsersIndex = () => {
  const location = useLocation();
  const args = location.search;
  const initialParams = React.useMemo(
      () => ({
        limit: 50,
        page: 0,
        isLifestyleCertified: null,
        isPhoneCertified: null,
        keyword: null,
        order: 'DESC',
        isDeleted:  false,
        ...args,
      }),
      [args],
  );

  const navigate = useNavigate();
  const [params, setParams] = React.useState(initialParams);

  const [selectedId, setSelectedId] = React.useState(null);
  const detailProps = ['customer', null];

  const filters = React.useMemo(() => {
    const phoneCertItems = [
      [null, '전체'],
      [true, 'Y'],
      [false, 'N'],
    ];
    const lifeCertItems = [
      [null, '전체'],
      [true, 'Y'],
      [false, 'N'],
    ];
    const deleted = [
      // [null, '전체'],
      [false, '관리 회원 리스트'],
      [true, '삭제 회원 리스트'],
    ];
    return [
      {
        title: '리스트 구분',
        key: 'isDeleted',
        defaultValue:false,
        options: deleted,
      },
      {
        title: '전화인증',
        key: 'isPhoneCertified',
        options: phoneCertItems,
      },
      {
        title: '라이프스타일',
        key: 'isLifestyleCertified',
        options: lifeCertItems,
      },
    ];
  }, []);
  const {params: upperParam, UpperLine} = useUpperLine(filters);


  React.useEffect(()=>{
    // navigate(`/accounts/user?${new URLSearchParams(params).toString()}`);
  },[params])
  const {data: userList,refetch,isLoading} = useGetUserList({
    params: {...params, ...upperParam},
    config: {
      onSuccess: (data) => {
        if (!selectedId) {
          setSelectedId(data.content[0].id);
        }
      },
    },
  });
  const handleDelete = async (id) => {
    const result = window.confirm(`정말 ${params?.isDeleted?'복구':'삭제'}하시겠습니까?`);
    if (result) {
      try {
        await restApi.put(`/accounts/user/${id}`)
      }catch (e){
        alert(`${params?.isDeleted?'복구':'삭제'}에 실패했습니다.`)
      }
      await refetch();
    }
  }

  const data = React.useMemo(() => {
    return userList?.content?.map((v) => ({
      ...v,
      data: v,
    }));
  }, [userList]);
  React.useEffect(() => {
    if (upperParam) {
      setParams((prev) => ({...prev, ...upperParam}));
    }
  }, [upperParam]);

  const columns = React.useMemo(
      () => [
        {
          title: '상태',
          dataIndex: 'isNew',
          key: 'isNew',
          width: 20,
          align: 'center',
          // showSorterTooltip: false,
          // sorter: true,
          render: (v) => {
            return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  {v ? (
                      <div className={styles.statusLabelWrapper}>
                  <span
                      style={{
                        fontSize: '10px',
                        fontWeight: '900',
                        color: '#fff',
                      }}
                  >
                    NEW
                  </span>
                      </div>
                  ) : null}
                </div>
            );
          },
          children: [
            {
              title: '순서',
              dataIndex: 'data',
              key: 'index',
              width: 20,
              align: 'center',
              // showSorterTooltip: false,
              // sorter: true,
              render: (v, r, i) => {
                return (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      {(userList?.content?.length) - (i)}
                    </div>
                );
              },
            },
          ],
        },

        {
          title: '프로필',
          dataIndex: 'profileImageId',
          key: 'profileImageId',
          width: 20,
          align: 'center',
          render: (data,r) => {
            return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <div
                      className={styles.profileWrapper}
                      style={{
                        position: 'relative',
                        backgroundImage: !data
                            ? assets.icons.blank_profile
                            : `url(${parseImageUri(data)})`,
                      }}
                  >
                    <div onClick={()=>{handleDelete(r.id)}} className={styles.deletebuttonwrapper}>
                      <CloseOutlined
                          style={{
                            color: '#FFF',
                            fontSize: '13px',
                          }}
                      />
                    </div>
                  </div>
                </div>
            );
          },
        },
        {
          title: '닉네임',
          dataIndex: 'nickname',
          key: 'data',
          width: 55,
          sorter: true,
          showSorterTooltip: false,
          align: 'center',
          render: (nickname) => (
              <span
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                  }}
              >
            {nickname}
          </span>
          ),
        },
        {
          title: '견적',
          dataIndex: 'estimateCount',
          key: 'estimateCount',
          width: 20,
          sorter: true,
          showSorterTooltip: false,
          align: 'center',
          render: (v, r) => (
              <button onClick={() => {
                window.open(`/accounts/user/saveestimate/${r.id}`, '_blank', '');
              }} style={{
                textDecoration: 'underline',
                background: 'none',
              }}>{v}</button>
          ),
        },
        {
          title: '공사도움받기',
          children: [
            {
              dataIndex: 'constructionRequestCount',
              title: '신청중',
              key: 'constructionRequestCount',
              width: 18,
              sorter: true,
              showSorterTooltip: false,
              align: 'center',
              render: (v, r) => (
                  <button onClick={() => {
                    window.open(`/accounts/user/save/request/${r.id}?isRunning=true`, '_blank', '');
                  }} style={{
                    textDecoration: 'underline',
                    background: 'none',
                  }}>{v}</button>
              ),
            },
            {
              dataIndex: 'completedConstructionRequestCount',
              title: '완료',
              key: 'completedConstructionRequestCount',
              width: 15,
              sorter: true,
              showSorterTooltip: false,
              align: 'center',
              render: (v, r) => (
                  <button onClick={() => {
                    window.open(`/accounts/user/save/request/${r.id}?isRunning=false`, '_blank', '');
                  }} style={{
                    textDecoration: 'underline',
                    background: 'none',
                  }}>{v}</button>
              ),
            },
          ],

        },
        {
          title: '제안업체수',
          children: [
            {
              dataIndex: 'todayProposalCount',
              title: '금번',
              key: 'todayProposalCount',
              width: 15,
              sorter: true,
              showSorterTooltip: false,
              align: 'center',
              render: (v, r) => (
                  <button onClick={() => {
                    window.open(`/accounts/save/proposal/${r.id}/user?isToday=true`, '_blank', '');
                  }} style={{
                    textDecoration: 'underline',
                    background: 'none',
                  }}>{v}</button>
              ),
            },
            {
              dataIndex: 'proposalCount',
              title: '누적',
              key: 'proposalCount',
              width: 15,
              sorter: true,
              showSorterTooltip: false,
              align: 'center',
              render: (v, r) => (
                  <button onClick={() => {
                    window.open(`/accounts/save/proposal/${r.id}/user`, '_blank', '');
                  }} style={{
                    textDecoration: 'underline',
                    background: 'none',
                  }}>{v}</button>
              ),
            },
          ],

        },
        {
          title: '찜하기',
          children: [
            {
              dataIndex: 'todaySavedProposalCount',
              title: '금번',
              key: 'todaySavedProposalCount',
              width: 15,
              sorter: true,
              showSorterTooltip: false,
              align: 'center',
              render: (v, r) => (
                  <button onClick={() => {
                    window.open(`/accounts/save/proposal/${r.id}/user?isToday=true&isSelected=true`, '_blank', '');
                  }} style={{
                    textDecoration: 'underline',
                    background: 'none',
                  }}>{v}</button>
              ),
            },
            {
              dataIndex: 'savedProposalCount',
              title: '누적',
              key: 'savedProposalCount',
              width: 15,
              sorter: true,
              showSorterTooltip: false,
              align: 'center',
              render: (v, r) => (
                  <button onClick={() => {
                        window.open(`/accounts/save/proposal/${r.id}/user?isSelected=true`, '_blank', '');
                  }} style={{
                    textDecoration: 'underline',
                    background: 'none',
                  }}>{v}</button>
              ),
            },
          ],

        },
        {
          title: '컨텐츠저장',
          children: [
            {
              dataIndex: 'savedStyleCount',
              title: '스타일',
              key: 'savedStyleCount',
              width: 18,
              sorter: true,
              showSorterTooltip: false,
              align: 'center',
              render: (v, r) => (
                  <button onClick={() => {
                    window.open(`/accounts/save/styles/${r.id}/user`, '_blank', '');
                  }} style={{
                    textDecoration: 'underline',
                    background: 'none',
                  }}>{v}</button>
              ),
            },
            {
              dataIndex: 'savedPortfolioCount',
              title: '포트폴리오',
              key: 'savedPortfolioCount',
              width: 18,
              sorter: true,
              showSorterTooltip: false,
              align: 'center',
              render: (v, r) => (
                  <button onClick={() => {
                    window.open(`/accounts/save/portfolios/${r.id}/user`, '_blank', '');
                  }} style={{
                    textDecoration: 'underline',
                    background: 'none',
                  }}>{v}</button>
              ),
            }, {
              dataIndex: 'savedTrendCount',
              title: '트랜드',
              key: 'savedTrendCount',
              width: 18,
              sorter: true,
              showSorterTooltip: false,
              align: 'center',
              render: (v, r) => (
                  <button onClick={() => {
                    window.open(`/accounts/save/trends/${r.id}/user`, '_blank', '');
                  }} style={{
                    textDecoration: 'underline',
                    background: 'none',
                  }}>{v}</button>
              ),
            },
            {
              dataIndex: 'savedConstructionGuideCount',
              title: '가이드',
              key: 'savedConstructionGuideCount',
              width: 18,
              sorter: true,
              showSorterTooltip: false,
              align: 'center',
              render: (v, r) => (
                  <button onClick={() => {
                    window.open(`/accounts/save/construction-guides/${r.id}/user`, '_blank', '');
                  }} style={{
                    textDecoration: 'underline',
                    background: 'none',
                  }}>{v}</button>
              ),
            },
          ],

        },
        {
          title: '가입일시',
          dataIndex: 'createdAt',
          key: 'createdAt',
          sorter: true,
          showSorterTooltip: false,
          width: 40,
          align: 'center',
          render: (v) => (
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{color: '#767676'}}>{getTimeAgo(v)}</span>
                <span style={{color: '#767676'}}>{moment(v).
                    format('YYYY-MM-DD HH:mm')}</span>
              </div>
          ),
        },
        {
          title: '전화인증',
          dataIndex: 'phone',
          key: 'phone',
          width: 15,
          align: 'center',
          sorter: true,
          showSorterTooltip: false,
          render: (v) => <span>{!!v ? 'Y' : 'N'}</span>,
        },
        {
          title: 'L/S체크',
          dataIndex: 'lifestyle',
          key: 'lifestyle',
          width: 15,
          align: 'center',
          sorter: true,
          showSorterTooltip: false,
          render: (v) => <span>{!!v ? 'Y' : 'N'}</span>,
        },
      ],
      [userList],
  );

  // 테이블 페이지네이션
  const [tableHeight, setTableHeight] = React.useState(
      document.body.clientHeight - 360);

  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 360);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setSelectedId(record.id);
      },
    };
  };

  return (
    <div>
      <PageHeader className="site-page-header" title={`소비자 회원 (${params?.isDeleted?'삭제회원':'관리회원'})`} style={{ paddingBottom: 10 }} />
      <ExtraWrapper>
        {/* 필터 윗줄 */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 4.5,
          }}
        >
          <UpperLine containerStyle={{ width: '76%' }} />
        </div>
        <div
          style={{
            width: 870,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '10px 0 8px 0',
          }}
        >
          {/*<FilterSelect*/}
          {/*  value={params?.order}*/}
          {/*  onChange={(v) => {*/}
          {/*    setParams(*/}
          {/*      produce((draft) => {*/}
          {/*        draft.order = v;*/}
          {/*      }),*/}
          {/*    );*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Option value="DESC">저장일시 최신순</Option>*/}
          {/*</FilterSelect>*/}
        </div>
      </ExtraWrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PaginateTable
          isLoading={isLoading}
          setParams={setParams}
          dataSource={data}
          columns={columns}
          scroll={{ y: tableHeight - 40 }}
          data={userList}
          page={params?.page}
          setPage={(v) =>
            setParams(
              produce((draft) => {
                draft.page = v;
              }),
            )
          }
          pageSize={params?.limit}
          setPageSize={(v) =>
            setParams(
              produce((draft) => {
                draft.limit = v;
              }),
            )
          }
          onRow={onRow}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '80%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
        />
        <div
          style={{
            width:400,
            overflowY: 'scroll',
            height: document.body.clientHeight - 240,
            paddingLeft: 20,
            boxSizing: 'border-box',
            paddingBottom: 15,
          }}
        >
          <UserDetail selectedId={selectedId} userType={detailProps[0]} />
          <CommonStatistics />
        </div>
      </div>
    </div>
  );
};

export default AccountsUsersIndex;
