import produce from 'immer';
import moment from 'moment/moment';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Switch,
  Table,
  Tabs,
} from 'antd';
import { restApi } from '../../../apis';
import { useParams } from 'react-router-dom';

const render = (v) => v?.toFixed(1);
const SettingsApartmentsDetails = (props) => {
  const params = useParams();

  const [data, setData] = useState({});
    const loadData = async () => {
      const { data } = await restApi.get(`/apartments/${params?.id}`);
      setData({ ...data });

    };
  // const [sizes, setSizes] = useState([]);
  useEffect(() => {
    loadData().catch(console.warn);
  }, [params?.id]);

  const tabs = useMemo(
    () => {
      console.log({data})
      const sizes = data?.apartmentSizeList?.sort((a,b)=>a.supplyArea-b.supplyArea);
      return sizes?.map((v) => {
        const columns = [
          {
            key: 'type',
            dataIndex: 'type',
            title: '구분',
            width: 200,
          },
          {
            key: 'size',
            dataIndex: 'size',
            title: '사이즈',
            align: 'center',
            width: 70,
          },
          {
            key: 'sash',
            dataIndex: 'sash',
            title: '하이샤시',
            width: 80,
            align: 'center',
            render,
          },
        ];

        const dataSource = [
          {
            type: '샷시면적',
            size: '평(자)',
            sash: v.sash,
          },
          {
            type: '바닥면적',
            size: '평',
          },
          {
            type: '벽체면적',
            size: '평',
          },
          {
            type: '화장실 바닥면적',
            size: 'm2',
          },
          {
            type: '화장실 벽체면적',
            size: 'm2',
          },
          {
            type: '붙박이장',
            size: '자',
          },
          {
            type: '신발장',
            size: '자',
          },
          {
            type: '몰딩',
            size: 'M',
          },
          {
            type: '문',
            size: 'EA',
          },
          {
            type: '발코니도어',
            size: 'EA',
          },
          {
            type: '중문',
            size: 'EA',
          },
          {
            type: '발코니',
            size: 'm2',
          },
          {
            type: '발코니장',
            size: '자',
          },
          {
            type: 'ㅡ자싱크대',
            size: 'M',
          },
          {
            type: 'ㅡ자싱크대앞 벽타일',
            size: 'm2',
          },
          {
            type: 'ㄱ자싱크대',
            size: 'M',
          },
          {
            type: 'ㄱ자싱크대앞 벽타일',
            size: 'm2',
          },
          {
            type: 'ㄷ자싱크대',
            size: 'M',
          },
          {
            type: 'ㄷ자싱크대 벽타일',
            size: 'm2',
          },
          {
            type: '11자싱크대',
            size: 'M',
          },
          {
            type: '11자싱크대앞 벽타일',
            size: 'm2',
          },
          {
            type: '화장실천정재',
            size: 'EA',
          },
        ];
        const livingRooms = v?.apartmentPartList?.filter(
            v => v.part === 'AREA001');
        for (let i = 0; i < Math.max(livingRooms?.length ?? 0, 1); i++) {
          const item = livingRooms?.[i] ?? {};
          const key = 'livingRooms' + i;
          let title = '거실';
          if (livingRooms?.length > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 80,
            align: 'center',
            render,
          });
          dataSource[1][key] = item.footSpace;
          dataSource[2][key] = item.wallSpace;
          dataSource[5][key] = item.closet;
          dataSource[7][key] = item.moulding;
          dataSource[9][key] = item.balconyDoor;
          dataSource[11][key] = item.balcony;
          dataSource[12][key] = item.balconyStorage;
        }

        const kitchens = v?.apartmentPartList?.filter(v => v.part === 'AREA002');
        for (let i = 0; i < Math.max(kitchens?.length ?? 1, 1); i++) {
          const item = kitchens?.[i] ?? {};
          const key = 'kitchens' + i;
          let title = '주방';
          if (kitchens?.length > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 80,
            align: 'center',
            render,
          });
          dataSource[1][key] = item.footSpace;
          dataSource[2][key] = item.wallSpace;
          dataSource[7][key] = item.moulding;
          dataSource[9][key] = item.balconyDoor;
          dataSource[11][key] = item.balcony;
          dataSource[12][key] = item.balconyStorage;
          dataSource[13][key] = item.sinkType1;
          dataSource[14][key] = item.wallTileType1;
          dataSource[15][key] = item.sinkType2;
          dataSource[16][key] = item.wallTileType2;
          dataSource[17][key] = item.sinkType3;
          dataSource[18][key] = item.wallTileType3;
          dataSource[19][key] = item.sinkType4;
          dataSource[20][key] = item.wallTileType4;
        }

        let room_count = v.roomCount;
        const masterRoom = v?.apartmentPartList?.filter(
            v => v.part === 'AREA003');
        for (let i = 0; i < Math.max(masterRoom?.length ?? 1, 1); i++) {
          const item = masterRoom?.[i] ?? {};
          const key = 'masterRoom' + i;
          let title = '안방';
          if (masterRoom?.length > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 80,
            align: 'center',
            render,
          });
          dataSource[1][key] = item.footSpace;
          dataSource[2][key] = item.wallSpace;
          dataSource[5][key] = item.closet;
          dataSource[7][key] = item.moulding;
          dataSource[8][key] = item.door;
          dataSource[11][key] = item.balcony;
          dataSource[12][key] = item.balconyStorage;
          room_count--;
        }

        const rooms = v?.apartmentPartList?.filter(
            v => v.part === 'AREA004');
        for (let i = 0; i < Math.max(rooms?.length ?? 0, room_count); i++) {
          const item = rooms?.[i] ?? {};
          const key = 'rooms' + i;
          let title = '방';
          if (Math.max(rooms?.length ?? 0, room_count) > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 80,
            align: 'center',
            render,
          });
          dataSource[1][key] = item.footSpace;
          dataSource[2][key] = item.wallSpace;
          dataSource[5][key] = item.closet;
          dataSource[7][key] = item.moulding;
          dataSource[8][key] = item.door;
          dataSource[11][key] = item.balcony;
          dataSource[12][key] = item.balconyStorage;
        }
        const entrance = v?.apartmentPartList?.filter(
            v => v.part === 'AREA005');
        for (let i = 0; i < Math.max(entrance?.length ?? 1, 1); i++) {
          const item = entrance?.[i] ?? {};
          const key = 'entrance' + i;
          let title = '현관출입부';
          if (entrance?.length > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 80,
            align: 'center',
            render,
          });
          dataSource[1][key] = item.footSpace;
          dataSource[2][key] = item.wallSpace;
          dataSource[6][key] = item.shoeRack;
          dataSource[7][key] = item.moulding;
          dataSource[8][key] = item.door;
          dataSource[10][key] = item.innerDoor;
        }

        let bath_count = v.bathRoomCount;
        const livingBathRooms = v?.apartmentPartList?.filter(
            v => v.part === 'AREA006');
        for (let i = 0; i < Math.max(livingBathRooms?.length ?? 1, 1); i++) {
          const item = livingBathRooms?.[i] ?? {};
          const key = 'livingRoomBath' + i;
          let title = '거실화장실';
          if (livingBathRooms?.length > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 90,
            align: 'center',
            render,
          });
          dataSource[3][key] = item.bathFootSpace;
          dataSource[4][key] = item.bathWallSpace;
          dataSource[8][key] = item.door;
          dataSource[21][key] = item.ceilingBoard;
          bath_count--;
        }

        const masterBathRoom = v?.apartmentPartList?.filter(
            v => v.part === 'AREA007');
        for (let i = 0; i <
        Math.max(masterBathRoom?.length ?? 0, bath_count); i++) {
          const item = masterBathRoom?.[i] ?? {};
          const key = 'masterBathRoom' + i;
          let title = '안방화장실';
          if (masterBathRoom?.length > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 90,
            align: 'center',
            render,
          });
          dataSource[3][key] = item.bathFootSpace;
          dataSource[4][key] = item.bathWallSpace;
          dataSource[8][key] = item.door;
          dataSource[21][key] = item.ceilingBoard;
          bath_count--;
        }

        const roomBathRoom = v?.apartmentPartList?.filter(
            v => v.part === 'AREA008');
        for (let i = 0; i < Math.max(roomBathRoom?.length ?? 0, bath_count); i++) {
          const item = roomBathRoom?.[i] ?? {};
          const key = 'roomBathRoom' + i;
          let title = '각방화장실';
          if (roomBathRoom?.length > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 90,
            align: 'center',
            render,
          });
          dataSource[3][key] = item.bathFootSpace;
          dataSource[4][key] = item.bathWallSpace;
          dataSource[8][key] = item.door;
          dataSource[21][key] = item.ceilingBoard;
          bath_count--;
        }

        return {
          id: v.id,
          title: Math.floor(v.supplyArea) + '㎡',
          columns,
          dataSource,
        };
      });
    },
    [data],
  );

  const handleSubmit = async () => {
    try {


    await restApi.put(`/apartments/${params?.id}`, data);
    alert('저장되었습니다.')
    loadData();
    }catch (e){
      alert(e.message)
    }
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="아파트 정보" subTitle={'설정'} />
      <Form layout={'horizontal'} onFinish={handleSubmit}>
        <div style={{ maxWidth: 1200 }}>
          <Card>
            <Form.Item label={'제목'} style={{ marginBottom: 20 }}>
              <Input value={data?.name} onChange={(e) => setData((x) => ({ ...x, name: e.target.value }))} />
            </Form.Item>
            <Form.Item label={'지번주소'} style={{ marginBottom: 20 }}>
              <Input value={data?.addressJibun} onChange={(e) => setData((x) => ({ ...x, addressJibun: e.target.value }))} />
            </Form.Item>
            <Form.Item label={'도로명주소'} style={{ marginBottom: 20 }}>
              <Input
                value={data?.addressRoad}
                onChange={(e) => setData((x) => ({ ...x, addressRoad: e.target.value }))}
              />
            </Form.Item>
            <Form.Item label={'허가일자'} style={{ marginBottom: 20 }}>
              <DatePicker

                  value={moment(data?.usedAt)}
                  onChange={(date, dateString) => {
                    setData((x) => ({ ...x, usedAt: dateString }))
                  }}
              />
            </Form.Item>
            <Form.Item label={'관리소 연락처'} style={{ marginBottom: 20 }}>
              <Input
                value={data?.phone}
                onChange={(e) => setData((x) => ({ ...x, phone: e.target.value }))}
              />
            </Form.Item>
            <Form.Item label={'검색가능여부'} style={{ marginBottom: 20 }}>
              <Switch
                checked={data?.isActive}
                onClick={() => {
                  setData((x) => ({ ...x, isActive: !x.isActive }));
                }}
              />
            </Form.Item>
          </Card>
          <div style={{ textAlign: 'right', marginTop: 10 }}>
            <Button onClick={handleSubmit}  type={'primary'} size={'large'}>
              저장
            </Button>
          </div>
        </div>

        <Card style={{ marginTop: 20 }}>
          <Tabs>
            {tabs?.map((tab) => {
              return (
                <Tabs.TabPane key={`${tab.id}`} tab={tab.title}>
                  <Table
                    scroll={{ x: tab.columns?.reduce((a, c) => a + c.width, 0) }}
                    dataSource={tab.dataSource}
                    columns={tab.columns}
                    pagination={false}
                  />
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </Card>
      </Form>
    </div>
  );
};

export default SettingsApartmentsDetails;
