export const POSITIONS = [
  '_all',
  'living_rooms',
  'master_rooms',
  'rooms',
  'kitchens',
  'entrance',
  'living_room_cr',
  'master_room_cr',
  'room_cr',
];

export const POSITION_LABELS = {
  _all: '전체',
  living_rooms: '거실',
  master_rooms: '안방',
  rooms: '방',
  kitchens: '주방',
  entrance: '현관',
  living_room_cr: '거실화장실',
  master_room_cr: '안방화장실',
  room_cr: '방화장실',
};

export const PRINT_LABELS = {
  M: 'm',
  M2: 'm2',
  PY: '평',
  JA: '자',
  EA: 'EA',
  LOT: 'LOT',
  SET: 'SET',
};

export const CALC_KEYS = {
  product_count: {
    key: 'product_count',
    label: '상품갯수',
    allow: [
      '_all',
      'living_rooms',
      'kitchens',
      'master_rooms',
      'rooms',
      'entrance',
      'living_room_cr',
      'master_room_cr',
      'room_cr',
    ],
  },
  size: {
    key: 'size',
    label: '전체실평수',
    allow: ['_all'],
  },
  sash: {
    key: 'sash',
    label: '샷시면적(평/자)',
    allow: ['_all'],
  },
  foot_space: {
    key: 'foot_space',
    label: '바닥면적(평)',
    allow: ['living_rooms', 'kitchens', 'master_rooms', 'rooms', 'entrance'],
  },
  wall_space: {
    key: 'wall_space',
    label: '벽체면적(평)',
    allow: ['living_rooms', 'kitchens', 'master_rooms', 'rooms', 'entrance'],
  },
  cr_foot_space: {
    key: 'cr_foot_space',
    label: '화장실 바닥면적(m2)',
    allow: ['living_room_cr', 'master_room_cr', 'room_cr'],
  },
  cr_wall_space: {
    key: 'cr_wall_space',
    label: '화장실 벽체면적(m2)',
    allow: ['living_room_cr', 'master_room_cr', 'room_cr'],
  },
  closet: {
    key: 'closet',
    label: '붙박이장',
    allow: ['master_rooms', 'rooms'],
  },
  shoe_rack: {
    key: 'shoe_rack',
    label: '신발장',
    allow: ['entrance'],
  },
  moulding: {
    key: 'moulding',
    label: '몰딩',
    allow: ['living_rooms', 'kitchens', 'master_rooms', 'rooms', 'entrance'],
  },
  door: {
    key: 'door',
    label: '문',
    allow: ['master_rooms', 'rooms', 'entrance', 'living_room_cr', 'master_room_cr', 'room_cr'],
  },
  balcony_door: {
    key: 'balcony_door',
    label: '발코니도어',
    allow: ['living_rooms', 'kitchens'],
  },
  inner_door: {
    key: 'inner_door',
    label: '중문',
    allow: ['entrance'],
  },
  balcony: {
    key: 'balcony',
    label: '발코니',
    allow: ['living_rooms', 'kitchens', 'master_rooms', 'rooms'],
  },
  balcony_storage: {
    key: 'balcony_storage',
    label: '발코니장',
    allow: ['living_rooms', 'kitchens', 'master_rooms', 'rooms'],
  },
  sinkType1: {
    key: 'sinkType1',
    label: 'ㅡ자싱크대',
    allow: ['kitchens'],
  },
  walltileType1: {
    key: 'walltileType1',
    label: 'ㅡ자싱크대앞 벽타일',
    allow: ['kitchens'],
  },
  sinkType2: {
    key: 'sinkType2',
    label: 'ㄱ자싱크대',
    allow: ['kitchens'],
  },
  walltileType2: {
    key: 'walltileType2',
    label: 'ㄱ자싱크대앞 벽타일',
    allow: ['kitchens'],
  },
  sinkType3: {
    key: 'sinkType3',
    label: 'ㄷ자싱크대',
    allow: ['kitchens'],
  },
  walltileType3: {
    key: 'walltileType3',
    label: 'ㄷ자싱크대앞 벽타일',
    allow: ['kitchens'],
  },
  sinkType4: {
    key: 'sinkType4',
    label: '11자싱크대',
    allow: ['kitchens'],
  },
  walltileType4: {
    key: 'walltileType4',
    label: '11자싱크대앞 벽타일',
    allow: ['kitchens'],
  },
  celling_board: {
    key: 'celling_board',
    label: '화장실천정재',
    allow: ['living_room_cr', 'master_room_cr', 'room_cr'],
  },
};

export const PERIOD_OPTIONS = [
  {
    label: '금일',
    value: 'today',
  },
  {
    label: '1주',
    value: '1w',
  },
  {
    label: '1달',
    value: '1m',
  },
  {
    label: '1년',
    value: '1y',
  },
];

export const regex = {
  businessNumber: /^[0-9]{10}$/,
  signname: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{4,20}$/,
  password: /^(?=(?:[^a-zA-Z]*[a-zA-Z]){2})(?=(?:\D*\d){2})[a-zA-Z\d!@#$%^&*()-_=+[\]{};:'",.<>/\\?|]{8,16}$/,
  email: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
};
export const COST_TYPE = [
  { label: '1백만원', value: 1000000 },
  { label: '3백만원', value: 3000000 },
  { label: '5백만원', value: 5000000 },
  { label: '7백만원', value: 7000000 },
  { label: '9백만원', value: 9000000 },
  { label: '1천만원', value: 10000000 },
  { label: '3천만원', value: 30000000 },
  { label: '5천만원', value: 50000000 },
  { label: '7천만원', value: 70000000 },
  { label: '9천만원', value: 90000000 },
  { label: '1억원', value: 100000000 },
  { label: '3억원', value: 300000000 },
  { label: '5억원', value: 500000000 },
  { label: '7억원', value: 700000000 },
  { label: '9억원', value: 900000000 },
  { label: '10억원 이상', value: 1000000000 },
];
