import React from 'react';
import { useSelector } from 'react-redux';
import { getFileId, parseImageUri } from '../../common/utils';
import produce from 'immer';
import { Modal } from 'antd';
import RoundButton from '../button/RoundButton';
import { InputComponent, MultiLineInput } from '../template/page';
import {
  CloseOutlined,
  DeleteOutlined, DownOutlined,
  RedoOutlined,
  UpOutlined,
} from '@ant-design/icons';
import ModifyEstimateModal from './ModifyEstimateModal';
import { restApi } from '../../apis';
import { useQueryClient } from 'react-query';
import { POSTS_KEY } from '../../apis/queryKeys';
import { useGetConstructionCategories, useGetPostDetail } from '../../apis/queries';
const styles = {
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: '50px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px 20px 0px 20px',
  },
  titleImageWrapper: {
    position: 'reative',
    width: '100%',
    height: '536px',
    backgroundColor: '#d9d9d9',
  },
  titleImage: {
    width: '100%',
    height: 'inherit',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  modify_reupload: {
    padding: '7px',
    borderRadius: '50%',
    backgroundcolor: '#888',
    mixBlendMode: 'luminosity',
    filter: 'blur(0.1px)',
    color: 'white',
    fontSize: '22px',
    marginRight: '10px',
  },
  modify_style_select: {
    display: 'block',
    height: '35px',
    padding: '10px 12px 9px 10px',
    border: 'none',
    borderRadius: '5px',
    mixBlendMode: 'luminosity',
    filter: 'blur(0.2px)',
    color: 'white',
  },
  bodyWrapper: {
    padding: '0px 120px',
  },
  titleInputWrapper: {
    position: 'relative',
    marginTop: '100px',
    borderBottom: '1px solid #d9d9d9',
  },
  input: {
    width: '100%',
    height: '25px',
    border: 'none',
    backgroundColor: 'transparent',
  },
  dropdownContainer: {
    position: 'relative',
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  contentInputWrapper: {
    // marginTop: '55px',
    marginBlock:25,
    minHeight: '200px',
  },
  imageInputWrapper: {
    width: '100%',
    height: '338px',
    borderRadius: '10px',
    backgroundColor: '#d9d9d9',
    display: 'flex',
    marginBlock:25,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  buttonContainer: {
    display: 'flex',
    position: 'absolute',
    right: '20px',
    bottom: '20px',
    gap: '10px',
  },
  buttonWrapper: {
    cursor: 'pointer',
    backgroundColor: '#999',
    mixBlendMode: 'luminosity',
    filter: 'blur(0.1px)',
    minWidth: '36px',
    minHeight: '36px',
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addButtonWrapper: {
    width: '100%',
    display: 'flex',
    marginTop: '50px',
    justifyContent: 'center',
    paddingBottom: '40px',
  },
};

const ButtonWrapper = React.memo(({ onClick = () => {}, isRound, children, htmlFor }) => {
  return (
    <label
      onClick={onClick}
      htmlFor={htmlFor}
      style={{ ...styles.buttonWrapper, borderRadius: isRound ? '50%' : '5px' }}
    >
      {children}
    </label>
  );
});

const SubForm = React.memo(({ data, setForm, index }) => {
  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    setForm(imageId, 'thumbnailId');
  };

  return (
      <>
        <div style={{...styles.titleInputWrapper, marginBottom: '20px'}}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}>
            <div style={{display: 'flex', gap: 10}}>

            </div>
            <CloseOutlined onClick={() => {
              const confirm = window.confirm(
                  '작성한 해당공간의 내용이 초기화됩니다. 정말 삭제하시겠습니까?');
              if (confirm) {
                setForm(produce((draft) => {
                  draft.subCreates = draft.subCreates.filter(
                      (v2,i) => i !== index);

                  return draft;
                }));
              }
            }} style={{fontSize: 20}}></CloseOutlined>
          </div>
          <InputComponent
              maxLength={80}
              value={data?.title}
              onChange={(e) => {
                const value = e.target.value;
                setForm(
                    produce((draft) => {
                      const target = draft.subCreates.find(
                          (v2,i) => i === index);
                      target.title = value;
                      return draft;
                    }),
                );
              }}
              placeholder="공간 제목을 입력해 주세요"
          />
          <span style={{
            position: 'absolute',
            right: 0,
            fontSize: '14px',
          }}>{`${data?.title?.length ?? 0}/80`}</span>
        </div>
        {data?.subContentItems?.map((v,i) => (
            <>
              {v?.thumbnailId !== undefined &&
                  <div style={{
                    ...styles.imageInputWrapper,
                    backgroundImage: `url(${parseImageUri(
                        v?.thumbnailId)})`,
                  }}>
                    {!v?.thumbnailId && (
                        <span
                            style={{
                              fontSize: '16px',
                              fontWeight: '600',
                              color: '#fff',
                            }}
                        >
                              사진을 업로드해 주세요.
                            </span>
                    )}
                    <div style={styles.buttonContainer}>
                      {i!==0&&
                          <ButtonWrapper
                              onClick={() => {
                                setForm(produce((draft) => {
                                  const target = draft.subCreates.find(
                                      (v2,i) => i === index);
                                  const temp = target.subContentItems[i];
                                  target.subContentItems[i] = target.subContentItems[i-1];
                                  target.subContentItems[i-1] = temp;
                                  return draft;
                                }))
                              }}
                              isRound>

                            <UpOutlined
                                style={{
                                  color: '#FFF',
                                  width: '15px',
                                  height: '15px',
                                }}/>
                          </ButtonWrapper>
                      }
                      {
                          i!==data.subContentItems.length-1&&

                          <ButtonWrapper onClick={
                            () => {
                              setForm(produce((draft) => {
                                const target = draft.subCreates.find(
                                    (v2,i) => i === index);
                                const temp = target.subContentItems[i];
                                target.subContentItems[i] = target.subContentItems[i+1];
                                target.subContentItems[i+1] = temp;
                                return draft;
                              }))
                            }
                          }  isRound>
                            <DownOutlined
                                style={{
                                  color: '#FFF',
                                  width: '15px',
                                  height: '15px',
                                }}/>
                          </ButtonWrapper>
                      }

                      <ButtonWrapper htmlFor={`fileInput_${index}_${i}`}
                                     isRound>
                        <input type="file" id={`fileInput_${index}_${i}`}
                               style={{display: 'none'}}
                               onChange={async (e)=>{
                                 const file = e.target.files[0];
                                 const imageId = await getFileId(file);
                                 setForm(
                                     produce((draft) => {
                                       const target = draft.subCreates.find(
                                           (v2,i) => i === index);
                                       target.subContentItems[i].thumbnailId = imageId;
                                       return draft;
                                     }),
                                 );
                                 // setForm(imageId, 'thumbnailId');

                               }}/>
                        <RedoOutlined
                            style={{
                              color: '#FFF',
                              width: '15px',
                              height: '15px',
                            }}/>
                      </ButtonWrapper>
                      <ButtonWrapper
                          onClick={() => {
                            setForm(produce((draft) => {
                              const target = draft.subCreates.find(
                                  (v2,i) => i === index);
                              target.subContentItems.splice(i, 1);
                              target.subContentItems.forEach((v,i)=>{
                                v.orderNum = i+1;
                              })
                              return draft;
                            }))
                          }}
                      >
                        <DeleteOutlined
                            style={{
                              color: '#FFF',
                              width: '15px',
                              height: '15px',
                            }}/>
                      </ButtonWrapper>

                    </div>
                  </div>
              }
              {v?.content !== undefined &&
                  <div style={{
                    ...styles.contentInputWrapper,
                    position: 'relative',
                    // borderRadius: 10,
                    // overflow: 'hidden',
                  }}>
                    <div style={{...styles.buttonContainer, zIndex: 1}}>
                      {i!==0&&
                          <ButtonWrapper
                              onClick={() => {
                                setForm(produce((draft) => {
                                  const target = draft.subCreates.find(
                                      (v2,i) => i === index);
                                  const temp = target.subContentItems[i];
                                  target.subContentItems[i] = target.subContentItems[i-1];
                                  target.subContentItems[i-1] = temp;
                                  return draft;
                                }))
                              }}
                              isRound>

                            <UpOutlined
                                style={{
                                  color: '#FFF',
                                  width: '15px',
                                  height: '15px',
                                }}/>
                          </ButtonWrapper>
                      }
                      {
                          i!==data.subContentItems.length-1&&

                          <ButtonWrapper onClick={
                            () => {
                              setForm(produce((draft) => {
                                const target = draft.subCreates.find(
                                    (v2,i) => i === index);
                                const temp = target.subContentItems[i];
                                target.subContentItems[i] = target.subContentItems[i+1];
                                target.subContentItems[i+1] = temp;
                                return draft;
                              }))
                            }
                          }  isRound>
                            <DownOutlined
                                style={{
                                  color: '#FFF',
                                  width: '15px',
                                  height: '15px',
                                }}/>
                          </ButtonWrapper>
                      }
                      <ButtonWrapper
                          onClick={() => {
                            setForm(produce((draft) => {
                              const target = draft.subCreates.find(
                                  (v2,i) => i === index);
                              target.subContentItems.splice(i, 1);
                              target.subContentItems.forEach((v,i)=>{
                                v.orderNum = i+1;
                              })
                              return draft;
                            }))
                          }}

                      >
                        <DeleteOutlined
                            style={{
                              color: '#FFF',
                              width: '15px',
                              height: '15px',
                            }}/>
                      </ButtonWrapper>
                    </div>
                    <MultiLineInput
                        style={{
                          backgroundColor: '#d9d9d9',
                          padding: 10,
                          paddingRight: 30,
                          borderRadius:10,
                          // marginBottom: 10,
                          minHeight: 200,
                        }}
                        value={v?.content}
                        onChange={(e) => {
                          setForm(
                              produce((draft) => {
                                const target = draft.subCreates.find(
                                    (v2,i) => i === index);
                                target.subContentItems[i].content = e.target.value;
                                return draft;
                              }),
                          );
                        }}
                        placeholdercolor={'#fff'}
                        // autoSize={{minRows: 6}}

                        placeholder="내용을 입력해 주세요"
                    />
                  </div>
              }

            </>
        ))}
        <div
            style={{...styles.addButtonWrapper, gap: 10, paddingBottom: 0}}>
          <RoundButton
              onClick={() => {
                setForm(produce((draft) => {
                  const target = draft.subCreates.find(
                      (v2,i) => i === index);
                  target.subContentItems.push({
                    content: null,
                    // orderNum: target.subContentItems.length+1,
                  });
                  target.subContentItems.forEach((v,i)=>{
                    v.orderNum = i+1;
                  })
                  return draft;
                }))
              }}
              label="글추가"
              containerStyle={{
                width: '100px',
                height: '30px',
              }}
          />
          <RoundButton
              onClick={() => {
                setForm(produce((draft) => {
                  const target = draft.subCreates.find(
                      (v2,i) => i === index);
                  target.subContentItems.push({
                    thumbnailId: null,
                    // orderNum: target.subContentItems.length+1,
                  });
                  target.subContentItems.forEach((v,i)=>{
                    v.orderNum = i+1;
                  })
                  return draft;
                }))
              }}
              label="사진추가"
              containerStyle={{
                width: '100px',
                height: '30px',
              }}
          />
        </div>
      </>
  );
});

const WritePostModal = React.memo(({
  visible,
  setVisible,
  selectedId,
  handleOk,
  handleCancel,
  type,
  isEdit,
}) => {
  const {commonCodes} = useSelector((s) => s.common);
  const initialSubCreates = React.useMemo(
      () => ({
        title: null,
        description: null,
        thumbnailId: null,
        subContentItems: [
          {
            thumbnailId: null,
            orderNum: 1,
          }, {
            content: null,
            orderNum: 2,
          }],
      }),
      [],
  );

  const initialForm = React.useMemo(
      () => ({
        title: '',
        thumbnailId: null,
        description: '',
        tag: 'TRTG001',
        category: 'COTP001',
        subCreates: [initialSubCreates],
      }),
      [],
  );
  const tagItems = React.useMemo(() => {
    return commonCodes?.tree['TRTG']?.map((v) => ({
      value: v.code,
      label: v.label,
    }));
  }, [commonCodes]);

  const constructionCategoryList = React.useMemo(() => {
    return commonCodes?.tree['COTP']?.map((v) => ({
      value: v.code,
      label: v.label,
    }));
  }, [commonCodes]);

  const [form, setForm] = React.useState(initialForm);

  useGetPostDetail({
    type,
    id: selectedId,
    config: {
      enabled: !!isEdit && !!selectedId,
      onSuccess: (data) => {
        if (isEdit) {
          setForm(
              produce((draft) => {
                  draft.tag = data?.tag;
                  draft.category = data?.category;
                draft.title = data?.title;
                draft.description = data?.description;
                draft.thumbnailId = data?.thumbnailId;
                const subCreates = data?.subContents?.map((v) => ({
                  title: v.title,
                  subContentItems: v?.subContentItems?.map((v) => ({
                    content: v?.content || undefined,
                    thumbnailId: v?.thumbnailId || undefined,
                    orderNum: v?.orderNum,
                  })),

                  description: v.description,
                  thumbnailId: v.thumbnailId,
                }));
                draft.subCreates = subCreates;
              }),
          );
        }
      },
    },
  });
  const queryClient = useQueryClient();

  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    setForm(
        produce((draft) => {
          draft.thumbnailId = imageId;
        }),
    );
  };

  const handleSubmit = React.useCallback(() => {
    const confirmText = isEdit ? '수정' : '저장';
    const id = selectedId;
    const api = isEdit ? (data) => restApi.put(`/${type}/${id}`, data) : (data) => restApi.post(`/${type}`, data);
    Modal.confirm({
      content: `게시글을 ${confirmText}하시겠습니까?`,
      okText: '확인',
      cancelText: '취소',
      onOk: () => {
        api(form).then(async (res) => {
          await queryClient.invalidateQueries([POSTS_KEY, type]);

          Modal.success({
            content: `${confirmText}되었습니다.`,
            okText: '확인',
            onOk: () => {
              setVisible(false);
              setForm(initialForm);
              handleCancel();
            },
          });
        });
      },
    });
  }, [form, type, queryClient, isEdit, selectedId]);

  return (
    <Modal
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 100,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
        padding: '0px',
      }}
      closable={false}
      open={visible}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
        setForm(initialForm);
      }}
      destroyOnClose={true}
      maskClosable={true}
      okText={'확인'}
      footer={null}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div style={styles.modalContainer}>
        <div style={styles.header}>
          <RoundButton
            onClick={() => {
              handleCancel();
            }}
            label="취소"
            containerStyle={{
              width: '100px',
              marginRight: '10px',
            }}
          />
          <RoundButton
            onClick={handleSubmit}
            reverse
            label="저장"
            containerStyle={{
              width: '100px',
            }}
          />
        </div>
        <div style={styles.titleImageWrapper}>
          <div style={{ ...styles.titleImage, backgroundImage: `url(${parseImageUri(form?.thumbnailId)})` }}>
            <div
              style={{
                display: 'flex',
                position: 'relative',
                justifyContent: 'flex-end',
                right: '50px',
                top: '490px',
                zIndex: 99,
              }}
            >
              <label style={{ cursor: 'pointer' }} htmlFor="fileInput">
                <div>
                  <RedoOutlined style={styles.modify_reupload} />
                </div>
                <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleChangeImage} />
              </label>
              {type !== 'guides' && (
                <select
                  value={type === 'trends' ? form?.tag : form?.category}
                  onChange={(e) => {
                    setForm(
                      produce((draft) => {
                        if (type !== 'construction-guides') {
                          draft.tag = e.target.value;
                        } else {
                          draft.category = e.target.value;
                        }
                      }),
                    );
                  }}
                  style={{ ...styles.modify_style_select, width: '100px', backgroundColor: '#999' }}
                >
                  {type === 'trends'
                    ? tagItems?.map((v) => <option value={v.value}>{v.label}</option>)
                    : constructionCategoryList?.map((v) => <option value={v.value}>{v.label}</option>)}
                </select>
              )}
            </div>
          </div>
        </div>
        <div style={styles.bodyWrapper}>
          <div style={styles.titleInputWrapper}>
            <InputComponent
              maxLength={80}
              value={form?.title}
              onChange={(e) => {
                setForm(
                  produce((draft) => {
                    draft.title = e.target.value;
                  }),
                );
              }}
              placeholder="메인 제목을 입력해 주세요"
            />
            <span style={{ position: 'absolute', right: 0, fontSize: '14px' }}>{`${form?.title?.length}/80`}</span>
          </div>
          <div style={styles.contentInputWrapper}>
            <MultiLineInput
              value={form?.description}
              onChange={(e) => {
                setForm(
                  produce((draft) => {
                    draft.description = e.target.value;
                  }),
                );
              }}
              autoSize={{ minRows: 6 }}
              placeholder="대표 본문을 입력해 주세요."
            />
          </div>
          {form?.subCreates?.map((v, i) => (
            <SubForm
              data={v}
              index={i}
              setForm={setForm}
            />
          ))}
          <div style={styles.addButtonWrapper}>
            <RoundButton
              onClick={() => {
                setForm(
                  produce((draft) => {
                    draft.subCreates.push(initialSubCreates);
                  }),
                );
              }}
              label="공간 추가"
              containerStyle={{
                width: '100px',
                height: '30px',
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default WritePostModal;
