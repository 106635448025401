import { Switch } from 'antd';
import React from 'react';

const useRenderSwitch = () => {
  const [isStatus, setIsStatus] = React.useState();
  const buttonHandler = () => {
    setIsStatus((prev) => !prev);
  };
  const RenderSwitch = ({ index, selectedRowKeys }) => {
    return <Switch checked={isStatus} onChange={buttonHandler} />;
  };
  return { isStatus, setIsStatus, RenderSwitch };
};

export default useRenderSwitch;
