import {message, Modal, Switch} from 'antd';
import React from 'react';
import {restApi} from '../../../apis';
import RoundButton from '../../button/RoundButton';

const WriteMessagingModal = ({account, messageId, setMessageId}) => {

  const [data, setData] = React.useState(null);

  const [title, setTitle] = React.useState('');
  const [content, setContent] = React.useState('');

  const [params, setParams] = React.useState({
    limit: 50,
    page: 0,
  });
  const handleCancel = () => {
    setMessageId(null);
  };

  React.useEffect(() => {
    const loadData = async () => {
      await restApi.get(`/direct-messaging/detail/${messageId}`, {
        params: {
          params,
        },
      }).then((res) => {
        setData(res.data);
      }).catch((e) => {
        alert(e.response.data.message);
      });
    };
    if (messageId && typeof messageId === 'number') {
      loadData();
    } else {
      setData(null);
    }
  }, [messageId, params]);

  return (
      <Modal
          width={900}
          footer={false}
          bodyStyle={{
            width: '100%',
            minWidth: 900,
            maxHeight: document.body.clientHeight - 300,
            overflowY: 'scroll',
            backgroundColor: '#f9f9f9',
          }}
          closable={true}
          open={!!messageId && account}
          onCancel={handleCancel}
          destroyOnClose={true}
          maskClosable={true}

          // cancelText={'수정'}
      >
        <div style={{padding: '70px 20px'}}>
          <p style={{fontSize: 22, fontWeight: 600, marginBottom: 10}}>다이렉트 메시지
            {typeof messageId === 'number' ? '확인' : '작성'}</p>
          <div style={{display: 'flex'}}>

            <div style={{
              marginBottom: 30,
              display: 'flex',
              flexDirection: 'column',
              width: '50%',
            }}>
            <span style={{
              fontSize: 16,
              color: '#767676',
              marginRight: 10,
            }}>수신</span>
              <span style={{
                fontSize: 18,
                fontWeight: 'bold',
                marginTop: '20',
                marginRight: 10,
              }}>{account?.nickname}</span>


            </div>
            {
                data?.createdAt &&

                <div style={{
                  marginBottom: 30,
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                }}>
            <span style={{
              fontSize: 16,
              color: '#767676',
              marginRight: 10,
            }}>수신일시</span>
                  <span style={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    marginTop: '20',
                    marginRight: 10,
                  }}>{data?.createdAt}</span>
                </div>
            }
          </div>
          <div style={{display: 'flex'}}>

          </div>
          <div style={{
            marginBottom: 30,
            display: 'flex',
            flexDirection: 'column',
          }}>
            <span style={{
              fontSize: 16,
              color: '#767676',
              marginRight: 10,
            }}>제목</span>
            <input
                value={data?.title || title}
                disabled={typeof messageId === 'number'}
                onChange={(e) => setTitle(e.target.value)}
                style={{
                  width: '100%',
                  height: 40,
                  backgroundColor: '#d9d9d9',
                  // borderRadius: 10,
                  border: '1px solid #d9d9d9',
                  padding: '0 10px',
                }}
            />
          </div>
          <div style={{
            marginBottom: 30,
            display: 'flex',
            flexDirection: 'column',
          }}>
            <span style={{
              fontSize: 16,
              color: '#767676',
              marginRight: 10,
            }}>내용</span>
            <textarea
                value={data?.content || content}
                disabled={typeof messageId === 'number'}

                onChange={(e) => setContent(e.target.value)}
                style={{

                  resize: 'none',
                  width: '100%',
                  height: 500,
                  backgroundColor: '#d9d9d9',
                  // borderRadius: 10,
                  border: '1px solid #d9d9d9',
                  padding: '10px 10px',
                }}
            />
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <RoundButton
                onClick={() => {
                  if (typeof messageId === 'number') {
                    setMessageId(null);
                  } else {
                    restApi.post(`/direct-messaging/${account?.id}`, {
                      title,
                      content,
                    }).then((res) => {
                      alert('메시지를 발송하였습니다.');
                      setMessageId(null);
                      setTitle('');
                      setContent('');
                    }).catch((e) => {
                      alert(e.response.data.message);
                    });
                  }
                }}
                reverse
                label={typeof messageId === 'number' ? '확인' : '발송'}
                containerStyle={{width: '100px'}}
            />
          </div>
        </div>
      </Modal>
  );
};

export default WriteMessagingModal;
