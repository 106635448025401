import React, { useEffect, useState } from 'react';
import { PageHeader, Switch } from 'antd';
import PaginateTable from '../../../components/table/PaginateTable';
import { useGetApartmentList } from '../../../apis/queries';
import produce from 'immer';
import RoundButton from '../../../components/button/RoundButton';
import useUpperLine from '../../../hooks/useUpperLine';
import { APARTMENT_LIST_KEY } from '../../../apis/queryKeys';
import assets from '../../../assets';
const SettingsApartmentsIndex = () => {
  const initialParams = React.useMemo(
    () => ({
      isApartment: null, // 아파트(true), 오피스텔(false) 여부,
      keyword: null,
      limit: 50,
      page: 0,
    }),
    [],
  );
  const filters = React.useMemo(() => {
    return [
      {
        title: '종류',
        key: 'isApartment',
        options: [
          [null, '전체'],
          [true, '아파트'],
          [false, '오피스텔'],
        ],
      },
    ];
  }, []);
  const { params: upperParam, UpperLine } = useUpperLine(filters);
  const [params, setParams] = React.useState(initialParams);
  const { data: apartmentList } = useGetApartmentList({ params });

  React.useEffect(() => {
    if (upperParam) {
      setParams((prev) => ({ ...prev, ...upperParam }));
    }
  }, [upperParam]);
  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 180);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 180);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);
  const columns = React.useMemo(
    () => [
      {
        title: '아파트명',
        dataIndex: 'name',
        key: 'name',
        width: 30,
        align: 'center',
        render: (v) => {
          return <span>{v}</span>;
        },
      },
      {
        title: '주소',
        dataIndex: 'addressRoad',
        key: 'addressRoad',
        width: 150,
        align: 'center',
        render: (v) => {
          return <span>{v}</span>;
        },
      },
      {
        title: '평형개수',
        dataIndex: 'apartmentSizeList',
        key: 'apartmentSizeList',
        width: 30,
        align: 'center',
        render: (v) => {
          return <span>{v?.length}</span>;
        },
      },
      {
        title: '사용여부',
        dataIndex: 'isActive',
        key: 'isActive',
        width: 30,
        align: 'center',
        render: (v, record) => {
          return (
            <img
              style={{ width: '15px', height: '15px' }}
              src={v ? assets.checked : assets.unChecked}
              alt={`${v}_${record.id}`}
            />
          );
        },
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        width: 30,
        align: 'center',
        render: (v) => {
          return (
            <a href={`/settings/apartments/${v}`}>
              <RoundButton containerStyle={{ width: '100px' }} label="자세히보기" />
            </a>
          );
        },
      },
    ],
    [],
  );
  return (
    <div>
      <PageHeader className="site-page-header" title="아파트 정보" subTitle={'설정'} />
      <div style={{ width: '100%', marginTop: '20px', marginBottom: '20px' }}>
        <UpperLine containerStyle={{ width: 400 }} listKey={APARTMENT_LIST_KEY} />
      </div>
      <div style={{ position: 'relative' }}>
        <PaginateTable
          dataSource={apartmentList?.content}
          columns={columns}
          data={apartmentList}
          page={params?.page}
          setPage={(v) =>
            setParams(
              produce((draft) => {
                draft.page = v;
              }),
            )
          }
          pageSize={params?.limit}
          setPageSize={(v) =>
            setParams(
              produce((draft) => {
                draft.limit = v;
              }),
            )
          }
          scroll={{ y: tableHeight - 240 }}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '100%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
        />
      </div>
    </div>
  );
};

export default SettingsApartmentsIndex;
