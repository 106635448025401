import React from 'react';
import CommentIcon from '../../image/CommentIcon';
import styles from './PostCard.module.less';
import { HeartFilled, WechatFilled } from '@ant-design/icons';
import { useGetPostDetail } from '../../../apis/queries';
import { parseImageUri } from '../../../common/utils';
export default function PostCard({ id, type, onClickMore }) {
  const { data } = useGetPostDetail({
    type,
    id,
    config: {
      enabled: !!id,
    },
  });

  return (
    <div style={{ height: 400, backgroundColor: 'white', borderRadius: 10, overflow: 'hidden' }}>
      <div>
        <div
          className={styles.slider_div}
          style={{
            backgroundImage: `url(${parseImageUri(data?.thumbnailId)})`,
          }}
        >
          <div className={styles.backdrop} />
          <div className={styles.slider_inner_div}>
            <p className={styles.category_p}>{data?.type === 'TREND' ? '트렌드' : '전문가'}</p>
            <p className={styles.title_p}>{data?.title}</p>
            <div className={styles.flex_div}>
              <div className={styles.flex_div} style={{ alignItems: 'center' }}>
                <img
                  className={styles.style_profile_img}
                  src={parseImageUri(data?.createdBy?.profileImageId)}
                  alt="프로필 이미지"
                />
                <p className={styles.category_p} style={{ marginLeft: 10 }}>
                  {data?.createdBy?.nickname}
                </p>
              </div>
              <div className={styles.flex_div}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <HeartFilled style={{ fontSize: 18, color: '#d9d9d9' }} />
                  <p className={styles.style_button_count_p}>{data?.likeInfo?.likeCount}</p>
                </div>
                <div
                  style={{
                    marginLeft: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <CommentIcon style={{ fontSize: 18, color: '#e8e8e8' }} />
                  <p className={styles.style_button_count_p}>{data?.shareCount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.trand_card_div}>
        <div style={{ display: 'flex', position: 'relative' }}>
          {data?.subContents?.map((v, index) => {
            const thumbnailId =  v?.subContentItems?.find((v) => v.thumbnailId)?.thumbnailId

            return (
                thumbnailId&&
                <div
                    className={styles.trand_thumbnail_img}
                    style={{
                      zIndex: 10,
                      right: index * 20,
                    }}
                >
                  <img style={{width: '100%', height: '100%'}}
                       src={parseImageUri(thumbnailId)} alt=""/>
                </div>
            );
          })}
        </div>
        <p style={{ maxHeight: 63, overflow: 'hidden', marginTop: 10 }}>{data?.description}</p>
        <button onClick={onClickMore}>더보기</button>
      </div>
    </div>
  );
}
