import {Input, InputNumber} from 'antd';
import produce from 'immer';
import * as PropTypes from 'prop-types';
import React from 'react';
import {
  CloseOutlined,
  CloseSquareOutlined,
  EditOutlined, RedoOutlined,
} from '@ant-design/icons';
import {restApi} from '../../../apis';
import styles from './ItemDetail.module.less';
import {
  useGetProductCategories,
  useGetProductDetail,
} from '../../../apis/queries';
import {
  findCategoryDeps,
  getFileId,
  parseImageUri,
} from '../../../common/utils';




const EditIcon = ({onClick, edit})=>{
  return (
      <button onClick={()=>onClick?.()} style={{backgroundColor: 'rgba(0,0,0,0)'}}>
        {edit
            ? <CloseSquareOutlined style={{fontSize: 20, color: '#767676'}}/>
            :
            <EditOutlined style={{fontSize: 20, color: '#767676'}}/>}
      </button>
        )
}



const ButtonWrapper = React.memo(({ onClick = () => {}, isRound, children, htmlFor }) => {
  return (
      <label
          onClick={onClick}
          htmlFor={htmlFor}
          style={{  cursor: 'pointer',
            position: 'relative',
            marginTop:10,
            marginRight:10,
            backgroundColor: '#999',
            mixBlendMode: 'luminosity',
            filter: 'blur(0.1px)',
            width: '36px',
            height: '36px',
            padding: '0px 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', borderRadius: isRound ? '50%' : '5px' }}
      >
        {children}
      </label>
  );
});

const ItemDetail = ({canModify, id}) => {
  const [edit, setEdit] = React.useState();
  const {data,refetch} = useGetProductDetail({
    id,
    config: {
      enabled: !!id,
    },
  });
  const [productDetail, setProductDetail] = React.useState(data);


  const toggleEdit = (key) => {
    setEdit(produce((draft) => {
      draft[key] = !draft[key];
    }));
  }
  const {data: productCategories} = useGetProductCategories();
  const categories = React.useMemo(
      () => findCategoryDeps(productCategories?.tree,
          productDetail?.categoryId),
      [productCategories, productDetail],
  );


  const handleSubmit = async  ()=>{
    try {
      await restApi.put(`/products/manage/${id}`,productDetail);
      alert('저장되었습니다.')
      refetch();
    }catch(e){
      alert(e)
    }
  }

  React.useEffect(() => {
    if(data){
      if(!data.price)data.price =0;
      if(!data.supplyPrice)data.supplyPrice =0;
      if(!data.cost)data.cost =0;
      setProductDetail(data);
      const editObj = {};
      Object.keys(data).forEach((key) => (editObj[key] = false));
      setEdit(editObj);
    }

  }, [data]);

  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    if (imageId) {
      setProductDetail(
          produce((draft) => {
            draft.thumbnailId = imageId;
          }),
      );
    }
  };

  return (
    <div className={styles.item_detail_div}>
      <p
        style={{
          fontSize: '16px',
          fontWeight: '600',
          color: '#000',
        }}
      >
        상품정보
      </p>
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: 10,
          overflow: 'hidden',
          boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
        }}
      >
        <div
          className={styles.thumbnail_img}
          style={{
            backgroundImage: `url(${parseImageUri(productDetail?.thumbnailId)})`,
          }}
        >
          <ButtonWrapper htmlFor={`fileInput_${data?.area}`} isRound>
            <input
              type="file"
              id={`fileInput_${data?.area}`}
              style={{ display: 'none' }}
              onChange={handleChangeImage}
            />
            <RedoOutlined style={{ color: '#FFF', width: '15px', height: '15px' }} />
          </ButtonWrapper>
        </div>
        <div className={styles.content_div}>
          <p className={styles.sub_title_text}>{categories?.join(' > ')}</p>
          <div className={styles.flex_div} style={{ marginBottom: 0 }}>
            <Input
              className={[styles.title_p, styles.input]}
              disabled={!edit?.['name']}
              onChange={(e) =>
                setProductDetail(
                  produce((draft) => {
                    draft.name = e?.target?.value;
                  }),
                )
              }
              value={productDetail?.name}
            ></Input>
            {canModify && <EditIcon edit={edit?.name} onClick={() => toggleEdit('name')} />}
          </div>
          <p className={styles.sub_title_text}>{productDetail?.makerName}</p>
          <p className={styles.sub_title_text} style={{ marginBottom: 10 }}>
            {productDetail?.brandName}
          </p>
          <div className={styles.sub_content_div}>
            <div className={styles.flex_div}>
              <p className={styles.sub_title_text}>상태</p>
              <p className={styles.sub_title_text} style={{ color: '#1e3d83', fontWeight: 'bolder', marginRight: 33 }}>
                {productDetail?.state === 'ACTIVE' ? '사용가능' : '대기중'}
              </p>
            </div>
            <div className={styles.flex_div}>
              <p className={styles.sub_title_text}>바바 품번</p>
              <div style={{ display: 'flex' }}>
                <Input
                  className={[styles.sub_title_text, styles.input]}
                  disabled={!edit?.['serial']}
                  value={productDetail?.serial}
                  onChange={(e) =>
                    setProductDetail(
                      produce((draft) => {
                        draft.serial = e?.target?.value;
                      }),
                    )
                  }
                ></Input>
                {canModify && <EditIcon edit={edit?.serial} onClick={() => toggleEdit('serial')} />}
              </div>
            </div>
            <div className={styles.flex_div}>
              <p className={styles.sub_title_text}>URL</p>
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                  <Input
                    className={[styles.sub_title_text, styles.input]}
                    disabled={!edit?.['link']}
                    value={productDetail?.link}
                    onChange={(e) =>
                      setProductDetail(
                        produce((draft) => {
                          draft.link = e?.target?.value;
                        }),
                      )
                    }
                  ></Input>
                  {canModify && <EditIcon edit={edit?.link} onClick={() => toggleEdit('link')} />}
                </div>
              </div>
            </div>
            <div className={styles.flex_div}>
              <p className={styles.sub_title_text}>유통사 제공단가</p>
              <div>
                <InputNumber
                  style={{ width: 180 }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  className={[styles.sub_title_text, styles.input]}
                  disabled={!edit?.['cost']}
                  min={0}
                  value={productDetail?.cost}
                  onChange={(e) =>
                    setProductDetail(
                      produce((draft) => {
                        draft.cost = e;
                      }),
                    )
                  }
                />
                <span className={styles.won}>원</span>
                {canModify && <EditIcon edit={edit?.cost} onClick={() => toggleEdit('cost')} />}
              </div>
            </div>
            <div className={styles.flex_div}>
              <p className={styles.sub_title_text}>시공사 공급단가</p>
              <div>
                <InputNumber
                  style={{ width: 180 }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  className={[styles.sub_title_text, styles.input]}
                  disabled={!edit?.['supplyPrice']}
                  min={0}
                  value={productDetail?.supplyPrice}
                  onChange={(e) =>
                    setProductDetail(
                      produce((draft) => {
                        draft.supplyPrice = e;
                      }),
                    )
                  }
                />
                <span className={styles.won}>원</span>
                {canModify && <EditIcon edit={edit?.supplyPrice} onClick={() => toggleEdit('supplyPrice')} />}
              </div>
            </div>
            <div className={styles.flex_div}>
              <p className={styles.sub_title_text}>소비자가</p>
              <div>
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  className={[styles.sub_title_text, styles.input, styles.price]}
                  disabled={!edit?.['price']}
                  min={0}
                  value={productDetail?.price}
                  onChange={(e) =>
                    setProductDetail(
                      produce((draft) => {
                        draft.price = e;
                      }),
                    )
                  }
                />

                <span className={styles.won}>원</span>

                {canModify && <EditIcon edit={edit?.price} onClick={() => toggleEdit('price')} />}
              </div>
            </div>
            <div className={styles.flex_div}>
              <textarea
                readOnly={!edit?.contents}
                value={productDetail?.contents}
                onChange={(e) =>
                  setProductDetail(
                    produce((draft) => {
                      draft.contents = e?.target?.value;
                    }),
                  )
                }
                className={styles.content_textarea}
                cols="45"
                rows="4"
              />
              {canModify && <EditIcon edit={edit?.contents} onClick={() => toggleEdit('contents')} />}
            </div>
            <div style={{ marginBottom: 5 }}>
              <button onClick={handleSubmit} className={styles.save}>
                저장하기
              </button>
            </div>
            <p className={styles.sub_title_text} style={{ textAlign: 'right' }}>
              최종수정 {productDetail?.updatedBy?.nickname} {productDetail?.updatedOn}
            </p>
            <p className={styles.sub_title_text} style={{ textAlign: 'right' }}>
              {productDetail?.activeBy && `최종게시 ${productDetail?.activeBy?.nickname} ${productDetail?.activeAt}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetail;
