import React from 'react';
import styles from './Thumbnail.module.less';
import { parseImageUri } from '../../common/utils';
import { CloseOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import assets from '../../assets';
export default function Thumbnail({ id, className, onDelete, containerStyle, imageStyle, wrapperStyle }) {
  const [isError, setIsError] = React.useState(false);
  React.useEffect(() => {
    setIsError(false);
  }, [id]);
  return (
    <div
      style={
        wrapperStyle
          ? wrapperStyle
          : {
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }
      }
    >
      <div className={styles.container} style={containerStyle}>
        <Image
          className={className}
          alt={`thumbnail_${id}`}
          preview={!isError}
          onError={(e) => {
            setIsError(true);
          }}
          src={isError ? assets.icons.blank_profile : parseImageUri(id, 60, 60)}
          style={
            className
              ? {}
              : imageStyle
              ? imageStyle
              : { width: '50px', height: '50px', borderRadius: '5px', objectFit: 'contain' }
          }
        />
        {!!onDelete && (
          <div onClick={onDelete} className={styles.deleteButtonWrapper}>
            <CloseOutlined style={{ color: '#FFF' }} />
          </div>
        )}
      </div>
    </div>
  );
}
