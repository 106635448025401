import { Modal } from 'antd';
import React from 'react';
import RoundButton from '../components/button/RoundButton';
import CommentIcon from '../components/image/CommentIcon';
import Thumbnail from '../components/image/Thumbnail';
import styles from '../pages/collections/trand/trand.module.less';
import { HeartFilled, WechatFilled } from '@ant-design/icons';
import Comment from '../components/comment/Comment';
import { useQueryClient } from 'react-query';
import { POST_DETAIL_KEY } from '../apis/queryKeys';
import { parseImageUri } from '../common/utils';
import { useSelector } from 'react-redux';
const usePostDetailModal = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const PostDetailModal = ({ id, type }) => {
    const queryClient = useQueryClient();
    const data = queryClient.getQueryData([POST_DETAIL_KEY, type, id]);
    const { commonCodes } = useSelector((state) => state.common);

    return (
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
          padding: 0,
        }}
        closable={false}
        open={isModalOpen}
        footer={false}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        title={
          <div style={{width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0px 20px 0px 20px',}}>
            <RoundButton
                onClick={() => {
                  handleCancel();
                }}
                label="나가기"
                containerStyle={{
                  width: '100px',
                  marginRight: '10px',
                }}
            />

          </div>
        }

      >
        <img
          style={{ width: '100%', objectFit: 'cover', height: '450px', marginLeft: 'auto' }}
          src={parseImageUri(data?.thumbnailId)}
          alt=""
        />
        <div style={{ padding: '50px 100px' }}>
          <p
            style={{
              fontSize: '12px',
              color: '#767676',
            }}
          >
            {commonCodes?.map[data?.tag]}
          </p>
          <p
            style={{
              fontSize: '16px',
              fontWeight: '600',
              color: '#000',
            }}
          >
            {data?.title}
          </p>
          <div className={styles.flex_div}>
            <div className={styles.flex_div} style={{ alignItems: 'center' }}>
              <Thumbnail
                  containerStyle={{
                    width: 30,
                    height: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  imageStyle={{ width: 30, height: 30, borderRadius: '50%' }}
                  id={data?.createdBy?.profileImageId}
              ></Thumbnail>
              <div>
              <p className={styles.category_p} style={{ marginLeft: 10, color: 'black',whiteSpace:'nowrap' }}>
                {data?.createdBy?.nickname}
              </p>
              </div>
            </div>
            <div className={styles.flex_div}>
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <HeartFilled style={{ fontSize: 18, color: '#d9d9d9' }} />
                <p className={styles.style_button_count_p} style={{ color: '#999999' }}>
                  {data?.likeInfo?.likeCount}
                </p>
              </div>
              <div style={{ marginLeft: 5, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <CommentIcon style={{ fontSize: 18, color: '#d9d9d9' }} />
                <p className={styles.style_button_count_p} style={{ color: '#999999' }}>
                  {data?.shareCount}
                </p>
              </div>
            </div>
          </div>
          <p style={{ margin: '25px 0 50px', fontSize: 14, color: '#767676' }}>{data?.description}</p>
          {data?.subContents?.map((v) => (
            <div className={styles.style_section_div}>
              <p className={styles.title_p}>{v?.title}</p>
              {v.subContentItems?.map((v) => (
                  <>
                  {v.thumbnailId && (
                      <img className={styles.style_section_main_img} src={parseImageUri(v.thumbnailId)} alt="" />
                  )}
                    {
                      v.content&& <p className={styles.style_section_desc_p}>{v.content}</p>
                    }
                  </>
              ))}
            </div>
          ))}
        </div>
        <div style={{ padding: '50px 100px' }}>
          <Comment />
        </div>
      </Modal>
    );
  };
  return { showModal, setIsModalOpen, PostDetailModal };
};

export default usePostDetailModal;
