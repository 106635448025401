import { Modal, Pagination } from 'antd';
import React from 'react';
import { FilterInput } from '../components/template/page';
import PaginateTable from '../components/table/PaginateTable';
import { dummyData } from '../data/dummy';
import UserDetail from '../components/card/userdetail/UserDetail';
import followImage from '../assets/dummy/user/04.jpg';
import { useGetLikedFollowsList, useGetUserDetail } from '../apis/queries';
import { parseImageUri } from '../common/utils';
import produce from 'immer';

import PartnerUserDetail from '../components/card/userdetail/PartnerUserDetail';

const useUserFollowModal = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const UserFollowModal = ({ userType, agency, selectedId }) => {
    console.log(selectedId);
    const initialParams = React.useMemo(
      () => ({
        limit: 50,
        page: 0,
      }),
      [],
    );
    const [params, setParams] = React.useState(initialParams);
    const [partnerDetail, setPartnerDetail] = React.useState({});
    const { data } = useGetLikedFollowsList({
      params,
      id: selectedId,
      config: {
        enabled: !!selectedId,
      },
    });

    return (
      <Modal
        width={1320}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          paddingBottom: '100px',
          overflowY: 'hidden',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={true}
        footer={null}
      >
        <div style={{ padding: '35px 45px', height: '100%' }}>
          <p style={{ fontSize: 24, fontWeight: 600, marginBottom: 50 }}>팔로우</p>
          <div style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
            <div style={{ width: '65%', height: '100%' }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'white',
                  overflowY: 'scroll',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    cursor: 'pointer',
                    padding: '50px 30px',
                  }}
                >
                  {data?.content?.map((item, index) => {
                    console.log(item);
                    return (
                      <div
                        key={`goodsList ${index}`}
                        style={{ width: '20%', textAlign: 'center', margin: '25px 0' }}
                        onClick={() => {
                          setPartnerDetail(
                            produce((draft) => {
                              draft.id = item.id;
                              draft.type = item.type;
                            }),
                          );
                        }}
                      >
                        {item && (
                          <>
                            <img
                              src={parseImageUri(item?.profileImageId)}
                              alt=""
                              style={{ width: 80, height: 80, borderRadius: '50%' }}
                            />
                            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{item?.nickname}</p>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    paddingBottom: '20px',
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Pagination
                    showSizeChanger
                    onChange={(page, pageSize) => {
                      setParams(
                        produce((draft) => {
                          draft.page = page - 1;
                        }),
                      );
                    }}
                    defaultCurrent={1}
                    current={params?.page + 1}
                    pageSize={params?.limit}
                    onShowSizeChange={(current, size) => {
                      setParams(
                        produce((draft) => {
                          draft.limit = size;
                        }),
                      );
                    }}
                    total={data?.totalElements}
                  />
                </div>
              </div>
            </div>
            <div style={{  minwidth: 550, height: '100%', overflowY: 'scroll', paddingBottom: 50 }}>
              {/* TODO : 시공사 카드로 교체 */}
              {partnerDetail?.id && (
                <PartnerUserDetail detailId={partnerDetail?.id} agency={partnerDetail?.type} userType={'agencies'} />
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  return { showModal, setIsModalOpen, UserFollowModal };
};

export default useUserFollowModal;
