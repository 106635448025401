import { Checkbox, Modal } from 'antd';
import React from 'react';
import RoundButton from '../button/RoundButton';
import { useSelector } from 'react-redux';
import styles from './ShowMaterialModal.module.less';
import { MagnifierContainer, MagnifierPreview, MagnifierZoom } from 'react-image-magnifiers';
import { FilterInput } from '../template/page';
import { useGetProductCategories, useGetProductList, useGetTemplateDetail } from '../../apis/queries';
import { findCategoryDeps, parseImageUri } from '../../common/utils';
import produce from 'immer';
import { PRINT_LABELS } from '../../common/define';
import { CheckOutlined } from '@ant-design/icons';
import { InputNumber, Pagination } from 'antd';

const ProductItem = React.memo(({ item, productCategories, unitName, handleSelectItem, products, setCurrentItem }) => {
  const isSelcted = React.useMemo(
    () => !!products?.flatMap((v) => v.products)?.find((v) => v.id === item?.id),
    [products, item],
  );

  return (
    <div
      onClick={() => {
        setCurrentItem(item);
        handleSelectItem(item);
      }}
      className={styles.productItemWrapper}
    >
      {isSelcted && (
        <div className={styles.checked}>
          <CheckOutlined
            style={{
              color: '#FFF',
              fontSize: '20px',
            }}
          />
        </div>
      )}
      <img
        className={styles.productImage}
        style={{
          filter: isSelcted ? 'brightness(0.7)' : 'brightness(1)',
        }}
        src={parseImageUri(item?.thumbnailId)}
        alt={`product_${item?.thumbnailId}`}
      />
      <span className={styles.productCategoryText}>
        {findCategoryDeps(productCategories?.tree, item?.categoryId).join(' > ')}
      </span>
      <span
        className={styles.productTitleText}
        style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
      >
        {item?.name}
      </span>
      <span className={styles.productWriterText}>{item?.makerName}</span>
      <div className={styles.rowWrapper}>
        <span className={styles.priceText}>유통사가</span>
        <span className={styles.priceText}>{`${item?.price?.toLocaleString()} 원/${unitName}`}</span>
      </div>
      <div className={styles.rowWrapper}>
        <span className={styles.priceText}>소비자가</span>
        <div>
          <span className={styles.priceText} style={{ color: '#1e3d83', fontWeight: '900', fontSize: '15px' }}>
            {item?.price.toLocaleString()}
          </span>
          <span className={styles.priceText}>{` 원/${unitName}`}</span>
        </div>
      </div>
    </div>
  );
});

const ProductCard = React.memo(({ item, productCategories, unitName, maxProductCount, setproducts }) => {
  return (
    <div className={styles.productCardWrapper}>
      <img
        className={styles.productCardImage}
        src={parseImageUri(item?.thumbnailId)}
        alt={`productCard_${item?.thumbnailId}`}
      />
      <div className={styles.productCardInfoWrapper}>
        <span
          className={styles.productTitleText}
          style={{
            fontSize: '12px',
            width: '150px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            margin: '0px',
          }}
        >
          {item?.name}
        </span>
        <span className={styles.productCategoryText} style={{ fontSize: '10px', marginTop: '0px' }}>
          {findCategoryDeps(productCategories?.tree, item?.categoryId).join(' > ')}
        </span>
        <span
          className={styles.priceText}
          style={{
            width: '100%',
            textAlign: 'right',
            fontSize: '10px',
          }}
        >{`${item?.price?.toLocaleString()} 원/${unitName}`}</span>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0px' }}>
          <InputNumber
            disabled
            size="small"
            style={{
              width: '70px',
              color: '#767676',
              fontSize: '12px',
              border: '2px solid #e6e6e6',
              borderRadius: '5px',
            }}
            min={1}
            max={maxProductCount}
            value={item?.qty}
          />
          <div>
            <span className={styles.priceText} style={{ color: '#1e3d83', fontWeight: '900', fontSize: '12px' }}>
              {(item?.price * item?.qty).toLocaleString()}
            </span>
            <span className={styles.priceText}>{` 원/${unitName}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
});

const ShowMaterialModal = React.memo(({ selectedId, visible, setVisible, handleOk, handleCancel, data }) => {
  const initialParams = React.useMemo(
    () => ({
      limit: 20,
      page: 0,
      categoryId: null, // TODO : 배열로
      keyword: null,
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  const [keyword, setKeyword] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [zoomHeight, setZoomHeight] = React.useState(0);
  const [currentItem, setCurrentItem] = React.useState({});

  const { data: productList } = useGetProductList({
    params,
    config: {
      enabled: !!params?.categoryId,
    },
  });
  const { data: productCategories } = useGetProductCategories();
  const { data: template } = useGetTemplateDetail({
    id: selectedId,
    config: {
      enabled: !!selectedId,
    },
  });
  React.useEffect(() => {
    const products = data?.areas?.flatMap((v) => v.estimateResponses)?.filter((v) => v.templateId === selectedId);
    setProducts(products);
  }, [data, selectedId]);

  const categoryList = React.useMemo(() => {
    if (!template) return [];
    const categoryIds = template?.productCategoryIds;

    let categories = categoryIds?.map((v) => ({
      categoryId: v,
      categoryName: findCategoryDeps(productCategories?.tree, v)?.join(' > '),
    }));
    return categories;
  }, [template]);

  const onCancel = () => {
    setParams(initialParams);
    setCurrentItem({});
    handleCancel();
  };

  return (
    <Modal
      width={'90%'}
      bodyStyle={{
        width: '100%',
        height: document.body.clientHeight - 100,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
        padding: '0px',
      }}
      closable={false}
      open={visible}
      onOk={handleOk}
      onCancel={() => {
        onCancel();
      }}
      destroyOnClose={true}
      footer={null}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div className={styles.modalContainer}>
        <div className={styles.leftContainer}>
          <h3 className={styles.titleText}>자재보기</h3>
          <MagnifierContainer className={styles.thumbnailContainer}>
            <MagnifierZoom
              key={`${currentItem?.thumbnailId}_zoom`}
              style={{
                height: '100%',
                width: '100%',
                borderRadius: '20px',
                objectFit: 'cover',
                opacity: 1,
              }}
              imageSrc={parseImageUri(currentItem?.thumbnailId)}
            />
            <div
              style={{
                width: '120px',
                height: zoomHeight,
                position: 'absolute',
                bottom: '20px',
                right: '40px',
                zIndex: 99,
              }}
            >
              <MagnifierPreview
                onImageLoad={(e) => {
                  const { naturalWidth, naturalHeight } = e.target;
                  const ratio = naturalWidth / naturalHeight;
                  const imageHeight = 120 / ratio;
                  setZoomHeight(imageHeight);
                }}
                key={`${currentItem?.thumbnailId}_preview`}
                style={{
                  zIndex: 99,
                }}
                imageSrc={parseImageUri(currentItem?.thumbnailId)}
              />
            </div>
          </MagnifierContainer>
        </div>
        <div className={styles.rightContainer}>
          <FilterInput
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            style={{ width: 250, marginRight: 10 }}
            placeholder="검색어를 입력하세요"
          />
          <div className={styles.filterWrapper}>
            <div className={styles.filterItemWrapper}>
              {categoryList?.map((v) => (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Checkbox
                    onChange={(e) => {
                      const checked = e.target.checked;
                      if (checked) {
                        // TODO : 배열로 변경
                        setParams(
                          produce((draft) => {
                            draft.categoryId = v.categoryId;
                          }),
                        );
                      }
                    }}
                    // TODO : 배열로 수정
                    checked={params?.categoryId?.includes(v.categoryId)}
                  />
                  <span className={styles.categoryName}>{v.categoryName}</span>
                </div>
              ))}
            </div>
            <RoundButton
              onClick={() => {
                setParams(
                  produce((draft) => {
                    draft.categoryId = null;
                  }),
                );
              }}
              containerStyle={{
                marginLeft: '40px',
                width: '100px',
              }}
              label="CLEAR ALL"
            />
            <div className={styles.buttonWrapper}>
              <RoundButton
                onClick={() => {
                  onCancel();
                }}
                containerStyle={{
                  width: '100px',
                }}
                label="확인"
              />
            </div>
          </div>
          <div className={styles.itemListContainer}>
            <div className={styles.itemListWrapper}>
              {productList?.content?.map((v) => (
                <ProductItem
                  item={v}
                  productCategories={productCategories}
                  unitName={PRINT_LABELS[template?.printUnitName]}
                  handleSelectItem={() => {}}
                  products={products}
                  setCurrentItem={setCurrentItem}
                />
              ))}
            </div>
            <div className={styles.itemListFooterWrapper}>
              <Pagination
                showSizeChanger
                onChange={(page, pageSize) => {
                  setParams(
                    produce((draft) => {
                      draft.page = page - 1;
                    }),
                  );
                }}
                defaultCurrent={1}
                current={params?.page + 1}
                pageSize={params?.limit}
                onShowSizeChange={(current, size) => {
                  setParams(
                    produce((draft) => {
                      draft.limit = size;
                    }),
                  );
                }}
                total={productList?.totalElements}
              />
            </div>
          </div>
          <div className={styles.itemCardListWrapper}>
            {products?.flatMap((v) =>
              v?.products.map((v2) => (
                <ProductCard
                  item={v2}
                  productCategories={productCategories}
                  unitName={PRINT_LABELS[template?.printUnitName]}
                  maxProductCount={template?.maxProductCount}
                />
              )),
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default ShowMaterialModal;
