import React, {useEffect} from 'react';
import {PageHeader, Input, Radio, Space, InputNumber} from 'antd';
import {restApi} from '../../../apis';
import {useGetProposalCost} from '../../../apis/queries';
import styles from './ExpertSetting.module.less';
import { comma } from '../../../common/utils';
import produce from 'immer';

const ExpertSetting = () => {

  const [request, setRequest] = React.useState(undefined);
  const [value, setValue] = React.useState(false);

  const onChange = (e) => {
    setRequest(produce((draft) => {
      draft.isRate = e.target.value
    }))
  };
  const {data,refetch } = useGetProposalCost({});
  useEffect(() => {
    if(data){
      setRequest(data)
    }
  },[data]);
  const handleSubmit = async () => {
    if(!value)return alert('수수료 변경 동의 여부를 체크해주세요.')
    if(request?.ranges?.filter(v=>!v.price).length>0){
      return alert('책정가를 입력해주세요.')
    }
    try {


      await restApi.put('/proposal-costs',request);
      alert('저장되었습니다.');
      refetch();
      setValue(false);
    }catch (e){
      alert(e)
    }
  }
  const PaymentSection = ({ item, index, radioValue }) => {
    const [price, setPrice] = React.useState();
    const [under, setUnder] = React.useState(item?.max);
    const handleBlureUnder = (event) => {
      const min = item?.min;
      if (min >= event.target.value) {
        event.target.value = parseInt(item?.min) + 1;
      }
      const minValue = Math.max(min, Number(event.target.value));
      setUnder(minValue);

      setRequest(
        produce((draft) => {
          if (draft?.ranges?.[index + 1]?.min) {
            draft.ranges[index + 1].min = minValue;
            if (index + 2 !== draft.ranges.length) {
              draft.ranges[index + 1].max = minValue;
            }
          }
          draft.ranges[index].max = minValue;
        }),
      );
    };
    const handleUnderChange = (event) => {
      setUnder(event.target.value);
    };

    const handlePriceChange = (event) => {
      setPrice(event.target.value);
    };
    const handlePlus = () => {
      setRequest(
        produce((draft) => {
          if (!item?.max) {
            draft.ranges[index].max = item?.min + 1;
          }

          draft.ranges?.push({
            min: draft.ranges[index].max,
            max: null,
          });
        }),
      );
    };

    const handleMinus = () => {
      setRequest(
        produce((draft) => {
          draft?.ranges?.pop();
          draft.ranges[draft?.ranges?.length - 1].max = null;
        }),
      );
    };
    return (
      <div className={styles.flex_three_div}>
        <p className={styles.gray_text}>구간 {index + 1}</p>
        <p>
          <span className={styles.gray_text} style={{ display: 'inline-block', width: '40%' }}>
            {comma(item?.min)} 원 초과
          </span>
          <span style={{ display: 'inline-block', width: '10%' }}>~</span>
          {request?.ranges?.length === index + 1 ? (
            <span style={{ display: 'inline-block', width: '50%' }}>∞</span>
          ) : (
            <div className={styles.gray_text} style={{ display: 'inline-block', width: '50%' }}>
              <input
                type="number"
                value={under}
                min={item?.min + 1}
                defaultValue={item?.max}
                onChange={handleUnderChange}
                onBlur={handleBlureUnder}
                disabled={index < request.ranges?.length - 2}
                style={{
                  color: 'black',
                  fontSize: 14,
                }}
              />
              원 이하
            </div>
          )}
        </p>
        <p className={styles.gray_text}>
          <input
            type="number"
            value={price}
            defaultValue={item?.price}
            onChange={handlePriceChange}
            onBlur={(e) =>
              setRequest(
                produce((draft) => {
                  draft.ranges[index].price = e.target.value;
                }),
              )
            }
            style={{
              color: 'black',
              fontSize: 14,
            }}
          />
          원/건
        </p>
        <p>
          {request?.ranges?.length === index + 1 && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <button
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  width: 20,
                  height: 20,
                  paddingTop: 5,
                  marginRight: 5,
                  borderRadius: 4,
                  backgroundColor: '#d9d9d9',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                }}
                onClick={handlePlus}
              >
                +
              </button>
              {request?.ranges?.length > 1 && (
                <button
                  style={{
                    fontWeight: 'bold',
                    fontSize: 20,
                    width: 20,
                    height: 20,
                    borderRadius: 4,
                    backgroundColor: '#d9d9d9',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                  }}
                  onClick={handleMinus}
                >
                  -
                </button>
              )}
            </div>
          )}
        </p>
      </div>
    );
  };
  return (
    <div style={{display:'flex',flexDirection:'column'}}>
      <PageHeader
        className="site-page-header"
        title="서비스 비용 설정"
        style={{ paddingBottom: 10, width: '840px', marginLeft: 'auto', marginRight: 'auto' }}
      />
      <p
        style={{
          paddingBottom: 10,
          width: '840px',
          marginTop: 50,
          marginLeft: 'auto',
          marginRight: 'auto',
          fontSize: 16,
          fontWeight: 600,
          color: '#000',
        }}
      >
        전문가 공사도움받기 비용설정
      </p>
      <div className={styles.expert_setting_div}>
        <Radio.Group onChange={onChange} value={request?.isRate}>
          <Space direction="vertical">
            <Radio value={false}>
              <span className={styles.title_span}>구간설정방식</span>
              <br />
              <span className={styles.sub_title_span}>견적금액의 구간별로 정액의 수수료를 적용합니다</span>
            </Radio>
            <div
              style={{
                fontSize: 12,
                width: '100%',
                height: '320px',
                overflowY: 'scroll',
                marginTop: 40,
                position: 'relative',
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: request?.isRate ? '100%' : '0%',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  position: 'absolute',
                  borderRadius: 10,
                }}
              ></div>

              <div className={styles.flex_three_div}>
                <p></p>
                <p className={styles.gray_text}>견적금액</p>
                <p className={styles.gray_text}>책정가</p>
                <p></p>
              </div>
              {request?.ranges?.map((item, index) => {
                return (
                  <PaymentSection
                    key={`paymentSection ${index}`}
                    radioValue={request?.isRate}
                    item={item}
                    index={index}
                  />
                );
              })}
            </div>
            <Radio value={true}>
              <span className={styles.title_span}>비율설정방식</span> <br />
              <span className={styles.sub_title_span}>견적금액에 일률적으로 소정의 수수료 비율을 적용 합니다.</span>
              <div style={{ fontSize: 12, width: '100%', marginTop: 40 }}>
                <InputNumber
                  min={0}
                  max={100}
                  disabled={!request?.isRate}
                  value={request?.rate}
                  onChange={(e) =>
                    setRequest(
                      produce((draft) => {
                        draft.rate = e;
                      }),
                    )
                  }
                  className={styles.percentage_input}
                  type="number"
                  placeholder="수수료를 기입해주세요"
                />
                <span style={{ position: 'relative', left: -30 }}>%</span>
              </div>
            </Radio>
          </Space>
        </Radio.Group>
        <div style={{ margin: '70px 0', textAlign: 'center' }}>
          <input checked={value} onChange={setValue} type="checkbox" />
          <span style={{ marginLeft: 20 }}>수수료율을 변경하는데 필요한 모든 사내 결재를 완료했음을 확인합니다.</span>
        </div>
        <div style={{ textAlign: 'center' }}>
          <button onClick={handleSubmit} className={styles.save_button}>
            저장하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExpertSetting;
