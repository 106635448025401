import moment from 'moment';
import React from 'react';
import styles from './LocationInfo.module.less';
import { NavermapsProvider, Container as MapDiv, NaverMap, Marker } from 'react-naver-maps';
const LocationInfo = ({ data }) => {
  const [coordinate, setCoordinate] = React.useState({
    latitude: null,
    longitude: null,
  });

  React.useEffect(() => {
    if (data) {
      setTimeout(() => {
        setCoordinate({
          latitude: data?.latitude,
          longitude: data?.longitude,
        });
      }, 100);
    }
  }, [data]);
  return (
    <div style={{marginBottom:30}}>
      <h3 style={{ marginTop: 20, marginBottom: 5 }}>현장정보</h3>
      <div className={styles.location_container}>
        <div className={styles.image_div}>
          <div className={styles.backdrop} />
          <NavermapsProvider ncpClientId={process.env.REACT_APP_MAP_CLIENT_ID||'tdu4z1a1i2'}>
            <MapDiv style={{ width: '100%', height: '100%' }}>
              <NaverMap
                center={{ lat: coordinate?.latitude, lng: coordinate?.longitude }}
                centerPoint={{ x: 300, y: 300 }}
                mapDivId="map"
                defaultZoom={16}
                zoomControl={false}
              >
                <Marker position={{ lat: coordinate?.latitude, lng: coordinate?.longitude }} />
              </NaverMap>
            </MapDiv>
          </NavermapsProvider>
        </div>
        <div className={styles.content_box_div}>
          <p>{data?.apartment?.name}</p>
          <div className={styles.line}>
            <span className={styles.small_bold_content}>현장주소</span>
            <span className={styles.small_content}>{data?.apartment?.addressRoad}</span>
          </div>
          <div className={styles.line}>
            <span className={styles.small_bold_content}>사용 승인일</span>
            <span className={styles.small_content}>{moment(data?.usedAt).format('YYYY.MM.DD')}</span>
          </div>
          <div className={styles.line}>
            <span className={styles.small_bold_content}>분양면적 / 전용면적</span>
            <span className={`${styles.small_content} ${styles.small_long_content}`}>
              {data?.supplyArea}㎡/{data?.exclusiveArea}㎡
            </span>
            <button className={styles.small_bold_content}>평환산</button>
          </div>
          <div className={styles.line}>
            <span className={styles.small_bold_content}>방 / 화장실 개소</span>
            <span className={styles.small_content}>
              {data?.roomCount}/{data?.bathRoomCount}
            </span>
          </div>
          <div style={{ textAlign: 'center' }}>
            <button className={styles.naver_more_btn}>네이버평면 보기</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationInfo;
