import {useState} from 'react';
import styled from 'styled-components';
import checked from '../../assets/checkedBox.png'
import unChecked from '../../assets/UncheckedBox.png'


const StyledInput = styled.input`
  appearance: none;
  border: none;
  //border-radius: 0.35rem;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: -5%;
  background-repeat: no-repeat;
  margin-right: -2px;
  background-image: url(${unChecked});
  transition: background-image 0.3s;
  &:checked {
    border-color: transparent;
    background-image: url(${checked});
    background-size: 100% 100%;
    background-position: -5%;
    //background-position: 50%;
    background-repeat: no-repeat;
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  user-select: none;
`;

const StyledP = styled.p`
  //margin-left: 0.25rem;
  color: ${({ checked }) => (checked ? '#1e3d83' : '#767676')};
  margin-left: 4px;
  font-size: 14px;
  font-weight: ${({ checked }) => (checked ? '500' : '400')};
  transition: color 0.3s, font-weight 0.3s;
`;
function CustomCheckBox({ checked, onChange, text, onClick }) {
  return (
    <StyledLabel htmlFor={text}>
      <StyledInput
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        type="checkbox"
        id={text}
        name={text}
      ></StyledInput>
      <StyledP checked={checked}>{text}</StyledP>
    </StyledLabel>
  );
}

export default CustomCheckBox;
