import { PageHeader, Descriptions, Table, Button, Desciptions, Select, DatePicker, Input } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './index.module.less';
import StyledTable from '../../../../components/table/StyledTable';
import { formatComma } from '../../../../common/utils';

//////////////

const PERIOD = [
  { label: '1개월', value: '1m' },
  { label: '3개월', value: '3m' },
  { label: '6개월', value: '6m' },
];

// 소비자회원 상세 api x
export default function AccountUsersDetails() {
  const params = useParams();

  const [columns, setColumns] = React.useState([]);
  const [listType, setListType] = React.useState('ESTIMATE');
  const [data, setData] = React.useState([]);

  const onChangeListType = (type) => {
    setListType(type);
  };

  const ESTIMATE_DATA = [
    {
      id: 1,
      estimate_name: '이사갈 집',
      pawn_name: '개봉 푸르지오',
      area: 34,
      address: '서울시 구로구 남부순환로 775',
      estimate_price: 34000000,
      estimate_date: '2023.03.20 12:24:57',
      estimate_price2: 34000000,
    },
  ];

  const PROFILE_LIST_ESTIMATE = [
    {
      title: '번호',
      key: 'id',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: '견적명',
      key: 'estimate_name',
      dataIndex: 'estimate_name',
      align: 'center',
      sorter: true,
    },
    {
      title: '주택명',
      key: 'pawn_name',
      dataIndex: 'pawn_name',
      align: 'center',
      sorter: true,
    },
    {
      title: '면적',
      key: 'area',
      dataIndex: 'area',
      align: 'center',
      sorter: true,
      render: (area) => {
        return <span>{`${area}PY`}</span>;
      },
    },
    {
      title: '주소',
      key: 'address',
      dataIndex: 'address',
      align: 'center',
      sorter: true,
    },
    {
      title: '견적금액',
      key: 'estimate_price',
      dataIndex: 'estimate_price',
      align: 'center',
      sorter: true,
      render: (estimate_price) => {
        return <span>{formatComma(estimate_price)}</span>;
      },
    },
    {
      title: '견적일시',
      key: 'estimate_date',
      dataIndex: 'estimate_date',
      align: 'center',
      sorter: true,
    },
    {
      title: '견적금액',
      key: 'estimate_price2',
      dataIndex: 'estimate_price2',
      align: 'right',
      sorter: true,
      render: (estimate_price2) => {
        return <span>{formatComma(estimate_price2)}</span>;
      },
    },
  ];

  const CONSTRUCTION_DATA = [
    {
      id: 1,
      state: '신청중',
      pawn_name: '개봉 푸르지오',
      area: 34,
      address: '서울시 구로구 남부순환로 775',
      estimate_price: 34000000,
      created_at: '2023.03.20 12:24:57',
      closed_at: '2023.03.20 12:24:57',
      proposal_amount: 1,
    },
    {
      id: 2,
      state: '신청만료',
      pawn_name: '개봉 푸르지오',
      area: 34,
      address: '서울시 구로구 남부순환로 775',
      estimate_price: 34000000,
      created_at: '2023.03.20 12:24:57',
      closed_at: '2023.03.20 12:24:57',
      proposal_amount: 1,
    },
  ];

  const PROFILE_LIST_CONSTRUCTION = [
    {
      title: '번호',
      key: 'id',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: '상태',
      key: 'state',
      dataIndex: 'state',
      align: 'center',
      sorter: true,
      render: (state) => {
        return (
          <div key={`CONSRUCTION_${state}`} className={styles.alignInline}>
            <div
              style={{
                width: 14,
                height: 14,
                marginRight: 3,
                backgroundColor: state === '신청중' ? '#4c72f1' : '#ff0000',
              }}
            />
            <span>{state}</span>
          </div>
        );
      },
    },
    {
      title: '주택명',
      key: 'pawn_name',
      dataIndex: 'pawn_name',
      align: 'center',
      sorter: true,
    },
    {
      title: '면적',
      key: 'area',
      dataIndex: 'area',
      align: 'center',
      sorter: true,
      render: (area) => {
        return <span>{`${area}PY`}</span>;
      },
    },
    {
      title: '주소',
      key: 'address',
      dataIndex: 'address',
      align: 'center',
      sorter: true,
    },
    {
      title: '견적금액',
      key: 'estimate_price',
      dataIndex: 'estimate_price',
      align: 'center',
      sorter: true,
      render: (estimate_price) => {
        return <span>{formatComma(estimate_price)}</span>;
      },
    },
    {
      title: '신청일시',
      key: 'created_at',
      dataIndex: 'created_at',
      align: 'center',
      sorter: true,
    },
    {
      title: '마감일시',
      key: 'closed_at',
      dataIndex: 'closed_at',
      align: 'center',
      sorter: true,
    },
    {
      title: '제안업체수',
      key: 'proposal_amount',
      dataIndex: 'proposal_amount',
      align: 'center',
      sorter: true,
      render: (proposal_amount) => {
        return <span>{formatComma(proposal_amount)}</span>;
      },
    },
  ];

  const COUNSEL_DATA = [
    {
      id: 1,
      state: '상담대기',
      corp_name: '민 인테리어',
      pawn_name: '개봉 푸르지오',
      area: 34,
      address: '서울시 구로구 남부순환로 775',
      estimate_price: 34000000,
      counsel_created_at: '2023.03.20 12:24:57',
      amount: 34000000,
      counsel_updated_at: '2023.03.20 12:24:57',
    },
    {
      id: 2,
      state: '상담',
      corp_name: '민 인테리어',
      pawn_name: '개봉 푸르지오',
      area: 34,
      address: '서울시 구로구 남부순환로 775',
      estimate_price: 34000000,
      counsel_created_at: '2023.03.20 12:24:57',
      amount: 34000000,
      counsel_updated_at: '2023.03.20 12:24:57',
    },
    {
      id: 3,
      state: '상담종료',
      corp_name: '민 인테리어',
      pawn_name: '개봉 푸르지오',
      area: 34,
      address: '서울시 구로구 남부순환로 775',
      estimate_price: 34000000,
      counsel_created_at: '2023.03.20 12:24:57',
      amount: 34000000,
      counsel_updated_at: '2023.03.20 12:24:57',
    },
  ];

  const PROFILE_LIST_COUNSEL = [
    {
      title: '번호',
      key: 'id',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: '상태',
      key: 'state',
      dataIndex: 'state',
      align: 'center',
      sorter: true,
      render: (state) => {
        return (
          <div key={`Counsel_${state}`} className={styles.alignInline}>
            <div
              style={{
                width: 14,
                height: 14,
                marginRight: 3,
                backgroundColor: state === '상담대기' ? '#ff0000' : state === '상담' ? '#4c72f1' : '#e6ff00',
              }}
            />
            <span>{state}</span>
          </div>
        );
      },
    },
    {
      title: '업체명',
      key: 'corp_name',
      dataIndex: 'corp_name',
      align: 'center',
      sorter: true,
    },
    {
      title: '주택명',
      key: 'pawn_name',
      dataIndex: 'pawn_name',
      align: 'center',
      sorter: true,
    },
    {
      title: '면적',
      key: 'area',
      dataIndex: 'area',
      align: 'center',
      sorter: true,
      render: (area) => {
        return <span>{`${area}PY`}</span>;
      },
    },
    {
      title: '주소',
      key: 'address',
      dataIndex: 'address',
      align: 'center',
      sorter: true,
    },
    {
      title: '견적금액',
      key: 'estimate_price',
      dataIndex: 'estimate_price',
      align: 'center',
      sorter: true,
      render: (estimate_price) => {
        return formatComma(estimate_price);
      },
    },
    {
      title: '상담요청일시',
      key: 'counsel_created_at',
      dataIndex: 'counsel_created_at',
      align: 'center',
      sorter: true,
    },
    {
      title: '최종상담금액',
      key: 'amount',
      dataIndex: 'amount',
      align: 'center',
      sorter: true,
      render: (amount) => {
        return formatComma(amount);
      },
    },
    {
      title: '최종상담일시',
      key: 'counsel_updated_at',
      dataIndex: 'counsel_updated_at',
      align: 'center',
      sorter: true,
    },
  ];

  const IM_DATA = [
    {
      id: 1,
      state: '진행중',
      corp_name: '민 인테리어',
      pawn_name: '개봉 푸르지오',
      area: 34,
      address: '서울시 구로구 남부순환로 775',
      contract_amount: 34000000,
      branch: '서울 중부',
      construction_created_at: '2023.03.20',
      construction_finished_at: '2023.03.20',
      hasSchedule: false,
    },
    {
      id: 2,
      state: '완료',
      corp_name: '민 인테리어',
      pawn_name: '개봉 푸르지오',
      area: 34,
      address: '서울시 구로구 남부순환로 775',
      contract_amount: 34000000,
      branch: '서울 중부',
      construction_created_at: '2023.03.20',
      construction_finished_at: '2023.03.20',
      hasSchedule: true,
    },
  ];

  const PROFILE_LIST_IM = [
    {
      title: '번호',
      key: 'id',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: '상태',
      key: 'state',
      dataIndex: 'state',
      align: 'center',
      sorter: true,
      render: (state) => {
        return (
          <div className={styles.alignInline}>
            <div
              style={{
                width: 14,
                height: 14,
                marginRight: 3,
                backgroundColor: state === '진행중' ? '#e6ff00' : '#ff0000',
              }}
            />
            <span>{state}</span>
          </div>
        );
      },
    },
    {
      title: '업체명',
      key: 'corp_name',
      dataIndex: 'corp_name',
      align: 'center',
      sorter: true,
    },
    {
      title: '주택명',
      key: 'pawn_name',
      dataIndex: 'pawn_name',
      align: 'center',
      sorter: true,
    },
    {
      title: '면적',
      key: 'area',
      dataIndex: 'area',
      align: 'center',
      sorter: true,
      render: (area) => {
        return <span>{`${area}PY`}</span>;
      },
    },
    {
      title: '주소',
      key: 'address',
      dataIndex: 'address',
      align: 'center',
      sorter: true,
    },
    {
      title: '계약금액',
      key: 'contract_amount',
      dataIndex: 'contract_amount',
      align: 'center',
      sorter: true,
      render: (contract_amount) => {
        return <span>{formatComma(contract_amount)}</span>;
      },
    },
    {
      title: '담당지부',
      key: 'branch',
      dataIndex: 'branch',
      align: 'center',
      sorter: true,
    },
    {
      title: '공사시작일',
      key: 'construction_created_at',
      dataIndex: 'construction_created_at',
      align: 'center',
      sorter: true,
    },
    {
      title: '공사완료일',
      key: 'construction_finished_at',
      dataIndex: 'construction_finished_at',
      align: 'center',
      sorter: true,
    },
    {
      title: '공사일정표',
      key: 'hasSchedule',
      dataIndex: 'hasSchedule',
      align: 'center',
      sorter: true,
      render: (hasSchedule) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                width: 10,
                height: 10,
                borderRadius: '50%',
                backgroundColor: hasSchedule ? '#4c72f1' : '#ff0000',
                textAlign: 'center',
              }}
            />
          </div>
        );
      },
    },
  ];

  React.useEffect(() => {
    switch (listType) {
      case 'ESTIMATE':
        setColumns(PROFILE_LIST_ESTIMATE);
        setData(ESTIMATE_DATA);
        break;

      case 'CONSTRUCTION':
        setColumns(PROFILE_LIST_CONSTRUCTION);
        setData(CONSTRUCTION_DATA);
        break;

      case 'COUNSEL':
        setColumns(PROFILE_LIST_COUNSEL);
        setData(COUNSEL_DATA);
        break;
      case 'IM':
        setColumns(PROFILE_LIST_IM);
        setData(IM_DATA);
        break;
      default:
        break;
    }
  }, [listType]);

  return (
    <div>
      <PageHeader title="고객관리 > 소비자회원" style={{ paddingBottom: 10 }} />

      <div style={{ display: 'flex', marginBottom: 35 }}>
        <div
          style={{
            width: 170,
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#dbbfbf',
          }}
        >
          <div
            style={{
              width: '80%',
              height: '80%',
              backgroundColor: '#fff',
              borderRadius: '50%',
            }}
          ></div>
        </div>
        <table style={{ width: '100%' }} className={styles.profileTable}>
          <tr>
            <th>고객명/닉네임</th>
            <td>이민혜/포롱이</td>
            <th>스타일작성/댓글</th>
            <td className={styles.alignInline}>
              <div style={{ flex: 1 }} className={styles.alignInline} s>
                <div style={{ flex: 1, textAlign: 'center' }}>
                  <span>3</span>
                </div>
                <button className={styles.checkBtn}>확인</button>
              </div>
              <div style={{ flex: 1 }} className={styles.alignInline}>
                <div style={{ flex: 1, textAlign: 'center' }}>
                  <span>3</span>
                </div>
                <button className={styles.checkBtn}>확인</button>
              </div>
            </td>
          </tr>
          <tr>
            <th>전화번호</th>
            <td>010-1234-5678</td>
            <th>견적횟수</th>
            <td>20</td>
            <td>
              <button
                className={styles.detailBtn}
                style={
                  listType === 'COUNSEL'
                    ? {
                        color: '#fff',
                        backgroundColor: '#4c72f1',
                      }
                    : null
                }
                onClick={() => {
                  onChangeListType('COUNSEL');
                }}
              >
                자세히보기
              </button>
            </td>
          </tr>
          <tr>
            <th>주소</th>
            <td>경기도 성남시 분당구 이매동</td>
            <th>저장견적</th>
            <td>8</td>
            <td>
              <button
                className={styles.detailBtn}
                style={
                  listType === 'ESTIMATE'
                    ? {
                        color: '#fff',
                        backgroundColor: '#4c72f1',
                      }
                    : null
                }
                onClick={() => {
                  onChangeListType('ESTIMATE');
                }}
              >
                자세히보기
              </button>
            </td>
          </tr>
          <tr>
            <th>이메일</th>
            <td>jksc3255@naver.com</td>
            <th>공사도움받기 횟수</th>
            <td>3</td>
            <td>
              <button
                className={styles.detailBtn}
                style={
                  listType === 'CONSTRUCTION'
                    ? {
                        color: '#fff',
                        backgroundColor: '#4c72f1',
                      }
                    : null
                }
                onClick={() => {
                  onChangeListType('CONSTRUCTION');
                }}
              >
                자세히보기
              </button>
            </td>
          </tr>
          <tr>
            <th>가입일자</th>
            <td>2023-01-24 13:25:35</td>
            <th>IM서비스 횟수</th>
            <td>0</td>
            <td>
              <button
                className={styles.detailBtn}
                style={
                  listType === 'IM'
                    ? {
                        color: '#fff',
                        backgroundColor: '#4c72f1',
                      }
                    : null
                }
                onClick={() => {
                  onChangeListType('IM');
                }}
              >
                자세히보기
              </button>
            </td>
          </tr>
          <tr>
            <th>인증여부 및 결재정보</th>
            <td className={styles.alignInline}>
              <div style={{ flex: 1, textAlign: 'center', borderRight: '1px solid #f2f2f2' }}>YES</div>
              <div style={{ flex: 1 }} className={styles.alignInline}>
                <div style={{ textAlign: 'center', flex: 1 }}>
                  <span>YES</span>
                </div>
                <button className={styles.checkBtn}>확인</button>
              </div>
            </td>
            <th>포인트 현황</th>
            <td>0</td>
            <td>
              <button className={styles.detailBtn}>자세히보기</button>
            </td>
          </tr>
        </table>
      </div>

      <div className={styles.alignInline} style={{ justifyContent: 'space-between', marginBottom: 35 }}>
        <div className={styles.alignInline}>
          <span style={{ marginRight: 10 }}>비용</span>
          <Input.Group className={styles.alignInline} style={{ flex: 0 }}>
            <Input style={{ width: 120 }} /> <span style={{ marginInline: 5 }}>~</span> <Input style={{ width: 120 }} />
          </Input.Group>
        </div>
        <div className={styles.alignInline}>
          <div className={styles.alignInline} style={{ marginRight: 20 }}>
            <span style={{ marginRight: 10 }}>가입일</span>
            <DatePicker.RangePicker />
          </div>
          <div>
            {PERIOD.map((v, index) => {
              return (
                <Button key={`Period_${index}`} style={{ marginRight: 5 }}>
                  {v.label}
                </Button>
              );
            })}
          </div>
        </div>
        <div>
          <span>지역</span>
          <Select style={{ width: 120, marginInline: 10 }} />
          <Select style={{ width: 120 }} />
        </div>
      </div>

      <p style={{ fontSize: 16, fontWeight: '700' }}>
        {listType === 'ESTIMATE'
          ? '저장견적 리스트'
          : listType === 'CONSTRUCTION'
          ? '공사도움받기 진행현황 리스트'
          : listType === 'COUNSEL'
          ? '상담 진행현황'
          : 'IM서비스 진행현황'}
      </p>

      <StyledTable dataSource={data} columns={columns} />
    </div>
  );
}
