import {HeartFilled} from '@ant-design/icons';
import { PageHeader} from 'antd';
import {Option} from 'antd/lib/mentions';
import produce from 'immer';
import moment from 'moment';
import qs from 'query-string';
import React from 'react';
import {useQueryClient} from 'react-query';
import {useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {
  useGetConstructionRequestDetail,
  useGetProposedList, useGetProposedListByConstructionId,
} from '../../../../apis/queries';
import {
  CONSTRUCTION_REQUEST_KEY,
} from '../../../../apis/queryKeys';
import assets from '../../../../assets';
import {formatComma, getTimeAgo, parseImageUri} from '../../../../common/utils';
import ContentSlider
  from '../../../../components/card/ContentSlider/ContentSlider';
import LocationInfo
  from '../../../../components/card/LocationInfo/LocationInfo';
import SmallEstimate
  from '../../../../components/card/SmallEstimate/SmallEstimate';
import SmallUserDetail
  from '../../../../components/card/SmallUserDetail/SmallUserDetail';
import CommentIcon from '../../../../components/image/CommentIcon';
import Thumbnail from '../../../../components/image/Thumbnail';
import ShowEstimateModal from '../../../../components/modal/ShowEstimateModal';
import PaginateTable from '../../../../components/table/PaginateTable';
import TableColHeader from '../../../../components/table/TableColHeader';
import {
  ExtraWrapper,
  FilterSelect,
} from '../../../../components/template/page';
import useUpperLine from '../../../../hooks/useUpperLine';
import styles from './index.module.less';

const ContentCard = React.memo(({ item, commonCodes, setVisibleDetailModal }) => {
  return (
    <div
      onClick={() => {
        setVisibleDetailModal(true);
      }}
      className={styles.slider_div}
      style={{
        cursor: 'pointer',
        backgroundImage: `url(${parseImageUri(item?.thumbnailId)})`,
      }}
    >
      <div className={styles.backdrop} />
      <div className={styles.slider_inner_div}>
        <p className={styles.category_p}>{commonCodes?.map[item?.area]}</p>
        <p className={styles.title_p}>{item?.description}</p>
        <div className={styles.flex_div}>
          <div className={styles.flex_div} style={{ alignItems: 'center' }}>
            <Thumbnail
              containerStyle={{
                width: 'max-content',
                display: 'flex',
                alignItems: 'center',
              }}
              className={styles.style_profile_img}
              id={item?.createdBy?.profileImageId}
            />
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <p className={styles.category_p} style={{ marginLeft: 10 }}>
                {item?.createdBy?.nickname}
              </p>
            </div>
          </div>
          <div className={styles.flex_div}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <HeartFilled style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p}>{item?.likeCount}</p>
            </div>
            <div
              style={{
                marginLeft: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CommentIcon style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p}>{item?.commentCount ?? 0}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
const ProposedListIndex = () => {
  const { commonCodes } = useSelector((s) => s.common);
  const { params: upperParam, UpperLine } = useUpperLine();
  const [isOpenEstimateModal, setIsOpenEstimateModal] = React.useState(false);

  const [filter, setFilter] = React.useState('createdAt_DESC');
  const location = useLocation();
  const args = qs.parse(location?.search);
  const initialParmas = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: 'DESC',
      sort: 'createdAt',
      keyword: null,
      ...args
    }),
    [args],
  );
  const [params, setParams] = React.useState(initialParmas);
  const [selectedId, setSelectedId] = React.useState(null);
  const { id,type } = useParams();
  const { data: proposedData,isLoading } = useGetProposedListByConstructionId({ params,id });
  console.log({selectedId})
  const { data: detail,refetch } = useGetConstructionRequestDetail({
    id: selectedId,
    config: {
      enabled: !!selectedId,
    },
  });
  console.log({detail});
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 380);
  React.useEffect(()=>{
    if(selectedId){
    refetch()

    }
  },[selectedId])
  React.useEffect(() => {
    if (upperParam) {
      const { keyword } = upperParam;
      setParams(
        produce((draft) => {
          draft.keyword = keyword;
        }),
      );
    }
  }, [upperParam]);

  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 360);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);
  const filterItem = React.useMemo(
    () => [
      {
        label: '최신순',
        value: 'createdAt_DESC',
      },
      {
        label: '만료순',
        value: 'createdAt_ASC',
      },
      {
        label: '예산 높은순',
        value: 'totalPrice_DESC',
      },
      {
        label: '예산 낮은순',
        value: 'totalPrice_ASC',
      },
      {
        label: '입찰순',
        value: 'proposalCount_DESC',
      },
    ],
    [],
  );

  const columns = [
    // {
    //   title: '상태',
    //   key: 'status',
    //   dataIndex: 'status',
    //   align: 'center',
    //   width: 60,
    //   render: (v) => {
    //     const isNew = v === 'NEW';
    //     return (
    //       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //         {isNew && (
    //           <div
    //             style={{
    //               width: '35px',
    //               height: '18px',
    //               padding: '3px 5px',
    //               borderRadius: '3px',
    //               backgroundColor: '#1e3d83',
    //               display: 'flex',
    //               alignItems: 'center',
    //               justifyContent: 'center',
    //             }}
    //           >
    //             <span
    //               style={{
    //                 fontSize: '10px',
    //                 fontWeight: '900',
    //                 color: '#fff',
    //               }}
    //             >
    //               NEW
    //             </span>
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      title: '썸네일',
      key: 'thumbnailId',
      dataIndex: 'thumbnailId',
      align: 'center',
      width: 60,
      render: (data, record) => {
        return (
          <Thumbnail
            id={record.thumbnailId}

          />
        );
      },
    },
    {
      title: <TableColHeader title={'견적명'} subTitle={'주소'} />,
      key: 'title',
      dataIndex: 'title',
      align: 'left',
      width: 170,
      render: (data, record) => (
        <>
          <TableColHeader title={data} subTitle={record.address} />
        </>
      ),
    },
    {
      title: '제안발송일시',
      key: 'proposalInfo',
      dataIndex: 'proposalInfo',
      align: 'center',
      width: 100,
      render: (data, record) => (
        <>
          <p style={{ color: '#767676', fontSize: 12 }}>{getTimeAgo(data?.createdAt)}</p>
          <p style={{ color: '#767676', fontSize: 12 }}>{moment(data?.createdAt).format('YYYY-MM-DD HH:mm')}</p>
        </>
      ),
    },
    {
      title: '제안첨부자료',
      key: 'proposalInfo',
      dataIndex: 'proposalInfo',
      align: 'center',
      width: 180,
      render: (data, record) => {
        return (
          <>
            <p style={{ color: '#1e3d83', fontSize: 12, fontWeight: 'bold' }}>
              <img
                style={{ width: 14, height: 14 }}
                src={data?.hasDeepDive ? assets.checked : assets.unChecked}
                alt=""
              />
              <span style={{ display: 'inline-block', marginRight: 3, color: !data?.hasDeepDive && '#767676' }}>
                심층제안
              </span>
              <img
                style={{ width: 14, height: 14 }}
                src={data?.isShowPortfolio ? assets.checked : assets.unChecked}
                alt=""
              />
              <span style={{ display: 'inline-block', marginRight: 3, color: !data?.isShowPortfolio && '#767676' }}>
                포트폴리오
              </span>
              <img
                style={{ width: 14, height: 14 }}
                src={data?.hasBluePrints ? assets.checked : assets.unChecked}
                alt=""
              />
              <span style={{ color: !data?.hasBluePrints && '#767676' }}>도면</span>
            </p>
          </>
        );
      },
    },
    {
      title: '고객예산',
      key: 'totalPrice',
      dataIndex: 'totalPrice',
      align: 'center',
      width: 80,
      render: (data, record) => (
        <>
          <p style={{ color: '#1e3d83', fontSize: 14, fontWeight: 'bold' }}>{data ? formatComma(data) : 0}</p>
        </>
      ),
    },
    {
      title: '고객확인',
      key: 'proposalInfo',
      dataIndex: 'proposalInfo',
      align: 'center',
      width: 100,
      render: (data, record) => {
        return (
          <>
            <p style={{ color: data.readAt ? '#1e3d83' : '#767676', fontSize: 12 }}>
              {data.readAt ? '확인완료' : '확인전'}
            </p>
            <p style={{ color: data.readAt ? '#1e3d83' : '#767676', fontSize: 12 }}>
              {data.readAt ? moment(data.readAt).format('YYYY-MM-DD HH:mm') : '____-__-__'}
            </p>
          </>
        );
      },
    },
  ];
  const onRow = (record) => {
    return {
      onClick: () => {
        setSelectedId(record.id);
      },
    };
  };

  return (
    <>
      <div>
        <div>
          <PageHeader className="site-page-header" title={args?.isSelected?'찜한 제안서 리스트':
              '제안서 리스트'} style={{ paddingBottom: 10 }} />
          <ExtraWrapper>
            {/* 필터 윗줄 */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 4.5,
              }}
            >
              <UpperLine
                listKey={'proposal'}
                detailKey={CONSTRUCTION_REQUEST_KEY}
                // setRefresh={setRefreshDetail}
              />
            </div>
            {/* 필터 아랫줄 */}
            <div
              style={{
                width: '65%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <FilterSelect
                style={{ width: 100 }}
                value={filter}
                onChange={(v) => {
                  const [sort, order] = v.split('_');
                  setParams(
                    produce((draft) => {
                      draft.sort = sort;
                      draft.order = order;
                    }),
                  );
                  setFilter(v);
                }}
              >
                {filterItem?.map((v) => (
                  <Option value={v.value}>{v.label}</Option>
                ))}
              </FilterSelect>
            </div>
          </ExtraWrapper>
        </div>

        <div className={styles.estimate_table} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PaginateTable
              isLoading={isLoading}
            dataSource={proposedData?.content}
            columns={columns}
            scroll={{ y: tableHeight - 40 }}
            data={proposedData}
            page={params?.page}
            setPage={(v) =>
              setParams(
                produce((draft) => {
                  draft.page = v;
                }),
              )
            }
            pageSize={params?.limit}
            setPageSize={(v) =>
              setParams(
                produce((draft) => {
                  draft.limit = v;
                }),
              )
            }
            onRow={onRow}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '65%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
            }}
          />
          {detail && (
            <div
              style={{
                width: 500,
                overflowY: 'scroll',
                overflowX: 'hidden',
                height: document.body.clientHeight - 240,
                paddingLeft: 20,
                boxSizing: 'border-box',
                paddingBottom: 15,
              }}
            >
              <SmallUserDetail isFirst data={detail} tree={commonCodes} user={detail?.estimateInfo?.createdBy} />
              <LocationInfo data={detail?.estimateInfo} />
              <div style={{ marginTop: 30 }}></div>
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  marginBottom: '10px',
                }}
              >
                선택 스타일 정보
              </span>
              <ContentSlider leftLine={true}>
                {detail?.estimateInfo?.areas?.map((v) => (
                  <ContentCard setVisibleDetailModal={() => {}} item={v?.subContent} commonCodes={commonCodes} />
                ))}
              </ContentSlider>
              <SmallEstimate visibleModal={() => setIsOpenEstimateModal(true)} data={detail?.estimateInfo} />
            </div>
          )}
        </div>
      </div>
      <ShowEstimateModal
        visible={isOpenEstimateModal}
        setVisible={setIsOpenEstimateModal}
        data={detail}
        handleCancel={() => setIsOpenEstimateModal(false)}
      />
    </>
  );
};

export default ProposedListIndex;
