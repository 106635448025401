import { Checkbox, Modal } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
// import ModifyMaterialModal from './ModifyMaterialModal';
import produce from 'immer';
import { useGetConstructionTemplates } from '../../apis/queries';
import RoundButton from '../button/RoundButton';
import PaginateTable from '../table/PaginateTable';
import ShowMaterialModal from './ShowMaterialModal';
const styles = {
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 25px',
  },
  header: {
    height: '50px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px 20px 0px 20px',
  },
  tabWrapper: {
    width: '100%',
    overFlow: 'scroll',
    display: 'flex',
    gap: '20px',
    marginTop: '25px',
  },
};
const ShowEstimateModal = React.memo(({ visible, setVisible, handleOk, handleCancel, data }) => {
  const { commonCodes } = useSelector((s) => s.common);
  const [selectedTab, setSelectedTab] = React.useState(null);
  const [selectedArea, setSelectedArea] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState(null);
  const [visibleMaterialModal, setVisibleMaterialModal] = React.useState(false);
  const { data: templatesFull } = useGetConstructionTemplates({
    enabled: !!visible && !!data,
  });
  const tabs = React.useMemo(() => {
    if (!templatesFull) return [];
    return templatesFull?.find((v) => v.area.code === selectedArea)?.category?.map((v) => v);
  }, [templatesFull, selectedArea]);

  const areas = React.useMemo(() => {
    if (!data) return null;
    const areas = data?.areas?.map((v) => ({
      area: v.area,
      estimateResponses: v.estimateResponses,
    }));
    setSelectedArea(areas?.[0]?.area);
    return areas;
  }, [data]);
  React.useEffect(() => {
    if (selectedArea) {
      setSelectedTab(tabs[0]?.categoryId);
    }
  }, [selectedArea]);

  React.useEffect(() => {
    if (tabs) {
      setSelectedTab(tabs[0]?.categoryId);
    }
  }, [tabs]);

  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 380);
  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 360);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const dataSource = React.useMemo(() => {
    const tab = tabs?.find((v) => v.categoryId === selectedTab);
    return tab?.children?.flatMap((v) =>
      v.templates?.flatMap((v2) => ({
        title: v.categoryName,
        details: v2.name,
        buttonOptions: { id: v2.id, productSelectable: v2.isProductSelectable },
      })),
    );
  }, [tabs, selectedTab]);

  const columns = [
    {
      title: '공종',
      dataIndex: 'title',
      key: 'title',
      width: 40,
      align: 'center',
      render: (v) => (
        <span
          style={{
            fontSize: '12px',
            color: '#767676',
          }}
        >
          {v}
        </span>
      ),
    },
    {
      title: '세부항목',
      dataIndex: 'details',
      key: 'details',
      width: 200,
      align: 'left',
      render: (v) => (
        <span
          style={{
            fontSize: '14px',
            color: '#000',
            fontWeight: '600',
          }}
        >
          {v}
        </span>
      ),
    },
    {
      title: commonCodes?.map[selectedArea],
      dataIndex: 'buttonOptions',
      key: 'buttonOptions',
      width: 40,
      align: 'left',
      render: ({ id, productSelectable }) => {
        const target = areas.find((v) => v.area === selectedArea);
        const isActive = !!target.estimateResponses.find((v) => v.templateId === id);

        return (
          <>
            {!productSelectable ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Checkbox disabled checked={isActive} />
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <RoundButton
                  disabled={!isActive}
                  reverse={isActive}
                  onClick={() => {
                    if (isActive) {
                      setVisibleMaterialModal(true);
                      setSelectedId(id);
                    }
                  }}
                  label={'자재보기'}
                />
              </div>
            )}
          </>
        );
      },
    },
  ];

  return (
    <Modal
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 200,
        overflowY: 'hidden',
        backgroundColor: '#f9f9f9',
        padding: '0px',
      }}
      closable={false}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      okText={'확인'}
      footer={null}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div style={styles.modalContainer}>
        <div style={styles.header}>
          <RoundButton
            onClick={handleCancel}
            label="확인"
            containerStyle={{
              width: '100px',
            }}
          />
        </div>
        <div style={styles.bodyWrapper}>
          <h3 style={styles.title}>자재보기</h3>
          <div style={styles.tabWrapper}>
            {areas?.map((v) => (
              <span
                onClick={() => {
                  setSelectedArea(v.area);
                }}
                style={{
                  cursor: 'pointer',
                  fontSize: '14px',
                  color: selectedArea === v.area ? '#1e3d83' : '#767676',
                  fontWeight: selectedArea === v.area ? '600' : 'normal',
                }}
              >
                {commonCodes?.map[v.area]}
              </span>
            ))}
          </div>
          <div style={{ ...styles.tabWrapper, marginTop: '10px' }}>
            {tabs?.map((v) => (
              <span
                onClick={() => {
                  setSelectedTab(v.categoryId);
                }}
                style={{
                  cursor: 'pointer',
                  fontSize: '14px',
                  color: selectedTab === v.categoryId ? '#1e3d83' : '#767676',
                  fontWeight: selectedTab === v.categoryId ? '600' : 'normal',
                }}
              >
                {v.categoryName}
              </span>
            ))}
          </div>
          <PaginateTable
            dataSource={dataSource}
            data={dataSource}
            columns={columns}
            scroll={{ y: tableHeight - 100 }}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
              marginTop: '30px',
            }}
            pagination={false}
          />
        </div>
      </div>
      <ShowMaterialModal
        visible={visibleMaterialModal}
        setVisible={setVisibleMaterialModal}
        handleOk={() => {}}
        handleCancel={() => setVisibleMaterialModal(false)}
        selectedId={selectedId}
        data={data}
      />
    </Modal>
  );
});

export default ShowEstimateModal;
