import { Modal } from 'antd';
import React from 'react';
import { FilterInput } from '../components/template/page';
import PaginateTable from '../components/table/PaginateTable';
import { dummyData } from '../data/dummy';
import UserDetail from '../components/card/userdetail/UserDetail';
import goodsImage from '../assets/dummy/03/03-04.jpg';
import ItemDetail from '../components/card/itemDetail/ItemDetail';

const useUserGoodsModal = () => {
  const goodsList = [
    { category: '전기조명>조명>방등', title: 'LED 방등 50W 60W 삼...', partnerName: '포유라이팅', price: '15,840' },
    { category: '전기조명>조명>방등', title: 'LED 방등 50W 60W 삼...', partnerName: '포유라이팅', price: '15,840' },
    { category: '전기조명>조명>방등', title: 'LED 방등 50W 60W 삼...', partnerName: '포유라이팅', price: '15,840' },
    { category: '전기조명>조명>방등', title: 'LED 방등 50W 60W 삼...', partnerName: '포유라이팅', price: '15,840' },
    { category: '전기조명>조명>방등', title: 'LED 방등 50W 60W 삼...', partnerName: '포유라이팅', price: '15,840' },
    { category: '전기조명>조명>방등', title: 'LED 방등 50W 60W 삼...', partnerName: '포유라이팅', price: '15,840' },
    { category: '전기조명>조명>방등', title: 'LED 방등 50W 60W 삼...', partnerName: '포유라이팅', price: '15,840' },
    { category: '전기조명>조명>방등', title: 'LED 방등 50W 60W 삼...', partnerName: '포유라이팅', price: '15,840' },
    {},
    {},
  ];
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const UserGoodsModal = ({ userType, agency }) => {
    return (
      <Modal
        width={1320}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 300,
          overflowY: 'hidden',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={true}
        okText={'확인'}
        cancelText={'수정'}
      >
        <div style={{ padding: '35px 45px', height: '100%' }}>
          <p style={{ fontSize: 24, fontWeight: 600, marginBottom: 50 }}>찜한 상품</p>
          <div style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
            <div style={{ width: '65%', minWidth: 760, height: '100%' }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  overflowY: 'scroll',
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  {goodsList?.map((item, index) => {
                    return (
                      <div key={`goodsList ${index}`} style={{ width: '18%', margin: '25px 0' }}>
                        {item?.title && (
                          <div>
                            <img
                              src={goodsImage}
                              alt=""
                              style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: 130,
                                height: 130,
                                borderRadius: 10,
                              }}
                            />
                            <div style={{ padding: 5 }}>
                              <p
                                style={{
                                  marginTop: 10,
                                  fontSize: 12,
                                  color: '#767676',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {item?.category}
                              </p>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {item?.title}
                              </p>
                              <p
                                style={{
                                  marginBottom: 10,
                                  fontSize: 12,
                                  color: '#767676',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {item?.partnerName}
                              </p>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span style={{ fontSize: 10, color: '#767676' }}>소비자가</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <p style={{ fontSize: 18, color: '#1e3d83', fontWeight: 'bold' }}>{item?.price}</p>
                                  <span style={{ fontSize: 10, color: '#767676' }}>원/EA</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div style={{  minwidth: 550, height: '100%', overflowY: 'auto', paddingBottom: 50 }}>
              <ItemDetail canModify={false} />
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  return { showModal, setIsModalOpen, UserGoodsModal };
};

export default useUserGoodsModal;
