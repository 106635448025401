import { CloseCircleFilled, HeartFilled, UserOutlined, WechatFilled } from '@ant-design/icons';
import { Modal, PageHeader, Pagination } from 'antd';
import { Option } from 'antd/lib/mentions';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import Slider from 'react-slick';
import { restApi } from '../../apis';
import RoundButton from '../../components/button/RoundButton';
import styles from './Customer.module.less';
import Comment from '../../components/comment/Comment';
import {
  useGetComment,
  useGetContentDetail,
  useGetGuideDetail,
  useGetMyComment,
  useGetMyPostDetail,
  useGetPostDetail,
  useGetPosts,
  useGetPostsAll,
} from '../../apis/queries';
import { COMMENT_KEY, MY_POST_DETAIL_KEY, MY_POST_KEY, USER_COMMENT_LIST_KEY } from '../../apis/queryKeys';
import { parseImageUri } from '../../common/utils';
import LowerLine from '../../components/LowerLine';
import ContentDetailModal from '../../components/modal/ContentDetailModal';

import { ExtraWrapper, FilterSelect } from '../../components/template/page';
import usePostDetailModal from '../../hooks/usePostDetailModal';
import useUpperLine from '../../hooks/useUpperLine';
import { useSelector } from 'react-redux';
import CommentIcon from '../../components/image/CommentIcon';
import assets from '../../assets';
import Thumbnail from '../../components/image/Thumbnail';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const ServiceModal = ({ onCancel, id }) => {
  const [imageError, setImageError] = React.useState(false); // 이미지 에러시 true
  const [commentCount, setCommentCount] = React.useState(0);
  const { data: serviceDetailData } = useGetGuideDetail({ id: id, params: {} });
  console.log(serviceDetailData);
  return (
    <Modal
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 200,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
        padding: '50px 50px',
      }}
      open={!!serviceDetailData}
      onCancel={() => {
        onCancel?.();
      }}
      destroyOnClose={true}
      maskClosable={true}
      okText={'확인'}
      cancelText={'수정'}
      closable={false}
      footer={null}
      title={
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <RoundButton
            onClick={() => onCancel?.()}
            label="나가기"
            containerStyle={{
              width: '100px',
              marginRight: '10px',
            }}
          />
        </div>
      }
    >
      <div
        style={{
          width: '100%',
          paddingInline: 10,
          overflowY: 'scroll',
          overflowX: 'hidden',
          height: document.body.clientHeight - 240,
        }}
      >
        {serviceDetailData?.thumbnailId && (
          <div className={styles.detailWrapper}>
            {imageError ? (
              <img style={{ width: '100%', borderRadius: 10, maxHeight: 400 }} src={assets.alter} alt="" />
            ) : (
              <img
                onError={(e) => setImageError(true)}
                style={{ width: '100%', borderRadius: 10, maxHeight: 400 }}
                src={parseImageUri(serviceDetailData?.thumbnailId)}
                alt=""
              />
            )}
          </div>
        )}
        <div className={styles.contentInfo}>
          <div className={styles.titleWrapper}>
            <div>
              <h3> {serviceDetailData?.title}</h3>
              <div className={styles.profileWrapper}>
                <Thumbnail
                  containerStyle={{
                    display: 'flex',
                    alignItems: 'center',
                    marginInline: 0,
                    width: 'min-content',
                  }}
                  imageStyle={{ borderRadius: '50%', overflow: 'hidden', width: 36, height: 36 }}
                  id={serviceDetailData?.createdBy?.profileImageId}
                />
                <div className={styles.nicknameWrapper}>
                  <span style={{ fontWeight: 'bold' }}>{serviceDetailData?.createdBy?.nickname}</span>
                  <span style={{ color: 'rgb(153, 153, 153)' }}>
                    {moment(serviceDetailData?.createdAt).format('YYYY.MM.DD')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.commentIconWrapper}>
            <CommentIcon style={{ fontSize: 18, color: '#e8e8e8' }} />
            <p className={styles.style_button_count_p}>{commentCount}</p>
          </div>
        </div>
        <p className={styles.description}>{serviceDetailData?.description}</p>
        {serviceDetailData?.subCreates?.map((subContent) => (
          <div style={{ marginBlock: 40 }}>
            <div className={styles.detailWrapper}>
              {imageError ? (
                <img style={{ width: '100%', borderRadius: 10, maxHeight: 400 }} src={assets.alter} alt="" />
              ) : (
                <img
                  onError={(e) => setImageError(true)}
                  style={{ width: '100%', borderRadius: 10, maxHeight: 400 }}
                  src={parseImageUri(subContent?.thumbnailId)}
                  alt=""
                />
              )}
            </div>
            <div className={styles.titleWrapper}>
              <div>
                <h3> {serviceDetailData?.title}</h3>
              </div>
              <p className={styles.description}>{subContent?.description}</p>
            </div>
          </div>
        ))}
        <div>
          <Comment setCommentCount={setCommentCount} id={id} type={'CONTENT'} />
        </div>
      </div>
    </Modal>
  );
};
function PostModal({ selectedId, onCancel, type }) {
  const { data: detailData } = useGetMyPostDetail({
    id: selectedId,
    params: { type: type },
    config: { enabled: !!selectedId },
  });
  const settings = {
    className: 'center',
    infinite: false,
    centerPadding: '60px',
    slidesToShow: 5,
    swipeToSlide: true,
    afterChange: function (index) {
      console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
    },
  };

  return (
    <Modal
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 200,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
      }}
      closable={false}
      open={!!detailData}
      // open={isModalOpen}
      // onOk={handleOk}
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
      title={
        <div className={styles.header}>
          <RoundButton
            onClick={() => {
              onCancel?.();
            }}
            label="나가기"
            containerStyle={{
              width: '100px',
              marginRight: '10px',
            }}
          />
        </div>
      }
      footer={null}
      // title={}
      cancelText={'확인'}
    >
      <div style={{ width: '100%', backgroundColor: 'black' }}>
        <img
          style={{
            display: 'block',
            height: '450px',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: 'black',
          }}
          src={detailData?.images?.length > 0 && parseImageUri(detailData?.images[0])}
          alt=""
        />
      </div>
      <div style={{ padding: '50px 100px' }}>
        {detailData?.images.length > 1 && (
          <Slider {...settings}>
            {[...detailData?.images].length > 1 &&
              [...detailData?.images].slice(1).map((item, index) => {
                return (
                  <div>
                    <img style={{ width: 108, height: 108, borderRadius: 10 }} src={parseImageUri(item)} alt="" />
                  </div>
                );
              })}
          </Slider>
        )}
        <p style={{ margin: '10px 0', fontWeight: 600, fontSize: 17 }}>{detailData?.title}</p>
        <div className={styles.flex_div}>
          <div className={styles.flex_div} style={{ alignItems: 'center' }}>
            {detailData?.createdBy?.profileImageId ? (
              <img
                className={styles.style_profile_img}
                src={detailData?.createdBy?.profileImageId}
                alt="프로필 이미지"
              />
            ) : (
              <div
                className={styles.style_profile_img}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#e8e8e8',
                }}
              >
                <UserOutlined style={{ color: 'white', fontSize: 30 }} />
              </div>
            )}
            <div>
              <p className={styles.category_p} style={{ marginLeft: 10, color: 'black', fontWeight: 'bold' }}>
                {detailData?.createdBy?.nickname}
              </p>
              <p className={styles.category_p} style={{ marginLeft: 10, color: '#787878' }}>
                {detailData?.createdAt}
              </p>
            </div>
          </div>
          <div className={styles.flex_div}>
            <div>
              <HeartFilled style={{ fontSize: 20, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p} style={{ color: '#999999', textAlign: 'center' }}>
                {detailData?.likeInfo?.likeCount}
              </p>
            </div>
            <div style={{ marginLeft: 5 }}>
              <CommentIcon style={{ fontSize: 20, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p} style={{ color: '#999999', textAlign: 'center' }}>
                {detailData?.commentCount}
              </p>
            </div>
          </div>
        </div>
        <p style={{ margin: '25px 0 50px', fontSize: 14, color: '#767676' }}>{detailData?.content}</p>
      </div>
      <div style={{ padding: '50px 100px' }}>
        <Comment id={selectedId} type={'POST'} />
      </div>
    </Modal>
  );
}
const PostDetailModal = ({ id, type, onCancel }) => {
  const queryClient = useQueryClient();
  const postType =
    type === 'STYLE'
      ? 'styles'
      : type === 'PORTFOLIO'
      ? 'portfolios'
      : type === 'TREND'
      ? 'trends'
      : 'construction-guides';
  const { data } = useGetPostsAll({ type: postType, id });
  const { commonCodes } = useSelector((state) => state.common);

  return (
    <Modal
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 200,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
        padding: 0,
      }}
      title={
        <div className={styles.header}>
          <RoundButton
            onClick={() => {
              onCancel?.();
            }}
            label="나가기"
            containerStyle={{
              width: '100px',
              marginRight: '10px',
            }}
          />
        </div>
      }
      closable={false}
      open={!!data}
      onOk={onCancel}
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
      footer={null}
      okText={'확인'}
      cancelText={'수정'}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <img
        style={{ width: '100%', objectFit: 'cover', height: '450px', marginLeft: 'auto' }}
        src={parseImageUri(data?.thumbnailId)}
        alt=""
      />
      <div style={{ padding: '50px 100px' }}>
        <p
          style={{
            fontSize: '12px',
            color: '#767676',
          }}
        >
          {commonCodes?.map[data?.tag]}
        </p>
        <p
          style={{
            fontSize: '16px',
            fontWeight: '600',
            color: '#000',
          }}
        >
          {data?.title}
        </p>
        <div className={styles.flex_div}>
          <div className={styles.flex_div} style={{ alignItems: 'center' }}>
            <img
              className={styles.style_profile_img}
              src={parseImageUri(data?.createdBy?.profileImageId)}
              alt="프로필 이미지"
            />
            <p className={styles.category_p} style={{ marginLeft: 10, color: 'black' }}>
              {data?.createdBy?.nickname}
            </p>
          </div>
          <div className={styles.flex_div}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <HeartFilled style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p} style={{ color: '#999999' }}>
                {data?.likeInfo?.likeCount}
              </p>
            </div>
            <div style={{ marginLeft: 5, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <CommentIcon style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p} style={{ color: '#999999' }}>
                {data?.shareCount}
              </p>
            </div>
          </div>
        </div>
        <p style={{ margin: '25px 0 50px', fontSize: 14, color: '#767676' }}>{data?.description}</p>
        {data?.subContents?.map((v) => (
          <div className={styles.style_section_div}>
            <img className={styles.style_section_main_img} src={parseImageUri(v?.thumbnailId)} alt="" />
            <p className={styles.title_p}>{v?.title}</p>
            <p className={styles.style_section_desc_p}>{v?.description}</p>
          </div>
        ))}
      </div>
      <div style={{ padding: '50px 100px' }}>
        <Comment id={id} type={'CONTENT'} />
      </div>
    </Modal>
  );
};

const CommentIndex = () => {
  const param = useParams();
  const [refreshDetail, setRefreshDetail] = React.useState();
  console.log(refreshDetail);
  const queryClient = useQueryClient();

  //필터 윗줄 생성(keword, filter params 포함, query param 에 spread 구문으로 삽입)
  // const filters = React.useMemo(() => {
  //   return [
  //     {
  //       title: '기준정렬',
  //       key: 'sort',
  //       options: [undefined, ['createdAt', '시간순'], ['viewCount', '조회순'], ['commentCount', '댓글순']],
  //     },
  //   ];
  // }, []);
  const { params: upperParam, UpperLine } = useUpperLine();

  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  const [order, setOrder] = React.useState('DESC');

  // 모두 선택 버튼로직
  const [checkedList, setCheckedList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const { data: commentData } = useGetComment({
    id: param?.id,
    type: param?.type,
    params: params,
  });
  const allData = React.useMemo(() => {
    return commentData?.content?.map((v) => ({
      data: v,
      id: v?.id,
    }));
  }, [commentData]);
  React.useEffect(() => {
    if (!!allData) {
      const idList_ = allData?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [allData]);

  const handleDetail = (comment) => {
    console.log(comment);
    if (comment?.content) {
      return { isContent: 'content', type: comment?.content?.type, id: comment?.content?.id };
    } else {
      return { type: comment?.post?.type, id: comment?.post?.id };
    }
  };

  const settings = {
    className: 'center',
    infinite: false,
    centerPadding: '60px',
    slidesToShow: 5,
    swipeToSlide: true,
    afterChange: function (index) {
      console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
    },
  };

  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 320);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  return (
    <>
      <div>
        <PageHeader className="site-page-header" title="댓글 관리" style={{ paddingBottom: 10 }} />
        <ExtraWrapper>
          {/* 필터 윗줄 */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 4.5,
              marginRight: 20,
            }}
          ></div>
          {/* 필터 아랫줄 */}
          <div style={{ width: 870, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}></div>
        </ExtraWrapper>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ width: '100%', padding: '5px 25px', position: 'relative' }}>
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 20,
                boxShadow: '0 10px 30px 0 rgba(0, 0, 0, 0.08)',
                overflow: 'hidden',
                paddingBottom: 30,
                width: '100%',
              }}
            >
              <div
                style={{
                  width: '100%',
                  maxHeight: '80vh',
                  overflow: 'scroll',
                  backgroundColor: 'white',
                  padding: '20px 20px',
                }}
              >
                {commentData?.content?.map((item, index) => {
                  const post = item?.post ?? item?.content;
                  return (
                    <div style={{ width: '100%' }} key={`commentList ${index}`}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: 20,
                          marginTop: 30,
                        }}
                      >
                        <span style={{ fontSize: 12, color: '#767676' }}>{item?.createdAt}</span>
                        {/* <CloseCircleFilled
                          onClick={() => {
                            Modal.confirm({
                              content: '댓글을 삭제하시겠습니까?',
                              okText: '삭제',
                              cancelText: '취소',
                              onOk: () => {
                                restApi.delete(`/comments/${item?.id}`).then(async (res) => {
                                  await queryClient.invalidateQueries(COMMENT_KEY);
                                  Modal.success({
                                    content: '삭제되었습니다.',
                                    okText: '확인',
                                  });
                                });
                              },
                            });
                          }}
                          style={{ fontSize: 20, color: '#1e3d83' }}
                        /> */}
                      </div>
                      <div style={{ fontSize: 14, color: '#767676' }}>{item?.text}</div>
                      {/* <div>
                        <span
                          onClick={() => setRefreshDetail(handleDetail(item))}
                          style={{ cursor: 'pointer', fontSize: 12, color: '#767676' }}
                        >
                          본문보기
                        </span>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{
                              width: 50,
                              height: 50,
                              backgroundColor: '#efefef',
                              borderRadius: 5,
                              marginRight: 10,
                              backgroundImage: `url(${parseImageUri(post?.thumbnailId)})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                            }}
                          />
                          <div>
                            <p style={{ fontSize: 14, fontWeight: 'bold' }}>{post?.title}</p>
                          </div>
                        </div>
                      </div> */}
                      <hr />
                    </div>
                  );
                })}
              </div>
              <div style={{ backgroundColor: 'white', width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  // style={{backgroundColor:'white'}}
                  showSizeChanger
                  onChange={(page, pageSize) => {
                    setParams(
                      produce((draft) => {
                        draft.page = page - 1;
                      }),
                    );
                    queryClient.invalidateQueries(COMMENT_KEY);
                  }}
                  defaultCurrent={1}
                  current={params?.page + 1}
                  pageSize={params?.limit}
                  onShowSizeChange={(current, size) => {
                    setParams(
                      produce((draft) => {
                        draft.limit = size;
                      }),
                    );
                    queryClient.invalidateQueries(COMMENT_KEY);
                  }}
                  total={commentData?.totalElements}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {refreshDetail?.isContent ? (
        ['STYLE', 'PORTFOLIO'].includes(refreshDetail?.type) ? (
          <ContentDetailModal
            type={refreshDetail?.type === 'STYLE' ? 'styles' : 'portfolios'}
            contentId={refreshDetail?.id}
            visible={!!refreshDetail}
            setVisible={setRefreshDetail}
            handleOk={() => {}}
            handleCancel={() => setRefreshDetail()}
          />
        ) : ['TREND', 'CONSTRUCTION_GUIDE'].includes(refreshDetail?.type) ? (
          <PostDetailModal id={refreshDetail?.id} type={refreshDetail?.type} onCancel={() => setRefreshDetail()} />
        ) : (
          <ServiceModal id={refreshDetail?.id} onCancel={() => setRefreshDetail()} />
        )
      ) : (
        ['ASK_ME', 'EXPERT_TALK'].includes(refreshDetail?.type) && (
          <PostModal
            selectedId={refreshDetail?.id}
            onCancel={() => setRefreshDetail()}
            type={refreshDetail?.type}
          ></PostModal>
        )
      )}
    </>
  );
};

export default CommentIndex;
