import {Table} from 'antd';
import produce from 'immer';
import React from 'react';
import styles from './Table.module.less';

const PaginateTable = ({
                           selectedRowKeys,
                           isLoading,
                           setSelectedRowKeys,
                           dataSource,
                           columns,
                           scroll,
                           page,
                           data,
                           setParams,
                           params,
                           setPageSize,
                           setPage,
                           rowSelection,
                           onRow,
                           pageSize,
                           style,
                           customHeight,
                           pagination = true,
                       }) => {
    const customStyle = customHeight
        ? {...style, height: document.body.clientHeight - 450}
        : {...style, height: document.body.clientHeight - 240};

    const [selectedRow, setSelectedRow] = React.useState([]);
    return (
        <Table
            loading={isLoading}
            className="ant-table-pointer"
            expandable={{
                expandIcon: () => {
                },
            }}

            dataSource={dataSource}
            columns={columns}
            rowClassName={(record, index) => {

                if (selectedRow?.find(v => v.id === record.id)) {
                    return styles.selectedRow;
                }
                return '';
            }}
            scroll={scroll}
            pagination={
                pagination && {
                    itemRender: (cur, type) => {
                        switch (type) {
                            case 'page':
                                return <a style={{
                                    // width: '22px',
                                    // height: '22px',
                                    border: 'none',
                                    color: cur !== page + 1 ? '#767676' : 'white',
                                    borderRadius: 3,
                                    backgroundColor: cur !== page + 1 ? '#efefef' : '#1e3d83',
                                }
                                }>{cur}</a>;
                            case 'prev':
                                return <a style={{fontSize: 12, color: '#767676'}}>
                                    <img style={{width: 25, height: 25, marginRight: 5}}
                                         src={require('../../assets/prev.png')} alt={'1'}/>이전 페이지</a>;
                            case 'next':
                                return <a style={{fontSize: 12, color: '#767676'}}>다음 페이지 <img
                                    style={{width: 25, height: 25, marginLeft: 5, 'font-family': 'Pretendard'}}
                                    src={require('../../assets/next.png')} alt={'1'}/></a>;
                            case 'jump-prev':
                            case 'jump-next':
                                return <a>...</a>;
                            // default:
                            // return <a>{page}</a>;
                        }
                    },
                    current: page ? page + 1 : 1,
                    total: data?.totalElements,
                    pageSize: pageSize,
                    pageSizeOptions: data?.totalElements >= 500 ? ['10', '20', '30', '50', '100'] : ['10', '20', '30', '50'],
                    onShowSizeChange: (current, size) => setPageSize(size),
                    showSizeChanger: true,
                    position: ['bottomCenter']
                }
            }
            onChange={(pagination, filters, sorter) => {
                console.log(pagination.current);
                setPage(pagination.current - 1);
                if (sorter) {
                    console.log({sorter});
                    setParams?.(produce((draft) => {
                            draft.order = sorter.order === 'ascend' ? 'ASC' : 'DESC';
                            draft.sort = sorter.field;
                        }),
                    );
                }

            }}
            onRow={(d, i) => {
                return {

                    onClick: () => {
                        if (!onRow) return;
                        onRow(d, i)?.onClick?.();
                        console.log({d})
                        setSelectedRow([d]);
                    },
                };
            }}
            style={customStyle}
            rowSelection={selectedRowKeys && rowSelection}
            rowKey={({id}) => id}
        />
    );
};

export default PaginateTable;
