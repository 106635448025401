import React, { useEffect, useMemo, useState } from 'react';
import { Button, DatePicker, PageHeader, Table, Select, Input, Checkbox, Modal, Switch } from 'antd';
import { restApi } from '../../../apis';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import assets from '../../../assets';
import {
  formatComma,
  getClientWidth,
  getTimeAgo,
  handleAccountShowAndStop,
  handleConfirmAndNot,
  handleModifyConfirm,
  handleModifyWriteAccount, parseImageUri,
} from '../../../common/utils';
import PaginateTable from '../../../components/table/PaginateTable';
import UserDetail from '../../../components/card/userdetail/UserDetail';
import CommonStatistics from '../../../components/card/CommonStatistics,js/CommonStatistics';
import useUserConstructionModal from '../../../hooks/useUserConstructionModal';
import useUserFollowModal from '../../../hooks/useUserFollowModal';
import useUserGoodsModal from '../../../hooks/useUserGoodsModal';
import useRegionsModal from '../../../hooks/useRegionsModal';
import { ExtraWrapper, FilterButton, FilterInput, FilterSelect } from '../../../components/template/page';
import useBusinessNumberModal from '../../../hooks/useBusinessNumberModal';
import useInsuranceModal from '../../../hooks/useInsuranceModal';
import { useGetAccountAdmin } from '../../../apis/queries';
import { useSelector } from 'react-redux';
import moment from 'moment';
import TableColHeader from '../../../components/table/TableColHeader';
import { QueryClient, useQueryClient } from 'react-query';
import { ADMIN_LIST_DETAIL_KEY, ADMIN_LIST_KEY } from '../../../apis/queryKeys';
import { CommonCodeContext } from '../../../context/CommonCodeContext';
import ModifyAllButton from '../../../components/button/ModifyAllButton';
import PartnerUserDetail from '../../../components/card/userdetail/PartnerUserDetail';
import useUpperLine from '../../../hooks/useUpperLine';
import LowerLine from '../../../components/LowerLine';
import { Option } from 'antd/lib/mentions';
import styles from '../../accounts/users/index.module.less';
import AdminUserDetail from './AdminUserDetail';
import produce from 'immer';
import {CloseOutlined, EyeOutlined} from '@ant-design/icons';

const ManageEmployeeIndex = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { codeMapPTAT } = React.useContext(CommonCodeContext);

  //필터 윗줄 생성(keword, filter params 포함, query param 에 spread 구문으로 삽입)
  const filters = React.useMemo(() => {
    const deleted = [
      // [null, '전체'],
      [false, '관리 회원 리스트'],
      [true, '삭제 회원 리스트'],
    ];
    return [
      {
        title: '리스트 구분',
        key: 'isDeleted',
        defaultValue:false,
        options: deleted,
      },];
  }, []);
  const { params: upperParam, UpperLine } = useUpperLine(filters);

  //테이블 페이지네이션 로직
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: 'DESC',
      isDeleted:  false,
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  React.useEffect(() => {
    if (upperParam) {
      setParams((prev) => ({ ...prev, ...upperParam }));
    }
  }, [upperParam]);

  const [data, setData] = React.useState();
  const navigate = useNavigate();

  //썸네일 삭제버튼 로직
  const handleDeleteItems = React.useCallback(
    (ids) => {
      Modal.confirm({
        content: '삭제하시겠습니까?',
        okText: '확인',
        cancelText: '취소',
        onOk: () => {
          restApi
            .delete('/accounts/admin', {
              data: { ids },
            })
            .then((res) => {
              queryClient.invalidateQueries(ADMIN_LIST_KEY);
              Modal.success({
                content: '삭제되었습니다.',
                okText: '확인',
              });
            });
        },
      });
    },
    [data],
  );

  // 모두 선택 버튼로직
  const [checkedList, setCheckedList] = React.useState([]);
  console.log(checkedList);
  const [idList, setIdList] = React.useState([]);
  const { data: adminData,refetch } = useGetAccountAdmin({
    params,
  });
  const allData = React.useMemo(() => {
    return adminData?.content?.map((v) => ({
      data: v,
      id: v?.id,
      isMaster: v?.isMaster,
    }));
  }, [adminData]);
  React.useEffect(() => {
    if (!!allData) {
      const idList_ = allData
        ?.filter((el) => {
          return !el.isMaster;
        })
        .map((v) => v.id);
      setIdList(idList_);
    }
  }, [allData]);

  //상세 화면
  const [refreshDetail, setRefreshDetail] = React.useState();
  React.useEffect(() => {
    if (!refreshDetail) {
      setRefreshDetail(adminData?.content[0]?.id);
    }
  }, [adminData]);

  // 회원 추가 모달
  const [modalOpen, setModalOpen] = React.useState(false);
  const [addMember, setAddMember] = React.useState({
    signname: null,
    password: null,
    name: null,
    nickname: null,
    email: null,
    phone: null,
  });

  //비밀번호 보이게
  const [visiblePassWord, setVisiblePassWord] = React.useState(false);

  //테이블 col
  const COLUMNS = [
    {
      title: '선택',
      dataIndex: 'data',
      key: 'data',
      width: 40,
      align: 'center',
      render: (data, record) => {
        return (
          <>
            {!record?.isMaster && (
              <Checkbox
                key={`${record.id}_check`}
                checked={checkedList.includes(record.id)}
                onClick={() => {
                  if (checkedList.includes(record.id)) {
                    setCheckedList(checkedList.filter((v) => v !== record.id));
                  } else {
                    setCheckedList([...checkedList, record.id]);
                  }
                }}
              />
            )}
          </>
        );
      },
    },
    {
      title: '프로필',
      dataIndex: 'profileImageId',
      key: 'profileImageId',
      width: 60,
      align: 'center',
      render: (data,r) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div
                  className={styles.profileWrapper}
                  style={{
                    position: 'relative',
                    backgroundImage: !data
                        ? assets.icons.blank_profile
                        : `url(${parseImageUri(data)})`,
                  }}
              >
                {!r?.isMaster && (
                <div onClick={()=>{handleDelete(r.id)}} className={styles.deletebuttonwrapper}>
                  <CloseOutlined
                      style={{
                        color: '#FFF',
                        fontSize: '13px',
                      }}
                  />
                </div>
                )}
              </div>
            </div>
        );
      },
    },
    {
      title: '닉네임',
      key: 'nickname',
      dataIndex: 'nickname',
      align: 'center',
      width: 60,
      render: (data, record) => (
        <>
          <p>{data}</p>
        </>
      ),
    },

    {
      title: '본명',
      key: 'name',
      dataIndex: 'name',
      align: 'center',
      width: 60,
      render: (data, record) => {
        return (
          <>
            <p>{data}</p>
          </>
        );
      },
    },
    {
      title: '소속팀',
      key: 'team',
      dataIndex: 'team',
      align: 'center',
      width: 60,
      render: (data, record) => (
        <>
          <p>{data}</p>
        </>
      ),
    },
    {
      title: '직책',
      key: 'role',
      dataIndex: 'role',
      align: 'center',
      width: 60,
      render: (data, record) => (
        <>
          <p style={{ color: '#767676', fontSize: 12 }}>{data}</p>
        </>
      ),
    },
    {
      title: '연락처',
      key: 'phone',
      dataIndex: 'phone',
      align: 'center',
      width: 80,
      render: (data, record) => {
        return (
          <>
            <p>{data}</p>
          </>
        );
      },
    },
    {
      title: '이메일',
      key: 'email',
      dataIndex: 'email',
      align: 'center',
      width: 80,
      render: (data, record) => {
        return (
          <>
            <p>{data}</p>
          </>
        );
      },
    },
    {
      title: <TableColHeader title={'상태'} subTitle={'정상/정지'} />,
      key: 'isConfirmed',
      dataIndex: 'isConfirmed',
      align: 'center',
      width: 80,
      render: (data, record) => {
        return (
          <Switch
            checked={data}
            disabled={record?.isMaster}
            onClick={() => {
              handleModifyConfirm(`/accounts/admin/confirm`, record.id, data, async () => {
                await queryClient.invalidateQueries([ADMIN_LIST_KEY]);
              });
            }}
          />
        );
      },
    },
  ];
  const [columns, tableWidth] = useMemo(() => {
    const o = [...COLUMNS];
    const width = o.reduce((a, c) => a + (c.width || c.minWidth || 300), 0);
    return [o, width];
  }, []);

  const [tableScroll, setTableScroll] = useState();
  useEffect(() => {
    const handle = () => {
      if (tableWidth < getClientWidth()) {
        setTableScroll(undefined);
      } else {
        setTableScroll({ x: tableWidth });
      }
    };

    handle();
  }, [tableWidth]);

  // 테이블 페이지네이션
  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 360);

  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 360);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setRefreshDetail(record.id);
      },
    };
  };
  const handleDelete = async (id) => {
    const result = window.confirm(`정말 ${params?.isDeleted?'복구':'삭제'}하시겠습니까?`);
    if (result) {
      try {
        await restApi.put(`/accounts/user/${id}`)
      }catch (e){
        alert(`${params?.isDeleted?'복구':'삭제'}에 실패했습니다.`)
      }
      await refetch();
    }
  }

  return (
    <div>
      <PageHeader className="site-page-header" title={`직원 관리 (${params?.isDeleted?'삭제회원':'관리회원'})`} style={{ paddingBottom: 10 }} />
      <ExtraWrapper>
        {/* 필터 윗줄 */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4.5 }}>
          <UpperLine containerStyle={{width:'65%'}} listKey={ADMIN_LIST_KEY} detailKey={ADMIN_LIST_DETAIL_KEY} setRefresh={setRefreshDetail} />
        </div>
        {/* 필터 아랫줄 */}
        <div style={{ width: '65%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <LowerLine
            onCheck={() => {
              if (idList.length === checkedList.length) {
                setCheckedList([]);
              } else {
                setCheckedList(idList);
              }
            }}
            left={<>
              <div
                  onClick={() => {
                    handleDeleteItems(checkedList);
                  }}
                  style={{
                    cursor: 'pointer',
                    marginLeft: 40,
                    display: 'flex',
                    alignContent: 'center',
                  }}
              >
                <img
                    src={require('../../../assets/delete.png')}
                    alt="delete"
                    style={{
                      width: '18.2px',
                      height: '21.2px',
                    }}
                />
                <span
                    style={{
                      marginLeft: 4,
                      fontSize: 14,
                      color: '#767676',
                    }}
                >
                항목삭제
              </span>
              </div>
              <div
                  style={{
                    cursor: 'pointer',
                    marginLeft: 40,
                    display: 'flex',
                    alignContent: 'center',
                  }}
                  onClick={() => {
                    setModalOpen(true);
                  }}
              >
                <img
                    src={require('../../../assets/newDoc.png')}
                    alt="add"
                    style={{
                      width: '18.2px',
                      height: '21.2px',
                    }}
                />
                <span
                    style={{
                      marginLeft: 4,
                      fontSize: 14,
                      color: '#767676',
                    }}
                >
                항목추가
              </span>
              </div>
            </>}
            checked={idList.length === checkedList.length}
          >
            {/* <ModifyAllButton
              showText={'견적 가능'}
              stopText={'불가능'}
              customStyle={{ marginLeft: 40 }}
              showAction={() => {
                handleConfirmAndNot('/accounts/admin/confirm', checkedList, true, () => {
                  queryClient.invalidateQueries([ADMIN_LIST_KEY]);
                });
              }}
              stopAction={() => {
                handleConfirmAndNot('/accounts/admin/confirm', checkedList, false, () => {
                  queryClient.invalidateQueries([ADMIN_LIST_KEY]);
                });
              }}
            /> */}

          </LowerLine>
          <div style={{width:150}}>
          <FilterSelect
              value={params?.order}
              onChange={(v) =>
                  setParams(
                      produce((draft) => {
                        draft.order = v;
                      }),
                  )
              }
          >
            <Option value="DESC">저장일시 최신순</Option>
          </FilterSelect>
          </div>
        </div>
      </ExtraWrapper>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <PaginateTable
            setParams={setParams}
            params={params}
            dataSource={adminData?.content}
            columns={COLUMNS}
            scroll={{y: tableHeight - 40}}
            data={adminData}
            page={params?.page}
            setPage={(v) =>
                setParams(
              produce((draft) => {
                draft.page = v;
              }),
            )
          }
          pageSize={params?.limit}
          setPageSize={(v) =>
            setParams(
              produce((draft) => {
                draft.limit = v;
              }),
            )
          }
          onRow={onRow}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '65%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
        />
        <div
          style={{

            overflowY: 'scroll',
            height: document.body.clientHeight - 240,
            paddingLeft: 20,
            boxSizing: 'border-box',
            paddingBottom: 15,
          }}
        >
          <AdminUserDetail userType={'agencies'} detailId={refreshDetail ? refreshDetail : adminData?.content[0]?.id} />
          <CommonStatistics />
        </div>
      </div>
      <Modal
        width={840}
        bodyStyle={{
          width: '100%',
          minWidth: 840,
          height: document.body.clientHeight - 200,
          overflowY: 'scroll',
          backgroundColor: '#f9f9f9',
        }}
        closable={true}
        open={modalOpen}
        onOk={async () => {
          try {
            await restApi.post(`/accounts/admin`, addMember);
            Modal.success({
              content: '성공적으로 저장되었습니다',
              onOk: () => {
                queryClient.invalidateQueries(ADMIN_LIST_KEY);
                queryClient.invalidateQueries(ADMIN_LIST_DETAIL_KEY);
                setAddMember(
                  produce((draft) => {
                    draft.signname = null;
                    draft.password = null;
                    draft.name = null;
                    draft.nickname = null;
                    draft.email = null;
                    draft.phone = null;
                  }),
                );
                setModalOpen(false);
              },
            });
          } catch (error) {
            Modal.error({
              title: '실패',
              content: error?.response?.data?.message,
            });
          }
        }}
        onCancel={() => {
          setAddMember(
            produce((draft) => {
              draft.signname = null;
              draft.password = null;
              draft.name = null;
              draft.nickname = null;
              draft.email = null;
              draft.phone = null;
            }),
          );
          setModalOpen(false);
        }}
        destroyOnClose={true}
        maskClosable={false}
        okText={'저장하기'}
        cancelText={'취소하기'}
      >
        <p
          style={{
            margin: '40px 46.5px 28px 50px',
            fontSize: 24,
            fontWeight: 600,
          }}
        >
          직원 추가
        </p>
        <div
          style={{
            width: 400,
            height: 800,
            backgroundColor: 'white',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 100,
            padding: 20,
          }}
        >
          <input
            type="text"
            style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '15px 0' }}
            placeholder="아이디를 입력해주세요."
            value={addMember?.signname}
            onChange={(e) => {
              setAddMember(
                produce((draft) => {
                  draft.signname = e.target.value;
                }),
              );
            }}
          />
          <div style={{ display: 'flex', borderBottom: '1px solid #d9d9d9', margin: '15px 0' }}>
            <input
              type={visiblePassWord ? 'text' : 'password'}
              autoComplete="off"
              style={{ width: '88%', border: 'none' }}
              placeholder="비밀번호를 입력해주세요"
              maxLength={79}
              value={addMember?.password}
              onChange={(e) => {
                setAddMember(
                  produce((draft) => {
                    draft.password = e.target.value;
                  }),
                );
              }}
            />
            <p
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#767676',
                fontSize: 12,
                width: '10%',
                marginLeft: 5,
                cursor: 'pointer',
              }}
              onClick={() => {
                setVisiblePassWord((prev) => !prev);
              }}
            >
              <EyeOutlined />
            </p>
          </div>
          <input
            type="text"
            style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '15px 0' }}
            placeholder="이름을 입력해주세요."
            value={addMember?.name}
            onChange={(e) => {
              setAddMember(
                produce((draft) => {
                  draft.name = e.target.value;
                }),
              );
            }}
          />
          <input
            type="text"
            style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '15px 0' }}
            placeholder="닉네임을 입력해주세요."
            value={addMember?.nickname}
            onChange={(e) => {
              setAddMember(
                produce((draft) => {
                  draft.nickname = e.target.value;
                }),
              );
            }}
          />
          <input
            type="email"
            autocomplete="off"
            style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '15px 0' }}
            placeholder="이메일을 입력해주세요."
            value={addMember?.email}
            onChange={(e) => {
              setAddMember(
                produce((draft) => {
                  draft.email = e.target.value;
                }),
              );
            }}
          />
          <input
            type="text"
            style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '15px 0' }}
            placeholder="번호를 입력해주세요."
            value={addMember?.phone}
            onChange={(e) => {
              setAddMember(
                produce((draft) => {
                  draft.phone = e.target.value;
                }),
              );
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ManageEmployeeIndex;
