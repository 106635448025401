export default {
  tpl_category: {
    main_1: require('./assets/tpl_categories/main_1.png'),
    main_10: require('./assets/tpl_categories/main_10.png'),
    main_11: require('./assets/tpl_categories/main_11.png'),
    main_12: require('./assets/tpl_categories/main_12.png'),
    main_13: require('./assets/tpl_categories/main_13.png'),
    main_14: require('./assets/tpl_categories/main_14.png'),
    main_2: require('./assets/tpl_categories/main_2.png'),
    main_3: require('./assets/tpl_categories/main_3.png'),
    main_4: require('./assets/tpl_categories/main_4.png'),
    main_435: require('./assets/tpl_categories/main_435.png'),
    main_5: require('./assets/tpl_categories/main_5.png'),
    main_6: require('./assets/tpl_categories/main_6.png'),
    main_60: require('./assets/tpl_categories/main_60.png'),
    main_7: require('./assets/tpl_categories/main_7.png'),
    main_8: require('./assets/tpl_categories/main_8.png'),
  },
  constructions: {
    length: require('./assets/constructionLength.png'),
    terms: require('./assets/constructionTerm.png'),
    difficulty: require('./assets/constructionDifficulty.png'),
    cost: require('./assets/constructionCost.png'),
  },
  icons: {
    blank_profile: require('./assets/blank_profile.png'),
  },
  lifeStyle: {
    generation: require('./assets/SUVA.png'),
    job: require('./assets/SUVB.png'),
    households: require('./assets/SUVC.png'),
    families: require('./assets/SUVD.png'),
    activityRate: require('./assets/SUVE.png'),
    interiorLiking: require('./assets/SUVF.png'),
  },
  unChecked: require('./assets/unChecked.png'),
  checked: require('./assets/checked.png'),
  delete: require('./assets/delete.png'),
  login: require(`./assets/login.png`),
  findInfo: require(`./assets/find_info.png`),
};
