import { PageHeader, Button, DatePicker, Col, Row, Input } from 'antd';
import React from 'react';
import TraceTable from '../../../components/table/TraceTable';
import { PERIOD_OPTIONS } from '../../../common/define';
import styles from './index.module.less';
import { MinusSquareFilled, PlusSquareFilled } from '@ant-design/icons';
import produce from 'immer';
import useForm from '../../../hooks/useForm';
import { shallowEqual, useSelector } from 'react-redux';

const INIT_STATE = {
  type: null,
  commision: null,
};

const ExpenseIndex = () => {
  const { form, setValue } = useForm(INIT_STATE);
  const common = useSelector((s) => s.common, shallowEqual);
  console.log({ common });

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="전문가 도움받기 서비스 관리 > 서비스 비용관리"
        extra={[
          PERIOD_OPTIONS.map((v) => {
            return <Button>{v?.label}</Button>;
          }),
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginLeft: 20, marginRight: 5 }}>기간설정</span>
            <DatePicker.RangePicker />
          </div>,
        ]}
      />
      <TraceTable style={{ marginBottom: 20 }} />

      <h3>공사도움받기 상담 서비스 요금</h3>
      <Row style={{ borderBottom: '1px solid gray' }}>
        <Col span={12} style={{ padding: 15, borderRight: '1px solid gray' }}>
          <label className={styles.radioBox}>
            <input
              type="radio"
              value={form.type}
              onChange={(e) => {
                setValue({ name: 'type', value: e.target.value });
              }}
              name="type"
            />
            <div className={styles.radioTextBox}>
              <p className={styles.radioTitle}>구간설정방식</p>
              <p>견적금액의 구간별로 정액의 수수료를 적용합니다</p>
            </div>
          </label>

          <table>
            <thead>
              <tr>
                <th></th>
                <th>견적금액 범위(원)</th>
                <th>책정기관(원/건)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>1</th>
                <th className={styles.secondCol}>
                  0 ~ <Input style={{ width: 110 }} />
                </th>
                <th className={styles.arange}>
                  <Input style={{ width: 140 }} />
                </th>
              </tr>
              <tr>
                <th>2</th>
                <th className={styles.secondCol}>
                  10,000,000 ~ <Input style={{ width: 110 }} />
                </th>
                <th className={styles.arange}>
                  <Input style={{ width: 140 }} />
                </th>
              </tr>
              <tr>
                <th>3</th>
                <th className={styles.secondCol}>
                  20,000,000 ~ <Input style={{ width: 110 }} />
                </th>
                <th className={styles.arange}>
                  <Input style={{ width: 140 }} />
                </th>
              </tr>
              <tr>
                <th>4</th>
                <th className={styles.secondCol}>
                  40,000,000 ~ <Input style={{ width: 110 }} />
                </th>
                <th className={styles.iconBox}>
                  <Input style={{ width: 140 }} />
                  <PlusSquareFilled style={{ fontSize: 24, color: '#a7a3a3', cursor: 'pointer' }} />
                  <MinusSquareFilled style={{ fontSize: 24, color: '#a7a3a3', cursor: 'pointer' }} />
                </th>
              </tr>
              <tr>
                <th>5</th>
                <th className={styles.secondCol}>
                  80,000,000 ~{' '}
                  <Input
                    value="무한대"
                    style={{ width: 110, border: 'none', backgroundColor: '#fff', color: '#333' }}
                    disabled
                  />
                </th>
                <th className={styles.arange}>
                  <Input style={{ width: 140 }} />
                </th>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col span={12} style={{ padding: 15 }}>
          <label className={styles.radioBox}>
            <input
              type="radio"
              name="type"
              onChange={(e) => {
                setValue({ name: 'type', value: e.target.value });
              }}
            />
            <div className={styles.radioTextBox}>
              <p className={styles.radioTitle}>비율설정방식</p>
              <p>견적금액의 구간별로 정액의 수수료를 적용합니다</p>
            </div>
          </label>

          <div className={styles.feeBox}>
            <p>수수로율 (%/건)</p>
            <Input
              value={form.commision}
              style={{ width: 200 }}
              disabled={form.type === 'interval'}
              onChange={(e) => {
                setValue({ name: 'commision', value: e.target.value });
              }}
            />
          </div>
        </Col>
      </Row>

      <Row style={{ justifyContent: 'center', textAlign: 'center', marginTop: 50 }}>
        <Col span={6} className={styles.bottomFitstColContainer}>
          <div>
            <p className={styles.inputTitle}>대상</p>
            <Input value={'소비자 시공사 케어센터 하도급'} />
          </div>
          <Input value={'케어센터 시공사'} />
        </Col>
        <Col span={6} className={styles.bottomSecondColContainer}>
          <p className={styles.inputTitle}>컨텐츠제작자 수수료율</p>
          <Input value={'30%'} className={styles.input} />
          <p className={styles.inputTitle}>견적수수료율</p>
          <Input value={'30%'} className={styles.input} />
        </Col>
      </Row>

      <Row style={{ alignItems: 'center', margin: '30px 0', backgroundColor: '#fff' }}>
        <Col span={12} style={{ padding: '0 30px', display: 'flex', alignItems: 'center' }}>
          <input type="radio" style={{ marginRight: 5 }} />
          <span className={styles.inputTitle}>
            수수료율을 변경하는데 필요한 모든 사내 결재를 완료했음을 확인합니다.
          </span>
        </Col>
        <Col
          span={12}
          style={{ paddingLeft: 40, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <span>최종 수정일 : 2023-00-00</span>
          <span>최종 수정자 : OM 김병욱 부장</span>
          <Button type="primary" style={{ width: 100 }}>
            저장
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ExpenseIndex;
