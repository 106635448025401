import { useMemo } from 'react';
import {
  BookOutlined,
  DeploymentUnitOutlined,
  FileDoneOutlined,
  HomeOutlined,
  SettingOutlined,
  SnippetsOutlined,
  UserOutlined,
} from '@ant-design/icons';
import HomeIndex from '../pages';
import AccountUsersIndex from '../pages/accounts/users';
import AccountUsersDetails from '../pages/accounts/users/[id]';
import AccountAgenciesInteriorIndex from '../pages/accounts/agencies/interior';
import AccountAgenciesPartIndex from '../pages/accounts/agencies/part';
import AccountMakersIndex from '../pages/accounts/makers';
import ProposalList from '../pages/accounts/users/proposalList';
import SaveProposal from '../pages/accounts/users/saveProposal';
import ConsultingList from '../pages/consulting/ConsultingList';
import ProductsCategoriesIndex from '../pages/products/categories';
import ContentNoticeIndex from '../pages/contents/notice';
import ContentNoticeCreate from '../pages/contents/notice/create';
import CollectionsItemsIndex from '../pages/collections/items';
import CollectionsItemsDetails from '../pages/collections/items/[id]';
import ProductsItemsIndex from '../pages/products/items';
import ProductsItemsDetails from '../pages/products/items/[id]';
import ProductCreate from '../pages/products/items/create';
import SettingsApartmentsIndex from '../pages/settings/apartments';
import SettingsApartmentsDetails from '../pages/settings/apartments/[id]';
import AccountsMakersDetails from '../pages/accounts/makers/[id]';
import QuotationUsersIndex from '../pages/quotation/users';
import QuotationUsersDetails from '../pages/quotation/users/[id]';
import QuotationApplicationIndex from '../pages/quotation/application';
import QuotationApplicationDetails from '../pages/quotation/application/[id]';
import QuotationListIndex from '../pages/quotation/list';
import QuotationListDetails from '../pages/quotation/list/[id]';
import SettingsEstimateTplIndex from '../pages/settings/estimate_tpls';
import SettingsEstimateOptionIndex from '../pages/settings/estimate_options';
import SettingsEstimateCategoriesIndex from '../pages/settings/estimate_categories';
import SettingsEstimateCategoriesDetails from '../pages/settings/estimate_categories/[id]';
import CollectionsMastersIndex from '../pages/collections/masters';
import CollectionsMastersDetails from '../pages/collections/masters/[id]';
import ContentBannerIndex from '../pages/contents/banner';
import ContentBannerCreate from '../pages/contents/banner/create';
import ContentSectionIndex from '../pages/contents/section';
import ContentSectionForm from '../pages/contents/section/create';
import ContentPopupIndex from '../pages/contents/popup';
import ContentPopupCreate from '../pages/contents/popup/create';
import ContentFaqIndex from '../pages/contents/faq';
import CollectionsMagazinesIndex from '../pages/collections/magazines';
import CollectionsMagazinesDetails from '../pages/collections/magazines/[id]';
import CollectionsTrandIndex from '../pages/collections/trand';
import CollectionsGroupsIndex from '../pages/collections/groups';
import CollectionsGroupDetails from '../pages/collections/groups/[id]';
import ContentSubBannerIndex from '../pages/contents/subbanner';
import ContentSubBannerCreate from '../pages/contents/subbanner/create';
import ContentRepBannerIndex from '../pages/contents/repbanner';
import ContentRepBannerCreate from '../pages/contents/repbanner/create';
import AccountSubcontractsIndex from '../pages/accounts/subcontract';
import AccountAlertsIndex from '../pages/accounts/alert';
import AccountSubcontractsDetails from '../pages/accounts/subcontract/[id]';
import AccountMakersDetails from '../pages/accounts/makers/[id]/index';
import ProposalListIndex from '../pages/expert/proposal';
import ProposalListDetails from '../pages/expert/proposal/[id]';
import EndListIndex from '../pages/expert/end';
import ExpenseIndex from '../pages/expert/expense';
import ExpertSetting from '../pages/expert/expertSetting/ExpertSetting';
import HoldIndex from '../pages/expert/hold';
import ImBranchList from '../pages/im/branchlist';
import ImBranchListDetails from '../pages/im/branchlist/[id]';
import ManageMentIndex from '../pages/im/management';
import ImRegisterIdex from '../pages/im/register';
import ImManagementIndex from '../pages/im/management';
import EstimateIndex from '../pages/expert/proposal/estimate/[id]';
import SubmitIndex from '../pages/expert/proposal/submit';
import ProposalEstimateIndex from '../pages/expert/proposal/submit/estimate/[id]';
import OperationMainPopupIndex from '../pages/operation/main_popup';
import OperationMainBannerIndex from '../pages/operation/main_banner';
import OperationSubBannerIndex from '../pages/operation/sub_banner';
import OperationMainSectionIndex from '../pages/operation/main_section';
import OperationAdsIndex from '../pages/operation/ads';
import ManagementGuide from '../pages/settings/serviceGuide';
import CustomerBoard from '../pages/writing/customer/CustomerBoard';
import AccountsAgenciesDistributionIndex from '../pages/accounts/agencies/distribution';
import AccountsInfluencerIndex from '../pages/accounts/influencer';
import FindCustomerIndex from '../pages/expert/findCustomer';
import SaveStyle from '../pages/accounts/users/saveStyle/SaveStyle';
import SaveEstimate from '../pages/accounts/users/saveEstimate/SaveEstimate';
import CollectionsPortfoliosIndex from '../pages/collections/portfolios';
import OperationMainSectionCompose from '../pages/operation/main_section/compose';
import OperationMainSectionComposeDetail from '../pages/operation/main_section/detail';
import OperationMainSectionComposeDetailSearch from '../pages/operation/main_section/Search';
import CollectionsGuideIndex from '../pages/collections/constructionGuide';
import AgencyBoard from '../pages/writing/agency/AgencyBoard';
import ManagementNotice from '../pages/settings/notice';
import ManageEmployeeIndex from '../pages/settings/manageEmployee';
import SaveRequest from '../pages/accounts/users/saveRequest/SaveRequest';
import SelectedListIndex from '../pages/expert/findCustomer/SelectedList';
import CommentIndex from '../components/comment/CommentIndex';
import LikeIndex from '../pages/like';
import EstimateDatail from '../pages/accounts/users/saveEstimate/EstimateDetail';
import ProposalDetail from '../pages/expert/proposal/ProposeDetail';

export const pages = [
  {
    path: '/',
    title: '홈',
    icon: HomeOutlined,
    screen: HomeIndex,
    isMenu: true,
  },
  {
    path: '/accounts',
    title: '회원관리',
    icon: UserOutlined,
    isMenu: true,
  },
  {
    path: '/accounts/users',
    title: '소비자회원',
    isMenu: true,
    screen: AccountUsersIndex,
  },
  {
    path: '/accounts/users/:id',
    title: '소비자 회원',
    // isMenu: true,
    screen: AccountUsersDetails,
  },
  {
    path: '/accounts/agencies/interior',
    title: '시공사 회원',
    isMenu: true,
    screen: AccountAgenciesInteriorIndex,
  },
  // {
  //   path: '/accounts/agencies/part',
  //   title: '시공사 회원(부분공사)',
  //   isMenu: true,
  //   screen: AccountAgenciesPartIndex,
  // },
  // {
  //   path: '/accounts/agencies/distribution',
  //   title: '제조/유통사',
  //   isMenu: true,
  //   screen: AccountsAgenciesDistributionIndex,
  // },
  // {
  //   path: '/accounts/agencies/influencer',
  //   title: '인플루언서',
  //   isMenu: true,
  //   screen: AccountsInfluencerIndex,
  // },
  {
    path: '/accounts/agencies/findcustomer',
    title: '고객찾기',
    screen: FindCustomerIndex,
  },
  {
    path: '/accounts/save/:type/:id/:accountType',
    title: '고객찾기',
    screen: SaveStyle,
  },
  {
    path: '/accounts/agencies/select/:type/:id',
    title: '고객찾기 - 시공사 회원 상세',
    screen: SelectedListIndex,
  },
  {
    path: '/accounts/user/saveestimate/:id',
    title: '저장한 견적서',
    screen: SaveEstimate,
  },
  {
    path: '/accounts/user/save/request/:id',
    title: '저장한 공사도움받기',
    screen: SaveRequest,
  },
  {
    path: '/accounts/save/proposal/:id/:type',
    title: '제안서',
    screen: SaveProposal,
  },

  {
    path: '/accounts/save/consulting/:id',
    title: '컨설팅리스트',
    screen: ConsultingList,
  },
  {
    path: '/proposal/construction-request/:id',
    title: '컨설팅리스트',
    screen: ProposalList,
  },

  {
    path: '/comment/detail/:id/:type',
    title: '댓글 - 테이블 상세',
    screen: CommentIndex,
  },
  {
    path: '/like/detail/:id/',
    title: '좋아요 - 테이블 상세',
    screen: LikeIndex,
  },
  {
    path: '/saveestimate/detail/:id',
    title: '견적 - 테이블 상세',
    screen: EstimateDatail,
  },
  {
    path: '/proposal/detail/:id',
    title: '공사도움받기 - 테이블 상세',
    screen: ProposalDetail,
  },
  // {
  //   path: '/accounts/subcontract',
  //   title: '하도급',
  //   isMenu: true,
  //   screen: AccountSubcontractsIndex,
  // },
  // {
  //   path: '/accounts/subcontract/:id',
  //   title: '하도급회원',
  //   screen: AccountSubcontractsDetails,
  // },
  // {
  //   path: '/accounts/makers',
  //   title: '제조/유통사',
  //   isMenu: true,
  //   screen: AccountMakersIndex,
  // },
  // {
  //   path: '/accounts/makers/:id',
  //   title: '제조/유통사',
  //   screen: AccountMakersDetails,
  // },
  // {
  //   path: '/accounts/alert',
  //   title: '알람센터',
  //   isMenu: true,
  //   screen: AccountAlertsIndex,
  // },
  // {
  //   path: '/accounts/makers/:id',
  //   title: '제품제공사 상세',
  //   screen: AccountsMakersDetails,
  // },
  // {
  //   path: '/quotation',
  //   title: '견적 관리',
  //   icon: FileDoneOutlined,
  //   isMenu: true,
  // },

  // {
  //   path: '/quotation/users',
  //   title: '등록 견적서',
  //   screen: QuotationUsersIndex,
  //   isMenu: true,
  // },
  // {
  //   path: '/quotation/users/:id',
  //   title: '등록 견적서',
  //   screen: QuotationUsersDetails,
  // },

  // {
  //   path: '/quotation/application',
  //   title: '공사 도움받기 신청',
  //   screen: QuotationApplicationIndex,
  //   isMenu: true,
  // },
  // {
  //   path: '/quotation/application/:id',
  //   title: '공사 도움받기 신청',
  //   screen: QuotationApplicationDetails,
  // },
  // {
  //   path: '/quotation/list',
  //   title: '공사 도움받기 현황',
  //   screen: QuotationListIndex,
  //   isMenu: true,
  // },
  // {
  //   path: '/quotation/list/:id',
  //   title: '공사 도움받기 현황',
  //   screen: QuotationListDetails,
  //   // isMenu: true,
  // },
  {
    path: '/expert',
    title: '공사도움받기',
    icon: FileDoneOutlined,
    isMenu: true,
  },
  {
    path: '/expert/expertSetting',
    title: '서비스 비용 설정',
    screen: ExpertSetting,
    isMenu: true,
  },
  // {
  //   path: '/expert/expense',
  //   title: '서비스 비용관리',
  //   screen: ExpenseIndex,
  //   isMenu: true,
  // },
  {
    path: '/expert/proposal',
    title: '실시간 의뢰 리스트',
    screen: ProposalListIndex,
    isMenu: true,
  },
  {
    path: '/expert/proposal/submit',
    title: '제안서제출업체',
    screen: SubmitIndex,
  },
  {
    path: '/expert/proposal/estimate/:id',
    title: '제안견적보기',
    screen: EstimateIndex,
  },
  {
    path: '/expert/proposal/submit/estimate/:id',
    title: '제안견적보기',
    screen: ProposalEstimateIndex,
  },
  {
    path: '/expert/proposal/:id',
    title: '제안요청리스트',
    screen: ProposalListDetails,
  },

  // {
  //   path: '/expert/hold',
  //   title: '요청진행보류리스트',
  //   screen: HoldIndex,
  //   isMenu: true,
  // },
  // {
  //   path: '/expert/end',
  //   title: '서비스 종료 리스트',
  //   screen: EndListIndex,
  //   isMenu: true,
  // },
  {
    path: '/writing',
    title: '글쓰기 관리',
    icon: FileDoneOutlined,
    isMenu: true,
  },
  {
    path: '/writing/customer',
    title: '소비자 게시판',
    screen: CustomerBoard,
    isMenu: true,
  },
  {
    path: '/writing/agency',
    title: '시공사 게시판',
    screen: AgencyBoard,
    isMenu: true,
  },
  // {
  //   path: '/im',
  //   title: 'IM 서비스 관리',
  //   icon: FileDoneOutlined,
  //   isMenu: true,
  // },
  // {
  //   path: '/im/register',
  //   title: '지부등록',
  //   screen: ImRegisterIdex,
  //   isMenu: true,
  // },
  // {
  //   path: '/im/branchlist',
  //   title: '지부리스트',
  //   screen: ImBranchList,
  //   isMenu: true,
  // },
  // {
  //   path: '/im/branchlist/:id',
  //   title: '지부리스트',
  //   screen: ImBranchListDetails,
  // },
  // {
  //   path: '/im/management',
  //   title: 'IM서비스 진행 관리',
  //   screen: ImManagementIndex,
  //   isMenu: true,
  // },
  {
    path: '/products',
    title: 'CM상품관리',
    icon: DeploymentUnitOutlined,
    isMenu: true,
  },
  {
    path: '/products/items',
    title: '상품등록 관리',
    screen: ProductsItemsIndex,
    isMenu: true,
  },
  {
    path: '/products/items/create',
    title: '신규 상품 등록',
    screen: ProductCreate,
    isMenu: false,
  },
  {
    path: '/products/items/:id',
    title: '제품 관리 상세',
    screen: ProductsItemsDetails,
  },
  {
    path: '/products/categories',
    title: '카테고리 관리',
    screen: ProductsCategoriesIndex,
    isMenu: true,
  },
  {
    path: '/collections',
    title: '컨텐츠 관리',
    icon: BookOutlined,
    isMenu: true,
    screen: CollectionsItemsIndex,
  },
  // {
  //   path: '/collections/group',
  //   title: '전시관리',
  //   isMenu: true,
  //   screen: CollectionsGroupsIndex,
  // },
  // {
  //   path: '/collections/group/:id',
  //   title: '전시관리 상세',
  //   screen: CollectionsGroupDetails,
  // },
  {
    path: '/collections/magazines',
    title: '스타일 관리',
    isMenu: true,
    screen: CollectionsMagazinesIndex,
  },

  {
    path: '/collections/magazines/:id',
    title: '스타일 상세',
    screen: CollectionsMagazinesDetails,
  },
  {
    path: '/collections/portfolios',
    title: '포트폴리오 관리',
    isMenu: true,
    screen: CollectionsPortfoliosIndex,
  },
  {
    path: '/collections/trand',
    title: '트렌드 관리',
    isMenu: true,
    screen: CollectionsTrandIndex,
  },
  {
    path: '/collections/construction-guide',
    title: '공사가이드 관리',
    isMenu: true,
    screen: CollectionsGuideIndex,
  },
  {
    path: '/operation',
    title: '앱 운영관리',
    icon: SettingOutlined,
    isMenu: true,
  },
  {
    path: '/operation/main_popup',
    title: '메인팝업',
    screen: OperationMainPopupIndex,
    isMenu: true,
  },
  {
    path: '/operation/main_banner',
    title: '메인배너',
    screen: OperationMainBannerIndex,
    isMenu: true,
  },
  {
    path: '/operation/sub_banner',
    title: '서브배너',
    screen: OperationSubBannerIndex,
    isMenu: true,
  },
  {
    path: '/operation/main_section',
    title: '메인섹션',
    screen: OperationMainSectionIndex,
    isMenu: true,
  },
  {
    path: '/operation/main_section/section',
    title: '메인 섹션 항목구성',
    screen: OperationMainSectionCompose,
  },
  {
    path: '/operation/main_section/section/detail',
    title: '메인 섹션 항목구성 세부',
    screen: OperationMainSectionComposeDetail,
  },
  {
    path: '/operation/main_section/section/detail/search',
    title: '메인 섹션 항목구성 세부 검색',
    screen: OperationMainSectionComposeDetailSearch,
  },
  {
    path: '/operation/ads',
    title: '견적광고',
    screen: OperationAdsIndex,
    isMenu: true,
  },
  {
    path: '/docs',
    title: '견적알고리즘 관리',
    icon: SnippetsOutlined,
    isMenu: true,
  },

  {
    path: '/docs/estimates/category',
    title: '공종 카테고리',
    screen: SettingsEstimateCategoriesIndex,
    isMenu: true,
  },
  {
    path: '/docs/estimates/category/:id',
    title: '공종 카테고리 상세',
    screen: SettingsEstimateCategoriesDetails,
  },

  //
  {
    path: '/docs/estimates/tpl',
    title: '견적 템플릿 작성',
    screen: SettingsEstimateTplIndex,
    isMenu: true,
  },

  //
  {
    path: '/docs/estimates/manage',
    title: '간접공사비율 및 추가 관리',
    screen: SettingsEstimateOptionIndex,
    isMenu: true,
  },

  // {
  //   path: '/contents',
  //   title: '앱 컨텐츠 관리',
  //   icon: SnippetsOutlined,
  //   isMenu: true,
  // },

  // {
  //   path: '/contents/banners',
  //   title: '메인 배너',
  //   screen: ContentBannerIndex,
  //   isMenu: true,
  // },
  // {
  //   path: '/contents/banners/:id',
  //   screen: ContentBannerCreate,
  // },

  // {
  //   path: '/contents/subbanners',
  //   title: '서브 배너',
  //   screen: ContentSubBannerIndex,
  //   isMenu: true,
  // },
  // {
  //   path: '/contents/subbanners/:id',
  //   screen: ContentSubBannerCreate,
  // },

  // {
  //   path: '/contents/repbanners',
  //   title: '견적광고',
  //   screen: ContentRepBannerIndex,
  //   isMenu: true,
  // },
  // {
  //   path: '/contents/repbanners/:id',
  //   screen: ContentRepBannerCreate,
  // },

  // {
  //   path: '/contents/popups',
  //   title: '메인 팝업',
  //   screen: ContentPopupIndex,
  //   isMenu: true,
  // },
  // {
  //   path: '/contents/popups/:id',
  //   screen: ContentPopupCreate,
  // },

  // {
  //   path: '/contents/sections',
  //   title: '메인 섹션',
  //   screen: ContentSectionIndex,
  //   isMenu: true,
  // },
  // {
  //   path: '/contents/sections/:id',
  //   screen: ContentSectionForm,
  // },

  // {
  //   path: '/contents/notices',
  //   title: '공지사항',
  //   screen: ContentNoticeIndex,
  //   isMenu: true,
  // },
  // {
  //   path: '/contents/notices/create',
  //   title: '공지사항 등록',
  //   screen: ContentNoticeCreate,
  // },

  // {
  //   path: '/contents/faqs',
  //   title: 'FAQ',
  //   screen: ContentFaqIndex,
  //   isMenu: true,
  // },
  // {
  //   path: '/contents/faqs/create',
  //   title: 'FAQ 등록',
  //   screen: ContentNoticeCreate,
  // },
  {
    path: '/settings',
    title: '설정',
    icon: SettingOutlined,
    isMenu: true,
  },
  {
    path: '/settings/manage_employee',
    title: '직원관리',
    screen: ManageEmployeeIndex,
    isMenu: true,
  },
  {
    path: '/settings/apartments',
    title: '아파트 정보',
    screen: SettingsApartmentsIndex,
    isMenu: true,
  },
  {
    path: '/settings/apartments/:id',
    screen: SettingsApartmentsDetails,
  },
  {
    path: '/settings/service_guide',
    title: '서비스 이용 가이드 관리',
    screen: ManagementGuide,
    isMenu: true,
  },
  {
    path: '/settings/notice',
    title: '공지사항',
    screen: ManagementNotice,
    isMenu: true,
  },
];

export const useRoutes = () => {
  const routes = useMemo(
    () =>
      pages
        ?.filter((v) => !!v.isMenu)
        ?.reduce((output, page) => {
          const path = page.path.substring(1);
          const parentPath = '/' + path?.substring(0, path.indexOf('/'));
          const parentIndex = path?.indexOf('/') >= 0 ? output.findIndex((v) => v.path === parentPath) : undefined;
          if (parentIndex === undefined) {
            output.push({
              path: page.path,
              title: page.title,
              icon: page.icon,
            });
          } else {
            if (!output[parentIndex].children) output[parentIndex].children = [];
            output[parentIndex].children.push({
              path: page.path,
              title: page.title,
            });
          }
          return output;
        }, []),
    [],
  );

  return { routes, pages };
};

export default useRoutes;
