import React from 'react';
import { useSelector } from 'react-redux';
import { useGetConstructionCategories, useGetEstimateCostTemplate } from '../../../apis/queries';
import Thumbnail from '../../image/Thumbnail';
import { FilterWhiteButton } from '../../template/page';
import EstimateCard from '../EstimateCard';

import styles from './SmallEstimate.module.less';
import ShowEstimateModal from '../../modal/ShowEstimateModal';

const SmallEstimate = ({
  isFirst,
  data: detail,
  visibleModal,
  isFirstShow,
  setIsFirstShow,
  isPartner,
    secret=true,
  isInner,
  preview,
  hideTitle = false,
}) => {
  const { data } = useGetEstimateCostTemplate({});
  const { data: categories } = useGetConstructionCategories();
  const { commonCodes } = useSelector((s) => s.common);
  const [isModlaOpen, setIsModlaOpen] = React.useState(false);
  const calculatePrice = ({ detail, cost, rate, estimateAdditionalCostOption }) => {
    if (cost) return cost;
    switch (estimateAdditionalCostOption) {
      case 'EACO001':
        return Math.floor(((detail?.totalLaborCost + detail?.totalMaterialCost) * rate) / 100);
      case 'EACO002':
        return Math.floor((detail?.totalMaterialCost * rate) / 100);
      case 'EACO003':
        return Math.floor((detail?.totalLaborCost * rate) / 100);
      default:
        return 0;
    }
  };
  const directCosts = React.useMemo(() => {
    return categories?.tree?.map((v) => {
      const obj = {};
      obj.title = v.categoryName;
      obj.price = 0;
      detail?.areas?.forEach((area) => {
        area.estimateResponses.forEach((response) => {
          if (
            response.categoryId === v.categoryId ||
            v?.children?.filter((x) => x.categoryId === response.categoryId).length > 0
          ) {
            obj.price += response?.priceInfo.price;
          }
        });
      });
      return obj;
    });
  }, [detail, categories]);

  return (
    <>
      <h3 style={{ marginTop: isFirst ? 0 : 20, marginBottom: 5 }}>견적정보</h3>
      <div className={styles.small_estimate_container}>
        <div className={styles.profile_div}>
          <div className={styles.profile_image_div}>
            <Thumbnail
              containerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              imageStyle={{ width: 36, borderRadius: '50%', height: 36 }}
              id={detail?.createdBy?.profileImageId}
            ></Thumbnail>
          </div>
          <div className={styles.profile_content_div}>
            <p className={styles.profile_title}>고객 산출 예산</p>
            <p className={styles.profile_name}>{detail?.createdBy?.nickname}</p>
          </div>
        </div>
        <div style={{ marginTop: 28 }}>
          <EstimateCard
            title={'전체'}
            subTitle={'총 견적금액 (A) + (B)'}
            price={
              directCosts?.reduce((a, v) => a + v.price, 0) +
              data
                ?.filter((v) => v.isFixed)
                ?.reduce(
                  (a, { title, cost, estimateAdditionalCostOption, rate }) =>
                    a + calculatePrice({ detail, cost, rate, estimateAdditionalCostOption }),
                  0,
                )
            }
          />
          <EstimateCard
            title={'직접공사비'}
            items={directCosts}
            subTitle={'(A)=자재비+부자재비+인건비'}
            price={directCosts?.reduce((a, v) => a + v.price, 0)}
            // items={}
            leftBottonButton={
              <FilterWhiteButton
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModlaOpen(true);
                }}
              >
                자재보기
              </FilterWhiteButton>
            }
          />
          <EstimateCard
            title={'간접공사비'}
            subTitle={'(B)=이윤+각종관리비+보험료'}
            price={data
              ?.filter((v) => v.isFixed)
              ?.reduce(
                (a, { title, cost, estimateAdditionalCostOption, rate }) =>
                  a + calculatePrice({ detail, cost, rate, estimateAdditionalCostOption }),
                0,
              )}
            items={data
              ?.filter((v) => v.isFixed)
              ?.map(({ title, cost, estimateAdditionalCostOption, rate }) => {
                const price = calculatePrice({ detail, cost, rate, estimateAdditionalCostOption });
                return {
                  title,
                  price,
                  option: rate
                    ? `${commonCodes?.tree?.eaco?.find((v) => v.code === estimateAdditionalCostOption)?.label}의 ${
                        rate / 100
                      }%`
                    : null,
                };
              })}
          />
        </div>
      </div>
      <ShowEstimateModal
        visible={isModlaOpen}
        setVisible={setIsModlaOpen}
        data={detail}
        handleCancel={() => setIsModlaOpen(false)}
      />
    </>
  );
};

export default SmallEstimate;
