import React from 'react';
import { useSelector } from 'react-redux';
import { useGetRegions } from '../apis/queries';
import useRoutes from '../nav/useRoutes';

export const CommonCodeContext = React.createContext(null);

const CommonCodePovider = ({ children }) => {
  const { commonCodes } = useSelector((state) => state.common);
  const { data: regionData } = useGetRegions();
  const { routes, pages } = useRoutes();
  console.log(routes);
  const codeMapADPM = React.useMemo(() => {
    const arr = [];
    commonCodes?.tree.ADPM.map((el) => {
      const obj = {};
      obj['title'] = el.label;
      obj['code'] = el.code;
      arr.push(obj);
    });
    return arr;
  }, [commonCodes]);
  const codeMapRegion = React.useMemo(() => {
    const obj = {};
    regionData?.trees.forEach((el) => {
      const innerobj = {};
      el.children.forEach((child) => {
        innerobj[child.areaCode] = child.label;
      });
      obj[el.areaCode] = {
        label: el.label,
        children: innerobj,
      };
    });
    return obj;
  }, [regionData]);

  const codeMapPTAT = React.useMemo(() => {
    const obj = {};
    commonCodes?.tree.PTAT.forEach((el) => {
      obj[el.code] = el.label;
    });
    return obj;
  }, [commonCodes]);
  const codeMapHDOP = React.useMemo(() => {
    const obj = {};
    commonCodes?.tree.HDOP?.forEach((el) => {
      obj[el.code] = el.label;
    });
    return obj;
  }, [commonCodes]);
  const codeMapCOTP = React.useMemo(() => {
    const obj = {};
    commonCodes?.tree.COTP.forEach((el) => {
      obj[el.code] = el.label;
    });
    return obj;
  }, [commonCodes]);
  const codeMapCOCT = React.useMemo(() => {
    const obj = {};
    commonCodes?.tree.COCT.forEach((el) => {
      obj[el.code] = el.label;
    });
    return obj;
  }, [commonCodes]);
  const codeMapCBER = React.useMemo(() => {
    const obj = {};
    commonCodes?.tree.CBER.forEach((el) => {
      obj[el.code] = el.label;
    });
    return obj;
  }, [commonCodes]);
  const codeMapCCRT = React.useMemo(() => {
    const obj = {};
    commonCodes?.tree.CCRT.forEach((el) => {
      obj[el.code] = el.label;
    });
    return obj;
  }, [commonCodes]);
  const codeMapCRRT = React.useMemo(() => {
    const obj = {};
    commonCodes?.tree.CRRT.forEach((el) => {
      obj[el.code] = el.label;
    });
    return obj;
  }, [commonCodes]);
  const codeMapCBRT = React.useMemo(() => {
    const obj = {};
    commonCodes?.tree.CBRT.forEach((el) => {
      obj[el.code] = el.label;
    });
    return obj;
  }, [commonCodes]);
  const codeMapRDTP = React.useMemo(() => {
    const obj = {};
    commonCodes?.tree.RDTP.forEach((el) => {
      obj[el.code] = el.label;
    });
    return obj;
  }, [commonCodes]);
  const codeMapEACO = React.useMemo(() => {
    const arr = [];
    commonCodes?.tree.EACO.forEach((el) => {
      const obj = {};
      obj['code'] = el.code;
      obj['title'] = el.label;
      arr.push(obj);
    });
    return arr;
  }, [commonCodes]);
  // const codeMapADPM = React.useMemo(() => {
  //   const obj = {};
  //   commonCodes?.tree.ADPM.forEach((el) => {
  //     obj[el.label] = el.code;
  //   });
  //   return obj;
  // }, [commonCodes]);
  // console.log(codeMapADPM);
  const codeMap = React.useMemo(() => {
    return {
      codeMapRegion,
      codeMapPTAT,
      codeMapHDOP,
      codeMapCOTP,
      codeMapCOCT,
      codeMapCBER,
      codeMapCCRT,
      codeMapCRRT,
      codeMapCBRT,
      codeMapRDTP,
      codeMapADPM,
      codeMapEACO,
    };
  }, [commonCodes]);

  return <CommonCodeContext.Provider value={codeMap}>{children}</CommonCodeContext.Provider>;
};
export default CommonCodePovider;
