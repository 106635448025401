import {Modal, Switch} from 'antd';
import produce from 'immer';
import React from 'react';
import {restApi} from '../../../apis';
import PaginateTable from '../../table/PaginateTable';

const MessagingModal = ({setData: setId, data: id,setMessageId}) => {

  const handleCancel = () => {
    setId(null);
  };

  const [data, setData] = React.useState(null);

  const [params, setParams] = React.useState({
    limit: 50,
    page: 0,
  });

  React.useEffect(() => {
    const loadData = () => {
      restApi.get(`/direct-messaging/${id}`, {
        params,
      }).then((res) => {
        setData(res.data);
      }).catch((e) => {
        alert(e.response.data.message);
      });
    };
    if (id) {
      loadData();
    }
  }, [id, params]);

  return (
      <Modal
          width={500}
          footer={false}
          bodyStyle={{
            width: '100%',
            minWidth: 440,
            maxHeight: document.body.clientHeight - 300,
            overflowY: 'scroll',
            backgroundColor: '#f9f9f9',
          }}
          closable={true}
          open={!!id}
          onCancel={handleCancel}
          destroyOnClose={true}
          maskClosable={true}

          // cancelText={'수정'}
      >
        <div style={{padding: '70px 10px'}}>
          <p style={{fontSize: 22, fontWeight: 600, marginBottom: 20}}>다이렉트 메시지
            리스트</p>
          <PaginateTable

              style={{
                borderRadius: 10,
                overflowY: 'scroll',
                width: '100%',
                backgroundColor: 'white',
                boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
                paddingBottom: '100px',
              }}
              pageSize={params?.limit}
              page={params?.page}
              setPage={(v) =>
                  setParams(
                      produce((draft) => {
                        draft.page = v;
                      }),
                  )
              }
              setPageSize={(v) =>
                  setParams(
                      produce((draft) => {
                        draft.limit = v;
                      }),
                  )
              }
              dataSource={data?.content}
              onRow={(r) => {
                return {
                  onClick: () => {
                    setMessageId(r.id);
                  },
                };
              }}
              columns={[
                {title: '제목', dataIndex: 'title', key: 'title', width: 200,render:(t)=>(
                    <div style={{width:200,paddingLeft:20,fontSize:12,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{t}</div>
                  )},
                {
                  title: '작성일시',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  width: 150,
                  render: (t) =>
                    (<div style={{
                      width: 150,
                      paddingLeft: 20,
                      fontSize: 12,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>{t}</div>
                  )
                  },
              ]}
                ></PaginateTable>


                </div>
                </Modal>
                );
              };

export default MessagingModal;
