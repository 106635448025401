import React from 'react';
import styles from './RoundButton.module.less';
export default function RoundButton({
  containerStyle,
  onClick,
  label,
  disabled,
  reverse = false,
  deleteButton = false,
}) {
  return (
    <div
      onClick={onClick}
      className={
        disabled
          ? styles.buttonContainer_disabled
          : deleteButton
          ? styles.buttonContainer_delete
          : !reverse
          ? styles.buttonContainer
          : styles.buttonContainer_reverseColor
      }
      style={containerStyle}
    >
      <span className={styles.text}>{label}</span>
    </div>
  );
}
