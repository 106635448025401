import React, { useEffect, useState } from 'react';
import { Image, Modal, PageHeader, Checkbox, Switch } from 'antd';
import qs from 'query-string';
import CommentIcon from '../../../components/image/CommentIcon';
import PaginateTable from '../../../components/table/PaginateTable';
import CommonStatistics from '../../../components/card/CommonStatistics,js/CommonStatistics';
import styles from './magazines.module.less';
import { HeartFilled } from '@ant-design/icons';
import { ExtraWrapper, FilterButton, FilterSelect, FilterWhiteButton } from '../../../components/template/page';
import TableColHeader from '../../../components/table/TableColHeader';
import ContentSlider from '../../../components/card/ContentSlider/ContentSlider';

import Thumbnail from '../../../components/image/Thumbnail';
import { useSelector } from 'react-redux';
import { Option } from 'antd/lib/mentions';
import produce from 'immer';
import { useGetContentDetail, useGetContents } from '../../../apis/queries';
import moment from 'moment';
import { restApi } from '../../../apis';
import { useQueryClient } from 'react-query';
import { CONTENTS_KEY, CONTENT_DETAIL_KEY } from '../../../apis/queryKeys';
import { parseImageUri } from '../../../common/utils';
import WriteContentModal from '../../../components/modal/WriteContentModal';
import ContentDetailModal from '../../../components/modal/ContentDetailModal';
import useUpperLine from '../../../hooks/useUpperLine';
import LowerLine from '../../../components/LowerLine';
import ModifyAllButton from '../../../components/button/ModifyAllButton';
import RoundButton from '../../../components/button/RoundButton';

const ContentCard = React.memo(({ item, commonCodes, setVisibleDetailModal }) => {
  console.log({item})
  return (
    <div
      onClick={() => {
        setVisibleDetailModal(true);
      }}
      className={styles.slider_div}
      style={{ cursor: 'pointer', backgroundImage: `url(${parseImageUri(item?.thumbnailId)})` }}
    >
      <div className={styles.backdrop} />
      <div className={styles.slider_inner_div}>
        <p className={styles.category_p}>{commonCodes?.map[item?.area]}</p>
        <p className={styles.title_p}>{item?.description}</p>
        <div className={styles.flex_div}>
          <div className={styles.flex_div} style={{ alignItems: 'center' }}>
            <Thumbnail
              containerStyle={{ width: 'max-content', display: 'flex', alignItems: 'center' }}
              className={styles.style_profile_img}
              id={item?.createdBy?.profileImageId}
            />
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <p className={styles.category_p} style={{ marginLeft: 10 }}>
                {item?.createdBy?.nickname}
              </p>
            </div>
          </div>
          <div className={styles.flex_div}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <HeartFilled style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p}>{item?.likeInfo?.likeCount}</p>
            </div>
            <div
              style={{
                marginLeft: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CommentIcon style={{ fontSize: 18, color: '#d9d9d9' }} />
              <p className={styles.style_button_count_p}>{item?.shareCount}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
const CollectionsMagazinesIndex = (props) => {
  const { commonCodes } = useSelector((state) => state.common);
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      constructionType: null,
      isConfirmed: null,
      order: 'DESC',
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  const queryClient = useQueryClient();
  const [idList, setIdList] = React.useState([]);
  const [checkedList, setCheckedList] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState(null);
  const [visibleModifyModal, setVisibleModifyModal] = React.useState(false);
  const [visibleDetailModal, setVisibleDetailModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  const filters = React.useMemo(() => {
    const constructionTypeItems = commonCodes?.tree['AREA']?.map((v) => [v.code, v.label]);
    constructionTypeItems?.unshift([null, '전체']);
    const isConfirmedItems = [
      [null, '전체'],
      [true, '승인'],
      [false, '대기'],
    ];

    return [
      { title: '공간구분', key: 'area', options: constructionTypeItems },
      { title: '승인여부', key: 'isConfirmed', options: isConfirmedItems },
    ];
  }, [commonCodes]);
  const { params: upperParam, UpperLine } = useUpperLine(filters);

  React.useEffect(() => {
    setParams((prev) => ({ ...prev, ...upperParam }));
  }, [upperParam]);

  const { data: styleData } = useGetContents({
    type: 'styles',
    params,
    config: {
      onSuccess: (data) => {
        if (!selectedId) {
          setSelectedId(data?.content[0]?.id);
        }
      },
    },
  });

  console.log(styleData);

  const { data: styleDetail } = useGetContentDetail({
    type: 'styles',
    id: selectedId,
    config: {
      enabled: !!selectedId,
    },
  });

  const data = React.useMemo(() => {
    return styleData?.content?.map((v) => ({
      data: v,
      id: v?.id,
      title: v?.title,
      image: v?.thumbnailId,
      likeCount: v?.likeCount,
      commentCount: v?.commentCount,
      createdBy: v?.createdBy,
      constructionType: v?.constructionType,
      handleConfirmAt: v?.handleConfirmAt,
      handleConfirmationBy: v?.handleConfirmationBy,
      isConfirmed: v.isConfirmed,
      confirmedBy: {
        handleConfirmAt: v.handleConfirmAt,
        handleConfirmBy: v.handleConfirmationBy,
        isConfirmed: v.isConfirmed,
      },
      ...v,
    }));
  }, [styleData]);

  const detailData = React.useMemo(() => {
    console.log({styleDetail: styleDetail?.subContents})
    return styleDetail?.subContents?.map((v) => ({
      createdBy: styleDetail?.createdBy,
      likeInfo: styleDetail?.likeInfo,
      shareCount: styleDetail?.shareCount,
      ...v,
      description: v.title,
      thumbnailId: v.subContentItems?.find(x=>x.thumbnailId!==null)?.thumbnailId,
    }));
  }, [styleDetail]);

  const constructionCategories = React.useMemo(() => {
    if (!commonCodes) return [];
    let constructionTypes = commonCodes?.tree['COTP']?.map((v) => ({
      value: v.code,
      label: v.label === '전체' ? '전체공사' : v.label,
    }));
    constructionTypes = [{ value: null, label: '전체' }, ...constructionTypes];
    return constructionTypes;
  }, [commonCodes]);
  React.useEffect(() => {
    if (!!data) {
      const idList_ = data?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [data]);
  const columns = [
    {
      title: '선택',
      dataIndex: 'data',
      key: 'data',
      width: 40,
      align: 'center',
      render: ({ id }) => {
        return (
          <Checkbox
            key={`${id}_check`}
            checked={checkedList.includes(id)}
            onClick={() => {
              if (checkedList.includes(id)) {
                setCheckedList(checkedList.filter((v) => v !== id));
              } else {
                setCheckedList([...checkedList, id]);
              }
            }}
          />
        );
      },
    },
    {
      title: '순서',
      dataIndex: 'data',
      key: 'index',
      width: 40,
      align: 'center',
      // sorter: true,
      render: (v, r, i) => {
        return <div style={{ display: 'flex', justifyContent: 'center' }}>{data?.length - i}</div>;
      },
    },
    {
      title: '썸네일',
      dataIndex: 'data',
      key: 'image',
      width: 40,
      align: 'center',
      render: ({ thumbnailId, id }) => (
        <Thumbnail
          onDelete={() => {
            handleDeleteItems([id]);
          }}
          id={thumbnailId}
        />
      ),
    },
    {
      title: <TableColHeader title="제목" subTitle="공사타입" />,
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      width: 150,
      render: (data, r) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span
              style={{
                color: '#000',
                fontWeight: '600',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {r?.title}
            </span>
            <span style={{ color: '#767676' }}>{commonCodes?.map[r?.constructionType]}</span>
          </div>
        );
      },
    },
    {
      title: '등록자',
      dataIndex: 'createdBy.nickname',
      key: 'createdBy.nickname',
      sorter: true,
      children: [
        {
          dataIndex: 'createdAt',
          title: '등록일시',
          key: 'createdAt',
          width: 100,
          sorter: true,
          render: (d, r) => {
            console.log();
            return (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span style={{ color: '#767676' }}>{r?.createdBy?.nickname}</span>
                <span style={{ color: '#767676' }}>{moment(r?.createdAt).format('YYYY-MM-DD HH:mm')}</span>
              </div>
            );
          },
        },
      ],
    },
    {
      title: '승인자',
      dataIndex: 'handleConfirmationBy',
      key: 'handleConfirmationBy',
      sorter: true,
      children: [
        {
          dataIndex: 'handleConfirmAt',
          title: '승인일시',
          key: 'handleConfirmAt',
          width: 100,
          sorter: true,
          render: (d, r) => {
            return (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span style={{ color: '#767676' }}>{r?.handleConfirmationBy?.nickname}</span>
                <span style={{ color: '#767676' }}>
                  {r?.isConfirmed
                    ? r?.handleConfirmAt
                      ? moment(r?.handleConfirmAt).format('YYYY-MM-DD HH:mm')
                      : '최초승인'
                    : '-'}
                </span>
              </div>
            );
          },
        },
      ],
    },
    // {
    //   title: <TableColHeader title="승인자" subTitle="승인일시" />,
    //   dataIndex: 'confirmedBy',
    //   key: 'confirmedBy',
    //   width: 100,
    //   render: ({ handleConfirmBy, handleConfirmAt, isConfirmed }) => {
    //     return (
    //       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    //         <span style={{ color: '#767676' }}>{handleConfirmBy?.nickname}</span>
    //         <span style={{ color: '#767676' }}>
    //           {isConfirmed ? (handleConfirmAt ? moment(handleConfirmAt).format('YYYY-MM-DD HH:mm') : '최초승인') : '-'}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    {
      title: '좋아요',
      dataIndex: 'likeCount',
      key: 'likeCount',
      width: 60,
      sorter: true,
      showSorterTooltip: false,
      align: 'center',
      render: (v, r) => (
        <button
          onClick={() => {
            window.open(`/like/detail/${r.id}`, '_blank', '');
          }}
          style={{
            textDecoration: 'underline',
            background: 'none',
          }}
        >
          {v}
        </button>
      ),
    },
    {
      title: '댓글',
      dataIndex: 'commentCount',
      key: 'commentCount',
      width: 50,
      sorter: true,
      showSorterTooltip: false,
      align: 'center',
      render: (v, r) => (
        <button
          onClick={() => {
            window.open(`/comment/detail/${r.id}/CONTENT`, '_blank', '');
          }}
          style={{
            textDecoration: 'underline',
            background: 'none',
          }}
        >
          {v ?? '-'}
        </button>
      ),
    },
    {
      title: '견적',
      dataIndex: 'estimateCount',
      key: 'estimateCount',
      width: 50,
      sorter: true,
      showSorterTooltip: false,
      align: 'center',
      render: (v, r) => (
        <button
          onClick={() => {
            window.open(`/saveestimate/detail/${r.id}`, '_blank', '');
          }}
          style={{
            textDecoration: 'underline',
            background: 'none',
          }}
        >
          {v ?? '-'}
        </button>
      ),
    },
    {
      title: '공사도움받기',
      dataIndex: 'constructionRequestCount',
      key: 'constructionRequestCount',
      width: 100,
      sorter: true,
      showSorterTooltip: false,
      align: 'center',
      render: (v, r) => (
        <button
          onClick={() => {
            window.open(`/proposal/detail/${r.id}`, '_blank', '');
          }}
          style={{
            textDecoration: 'underline',
            background: 'none',
          }}
        >
          {v ?? '-'}
        </button>
      ),
    },
    {
      title: <TableColHeader title="추천여부" subTitle="추천/미추천" />,
      dataIndex: 'isSuggest',
      key: 'isSuggest',
      sorter: true,
      width: 80,
      align: 'center',
      render: (_, record) => {
        const { data } = record;
        const { isSuggest, id } = data;
        return (
          <div className={styles.switchWrapper}>
            <Switch
              checked={!!isSuggest}
              onClick={() =>
                restApi.put(`/contents/suggest/${id}?isSuggest=${isSuggest === true ? false : true}`).then((res) => {
                  queryClient.invalidateQueries(CONTENTS_KEY);
                })
              }
            />
          </div>
        );
      },
    },
    {
      title: <TableColHeader title="승인여부" subTitle="승인/대기" />,
      dataIndex: 'isConfirmed',
      key: 'isConfirmed',
      width: 80,
      align: 'center',
      sorter: true,
      render: (data, record) => {
        const { confirmedBy, id, isConfirmed } = record;
        return (
          <div className={styles.switchWrapper}>
            <Switch
              checked={isConfirmed !== false}
              onClick={() =>
                restApi
                  .put(`/contents`, {
                    ids: [id],
                    isConfirmed: isConfirmed !== false ? false : true,
                  })
                  .then((res) => {
                    queryClient.invalidateQueries(CONTENTS_KEY);
                  })
              }
            />
          </div>
        );
      },
    },
  ];

  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 280);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);
  const onRow = React.useCallback(
    (record) => {
      return {
        onClick: (event) => {
          setSelectedId(record.id);
        },
      };
    },
    [styleData],
  );

  const handleDeleteItems = React.useCallback(
    (ids) => {
      Modal.confirm({
        content: '정말 삭제하시겠습니까?',
        okText: '확인',
        cancelText: '취소',
        onOk: () => {
          restApi
            .delete('/contents', {
              data: { ids },
            })
            .then((res) => {
              queryClient.invalidateQueries(CONTENTS_KEY);
              Modal.success({
                content: '삭제되었습니다.',
                okText: '확인',
              });
            });
        },
      });
    },
    [data],
  );

  const handleConfirmed = React.useCallback(
    (type) => {
      const ids = checkedList;
      restApi
        .put(`/contents`, {
          ids,
          isConfirmed: type,
        })
        .then((res) => {
          queryClient.invalidateQueries(CONTENTS_KEY);
        });
    },
    [checkedList],
  );
  return (
    <>
      <div>
        <PageHeader className="site-page-header" title="스타일 관리" style={{ paddingBottom: 10 }} />
        <ExtraWrapper>
          {/* 필터 윗줄 */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 4.5,
              justifyContent: 'space-between',
              width: '100%',
              position: 'relative',
            }}
          >
            <UpperLine
              containerStyle={{ width: '65%' }}
              listKey={CONTENTS_KEY}
              detailKey={CONTENT_DETAIL_KEY}
              childrenRight={
                <div
                  style={{
                    // width: '30%',
                    marginLeft: 10,
                    justifyContent: 'flex-end',
                    display: 'flex',
                    position: 'relative',
                  }}
                ></div>
              }
            />
            <div style={{ display: 'flex', gap: '10px' }}>
              <RoundButton
                onClick={() => {
                  setIsEdit(true);
                  setVisibleModifyModal(true);
                }}
                label="수정하기"
                containerStyle={{ width: '100px' }}
              />
              <RoundButton
                onClick={() => {
                  setIsEdit(false);
                  setVisibleModifyModal(true);
                }}
                reverse
                label="새글쓰기"
                containerStyle={{ width: '100px' }}
              />
            </div>
          </div>
          {/* 필터 아랫줄 */}
          <LowerLine
            left={
              <>
                <div
                  onClick={() => {
                    handleDeleteItems(checkedList);
                  }}
                  style={{ cursor: 'pointer', marginLeft: 40 }}
                  className={styles.filterWrapper}
                >
                  <img
                    src={require('../../../assets/delete.png')}
                    alt="delete"
                    style={{
                      width: '18.2px',
                      height: '21.2px',
                    }}
                  />
                  <span className={styles.filterText}>항목삭제</span>
                </div>
                <ModifyAllButton
                  showText={'승인'}
                  stopText={'대기'}
                  customStyle={{ marginLeft: 40 }}
                  showAction={() => {
                    handleConfirmed(true);
                  }}
                  stopAction={() => {
                    handleConfirmed(false);
                  }}
                />
              </>
            }
            containerStyle={{ width: '65%' }}
            onCheck={() => {
              if (idList.length === checkedList.length) {
                setCheckedList([]);
              } else {
                setCheckedList(idList);
              }
            }}
            checked={idList.length === checkedList.length}
          >
            <div style={{ display: 'flex' }}>

            </div>
          </LowerLine>
        </ExtraWrapper>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PaginateTable
            dataSource={data}
            columns={columns}
            scroll={{ y: tableHeight - 40 }}
            data={styleData}
            setParams={setParams}
            page={params?.page}
            setPage={(v) =>
              setParams(
                produce((draft) => {
                  draft.page = v;
                }),
              )
            }
            pageSize={params?.limit}
            setPageSize={(v) =>
              setParams(
                produce((draft) => {
                  draft.limit = v;
                }),
              )
            }
            onRow={onRow}
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              width: '65%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
            }}
          />
          <div
            style={{
              width: 550,
              paddingLeft: 20,
              overflowY: 'scroll',
              overflowX: 'hidden',
              height: document.body.clientHeight - 240,
              paddingBottom: 15,
            }}
          >
            <h3
              style={{
                fontSize: '16px',
                fontWeight: '600',
                color: '#000',
              }}
            >
              스타일 정보
            </h3>
            <ContentSlider>
              {detailData?.map((v) => (
                <ContentCard setVisibleDetailModal={setVisibleDetailModal} item={v} commonCodes={commonCodes} />
              ))}
            </ContentSlider>
            <CommonStatistics />
          </div>
        </div>
      </div>

      <WriteContentModal
        visible={visibleModifyModal}
        setVisible={setVisibleModifyModal}
        handleCancel={() => {
          setVisibleModifyModal(false);
        }}
        type="styles"
        isEdit={isEdit}
        selectedId={selectedId}
      />
      <ContentDetailModal
        type="styles"
        contentId={selectedId}
        visible={visibleDetailModal}
        setVisible={setVisibleDetailModal}
        handleOk={() => {}}
        handleCancel={() => setVisibleDetailModal(false)}
      />
    </>
  );
};

export default CollectionsMagazinesIndex;
