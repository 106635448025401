import { CloseCircleFilled, SyncOutlined } from '@ant-design/icons';
import { DatePicker, Modal, PageHeader } from 'antd';
import produce from 'immer';
import React from 'react';
import { useSelector } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';
import { restApi } from '../../../apis';
import { useGetMainSectionDetail } from '../../../apis/queries';
import { getFileId, parseImageUri } from '../../../common/utils';
import PaginateTable from '../../../components/table/PaginateTable';
import useBlockRefresh from '../../../hooks/useBlockRefresh';

const OperationMainSectionCompose = () => {
  useBlockRefresh();
  const location = useLocation();
  const navigate = useNavigate();
  const { commonCodes } = useSelector((state) => state.common);

  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: null,
      sort: null,
    }),
    [],
  );
  const [page, setPage] = React.useState(initialParams.page);
  const [pageSize, setPageSize] = React.useState(20);
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 60 - 280);

  const [items, setItems] = React.useState([]);

  const [detailModify, setDetailModify] = React.useState({
    title: '',
    description: '',
    imageId: null,
    sortNum: null,
    renderType: 'RDTP001',
    contentGroupId: undefined,
    isShow: true,
    color: null,
    fontSize: null,
    mainSectionItems: [],
    lineHeight: null,
  });
  const [modalContent, setModalContent] = React.useState([
    {
      title: '타일',
      order: 1,
    },
  ]);
  const loadData = async () => {
    const { data } = await restApi.get(`/main-sections/${location?.state?.id}`);
    setDetailModify(data);
  };
  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    loadData();
    window.addEventListener('resize', fn);

    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    if (imageId) {
      setDetailModify(
        produce((draft) => {
          draft.imageId = imageId;
        }),
      );
    }
  };
  const onRow = (record, index) => {
    return {
      onClick: () => {
        navigate('/operation/main_section/section/detail', {
          state: { detailId: location?.state?.id, contentGroupId: record.contentGroupId, index: index },
        });
      },
    };
  };
  return (
    <div>
      <PageHeader className="site-page-header" title="메인 섹션 > 항목구성" style={{ paddingBottom: 10 }} />
      <div style={{ height: 50, textAlign: 'right', marginTop: 10 }}>
        {(detailModify?.renderType==='RDTP006'&&detailModify?.mainSectionItems?.length===0)||(detailModify?.renderType==='RDTP005'&&detailModify?.mainSectionItems?.length===0)||(detailModify?.renderType!=='RDTP006'&&detailModify?.renderType!=='RDTP005')&&
        <button
          style={{ padding: '3px 18px', borderRadius: '20px', border: '1.5px solid #1e3d83' }}
          onClick={() => {
            if (location?.state?.id) {
              navigate('/operation/main_section/section/detail', {
                state: { detailId: location?.state?.id },
              });
            } else {
              Modal.warning({
                title: '실패',
                content: '빈 섹션에는 항목을 추가할 수 없습니다.',
              });
            }
          }}
        >
          항목추가
        </button>
        }
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{ width: '32%', overflowY: 'scroll', overflowX: 'hidden', height: document.body.clientHeight - 240 }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              padding: 20,
              backgroundColor: 'white',
              height: 900,
            }}
          >
            <div
              style={{
                width: 400,
                height: 200 + modalContent.length * 76,
                backgroundColor: 'white',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 40,
                padding: 20,
              }}
            >
              <div>
                <select
                  style={{
                    width: '100%',
                    padding: '5px 0',
                    marginTop: 30,
                    border: 'none',
                    borderBottom: '1px solid #d9d9d9',
                  }}
                  value={detailModify?.renderType}
                  onChange={(e) => {
                    setDetailModify(
                      produce((draft) => {
                        draft.renderType = e.target.value;
                      }),
                    );
                  }}
                >
                  {commonCodes?.tree['RDTP']?.map((el, index) => {
                    return (
                      <option key={`layout option ${index}`} value={el.code}>
                        {el.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <input
                  type="text"
                  style={{ width: '85%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '30px 0' }}
                  placeholder="제목을 입력해주세요."
                  value={detailModify?.title}
                  onChange={(e) => {
                    setDetailModify(
                      produce((draft) => {
                        draft.title = e.target.value;
                      }),
                    );
                  }}
                />
                <input
                  type="text"
                  style={{ width: '10%', border: 'none', borderBottom: '1px solid #d9d9d9', margin: '30px 0' }}
                  placeholder="순서"
                  value={detailModify?.sortNum}
                  onChange={(e) => {
                    setDetailModify(
                      produce((draft) => {
                        draft.sortNum = e.target.value;
                      }),
                    );
                  }}
                />
              </div>
              <div style={{ display: 'flex', borderBottom: '1px solid #d9d9d9' }}>
                <input
                  type="text"
                  style={{ width: '90%', border: 'none' }}
                  placeholder="설명을 입력해주세요."
                  value={detailModify?.description}
                  maxLength={79}
                  onChange={(e) => {
                    setDetailModify(
                      produce((draft) => {
                        draft.description = e.target.value;
                      }),
                    );
                  }}
                />
                <span style={{ color: '#767676', fontSize: 12, marginLeft: 10 }}>
                  {detailModify?.description?.length}/80
                </span>
              </div>
            </div>
            <p>대표 이미지</p>
            <div style={{ position: 'relative' }}>
              <button
                style={{
                  width: 50,
                  height: 50,
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  padding: 10,
                  position: 'absolute',
                  top: 10,
                  right: 30,
                  borderRadius: '50%',
                }}
              >
                <input
                  type="file"
                  id="fileInput"
                  style={{
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    cursor: 'pointer',
                  }}
                  onChange={handleChangeImage}
                />
                <SyncOutlined style={{ fontSize: 30, color: '#1e3d83' }} />
              </button>
              <img
                src={parseImageUri(detailModify?.imageId)}
                alt=""
                style={{ width: 360, height: 360, backgroundColor: '#d9d9d9', borderRadius: 10 }}
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <button
                style={{ marginBottom: 20, padding: '3px 18px', borderRadius: '20px', border: '1.5px solid #1e3d83' }}
                onClick={() => {
                  Modal.confirm({
                    title: '저장',
                    content: '이 값으로 저장하시겠습니까?',
                    okText: '저장',
                    cancelText: '취소',
                    async onOk() {
                      try {
                        if (location?.state?.id) {
                          await restApi.put(`/main-sections/${location?.state?.id}`, detailModify);
                        } else {
                          await restApi.post(`/main-sections`, detailModify);
                        }
                        Modal.confirm({
                          title: '완료',
                          content: '완료되었습니다',
                          onOk() {
                            navigate('/operation/main_section');
                          },
                          cancelButtonProps: {
                            style: { display: 'none' },
                          },
                          okText: '확인',
                        });
                      } catch (error) {
                        Modal.warning({
                          title: '오류',
                          content: error.response.data.message,
                        });
                      }
                    },
                  });
                }}
              >
                저장
              </button>
            </div>
          </div>
        </div>
        <PaginateTable
          dataSource={detailModify?.mainSectionItems}
          scroll={{ y: tableHeight }}
          style={{
            borderRadius: 10,
            overflow: 'hidden',
            width: '65%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
          page={page}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          onRow={onRow}
          columns={[
            {
              title: '이미지',
              dataIndex: 'imageId',
              key: 'imageId',
              width: 30,
              align: 'center',
              render: (data, record, index) => {
                return (
                  <div key={index} style={{ position: 'relative' }}>
                    <button
                      style={{
                        position: 'absolute',
                        top: -2,
                        right: 10,
                        zIndex: 10,
                        padding: 0,
                        width: 18,
                        height: 18,
                        border: 'none',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(255,255,255,1)',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        Modal.confirm({
                          title: '삭제',
                          content: '해당 항목을 삭제하시겠습니까?',
                          okText: '확인',
                          cancelText: '취소',
                          async onOk() {
                            const putData = detailModify?.mainSectionItems;
                            putData.splice(index, 1);
                            try {
                              await restApi.put(`/main-sections/${location?.state?.id}/items`, {
                                mainSectionItems: putData,
                              });
                              Modal.info({
                                title: '완료',
                                content: '삭제가 완료되었습니다',
                              });
                              loadData();
                            } catch (error) {
                              Modal.warning({
                                title: '실패했습니다',
                                content: error.response.data.message,
                              });
                            }
                          },
                        });
                      }}
                    >
                      <CloseCircleFilled style={{ fontSize: 18, color: '#1e3d83' }} />
                    </button>
                    <img style={{ width: 50, height: 50 }} src={parseImageUri(record.imageId)} alt="섹션 이미지" />
                  </div>
                );
              },
            },
            {
              title: '제목',
              dataIndex: 'title',
              key: 'title',
              width: 200,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>{record.title}</p>
                  </div>
                );
              },
            },
            {
              title: '순서',
              dataIndex: 'sortNum',
              key: 'sortNum',
              width: 30,
              align: 'center',
              render: (data, record) => {
                return (
                  <div>
                    <p>{data}</p>
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default OperationMainSectionCompose;
