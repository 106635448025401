import { Checkbox, Modal } from 'antd';
import React from 'react';
import RoundButton from '../button/RoundButton';
import { useGetConstructionTemplates } from '../../apis/queries';
import PaginateTable from '../table/PaginateTable';
import { useSelector } from 'react-redux';
import ModifyMaterialModal from './ModifyMaterialModal';
import produce from 'immer';
const styles = {
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 25px',
  },
  header: {
    height: '50px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px 20px 0px 20px',
  },
  tabWrapper: {
    width: '100%',
    overFlow: 'scroll',
    display: 'flex',
    gap: '20px',
    marginTop: '50px',
  },
};
const ModifyEstimateModal = React.memo(({ area, visible, setVisible, handleOk, handleCancel, form, setForm }) => {
  const { commonCodes } = useSelector((s) => s.common);
  const [selectedId, setSelectedId] = React.useState(null);
  const [excludesIds, setExcludesIds] = React.useState([]); // [id, id, id
  const [selectedTab, setSelectedTab] = React.useState(null);
  const [visibleMaterialModal, setVisibleMaterialModal] = React.useState(false);
  const { data: templatesFull } = useGetConstructionTemplates({
    enabled: !!visible && !!area,
  });

  const tabs = React.useMemo(() => {
    if (!templatesFull) return [];
    return templatesFull?.find((v) => v.area.code === area?.split('_')[0])?.category?.map((v) => v);
  }, [templatesFull, area]);

  React.useEffect(() => {
    if (tabs) {
      setSelectedTab(tabs[0]?.categoryId);
    }
  }, [tabs]);

  const dataSource = React.useMemo(() => {
    const tab = tabs?.find((v) => v.categoryId === selectedTab);
    return tab?.children?.flatMap((v) =>
      v.templates?.flatMap((v2) => ({
        title: v.categoryName,
        details: v2.name,
        buttonOptions: {
          id: v2.id,
          productSelectable: v2.isProductSelectable,
          selectable: v2.isSelectable,
          excludesIds: v2.excludesIds,
          children: v2.children,
        },
      })),
    );
  }, [tabs, selectedTab]);

  const columns = [
    {
      title: '공종',
      dataIndex: 'title',
      key: 'title',
      width: 40,
      align: 'center',
      render: (v) => (
        <span
          style={{
            fontSize: '12px',
            color: '#767676',
          }}
        >
          {v}
        </span>
      ),
    },
    {
      title: '세부항목',
      dataIndex: 'details',
      key: 'details',
      width: 200,
      align: 'left',
      render: (v) => (
        <span
          style={{
            fontSize: '14px',
            color: '#000',
            fontWeight: '600',
          }}
        >
          {v}
        </span>
      ),
    },
    {
      title: commonCodes?.map[area?.split('_')[0]],
      dataIndex: 'buttonOptions',
      key: 'buttonOptions',
      width: 40,
      align: 'left',
      render: ({ id, productSelectable,selectable, excludesIds, children }) => {
        const target = form?.subCreates.find((v) => v.area === area);
        const isActive = !!target.materialAndProcesses?.find((v) => v.templateId === id);

        return (
          <>
            {!productSelectable ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Checkbox
                  checked={isActive}
                  disabled={!selectable}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked) {
                      // 추가
                      setForm(
                        produce((draft) => {
                          const prevMaterials = draft.subCreates
                            .find((v) => v.area === area)
                            .materialAndProcesses?.map((v) => v?.templateId);

                            const mapr = form.subCreates.find((v) => v.area === area).materialAndProcesses;

                          let newRecords = [...children, ...prevMaterials, id];

                          newRecords = newRecords?.filter((v) => !excludesIds?.includes(v));
                          newRecords = Array.from(new Set(newRecords));
                          newRecords = newRecords?.map((v) => ({
                            templateId: v,
                            productId: null,
                            qty: null,
                            ...((mapr?.find(y => y.templateId === v)) || {}),
                          }));

                          draft.subCreates.find((v) => v.area === area).materialAndProcesses = newRecords;
                        }),
                      );
                    } else {
                      // 제거
                      setForm(
                        produce((draft) => {
                          let deleteIds = [id];

                          deleteIds = Array.from(new Set(deleteIds));
                          const target = draft.subCreates.find((v) => v.area === area).materialAndProcesses;
                          children.forEach(x=>{
                          const length = target.find(v=>v?.children?.indclues(x)).length
                            if(length===1){
                              deleteIds.push(x);
                            }
                          })
                          draft.subCreates.find((v) => v.area === area).materialAndProcesses = target.filter(
                            (v) => !deleteIds.includes(v.templateId),
                          );
                          return draft;
                        }),
                      );
                    }
                  }}
                />
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <RoundButton
                  reverse={isActive}
                  onClick={() => {
                    setSelectedId(id);
                    setExcludesIds(excludesIds)
                    setVisibleMaterialModal(true);
                  }}
                  containerStyle={{}}
                  label={'자재변경'}
                />
              </div>
            )}
          </>
        );
      },
    },
  ];

  return (
    <Modal
      // mask={false}
      width={840}
      bodyStyle={{
        width: '100%',
        minWidth: 840,
        height: document.body.clientHeight - 200,
        overflowY: 'scroll',
        backgroundColor: '#f9f9f9',
        padding: '0px',
      }}
      closable={false}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      okText={'확인'}
      footer={null}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div style={styles.modalContainer}>
        <div style={styles.header}>
          <RoundButton
            onClick={handleCancel}
            label="취소"
            containerStyle={{
              width: '100px',
              marginRight: '10px',
            }}
          />
          <RoundButton
            onClick={handleCancel}
            reverse
            label="저장"
            containerStyle={{
              width: '100px',
            }}
          />
        </div>
        <div style={styles.bodyWrapper}>
          <h3 style={styles.title}>견적설정</h3>
          <div style={styles.tabWrapper}>
            {tabs.map((v) => (
              <span
                onClick={() => {
                  setSelectedTab(v.categoryId);
                }}
                style={{
                  cursor: 'pointer',
                  fontSize: '14px',
                  color: selectedTab === v.categoryId ? '#1e3d83' : '#767676',
                  fontWeight: selectedTab === v.categoryId ? '600' : 'normal',
                }}
              >
                {v?.categoryName}
              </span>
            ))}
          </div>
          <PaginateTable
            dataSource={dataSource}
            data={dataSource}
            columns={columns}
            style={{
              borderRadius: 10,
              width: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
              paddingBottom: '100px',
              marginTop: '30px',
            }}
            pagination={false}
          />
        </div>
      </div>
      <ModifyMaterialModal
        visible={visibleMaterialModal}
        setVisible={setVisibleMaterialModal}
        handleOk={() => {}}
        handleCancel={() => {
          setVisibleMaterialModal(false);
          setSelectedId(null);
          setExcludesIds([])
        }}
        excludesIds={excludesIds}
        selectedId={selectedId}
        form={form}
        setForm={setForm}
        area={area}
      />
    </Modal>
  );
});

export default ModifyEstimateModal;
