import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Checkbox, Form, Input, InputNumber, Modal, PageHeader, Switch } from 'antd';
import qs from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import CustomCheckBox from '../../../components/button/CustomCheckBox';
import RoundButton from '../../../components/button/RoundButton';
import PaginateTable from '../../../components/table/PaginateTable';
import {
  ExtraWrapper,
  FilterButton,
  FilterInput,
  FilterSelect,
  FilterWhiteButton,
  TableCheckbox,
} from '../../../components/template/page';
import ItemDetail from '../../../components/card/itemDetail/ItemDetail';
import { useGetProductCategories, useGetProductList } from '../../../apis/queries';
import { Option } from 'antd/lib/mentions';
import produce from 'immer';
import Thumbnail from '../../../components/image/Thumbnail';
import { findCategoryDeps, isSwitchChecked } from '../../../common/utils';
import TableColHeader from '../../../components/table/TableColHeader';
import { restApi } from '../../../apis';
import { useQueryClient } from 'react-query';
import { PRODUCT_DETAIL_KEY, PRODUCT_LIST_KEY } from '../../../apis/queryKeys';
import useUpperLine from '../../../hooks/useUpperLine';
import { useSelector } from 'react-redux';
import LowerLine from '../../../components/LowerLine';
import ModifyAllButton from '../../../components/button/ModifyAllButton';
const styles = {
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
  },
  filterText: {
    marginLeft: '4px',
    fontSize: '14px',
    color: '#767676',
  },
  switchWrapper: {
    transform: 'scaleX(-1)',
  },
};
const ProductsItemsIndex = (props) => {
  const { commonCodes } = useSelector((state) => state.common);
  const { data: productCategories } = useGetProductCategories();

  //필터 윗줄 생성(keword, filter params 포함, query param 에 spread 구문으로 삽입)
  const filters = React.useMemo(() => {
    const constructionTypeItems = productCategories?.tree?.map((v) => [v.id, v.name]);
    constructionTypeItems?.unshift([null, '전체공사']);
    const isConfirmedItems = [
      [null, '전체'],
      ['ACTIVE', '게시중'],
      ['READY', '대기중'],
    ];
    return [
      { title: '상품카테고리', key: 'categoryId', options: constructionTypeItems },
      { title: '사용구분', key: 'state', options: isConfirmedItems },
    ];
  }, [commonCodes, productCategories]);
  const { params: upperParam, UpperLine } = useUpperLine(filters);
  const initialParams = React.useMemo(
    () => ({
      limit: 50,
      page: 0,
      order: 'DESC',
      keyword: null,
      categoryId: null,
      state: null,
    }),
    [],
  );

  const [percentage, setPercentage] = useState({ supplyPricePercentage: 0, pricePercentage: 0 });
  const [modalVisible, setModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const [params, setParams] = React.useState(initialParams);
  const [keyword, setKeyword] = React.useState(null);
  const [checkedList, setCheckedList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState(null);

  const { data: productList } = useGetProductList({
    params: { ...params, ...upperParam },
    config: {
      onSuccess: (data) => {
        if (!selectedId) {
          setSelectedId(data?.content[0]?.id);
        }
      },
    },
  });
  const data = React.useMemo(() => {
    return productList?.content?.map((v) => ({
      data: v,
      ...v,
    }));
  }, [productList]);
  const productCategoryItems = React.useMemo(() => {
    if (!productCategories) return [];
    let categories = productCategories?.tree?.map((v) => ({
      value: v.id,
      label: v.name,
    }));
    categories.unshift({ value: null, label: '전체' });
    return categories;
  }, [productCategories]);

  const productStateItems = React.useMemo(
    () => [
      {
        value: null,
        label: '전체',
      },
      {
        value: 'READY',
        label: '대기중',
      },
      {
        value: 'ACTIVE',
        label: '게시중',
      },
    ],
    [],
  );

  const columns = [
    {
      title: '선택',
      dataIndex: 'id',
      key: 'id',
      width: 20,
      align: 'center',
      render: (v) => (
        <Checkbox
          checked={checkedList?.includes(v)}
          onClick={() => {
            if (checkedList?.includes(v)) {
              setCheckedList(checkedList.filter((id) => id !== v));
            } else {
              setCheckedList([...checkedList, v]);
            }
          }}
        />
      ),
    },
    {
      title: '형상',
      dataIndex: 'thumbnailId',
      key: 'thumbnailId',
      width: 20,
      align: 'center',
      render: (v) => <Thumbnail id={v} />,
    },
    {
      title: '주기',
      dataIndex: 'data',
      key: 'data',
      width: 80,
      align: 'center',
      render: ({ makerName, baseSize, categoryId }) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1px' }}>
            <span
              style={{
                fontSize: '12px',
                color: '#767676',
                textAlign: 'left',
              }}
            >{`A. ${makerName}`}</span>
            {baseSize && (
              <span
                style={{
                  fontSize: '12px',
                  color: '#767676',
                  textAlign: 'left',
                }}
              >{`B. ${baseSize}`}</span>
            )}
            {categoryId && (
              <span
                style={{
                  fontSize: '12px',
                  color: '#767676',
                  textAlign: 'left',
                }}
              >{`C. ${findCategoryDeps(productCategories?.tree, categoryId)[0]}`}</span>
            )}
          </div>
        );
      },
    },
    {
      title: <TableColHeader title={'상품명'} subTitle={'바바품번'} />,
      dataIndex: 'data',
      key: 'data',
      width: 100,
      align: 'left',
      render: ({ name, serial }) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
          <span
            style={{
              fontSize: '12px',
              fontWeight: '600',
              color: '#000',
            }}
          >
            {name}
          </span>
          <span
            style={{
              fontSize: '12px',
              color: '#767676',
            }}
          >
            {serial}
          </span>
        </div>
      ),
    },
    {
      title: <TableColHeader title={'등록자'} subTitle={'등록일시'} />,
      dataIndex: 'data',
      key: 'data',
      width: 80,
      align: 'center',
      render: ({ createdAt, createdBy }) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
          <span
            style={{
              fontSize: '12px',
              color: '#767676',
            }}
          >
            {createdBy?.nickname ?? '-'}
          </span>
          <span
            style={{
              fontSize: '12px',
              color: '#767676',
            }}
          >
            {moment(createdAt).format('YYYY-MM-DD HH:mm')}
          </span>
        </div>
      ),
    },
    {
      title: '유통사 제공단가',
      dataIndex: 'cost',
      key: 'cost',
      width: 40,
      align: 'center',
      render: (v) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span
              style={{
                fontSize: '12px',
                color: '#1e3d83',
                fontWeight: 'bold',
              }}
            >
              {v.toLocaleString()}
            </span>
            <span
              style={{
                fontSize: '10px',
                color: '#767676',
                alignSelf: 'flex-end',
                marginLeft: '2px',
              }}
            >
              원
            </span>
          </div>
          <span
            style={{
              fontSize: '12px',
              color: '#767676',
            }}
          >
            {v.toLocaleString()}
          </span>
        </div>
      ),
    },
    {
      title: '시공사 공급단가',
      dataIndex: 'data',
      key: 'data',
      width: 40,
      align: 'center',
      render: ({ supplyPricePercentage, supplyPrice }) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span
              style={{
                fontSize: '12px',
                color: '#1e3d83',
                fontWeight: 'bold',
              }}
            >
              {(supplyPrice ?? 0).toLocaleString()}
            </span>
            <span
              style={{
                fontSize: '10px',
                color: '#767676',
                alignSelf: 'flex-end',
                marginLeft: '2px',
              }}
            >
              원
            </span>
          </div>
          <span
            style={{
              fontSize: '12px',
              color: '#767676',
            }}
          >
            ({supplyPricePercentage ?? 0}%)
          </span>
        </div>
      ),
    },
    {
      title: '소비자가',
      dataIndex: 'data',
      key: 'data',
      width: 40,
      align: 'center',
      render: ({ pricePercentage, price }) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span
              style={{
                fontSize: '12px',
                color: '#1e3d83',
                fontWeight: 'bold',
              }}
            >
              {(price ?? 0).toLocaleString()}
            </span>
            <span
              style={{
                fontSize: '10px',
                color: '#767676',
                alignSelf: 'flex-end',
                marginLeft: '2px',
              }}
            >
              원
            </span>
          </div>
          <span
            style={{
              fontSize: '12px',
              color: '#767676',
            }}
          >
            ({pricePercentage ?? 0}%)
          </span>
        </div>
      ),
    },
    {
      title: <TableColHeader title="추천여부" subTitle="추천/미추천" />,
      dataIndex: 'data',
      key: 'data',
      width: 80,
      align: 'center',
      render: (_, record) => {
        const { isSuggest, id } = record;
        return (
          <div className={styles.switchWrapper}>
            <Switch
              checked={!!isSuggest}
              onClick={() =>
                restApi
                  .put(`/products/manage/${id}/suggest?isSuggest=${isSuggest === true ? false : true}`)
                  .then((res) => {
                    queryClient.invalidateQueries(PRODUCT_LIST_KEY);
                  })
              }
            />
          </div>
        );
      },
    },
    {
      title: <TableColHeader title="사용가능" subTitle="게시중/대기중" />,
      dataIndex: 'data',
      key: 'data',
      width: 40,
      align: 'center',
      render: ({ state, id }) => (
        <div style={{ transform: 'rotate(180deg)' }}>
          <Switch
            checked={state === 'ACTIVE'}
            onChange={(v) => {
              handleChangeState([id], v ? 'ACTIVE' : 'READY');
            }}
          />
        </div>
      ),
    },
  ];

  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 320);
  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 280);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  React.useEffect(() => {
    if (!!data) {
      const idList_ = data?.map((v) => v.id);
      setIdList(idList_);
    }
  }, [data]);
  const [form] = Form.useForm();
  const handleChangeState = (ids, state) => {
    if (ids?.length === 0) return alert('최소 한개이상의 상품을 선택해주세요.');
    // const query = `${ids.map((v) => `ids=${v}`).join('&')}&state=${state}`;
    try {
      restApi.put(`/products/manage/state`, { ids, state }).then((res) => {
        queryClient.invalidateQueries(PRODUCT_LIST_KEY);
      });
      setCheckedList([]);
    } catch (e) {
      alert(e);
    }
  };

  const handleChangePercentage = async () => {
    restApi.put(`/products/manage/margin`, { ids: checkedList, ...percentage }).then((res) => {
      queryClient.invalidateQueries(PRODUCT_LIST_KEY);
      setCheckedList([]);
      setModalVisible(false);
      setPercentage({ supplyPricePercentage: 0, pricePercentage: 0 });
    });
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: (e) => {
        setSelectedId(record.id);
      },
    };
  };
  return (
    <div>
      <PageHeader className="site-page-header" title="상품등록 관리" style={{ paddingBottom: 10 }} />
      <ExtraWrapper>
        {/* 필터 윗줄 */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 4.5,
            width: '100%',
            position: 'relative',
            justifyContent: 'space-between',
          }}
        >
          <UpperLine containerStyle={{width:'65%'}} listKey={PRODUCT_LIST_KEY} detailKey={PRODUCT_DETAIL_KEY} childrenRight={
            <div>
            </div>
          }/>
              <Link to={'/products/items/create'}>
                <FilterButton>상품등록</FilterButton>
              </Link>

        </div>
        {/* 필터 아랫줄 */}
        {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4.5 }}>
          <div style={{ width: 260, marginLeft: 20, display: 'flex', alignItems: 'center' }}>
            <CustomCheckBox
              onChange={() => {
                if (idList.length === checkedList.length) {
                  setCheckedList([]);
                } else {
                  setCheckedList(idList);
                }
              }}
              key={`check_all`}
              value={idList.length === checkedList.length}
              style={{ marginInline: 14 }}
              text={'모두 선택'}
            ></CustomCheckBox>
            <div style={styles.filterWrapper}>
              <span style={styles.filterText}>사용</span>
              <div style={styles.switchWrapper}>
                <Switch
                  onClick={(e) => {
                    handleChangeState(checkedList, e ? 'ACTIVE' : 'READY');
                  }}
                  checked={isSwitchChecked(
                    productList?.content?.filter((v) => v.state === 'ACTIVE')?.map((v) => v.id),
                    checkedList,
                  )}
                  style={{ marginLeft: '2px', marginRight: '2px' }}
                />
              </div>
              <span style={styles.filterText}>대기</span>
            </div>
          </div>
          <div
            onClick={() => {
              // handleDelete();
              if (checkedList?.length === 0) return alert('최소 한개이상의 상품을 선택해주세요.');
              setModalVisible(true);
            }}
            style={{ cursor: 'pointer', ...styles.filterWrapper }}
          >
            <img
              src={require('../../../assets/dollar.png')}
              alt="delete"
              style={{
                width: '13.2px',
                height: '24.2px',
                marginBottom: 2,
              }}
            />
            <span style={styles.filterText}>마진비율설정</span>
          </div>
        </div> */}
        <div style={{ width: '65%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <LowerLine
            onCheck={() => {
              if (idList.length === checkedList.length) {
                setCheckedList([]);
              } else {
                setCheckedList(idList);
              }
            }}
            checked={idList.length === checkedList.length}
left={<div style={{display: 'flex'}}>
  <ModifyAllButton
      showText={'사용'}
      stopText={'대기'}
      customStyle={{marginLeft: 40}}
      showAction={() => {
        handleChangeState(checkedList, 'ACTIVE');
      }}
      stopAction={() => {
        handleChangeState(checkedList, 'READY');
      }}
  />
  <div
      onClick={() => {
        // handleDelete();
        if (checkedList?.length === 0) return alert('최소 한개이상의 상품을 선택해주세요.');
        setModalVisible(true);
      }}
      style={{cursor: 'pointer', ...styles.filterWrapper, marginLeft: 40}}
  >
    <img
        src={require('../../../assets/dollar.png')}
        alt="delete"
        style={{
          width: '13.2px',
          height: '24.2px',
          marginBottom: 2,
        }}
    />
    <span style={styles.filterText}>마진비율설정</span>
  </div>
</div>}
          >

          </LowerLine>
          <div style={{width: 180}}>
            <FilterSelect
                value={params?.order}
                onChange={(v) => {
                  setParams(
                      produce((draft) => {
                        draft.order = v;
                      }),
                  );
                }}
            >
              <Option value="DESC">저장일시 최신순</Option>
            </FilterSelect>
          </div>
        </div>
      </ExtraWrapper>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <PaginateTable
            dataSource={data}
            columns={columns}
            data={productList}
            page={params.page}
            scroll={{y: tableHeight - 40}}
            setPage={(v) =>
                setParams(
                    produce((draft) => {
                      draft.page = v;
                    }),
                )
            }
            pageSize={params?.limit}
            setPageSize={(v) =>
                setParams(
                    produce((draft) => {
                      draft.limit = v;
                    }),
                )
            }
            onRow={onRow}
            style={{
              borderRadius: 10,
            overflow: 'hidden',
            width: '65%',
            backgroundColor: 'white',
            boxShadow: '0px 10px 30px rgba(0,0,0,0.08)',
            paddingBottom: '100px',
          }}
        />
        <div
          style={{

            paddingLeft: 20,
            paddingBottom: 15,
            backgroundColor: '#f9f9f9',
            overflowY: 'scroll',
            height: document.body.clientHeight - 240,
            paddingBottom: 15,
          }}
        >
          <ItemDetail id={selectedId} canModify={true} />
        </div>
      </div>
      <Modal
        title={'마진비율설정'}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        closable={true}
        maskClosable={true}
        footer={
          <div style={{ width: '100%', justifyContent: 'right', display: 'flex' }}>
            <RoundButton onClick={handleChangePercentage} containerStyle={{ width: '100px' }} label="설정하기" />
          </div>
        }
      >
        <Form form={form}>
          <Form.Item style={{ margin: 20 }} labelCol={{ span: 6, offset: 0 }} label="업체가 추가비율">
            <InputNumber
              value={percentage?.supplyPricePercentage}
              onChange={(e) =>
                setPercentage(
                  produce((draft) => {
                    draft.supplyPricePercentage = e;
                  }),
                )
              }
              min={0}
              max={100}
              addonAfter={'%'}
            />
          </Form.Item>
          <Form.Item style={{ margin: 20 }} labelCol={{ span: 6, offset: 0 }} label="소비자가 추가비율">
            <InputNumber
              value={percentage.pricePercentage}
              onChange={(e) =>
                setPercentage(
                  produce((draft) => {
                    draft.pricePercentage = e;
                  }),
                )
              }
              min={0}
              max={100}
              addonAfter={'%'}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProductsItemsIndex;
