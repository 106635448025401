import {DownOutlined} from '@ant-design/icons';
import {Collapse} from 'antd';
import React from 'react';

const EstimateCard = ({title, subTitle, leftBottonButton, price, items}) => {
  const [filpActive, setFilpActive] = React.useState(false);
  const {Panel} = Collapse;
  return (
      <Collapse
          onChange={e=>setFilpActive(!filpActive)}
          bordered={false}
          ghost={true}
          style={{borderRadius: 10,overflow:'hidden',boxShadow:'0 10px 20px 0 rgba(0, 0, 0, 0.08)',backgroundColor:'#fff',marginBottom:13}}
          expandIcon={()=>(items?.length>0&&<DownOutlined
              style={{
                fontSize: 20,
                right:20,
                position: 'absolute',
                top:29,
                color:'#767676',
                transform: filpActive ? `rotate(-180deg)` : `rotate(0deg)`,
                transition: 'transform 0.3s',
              }}
          />)} expandIconPosition={'end'}>
        <Panel
            header={
          <div style={{display:'flex'}}>
          <div style={{width:15,backgroundColor:'#7eccde',height:1000,position:'absolute',left:0}}></div>
          <div style={{
            marginLeft:15,
            width:'100%',
            paddingTop: 7 ,
            paddingRight:20,
            paddingLeft: 10 ,
            // backgroundColor:'#FFF',
            display: 'flex',
            flexDirection: 'column',
          }}>
            <div style={{display:'flex'}}>
              <div style={{display:'flex',flexDirection:'column'}}>
                <p style={{
                  fontWeight:600,
                  fontSize:14,
                  color:'#767676',
                  marginBottom:2
                }}>{title}</p>
                <p style={{
                  color:'#767676', fontWeight:500
                }}>{subTitle}</p>
              </div>
            </div>
              <div style={{marginTop:18,marginBottom:12,marginLeft:1,display:'flex',justifyContent:'space-between',alignItems:'end'}}>{leftBottonButton??<div></div>} <div style={{display:'flex',alignItems:'end',color:'#767676'}}><span style={{fontWeight:'bolder',fontSize:16,color:'#1e3d83'}}>{(price??0).toLocaleString()}</span>원</div></div>
          </div>
          </div>
        }>
          {items?.length>0&&(
              <>
          <hr style={{width:380,marginTop:2,height:2,border:'none',backgroundColor:'#d9d9d9'}}/>
          <div style={{marginLeft:25,marginRight:20,marginBlock:25}}>
          {items?.map((item, index) => (<div>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',fontSize:13,color:' #767676',marginBottom:7,fontWeight:'500'}}>
              <span style={{width:'33%'}} >{item.title}</span>
              <span style={{width:'33%',textAlign:'center',fontSize:10,fontWeight:400}}>{item.option}</span>
              <div style={{width:'33%',textAlign:'right'}}><span >{(item.price??0).toLocaleString()}</span>원</div>
            </div>
          </div>))}
          </div>
              </>)

        }
        </Panel>
      </Collapse>
  );
};
export default EstimateCard;
