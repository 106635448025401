import React from 'react';
import {useSelector} from 'react-redux';
import {getFileId, parseImageUri} from '../../common/utils';
import produce from 'immer';
import {Modal} from 'antd';
import RoundButton from '../button/RoundButton';
import {InputComponent, MultiLineInput} from '../template/page';
import {
  CloseOutlined,
  DeleteOutlined, DownOutlined,
  RedoOutlined,
  UpOutlined,
} from '@ant-design/icons';
import ModifyEstimateModal from './ModifyEstimateModal';
import {restApi} from '../../apis';
import {useQueryClient} from 'react-query';
import {CONTENTS_KEY, CONTENT_DETAIL_KEY} from '../../apis/queryKeys';
import {useGetContentDetail} from '../../apis/queries';

const styles = {
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: '50px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px 20px 0px 20px',
  },
  titleImageWrapper: {
    position: 'reative',
    width: '100%',
    height: '536px',
    backgroundColor: '#d9d9d9',
  },
  titleImage: {
    width: '100%',
    height: 'inherit',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  modify_reupload: {
    padding: '7px',
    borderRadius: '50%',
    backgroundcolor: '#888',
    mixBlendMode: 'luminosity',
    filter: 'blur(0.1px)',
    color: 'white',
    fontSize: '22px',
    marginRight: '10px',
  },
  modify_style_select: {
    display: 'block',
    height: '35px',
    padding: '10px 12px 9px 10px',
    border: 'none',
    borderRadius: '5px',
    mixBlendMode: 'luminosity',
    filter: 'blur(0.2px)',
    color: 'white',
  },
  bodyWrapper: {
    padding: '0px 120px',
  },
  titleInputWrapper: {
    position: 'relative',
    marginTop: '100px',
    borderBottom: '1px solid #d9d9d9',
  },
  input: {
    width: '100%',
    height: '25px',
    border: 'none',
    backgroundColor: 'transparent',
  },
  dropdownContainer: {
    position: 'relative',
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  contentInputWrapper: {
    // marginTop: '55px',
    marginBlock:25,
    minHeight: '200px',
  },
  imageInputWrapper: {
    width: '100%',
    height: '338px',
    borderRadius: '10px',
    backgroundColor: '#d9d9d9',
    display: 'flex',
    marginBlock:25,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  buttonContainer: {
    display: 'flex',
    position: 'absolute',
    right: '20px',
    bottom: '20px',
    gap: '10px',
  },
  buttonWrapper: {
    cursor: 'pointer',
    backgroundColor: '#999',
    mixBlendMode: 'luminosity',
    filter: 'blur(0.1px)',
    minWidth: '36px',
    minHeight: '36px',
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addButtonWrapper: {
    width: '100%',
    display: 'flex',
    marginTop: '50px',
    justifyContent: 'center',
    paddingBottom: '40px',
  },
};

const ButtonWrapper = React.memo(
    ({onClick = () => {}, isRound, children, htmlFor}) => {
      return (
          <label
              onClick={onClick}
              htmlFor={htmlFor}
              style={{
                ...styles.buttonWrapper,
                borderRadius: isRound ? '50%' : '5px',
              }}
          >
            {children}
          </label>
      );
    });

const SubForm = React.memo(
    ({data, areaItems, setForm, setSelectedArea, setEstimateModalVisible}) => {

      return (
          <>
            <div style={{...styles.titleInputWrapper, marginBottom: '20px'}}>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}>
                <div style={{display: 'flex', gap: 10}}>
                  <ButtonWrapper>
                    <select
                        value={data?.area?.split('_')[0]}
                        onChange={(e) => {
                          const value = e.target.value;
                          setForm(produce((draft) => {
                            const target = draft.subCreates.find(
                                (v2) => v2.area === data?.area);
                            target.area = value
                            return draft;
                          }))
                        }}
                        style={{
                          backgroundColor: '#999999',
                          width: '60px',
                          border: 'none',
                          color: '#fff',
                        }}
                    >
                      {areaItems?.map((v) => (
                          <option value={v.value}>{v.label}</option>
                      ))}
                    </select>
                  </ButtonWrapper>
                  <ButtonWrapper
                      onClick={() => {
                        setSelectedArea(data.area);
                        setEstimateModalVisible(true);
                      }}
                  >
            <span
                style={{
                  fontSize: '14px',
                  color: '#fff',
                }}
            >
              견적설정
            </span>
                  </ButtonWrapper>
                </div>
                <CloseOutlined onClick={()=>{
                  const confirm = window.confirm('해당공간의 설정한 견적 내용이 초기화됩니다. 정말 삭제하시겠습니까?');
                  if(confirm){
                    setForm(produce((draft) => {
                      draft.subCreates = draft.subCreates.filter(
                          (v2) => v2.area !== data?.area);

                      return draft;
                    }))
                  }
                }} style={{fontSize: 20}}></CloseOutlined>
              </div>
              <InputComponent
                  maxLength={80}
                  value={data?.title}
                  onChange={(e) => {
                    const value = e.target.value;
                    setForm(
                        produce((draft) => {
                          const target = draft.subCreates.find(
                              (v2) => v2.area === data?.area);
                          target.title = value;
                          return draft;
                        }),
                    );
                  }}
                  placeholder="공간 제목을 입력해 주세요"
              />
              <span style={{
                position: 'absolute',
                right: 0,
                fontSize: '14px',
              }}>{`${data?.title?.length ?? 0}/80`}</span>
            </div>
            {data?.subContentItems?.map((v,i) => (
                <>
                  {v?.thumbnailId !== undefined &&
                      <div style={{
                        ...styles.imageInputWrapper,
                        backgroundImage: `url(${parseImageUri(
                            v?.thumbnailId)})`,
                      }}>
                        {!v?.thumbnailId && (
                            <span
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '600',
                                  color: '#fff',
                                }}
                            >
                              사진을 업로드해 주세요.
                            </span>
                        )}
                        <div style={styles.buttonContainer}>
                          {i!==0&&
                          <ButtonWrapper
                              onClick={() => {
                                setForm(produce((draft) => {
                                  const target = draft.subCreates.find(
                                      (v2) => v2.area === data?.area);
                                  const temp = target.subContentItems[i];
                                  target.subContentItems[i] = target.subContentItems[i-1];
                                  target.subContentItems[i-1] = temp;
                                  return draft;
                                }))
                              }}
                                         isRound>

                            <UpOutlined
                                style={{
                                  color: '#FFF',
                                  width: '15px',
                                  height: '15px',
                                }}/>
                          </ButtonWrapper>
                          }
                          {
                            i!==data.subContentItems.length-1&&

                          <ButtonWrapper onClick={
                            () => {
                              setForm(produce((draft) => {
                                const target = draft.subCreates.find(
                                    (v2) => v2.area === data?.area);
                                const temp = target.subContentItems[i];
                                target.subContentItems[i] = target.subContentItems[i+1];
                                target.subContentItems[i+1] = temp;
                                return draft;
                              }))
                            }
                          }  isRound>
                            <DownOutlined
                                style={{
                                  color: '#FFF',
                                  width: '15px',
                                  height: '15px',
                                }}/>
                          </ButtonWrapper>
                          }

                          <ButtonWrapper htmlFor={`fileInput_${data.area}_${i}`}
                                         isRound>
                            <input type="file" id={`fileInput_${data.area}_${i}`}
                                   style={{display: 'none'}}
                                   onChange={async (e)=>{
                                       const file = e.target.files[0];
                                       const imageId = await getFileId(file);
                                       setForm(
                                           produce((draft) => {
                                             const target = draft.subCreates.find(
                                                 (v2) => v2.area === data?.area);
                                             target.subContentItems[i].thumbnailId = imageId;
                                             return draft;
                                           }),
                                       );
                                       // setForm(imageId, 'thumbnailId');

                                   }}/>
                            <RedoOutlined
                                style={{
                                  color: '#FFF',
                                  width: '15px',
                                  height: '15px',
                                }}/>
                          </ButtonWrapper>
                          <ButtonWrapper
                              onClick={() => {
                                setForm(produce((draft) => {
                                  const target = draft.subCreates.find(
                                      (v2) => v2.area === data?.area);
                                  target.subContentItems.splice(i, 1);
                                  target.subContentItems.forEach((v,i)=>{
                                    v.orderNum = i+1;
                                  })
                                  return draft;
                                }))
                              }}
                          >
                            <DeleteOutlined
                                style={{
                                  color: '#FFF',
                                  width: '15px',
                                  height: '15px',
                                }}/>
                          </ButtonWrapper>

                        </div>
                      </div>
                  }
                  {v?.content !== undefined &&
                      <div style={{
                        ...styles.contentInputWrapper,
                        position: 'relative',
                        // borderRadius: 10,
                        // overflow: 'hidden',
                      }}>
                        <div style={{...styles.buttonContainer, zIndex: 1}}>
                          {i!==0&&
                              <ButtonWrapper
                                  onClick={() => {
                                    setForm(produce((draft) => {
                                      const target = draft.subCreates.find(
                                          (v2) => v2.area === data?.area);
                                      const temp = target.subContentItems[i];
                                      target.subContentItems[i] = target.subContentItems[i-1];
                                      target.subContentItems[i-1] = temp;
                                      return draft;
                                    }))
                                  }}
                                  isRound>

                                <UpOutlined
                                    style={{
                                      color: '#FFF',
                                      width: '15px',
                                      height: '15px',
                                    }}/>
                              </ButtonWrapper>
                          }
                          {
                              i!==data.subContentItems.length-1&&

                              <ButtonWrapper onClick={
                                () => {
                                  setForm(produce((draft) => {
                                    const target = draft.subCreates.find(
                                        (v2) => v2.area === data?.area);
                                    const temp = target.subContentItems[i];
                                    target.subContentItems[i] = target.subContentItems[i+1];
                                    target.subContentItems[i+1] = temp;
                                    return draft;
                                  }))
                                }
                              }  isRound>
                                <DownOutlined
                                    style={{
                                      color: '#FFF',
                                      width: '15px',
                                      height: '15px',
                                    }}/>
                              </ButtonWrapper>
                          }
                          <ButtonWrapper
                              onClick={() => {
                                setForm(produce((draft) => {
                                  const target = draft.subCreates.find(
                                      (v2) => v2.area === data?.area);
                                  target.subContentItems.splice(i, 1);
                                  target.subContentItems.forEach((v,i)=>{
                                    v.orderNum = i+1;
                                  })
                                  return draft;
                                }))
                              }}

                          >
                            <DeleteOutlined
                                style={{
                                  color: '#FFF',
                                  width: '15px',
                                  height: '15px',
                                }}/>
                          </ButtonWrapper>
                        </div>
                        <MultiLineInput
                            style={{
                              backgroundColor: '#d9d9d9',
                              padding: 10,
                              paddingRight: 30,
                              borderRadius:10,
                              // marginBottom: 10,
                              minHeight: 200,
                            }}
                            value={v?.content}
                            onChange={(e) => {
                              setForm(
                                  produce((draft) => {
                                    const target = draft.subCreates.find(
                                        (v2) => v2.area === data?.area);
                                    target.subContentItems[i].content = e.target.value;
                                    return draft;
                                  }),
                              );
                            }}
                            placeholdercolor={'#fff'}
                            // autoSize={{minRows: 6}}

                            placeholder="내용을 입력해 주세요"
                        />
                      </div>
                  }

                </>
            ))}
            <div
                style={{...styles.addButtonWrapper, gap: 10, paddingBottom: 0}}>
              <RoundButton
                  onClick={() => {
                    setForm(produce((draft) => {
                      const target = draft.subCreates.find(
                          (v2) => v2.area === data?.area);
                      target.subContentItems.push({
                        content: null,
                        // orderNum: target.subContentItems.length+1,
                      });
                      target.subContentItems.forEach((v,i)=>{
                        v.orderNum = i+1;
                      })
                      return draft;
                    }))
                  }}
                  label="글추가"
                  containerStyle={{
                    width: '100px',
                    height: '30px',
                  }}
              />
              <RoundButton
                  onClick={() => {
                    setForm(produce((draft) => {
                      const target = draft.subCreates.find(
                          (v2) => v2.area === data?.area);
                      target.subContentItems.push({
                        thumbnailId: null,
                        // orderNum: target.subContentItems.length+1,
                      });
                      target.subContentItems.forEach((v,i)=>{
                        v.orderNum = i+1;
                      })
                      return draft;
                    }))
                  }}
                  label="사진추가"
                  containerStyle={{
                    width: '100px',
                    height: '30px',
                  }}
              />
            </div>
          </>
      );
    });

const WriteContentModal = React.memo(({
  selectedId,
  visible,
  setVisible,
  handleOk,
  handleCancel,
  type,
  isEdit,
}) => {
  const {commonCodes} = useSelector((s) => s.common);
  const [EstimateModalVisible, setEstimateModalVisible] = React.useState(false);
  const [selectedArea, setSelectedArea] = React.useState(null);
  const queryClient = useQueryClient();
  useGetContentDetail({
    type,
    id: selectedId,
    config: {
      enabled: !!isEdit && !!selectedId && visible,
      onSuccess: (data) => {
        if (!!isEdit&&!form.id) {
          setForm(
              produce((draft) => {
                draft.id = data.id;
                const areaCounters = {};
                draft.title = data.title;
                draft.description = data.description;
                draft.thumbnailId = data.thumbnailId;
                draft.constructionType = data.constructionType;
                draft.constructionTerm = data.constructionTerm;
                draft.constructionDifficulty = data.constructionDifficulty;
                draft.constructionCost = data.constructionCost;
                draft.subCreates = data.subContents?.map((v) => {
                  const {area} = v;
                  areaCounters[area] = (areaCounters[area] || 0) + 1;
                  const newArea = `${area}_${areaCounters[area] - 1}`;
                  const materialAndProcesses = v?.appliedMaterialsAndProcessList;
                  return {
                    area: newArea,
                    subContentItems: v?.subContentItems?.map((v) => ({
                      content : v?.content || undefined,
                      thumbnailId: v?.thumbnailId || undefined,
                      orderNum: v?.orderNum,
                        })),
                    materialAndProcesses: materialAndProcesses?.map((v) => ({
                      templateId: v.templateId,
                      productId: v?.id,
                      qty: v?.qty,
                      // 보여주기 위한 데이터
                      name: v?.name,
                      thumbnailId: v?.thumbnailId,
                      price: v?.price,
                      makerName: v?.makerName,
                      categoryId: v?.categoryId,
                      children: v?.children,
                    })),
                    title: v?.title,
                    description: v?.description,
                    thumbnailId: v?.thumbnailId,
                  };
                });
              }),
          );
        }
      },
    },
  });
  const initialSubCreates = React.useMemo(
      () => ({
        area: 'AREA001_0',
        title: null,
        description: null,
        materialAndProcesses: [],
        thumbnailId: null,
        subContentItems: [
          {
            thumbnailId: null,
            orderNum: 1,
          }, {
            content: null,
            orderNum: 2,
          }],
      }),
      [],
  );
  const initialForm = React.useMemo(
      () => ({
        title: '',
        thumbnailId: null,
        constructionType: 'COTP001',
        constructionTerm: 'COTM001',
        constructionDifficulty: 'CODF001',
        constructionCost: 'COCO001',
        subCreates: [initialSubCreates],
      }),
      [initialSubCreates],
  );
  const [form, setForm] = React.useState(initialForm);

  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const imageId = await getFileId(file);
    setForm(
        produce((draft) => {
          draft.thumbnailId = imageId;
        }),
    );
  };

  const constructionTypeItems = React.useMemo(() => {
    return commonCodes?.tree['COTP']?.map((v) => ({
      value: v.code,
      label: v.label,
    }));
  }, [commonCodes]);

  const constructionTermItems = React.useMemo(() => {
    return commonCodes?.tree['COTM']?.map((v) => ({
      value: v.code,
      label: v.extra1,
    }));
  }, [commonCodes]);

  const constructionDifficultyItems = React.useMemo(() => {
    return commonCodes?.tree['CODF']?.map((v) => ({
      value: v.code,
      label: v.extra1,
    }));
  }, [commonCodes]);

  const constructionCostItems = React.useMemo(() => {
    return commonCodes?.tree['COCO']?.map((v) => ({
      value: v.code,
      label: v.extra1,
    }));
  }, [commonCodes]);

  const areaItems = React.useMemo(() => {
    return commonCodes?.tree['AREA']?.map((v) => ({
      value: v.code,
      label: v.label,
    }));
  }, [commonCodes]);

  const handleSubmit = React.useCallback(() => {
    const subCreates = form?.subCreates?.map((v) => ({
      ...v,
      area: v.area?.split('_')[0],
    }));
    const data = {
      ...form,
      subCreates: subCreates,
    };

    Modal.confirm({
      content: '저장하시겠습니까?',
      okText: '저장',
      cancelText: '취소',
      onOk: () => {
        const api = isEdit
            ? (data) => restApi.put(`/${type}/${selectedId}`, data)
            : (data) => restApi.post(`/${type}`, data);
        api(data).then((res) => {
          Modal.success({
            content: '저장되었습니다.',
            onOk: async () => {
              await queryClient.invalidateQueries(CONTENTS_KEY);
              await queryClient.invalidateQueries(
                  [CONTENT_DETAIL_KEY, type, selectedId]);
              setVisible(false);
              setForm(initialForm);
            },
          });
        }).catch((err) => {
          Modal.warn({
            content: err.response.data.message,
            okText: '확인',
          });
        });
      },
    });
  }, [form, queryClient, type, isEdit, selectedId]);
  return (
      <Modal
          width={840}
          bodyStyle={{
            width: '100%',
            minWidth: 840,
            height: document.body.clientHeight - 100,
            overflowY: 'scroll',
            backgroundColor: '#f9f9f9',
            padding: '0px',
          }}
          closable={false}
          open={visible}
          onCancel={() => {
            handleCancel();
            setForm(initialForm);
          }}
          destroyOnClose={true}
          maskClosable={true}
          okText={'확인'}
          footer={null}
          title={
            <div style={styles.header}>
              <RoundButton
                  onClick={() => {
                    handleCancel();
                    setForm(initialForm);
                  }}
                  label="취소"
                  containerStyle={{
                    width: '100px',
                    marginRight: '10px',
                  }}
              />
              <RoundButton
                  onClick={() => handleSubmit()}
                  reverse
                  label="저장"
                  containerStyle={{
                    width: '100px',
                  }}
              />
            </div>
          }
          cancelButtonProps={{style: {display: 'none'}}}
      >
        <div style={styles.modalContainer}>

          <div style={styles.titleImageWrapper}>
            <div style={{
              ...styles.titleImage,
              backgroundImage: `url(${parseImageUri(form?.thumbnailId)})`,
            }}>
              <div
                  style={{
                    display: 'flex',
                    position: 'relative',
                    justifyContent: 'flex-end',
                    right: '120px',
                    top: '490px',
                    zIndex: 99,
                  }}
              >
                <label style={{cursor: 'pointer'}} htmlFor="fileInput">
                  <div>
                    <RedoOutlined style={styles.modify_reupload}/>
                  </div>
                  <input type="file" id="fileInput" style={{display: 'none'}}
                         onChange={handleChangeImage}/>
                </label>
                <div>
                  <select
                      onChange={(e) => {
                        setForm(
                            produce((draft) => {
                              draft.constructionType = e.target.value;
                            }),
                        );
                      }}
                      style={{
                        ...styles.modify_style_select,
                        width: '100px',
                        backgroundColor: '#999',
                      }}
                  >
                    {constructionTypeItems?.map((v) => (
                        <option value={v.value}>{v.label}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div style={styles.bodyWrapper}>
            <div style={styles.titleInputWrapper}>
              <InputComponent
                  maxLength={80}
                  value={form?.title}
                  onChange={(e) => {
                    setForm(
                        produce((draft) => {
                          draft.title = e.target.value;
                        }),
                    );
                  }}
                  placeholder="메인 제목을 입력해 주세요"
              />
              <span style={{
                position: 'absolute',
                right: 0,
                fontSize: '14px',
              }}>{`${form?.title?.length}/80`}</span>
            </div>
            <div style={styles.dropdownContainer}>
              <select
                  onChange={(e) => {
                    setForm(
                        produce((draft) => {
                          draft.constructionTerm = e.target.value;
                        }),
                    );
                  }}
                  style={{
                    ...styles.modify_style_select,
                    width: '300px',
                    backgroundColor: '#e8e8e8',
                    color: '#767676',
                  }}
              >
                {constructionTermItems?.map((v) => (
                    <option value={v.value}>{v.label}</option>
                ))}
              </select>
              <select
                  onChange={(e) => {
                    setForm(
                        produce((draft) => {
                          draft.constructionDifficulty = e.target.value;
                        }),
                    );
                  }}
                  style={{
                    ...styles.modify_style_select,
                    width: '300px',
                    backgroundColor: '#e8e8e8',
                    color: '#767676',
                  }}
              >
                {constructionDifficultyItems?.map((v) => (
                    <option value={v.value}>{v.label}</option>
                ))}
              </select>
              <select
                  onChange={(e) => {
                    setForm(
                        produce((draft) => {
                          draft.constructionTerm = e.target.value;
                        }),
                    );
                  }}
                  style={{
                    ...styles.modify_style_select,
                    width: '300px',
                    backgroundColor: '#e8e8e8',
                    color: '#767676',
                  }}
              >
                {constructionCostItems?.map((v) => (
                    <option value={v.value}>{v.label}</option>
                ))}
              </select>
            </div>
            <div style={styles.contentInputWrapper}>
              <MultiLineInput
                  value={form?.description}
                  onChange={(e) => {
                    setForm(
                        produce((draft) => {
                          draft.description = e.target.value;
                        }),
                    );
                  }}
                  autoSize={{minRows: 6}}
                  placeholder="대표 본문을 입력해 주세요."
              />
            </div>
            {form?.subCreates?.map((v) => (
                <SubForm
                    setSelectedArea={setSelectedArea}
                    setEstimateModalVisible={setEstimateModalVisible}
                    data={v}
                    areaItems={areaItems}
                    setForm={setForm}
                />
            ))}

            <div style={styles.addButtonWrapper}>
              <RoundButton
                  onClick={() => {
                    const existAreas = form.subCreates?.map(
                        (v) => v.area?.split('_')[0]);
                    const area = areaItems?.find(
                        (v) => !existAreas.includes(v.value))?.value;
                    if (area) {
                      setForm(
                          produce((draft) => {
                            draft.subCreates.push({
                              ...initialSubCreates,
                              area: `${area}_0`,
                            });
                          }),
                      );
                    } else {
                      const area1Length = form.subCreates.filter(
                          (v) => v.area?.split('_')[0] === 'AREA001').length;
                      setForm(
                          produce((draft) => {
                            draft.subCreates.push({
                              ...initialSubCreates,
                              area: `AREA001_${area1Length}`,
                            });
                          }),
                      );
                    }
                  }}
                  label="공간추가"
                  containerStyle={{
                    width: '100px',
                    height: '30px',
                  }}
              />
            </div>
          </div>
        </div>
          <ModifyEstimateModal
              visible={EstimateModalVisible}
              setVisible={setEstimateModalVisible}
              handleOk={() => {}}
              handleCancel={() => setEstimateModalVisible(false)}
              area={selectedArea}
              form={form}
              setForm={setForm}
          />
      </Modal>
  );
});

export default WriteContentModal;
